import React, { createRef, useContext } from "react";
import { Component } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import {step3DataFailure, step3DataReset, step3DataSuccess, step4DataSuccess, userInfoDataSuccess } from "../redux/actions";
import * as Constants from "./utils/constants";
import { BlankPerson, IAccidentInfo, IFieldDescriptor, IPerson, Step1Data, Step2Data, Step3Data, Step4Data } from "./utils/common";
import { fetchList, getNumberList, fixDirtyBackendErrorString, formatDateAdform, sortJson, encryptEmail, extractAddressData, removeFocusClass, offsetFirstErrorField, getValidInsuranceStart
} from "./utils/methods";
import { isLicensePlateAutoValid, isLicensePlateMotoValid } from "./utils/validate";

import ContractorRecap from "./customs/ContractorRecap";
import {default as RadioButtonList} from "./customs/RadioButtonList";
import User, { IValidationResponse, sortPeopleFunction } from "./utils/User";
import OwnerDriverListItemLabel from "./customs/OwnerDriverListItemLabel";
import { PersonCard } from "./customs/PersonCard";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import EmptyListItemLabel from "./customs/EmptyListItemLabel";
import { Step3ContractorBuilder, Step3DriverFieldsBuilder, Step3OwnerFieldsBuilder } from "./utils/PersonFieldsBuilder";
import { connect } from "react-redux";
import { StepDataDualState } from "../redux/reducers";
import { useHistory } from "react-router-dom";
import { IWithEventManager } from "./customs/HOC/EventManager";
import withEventManager from "./customs/HOC/withEventManager";
import { compose } from "ramda";
import { DataList, IDataList } from "./customs/dataListContext";
import CaptchaComponent from "./captcha/Captcha";

interface IStep3Props extends IWithEventManager{
  user: User;
  step1Data: StepDataDualState;
  step2Data: StepDataDualState;
  step3Data: StepDataDualState;
  userInfoDataSuccess: Function;
  step3DataSuccess: Function;
  step3DataFailure: Function;
  step3DataReset: Function;
  step4DataSuccess: Function;
  fromMailSereno: boolean;
  vehicle: string;
  history: ReturnType<typeof useHistory>;
  setResourceListRequest: Function;
}

interface IStep3State{
  paymentMethodsList: any[];
  insuranceStatusList: any[];
  insuranceClassList: any[];
  yearsInClass1List: any[];
  yearsInsuredList: any[];
  insuranceCompaniesList: any[];
  pastAccidentCountList: any[];
  bersaniDecreeStatusList: any[];
  peopleCoincideList: any[];  // {value: string, value_id: string, label: string}
  youngestOtherDriverAgeList: any[];
  youngestFamilyDriverAgeList: any[];
  insuranceClassListBersani: any[];
  licenseTypesList: any[];
  licensePointsList: any[];
  drunkDrivingTicketsList: any[];
  licenseSuspensionsList: any[];
  licenseSuspensionMonthsList: any[];
  accidentYearList: any[];
  accidentResponsibilityList: any[];
  highestDisplacementList: any[];
  consecutiveDrivingMonthsList: any[];
  
  acceptAllOptionalCollection: boolean | undefined;

  detailIvassIsVisible: boolean | undefined;
  iscontractorEmailValid: boolean;

  serverSideCheckErrorInsuranceClass: string;
  serverSideCheckErrorDriverDob: string;
  serverSideCheckErrorDriverAddress: string;
  serverSideCheckErrorDriverLicenseAge: string;
  serverSideCheckErrorOwnerDob: string;
  serverSideCheckErrorOwnerAddress: string;
  serverSideCheckErrorOwnerLicenseAge: string;
  serverSideCheckErrornewInsuranceStart: string;
  loading: boolean;
  errors: string[];
  errorTexts: IFeedbackText[];

  people: IPerson[];

  submitting: boolean;
  activeIndex: number;
  activeIndexIvass: number;

  showTurnstileManaged: boolean;
  turnstileToken: string | undefined;
}

class Step3Component extends Component<IStep3Props, IStep3State> {
  private vehicle: string;
  private privacySeiSicuroCollection: HTMLDivElement | null = null;

  private personCardRef: any;
  private currentPersonCardType : string = "";

  private readonly stepFields : any;

  constructor(props: any) {
    super(props);
    this.vehicle = this.props.vehicle;


    this.stepFields = {
      step: {},
      owner: Step3OwnerFieldsBuilder(),
      driver: Step3DriverFieldsBuilder(),
      contractor: Step3ContractorBuilder()
    }

    this.personCardRef = createRef();

    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    let pastAccidentCountOptions = getNumberList(Constants.MAX_ACCIDENTS);

    let policy3 = (step3Data?.acceptGdprPrivacyPolicy3 && step3Data.acceptGdprPrivacyPolicy3 === true);
    let policy102 = (step3Data?.acceptGdprPrivacyPolicy102 && step3Data.acceptGdprPrivacyPolicy102 === true);
    let policy104 = (step3Data?.acceptGdprPrivacyPolicy104 && step3Data.acceptGdprPrivacyPolicy104 === true);

    this.state = {
      paymentMethodsList: [],
      insuranceStatusList: [],
      insuranceClassList: [],
      yearsInClass1List: [],
      yearsInsuredList: [],
      insuranceCompaniesList: [],
      pastAccidentCountList: pastAccidentCountOptions,
      bersaniDecreeStatusList: [],
      peopleCoincideList: [],
      youngestOtherDriverAgeList: [],
      youngestFamilyDriverAgeList: [],
      insuranceClassListBersani: [],
      licenseTypesList: [],
      licensePointsList: [],
      drunkDrivingTicketsList: [],
      licenseSuspensionsList: [],
      licenseSuspensionMonthsList: [],
      accidentYearList: [],
      accidentResponsibilityList: [],
      highestDisplacementList: [],
      consecutiveDrivingMonthsList: [],
      
      acceptAllOptionalCollection: policy102 && policy104 && policy3,

      detailIvassIsVisible: false,

      people: [],

      errors: [],
      errorTexts: [],

      iscontractorEmailValid: false,
      loading: false,
      serverSideCheckErrorInsuranceClass: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorDriverAddress: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorDriverDob: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorDriverLicenseAge: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorOwnerAddress: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorOwnerDob: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorOwnerLicenseAge: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrornewInsuranceStart: Constants.MISSING_FIELD_TEXT,

      submitting: false,

      activeIndex: -1,
      activeIndexIvass: -1,

      showTurnstileManaged: false,
      turnstileToken: undefined
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.showMoreIvass = this.showMoreIvass.bind(this);
    this.trakSendLoaded = this.trakSendLoaded.bind(this);
    
    this.getContractor = this.getContractor.bind(this);
    this.reduxPreRefineData = this.reduxPreRefineData.bind(this);
    this.saveReduxState = this.saveReduxState.bind(this);
    this.updateStatePersonSave = this.updateStatePersonSave.bind(this);
    this.togglePrivacyDetails = this.togglePrivacyDetails.bind(this);
    this.toggleIvassDetails = this.toggleIvassDetails.bind(this);

    this.saveCaptchaToken = this.saveCaptchaToken.bind(this)
    this.disableFormSubmit = this.disableFormSubmit.bind(this)

  }

  private togglePrivacyDetails(){
    this.setState({
      activeIndex: (this.state.activeIndex === 0 ? -1 : 0)
    });
  }

  private toggleIvassDetails(){
    this.setState({activeIndexIvass: (this.state.activeIndexIvass === 0 ? -1 : 0)});
  }

  filterOnlyMaggiorenne(p: IPerson): boolean{
    let show: boolean = true;
    if(p?.dateOfBirth){
      const today = new Date();
      const age = today.getFullYear() - p.dateOfBirth.getFullYear();
      if(age > Constants.DOB_YEAR_RANGE_MIN){
        show = true; // è maggiorenne
      }
      else if(age === Constants.DOB_YEAR_RANGE_MIN){
        if(today.getMonth() < p.dateOfBirth.getMonth()){
          // devi essere maggiorenne
          show = false;
        }
        else if(today.getMonth() > p.dateOfBirth.getMonth()){
          //ok
          show = true;
        }
        else{
          //check giorno
          if(today.getUTCDay() < p.dateOfBirth.getUTCDay()){
            // devi essere maggiorenne
            show = false;
          }
          else{
            // ok
            show = true;
          }
        }
      }
      else{
        show = false;
      }
    }
    return show;
  }

  sortPeople: sortPeopleFunction = (p1, p2) => {
    let p1Name: string = User.getPersonSafeName(p1).toLowerCase();
    let p2Name: string = User.getPersonSafeName(p2).toLowerCase();
    return p1Name > p2Name
      ? 1
      : p1Name < p2Name
        ? -1
        : 0
  }

  enhanceListOfPeopleWithError(people: IPerson[]): Promise<IPerson[]> {
    
    let peoplePromises : Promise<IPerson>[] = people
      .map(async (p_validated) => {
        return User.validatePerson(
          {...p_validated},
          Object.entries(Step3DriverFieldsBuilder()).map(([, value]) => value) as IFieldDescriptor[])
            .then(result => {
              const {error_fields: errors, error_messages: errorTexts} = result;
              const refinedItems = {...p_validated, errors, errorTexts};
              return refinedItems;
            })
    })
  
    return Promise.allSettled(peoplePromises)
      .then((results) => {
        let listOfPeople: IPerson[] = [];
        results.forEach((fieldResponse) => {
          if (fieldResponse.status === "fulfilled") {
            listOfPeople.push(fieldResponse.value);
          }
        })
        return listOfPeople;
      })
  }

  loadResourceFromUser(){
    const listOfPeople: IPerson[] = this.props.user?.getPeople(this.filterOnlyMaggiorenne, true, this.sortPeople) ?? [];
    
    this.enhanceListOfPeopleWithError(listOfPeople)
      .then((enhancedPeople: IPerson[]) => {
        this.setState({
          people: enhancedPeople ?? [],
        }/*, () => {
          const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
          if(step1Data?.contractorId){
            const contractor: IPerson | undefined = this.state.people.find((p: IPerson) => p.id === step1Data.contractorId);
            if(contractor){
              this.props.step3DataSuccess({...contractor}, this.vehicle)
            }
          }
        }*/)
      })
  }

  async componentDidMount() {

    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_STATE_ENDPOINT,
      (result: any) => {
        this.setState({ insuranceStatusList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_CLASS_ENDPOINT,
      (result: any) => {
        this.setState({ insuranceClassList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_COMPANY_ENDPOINT,
      (result: any) => {
        result.sort(sortJson);
        this.setState({ insuranceCompaniesList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_BERSANI_ENDPOINT,
      (result: any) => {
        this.setState({ bersaniDecreeStatusList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_PEOPLE_COINCIDE_ENDPOINT,
      (result: any) => {
        this.setState({ peopleCoincideList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_CAR_LICENSE_TYPE_ENDPOINT(this.vehicle),
      (result: any) => {
        this.setState({ licenseTypesList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_LICENSE_POINTS_ENDPOINT,
      (result: any) => {
        this.setState({ licensePointsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_DRUNK_DRIVING_ENDPOINT,
      (result: any) => {
        this.setState({ drunkDrivingTicketsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_LICENSE_SUSPENSIONS_ENDPOINT,
      (result: any) => {
        this.setState({ licenseSuspensionsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_LICENSE_SUSPENSION_MONTHS_ENDPOINT,
      (result: any) => {
        this.setState({ licenseSuspensionMonthsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_BERSANI_CLASS_ENDPOINT,
      (result: any) => {
        this.setState({ insuranceClassListBersani: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_YOUNGEST_FAMILY_ENDPOINT,
      (result: any) => {
        this.setState({ youngestFamilyDriverAgeList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_YOUNGEST_DRIVER_ENDPOINT,
      (result: any) => {
        this.setState({ youngestOtherDriverAgeList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_AGE_ENDPOINT,
      (result: any) => {
        this.setState({ yearsInsuredList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_CLASS_AGE_ENDPOINT,
      (result: any) => {
        this.setState({ yearsInClass1List: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_PAYMENT_OPTIONS_ENDPOINT,
      (result: any) => {
        result.sort(sortJson);
        this.setState({ paymentMethodsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_ACCIDENT_YEAR_ENDPOINT,
      (result: any) => {
        this.setState({ accidentYearList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_ACCIDENT_RESPONSIBILITY_ENDPOINT,
      (result: any) => {
        this.setState({ accidentResponsibilityList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_HIGHEST_DISPLACEMENT_ENDPOINT,
      (result: any) => {
        this.setState({ highestDisplacementList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_CONSECUTIVE_DRIVING_MONTHS_ENDPOINT,
      (result: any) => {
        this.setState({ consecutiveDrivingMonthsList: result });
      }
    );

    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let privacytToDisapatch: Step3Data = {};
    if(!(step3Data?.acceptCgu105 ?? false)){
      privacytToDisapatch.acceptCgu105 = true;
    }
    if(!(step3Data?.acceptSeiSicuroPrivacyPolicy ?? false)){
      privacytToDisapatch.acceptSeiSicuroPrivacyPolicy = true;
    }
    if(JSON.stringify(privacytToDisapatch) !== "{}"){
      this.props.step3DataSuccess(privacytToDisapatch, this.vehicle);
    }
    this.loadResourceFromUser()

    if(!(window as any)?.Cookiebot?.hasResponse){
      window.addEventListener("CookiebotOnAccept", this.trakSendLoaded)
      window.addEventListener("CookiebotOnDecline", this.trakSendLoaded)
    }
    else{
      this.trakSendLoaded();
    }
  }

  componentDidUpdate(prevProps: IStep3Props, prevState: IStep3State){
    !prevProps.user.isEqual(this.props.user) && this.loadResourceFromUser()
  }

  // ------------------------------------------------------------------------------------------------------------------------------

  private getDataToTrack(){
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    return {
      prodotto: this.vehicle,
      gender: step1Data.gender,
      dataNascita: formatDateAdform(dateOfBirth),
      titoloStudio: step1Data.degree,
      professione: step1Data.profession,
      dataImmatricolazione: `${
        step2Data.vehicleRegistrationMonth !== undefined &&
        step2Data.vehicleRegistrationMonth < 10
          ? `0${step2Data.vehicleRegistrationMonth}`
          : step2Data.vehicleRegistrationMonth
      }/${step2Data.vehicleRegistrationYear}`,
      annoAcquisto: step2Data.vehicleBuyYear,
      veichleBrand: step2Data.vehicleBrandLabel,
      veichleModel: step2Data.vehicleModelLabel,
      telefono: step3Data.contractorPhoneNumber,
      emailKript: encryptEmail(step3Data.contractorEmail),
      email: step3Data.contractorEmail,
      name: step3Data.contractorName,
      surname: step3Data.contractorSurname,
      mktgAssicurativo: step3Data.acceptGdprPrivacyPolicy102,
      mktgNonAssicurativo: step3Data.acceptGdprPrivacyPolicy102,
      newsletter: step3Data.acceptGdprPrivacyPolicy22,
      profilazione: step3Data.acceptGdprPrivacyPolicy3,
      cessioneAssicurativo: step3Data.acceptGdprPrivacyPolicy104,
      cessioneNonAssicurativo: step3Data.acceptGdprPrivacyPolicy104,
      gdpr: step3Data.acceptSeiSicuroPrivacyPolicy,
    }
  }

  /**
   * Trak tag-manager: notifica errore
   */
  private trackSendFailure(error_data?: any) {
    let errorToSend: any = {};
    if(error_data){
      errorToSend = {
        errors: error_data
      }
    }
    else{
      errorToSend = {
        errors: this.state.errors,
        errorTexts: this.state.errorTexts
      }
    }

    this.props.trackEvent?.({
      name: "step3LoggedError",
      data: {
        ...this.getDataToTrack(),
        ...errorToSend
      }
    });
  }

  /**
   * Trak tag-manager: notifica successo
   */
  private trackSendSuccess(callbackFunction: Function) {
    this.props.trackEvent?.({
      name: "step3LoggedSuccess",
      data: this.getDataToTrack(),
      callback: callbackFunction
    });
  }

  /**
   * Track tag-mamager: notifica pagina caricata
   */
  private trakSendLoaded() {
    this.props.trackEvent?.({
      name: "step3LoggedLoaded",
      data: this.getDataToTrack()
    });
  }

  /**
   * Assembles the month picker component contained in the Calendar component
   *
   * @param e
   * @returns {any} the component which will represent the month picker
   */
  private monthNavigatorTemplate(e: any) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  /**
   * Assembles the year picker component contained in the Calendar component
   *
   * @param e
   * @returns {any} the component which will represent the year picker
   */
  private yearNavigatorTemplate(e: any) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  updateStatePersonSave(person: IPerson){
    if(["owner", "driver", "contractor"].includes(this.currentPersonCardType)){
      let fieldToValidate: IFieldDescriptor[] = [];
      if(this.currentPersonCardType === "owner"){
        fieldToValidate = Object.entries(this.stepFields.owner).map(([, value]) => value as IFieldDescriptor);
      }
      
      if(this.currentPersonCardType === "driver"){
        fieldToValidate = Object.entries(this.stepFields.driver).map(([, value]) => value as IFieldDescriptor);
      }
      
      if(this.currentPersonCardType === "contractor"){
        fieldToValidate = Object.entries(this.stepFields.contractor).map(([, value]) => value as IFieldDescriptor);
      }
  
      let dataToDispatch: Step3Data = {};
      let newState: any = {};
  
      User.validatePerson(person, fieldToValidate)
        .then(result => {
          this.props.user.setPerson(person);
          const {error_fields: errors, error_messages: errorTexts} = result;

          newState = {
            people: [...this.state.people.filter((p: IPerson) => p.id !== person.id), {...person, errors, errorTexts} as IPerson].sort(this.sortPeople),
          }
  
          if(this.currentPersonCardType === "owner"){
            dataToDispatch = {
              ownerId: person.id,
              owner: {...person},
            };
          }
          if(this.currentPersonCardType === "driver"){
            dataToDispatch = {
              driverId: person.id,
              driver: {...person},
            };
          }
          if(this.currentPersonCardType === "contractor"){
            const {toponimo} = extractAddressData(this.props.user.userData.address ?? "");
            dataToDispatch = {
              contractorName: person.name,
              contractorSurname: person.surname,
              contractorCityOfResidence: person.cityOfResidence,
              contractorPostalCode: person.postalCodeOfResidence,
              contractorAddress: person.address,
              contractorAddressToponimo: toponimo,
              contractorAddressNumber: person.addressNumber,
              contractorChildren: person.children,
              contractorYoungestChild: typeof(person?.youngestChild) === "string" ? parseInt(person.youngestChild) : person?.youngestChild,
              contractorEmail: this.props.user.userData.email,
              contractorPhoneNumber: this.props.user.userData.phone,
              contractorItalianDrivingLicense: person.italianDrivingLicense,
              contractorDrivingLicenseAge: person.drivingLicenseAge,
            };
          }
          
          this.setState(newState, () => this.saveReduxState(this.reduxPreRefineData(dataToDispatch)))
        })
    }
  }

  handlePersonSaveError(sentPerson: IPerson, response: any){
    if(["owner", "driver"].includes(this.currentPersonCardType)){

      let dataToDispatch: Step3Data = {};

      if(this.currentPersonCardType === "owner"){
        if(sentPerson?.id === undefined){
          dataToDispatch = {
            ownerId: undefined,
            owner: undefined
          }

          this.saveReduxState(this.reduxPreRefineData(dataToDispatch));
        }
      }

      if(this.currentPersonCardType === "driver"){
        if(sentPerson?.id === undefined){
          dataToDispatch = {
            driverId: undefined,
            driver: undefined
          }

          this.saveReduxState(this.reduxPreRefineData(dataToDispatch));
        }
      }

    }
  }

  private accidentalDetail() : JSX.Element[] {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    
    return (step3Data?.pastAccidentsInfo ?? [])?.map((item, i) => 
      <div className="col-12" key={i}>
        <div className="row">
          <div className="col-12">
            <p>Incidente {i + 1}</p>
          </div>
          <div className="col-xxl-3 col-4 mb-4">
            <label>Anno</label>
            <Dropdown
              value={item.year}
              options={this.state.accidentYearList}
              onChange={(selected) =>
                this.onChangeHandler(
                  {
                    index: i,
                    field: "year",
                    value: selected.value
                  },
                  `pastAccidentYear${i}`
                )
              }
              onHide={() => removeFocusClass()}
              optionLabel="label"
              placeholder="Seleziona"
              emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
            />
            <ContextFeedback
              show={this.state.errors.includes(`pastAccidentYear${i}`)}
              message={
                this.state.errorTexts.find(elem => elem.field === `pastAccidentYear${i}`) ??
                {msg: "Campo obbligatorio", severity: "error"}
              }
            />
          </div>
          <div className="col-xxl-9 col-8 mb-4">
            <label>Responsabilità</label>
            <Dropdown
              value={item.responsibility}
              options={this.state.accidentResponsibilityList}
              onChange={(selected) =>
                this.onChangeHandler(
                  {
                    index: i,
                    field: "responsibility",
                    value: selected.value,
                  },
                  `pastAccidentRespondibility${i}`
                )
              }
              onHide={() => removeFocusClass()}
              optionLabel="label"
              placeholder="Seleziona"
              emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
            />
            <ContextFeedback
              show={this.state.errors.includes(`pastAccidentRespondibility${i}`)}
              message={
                this.state.errorTexts.find(elem => elem.field === `pastAccidentRespondibility${i}`) ??
                {msg: "Campo obbligatorio", severity: "error"}
              }
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    // const progress = getProgressValue();
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    const contractor: IPerson = this.state.people.find((p: IPerson) => p.id === step1Data.contractorId) as IPerson;

    const dateOfBirthMin = new Date();
    dateOfBirthMin.setFullYear(
      dateOfBirthMin.getFullYear() - Constants.DOB_YEAR_RANGE_MAX
    );
    const dateOfBirthMax = new Date();
    dateOfBirthMax.setFullYear(
      dateOfBirthMax.getFullYear() - Constants.DOB_YEAR_RANGE_MIN
    );

    return (
      <div id="step-3-form">

        <PersonCard
          visible={false}
          user={this.props.user}
          ref={this.personCardRef}
          successCallback={this.updateStatePersonSave}
          setResourceListRequest={this.props.setResourceListRequest}
        />

        {/* <div className="progress-bar-steps">
          <ProgressBar value={progress}></ProgressBar>
        </div> */}
        <Card className="pr-form">
          <div className="p-fluid p-4 p-lg-5">
            <h1 className="titleLev4 colorPrimaryBlue">Dati assicurativi</h1>
            <div className="row">
                    
              <FeedbackBox items={this.state.errorTexts}/>

              <div className="col-12 mt-4 mb-1">
                <h3 className="titleLev5">Contraente: {User.getPersonSafeName(contractor)}</h3>
                { /*<p>Ecco le informazioni che hai già inserito nell'account</p>*/ }
                {contractor &&
                  <>
                    <ContractorRecap
                      contractor={contractor}
                      onContractorUpdate={(person: IPerson) => {
                        this.currentPersonCardType = "contractor";
                        this.updateStatePersonSave(person);
                      }}
                      personCard={this.personCardRef}
                      personCardHideFields={["email", "phone"]}
                      personCardRequiredFields={this.stepFields.contractor}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorRecap")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorRecap") ??
                        {msg: "Dati del contraente incompleti", severity: "error"}
                      }
                    />
                  </>
                }
              </div>

              <div className="col-12">
                <label>Metodo di pagamento preferito</label>
                <Dropdown
                  value={step3Data?.contractorPreferredPaymentMethod}
                  options={this.state.paymentMethodsList}
                  onChange={(selected) =>
                    this.onChangeHandler(
                      selected.value,
                      "contractorPreferredPaymentMethod"
                    )
                  }
                  onHide={() => removeFocusClass()}
                  optionLabel="label"
                  placeholder="Seleziona"
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                />
                <ContextFeedback
                  show={this.state.errors.includes("contractorPreferredPaymentMethod")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "contractorPreferredPaymentMethod") ??
                    {msg: "Campo obbligatorio", severity: "error"}
                  }
                />
                <div>
                  <small>
                    La compilazione del campo non vincola in nessun modo
                    all'acquisto ed è possibile modificarla in un secondo
                    momento
                  </small>
                </div>
              </div>
            </div>
            <Divider/>
            {this.isVisible("ownerFilled") && (
              <div>
                <div className="row">
                  <div className="col-12 mb-4">
                    <h3 className="titleLev5 mb-2">Situazione Assicurativa</h3>
                    <label>Data di decorrenza nuova polizza</label>
                    <Calendar
                      id="navigatorstemplate"
                      value={getValidInsuranceStart(step3Data.newInsuranceStart ? new Date(step3Data.newInsuranceStart) : new Date())}
                      onChange={(selected) => this.onChangeHandler(selected.value, "newInsuranceStart")}
                      minDate={new Date()}
                      monthNavigatorTemplate={this.monthNavigatorTemplate}
                      yearNavigatorTemplate={this.yearNavigatorTemplate}
                      showIcon
                      locale="it"
                      dateFormat={Constants.CALENDAR_DATE_FORMAT}
                      placeholder="Seleziona"
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("newInsuranceStart")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "newInsuranceStart") ??
                        {msg: this.state.serverSideCheckErrornewInsuranceStart, severity: "error"}
                      }
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label>La tua {this.vehicle} è</label>
                    <Dropdown
                      value={step3Data?.vehicleInsuranceStatus}
                      options={this.state.insuranceStatusList}
                      onChange={(selected) =>
                        this.onChangeHandler(
                          selected.value,
                          "vehicleInsuranceStatus"
                        )
                      }
                      onHide={() => removeFocusClass()}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleInsuranceStatus")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleInsuranceStatus") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  {this.isVisible("assicurataAMioNome") && (
                    <div className="col-12 mb-4">
                      <label>Classe di merito di assegnazione</label>
                      <Dropdown
                        value={step3Data?.currentInsuranceCreditClass}
                        options={this.state.insuranceClassList}
                        onChange={(selected) =>
                          this.onChangeHandler(
                            selected.value,
                            "currentInsuranceCreditClass"
                          )
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("currentInsuranceCreditClass")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "currentInsuranceCreditClass") ??
                          {msg: this.state.serverSideCheckErrorInsuranceClass, severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("yearsInClass1List") && (
                    <div className="col-12 mb-4">
                      <label>Da quanti anni sei in classe 1?</label>
                      <Dropdown
                        value={step3Data?.yearsInClass1}
                        options={this.state.yearsInClass1List}
                        onChange={(selected) =>
                          this.onChangeHandler(selected.value, "yearsInClass1")
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("yearsInClass1")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "yearsInClass1") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("assicurataAMioNome") && (
                    <div className="col-12 mb-4">
                      <label>Compagnia assicurativa attuale</label>
                      <Dropdown
                        value={step3Data?.currentInsuranceCompany}
                        options={this.state.insuranceCompaniesList}
                        onChange={(selected) =>
                          this.onChangeHandler(
                            selected.value,
                            "currentInsuranceCompany"
                          )
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("currentInsuranceCompany")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "currentInsuranceCompany") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("assicurataAMioNome") && (
                    <div className="col-12 mb-4">
                      <label>Da quanti anni sei assicurato?</label>
                      <Dropdown
                        value={step3Data?.currentInsuranceYears}
                        options={this.state.yearsInsuredList}
                        onChange={(selected) =>
                          this.onChangeHandler(
                            selected.value,
                            "currentInsuranceYears"
                          )
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("currentInsuranceYears")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "currentInsuranceYears") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("assicurataAMioNome") && (
                    <div className="col-12 mb-4">
                      <label>
                        Quanti incidenti hai avuto (con colpa o senza colpa)?
                      </label>
                      <Dropdown
                        value={step3Data?.pastAccidentCount?.toString()}
                        options={this.state.pastAccidentCountList}
                        onChange={(selected) => this.onChangeHandler(Number(selected.value), "pastAccidentCount")
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("pastAccidentCount")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "pastAccidentCount") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("accidentDetails") &&
                    <>
                      {this.accidentalDetail()}
                    </>
                  }
                  {this.isVisible("bersaniDecree") && (
                    <div className="col-12 mb-4">
                      <label>Decreto Bersani</label>
                      <Dropdown
                        value={step3Data?.bersaniDecree}
                        options={this.state.bersaniDecreeStatusList}
                        onChange={(selected) =>
                          this.onChangeHandler(selected.value, "bersaniDecree")
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("bersaniDecree")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "bersaniDecree") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("cuLicensePlate") && (
                    <div className="col-12 mb-4">
                      <label>Targa del veicolo di cui utilizzi la CU</label>
                      <InputText
                        value={step3Data?.cuLicensePlate ?? ""}
                        onChange={(selected) => this.onChangeHandler(selected.target.value,"cuLicensePlate")
                        }
                        onBlur={(evt) => {
                          let invalid : Boolean = false;

                          if (
                            !isLicensePlateAutoValid(evt.target.value ?? "") &&
                            !isLicensePlateMotoValid(evt.target.value ?? "")
                          ) {
                            invalid = true;
                          }

                          /*
                          if(this.vehicle === "auto" && !isLicensePlateAutoValid(evt.target.value ?? "")){
                            invalid = true;
                          }
                          else if(this.vehicle === "moto" && !(isLicensePlateMotoValid(evt.target.value ?? ""))){
                            invalid = true;
                          }
                          */
                          
                          if(invalid){
                            this.setState({
                              errors: this.state.errors.includes("cuLicensePlate")
                                ? this.state.errors
                                : [...this.state.errors, "cuLicensePlate"],
                              errorTexts: [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "cuLicensePlate"), {field: "cuLicensePlate", msg: "Targa non valida"}]
                            });
                          }
                        }}
                        placeholder="Targa"
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("cuLicensePlate")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "cuLicensePlate") ??
                          {msg: "Targa non valida", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("cuInsuranceClass") && (
                    <div className="col-12 mb-4">
                      <label>Classe di merito (CU) di assegnazione</label>
                      <Dropdown
                        value={step3Data?.cuInsuranceClass}
                        options={this.state.insuranceClassListBersani}
                        onChange={(selected) => this.onChangeHandler(selected.value, "cuInsuranceClass")}
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("cuInsuranceClass")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "cuInsuranceClass") ??
                          {msg: "Targa non valida", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("assicurataAMioNome") && (
                    <div className="col-12 mb-4">
                      <label>
                        Hai usufruito in passato del Decreto Bersani?
                      </label>
                      <SelectButton
                        value={step3Data?.pastBersaniDecree}
                        options={[
                          { label: "Si", value: true },
                          { label: "No", value: false },
                        ]}
                        onChange={(selected) => this.onChangeHandler(selected.value, "pastBersaniDecree")}
                        unselectable={false}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("pastBersaniDecree")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "pastBersaniDecree") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}

                  <div className="col-12">
                    <Divider className="divider-fields" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h3 className="titleLev5 mb-2">Il proprietario</h3>
                  </div>
                  <div className="col-12 mb-4">
                    <label>
                      {`Il proprietario e il contraente della tua ${this.vehicle} coincidono?`}
                    </label>
                    <Dropdown
                      value={step3Data.contractorAndOwnerCoincide ?? ""}
                      options={this.state.peopleCoincideList}
                      onChange={(selected) => this.onChangeHandler(selected.value, "contractorAndOwnerCoincide")}
                      onHide={() => removeFocusClass()}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorAndOwnerCoincide")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorAndOwnerCoincide") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>

                  {this.isVisible("owner") && contractor &&
                    <RadioButtonList
                      labelType={{component: OwnerDriverListItemLabel}}
                      name="owner"
                      items={this.state.people.filter((p: IPerson) => p.id !== contractor.id)}
                      itemsAction={{
                        label: "Modifica",
                        attributes: {
                          className: "aLike",
                          onClick: (e: Event) => {
                            const safename = User.getPersonSafeName(this.props.user.getPersonById(step3Data?.ownerId))
                            this.currentPersonCardType = "owner";

                            const owner : any = this.state.people.find((p : IPerson) => p.id === step3Data?.ownerId);
                            const {errors, errorTexts} = owner;
                            
                            this.personCardRef?.current.show({
                              person: owner,
                              header: <h3 className="colorPrimaryGreen titleLev4">Dettaglio utente {safename}</h3>,
                              errors: errors,
                              errorTexts: errorTexts,
                              showControl: false,
                              editMode: true,
                              requiredFields: this.stepFields.owner,
                              hideFields: ["validDateOfBirthLabel", "email", "phone", "youngestChild"],
                              successCallback: this.updateStatePersonSave
                            });
                          }
                        },
                      }}
                      value={step3Data?.ownerId}
                      valueField="id"
                      emptyItem={{
                        label: "Inserisci un nuovo proprietario",
                        component: EmptyListItemLabel,
                        onClick: (e: Event) => {
                          this.currentPersonCardType = "owner";

                          this.personCardRef?.current.show({
                            header: <h3 className="colorPrimaryGreen titleLev4">Inserisci un nuovo proprietario</h3>,
                            errors: [],
                            errorTexts: [],
                            showControl: false,
                            editMode: true,
                            requiredFields: this.stepFields.owner,
                            hideFields: ["validDateOfBirthLabel", "email", "phone", "youngestChild"],
                            successCallback: this.updateStatePersonSave
                          });
                        }
                      }}
                      handleChange={(selected : any) => {this.onChangeHandler(selected, "ownerSelector")}}
                    />
                  }

                  <ContextFeedback
                    show={this.state.errors.includes("ownerSelector")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "ownerSelector") ??
                      {msg: "Seleziona un proprietario", severity: "error"}
                    }
                  />

                  <div className="col-12">
                    <Divider className="divider-fields" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h3 className="titleLev5 mb-2">Il conducente</h3>
                  </div>
                  <div className="col-12 mb-4">
                    <label>
                      {`Il conducente abituale e il contraente della tua ${this.vehicle} coincidono?`}
                    </label>
                    <Dropdown
                      value={step3Data.contractorAndDriverCoincide}
                      options={this.state.peopleCoincideList}
                      onChange={(selected) => this.onChangeHandler(selected.value, "contractorAndDriverCoincide")}
                      onHide={() => removeFocusClass()}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorAndDriverCoincide")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorAndDriverCoincide") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  {(step3Data?.contractorAndDriverCoincide !== undefined && step3Data?.contractorAndDriverCoincide !== "si") && 
                    <div className="col-12 mb-4">
                      <label>
                        {`Il conducente abituale e il proprietario della tua ${this.vehicle} coincidono?`}
                      </label>
                      <Dropdown
                        value={step3Data.ownerAndDriverCoincide}
                        options={ step3Data?.contractorAndOwnerCoincide === "si" && step3Data?.contractorAndDriverCoincide !== "si"
                          ? this.state.peopleCoincideList.filter((item: any) => item.value !== "si")
                          : this.state.peopleCoincideList
                        }

                        onChange={(selected) => this.onChangeHandler(selected.value, "ownerAndDriverCoincide")}
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("ownerAndDriverCoincide")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "ownerAndDriverCoincide") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  }

                  {this.isVisible("driver") && contractor &&
                    <>
                      {step3Data?.ownerAndDriverCoincide !== "si" &&
                        <RadioButtonList
                          labelType={{component: OwnerDriverListItemLabel}}
                          name="driver"
                          items={this.state.people.filter((p: IPerson) => p.id !== contractor.id && p.id !== step3Data.ownerId)}
                          itemsAction={{
                            label: "Modifica",
                            attributes: {
                              className: "aLike",
                              onClick: (e: Event) => {
                                const safename = User.getPersonSafeName(this.props.user.getPersonById(step3Data?.driverId))
                                this.currentPersonCardType = "driver";

                                const driver : any = this.state.people.find((p : IPerson) => p.id === step3Data?.driverId);
                                const {errors, errorTexts} = driver;

                                this.personCardRef?.current.show({
                                  person: driver,
                                  header: <h3 className="colorPrimaryGreen titleLev4">Dettaglio utente {safename}</h3>,
                                  error: errors,
                                  errorTexts: errorTexts,
                                  showControl: false,
                                  editMode: true,
                                  hideFields: ["validDateOfBirthLabel", "email", "phone", "youngestChild"],
                                  requiredFields: this.stepFields.driver,
                                  successCallback: this.updateStatePersonSave
                                });
                              }
                            },
                          }}
                          value={step3Data?.driverId}
                          valueField="id"
                          emptyItem={{
                            label: "Inserisci un nuovo conducente",
                            component: EmptyListItemLabel,
                            onClick: (e: Event) => {
                              this.currentPersonCardType = "driver";

                              this.personCardRef?.current.show({
                                header: <h3 className="colorPrimaryGreen titleLev4">Inserisci un nuovo conducente</h3>,
                                errors: [],
                                errorTexts: [],
                                showControl: false,
                                editMode: true,
                                requiredFields: this.stepFields.owner,
                                hideFields: ["validDateOfBirthLabel", "email", "phone", "youngestChild"],
                                successCallback: this.updateStatePersonSave
                              });
                            }
                          }}
                          handleChange={(selected : any) => {this.onChangeHandler(selected, "driverSelector")}}
                        />
                      }
                    </>
                  }

                  <ContextFeedback
                    show={this.state.errors.includes("driverSelector") && !this.state.errors.includes("contractorAndDriverCoincide")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "driverSelector") ??
                      {msg: "Seleziona un conducente", severity: "error"}
                    }
                  />

                  <div className="col-12">
                    <Divider className="divider-fields" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h3 className="titleLev5 mb-2">Altre informazioni</h3>
                  </div>
                  <div className="col-12 mb-4">
                    <label>Ci sono altri conducenti?</label>
                    <SelectButton
                      value={step3Data?.otherDriversExist}
                      options={[
                        { label: "Si", value: true },
                        { label: "No", value: false },
                      ]}
                      onChange={(selected) => this.onChangeHandler(selected.value, "otherDriversExist")}
                      className="smallBtn"
                      unselectable={false}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("otherDriversExist")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "otherDriversExist") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  {this.isVisible("youngestOtherDriverAge") && (
                    <div className="col-12 mb-4">
                      <label>Indica l'età del più giovane</label>
                      <Dropdown
                        value={step3Data?.youngestOtherDriverAge}
                        options={this.state.youngestOtherDriverAgeList}
                        onChange={(selected) => this.onChangeHandler(selected.value, "youngestOtherDriverAge")}
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("youngestOtherDriverAge")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "youngestOtherDriverAge") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("otherDriversNoviceExist") && (
                    <div>
                      <div className="col-12 mb-4">
                        <label>
                          Tra questi, vi è qualcuno patentato da meno di due
                          anni?
                        </label>
                        <SelectButton
                          value={step3Data?.otherDriversNoviceExist}
                          options={[
                            { label: "Si", value: true },
                            { label: "No", value: false },
                          ]}
                          onChange={(selected) => this.onChangeHandler(selected.value, "otherDriversNoviceExist")}
                          className="smallBtn"
                          unselectable={false}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("otherDriversNoviceExist")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "otherDriversNoviceExist") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-12 mb-4">
                    <label>
                      Ci sono familiari residenti con il proprietario e/o con il
                      conducente patentati e minori di 26 anni? Indica l'età del
                      più giovane
                    </label>
                    <Dropdown
                      value={step3Data?.youngestFamilyDriver}
                      options={this.state.youngestFamilyDriverAgeList}
                      onChange={(selected) => this.onChangeHandler(selected.value, "youngestFamilyDriver")}
                      onHide={() => removeFocusClass()}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("youngestFamilyDriver")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "youngestFamilyDriver") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label>
                      Il conducente dichiara di avere una patente italiana mai
                      sospesa da 5 anni e con 20 punti o più, non aver mai preso
                      multe per stato di ebbrezza e non aver modificato il
                      veicolo.
                    </label>
                    <SelectButton
                      value={step3Data?.contractoDeclares}
                      options={[
                        { label: "Si", value: true },
                        { label: "No", value: false },
                      ]}
                      onChange={(selected) => this.onChangeHandler(selected.value, "contractoDeclares")}
                      className="smallBtn"
                      unselectable={false}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractoDeclares")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractoDeclares") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  
                  {this.isVisible("moreQuestions") &&
                    <>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <label>Tipo di patente</label>
                          <Dropdown
                            value={step3Data?.licenseType}
                            options={this.state.licenseTypesList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "licenseType")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("licenseType")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "licenseType") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <label>Punti nella patente</label>
                          <Dropdown
                            value={step3Data?.licensePoints}
                            options={this.state.licensePointsList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "licensePoints")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("licensePoints")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "licensePoints") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <label>Multe per stato di ebbrezza</label>
                          <Dropdown
                            value={step3Data?.drunkDrivingTickets}
                            options={this.state.drunkDrivingTicketsList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "drunkDrivingTickets")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("drunkDrivingTickets")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "drunkDrivingTickets") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <label>Numero sospensioni patente</label>
                          <Dropdown
                            value={step3Data?.licenseSuspensions}
                            options={this.state.licenseSuspensionsList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "licenseSuspensions")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("licenseSuspensions")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "licenseSuspensions") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <label>Totale mesi sospensione patente</label>
                          <Dropdown
                            value={step3Data?.totalLicenseSuspensionMonths}
                            options={this.state.licenseSuspensionMonthsList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "totalLicenseSuspensionMonths")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("totalLicenseSuspensionMonths")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "totalLicenseSuspensionMonths") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                      </div>
    
                      {this.isVisible("carModifications") &&
                        <div>
                          <Divider className="divider-fields" />
                          <div className="row">
                            <div className="p-col-12">
                              <h3 className="titleLev6 mb-2">Modifiche effettuate al veicolo</h3>
                            </div>
                            <div className="p-field-checkbox p-col-12">
                              <Checkbox
                                inputId="vehicleMod1"
                                checked={step3Data?.vehicleModificationsAlloyWheels}
                                onChange={(event) => this.saveReduxState({vehicleModificationsAlloyWheels: event.checked})}
                              />
                              <label htmlFor="vehicleMod1">Cerchi in lega</label>
                            </div>
                            <div className="p-field-checkbox p-col-12">
                              <Checkbox
                                inputId="vehicleMod2"
                                checked={step3Data?.vehicleModificationsInvalidity}
                                onChange={(event) => this.saveReduxState({vehicleModificationsInvalidity: event.checked})
                                }
                              />
                              <label htmlFor="vehicleMod2">
                                Dovute ad invalidità fisica
                              </label>
                            </div>
                            <div className="p-field-checkbox p-col-12">
                              <Checkbox
                                inputId="vehicleMod3"
                                checked={step3Data?.vehicleModificationsWindowTint}
                                onChange={(event) => this.saveReduxState({vehicleModificationsWindowTint: event.checked})}
                              />
                              <label htmlFor="vehicleMod3">Finestrini scuriti</label>
                            </div>
                            <div className="p-field-checkbox p-col-12">
                              <Checkbox
                                inputId="vehicleMod4"
                                checked={step3Data?.vehicleModificationsOther}
                                onChange={(event) => this.saveReduxState({vehicleModificationsOther: event.checked})}
                              />
                              <label htmlFor="vehicleMod4">Altro</label>
                            </div>
                          </div>
                        </div>
                      }
                      {this.isVisible("motorCycleModifications") &&
                        <div>
                          <Divider className="divider-fields" />
                          <div className="row">
                            <div className="col-12">
                              <h3 className="titleLev6 mb-2">Modifiche effettuate alla moto</h3>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod1"
                                checked={step3Data?.vehicleModificationsEngineAntitheft}
                                onChange={(event) => this.saveReduxState({vehicleModificationsEngineAntitheft: event.checked})}
                              />
                              <label htmlFor="vehicleMod1">
                                Barre antiurto del motore
                              </label>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod2"
                                checked={step3Data?.vehicleModificationsExhaust}
                                onChange={(event) => this.saveReduxState({vehicleModificationsExhaust: event.checked})}
                              />
                              <label htmlFor="vehicleMod2">Modifica dello scarico</label>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod3"
                                checked={step3Data?.vehicleModificationsDecals}
                                onChange={(event) =>
                                  this.saveReduxState({vehicleModificationsDecals: event.checked})
                                }
                              />
                              <label htmlFor="vehicleMod3">
                                Carenatura aerografata
                              </label>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod3"
                                checked={step3Data?.vehicleModificationsRemovableBags}
                                onChange={(event) =>
                                  this.saveReduxState({vehicleModificationsRemovableBags: event.checked,})
                                }
                              />
                              <label htmlFor="vehicleMod3">
                                Borse laterali rimovibili
                              </label>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod4"
                                checked={step3Data?.vehicleModificationsOther}
                                onChange={(event) =>
                                  this.saveReduxState({
                                    vehicleModificationsOther: event.checked,
                                  })
                                }
                              />
                              <label htmlFor="vehicleMod4">Altro</label>
                            </div>
                            <Divider className="divider-fields" />
                          </div>
                        </div>
                      }
                    </>
                  }

                  {this.isVisible("satelliteTracker") && this.vehicle === "auto" &&
                    <div className="col-12 mb-4">
                      <label>
                        Desideri visualizzare anche offerte di prodotti
                        satellitari?
                      </label>
                      <SelectButton
                        value={step3Data?.includeSatProducts}
                        options={[
                          { label: "Si", value: true },
                          { label: "No", value: false },
                        ]}
                        onChange={(selected) => this.onChangeHandler(selected.value, "includeSatProducts")}
                        className="smallBtn"
                        unselectable={false}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("includeSatProducts")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "includeSatProducts") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  }

                  {Constants.ENABLE_NO_CARD &&
                    <div className="col-12 mb-4">
                      <label>
                        Vuoi visualizzare anche i risultati di compagnie non aderenti alla CARD? Ti indicheremo sempre quali sono CARD/noCARD.
                      </label>
                      <SelectButton
                        value={step3Data?.includeNonCard}
                        options={[
                          { label: "Si", value: true },
                          { label: "No", value: false },
                        ]}
                        onChange={(selected) => this.onChangeHandler(selected.value, "includeNonCard")}
                        className="smallBtn"
                        unselectable={false}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("includeNonCard")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "includeNonCard") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  }

                  {this.isVisible("vehicleIsMotorCycle") &&
                    <>
                      <div className="col-12 mb-4">
                        <label>
                          Qual è la cilindrata più alta che hai guidato sino ad
                          oggi?
                        </label>
                        <Dropdown
                          value={step3Data?.highestDisplacement}
                          options={this.state.highestDisplacementList}
                          onChange={(selected) => this.onChangeHandler(selected.value, "highestDisplacement")}
                          onHide={() => removeFocusClass()}
                          optionLabel="label"
                          placeholder="Seleziona"
                          emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("highestDisplacement")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "highestDisplacement") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                      
                      <div className="col-12 mb-4">
                        <label>
                          Da quanti mesi consecutivi guidi il tuo veicolo?
                        </label>
                        <Dropdown
                          value={step3Data?.consecutiveDrivingMonths}
                          options={this.state.consecutiveDrivingMonthsList}
                          onChange={(selected) => this.onChangeHandler(selected.value, "consecutiveDrivingMonths")}
                          onHide={() => removeFocusClass()}
                          optionLabel="label"
                          placeholder="Seleziona"
                          emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("consecutiveDrivingMonths")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "consecutiveDrivingMonths") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                    </>
                  }
                </div>

                <Divider className="divider-fields" />
                <div className="registrationPrivacy">
                  {/* inizio privacy loggato */}
                  <div className="row">
                    <div className="col-12">
                      <h3 className="titleLev5 mb-2">Informativa IVASS</h3>
                    </div>

                    {/* inizio IVASS  */}
                    <div className="p-field-checkbox col-12">
                      <Checkbox
                        inputId="privacySeiSicuro"
                        onChange={(selected) => this.onChangeHandler(selected.checked, "acceptIvass106")}
                        checked={step3Data.acceptIvass106 ?? false}
                      />
                      <label htmlFor="privacySeiSicuro">
                        Dichiaro di aver letto l'informativa precontrattuale di cui alla normativa vigente ai sensi del d.lgs. 209/2005 
                          (Codice delle Assicurazioni Private) e leggi e regolamenti collegati e di averne stampato o salvato copia. 
                          Clicca sui link per visualizzare i documenti Allegati&nbsp;
                          <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.6sicuro.it/wp-content/uploads/2023/10/Assiteca-Spa-Allegato-3-rev-2022_09-DEF.pdf">3</a>,&nbsp;
                          <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.6sicuro.it/wp-content/uploads/2023/10/Assiteca-Spa-Allegato-4-TER-rev-2021_03-DEF.pdf">4</a>&nbsp;
                          e&nbsp;
                          <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.6sicuro.it/wp-content/uploads/2023/10/Assiteca-Spa-Allegato-4-TER-rev-2021_03-DEF.pdf">4TER</a>. (obbligatorio)
                      </label>
                    </div>
                    <div className={"col-12" + (this.state.errors.includes("acceptIvass106") ? "mb-4" : "")}>
                      <ContextFeedback
                        show={this.state.errors.includes("acceptIvass106")}
                        message={{ msg: "Dichiarare la presa visione dell'informativa precontrattuale", severity: "error" }}
                      />
                    </div>
                    {/* fine IVASS  */}
                  </div>
                </div>

              </div>
            )}
          </div>

          <div className="step-btn-container p-fluid p-4 p-lg-5">
            <div className="row">
              <div className="col-6 button-back">
                <Button
                  className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                  type="button"
                  label="Indietro"
                  onClick={() => {
                    this.props.userInfoDataSuccess({ direction: "backwards", nextStep: 3 });
                    this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(2, this.vehicle));
                  }}
                />
              </div>
              <div className="col-6 button-next">
                <Button
                  className="customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                  type="button"
                  label="Continua"
                  onClick={() => {
                    this.props.userInfoDataSuccess({ direction: "forwards", nextStep: 4 });
                    this.handleFormSubmit();
                  }}
                  loading={this.state.loading}
                />
              </div>

              <div className="col-12">
                <CaptchaComponent 
                  isManaged={ this.state.showTurnstileManaged === true }
                  successCallBack={this.saveCaptchaToken}
                  setLoadingCallBack={this.disableFormSubmit}
                />
                <ContextFeedback
                    show={this.state.errors.includes('["captcha"]')}
                    message={
                      {msg: "Compilare il controllo anti bot", severity: "error"}
                    }
                  />
              </div>

            </div>
          </div>
        </Card>
      </div>
    );
  }

  private disableFormSubmit () {
    this.setState(
      { loading: true }
    )
  }

  /** 
   * salva il token generato dal capthca e abilita l'invio della form
   */
  private saveCaptchaToken (cToken: any) {
    this.setState ({
      turnstileToken: cToken
    }, () => {
      this.setState(
        { loading: false }
      )
    })
  }

  /**
   * Show/hide the privacy sub checkboxes.
   */
  private showMoreIvass() {
    this.setState(
      { detailIvassIsVisible: !this.state.detailIvassIsVisible },
      () => {
        if (
          this.state.detailIvassIsVisible &&
          this.privacySeiSicuroCollection !== null
        ) {
          this.privacySeiSicuroCollection.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    );
  }

  /**
   * Visibility check for the youngest child dropdown.
   *
   * @returns {boolean} whether the youngest child dropdown should be visible
   */
  private isVisiblecontractorYoungestChild() {
    let is_visible;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    if (
      step3Data?.contractorChildren === undefined ||
      step3Data.contractorChildren.includes(0)
    ) {
      is_visible = false;
    } else {
      is_visible = step3Data.contractorChildren.includes(1);
    }
    return is_visible;
  }

  /**
   * Visibility check for the bersani decree dropdown.
   *
   * @returns {boolean} whether the bersani decree dropdown should be visible
   */
  private isVisibleBersaniDecree() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return (
      step3Data?.vehicleInsuranceStatus === "aMioNomePrimaVoltaNuovo" ||
      step3Data?.vehicleInsuranceStatus === "aMioNomePrimaVoltaUsato"
    );
  }

  /**
   * Visibility check for the "assicurata a mio nome" form part.
   *
   * @returns whether the "assicurata a mio nome" form part should be visible
   */
  private isVisibleAssicurataAMioNome() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return step3Data?.vehicleInsuranceStatus === "assicurataAMioNome";
  }

  /**
   * Visibility check for the "years in class 1".
   *
   * @returns {boolean} whether the "years in class 1" dropdown should be visible
   */
  private isVisibleYearsInClass1List() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return (
      step3Data?.currentInsuranceCreditClass !== undefined &&
      this.state.insuranceStatusList !== undefined &&
      this.state.insuranceStatusList.length > 0 &&
      step3Data?.currentInsuranceCreditClass === "c1DaOltreUnAnno" &&
      step3Data?.vehicleInsuranceStatus === this.state.insuranceStatusList[0].value
    );
  }

  /**
   * Visibility check for the cu license form part.
   *
   * @returns {boolean} whether the cu license form part should be visible
   */
  private isVisibleCuLicense() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return (
      step3Data?.vehicleInsuranceStatus !== "assicurataAMioNome" &&
      (step3Data?.bersaniDecree === "siVeicoloDelloStessoProprietario" ||
      step3Data?.bersaniDecree === "SiVeicoloDiFamiliareConvivente")
    );
  }

  /**
   * Visibility check of the "owner and driver coincide" dropdown.
   *
   * @returns {boolean} whether "owner and driver coincide" dropdown should be visible
   */
  private isVisibleOwnerAndDriverCoincide() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;
    if (step3Data?.contractorAndDriverCoincide === undefined &&
      step3Data?.contractorAndOwnerCoincide === undefined
    ) {
      is_visible = false;
    }
    else {
      is_visible = step3Data?.contractorAndDriverCoincide !== "si" && step3Data?.contractorAndOwnerCoincide !== "si";
    }
    return is_visible;
  }

  /**
   * Visibility check for the "youngest other driver age" dropdown.
   *
   * @returns {boolean} whether the "youngest other driver age" dropdown should be visible
   */
  private isVisibleYoungestOtherDriverAge() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return step3Data?.otherDriversExist ?? false;
  }

  /**
   * Visibility check for the "more questions" (i.e. license points, license suspensions, etc) form part.
   *
   * @returns {boolean} whether the "more questions" (i.e. license points, license suspensions, etc) form part should be visible
   */
  private isVisibleMoreQuestions() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;
    if (step3Data.contractoDeclares === undefined) {
      is_visible = false;
    } else {
      is_visible = step3Data.contractoDeclares ? false : true;
    }
    return is_visible;
  }

  /**
   * Visibility check for the owner form part
   *
   * @returns {boolean} whether the owner form part should be visible
   */
  private isVisibleOwner() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;
    if (step3Data?.contractorAndOwnerCoincide === undefined) {
      is_visible = false;
    } else {
      is_visible = step3Data?.contractorAndOwnerCoincide !== "si" ? true : false;
    }
    return is_visible;
  }

  /**
   * Visibility check for the driver form part
   *
   * @returns {boolean} whether the driver form part should be visible
   */
  private isVisibleDriver() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;
    if (step3Data?.ownerAndDriverCoincide === undefined) {
      is_visible = false;
    } else {
      is_visible =
        (!this.isVisibleOwnerAndDriverCoincide() &&
          step3Data?.contractorAndDriverCoincide !== "si") ||
        (step3Data?.ownerAndDriverCoincide !== "si" &&
          step3Data?.contractorAndDriverCoincide !== "si")
          ? true
          : false;
    }
    return is_visible;
  }

  /**
   * Check if current field is viewable.
   *
   * @param {string} current_field The current field
   *
   * @returns {boolean} Visibility check
   */
  private isVisible(current_field: string): boolean {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible: boolean;

    switch (current_field) {
      case "ownerFilled":
        is_visible =
          step3Data?.contractorName !== undefined &&
          step3Data?.contractorSurname !== undefined &&
          step3Data?.contractorCityOfResidence !== undefined &&
          step3Data?.contractorPostalCode !== undefined &&
          step3Data?.contractorAddress !== undefined &&
          step3Data?.contractorChildren !== undefined &&
          step3Data?.contractorEmail !== undefined &&
          step3Data?.contractorPhoneNumber !== undefined &&
          step3Data?.contractorItalianDrivingLicense !== undefined &&
          step3Data?.contractorDrivingLicenseAge !== undefined &&
          step3Data?.contractorPreferredPaymentMethod !== undefined;
        break;
      case "contractorYoungestChild":
        is_visible = this.isVisiblecontractorYoungestChild();
        break;
      case "bersaniDecree":
        is_visible = this.isVisibleBersaniDecree();
        break;
      case "assicurataAMioNome":
        is_visible = this.isVisibleAssicurataAMioNome();
        break;
      case "accidentDetails":
        is_visible =
          this.isVisibleAssicurataAMioNome() &&
          step3Data?.pastAccidentCount !== undefined
            ? step3Data?.pastAccidentCount > 0
            : false;
        break;
      case "yearsInClass1List":
        is_visible = this.isVisibleYearsInClass1List();
        break;
      case "cuLicensePlate":
      case "cuInsuranceClass":
        is_visible = this.isVisibleCuLicense();
        break;
      case "ownerAndDriverCoincide":
        is_visible = this.isVisibleOwnerAndDriverCoincide();
        break;
      case "youngestOtherDriverAge":
      case "otherDriversNoviceExist":
        is_visible = this.isVisibleYoungestOtherDriverAge();
        break;
      case "moreQuestions":
        is_visible = this.isVisibleMoreQuestions();
        break;
      case "owner":
        is_visible = this.isVisibleOwner();
        break;
      case "driver":
        is_visible = this.isVisibleDriver();
        break;
      case "carModifications":
        is_visible = this.isVisibleMoreQuestions() && this.vehicle === "auto";
        break;
      case "motorCycleModifications":
        is_visible = this.isVisibleMoreQuestions() && this.vehicle === "moto";
        break;
      case "satelliteTracker":
        is_visible = this.vehicle === "auto";
        break;
      case "vehicleIsMotorCycle":
        is_visible = this.vehicle === "moto";
        break;
      default:
        // is_visible = false;
        is_visible = true;
        break;
    }

    return is_visible;
  }


  private getContractor(id: string | number | undefined): IPerson | undefined{
    return this.state.people.find((p: IPerson) => p.id === id)
  }

  /**
   * Handle the DropDown changes evaluating condition to fetch choices.
   *
   * @param selected The selected object
   * @param componentName The name of the component tp handle
   */
  private onChangeHandler(selected: any, componentName: string) {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;

    switch (componentName) {
      case "contractorAndOwnerCoincide":
        let dataToDispatch2: Step3Data = {
          contractorAndOwnerCoincide: selected
        };

        if(selected === "si"){
          dataToDispatch2.ownerId = step1Data.contractorId;
          dataToDispatch2.owner = this.getContractor(step1Data.contractorId);

          if(step3Data.ownerAndDriverCoincide === "si"){ //se il proprietario coincide con il conducente
            dataToDispatch2.driverId = step1Data.contractorId;
            dataToDispatch2.driver = dataToDispatch2.owner;
            dataToDispatch2.ownerAndDriverCoincide = undefined;
            dataToDispatch2.contractorAndDriverCoincide = "si";
          }
        }
        else{
          if(step3Data.contractorAndOwnerCoincide === "si"){  // se prima coincidevano
            dataToDispatch2.ownerId = undefined;
            dataToDispatch2.owner = undefined;
          }

          if(step3Data.ownerAndDriverCoincide === "si"){ //se il proprietario coincide con il conducente
            dataToDispatch2.driverId = undefined;
            dataToDispatch2.driver = undefined;
          }
        }
        this.saveReduxState(this.reduxPreRefineData(dataToDispatch2))
        break;
      case "ownerSelector":
        if(step3Data.ownerId !== selected){
          if (selected !== -1) {
            let ownerUpdateState: Step3Data;
            const id : string = selected;
            const owner: IPerson = {...BlankPerson, ...(this.state.people.find(p => p.id === id) || {})};
            if(owner){
              ownerUpdateState = {
                ownerId: id,
                owner: owner,
                ownerAndDriverCoincide: undefined,
                driverId: undefined,
                driver: undefined
              };
  
              this.saveReduxState(this.reduxPreRefineData(ownerUpdateState))
            }
          }
        }
        break;
      case "contractorAndDriverCoincide":
        let dataToDispatch1: Step3Data = {
          contractorAndDriverCoincide: selected,
          driverId: selected === "si"
            ? step1Data.contractorId
            : step3Data.contractorAndDriverCoincide !== "si"
              ? step3Data.driverId
              : undefined,
          driver: selected === "si"
            ? this.getContractor(step1Data.contractorId)
            : step3Data.contractorAndDriverCoincide !== "si"
              ? step3Data.driver
              : {}
        };

        this.saveReduxState(this.reduxPreRefineData(dataToDispatch1))
        break;
      case "ownerAndDriverCoincide":
        let dataToDispatch3: Step3Data = {
          ownerAndDriverCoincide: selected,
          driverId: selected === "si"
            ? step3Data.ownerId
            : step3Data.ownerAndDriverCoincide !== "si"
              ? step3Data.driverId
              : undefined,
          driver: selected === "si"
            ? step3Data.owner
            : step3Data.ownerAndDriverCoincide !== "si"
              ? step3Data.driver
              : {}
        }

        this.saveReduxState(this.reduxPreRefineData(dataToDispatch3))
        break;
      case "driverSelector":
        if(step3Data.driverId !== selected){
          if (selected !== -1) {
            let driverUpdateState: object;
            const id : string = selected;
            const driver: IPerson = {...BlankPerson, ...(this.state.people.find(p => p.id === id) || {})};

            if(driver){
              driverUpdateState = {
                driverId: id,
                driver: driver
              };
              this.saveReduxState(this.reduxPreRefineData(driverUpdateState))
            }
          }
        }

        break;
      case "pastAccidentCount":
        let pastAccidentsInfo = step3Data.pastAccidentsInfo as IAccidentInfo[] ?? [];
        let dataAccidentToDispact: Step3Data = {};
        if (selected !== undefined && pastAccidentsInfo.length > selected) {
          dataAccidentToDispact = {
            pastAccidentCount: selected.toString(),
            pastAccidentsInfo: pastAccidentsInfo.splice(0, selected)
          }
        }
        else if (selected !== undefined && pastAccidentsInfo.length < selected) {
          const length: number = pastAccidentsInfo.length;
          for (let i = 0; i < selected - length; i++) {
            pastAccidentsInfo.push({
              year: undefined,
              responsibility: undefined,
            });
          }
          dataAccidentToDispact = {
            pastAccidentCount: selected.toString(),
            pastAccidentsInfo: pastAccidentsInfo
          }
        }
        else if (selected === 0 || selected === "0") {
          dataAccidentToDispact = {
            pastAccidentCount: 0,
            pastAccidentsInfo: []
          }
        }

        if(JSON.stringify(dataAccidentToDispact) !== "{}"){
          this.saveReduxState(dataAccidentToDispact)
        }
        break;
      case "accidentDetails":
      case "pastAccidentYear0":
      case "pastAccidentRespondibility0":
      case "pastAccidentYear1":
      case "pastAccidentRespondibility1":
      case "pastAccidentYear2":
      case "pastAccidentRespondibility2":
      case "pastAccidentYear3":
      case "pastAccidentRespondibility3":
      case "pastAccidentYear4":
      case "pastAccidentRespondibility4":
      case "pastAccidentYear5":
      case "pastAccidentRespondibility5":
      case "pastAccidentYear6":
      case "pastAccidentRespondibility6":
      case "pastAccidentYear7":
      case "pastAccidentRespondibility7":
      case "pastAccidentYear8":
      case "pastAccidentRespondibility8":
      case "pastAccidentYear9":
      case "pastAccidentRespondibility9":
      case "pastAccidentYear10":
      case "pastAccidentRespondibility10":
      case "pastAccidentYear11":
      case "pastAccidentRespondibility11":
      case "pastAccidentYear12":
      case "pastAccidentRespondibility12":
      case "pastAccidentYear13":
      case "pastAccidentRespondibility13":
      case "pastAccidentYear14":
      case "pastAccidentRespondibility14":
        let newDetails: any = step3Data.pastAccidentsInfo;
        newDetails[selected.index][selected.field] = selected.value;

        this.saveReduxState({ pastAccidentsInfo: newDetails })
        break;

      case "acceptSeiSicuroPrivacyPolicy":
        this.saveReduxState({ acceptSeiSicuroPrivacyPolicy: selected })
        break;

      default:
        this.saveReduxState(this.reduxPreRefineData({ ...step3Data, [componentName]: selected }))
        break;
    }
    this.resetValidation(componentName);
  }

  /**
   * Reset element validation
   * @param element
   * @returns
   */
  resetValidation(element: any) {

    const ownerFields : string[] = ["contractorAndOwnerCoincide", "ownerSelector"];
    const driverFields : string[] = ["contractorAndDriverCoincide", "ownerAndDriverCoincide", "driverSelector"];
    if(ownerFields.includes(element)){
      element = "ownerSelector";
    }
    else if(driverFields.includes(element)){
      element = "driverSelector";
    }

    this.setState({
      errors: this.state.errors.filter((item: any) => item !== element),
    });
    return;
  }
  

  /**
   * Form validation.
   *
   * @param specificField whether to check validity of a specific field
   * @returns {boolean} Validation check
   */
  private validation(specificField?: any): Promise<IValidationResponse> {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    let validation: IValidationResponse = {
      status: true,
      error_fields: [],
      error_messages: [],
    };
    let promises : Promise<IValidationResponse>[] = [];

    const contractor : IPerson = {
      name: step3Data?.contractorName,
      surname: step3Data?.contractorSurname,
      address: step3Data?.contractorAddress,
      addressNumber: step3Data?.contractorAddressNumber,
      cityOfResidence: step3Data?.contractorCityOfResidence,
      postalCodeOfResidence: step3Data?.contractorPostalCode,
      children: step3Data?.contractorChildren,
      youngestChild: step3Data?.contractorYoungestChild,
      email: step3Data?.contractorEmail,
      phone: step3Data?.contractorPhoneNumber,
      italianDrivingLicense: step3Data?.contractorItalianDrivingLicense,
      drivingLicenseAge: step3Data?.contractorDrivingLicenseAge,
    }
    
    promises.push(User.validatePerson (contractor, Object.entries(this.stepFields.contractor).map(([, value]) => value) as IFieldDescriptor[], "contractor")
      .then((result: IValidationResponse) => {
        let wrappedValidation : IValidationResponse;
        
        const {status, error_fields, error_messages} = result;
        if(!status){

          let errorFieldsList : string[] = error_fields.map((field: string) => {
            let error : IFeedbackText | undefined = error_messages.find((item: IFeedbackText) => item.field === field && item.msg)
            let errorMsg : string;
            if(error === undefined){
              errorMsg = field
            }
            else{
              errorMsg = Array.isArray(error.msg) ? error.msg.join("; ") : error.msg as string
            }
            return errorMsg;
          })

          wrappedValidation = {
            status: false,
            error_fields: ["contractorRecap"],
            error_messages: [{field: "contractorRecap", msg: `Informazioni mancanti, inserisci i seguenti dati: ${errorFieldsList.join(";")}`}]
          }
        }
        else{
          wrappedValidation = result;
        }
        return wrappedValidation
      })
    );

    if(step3Data?.contractorPreferredPaymentMethod === undefined ||
      step3Data.contractorPreferredPaymentMethod === "" ||
      !(this.state?.paymentMethodsList === undefined || (this.state.paymentMethodsList.find(item => item.value === step3Data.contractorPreferredPaymentMethod) ?? false))
    ){
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["contractorPreferredPaymentMethod"],
        error_messages: [{field: "contractorPreferredPaymentMethod", msg: "Seleziona un metodo di pagamento"}]
      }));
    }

    if (this.isVisible("ownerFilled")) {

      if(step3Data?.newInsuranceStart){
        const startData: Date = new Date(step3Data.newInsuranceStart);
        const now: Date = new Date();
        let error: boolean = false;
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        
        if(startData instanceof Date && !isNaN(startData.getTime())){
          if(startData < now){
            error = true;
          }
        }
        else{
          error = true;
        }

        if(error){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["newInsuranceStart"],
            error_messages: [{field: "newInsuranceStart", msg: "Seleziona una data valida"}]
          }));
        }
      }
      else{
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["newInsuranceStart"],
          error_messages: [{field: "newInsuranceStart", msg: "Seleziona una data valida"}]
        }));
      }

      if(step3Data?.vehicleInsuranceStatus === undefined ||
        step3Data.vehicleInsuranceStatus === "" ||
        !(this.state.insuranceStatusList === undefined || (this.state?.insuranceStatusList.find(item => item.value === step3Data.vehicleInsuranceStatus) ?? false))
      ){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["vehicleInsuranceStatus"],
          error_messages: [{field: "vehicleInsuranceStatus", msg: "Seleziona un'opzione"}]
        }));
      }

      if (this.isVisibleYearsInClass1List()) {
        if(step3Data?.yearsInClass1 === undefined ||
          !(this.state.yearsInClass1List === undefined || (this.state?.yearsInClass1List.find(item => item.value === step3Data.yearsInClass1) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["yearsInClass1"],
            error_messages: [{field: "yearsInClass1", msg: "Seleziona un'opzione'"}]
          }));
        }
      }

      if (this.isVisibleAssicurataAMioNome()) {

        if(step3Data?.currentInsuranceCreditClass === undefined ||
          !(this.state.insuranceClassList === undefined || (this.state?.insuranceClassList.find(item => item.value === step3Data.currentInsuranceCreditClass) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["currentInsuranceCreditClass"],
            error_messages: [{field: "currentInsuranceCreditClass", msg: "Seleziona un'opzione"}]
          }));
        }

        if(step3Data?.currentInsuranceCompany === undefined ||
          !(this.state.insuranceCompaniesList === undefined || (this.state?.insuranceCompaniesList.find(item => item.value === step3Data.currentInsuranceCompany) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["currentInsuranceCompany"],
            error_messages: [{field: "currentInsuranceCompany", msg: "Seleziona un'opzione"}]
          }));
        }

        if(step3Data?.currentInsuranceYears === undefined ||
          !(this.state.yearsInsuredList === undefined || (this.state?.yearsInsuredList.find(item => item.value === step3Data.currentInsuranceYears) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["currentInsuranceYears"],
            error_messages: [{field: "currentInsuranceYears", msg: "Seleziona un'opzione"}]
          }));
        }

        if(step3Data?.pastAccidentCount === undefined ||
          !(this.state.pastAccidentCountList === undefined || (this.state?.pastAccidentCountList.find(item => item.value === step3Data.pastAccidentCount?.toString()) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["pastAccidentCount"],
            error_messages: [{field: "pastAccidentCount", msg: "Seleziona un'opzione"}]
          }));
        }

        if(step3Data?.pastBersaniDecree === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["pastBersaniDecree"],
            error_messages: [{field: "pastBersaniDecree", msg: "Seleziona un'opzione"}]
          }));
        }
      }

      if (this.isVisibleBersaniDecree()) {
        if(step3Data?.bersaniDecree === undefined ||
          !(this.state.bersaniDecreeStatusList === undefined || (this.state?.bersaniDecreeStatusList.find(item => item.value === step3Data.bersaniDecree) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["bersaniDecree"],
            error_messages: [{field: "bersaniDecree", msg: "Seleziona un'opzione"}]
          }));
        }
      }

      if (this.isVisibleCuLicense()) {

        // superamento bersani
        if (
          !isLicensePlateAutoValid(step3Data.cuLicensePlate ?? "") &&
          !isLicensePlateMotoValid(step3Data.cuLicensePlate ?? "")
        ) {
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["cuLicensePlate"],
            error_messages: [{ field: "cuLicensePlate", msg: "Targa non valida" }]
          }));
        }

        /*
        if(this.vehicle === "auto"){
          if(!isLicensePlateAutoValid(step3Data?.cuLicensePlate ?? "")){
            promises.push(Promise.resolve({
              status: false,
              error_fields: ["cuLicensePlate"],
              error_messages: [{field: "cuLicensePlate", msg: "Targa non valida"}]
            }));
          }
        }
        if(this.vehicle === "moto"){
          if(!(isLicensePlateMotoValid(step3Data?.cuLicensePlate ?? ""))){
            promises.push(Promise.resolve({
              status: false,
              error_fields: ["cuLicensePlate"],
              error_messages: [{field: "cuLicensePlate", msg: "Targa non valida"}]
            }));
          }
        }
        */

        if(step3Data?.cuInsuranceClass === undefined ||
          step3Data?.cuInsuranceClass === "" ||
          !(this.state.insuranceClassListBersani === undefined || (this.state?.insuranceClassListBersani.find(item => item.value === step3Data.cuInsuranceClass) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["cuInsuranceClass"],
            error_messages: [{field: "cuInsuranceClass", msg: "Targa non valida"}]
          }));
        }
      }


      if(!step3Data?.contractorAndOwnerCoincide){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["contractorAndOwnerCoincide"],
          error_messages: [{field: "contractorAndOwnerCoincide", msg: "Seleziona un'opzione"}]
        }));
      }

      if(!step3Data?.contractorAndDriverCoincide){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["contractorAndDriverCoincide"],
          error_messages: [{field: "contractorAndDriverCoincide", msg: "Seleziona un'opzione"}]
        }));
      }

      if (this.isVisibleOwnerAndDriverCoincide()) {
        if(!step3Data?.contractorAndDriverCoincide){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["ownerAndDriverCoincide"],
            error_messages: [{field: "ownerAndDriverCoincide", msg: "Seleziona un'opzione"}]
          }));
        }
      }

      if(step3Data?.otherDriversExist === undefined){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["otherDriversExist"],
          error_messages: [{field: "otherDriversExist", msg: "Campo obbligatorio"}]
        }));
      }

      if (this.isVisibleYoungestOtherDriverAge()) {
        if(step3Data?.youngestOtherDriverAge === undefined ||
          step3Data?.youngestOtherDriverAge === "" ||
          !(this.state.youngestOtherDriverAgeList === undefined || (this.state?.youngestOtherDriverAgeList.find(item => item.value === step3Data.youngestOtherDriverAge) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["youngestOtherDriverAge"],
            error_messages: [{field: "youngestOtherDriverAge", msg: "Targa non valida"}]
          }));
        }

        if(step3Data?.otherDriversNoviceExist === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["otherDriversNoviceExist"],
            error_messages: [{field: "otherDriversNoviceExist", msg: "Campo obbligatorio"}]
          }));
        }
      }

      if(step3Data?.youngestFamilyDriver === undefined ||
        step3Data?.youngestFamilyDriver === "" ||
        !(this.state.youngestFamilyDriverAgeList === undefined || (this.state?.youngestFamilyDriverAgeList.find(item => item.value === step3Data.youngestFamilyDriver) ?? false))
      ){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["youngestFamilyDriver"],
          error_messages: [{field: "youngestFamilyDriver", msg: "Campo obbligatorio"}]
        }));
      }

      if(step3Data?.contractoDeclares === undefined){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["contractoDeclares"],
          error_messages: [{field: "contractoDeclares", msg: "Campo obbligatorio"}]
        }));
      }

      if (this.isVisible("satelliteTracker")) {
        if(step3Data?.includeSatProducts === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["includeSatProducts"],
            error_messages: [{field: "includeSatProducts", msg: "Campo obbligatorio"}]
          }));
        }
      }

      if(step3Data?.includeNonCard === undefined){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["includeNonCard"],
          error_messages: [{field: "includeNonCard", msg: "Campo obbligatorio"}]
        }));
      }
      
      if (this.isVisibleMoreQuestions()) {

        if(step3Data?.licenseType === undefined ||
          step3Data?.licenseType === "" ||
          !(this.state.licenseTypesList === undefined || (this.state?.licenseTypesList.find(item => item.value === step3Data.licenseType) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["licenseType"],
            error_messages: [{field: "licenseType", msg: "Campo obbligatorio"}]
          }));
        }

        if(step3Data?.licensePoints === undefined ||
          step3Data?.licensePoints === "" ||
          !(this.state.licensePointsList === undefined || (this.state?.licensePointsList.find(item => item.value === step3Data.licensePoints) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["licensePoints"],
            error_messages: [{field: "licensePoints", msg: "Campo obbligatorio"}]
          }));
        }

        if(step3Data?.drunkDrivingTickets === undefined ||
          !(this.state.drunkDrivingTicketsList === undefined || (this.state?.drunkDrivingTicketsList.find(item => item.value === step3Data.drunkDrivingTickets) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["drunkDrivingTickets"],
            error_messages: [{field: "drunkDrivingTickets", msg: "Campo obbligatorio"}]
          }));
        }

        if(step3Data?.licenseSuspensions === undefined ||
          !(this.state.licenseSuspensionsList === undefined || (this.state?.licenseSuspensionsList.find(item => item.value === step3Data.licenseSuspensions) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["licenseSuspensions"],
            error_messages: [{field: "licenseSuspensions", msg: "Campo obbligatorio"}]
          }));
        }

        if(step3Data?.totalLicenseSuspensionMonths === undefined ||
          !(this.state.licenseSuspensionMonthsList === undefined || (this.state?.licenseSuspensionMonthsList.find(item => item.value === step3Data.totalLicenseSuspensionMonths) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["totalLicenseSuspensionMonths"],
            error_messages: [{field: "totalLicenseSuspensionMonths", msg: "Campo obbligatorio"}]
          }));
        }
      }

      if (this.isVisibleOwner()) {
        // validazione proprietario
        if(step3Data.owner){
          const owner: IPerson = step3Data.owner;
          promises.push(User.validatePerson(
            owner,
            Object.entries(this.stepFields.owner).map(([, value]) => value) as IFieldDescriptor[]
          )
            .then((result: IValidationResponse) => {
              let wrappedValidation : IValidationResponse;
  
              const {status, error_fields, error_messages} = result;
  
              if(!status){
  
                let errorFieldsList : string[] = error_fields.map((field: string) => {
                  let error : IFeedbackText | undefined = error_messages.find((item: IFeedbackText) => item.field === field && item.msg)
                  let errorMsg : string;
                  if(error === undefined){
                    errorMsg = field
                  }
                  else{
                    errorMsg = Array.isArray(error.msg) ? error.msg.join("; ") : error.msg as string
                  }
                  return errorMsg;
                })
  
                wrappedValidation = {
                  status: false,
                  error_fields: ["ownerSelector"],
                  error_messages: [{field: "ownerSelector", msg: `Informazioni mancanti, inserisci i seguenti dati: ${errorFieldsList.join(";")}`}]
                }
                
              }
              else{
                wrappedValidation = {
                  status: result.status,
                  error_fields: result.error_fields.map((field: string) => `${field}Driver`),
                  error_messages: result.error_messages.map((feedback: IFeedbackText) => {
                    return {...feedback, field: `${feedback.field}Driver`}
                  })
                }
              }
              return wrappedValidation
            })
          );
        }
        else{
          // errore
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["ownerSelector"],
            error_messages: [{field: "ownerSelector", msg: "Seleziona il proprietario del veicolo"}]
          }));
        }
      }

      if (this.isVisibleDriver()) {
        // validazione conducente
        if(step3Data.driver){
          const driver: IPerson = step3Data.driver;

          promises.push(User.validatePerson(driver, Object.entries(this.stepFields.driver).map(([, value]) => value) as IFieldDescriptor[])
            .then((result: IValidationResponse) => {
              let wrappedValidation : IValidationResponse;
  
              const {status, error_fields, error_messages} = result;
  
              if(!status){
  
                let errorFieldsList : string[] = error_fields.map((field: string) => {
                  let error : IFeedbackText | undefined = error_messages.find((item: IFeedbackText) => item.field === field && item.msg)
                  let errorMsg : string;
                  if(error === undefined){
                    errorMsg = field
                  }
                  else{
                    errorMsg = Array.isArray(error.msg) ? error.msg.join("; ") : error.msg as string
                  }
                  return errorMsg;
                })
  
                wrappedValidation = {
                  status: false,
                  error_fields: ["driverSelector"],
                  error_messages: [{field: "driverSelector", msg: `Informazioni mancanti, inserisci i seguenti dati: ${errorFieldsList.join(";")}`}]
                }
                
              }
              else{
                wrappedValidation = {
                  status: result.status,
                  error_fields: result.error_fields.map((field: string) => `${field}Driver`),
                  error_messages: result.error_messages.map((feedback: IFeedbackText) => {
                    return {...feedback, field: `${feedback.field}Driver`}
                  })
                }
              }
              return wrappedValidation
            })
          );
        }
        else{
          // errore
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["driverSelector"],
            error_messages: [{field: "driverSelector", msg: "Seleziona il conducente del veicolo"}]
          }));
        }
      }

    }

    if (this.isVisible("vehicleIsMotorCycle")) {

      if(step3Data?.highestDisplacement === undefined ||
        step3Data?.highestDisplacement === "" ||
        !(this.state.highestDisplacementList === undefined || (this.state?.highestDisplacementList.find(item => item.value === step3Data.highestDisplacement) ?? false))
      ){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["highestDisplacement"],
          error_messages: [{field: "highestDisplacement", msg: "Campo obbligatorio"}]
        }));
      }

      if(step3Data?.consecutiveDrivingMonths === undefined ||
        step3Data?.consecutiveDrivingMonths === "" ||
        !(this.state.consecutiveDrivingMonthsList === undefined || (this.state?.consecutiveDrivingMonthsList.find(item => item.value === step3Data.consecutiveDrivingMonths) ?? false))
      ){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["consecutiveDrivingMonths"],
          error_messages: [{field: "consecutiveDrivingMonths", msg: "Campo obbligatorio"}]
        }));
      }
      
    }

    if (!step3Data?.acceptSeiSicuroPrivacyPolicy) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["acceptSeiSicuroPrivacyPolicy"],
        error_messages: [{field: "acceptSeiSicuroPrivacyPolicy", msg: "Campo necessario"}]
      }));
    }

    if (!step3Data?.acceptIvass106) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["acceptIvass106"],
        error_messages: [{ field: "acceptIvass106", msg: "Campo obbligatorio" }]
      }));
    }

    if (!step3Data?.acceptCgu105) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["acceptCgu105"],
        error_messages: [{ field: "acceptCgu105", msg: "Campo necessario" }]
      }));
    }

    if (this.isVisible("accidentDetails")) {

      step3Data.pastAccidentsInfo?.forEach((item, i) => {
        if(item.year === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: [`pastAccidentYear${i}`],
            error_messages: [{field: `pastAccidentYear${i}`, msg: "Campo obbligatorio"}]
          }));
        }
        if(item.responsibility === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: [`pastAccidentRespondibility${i}`],
            error_messages: [{field: `pastAccidentRespondibility${i}`, msg: "Campo obbligatorio"}]
          }));
        }
      })

    }

    return Promise.allSettled(promises)
      .then((results) => {
        results.forEach((fieldResponse) => {
          if (fieldResponse.status === "fulfilled") {
            const {status, error_fields, error_messages} = fieldResponse.value;
            
            validation.status = validation.status && status;

            error_fields.forEach((item: string) => {
              if(!validation.error_fields.includes(item)){
                validation.error_fields.push(item)
              }
            })

            error_messages.forEach((item: IFeedbackText) => {
              validation.error_messages = validation.error_messages.filter((storedText: IFeedbackText) => storedText.field !== item.field);
              validation.error_messages.push(item);
            })
          }
        })
        return validation;
      });
  }

  private reduxPreRefineData(bodyData: any): Step3Data{

    let refined = {...bodyData};
    // if(refined?.newInsuranceStart){
    //   refined.newInsuranceStart = (refined.newInsuranceStart as Date)?.getTime()
    // }

    refined.currentInsuranceCreditClassLabel = this.state.insuranceClassList?.find(
      (element: any) =>
        element.value === bodyData.currentInsuranceCreditClass
    )?.label

    return (refined as Step3Data);
  }

  /**
   * Saves the current internal component state to Redux.
   */
  private saveReduxState(bodyData: Step3Data) {
    this.props.step3DataSuccess(bodyData, this.vehicle);
  }

  private formSubmit() {
    let formData = this.setFormData();
    let formValidationData = new URLSearchParams();
    let step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    let step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    let step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    formData.forEach((value, key) => {
      let replacedKey = key.replaceAll(".", "+");
      if (value !== null) {
        formValidationData.append(replacedKey, value);
      }
    });

    formValidationData.append("idUserAP", this.props.user.id.toString());
    formValidationData.append("idContraenteAP", (step1Data?.contractorId ?? "").toString());
    formValidationData.append("idVeicoloAP", (step2Data?.vehicleId ?? "").toString());
    formValidationData.append("idProprietarioAP", (step3Data?.ownerId ?? "").toString());
    formValidationData.append("idConducenteAP", (step3Data?.driverId ?? "").toString());

    // recupero la validazione di 4 ruote
    formValidationData.append("is4RuoteValid", step2Data.is4RuoteValid
      ? `${step2Data.is4RuoteValid}`
      : "false"
    );

    // recupero il captcha
    const captchaValue = (document.getElementsByName('cf-turnstile-response')[0] as HTMLInputElement)?.value;
    if (captchaValue) {
      formValidationData.append("cToken", captchaValue);
      formValidationData.append("isHidden", (this.state.showTurnstileManaged ? "false" : "true"));
    }

    // indipendentemente da cosa mi risponderà, se lo step3 è valido.. resetto la segnalazione che il preventivo deve caricare le quotazioni da un salvataggio
    let step4Result: Step4Data = {
      quotation: [],
      noQuotation: [],
      selectedOptions: [],
      requestCounter: 1,
      warrantiesFromBo: false,
      fromMailSereno: false,
    };
    this.props.step4DataSuccess(step4Result, this.vehicle);    
    this.setState({ loading: true }, () => {
      fetch(Constants.SEISICURO_FORM_POST_ENDPOINT, {
        method: "post",
        credentials: "include",
        body: formValidationData,
        headers: {
          "Content-Type":
            'application/x-www-form-urlencoded; charset="utf-8"' /** AUTH_REDACTED */,
        },
      })
        .then((response) => {
          if (response.status === 400) {
            response
              .text()
              .then((responseText) => {
                this.setState({ errors: [responseText] }, () => this.trackSendFailure);
              })
              .catch((error) => {
                this.setState({ loading: false }, () => this.trackSendFailure);
              });
  
            ;
  
            this.setState({ loading: false });
          } else if (response.ok) {
            this.props.step3DataSuccess({ step3Ok: true }, this.vehicle);
            //console.log(response);
            fetch(Constants.SEISICURO_STEP_CHECK(3), {
              credentials: "include",
              headers: {
                /** AUTH_REDACTED */
              },
            }).then((response) => {
              response.json().then((responseJson) => {
                if (Object.keys(responseJson[0]).length > 0) {
                  let specificErrors = [];
                  let genericErrors = [];
                  let newServerSideCheckErrorInsuranceClass =
                    Constants.MISSING_FIELD_TEXT;
                  let newServerSideCheckErrorDriverAddress =
                    Constants.MISSING_FIELD_TEXT;
                  let newServerSideCheckErrorDriverDob =
                    Constants.MISSING_FIELD_TEXT;
                  let newServerSideCheckErrorDriverLicenseAge =
                    Constants.MISSING_FIELD_TEXT;
                  let newServerSideCheckErrorOwnerAddress =
                    Constants.MISSING_FIELD_TEXT;
                  let newServerSideCheckErrorOwnerDob =
                    Constants.MISSING_FIELD_TEXT;
                  let newServerSideCheckErrorOwnerLicenseAge =
                    Constants.MISSING_FIELD_TEXT;
                  let newServerSideCheckErrornewInsuranceStart =
                    Constants.MISSING_FIELD_TEXT;
                  for (let i = 0; i < Object.keys(responseJson[0]).length; i++) {
                    if (
                      responseJson[0][Object.keys(responseJson[0])[i]] !==
                      undefined
                    ) {
                      switch (Object.keys(responseJson[0])[i]) {
                        case "veicolo.targa":
                          if (
                            !step2Data.vehicleOwned &&
                            !step2Data.knowLicensePlate
                          ) {
                            // Do nothing, ignore server side validation in this case.
                          } else {
                            genericErrors.push(responseJson[0]["veicolo.targa"]);
                          }
                          break;
                        case "dettagliAssicurazione.attestatoDiRischio":
                          for (let i = 0; i < (step3Data?.pastAccidentCount ?? 0); i++) {
                            specificErrors.push(`pastAccidentYear${i}`);
                            specificErrors.push(`pastAccidentRespondibility${i}`);
                          }
                          break;
                        case "dettagliAssicurazione.classeDiMerito":
                          specificErrors.push("currentInsuranceCreditClass");
                          newServerSideCheckErrorInsuranceClass =
                            fixDirtyBackendErrorString(
                              responseJson[0][
                                "dettagliAssicurazione.classeDiMerito"
                              ]
                            );
                          break;
                        case "conducenteAbituale.residenza.indirizzo":
                          specificErrors.push("addressDriver");
                          newServerSideCheckErrorDriverAddress =
                            fixDirtyBackendErrorString(
                              responseJson[0][
                                "conducenteAbituale.residenza.indirizzo"
                              ]
                            );
                          break;
                        case "conducenteAbituale.dataDiNascita":
                          specificErrors.push("dateOfBirthDriver");
                          newServerSideCheckErrorDriverDob =
                            fixDirtyBackendErrorString(
                              responseJson[0]["conducenteAbituale.dataDiNascita"]
                            );
                          break;
                        case "conducenteAbituale.etaPatente":
                          specificErrors.push("drivingLicenseAgeDriver");
                          newServerSideCheckErrorDriverLicenseAge =
                            fixDirtyBackendErrorString(
                              responseJson[0]["conducenteAbituale.etaPatente"]
                            );
                          break;
                        case "proprietario.residenza.indirizzo":
                          specificErrors.push("addressOwner");
                          newServerSideCheckErrorOwnerAddress =
                            fixDirtyBackendErrorString(
                              responseJson[0]["proprietario.residenza.indirizzo"]
                            );
                          break;
                        case "contraente.residenza.numero":
                          specificErrors.push("contractorAddress");
                          newServerSideCheckErrorOwnerAddress =
                            fixDirtyBackendErrorString(
                              responseJson[0]["contraente.residenza.numero"]
                            );
                          break;
                        case "proprietario.dataDiNascita":
                          specificErrors.push("dateOfBirthOwner");
                          newServerSideCheckErrorOwnerDob =
                            fixDirtyBackendErrorString(
                              responseJson[0]["proprietario.dataDiNascita"]
                            );
                          break;
                        case "proprietario.etaPatente":
                          specificErrors.push("drivingLicenseAgeOwner");
                          newServerSideCheckErrorOwnerLicenseAge =
                            fixDirtyBackendErrorString(
                              responseJson[0]["proprietario.etaPatente"]
                            );
                          break;
                        case "dataDecorrenzaPolizza":
                          specificErrors.push("newInsuranceStart");
                          newServerSideCheckErrornewInsuranceStart =
                            fixDirtyBackendErrorString(
                              responseJson[0]["dataDecorrenzaPolizza"]
                            );
                          break;
                        case "dettagliAssicurazione.bersaniApplicabilita":
                          specificErrors.push("bersaniDecree");
                          break;
                        case "dettagliAssicurazione.bersani":
                          specificErrors.push("pastBersaniDecree");
                          break;
                        case "etaConducentePiuGiovane":
                          specificErrors.push("youngestOtherDriverAge");
                          break;
                        case "dettagliAssicurazione.statoAssicurativo":
                          specificErrors.push("vehicleInsuranceStatus");
                          break;
                        case "contraente.altriConducenti":
                          specificErrors.push("otherDriversExist");
                          break;
                        default:
                          genericErrors.push(
                            fixDirtyBackendErrorString(
                              responseJson[0][Object.keys(responseJson[0])[i]]
                            )
                          );
                      }

                      this.setState({
                        errors: [...this.state.errors, ...specificErrors],
                        errorTexts: [{field: "generic", msg: genericErrors}],
                        loading: false,
                        serverSideCheckErrorInsuranceClass:
                          newServerSideCheckErrorInsuranceClass,
                        serverSideCheckErrorDriverAddress:
                          newServerSideCheckErrorDriverAddress,
                        serverSideCheckErrorDriverDob:
                          newServerSideCheckErrorDriverDob,
                        serverSideCheckErrorDriverLicenseAge:
                          newServerSideCheckErrorDriverLicenseAge,
                        serverSideCheckErrorOwnerAddress:
                          newServerSideCheckErrorOwnerAddress,
                        serverSideCheckErrorOwnerDob:
                          newServerSideCheckErrorOwnerDob,
                        serverSideCheckErrorOwnerLicenseAge:
                          newServerSideCheckErrorOwnerLicenseAge,
                        serverSideCheckErrornewInsuranceStart:
                          newServerSideCheckErrornewInsuranceStart,
                      }, () => {
                        window.scrollTo(0, offsetFirstErrorField());
                      });
                    }
                  }
                  this.trackSendFailure({errors: [...this.state.errors, ...specificErrors], errorTexts: [{field: "generic", msg: genericErrors}]});
                } else {
                  this.trackSendSuccess(() => {
                    // rimanda allo step 4
                      this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(4, this.vehicle));
                  });
                }
              });
            });
          } else {
            this.props.step3DataFailure(this.vehicle);
            this.setState({ loading: false }, () => {
              this.trackSendFailure();
            });
          }
        })
        .catch((error) => {
          this.props.step3DataFailure(this.vehicle);
          this.setState({ loading: false }, () => {
            this.trackSendFailure();
          });
        });
    });
  }

  /**
   * Form submit.
   *
   */
  private handleFormSubmit() {    
    this.setState({submitting: true}, () => {
      this.validation()
        .then((result: any) => {
          const {status, error_fields, error_message} = result;
          if(status){
            this.formSubmit();
          }
          else{
            this.setState({submitting: false, errors: error_fields, errorTexts: error_message ?? []}, () => {
              this.trackSendFailure();
              window.scrollTo(0, offsetFirstErrorField());
            });
          }
        })
    })
  }

  /**
   * Converts a month index number (1-12) to the corresponding italian month name.
   *
   * @param index
   * @returns
   */
  private monthIndexToName(index: number | undefined): string | undefined {
    if (index === undefined) {
      return undefined;
    }
    else {
      return Constants.MONTHS_LIST_IT.find(
        (element) => element.value === index
      )?.label.toLowerCase();
    }
  }

  /**
   * Assembles the combined step1/step2/step3 form data, which will be sent via POST to the backend.
   *
   * @returns {URLSearchParams} the assembled form data
   */
  private setFormData() {
    let formData = new URLSearchParams();
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    //Step 1
    formData.append("contraente.partitaIva", "");
    formData.append("contraente.denominazioneSociale", "");
    formData.append("contraente.settoreMerceologico", "");
    //
    if (step1Data.gender) {
      formData.append("contraente.sesso", `${step1Data.gender}`);
    }
    if (step1Data.dateOfBirth) {
      formData.append("contraente.dataDiNascita+giorno", new Date(step1Data.dateOfBirth).getDate().toString());
      formData.append("contraente.dataDiNascita+mese", new Date(step1Data.dateOfBirth).toLocaleString("it-IT", {month: "long",}));
      formData.append("contraente.dataDiNascita+anno", new Date(step1Data.dateOfBirth).getFullYear().toString());
    }
    formData.append("contraente.natoInItalia", `${step1Data.bornInItaly} `);
    if (step1Data.bornInItaly) {
      formData.append("contraente.luogoDiNascitaItalia.id", `${step1Data.cityOfBirth?.id}`);
    }
    else {
      formData.append("contraente.luogoDiNascitaEstero.id", `${step1Data.countryOfBirth?.id}`);
    }
    if (step1Data.civilState) {
      formData.append("contraente.statoCivile", `${step1Data.civilState}`);
    }
    if (step1Data.degree) {
      formData.append("contraente.titoloDiStudio", `${step1Data.degree}`);
    }
    if (step1Data.profession) {
      formData.append("contraente.professione", `${step1Data.profession}`);
    }

    //Step 2
    formData.append("veicolo.ricordaLaTarga", `${step2Data.vehicleOwned || step2Data.knowLicensePlate}`);
    if (step2Data.vehicleOwned || step2Data.knowLicensePlate) {
      formData.append("veicolo.targa", `${step2Data.vehicleLicensePlateNumber}`);
    }
    formData.append("veicolo+nonConosceLaTarga", `${!(step2Data.vehicleOwned || step2Data.knowLicensePlate)}`);
    formData.append("veicolo.meseDiImmatricolazione", `${this.monthIndexToName(step2Data.vehicleRegistrationMonth)}`);
    formData.append("veicolo.annoDiImmatricolazione", `${step2Data.vehicleRegistrationYear} `);
    formData.append("veicolo.annoAcquisto", `${step2Data.vehicleBuyYear}`);
    formData.append("veicolo.marca", `${step2Data.vehicleBrand}`);
    formData.append("veicolo.modello.id", `${step2Data.vehicleModel}`);
    formData.append("veicolo.allestimento.id", `${step2Data.vehicleFitting}`);
    formData.append("contraente.numeroAuto", `${step2Data.moreThanOneVehicle ? "dueOPiu" : "una"}`);
    formData.append("chilometriPerAnno", `${step2Data.kilometersPerYear ? step2Data.kilometersPerYear : 0}`);
    formData.append("veicolo.antifurto", `${step2Data.vehicleAntitheft}`);
    if (this.vehicle === "auto") {
      formData.append("utilizzoTipico", `${step2Data.vehicleUse}`);
      formData.append("veicolo.gancioTraino", `${step2Data.vehicleTowbarMounted}`);
      formData.append("veicolo.alimentazione", `${step2Data.vehicleFuelType}`);
      
      if (step2Data.vehicleFuelType !== "E") {
        formData.append("veicolo.impiantoGPL", `${step2Data.vehicleGplMounted ?? false}`);
        formData.append("veicolo.cilindrata", `${step2Data.vehicleEngineDisplacement}`);
      }
      else { 
        formData.append( "veicolo+cilindrata", "0");
      }

      if (step3Data.includeSatProducts !== undefined) {
        formData.append("mostraOfferteBlackBox", `${step3Data.includeSatProducts}`);
      }
    }
    formData.append("parcheggioNotturno", `${step2Data.vehicleParkingLocation}`);

    //Step 3
    formData.append("vehicle", this.vehicle);
    formData.append("step", "3");
    if (step3Data.contractorName) {
      formData.append("contraente.nome", `${step3Data.contractorName}`);
    }
    if (step3Data.contractorSurname) {
      formData.append("contraente.cognome", `${step3Data.contractorSurname}`);
    }
    if (step3Data.contractorCityOfResidence) {
      formData.append("contraente.residenza.comune.id", `${(step3Data.contractorCityOfResidence as any)?.id}`);
    }
    if (step3Data.contractorPostalCode) {
      formData.append("contraente.residenza.cap", `${step3Data.contractorPostalCode}`);
    }
    if (step3Data.contractorAddress) {
      let { toponimo, indirizzo, } = extractAddressData(step3Data.contractorAddress);
      formData.append("contraente.residenza.toponimo", `${toponimo}`);
      formData.append("contraente.residenza.indirizzo", `${indirizzo}`);
    }
    if (step3Data.contractorAddressNumber) {
      formData.append("contraente.residenza.numero", step3Data.contractorAddressNumber);
    }
    if (step3Data.contractorChildren?.includes(0)) {
      formData.append("contraente.figliNo", "true");
    }
    if (step3Data.contractorChildren?.includes(1)) {
      formData.append("contraente.figliMinori", "true");
    }
    if (step3Data.contractorChildren?.includes(2)) {
      formData.append("contraente.figliMaggiori", "true");
    }
    if (
      this.isVisiblecontractorYoungestChild() &&
      step3Data.contractorYoungestChild
    ) {
      formData.append("contraente.etaFiglioMinore", `${step3Data.contractorYoungestChild}`);
    }
    if (step3Data.contractorEmail) {
      formData.append("contraente.email", `${step3Data.contractorEmail}`);
    }
    if (step3Data.contractorPhoneNumber) {
      formData.append("contraente.numeroCellulare", `${step3Data.contractorPhoneNumber}`);
    }
    if (step3Data.contractorItalianDrivingLicense) {
      formData.append("contraente.patenteItaliana", `${step3Data.contractorItalianDrivingLicense}`);
    }
    if (step3Data.contractorDrivingLicenseAge) {
      formData.append("contraente.etaPatente", `${step3Data.contractorDrivingLicenseAge}`);
    }
    if (step3Data.contractorPreferredPaymentMethod) {
      formData.append("metodoPagamento", `${step3Data.contractorPreferredPaymentMethod}`);
    }
    if (step3Data.newInsuranceStart) {
      formData.append("dataDecorrenzaPolizza.giorno", (new Date(step3Data.newInsuranceStart)).getDate().toString());
      formData.append("dataDecorrenzaPolizza.mese", (new Date(step3Data.newInsuranceStart)).toLocaleString("it-IT", {month: "long",}));
      formData.append("dataDecorrenzaPolizza.anno", (new Date(step3Data.newInsuranceStart)).getFullYear().toString());
    }
    if (step3Data.vehicleInsuranceStatus) {
      formData.append("dettagliAssicurazione.statoAssicurativo", `${step3Data.vehicleInsuranceStatus}`);
    }
    if (this.isVisible("assicurataAMioNome")) {
      if (step3Data.currentInsuranceCreditClass) {
        formData.append("dettagliAssicurazione.classeDiMerito", `${step3Data.currentInsuranceCreditClass}`);
      }
      if (this.isVisible("yearsInClass1List") && step3Data.yearsInClass1) {
        formData.append("dettagliAssicurazione.anzianitaPrimaClasse", `${step3Data.yearsInClass1}`);
      }
      if (step3Data.currentInsuranceCompany) {
        formData.append("dettagliAssicurazione.compagniaAssicurazioneAttuale", `${step3Data.currentInsuranceCompany}`);
      }
      if (step3Data.currentInsuranceYears) {
        formData.append("dettagliAssicurazione.etaAssicurativa", `${step3Data.currentInsuranceYears}`);
      }
      if (step3Data.pastBersaniDecree !== undefined) {
        formData.append("dettagliAssicurazione.bersani", `${step3Data.pastBersaniDecree}`);
      }
      if (step3Data?.pastAccidentCount !== undefined) {
        formData.append("dettagliAssicurazione.numeroIncidenti", `${step3Data.pastAccidentCount}`);
      }
      if (this.isVisible("accidentDetails")) {
        if(step3Data?.pastAccidentsInfo){
          for (let i = 0; i < step3Data.pastAccidentsInfo?.length ?? 0; i++) {
            formData.append( `dettagliAssicurazione.attestatoDiRischio[${i}].anno`, step3Data.pastAccidentsInfo[i].year);
            formData.append(`dettagliAssicurazione.attestatoDiRischio[${i}].responsabilita`, step3Data.pastAccidentsInfo[i].responsibility);
          }
        }
      }
    }
    if (this.isVisible("bersaniDecree") && step3Data.bersaniDecree) {
      formData.append("dettagliAssicurazione.bersaniApplicabilita", `${step3Data.bersaniDecree}`);
    }
    if (this.isVisible("cuLicensePlate") && step3Data.cuLicensePlate) {
      formData.append("targaBersani", `${step3Data.cuLicensePlate}`);
    }
    if (this.isVisible("cuInsuranceClass") && step3Data.cuInsuranceClass) {
      formData.append("dettagliAssicurazione.classeDiMeritoBersani", `${step3Data.cuInsuranceClass}`);
    }
    if (step3Data.contractorAndOwnerCoincide) {
      formData.append("contraenteProprietarioNew", `${step3Data.contractorAndOwnerCoincide}`);
    }
    if (step3Data.contractorAndDriverCoincide) {
      formData.append("contraenteConducenteAbitualeNew", `${step3Data.contractorAndDriverCoincide}`);
    }
    if (this.isVisibleOwnerAndDriverCoincide() && step3Data.ownerAndDriverCoincide) {
      formData.append("proprietarioConducenteAbitualeNew", `${step3Data.ownerAndDriverCoincide}`);
    }
    if (step3Data.otherDriversExist !== undefined) {
      formData.append("contraente.altriConducenti", `${step3Data.otherDriversExist}`);
    }
    if (this.isVisible("youngestOtherDriverAge") && step3Data.youngestOtherDriverAge) {
      formData.append("etaConducentePiuGiovane", `${step3Data.youngestOtherDriverAge}`);
    }

    if (this.isVisible("otherDriversNoviceExist") && step3Data.otherDriversNoviceExist !== undefined) {
      formData.append("contraente.conducenteNeoPatentato", `${step3Data.otherDriversNoviceExist}`);
    }
    else {
      formData.append("contraente.conducenteNeoPatentato", "false");
    }

    if (step3Data.youngestFamilyDriver) {
      formData.append("contraente.etaResidentePiuGiovane", `${step3Data.youngestFamilyDriver}`);
    }
    if (step3Data.contractoDeclares !== undefined) {
      formData.append("domandone", `${step3Data.contractoDeclares}`);
    }
    if (step3Data.includeNonCard !== undefined) {
      formData.append("mostraOfferteNoCard", `${step3Data.includeNonCard}`);
    }

    if (this.isVisibleOwner()) {
      // TODO: Il vecchio preventivatore setta i prossimi quattro campi a stringa vuota, verificare se sono effettivamente necessari
      formData.append("proprietario.partitaIva", "");
      formData.append("proprietario.denominazioneSociale", "");
      formData.append("proprietario.settoreMerceologico", "");
      formData.append("proprietario.tipoSocieta", "");

      if(step3Data.owner){

        const owner: IPerson = step3Data.owner;
  
        if (owner.gender !== undefined) {
          formData.append("proprietario.sesso", `${owner.gender}`);
        }
        if (owner.name) {
          formData.append("proprietario.nome", `${owner.name}`);
        }
        if (owner.surname) {
          formData.append("proprietario.cognome", `${owner.surname}`);
        }
        if ( owner.dateOfBirth && !isNaN((new Date(owner.dateOfBirth)).getDate())) {
          const ownerBirthdate: Date = new Date(owner.dateOfBirth);
          formData.append("proprietario.dataDiNascita.giorno", ownerBirthdate.getDate().toString());
          formData.append("proprietario.dataDiNascita.mese", ownerBirthdate.toLocaleString("it-IT", {month: "long"}));
          formData.append("proprietario.dataDiNascita.anno", ownerBirthdate.getFullYear().toString());
        }
        if (owner.bornInItaly !== undefined) {
          formData.append("proprietario.natoInItalia", `${owner.bornInItaly}`);
        }
        if (owner.cityOfBirth) {
          formData.append("proprietario.luogoDiNascitaItalia.id", `${(owner.cityOfBirth as any)?.id}`);
        }
        if (owner.countryOfBirth) {
          formData.append("proprietario.luogoDiNascitaEstero.id", `${(owner.countryOfBirth as any)?.id}`);
        }
        if (owner.cityOfResidence) {
          formData.append("proprietario.residenza.comune.id", `${(owner.cityOfResidence as any)?.id}`);
        }
        if (owner.postalCodeOfResidence) {
          formData.append("proprietario.residenza.cap", `${owner.postalCodeOfResidence}`);
        }
        if (owner?.address) {
          //TODO: Rivedere questa parte quando avremo l'integrazione con l'API di Google Maps
          let { toponimo, indirizzo, } = extractAddressData(owner.address);
          formData.append("proprietario.residenza.toponimo", `${toponimo}`);
          formData.append("proprietario.residenza.indirizzo", `${indirizzo}`);
        }
        if (owner?.addressNumber) {
          formData.append("proprietario.residenza.numero", owner.addressNumber);
        }
        if (owner?.civilState) {
          formData.append("proprietario.statoCivile", `${owner.civilState}`);
        }
        if (owner?.children?.includes(0)) {
          formData.append("proprietario.figliNo", "true");
        }
        if (owner?.children?.includes(1)) {
          formData.append("proprietario.figliMinori", "true");
        }
        if (owner?.children?.includes(2)) {
          formData.append("proprietario.figliMaggiori", "true");
        }
        if (owner?.degree) {
          formData.append("proprietario.titoloDiStudio", owner.degree);
        }
        if (owner?.profession) {
          formData.append("proprietario.professione", owner.profession);
        }
        if (owner?.italianDrivingLicense !== undefined) {
          formData.append("proprietario.patenteItaliana", `${owner.italianDrivingLicense}`);
        }
        if (owner?.italianDrivingLicense !== undefined) {
          formData.append("proprietario.etaPatente", `${owner.drivingLicenseAge}`);
        }
      }
    }

    if (this.isVisibleDriver()) {
      // TODO: Il vecchio preventivatore setta i prossimi quattro campi a stringa vuota, verificare se sono effettivamente necessari
      formData.append("conducenteAbituale.partitaIva", "");
      formData.append("conducenteAbituale.denominazioneSociale", "");
      formData.append("conducenteAbituale.settoreMerceologico", "");
      formData.append("conducenteAbituale.tipoSocieta", "");
      //

      if(step3Data.driver){
        const driver: IPerson = step3Data.driver;

        if (driver.gender !== undefined) {
          formData.append("conducenteAbituale.sesso", `${driver.gender}`);
        }
        if (driver.name) {
          formData.append("conducenteAbituale.nome", `${driver.name}`);
        }
        if (driver.surname) {
          formData.append("conducenteAbituale.cognome", `${driver.surname}`);
        }
        if (driver.bornInItaly !== undefined) {
          formData.append("conducenteAbituale.natoInItalia", `${driver.bornInItaly}`);
        }
        if (driver.postalCodeOfResidence) {
          formData.append("conducenteAbituale.residenza.cap", `${driver.postalCodeOfResidence}`);
        }
        if (driver.cityOfBirth) {
          formData.append("conducenteAbituale.luogoDiNascitaItalia.id", `${(driver.cityOfBirth as any)?.id}`);
        }
        if (driver.countryOfBirth) {
          formData.append("conducenteAbituale.luogoDiNascitaEstero.id", `${(driver.countryOfBirth as any)?.id}`);
        }
        if (driver.cityOfResidence) {
          formData.append("conducenteAbituale.residenza.comune.id", `${(driver.cityOfResidence as any)?.id}`);
        }
        if (driver.dateOfBirth && !isNaN((new Date(driver.dateOfBirth)).getDate())) {
          const driverBirthdate: Date = new Date(driver.dateOfBirth);
          formData.append("conducenteAbituale.dataDiNascita.giorno", driverBirthdate.getDate().toString());
          formData.append("conducenteAbituale.dataDiNascita.mese", driverBirthdate.toLocaleString("it-IT", {month: "long",}));
          formData.append("conducenteAbituale.dataDiNascita.anno", driverBirthdate.getFullYear().toString());
        }
        if (driver?.address) {
          let { toponimo, indirizzo, } = extractAddressData(driver.address);
          formData.append("conducenteAbituale.residenza.toponimo", `${toponimo}`);
          formData.append("conducenteAbituale.residenza.indirizzo", `${indirizzo}`);
  
          if (driver?.addressNumber) {
            formData.append("conducenteAbituale.residenza.numero", driver.addressNumber);
          }
        }
        if (driver?.civilState) {
          formData.append("conducenteAbituale.statoCivile", `${driver.civilState}`);
        }
        if (driver?.children?.includes(0)) {
          formData.append("conducenteAbituale.figliNo", "true");
        }
        if (driver?.children?.includes(1)) {
          formData.append("conducenteAbituale.figliMinori", "true");
        }
        if (driver?.children?.includes(2)) {
          formData.append("conducenteAbituale.figliMaggiori", "true");
        }
        if (driver?.degree) {
          formData.append("conducenteAbituale.titoloDiStudio", driver.degree);
        }
        if (driver?.profession) {
          formData.append("conducenteAbituale.professione", driver.profession);
        }
        if (driver?.italianDrivingLicense !== undefined) {
          formData.append("conducenteAbituale.patenteItaliana", `${driver.italianDrivingLicense}`);
        }
        if (driver?.italianDrivingLicense !== undefined) {
          formData.append("conducenteAbituale.etaPatente", `${driver.drivingLicenseAge}`);
        }
      }
    }

    if (this.props.vehicle === "moto"){
      if (step3Data.highestDisplacement) {
        formData.append("cilindrataMassima", `${step3Data.highestDisplacement}`);
      }
      if (step3Data.consecutiveDrivingMonths) {
        formData.append("mesiGuidaConsecutiva", `${step3Data.consecutiveDrivingMonths}`);
      }
    }

    if (this.isVisibleMoreQuestions()) {
      formData.append("puntiPatente", `${step3Data.licensePoints}`);
      formData.append("multeEbbrezza", `${step3Data.drunkDrivingTickets}`);
      formData.append("numeroSospensioniPatente", `${step3Data.licenseSuspensions}`);
      formData.append("mesiSospensionePatente", `${step3Data.totalLicenseSuspensionMonths}`);
      
      if (this.vehicle === "auto") {
        formData.append("tipoPatenteAuto", `${step3Data.licenseType}`);

        if (step3Data.vehicleModificationsAlloyWheels !== undefined) {
          formData.append("veicolo+cerchiInLega", `${step3Data.vehicleModificationsAlloyWheels}`);
        }
        if (step3Data.vehicleModificationsInvalidity !== undefined) {
          formData.append("veicolo+invaliditaFisica", `${step3Data.vehicleModificationsInvalidity}`);
        }
        if (step3Data.vehicleModificationsWindowTint !== undefined) {
          formData.append("veicolo+finestriniScuriti", `${step3Data.vehicleModificationsWindowTint}`);
        }
        if (step3Data.vehicleModificationsOther !== undefined) {
          formData.append("veicolo+altreModifiche", `${step3Data.vehicleModificationsOther}`);
        }
      }
      else {
        formData.append("tipoPatenteMoto", `${step3Data.licenseType}`);

        if (step3Data.vehicleModificationsEngineAntitheft !== undefined) {
          formData.append("veicolo+barreAntiurtoMotore", `${step3Data.vehicleModificationsEngineAntitheft}`);
        }
        if (step3Data.vehicleModificationsExhaust !== undefined) {
          formData.append("veicolo+modificaScarico", `${step3Data.vehicleModificationsExhaust}`);
        }
        if (step3Data.vehicleModificationsDecals !== undefined) {
          formData.append("veicolo+carenaturaAerografata", `${step3Data.vehicleModificationsDecals}`);
        }
        if (step3Data.vehicleModificationsRemovableBags !== undefined) {
          formData.append("veicolo+borseLateraliRimovibili", `${step3Data.vehicleModificationsRemovableBags}`);
        }
        if (step3Data.vehicleModificationsOther !== undefined) {
          formData.append("veicolo+altreModifiche", `${step3Data.vehicleModificationsOther}`);
        }
      }
    }

    formData.append("privacy.consensoCommunicazioniCommerciali", `${step3Data?.acceptGdprPrivacyPolicy102 ?? false}`);
    formData.append("privacy.consensoCommunicazioniCommercialiNewsletter", `${step3Data?.acceptGdprPrivacyPolicy22 ??false}`);
    formData.append("privacy.consensoRicercaEProfilazione", `${step3Data?.acceptGdprPrivacyPolicy3 ?? false}`);
    formData.append("privacy.consensoConcessioneATerzi", `${step3Data?.acceptGdprPrivacyPolicy104 ?? false}`);
    formData.append("privacy.consensoIvass", `${step3Data?.acceptIvass106 ?? false}`);
    formData.append("privacy.allegato3e4ter", `${step3Data?.acceptIvass106 ?? false}`);

    formData.append("privacy.consensoCgu", `${true}`);
    formData.append("privacy.privacypreventivo", `${true}`);

    return formData;
  }

}

const mapState = (state: any) => {
  return {
    userInfo: state.userInfoData.user,
    step1Data: state.step1Data,
    step2Data: state.step2Data,
    step3Data: state.step3Data,
    fromMailSereno: state.step4Data.fromMailSereno,
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    step3DataSuccess: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle)),
    step3DataFailure: (vehicle: string) => dispatch(step3DataFailure(vehicle)),
    step3DataReset: (vehicle: string) => dispatch(step3DataReset(vehicle)),
    step4DataSuccess: (payload: any, vehicle: string) => dispatch(step4DataSuccess(payload, vehicle))
  }
};

// Required to access react-router's params (i.e. ":vehicle" in App.tsx).
const Step3Logged = (props: any) => {
  const history: ReturnType<typeof useHistory> = useHistory();
  const lists: IDataList = useContext(DataList);

  return <Step3Component {...props} history={history} {...lists}/>
}

export default compose(connect(mapState, mapDispatch), withEventManager)(Step3Logged);

