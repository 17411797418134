import { Component } from 'react';
import * as Constants from './utils/constants';
import { IVehicle, Step2Data } from './utils/common';
import { Link } from 'react-router-dom';
import User from './utils/User';
import UserService from './utils/UserService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import '../scss/person-row.scss';
import '../scss/vehicle-row.scss';
import { connect, useSelector } from 'react-redux';

interface VehicleRowProps{
  vehicle: IVehicle,
  openCard: Function;
  step2Data: any;
  setLoading?: (callback?: Function) => void,
  unsetLoading?: (callback?: Function) => void,
  deleteCallback?: (id: string, callback?: Function) => void
}
interface VehicleRowState{
  dialogOpened: boolean;
  dialogMsg: JSX.Element;
  dialogHeader: JSX.Element;
  errorDialogOpened: boolean;
  isLoading: boolean;
  vehicleToDelete: IVehicle | null,
}
export class PersonRowComponent extends Component<VehicleRowProps, VehicleRowState>{

  constructor(props: VehicleRowProps){
    super(props);
    this.state = {
      dialogOpened: false,
      dialogHeader: <></>,
      dialogMsg: <></>,
      errorDialogOpened: false,
      vehicleToDelete: null,
      isLoading: true,
    }
  }
  
  // controlla se il veicolo è coinvolto nel preventivo in corso
  isInvolvedInQuotation(id: string): boolean{

    let arr: string[] = [];
    Object.keys(this.props.step2Data).forEach((v: string) => {
      const v_k = v as keyof Step2Data;

      if(this.props.step2Data[v_k]?.vehicleId){
        arr.push(this.props.step2Data[v_k].vehicleId as string)
      }
    })

    return arr.includes(id);

  }

  private openDialogDelete(vehicle: IVehicle){

    this.setState({
      dialogOpened: true,
      dialogHeader: <>Vuoi davvero eliminare il veicolo {User.getVehicleSafeName(vehicle)}?</>,
      dialogMsg: this.isInvolvedInQuotation(vehicle.id as string)
        ? <p>È in corso un preventivo per il veicolo {User.getVehicleSafeName(vehicle)},<br/>confermando l'eliminazione verrano cancellati anche i dati del preventivo in corso</p>
        : <p>Confermando l'eliminazione perderai tutti i dati del veicolo {User.getVehicleSafeName(vehicle)}</p>,
        vehicleToDelete: vehicle,
    });
  }

  private deleteVehicle(){

    this.setState(
      {
        dialogOpened: false,
        isLoading: true
      },
      () => {

        this.props.setLoading?.(() => {
          const id: string = this.state.vehicleToDelete?.id as string;
          UserService.call(
            Constants.WEB_SITE_API_SERVER_URL(`/vehicles/${id}`),
            {
              method: "DELETE"
            },
            (response: any) => {
              
              if(response?.success === false){
                this.setState(
                  {
                    errorDialogOpened: true,
                    isLoading: false
                  },
                  () => this.props.unsetLoading?.()
                );
              }
              else{
                
                this.setState(
                  {isLoading: false},
                  () => {
                    //controllo se cancellare il preventivo in corso
                    if(this.isInvolvedInQuotation(id)){
                      ["auto", "moto"].forEach((v: string) => User.resetStepData(v))
                    }
                    this.props.deleteCallback?.(id)
                  }
                );
              }
            },
            (err: any) => {
              this.setState({
                errorDialogOpened: true,
                dialogOpened: false,
              })
              
              this.props.unsetLoading?.();
            }
          );
        });
      }
    );
  }

  render() {
    /*let msg = <span>Recupero informazioni in corso <span className="vehicleSpinner"></span></span>;
    if(this.props.quotation === 0)
      msg = <span>Nessun preventivo in archivio</span>;
    if(this.props.quotation === 1)
      msg = <span>Un preventivo in archivio</span>;
    if(this.props.quotation > 1)
      msg = <span>{this.props.quotation} preventivi in archivio</span>;*/

    return <div className="personRow cardTitle">

      <Dialog
        className="customDialog error"
        header={this.state.dialogHeader}
        visible={this.state.dialogOpened}
        modal={true}
        onHide={() => {this.setState({dialogOpened: false})}}
        resizable={false}
        draggable={false}
        footer={
          <div>
              <Button label="No, annulla" className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.setState({dialogOpened: false})}} />

              <Button label="Si, elimina" className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.deleteVehicle()}} />
          </div>
        }>
        {this.state.dialogMsg}        
      </Dialog>

      <Dialog
        className="customDialog error"
        header="Attenzione!"
        visible={this.state.errorDialogOpened}
        onHide={() => {this.setState({dialogOpened: false})}}
        modal={true}
        resizable={false}
        draggable={false}
      >
        
        <p>Errore generico. Riprovare.</p>        
      </Dialog>

      <div className="row">
        <div className="col-md-6">
          <div className="d-flex">
            <img src={Constants.SEISICURO_STATIC_CONTENT_URL+"/img/"+(this.props.vehicle.vehicleType === "auto" ? "icon-auto" : "icon-moto")+".svg"} className="iconVehicle" alt=""/>
            <div>
              <h3 className='titleLev5 colorPrimaryGreen mb-3'>{User.getVehicleSafeName(this.props.vehicle)}</h3>
              <div className="row">
                <div className="col-lg-12">
                  <p className="mb-1"><strong>Modello:</strong> {this.props.vehicle.vehicleModelLabel}</p>
                  {this.props.vehicle?.vehicleLicensePlateNumber &&
                    <p className="mb-3"><strong>Targa:</strong> { this.props.vehicle.vehicleLicensePlateNumber }</p>
                  }

                  <span
                    className="colorPrimaryGreen btnDettagli"
                    style={{cursor: "pointer"}}
                    onClick={() => this.props.openCard(this.props.vehicle.id)}
                  >
                    Visualizza dettagli
                    <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/arrow-down-bluegreen.svg`} style={{transform: "rotate(-90deg)", marginLeft: "0.5rem"}} alt=""/>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6" style={{textAlign: "right"}}>
          <div className="blockCta">
            <div>
              {/*<p style={{marginBottom: 0, overflow: "hidden"}}>{msg}</p>*/}
              {
                (true /*this.props.quotation > 0*/)
                  ? <Link
                      className="mt-2"
                      // to={"/preventivi/area-personale/auto-moto/preventivi/?idVeicolo=" + this.props.vehicle.id}
                      to={`/preventivi/area-personale/auto-moto/preventivi/${this.props.vehicle.id}`}
                    >
                      <span className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">
                        Visualizza i preventivi
                      </span>
                    </Link>
                  : ""
              }
              <Button className="deleteBtn" label="Elimina veicolo" onClick={() => this.openDialogDelete(this.props.vehicle)}></Button>
            </div>
          </div>
        </div>
      </div>      
    </div>    
  }
}

const mapState = (state: any) => {
  return {
    loginVisible: state.userInfoData.user.openLogin,
    step2Data: state.step2Data
  };
}

const VehicleRow = (props: any) => {
  const step2Data: any = useSelector((state: any) => {return state.step2Data});

  return <PersonRowComponent
    {...props}
    step2Data={step2Data}
  />

};

export default connect(mapState)(VehicleRow);