import { Component } from 'react';
import * as Constants from './utils/constants';
import { ICompany, Step1Data, Step3Data } from './utils/common';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import User from './utils/User';
import { Dialog } from 'primereact/dialog';
import UserService from './utils/UserService';
import '../scss/person-row.scss';
import { connect, useSelector } from 'react-redux';


interface CompanyRowProps{
  company: ICompany,
  titolare?: boolean,
  openCard: Function;
  /*step1Data: any;
  step3Data: any;*/
  setLoading?: (callback?: Function) => void,
  unsetLoading?: (callback?: Function) => void,
  deleteCallback?: (id: string, callback?: Function) => void
}
interface CompanyRowState{
  dialogOpened: boolean;
  dialogMsg: JSX.Element;
  dialogHeader: JSX.Element;
  errorDialogOpened: boolean;
  isLoading: boolean;
  companyToDelete: ICompany | null;
}

export class CompanyRowComponent extends Component<CompanyRowProps, CompanyRowState>{

  constructor(props: CompanyRowProps){
    super(props);
    this.state = {
      dialogOpened: false,
      dialogHeader: <></>,
      dialogMsg: <></>,
      errorDialogOpened: false,
      companyToDelete: null,
      isLoading: true,
    }
  }

  // controlla se la persona è coinvolta nel preventivo in corso
  isInvolvedInQuotation(id: string): boolean{
    return false;
    /*let arr: string[] = [];
    Object.keys(this.props.step1Data).forEach((v: string) => {
      const v_k = v as keyof Step1Data;
      const s1d: Step1Data = this.props.step1Data[v_k];
      const s3d: Step3Data = this.props.step3Data[v_k];

      if(s1d?.contractorId){
        arr.push(s1d.contractorId as string)
      }
      if(s3d?.driverId){
        arr.push(s3d.driverId as string)
      }
      if(s3d?.ownerId){
        arr.push(s3d.ownerId as string)
      }
    })

    return arr.includes(id);*/
  }

  private openDialogDelete(company: ICompany){

    this.setState({
      dialogOpened: true,
      dialogHeader: <>Vuoi davvero eliminare l'azienda {company.name}?</>,
      dialogMsg: this.isInvolvedInQuotation(company.id as string)
        ? <p>È in corso un preventivo per {company.name},<br/>confermando l'eliminazione verrano cancellati anche i dati del preventivo in corso</p>
        : <p>Confermando l'eliminazione perderai tutti i dati di {company.name}</p>,
      companyToDelete: company,
    });
  }

  private deleteCompany(){
    this.setState(
      {
        dialogOpened: false,
        isLoading: true
      },
      () => {
        this.props.setLoading?.(() => {
          const id: string = this.state.companyToDelete?.id as string;

          UserService.call(
            Constants.WEB_SITE_API_SERVER_URL(`/companies/${id}`),
            {
              method: "DELETE"
            },
            (response: any) => {
              let newState: CompanyRowState = this.state;
              newState.dialogOpened = false;
      
              if(response?.success === false){
                this.setState(
                  {
                    errorDialogOpened: true,
                    isLoading: false
                  },
                  () => this.props.unsetLoading?.()
                );
              }
              else{
                
                this.setState(
                  {isLoading: false},
                  () => {
                    this.props.deleteCallback?.(id)
                  }
                );
              }
            },
            (err: any) => {
              this.setState({
                errorDialogOpened: true,
                dialogOpened: false,
              })
              
              this.props.unsetLoading?.();
            }
          );
        });
    });

  }

  render() {
    let dateOfBirth : string;
    const company: ICompany = this.props.company;

    return <div className="personRow cardTitle">
      <Dialog
        className="customDialog error"
        header={this.state.dialogHeader}
        visible={this.state.dialogOpened}
        modal={true}
        onHide={() => {this.setState({dialogOpened: false})}}
        resizable={false}
        draggable={false}
        footer={
          <div>
              <Button label="No, annulla" className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.setState({dialogOpened: false})}} />

              <Button label="Si, elimina" className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.deleteCompany()}} />
          </div>
        }>
        {this.state.dialogMsg}
      </Dialog>

      <Dialog
        className="customDialog error"
        header="Attenzione!"
        visible={this.state.errorDialogOpened}
        onHide={() => {this.setState({dialogOpened: false})}}
        modal={true}
        resizable={false}
        draggable={false}
      >
        
        <p>Errore generico. Riprovare.</p>        
      </Dialog>

      <div className={"row"+((this.props?.titolare === true) ? '' : ' align-items-center')}>
        <div className="col-3 col-md-2">
          <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/icon-persona.svg`} className="iconCompany" alt=""/>
        </div>
        <div className="col-9 col-md-6">
          <h3 className='titleLev6 colorPrimaryGreen mb-0'>{company.name}</h3>          
          <p>
            <span className="smallParagraph"><strong>P.IVA:</strong> {company.vat}</span><br/>
            <span className="smallParagraph"><strong>Sede legale:</strong> { company.registered_office_address }</span>
          </p>
          <p className="colorPrimaryGreen cursor-pointer" onClick={() => this.props.openCard(company)}>+ Visualizza dettagli</p>
        </div>
        <div className="col-md-4" style={{textAlign: "right"}}>
          <div className="blockCta">
            <div>
              <Link className="mt-2" to={`/preventivi/area-personale/aziende/${company.id}`}>
                <span className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">
                  Visualizza richieste di preventivo
                </span>
              </Link>

              {!this.props?.titolare &&
                <Button
                  className="deleteBtn"
                  label="Elimina azienda"
                  onClick={() => this.openDialogDelete(this.props.company)} />
              }
              
            </div>
          </div>
        </div>
      </div>      
    </div>    
  }
}

const mapState = (state: any) => {
  return {
    loginVisible: state.userInfoData.user.openLogin,
    step1Data: state.step1Data
  };
}

const CompanyRow = (props: any) => {
  const step1Data: any = useSelector((state: any) => {return state.step1Data});
  const step3Data: any = useSelector((state: any) => {return state.step3Data});

  return <CompanyRowComponent
    {...props}
    step1Data={step1Data}
    step3Data={step3Data}
  />

};

export default connect(mapState)(CompanyRow);