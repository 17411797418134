import { Button } from "primereact/button";
import * as Constants from './utils/constants';
import { InputText } from "primereact/inputtext";
import { Component } from "react";
import UserService, { GenericException } from "./utils/UserService";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Redirect } from "react-router-dom";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import StepLoaderSpinner from "./StepLoaderSpinner";

export default class ForgottenPasswordPage extends Component<{
  // No props
}, {
  email: string;

  emailValid: boolean | undefined;
  emailRegistered: boolean;
  emailChecking: boolean;

  isClosed: boolean;
  isCompleted: boolean;
  isLoading: boolean;
  isSubmitting: boolean;

  errors: string[];
  errorTexts: IFeedbackText[];
}> {
  private previousMail : string;

  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      
      emailValid: undefined,    // status of mail validation, true if valid and not registered
      emailRegistered: false,    // user registered but not logged
      emailChecking: false,

      isClosed: false,
      isCompleted: false,
      isLoading: false,
      isSubmitting: false,

      errors: [],
      errorTexts: [],    
    };
    this.previousMail = this.state.email || "";
    this.onHideHandler = this.onHideHandler.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.emailHandler = this.emailHandler.bind(this);
  }

  render() {
    const condition = this.state.isClosed
    if (condition) {
      return <Redirect to='/preventivi/area-personale/login'></Redirect>
    }

    return (
      <>
      <div className="pr-body">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-8 col-10"> 
              {!this.state.isLoading
              ?
              <div>
                <Dialog
                  visible={this.state.isCompleted}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "624px" }}
                  header={<h3 className="titleLev5 colorPrimaryBlue">Richiesta inviata correttamente</h3>}
                  onHide={this.onHideHandler}  
                  modal={true}
                  resizable={false}
                  draggable={false}
                  id="passwordResetConfirmation"
                  >
                  <div className="container-fluid">
                    <div className="row">
                    
                      <p>Se all’indirizzo email fornito corrisponde un account 6sicuro, riceverai una email per effettuare il reset della password.<br/>Segui le istruzioni che ti abbiamo inviato per email.
                      </p>
                    </div>
                  </div>
                </Dialog>
                              
                <Card className="pr-form mb-5">
                  <div className="p-fluid p-5">
                    <div className="row">
                      <div className="col-12">
                        <h1 className="titleLev4 colorPrimaryBlue mb-2">
                          Hai dimenticato la password?
                        </h1>
                        <p>
                          Inserisci il tuo indirizzo email per crearne una nuova.
                        </p>
                      </div>
                    </div>
                    <FeedbackBox items={this.state.errorTexts}/>                  
                    <div className="row">                    
                      <div className="col-12 mb-2">  
                        <label htmlFor="email">E-mail</label>
                        {this.state.emailChecking || this.state.emailValid !== undefined
                            ? <span className="p-input-icon-right">
                              {this.state.emailChecking
                                ? <i className="pi pi-spin pi-spinner" />
                                : this.state.emailValid !== undefined
                                  ? this.state.emailValid
                                    ? <i className="pi pi-check" />
                                    : <i className="pi pi-times" />
                                  : <></>
                              }
                                {this.emailInputElement()}
                              </span>
                            : this.emailInputElement()
                          }                        
                        <ContextFeedback
                          show={this.state.errors.includes("email")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "email") ??
                            {msg: "Inserisci una email valida", severity: "error"}
                          }
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <Button
                          id="submitButton"
                          label="Invia la richiesta"
                          className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                          onClick={() => {this.onSubmit()}}
                          loading={this.state.isSubmitting}
                        >
                        </Button>                      
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              :
              <div>
                <StepLoaderSpinner title="Stai per essere reindirizzato alla pagina di Login"/>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
  
  private emailInputElement() : JSX.Element{
    return <InputText
      id="email"
      placeholder="E-mail"
      name="email"
      autoComplete="email"
      type="email"
      value={this.state?.email}
      onChange={event => this.onChangeHandler(event.target.value, "email")}
      onBlur={(event) => { this.emailHandler(event.target.value) }}
    />
  }

  private onHideHandler() {
    this.setState({isLoading: true})
    setTimeout(() => {
        this.setState({isClosed: true})
    }, 3000)
  }

  private onChangeHandler(value: any, componentName: string) {
    this.setState({email: value})
    this.resetValidation(componentName)
  }

  private emailHandler(candidateEmail: string, skipPrevValueControl:boolean = false, callback?: Function){
    if(skipPrevValueControl || candidateEmail !== this.previousMail){
      if(candidateEmail === ""){
        this.setState({emailValid: false, emailChecking: false})
      } else {
        this.setState({emailChecking: true}, () => {
          
          UserService.call(Constants.WEB_SITE_API_SERVER_URL("/utils/misc/validate"), {
            method: "post",
            body: JSON.stringify({
              email: candidateEmail ?? ""              
            })
          })
            .then(result => {
              const {valid} = result.email;

              if(valid) {                      
                this.setState({
                  emailValid: true,
                  emailChecking: false
                }) 
              } else {
                this.setState({
                  emailValid: false,
                  emailChecking: false
                }) 

                throw new GenericException("Inserisci un indirizzo email valido")
              }              
            })
            .catch((err: any) => {
              let textErrors : any[] = [this.state.errorTexts.filter(elem => elem.field !== "email")];
              let errors : any[] = this.state.errors.filter(elem => elem !== "email");
              if(err instanceof GenericException) {    
                textErrors.push({field: "email", msg: err.message});
                errors.push("email");
              } else {
                textErrors.push({field: "generic", msg: "Servizio al momento non disponibile riprovare più tardi."});
                errors.push("generic");
              }

              this.setState({
                emailValid: false,
                errors: errors,
                errorTexts: textErrors,
                emailChecking: false
              })     
            })          
        })
      }
    }
  }

  /**
  * Reset form element validation
  * @param element
  * @returns
  */
  resetValidation(element: any) {
    this.setState({
      errors: this.state.errors.filter((item: any) => item !== element),
      errorTexts: []    
    });
    return;
  }

  private onSubmit() {
    this.setState({isSubmitting: true}, () => {
      UserService.call(
        Constants.WEB_SITE_API_SERVER_URL('/users/password/reset'),
        {
          method: "POST",
          body: JSON.stringify({
              email: this.state.email
            })
        },
        (response: any) => {
          if(response?.success === false){
            // caso di errore applicativo
            if(response.error_code === "GENERIC"){
              throw new GenericException("Impossibile completare la richiesta");
            }
          }
          else {
            this.setState({isCompleted: true, email: '', isSubmitting: false})
          }
      },
      (err: any) => {          
        if (err instanceof GenericException) {
          this.setState({ errorTexts: [err.getData()], isSubmitting: false});
        }
        else {
          this.setState({errorTexts: [{ field: "generic", msg: "Servizio al momento non disponibile riprovare più tardi." }], isSubmitting: false});
        }
      });
    })
  }
}