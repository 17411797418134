import * as Constants from "./constants";

const PATTERN_TARGA_AUTO_VECCHIA = /^(AG|AL|AN|AO|AP|AQ|AR|AT|AV|BA|BG|BI|BL|BN|BO|BR|BS|BT|BZ|CA|CB|CE|CH|CI|CL|CN|CO|CR|CS|CT|CZ|EN|FC|FE|FG|FI|FM|FO|FR|GE|GO|GR|IM|IS|KR|LC|LE|LI|LO|LT|LU|MB|MC|ME|MI|MN|MO|MS|MT|NA|NO|NU|OG|OR|OT|PA|PC|PD|PE|PG|PI|PN|PO|PR|PS|PT|PU|PV|PZ|RA|RC|RE|RG|RI|RM|RN|RO|SA|SI|SO|SP|SR|SS|SV|TA|TE|TN|TO|TP|TR|TS|TV|UD|VA|VB|VC|VE|VI|VR|VS|VT|VV|ROMA)(?:(?![CIJOQcijoq])[a-zA-Z0-9]){6}$/g;
const PATTERN_TARGA_AUTO_NUOVA = /^(?:(?![IOQUioqu])[a-zA-Z]){2}[0-9]{3}(?:(?![IOQUioqu])[a-zA-Z]){2}$/g;

const PATTERN_TARGA_MOTO_VECCHIA = /^(AG|AL|AN|AO|AP|AQ|AR|AT|AV|BA|BG|BI|BL|BN|BO|BR|BS|BT|BZ|CA|CB|CE|CH|CI|CL|CN|CO|CR|CS|CT|CZ|EN|FC|FE|FG|FI|FM|FO|FR|GE|GO|GR|IM|IS|KR|LC|LE|LI|LO|LT|LU|MB|MC|ME|MI|MN|MO|MS|MT|NA|NO|NU|OG|OR|OT|PA|PC|PD|PE|PG|PI|PN|PO|PR|PS|PT|PU|PV|PZ|RA|RC|RE|RG|RI|RM|RN|RO|SA|SI|SO|SP|SR|SS|SV|TA|TE|TN|TO|TP|TR|TS|TV|UD|VA|VB|VC|VE|VI|VR|VS|VT|VV|ROMA)(?:(?![CIJOQcijoq])[a-zA-Z0-9]){6}$/g;
const PATTERN_TARGA_MOTO_NUOVA = /^(?:(?![IOQUioqu])[a-zA-Z]){2}[0-9]{5}$/g;

const PATTERN_NOME = /^[a-zA-ZÀ-ÿ'. ]{2,20}$/g;
const PATTERN_COGNOME = /^[a-zA-ZÀ-ÿ'. ]{2,50}$/g;

const PATTERN_PREFISSO_TELEFONO = /(320)|(321)|(322)|(323)|(324)|(325)|(326)|(327)|(328)|(329)|(330)|(331)|(333)|(334)|(335)|(336)|(337)|(338)|(339)|(340)|(342)|(344)|(345)|(346)|(347)|(348)|(349)|(350)|(351)|(353)|(360)|(366)|(368)|(370)|(371)|(373)|(375)|(376)|(377)|(378)|(379)|(380)|(388)|(389)|(391)|(392)|(393)/g;
const PATTERN_BLACKLIST_NUMERO_TELEFONO = /(12345)|(00000)|(11111)|(22222)|(33333)|(44444)|(55555)|(66666)|(77777)|(88888)|(99999)/g
const PATTERN_NUMERO_TELEFONO = /^\d{9,12}$/g
const PATTERN_DIGIT_NUMERO_TELEFONO = /^\d+$/g

const PATTERN_EMAIL = /^\S+@\S+\.\S+$/g;

const PATTERN_VIA = /([A-z]|[À-ú]|[0-9]|\&|\'|\/|\,|\ )/g;
const PATTERN_BLACKLIST_VIA = /^([A-Z]|[a-z])* *([0-9]*|[0-9]*\/([A-Z]|[a-z])*)$/g;
const PATTERN_STREET_ADDRESS = /^[0-9A-zÀ-ú ']+?,?\s?\d+(\/?\\?[a-zA-Z]+)?$/g;

/**
 * Check the license plate for auto
 * 
 * @param {string} value The license plate
 * @returns {boolean}
 */
export function isLicensePlateAutoValid(value: string){
    const pattern_targa_vecchia: any = new RegExp(PATTERN_TARGA_AUTO_VECCHIA);
    const pattern_targa_nuova: any = new RegExp(PATTERN_TARGA_AUTO_NUOVA);
    return pattern_targa_nuova.test(value) || pattern_targa_vecchia.test(value);
}

/**
 * Check name
 * 
 * @param {string} value name
 * @returns {boolean}
 */
 export function isNameValid(value: string){
    const pattern_nome: any = new RegExp(PATTERN_NOME);
    return pattern_nome.test(value);
}

/**
 * Check surname
 * 
 * @param {string} value name
 * @returns {boolean}
 */
 export function isSurnameValid(value: string){
    const pattern_cognome: any = new RegExp(PATTERN_COGNOME);
    return pattern_cognome.test(value);
}

/**
 * Check phone number
 * 
 * @param {string} value phone number
 * @returns {boolean}
 */
 export function isPhoneNumberValid(value: string | number){

    if(typeof(value) === "number"){
        value = value.toString();
    }

    const pattern_numero: any = new RegExp(PATTERN_NUMERO_TELEFONO);
    const pattern_whitelist_prefisso: any = new RegExp(PATTERN_PREFISSO_TELEFONO);
    const pattern_blacklist_numero: any = new RegExp(PATTERN_BLACKLIST_NUMERO_TELEFONO);
    const pattern_digit_numero = new RegExp(PATTERN_DIGIT_NUMERO_TELEFONO);
    try{
        return pattern_numero.test(value) && pattern_whitelist_prefisso.test(value.substr(0, 3)) && !pattern_blacklist_numero.test(value.substr(3)) && pattern_digit_numero.test(value);
    } catch(error) {
        return false;
    }
}

/**
 * Check email
 * 
 * @param {string} value email
 * @returns {boolean}
 */
 export function isEmailValid(value: string){
    const pattern_email: any = new RegExp(PATTERN_EMAIL);
    return pattern_email.test(value);
}

/**
 * Check email
 * 
 * @param {string} email email
 */
export function serverIsEmailValid(email: string, successCallback?: Function, errorCallback?: Function): Promise<{}>{
    let jsonData = {value: email}
    return fetch(Constants.SEISICURO_VALIDATION_ENDPOINT('chek-email'), {
        method: "post",
        credentials: "include",
        body: JSON.stringify(jsonData),
        headers: {
            /** AUTH_REDACTED */
            "Content-Type": "application/json"
        },
    })
    .then(response => {
        return response.json();
    })
    .then((result : {valid: string | boolean | undefined, known: string | boolean | undefined}) => {

        // cast to boolean
        if(typeof(result.valid) === "string"){
            result.valid = result.valid === "true"
                ? true
                : result.valid === "false"
                    ? false
                    : undefined;
        }
        
        // cast to boolean
        if(typeof(result.known) === "string"){
            result.known = result.known === "true"
                ? true
                : result.known === "false"
                    ? false
                    : undefined;
        }

        return successCallback?.(result);
    })
    .catch(err => {
        return errorCallback?.(err);
    });
}

/**
 * 
 * @param email 
 * @param check_user_exists 
 * @param successCallback 
 * @param errorCallback 
 * @returns 
 */
export function isEmailValidCustom(email: string, check_user_exists: boolean = false, successCallback?: Function, errorCallback?: Function) {
    let jsonData = {
        email: email,
        check_user_exists: check_user_exists
    }

    return fetch(Constants.WEB_SITE_API_SERVER_URL('/utils/email/validate'), {
        method: "POST",
        body: JSON.stringify(jsonData),
        headers: {
            /** AUTH_REDACTED */
            "Content-Type": "application/json"
        },
    })
    .then(response => {
        return response.json();
    })
    .then((result : {valid: string | boolean | undefined, known: string | boolean | undefined}) => {

        // cast to boolean
        if(typeof(result.valid) === "string"){
            result.valid = result.valid === "true"
                ? true
                : result.valid === "false"
                    ? false
                    : undefined;
        }
        
        // cast to boolean
        if(typeof(result.known) === "string"){
            result.known = result.known === "true"
                ? true
                : result.known === "false"
                    ? false
                    : undefined;
        }

        successCallback?.(result);
    })
    .catch(err => {
        errorCallback?.(err);
    });
}

/**
 * Check email
 * 
 * @param {string} phone phone
 */
export function serverIsPhoneValid(phone: string, successCallback?: Function, errorCallback?: Function){
    
    let formData = new URLSearchParams();
    formData.append("value", phone);

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.append('Content-Type', 'application/x-www-form-urlencoded; charset="utf-8"');

    fetch(Constants.SEISICURO_VALIDATION_PHONE, {
        method: "post",
        credentials: "include",
        body: formData,
        headers: requestHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then((result : []) => {
        successCallback?.(result);
    })
    .catch(err => {
        errorCallback?.(err);
    });
}

// strong: almeno 8 lettere, almeno una lettera minuscola, almeno una lettera maiuscola, almeno un numero almeno un carattere speciale !@#$%^&*
// medium: almeno 6 lettere, almeno una lettera minuscola e una maiuscola oppure almeno una lettera minuscola e almeno un numero o almenu una lettera maiuscola e almeno un numero
// low: almeno 6 lettere, almeno una lettera minuscola e una maiuscola
export function passwordSecurityLevel(password: string){
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const valStrong = strongRegex.test(password);
    
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
    const valMedium = mediumRegex.test(password);
    
    const lowRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.{6,})");
    const valLow = lowRegex.test(password);

    let result: {
        strong: boolean;
        medium: boolean;
        lower: boolean;
        valid: boolean;
    } = {
        strong: valStrong,
        medium: valMedium,
        lower: valLow,
        valid: valStrong || valMedium || valLow
    }

    return result;

}
 
/**
 * Check the license plate for moto
 * 
 * @param {string} value The license plate
 * @returns {boolean}
 */
 export function isLicensePlateMotoValid(value: string){
    const pattern_targa_vecchia: any = new RegExp(PATTERN_TARGA_MOTO_VECCHIA);
    const pattern_targa_nuova: any = new RegExp(PATTERN_TARGA_MOTO_NUOVA);
    return pattern_targa_nuova.test(value) || pattern_targa_vecchia.test(value);
}


/**
 * Verifica che il carattere digitato sia ammissibile
 * @param {string} value il carattere digitato
 * @returns {boolean}
 */
 export function isAddressCharacterValid(value: string){
    const pattern_via: any = new RegExp(PATTERN_VIA);
    return pattern_via.test(value);
}

/**
 * Check address
 * 
 * @param {string} value address
 * @returns {boolean}
 */
 export function isAddressValid(value: string){
    const pattern_via: any = new RegExp(PATTERN_BLACKLIST_VIA);
    return !pattern_via.test(value);
}

/**
 * Verifica se la stringa corrisponde ad un pattern di un indirizzo
 * @param value la stringa da testare 
 * @returns true se la stringa è un induirizzo valido, false altrimenti
 */
export function isStreetAddres(value: string): boolean {
    const pattern_street: any = new RegExp(PATTERN_STREET_ADDRESS);
    return pattern_street.test(value.trim());
}

/**
 * Verifica se si tratta di un indirizzo completo di tutto
 * @param value true se la stringa è un indirizzo completo (proveninete da un preventivo caricato dal Nodo)
 */
export function isCompleteAddress (value: string) : boolean {
    return value.trim().endsWith(", Italia");
}