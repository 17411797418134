import { Card } from "react-bootstrap";
import { Divider } from "primereact/divider";
import { Component, useContext } from "react";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import * as Constants from './utils/constants';
import User from "./utils/User";
import UserService, { GenericException } from "./utils/UserService";
import StepLoaderSpinner from "./StepLoaderSpinner";
import { Dropdown } from "primereact/dropdown";
import { IVehicle } from "./utils/common";
import CardPreventivo from "./CardPreventivo";
import { useHistory, useRouteMatch } from "react-router-dom";
import '../scss/quotation-row.scss';

interface IDropdownItem{
  value: string | number;
  label: string;
}

interface IPreventiviProps extends IPageProps{
  pages: IPageDescriptor[];
  history: ReturnType<typeof useHistory>;
  vehicleId: string;
}

interface IPreventiviState{
  isLoading: boolean;
  quotations: any[];
  driversDropdown: IDropdownItem[];
  filterDriver: number;
  filterDate: number;
  prevVehicleId: string;
  error: boolean;
  errorMsg?: string | JSX.Element;
}

enum Status {
  LOADING,
  LOADED,
  ERROR
}

enum MonthsLimit{
  MESI_3 = 1,
  MESI_6 = 2,
  MESI_12 = 3,
  ALL = 0
}

interface ISalvati{
  status: Status;
  data: any[];
}

class AreaPersonalePreventiviComponent extends Component<IPreventiviProps, IPreventiviState>{


  private currentPage: IPageDescriptor;
  private basePage: IPageDescriptor;
  private user: User;
  //private refVCard: any;
  private nomeVeicolo: string | undefined;
  private _isMounted: boolean = false;
  private vehicleList: IDropdownItem[] = [];

  private filterDateSelector : IDropdownItem[] = [];

  constructor (props: IPreventiviProps){
    super(props);

    this.user = this.props.user as User;

    this.nomeVeicolo = this.user.vehicles.find((v: IVehicle) => v.id === this.props.vehicleId)?.vehicleLicensePlateNumber;

    this.currentPage = this.props.pages.find((p: IPageDescriptor) => p.name === this.props.page?.name) as IPageDescriptor;
    this.basePage = this.props.pages.find((p: IPageDescriptor) => p.name === "preventivi-veicolo") as IPageDescriptor;

    this.state = {
      isLoading: true,
      quotations: [],
      driversDropdown: [],
      filterDriver: -1,
      filterDate: -1,
      prevVehicleId: this.props.vehicleId,
      error: false
    }

    this.filterDateSelector = [
      {label: "Tutti", value: MonthsLimit.ALL},
      {label: "Ultimi tre mesi", value: MonthsLimit.MESI_3},
      {label: "Ultimi sei mesi", value: MonthsLimit.MESI_6},
      {label: "Ultimo anno", value: MonthsLimit.MESI_12},
    ]

    this.vehicleList = this.user.vehicles
      .filter(vehicle => vehicle.vehicleLicensePlateNumber !== undefined)
      .map((vehicle: IVehicle) => {
        return {label: User.getVehicleSafeName(vehicle), value: `${vehicle.id}`};
      });

    this.applyFilter = this.applyFilter.bind(this);
  }

  componentDidMount(){
    this._isMounted = true;
    window.onpopstate = () => {
      if(this._isMounted) {
        this.setState(
          {isLoading: true},
          () => {
            this
              .readPreventiviData(this.props.vehicleId)
              .then((response: any[] | Error) => this.readPreventiviDataHandler(response))
          }
        )
      }
    }

    this
      .readPreventiviData(this.props.vehicleId)
      .then((response: any[] | Error) => this.readPreventiviDataHandler(response))
  }

  componentDidUpdate(prevProps: Readonly<IPreventiviProps>, prevState: Readonly<IPreventiviState>, snapshot?: any): void {
    if(prevProps.vehicleId !== this.props.vehicleId){
      this
        .readPreventiviData(this.props.vehicleId)
        .then((response: any[] | Error) => this.readPreventiviDataHandler(response))
    }
  }
  
  private readPreventiviData(vehicleId: string): Promise<any[] | Error>{
    return UserService.call(
      Constants.WEB_SITE_API_SERVER_URL(`/quotes/vehicle/${vehicleId}/basic-list`),
      {method: "GET"},
      (response: any) => {
        if(response?.success === false){
          // caso di errore applicativo
          // if(response.error_code === "GENERIC" || response.error_code === "VALIDATION_ERROR"){
          //   throw new GenericException("Impossibile completare la richiesta di attivazione dell'account");
          // }
          // else{
          // }
          throw new GenericException("Siamo spiacenti non è stato possibile recuperare i preventivi del veicolo richiesto");
        }
        else {
          if(response.length > 0){
            const quotations = response;
            let today = new Date();
            
            for(let i in quotations){
              // aggiungo due giorni alla data di creazione per capire se, nel caso non abbia preventivi salvati, questa richiesta di preventivo è attiva
              let expire_date = new Date(quotations[i].created_at);
              expire_date.setDate(today.getDate() + 2);
              quotations[i].expire_date = expire_date.toISOString();
              if((quotations[i]?.salvataggi ?? []).length === 0){
                quotations[i].active = (expire_date > new Date());             
              }
            }
            return quotations.sort((a : any, b : any) => a.quote_id < b.quote_id ? 1 : -1);
          }
          else{
            return [];
          }
        }
      },
      (error: any) => {
        return error;
      }
    )
  }

  private readPreventiviDataHandler(response: any[] | Error){
    if(response instanceof Error){
      const error = response;
      if(error instanceof GenericException){
        this.setState({
          error: true,
          errorMsg: error.message,
          isLoading: false
        })
      }
      else{
        this.setState({
          error: true,
          errorMsg: "Si è verificato un errore",
          isLoading: false
        })
      }
    }
    else{
      const quotations = response;
      const peopleList: IDropdownItem[] = [];

      let enrichedQuotation = quotations.map((quotation: any) => {

        let expire_date: Date = new Date(quotation.created_at_datetime);
        expire_date.setDate((new Date()).getDate() + 2);
        const now: Date = new Date();

        if(!peopleList.some((item: IDropdownItem) => item.value === quotation.contractor_id)){
          peopleList.push({
            value: quotation.contractor_id,
            label: User.getPersonSafeName(this.user.getPersonById(quotation.contractor_id)),
          })
        }

        if(quotation?.has_saves){
          return {...quotation, salvataggi: {
            status: Status.LOADING,
            data: []
          }}
        }
        else{
          return {...quotation, active: expire_date > now};
        }

      })

      peopleList.sort((p1: IDropdownItem, p2: IDropdownItem) => {
        let p1Name: string = p1.label.toLowerCase();
        let p2Name: string = p2.label.toLowerCase();
        
        return p1Name > p2Name
          ? 1
          : p1Name < p2Name
            ? -1
            : 0
      })

      this.setState(
        {
          driversDropdown: peopleList,
          quotations: enrichedQuotation,
          isLoading: false
        },
        () => {
          this.state.quotations.forEach((quotation: any) => {
            if(quotation?.salvataggi?.status === Status.LOADING){
              this.getSalvati(quotation)
                .then((response: {active?: boolean, salvataggi: ISalvati}) => {

                  let refinedQuotation: any[] = [
                    ...this.state.quotations.filter((q:any) => q.quote_id !== quotation.quote_id),
                    {...quotation, ...response}
                  ].sort((a : any, b : any) => a.quote_id < b.quote_id ? 1 : -1);

                  this.setState({
                    quotations: refinedQuotation
                  })
                })
            }
          })
        }
      );
    }
  }

  private getSalvati(quotation: any): Promise<{active?: boolean, salvataggi: ISalvati}>{

    return UserService.call(
      // url recupero preventivi salvati
      Constants.WEB_SITE_API_SERVER_URL(`/quotes/vehicle/${this.props.vehicleId}/saves/${quotation.quote_id}`),
      {method: "GET"},
      (response: any) => {
        let yetValid: boolean = false;

        let refinedSaved: any[] = response
          .map((saved: any) => {
            const savedDate = new Date(saved.exp_date.replace(" ", "T"));
            const active: boolean = savedDate > new Date();
            
            if(active && ! yetValid){
              yetValid = true;
            }

            return {
              ...saved,
              active: active
            }
          })

        if(!yetValid){

          let expire_date: Date = new Date(quotation.created_at_datetime);
          expire_date.setDate((new Date()).getDate() + 2);
          const now: Date = new Date();

          yetValid = expire_date > now;
        }

        return {
          active: yetValid,
          salvataggi: {
            status: Status.LOADED,
            data: refinedSaved
          }
        };
      },
      (error: any) => {
        return {
          active: quotation.active,
          salvataggi: {
            status: Status.ERROR,
            data: []
          }
        };
      }
    )
  }

  private applyFilter(quotation: any): boolean{
    let show: boolean = false;

    show = (this.state.filterDriver === -1 || this.state.filterDriver === 0)
      ? true
      : (quotation.contractor_id === this.state.filterDriver)

    if([MonthsLimit.MESI_3, MonthsLimit.MESI_6, MonthsLimit.MESI_12, MonthsLimit.ALL].includes(this.state.filterDate)){
      let limitDate: Date = new Date();
      let skip: boolean = false;
      switch(this.state.filterDate){
        case MonthsLimit.MESI_3:
          limitDate.setMonth(limitDate.getMonth() - 3)
          break;
        case MonthsLimit.MESI_6:
          limitDate.setMonth(limitDate.getMonth() - 6)
          break;
        case MonthsLimit.MESI_12:
          limitDate.setMonth(limitDate.getMonth() - 12)
          break;
        case MonthsLimit.ALL:
        default:
          skip = true;
          break;
      }

      if(!skip){
        show = show && (new Date(quotation.created_at) > limitDate);
      }
      else{
        show = show && true;
      }
    }
    else{
      show = show && true;
    }

    return show;
  }

  render(){
    
    let activeQuotation: any[] = [];
    let archivedQuotation: any[] = [];
    let waitingQuotation: number = 0;
    this.state.quotations.forEach((q: any) => {

      if(!q?.has_saves || (q.has_saves && (q?.salvataggi.status === Status.LOADED))){

        q.active
          ? activeQuotation.push(q)
          : archivedQuotation.push(q);
          
      }
      else{
        waitingQuotation++
      }

    })

    let filteredActiveQuotation: any[] = activeQuotation;
    let filteredArchivedQuotation: any[] = archivedQuotation;

    filteredActiveQuotation = activeQuotation.filter(this.applyFilter);
    filteredArchivedQuotation = archivedQuotation.filter(this.applyFilter);

    return <div className="mainBody">
      { this.state.isLoading ? <StepLoaderSpinner title="Caricamento"/> : "" }

      {this.state.error &&
        <>{this.state?.errorMsg}</>
      }

      {!this.state.error &&
        <>
          <div className="d-flex align-items-center">
            <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/prev-auto.svg`} style={{height: "3rem", marginRight: "1rem"}} alt=""/>
            <h1 className="titleLev3 colorPrimaryBlue mb-0 d-flex align-items-center">{this.currentPage.fullTitle ?? ""}
              {
                (this.vehicleList.length === 1)
                  ? " "+this.nomeVeicolo
                  : <Dropdown
                      className="chooseVehicle"
                      value={this.props.vehicleId}
                      options={this.vehicleList}
                      onChange={(e) => {
                        this.setState(
                          {isLoading: true},
                          () => { this.props.history.push(this.basePage.url.replace(":vehicleId", e.value))}
                        )
                      }}
                    />
              }        
            </h1>
          </div>

          <p className="mt-2">Visualizza tutti i tuoi preventivi richiesti in una sola schermata e confronta le migliori tariffe che abbiamo selezionato per te. Hai trovato l’offerta più giusta per le tue esigenze? Richiedi una consulenza oppure procedi con l’acquisto.</p>
          <Divider></Divider>
          <div className="mt-4 filterContainer">
            <span>Filtra</span>
            <Dropdown
              value={this.state.filterDriver}
              options={[{value: -1, label: "Tutti i contraenti"}, ...this.state.driversDropdown]}
              onChange={(e) => this.setState({filterDriver: e.value})}
              placeholder="Contraente"
            />
            <Dropdown
              value={this.state.filterDate}
              options={this.filterDateSelector}
              onChange={(e) => this.setState({filterDate: e.value})}
              placeholder="Periodo"
            />
          </div>


          <div className="mt-4">

            <Card>
              <div className="cardTitle">
                <h3 className="titleLev4 colorPrimaryBlue">Richieste di preventivo attive</h3>
              </div>
              {
                filteredActiveQuotation.length > 0
                  ? <ul className="quotationList">
                      {filteredActiveQuotation
                        .map((quotation: any) => <li key={`${quotation.quote_id}`}><CardPreventivo quotation={{...quotation, vehicleId: this.props.vehicleId}} /></li>
                        )
                      }
                    </ul>
                  : <p className="px-5 py-4">Non ci sono richieste di preventivo disponibili</p>
              }
              {waitingQuotation > 0 &&
                <p className="px-5 py-4">Placeholder calcolo scadenza preventivi salvati</p>
              }
            </Card>

            <Card className="mt-5 bgColorLightGrey">
              <div className="cardTitle">
                <h3 className="titleLev4 colorPrimaryBlue">Richieste di preventivo archiviate</h3>
              </div>          
              {
                filteredArchivedQuotation.length > 0
                ? <ul className="quotationList">
                    {filteredArchivedQuotation
                      .map((quotation: any) => <li key={`${quotation.quote_id}`}><CardPreventivo quotation={{...quotation, vehicleId: this.props.vehicleId}} /></li>
                      )
                    }
                  </ul>
                : <p className="px-5 py-4">Non ci sono richieste di preventivo archiviate</p>
              }

            </Card>

          </div>
        </>
      }
    
    </div>;
  }

}

const AreaPersonalePreventivi = (props: IPageProps) => {
  const pages = useContext(PagesContext);
  const {params} = useRouteMatch();
  const history: ReturnType<typeof useHistory> = useHistory();
  let vehicleId: string = (params as {vehicleId?: string})?.vehicleId ?? "";

  return <AreaPersonalePreventiviComponent vehicleId={vehicleId} pages={pages} {...props} history={history} />;

};

export default AreaPersonalePreventivi;