import { Card } from "react-bootstrap";
import { Divider } from "primereact/divider";
import { Component, useContext } from "react";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import * as Constants from './utils/constants';
import User from "./utils/User";
import UserService, { GenericException } from "./utils/UserService";
import StepLoaderSpinner from "./StepLoaderSpinner";
import { Dropdown } from "primereact/dropdown";
import { IPerson, IVehicle } from "./utils/common";
import CardPreventivo from "./CardPreventivo";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import '../scss/quotation-row.scss';
import '../scss/area-personale-menu.scss';
import QuoteInfo, {IQuoteDescriptor, IQuoteFieldsDescriptor} from "./utils/QuoteInfo";
import { Dialog } from "primereact/dialog";

interface IDropdownItem{
  value: string | number;
  label: string;
}

interface IPreventiviProps extends IPageProps{
  pages: IPageDescriptor[];
  history: ReturnType<typeof useHistory>;
  contractorId: string;
  contractorType: string;
  quoteType: string;
}

interface IPreventiviState{
  isLoading: boolean;
  dialogOpened: boolean;
  quotations: any[];
  openedQuote: any;
  driversDropdown: IDropdownItem[];
  filterDriver: number;
  filterDate: number;
  prevVehicleId: string;
  error: boolean;
  errorMsg?: string | JSX.Element;
}

enum Status {
  LOADING,
  LOADED,
  ERROR
}

enum MonthsLimit{
  MESI_3 = 1,
  MESI_6 = 2,
  MESI_12 = 3,
  ALL = 0
}

interface ISalvati{
  status: Status;
  data: any[];
}

class QuoteList extends Component<IPreventiviProps, IPreventiviState>{


  private currentPage: IPageDescriptor;
  private basePage: IPageDescriptor;
  private user: User;
  //private refVCard: any;
  private nomeVeicolo: string | undefined;
  private _isMounted: boolean = false;
  private vehicleList: IDropdownItem[] = [];

  private filterDateSelector : IDropdownItem[] = [];

  constructor (props: IPreventiviProps){
    super(props);

    this.user = this.props.user as User;

    this.nomeVeicolo = this.user.vehicles.find((v: IVehicle) => v.id === this.props.contractorId)?.vehicleLicensePlateNumber;

    this.currentPage = this.props.pages.find((p: IPageDescriptor) => p.name === this.props.page?.name) as IPageDescriptor;
    this.basePage = this.props.pages.find((p: IPageDescriptor) => p.name === "preventivi-veicolo") as IPageDescriptor;

    this.state = {
      isLoading: true,
      dialogOpened: false,
      openedQuote: null,
      quotations: [],
      driversDropdown: [],
      filterDriver: -1,
      filterDate: -1,
      prevVehicleId: this.props.contractorId,
      error: false
    }

    this.filterDateSelector = [
      {label: "Tutti", value: MonthsLimit.ALL},
      {label: "Ultimi tre mesi", value: MonthsLimit.MESI_3},
      {label: "Ultimi sei mesi", value: MonthsLimit.MESI_6},
      {label: "Ultimo anno", value: MonthsLimit.MESI_12},
    ]

    this.vehicleList = this.user.vehicles
      .filter(vehicle => vehicle.vehicleLicensePlateNumber !== undefined)
      .map((vehicle: IVehicle) => {
        return {label: User.getVehicleSafeName(vehicle), value: `${vehicle.id}`};
      });

    this.applyFilter = this.applyFilter.bind(this);
  }

  componentDidMount(){
    this._isMounted = true;
    window.onpopstate = () => {
      if(this._isMounted) {
        this.setState(
          {isLoading: true},
          () => {
            this
              .readPreventiviData(this.props.contractorId, this.props.quoteType)
              .then((response: any[] | Error) => this.readPreventiviDataHandler(response))
          }
        )
      }
    }

    this
      .readPreventiviData(this.props.contractorId, this.props.quoteType)
      .then((response: any[] | Error) => this.readPreventiviDataHandler(response))
  }

  componentDidUpdate(prevProps: Readonly<IPreventiviProps>, prevState: Readonly<IPreventiviState>, snapshot?: any): void {
    if(prevProps.contractorId !== this.props.contractorId){
      this
        .readPreventiviData(this.props.contractorId, this.props.quoteType)
        .then((response: any[] | Error) => this.readPreventiviDataHandler(response))
    }
  }
  
  private readPreventiviData(contractorId: string, quoteType:string): Promise<any[] | Error>{
    if(this.props.contractorType == "business")
      var url = `/quote-request/fetch?Contractor_Business_Id=${contractorId}&Quote_Request_Type=${quoteType}`;
    else
      var url = `/quote-request/fetch?Contractor_Type=Person&Quote_Request_Type=${quoteType}`;
    return UserService.call(      
      Constants.WEB_SITE_API_SERVER_URL(url),
      {method: "GET"},
      (response: any) => {
        if(response.length > 0){
          const quotations = response;
          return quotations.sort((a : any, b : any) => a.quote_id < b.quote_id ? 1 : -1);
        }
        else{
          return [];
        }
      },
      (error: any) => {
        return error;
      }
    )
  }

  private readPreventiviDataHandler(response: any[] | Error){
    if(response instanceof Error){
      const error = response;
      if(error instanceof GenericException){
        this.setState({
          error: true,
          errorMsg: error.message,
          isLoading: false
        })
      }
      else{
        this.setState({
          error: true,
          errorMsg: "Si è verificato un errore",
          isLoading: false
        })
      }
    }
    else{
      const quotations = response;      
      this.setState({
        quotations: quotations,
        isLoading: false
      });
    }
  }

  private applyFilter(quotation: any): boolean{
    let show: boolean = false;

    if([MonthsLimit.MESI_3, MonthsLimit.MESI_6, MonthsLimit.MESI_12, MonthsLimit.ALL].includes(this.state.filterDate)){
      let limitDate: Date = new Date();
      let skip: boolean = false;
      switch(this.state.filterDate){
        case MonthsLimit.MESI_3:
          limitDate.setMonth(limitDate.getMonth() - 3)
          break;
        case MonthsLimit.MESI_6:
          limitDate.setMonth(limitDate.getMonth() - 6)
          break;
        case MonthsLimit.MESI_12:
          limitDate.setMonth(limitDate.getMonth() - 12)
          break;
        case MonthsLimit.ALL:
        default:
          skip = true;
          break;
      }
      
      if(!skip){
        show = show && (new Date(quotation.Quote_Request_CreatedDate) > limitDate);
      }
      else{
        show = show && true;
      }
    }
    else{
      show = show && true;
    }

    return show;
  }

  openQuote(quote : any){
    this.setState({
      dialogOpened: true,
      openedQuote: quote
    });
  }

  private ucFirst(string : string){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  formatField(sfFieldName : string, sfFieldValue : string) : [string, string, boolean | undefined]{
    var fieldInfo = QuoteInfo.getQuoteInfoFieldById(this.props.quoteType, sfFieldName);
    var fieldName, fieldValue;
    fieldValue = sfFieldValue;
    if(fieldValue == "true")
      fieldValue = "Si";
    if(fieldValue == "false")
      fieldValue = "No";
    fieldName = fieldInfo?.name;
    if(fieldName === undefined)
      fieldName = "";
    return [fieldName, fieldValue, fieldInfo?.visible];
  }

  printField(sfFieldName : string, sfFieldValue : string){      
    var fieldValue, fieldName;
    if(sfFieldName == "Contractor_Type"){
      if(sfFieldValue == "Person"){
        // recupero il campo relativo al contraente
        return Object.entries(this.state.openedQuote?.Quote_Request_Snapshot).map((elem : any) => {
          var sfFieldName = elem[0];
          var sfFieldValue = elem[1];
          if(sfFieldName == "Contractor_Person_Id"){
            var person : IPerson | undefined = User.getInstance().getPersonById(sfFieldValue);
            return <div className="contractorQuoteDetails" key={sfFieldName}>
              <p className="singleField"><strong>Contraente:</strong> {person?.name} {person?.surname}</p>
              <p className="singleField"><strong>E-mail:</strong> {person?.email}</p>
              <p className="singleField"><strong>Telefono:</strong> {person?.phone}</p>
              <p className="singleField"><strong>Professione:</strong> {this.ucFirst(person?.profession ?? "")}</p>
            </div>
          }
        })
      }
      if(sfFieldValue == "Business"){
        // recupero il campo relativo al contraente
        return Object.entries(this.state.openedQuote?.Quote_Request_Snapshot).map((elem : any) => {
          var sfFieldName = elem[0];
          var sfFieldValue = elem[1];
          if(sfFieldName == "Contractor_Business_Id"){
            var company = User.getInstance().getCompanyById(sfFieldValue);
            return <div className="contractorQuoteDetails" key={sfFieldName}>              
              <p className="singleField"><strong>Ragione sociale:</strong> {company?.legal_name}</p>
              <p className="singleField"><strong>Nome azienda:</strong> {company?.name}</p>
              <p className="singleField"><strong>Indirizzo:</strong> {company?.registered_office_address}</p>
              <p className="singleField"><strong>Referente:</strong> {company?.referent_first_name} {company?.referent_last_name}</p>
              <p className="singleField"><strong>E-mail referente:</strong> {company?.referent_email}</p>
              <p className="singleField"><strong>Telefono referente:</strong> {company?.referent_phone}</p>
              <p className="singleField"><strong>Partita IVA:</strong> {company?.vat}</p>
            </div>
          }
        })
      }      
    }
    else{
      var field = this.formatField(sfFieldName, sfFieldValue);
      field[0] = field[0].replace(" (valori separati da ';')", "");
      if(field[2] === undefined || field[2] === true){
        return <p className="singleField" key={sfFieldName}><strong>{field[0]}:</strong> {field[1]}</p>
      }
      else
        return "";
    }


  }

  render(){
    
    let quotations: any[] = this.state.quotations;
    let waitingQuotation: number = 0;
    let product = QuoteInfo.getQuoteInfoById(this.props.quoteType);
    let openedQuote = this.state.openedQuote;

    return <div className="mainBody">
      { openedQuote &&
          <Dialog
            className=""
            header={<h1 className="titleLev4 colorPrimaryBlue">Assicurazione {product?.name}</h1>}
            visible={this.state.dialogOpened}
            modal={true}
            onHide={() => {this.setState({dialogOpened: false})}}
            resizable={false}
            draggable={false}>
            <div>
              Richiesta di preventivo n.ro {openedQuote?.Quote_Request_Name}
              <div>
                <p className="titleLev5 mt-4">Informazioni richiesta</p>
                <div className="productQuoteDetails">
                  {
                    product?.fields?.map((elem : any) => {
                      for(var key in openedQuote?.Quote_Request_Snapshot){
                        if(key == elem.sfName){
                          return this.printField(elem.sfName, openedQuote?.Quote_Request_Snapshot[key]);
                        }
                      }                      
                    })



                    /*Object.entries(openedQuote?.Quote_Request_Snapshot).map((elem : any) => {
                      var sfFieldName = elem[0];
                      var sfFieldValue = elem[1];
                      return this.printField(sfFieldName, sfFieldValue);
                    })*/
                  }
                </div>
              </div>
            </div>
          </Dialog>
      }

      { this.state.isLoading ? <StepLoaderSpinner title="Caricamento"/> : "" }

      {this.state.error &&
        <>{this.state?.errorMsg}</>
      }

      {!this.state.error &&
        <>
          <div className="d-flex align-items-center">
            <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/prev-auto.svg`} style={{height: "3rem", marginRight: "1rem"}} alt=""/>
            <h1 className="titleLev3 colorPrimaryBlue mb-0 d-flex align-items-center">{this.currentPage.fullTitle ?? ""} {product?.name}
              {
                /*(this.vehicleList.length === 1)
                  ? " "+this.nomeVeicolo
                  : <Dropdown
                      className="chooseVehicle"
                      value={this.props.contractorId}
                      options={this.vehicleList}
                      onChange={(e) => {
                        this.setState(
                          {isLoading: true},
                          () => { this.props.history.push(this.basePage.url.replace(":contractorId", e.value))}
                        )
                      }}
                    />*/
              }        
            </h1>
          </div>

          <p className="mt-2">Visualizza tutti i tuoi preventivi richiesti in una sola schermata e confronta le migliori tariffe che abbiamo selezionato per te. Hai trovato l’offerta più giusta per le tue esigenze? Richiedi una consulenza oppure procedi con l’acquisto.</p>
          <Divider></Divider>
          <div className="mt-4 filterContainer">
            <span>Filtra</span>
            <Dropdown
              value={this.state.filterDate}
              options={this.filterDateSelector}
              onChange={(e) => this.setState({filterDate: e.value})}
              placeholder="Periodo"
            />
          </div>


          <div className="mt-4">

            <Card>
              <div className="cardTitle">
                <h3 className="titleLev4 colorPrimaryBlue mb-0">Richieste di preventivo attive</h3>
              </div>
              <hr className="noMargin"/>
              {
                quotations.length > 0
                  ? <ul className="quotationList">
                      {quotations
                        .map((quotation: any) => <li key={`${quotation.Quote_Request_Id}`}>
                            <div className="quotationRow">
                              <div className="header align-items-end">
                                <p>
                                  <span className="smallParagraph">Numero preventivo:</span><br/>
                                  <span className="titleLev6">#{quotation.Quote_Request_Name}</span>
                                </p>
                              </div>
                              <div className="summary smallParagraph">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <p className="mb-1">
                                      <strong>Contraente:</strong> {
                                        (this.props.quoteType == "Person") ?
                                          User.getPersonSafeName(this.user.getPersonById(quotation.contractor_id)) : User.getInstance().getCompanyById(quotation.Contractor_Business_Id)?.name
                                      }
                                    </p>
                                    <p className="mb-2"><strong>Data:</strong> { (new Date(quotation.Quote_Request_CreatedDate)).toLocaleDateString("it-IT") }</p>
                                    <p className="mb-1">
                                      <span className="colorPrimaryGreen cursor-pointer" onClick={() => {this.openQuote(quotation)}}>
                                        Visualizza dettagli <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/arrow-down-bluegreen.svg`} style={{transform: "rotate(-90deg)", marginLeft: "0.5rem"}} alt=""/>
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-lg-8 text-right xs-text-center">
                                    <Link to={{pathname: QuoteInfo.siteProductsPath+product?.link+"/"}} target="_blank">
                                      <span className="customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">
                                        Richiedi un nuovo preventivo
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      }
                    </ul>
                  : <p className="px-5 py-4">Non ci sono richieste di preventivo disponibili</p>
              }
              {waitingQuotation > 0 &&
                <p className="px-5 py-4">Placeholder calcolo scadenza preventivi salvati</p>
              }
            </Card>            

          </div>
        </>
      }
    
    </div>;
  }

}

export default QuoteList;