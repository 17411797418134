import { Component, createRef, useContext } from "react";
import { Card } from "react-bootstrap";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import User, { IValidationResponse } from "./utils/User";
import * as Constants from './utils/constants';
import PersonRow from "./PersonRow";
import { IPerson, IPerson_WithErrors } from "./utils/common";
import { PersonCard } from "./customs/PersonCard";
import { Divider } from "primereact/divider";
import { AreaPesonalePersonFieldsBuilder } from "./utils/PersonFieldsBuilder";
import StepLoaderSpinner from "./StepLoaderSpinner";
import { isLikelyDate } from "./utils/methods";
import { IFeedbackText } from "./utils/Feedback";
import '../scss/gestione-persone.scss';

interface IGestioneAccountProps extends IPageProps{
  pages: IPageDescriptor[];
}

interface IGestioneAccountState{
  listOfPeople: IPerson[];
  titolareData: IPerson;
  isLoading: boolean;
}

class AreaPersonaleGestionePersoneComponent extends Component<IGestioneAccountProps, IGestioneAccountState>{
  
  private currentPage: IPageDescriptor | undefined;
  private pages: IPageDescriptor[];
  private refPCard: any;

  private readonly personFields : any;

  constructor (props: IGestioneAccountProps){
    super(props);

    this.pages = this.props.pages;

    let listOfPeople: IPerson[] = (this.props.user as User).getPeople(undefined, false);
    listOfPeople.sort((p1, p2) => {
      let p1Name: string = User.getPersonSafeName(p1).toLowerCase();
      let p2Name: string = User.getPersonSafeName(p2).toLowerCase();
      return p1Name > p2Name
        ? 1
        : p1Name < p2Name
          ? -1
          : 0
    });

    this.state = {
      titolareData: (this.props.user as User).userData,
      listOfPeople: listOfPeople,
      isLoading: false,
    }

    this.personFields = AreaPesonalePersonFieldsBuilder();

    this.refPCard = createRef();
    this.currentPage = this.pages.find((p: IPageDescriptor) => p.name === this.props.page?.name);

    this.openCard = this.openCard.bind(this);
    this.showPerson = this.showPerson.bind(this);
    this.newPerson = this.newPerson.bind(this);
    this.updateStatePersonUpsert = this.updateStatePersonUpsert.bind(this);

    this.setLoading = this.setLoading.bind(this);
    this.unsetLoading = this.unsetLoading.bind(this);
    this.deletePerson = this.deletePerson.bind(this);
  }

  private openCard(parameter: any = {}){
    this.refPCard?.current?.show({...parameter});
  }

  private showPerson(p: IPerson_WithErrors){
    let reqFields : any = this.personFields;
    let hideFields: string[] = [];
    if(p.id === (this.props.user as User).userData.id){
      reqFields.phone = {
        field: "phone",
        label_human_readable: "Cellulare",
        validation:{
          mandatory: true,
          fail_severity: "error",
          fail_msg: "Campo obbligatorio"
        }
      }
    }
    else{
      hideFields = ["phone"];
    }

    let parameters = {
      person: p,
      header: <h3 className="colorPrimaryGreen titleLev4">{p !== undefined ? User.getPersonSafeName(p) : "Aggiungi una nuova persona"}</h3>,
      editMode: false,
      showControl: true,
      errors: p?.errors ? p.errors : [],
      errorTexts: p?.errorTexts ? p.errorTexts : [],
      requiredFields: reqFields,
      hideFields: hideFields
    }
    
    this.openCard(parameters);
  }

  private newPerson(){

    this.openCard({
      header: <h3 className="colorPrimaryGreen titleLev4">Nuova persona</h3>,
      editMode: true,
      showControl: false,
      hideFields: ["phone"],
      requiredFields: this.personFields,
      feedbackDialog: false,
      errors: [],
      errorTexts: []
    })
  }

  updateStatePersonUpsert(person: IPerson, validation: IValidationResponse){

    if(validation.status){
      let listOfPeople: IPerson[] = this.state.listOfPeople;
      let titolareData: IPerson = this.state.titolareData;
      (this.props.user as User).setPerson(person);
      
      // TITOLARE
      if(person.id === (this.props.user as User).userData.id){
        //NOTE se cambio le info dello user ricarico la lista delle persone perché potrebbero essere cambiati email o numero di telefono
        // email e numero di telefono delle persone devono essere settati a quelli dello user salvo nuove disposizioni
        listOfPeople = (this.props.user as User).getPeople(undefined, false);
        titolareData = {...person};
      }
      // PERSONA
      else{
        if(this.state.listOfPeople.find((p: IPerson) => p.id === person.id)){
          // UPDATE
          listOfPeople = listOfPeople.map((p: IPerson) => p.id === person.id ? person : p)
        }
        else{
          //INSERT
          listOfPeople = [...listOfPeople, person];
        }
  
        listOfPeople.sort((p1, p2) => {
          let p1Name: string = User.getPersonSafeName(p1).toLowerCase();
          let p2Name: string = User.getPersonSafeName(p2).toLowerCase();
          return p1Name > p2Name
            ? 1
            : p1Name < p2Name
              ? -1
              : 0
        });
      }
  
      this.setState({
        listOfPeople: listOfPeople,
        titolareData: titolareData
      });
    }
  }

  deletePerson(id: string, callback?: Function){
    this.setState(
      {
        listOfPeople: this.state.listOfPeople.filter((person : IPerson) => person.id !== id),
        isLoading: false
      },
      () => {
        (this.props.user as User).removePerson(id);
        callback?.()
      }
    );
  }

  setLoading(callback?: Function){
    this.setState(
      {isLoading: true},
      () => {callback?.()}
    );
  }

  unsetLoading(callback?: Function){
    this.setState(
      {isLoading: false},
      () => {callback?.()}
    );
  }

  ageValidation(dateString: string) : {dateOfBirth: Date | undefined; error: string | undefined, feedback: IFeedbackText | undefined}{
    
    let dateOfBirth : Date | undefined;
    let error: string | undefined;
    let feedback: IFeedbackText | undefined;
    
    if(dateString){
      if(isLikelyDate(dateString)){
        const [day, month, year] : [day: string, month : string, year : string] = dateString.split("/") as [string, string, string];
        dateOfBirth = new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00Z`);
  
        if(dateOfBirth instanceof Date && !isNaN(dateOfBirth.getTime())){
          const today = new Date();
          const age = today.getFullYear() - dateOfBirth.getFullYear();
    
          if(age > Constants.DOB_YEAR_RANGE_MAX){ // too old
            error = "dateOfBirth";
            feedback = {
              field: "dateOfBirth",
              msg: "Età invalida. Il contraente deve avere meno di 100 anni."
            };
          }
          else{
            error = undefined;
            feedback = undefined;
          }
        }
        else{
          dateOfBirth = undefined;
          error = "dateOfBirth";
          feedback = {field: "dateOfBirth", msg: "Data invalida"};
        }
      }
      else{
        dateOfBirth = undefined;
        error = "dateOfBirth";
        feedback = {field: "dateOfBirth", msg: "Data invalida"};
      }
    }
    else{
      dateOfBirth = undefined;
      error = "dateOfBirth";
      feedback = {field: "dateOfBirth", msg: "Data invalida"};
    }

    return {dateOfBirth: dateOfBirth, error: error, feedback: feedback};
  }

  render(){
    let styleLabel = {
      display: "inline-block",
      color: "#2DACA6",
      cursor: "pointer"
    }
    return <div className="mainBody">
      { this.state.isLoading ? <StepLoaderSpinner title="Caricamento"/> : "" }
      <PersonCard
        ref={this.refPCard}
        visible={false}
        user={(this.props.user as User)}
        successCallback={this.updateStatePersonUpsert}
        requiredFields={this.personFields}
        ageValidation={this.ageValidation}
        setResourceListRequest={this.props.setResourceListRequest}
      />

      <div className="d-flex align-items-center">
        <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/gest-persone.svg`} style={{height: "3rem", marginRight: "1rem"}} alt=""/>
        <h1 className="titleLev3 colorPrimaryBlue mb-0 d-block">{this.currentPage?.fullTitle ?? ""}</h1>
      </div>

      <p className="mt-2">Con 6sicuro.it puoi gestire le tue informazioni e quelle delle persone a te più care. Visualizza o modifica i dati dei profili associati oppure creane uno nuovo. Più comodo di così!</p>
      <Divider></Divider>
      <div className="mt-4">       

        <Card title="Titolare dell'account">            
          <h3 className="titleLev4 colorPrimaryBlue cardTitle">Titolare dell'account</h3>
          <PersonRow
            titolare={true}
            person={this.state.titolareData}
            openCard={() => this.showPerson(this.state.titolareData)}
            user={(this.props.user as User)}
          />
        </Card>


        <Card className="mt-5" title="Persone associate all'account">            
          <div className="cardTitle simpleBorderBottom">
            <h3 className="titleLev4 colorPrimaryBlue mb-3">Persone associate all'account</h3>
            <span style={styleLabel} onClick={() => this.newPerson()}>+ Aggiungi persona all'account</span>
          </div>
          <ul className="peopleList">
            {
              this.state.listOfPeople.map((person: IPerson, index) =>
                <li key={person.id}>
                  <PersonRow
                    person={person}
                    setLoading={this.setLoading}
                    unsetLoading={this.unsetLoading}
                    deleteCallback={this.deletePerson}
                    openCard={this.showPerson}
                  />
                </li>
              )
            }            
          </ul>
          {
            this.state.listOfPeople.length > 0 &&
              <div className="cardTitle">
                <span style={styleLabel} onClick={() => this.newPerson()}>+ Aggiungi persona all'account</span>
              </div>
          }
        </Card>


      </div>
      
    </div>

    
  }
}

const AreaPersonaleGestionePersone = (props: IPageProps) => {

  const pages = useContext(PagesContext);
  return <AreaPersonaleGestionePersoneComponent pages={pages} {...props} />;

};

export default AreaPersonaleGestionePersone;