import { Component } from 'react';
import * as Constants from './utils/constants';
import { IPerson, Step1Data, Step3Data } from './utils/common';
import { Button } from 'primereact/button';
import User from './utils/User';
import { Dialog } from 'primereact/dialog';
import UserService from './utils/UserService';
import '../scss/person-row.scss';
import { connect, useSelector } from 'react-redux';


interface PersonRowProps{
  person: IPerson,
  titolare?: boolean,
  openCard: Function;
  step1Data: any;
  step3Data: any;
  setLoading?: (callback?: Function) => void,
  unsetLoading?: (callback?: Function) => void,
  deleteCallback?: (id: string, callback?: Function) => void
}
interface PersonRowState{
  dialogOpened: boolean;
  dialogMsg: JSX.Element;
  dialogHeader: JSX.Element;
  errorDialogOpened: boolean;
  isLoading: boolean;
  personToDelete: IPerson | null;
}

export class PersonRowComponent extends Component<PersonRowProps, PersonRowState>{

  constructor(props: PersonRowProps){
    super(props);
    this.state = {
      dialogOpened: false,
      dialogHeader: <></>,
      dialogMsg: <></>,
      errorDialogOpened: false,
      personToDelete: null,
      isLoading: true,
    }
  }

  // controlla se la persona è coinvolta nel preventivo in corso
  isInvolvedInQuotation(id: string): boolean{

    let arr: string[] = [];
    Object.keys(this.props.step1Data).forEach((v: string) => {
      const v_k = v as keyof Step1Data;
      const s1d: Step1Data = this.props.step1Data[v_k];
      const s3d: Step3Data = this.props.step3Data[v_k];

      if(s1d?.contractorId){
        arr.push(s1d.contractorId as string)
      }
      if(s3d?.driverId){
        arr.push(s3d.driverId as string)
      }
      if(s3d?.ownerId){
        arr.push(s3d.ownerId as string)
      }
    })

    return arr.includes(id);

  }

  private openDialogDelete(person: IPerson){

    this.setState({
      dialogOpened: true,
      dialogHeader: <>Vuoi davvero eliminare la persona {User.getPersonSafeName(person)}?</>,
      dialogMsg: this.isInvolvedInQuotation(person.id as string)
        ? <p>È in corso un preventivo per {person.name || User.getPersonSafeName(person)},<br/>confermando l'eliminazione verrano cancellati anche i dati del preventivo in corso</p>
        : <p>Confermando l'eliminazione perderai tutti i dati di {person.name || User.getPersonSafeName(person)}</p>,
      personToDelete: person,
    });
  }

  private deletePerson(){
    this.setState(
      {
        dialogOpened: false,
        isLoading: true
      },
      () => {
        this.props.setLoading?.(() => {
          const id: string = this.state.personToDelete?.id as string;

          UserService.call(
            Constants.WEB_SITE_API_SERVER_URL(`/persons/${id}`),
            {
              method: "DELETE"
            },
            (response: any) => {
              let newState: PersonRowState = this.state;
              newState.dialogOpened = false;
      
              if(response?.success === false){
                this.setState(
                  {
                    errorDialogOpened: true,
                    isLoading: false
                  },
                  () => this.props.unsetLoading?.()
                );
              }
              else{
                
                this.setState(
                  {isLoading: false},
                  () => {
                    //controllo se cancellare il preventivo in corso
                    if(this.isInvolvedInQuotation(id)){
                      ["auto", "moto"].forEach((v: string) => User.resetStepData(v))
                    }
                    this.props.deleteCallback?.(id)
                  }
                );
              }
            },
            (err: any) => {
              this.setState({
                errorDialogOpened: true,
                dialogOpened: false,
              })
              
              this.props.unsetLoading?.();
            }
          );
        });
    });

  }

  render() {
    let dateOfBirth : string;
    const person: IPerson = this.props.person;

    if(!person?.dateOfBirth)
      dateOfBirth = "-";
    else{
      let date = person.dateOfBirth;
      dateOfBirth = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    }
    
    let gender : string;
    if(person?.gender){
      gender = person.gender === "uomo" ? "Uomo" : person.gender === "donna" ? "Donna" : "Non definito";
    }
    else{
      gender = "-";
    }

    return <div className="personRow cardTitle">
      <Dialog
        className="customDialog error"
        header={this.state.dialogHeader}
        visible={this.state.dialogOpened}
        modal={true}
        onHide={() => {this.setState({dialogOpened: false})}}
        resizable={false}
        draggable={false}
        footer={
          <div>
              <Button label="No, annulla" className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.setState({dialogOpened: false})}} />

              <Button label="Si, elimina" className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.deletePerson()}} />
          </div>
        }>
        {this.state.dialogMsg}
      </Dialog>

      <Dialog
        className="customDialog error"
        header="Attenzione!"
        visible={this.state.errorDialogOpened}
        onHide={() => {this.setState({dialogOpened: false})}}
        modal={true}
        resizable={false}
        draggable={false}
      >
        
        <p>Errore generico. Riprovare.</p>        
      </Dialog>

      <div className={"row"+((this.props?.titolare === true) ? '' : ' align-items-center')}>
        <div className="col-3 col-lg-2">
          <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/icon-persona.svg`} className="iconPersona" alt=""/>
        </div>
        <div className="col-9 col-lg-6">
          <h3 className='titleLev6 colorPrimaryGreen mb-0'>{User.getPersonSafeName(person)}</h3>
          
            {
              (this.props?.titolare === true) &&
                <div className="row mt-3">

                  <div className="col-6">
                    <p><span className="smallParagraph">Sesso</span><br/>{gender}</p>
                    <p><span className="smallParagraph">N.ro di telefono</span><br/>{ person.phone }</p>
                  </div>  
                  <div className="col-6">
                    <p><span className="smallParagraph">Data di nascita</span><br/>{ dateOfBirth }</p>
                  </div>  
                </div>
            }
          
        </div>
        <div className="col-lg-4" style={{textAlign: "right"}}>
          <div className="blockCta">
            <div>
              <Button
                className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                label="Visualizza dettagli"
                onClick={() => this.props.openCard(person)}
              />
              {!this.props?.titolare &&
                <Button
                  className="deleteBtn"
                  label="Elimina persona"
                  onClick={() => this.openDialogDelete(this.props.person)} />
              }
              
            </div>
          </div>
        </div>
      </div>      
    </div>    
  }
}

const mapState = (state: any) => {
  return {
    loginVisible: state.userInfoData.user.openLogin,
    step1Data: state.step1Data
  };
}

const PersonRow = (props: any) => {
  const step1Data: any = useSelector((state: any) => {return state.step1Data});
  const step3Data: any = useSelector((state: any) => {return state.step3Data});

  return <PersonRowComponent
    {...props}
    step1Data={step1Data}
    step3Data={step3Data}
  />

};

export default connect(mapState)(PersonRow);