import { Card } from "react-bootstrap";
import { Divider } from "primereact/divider";
import { Component, createRef, useContext } from "react";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import { VehicleCard } from "./customs/VehicleCard";
import { IVehicle } from "./utils/common";
import * as Constants from './utils/constants';
import User from "./utils/User";
import { Dropdown } from "primereact/dropdown";
import StepLoaderSpinner from "./StepLoaderSpinner";
import { AreaPersonaleAutoMotoFieldsBuilder } from "./utils/VehicleFieldBuilder";
import VehicleRow from "./VehicleRow";
import '../scss/gestione-veicoli.scss';


interface IQuotation{
  idVeicolo: string;
  idQuote: string | number
}

interface IGestioneAccountProps extends IPageProps{
  pages: IPageDescriptor[]
}

interface IGestioneAccountState{
  filterVehicleType: string;
  listOfVehicle: IVehicle[],
  listOfQuotation: IQuotation[],
  isLoading: boolean
}

class AreaPersonaleAutoMotoComponent extends Component<IGestioneAccountProps, IGestioneAccountState>{

  private currentPage: IPageDescriptor | undefined;
  private pages: IPageDescriptor[];
  private user: User;
  private refVCard: any;
  private requiredFields: any;

  constructor (props: IGestioneAccountProps){
    super(props);

    this.pages = this.props.pages;
    this.user = this.props.user as User;

    let listOfVehicles: IVehicle[] = this.user.getVehicles((vehicle: IVehicle) => vehicle.vehicleLicensePlateNumber !== undefined);
    listOfVehicles.sort((v1, v2) => {
      let p1: string = v1.vehicleLicensePlateNumber?.toLowerCase() ?? "";
      let p2: string = v2.vehicleLicensePlateNumber?.toLowerCase() ?? "";
      return p1 > p2
        ? 1
        : p1 < p2
          ? -1
          : 0
    });

    this.state = {
      filterVehicleType: "",
      listOfVehicle: listOfVehicles,
      listOfQuotation: [],
      isLoading: false
    }
    
    this.refVCard = createRef();
    this.currentPage = this.pages.find((p: IPageDescriptor) => p.name === this.props.page?.name);
    this.requiredFields = {
      auto: AreaPersonaleAutoMotoFieldsBuilder("auto", true),
      moto: AreaPersonaleAutoMotoFieldsBuilder("moto", true),
      none: AreaPersonaleAutoMotoFieldsBuilder("", true),
    };
    this.openCard = this.openCard.bind(this);
    this.filterFor = this.filterFor.bind(this);
    this.updateStateVehicleSave = this.updateStateVehicleSave.bind(this);
    
    this.setLoading = this.setLoading.bind(this);
    this.unsetLoading = this.unsetLoading.bind(this);
    this.deleteVehicle = this.deleteVehicle.bind(this);
  }

  private openCard(v?: IVehicle, option: any = {}){
    this.refVCard?.current?.show({
      vehicle: v !== undefined ? v : (["auto","moto"].includes(this.state.filterVehicleType) ? {vehicleType: this.state.filterVehicleType} : undefined),
      header: <h3 className="colorPrimaryGreen title titleLev4">{v !== undefined ? User.getVehicleSafeName(v) : (["auto","moto"].includes(this.state.filterVehicleType) ? `Aggiungi nuova ${this.state.filterVehicleType}` : "Aggiungi un nuovo veicolo")}</h3>,
      ...option,
      hideFields: ["auto","moto"].includes(this.state.filterVehicleType) ? ["vehicleType"] : option?.hideFields,
      requiredFields:  ["auto","moto"].includes(this.state.filterVehicleType) 
      ? this.state.filterVehicleType === "auto" ? this.requiredFields.auto : this.requiredFields.moto
      : option?.requiredFields,
      successCallback: (v: IVehicle) => {this.setState({listOfVehicle: [...this.state.listOfVehicle, v]})}
    })
  }

  private filterFor(type: string){
    this.setState({
      filterVehicleType: type,
      listOfVehicle: this.user.getVehicles((v: IVehicle) => v.vehicleType === type)
    })
  }

  updateStateVehicleSave(vehicle: IVehicle){
    this.user.setVehicle(vehicle)

    let listOfVehicles: IVehicle[] = [...this.state.listOfVehicle.filter((v: IVehicle) => v.id !== vehicle.id), vehicle];
    listOfVehicles.sort((v1, v2) => {
      let p1: string = v1.vehicleLicensePlateNumber?.toLowerCase() ?? "";
      let p2: string = v2.vehicleLicensePlateNumber?.toLowerCase() ?? "";
      return p1 > p2
        ? 1
        : p1 < p2
          ? -1
          : 0
    });

    this.setState({
      listOfVehicle: listOfVehicles
    })
  }

  deleteVehicle(id: string, callback?: Function){
    this.setState(
      {
        listOfVehicle: this.state.listOfVehicle.filter((vehicle : IVehicle) => vehicle.id !== id),
        isLoading: false
      },
      () => {
        (this.props.user as User).removeVehicle(id);
        callback?.()
      }
    );
  }

  setLoading(callback?: Function){
    this.setState(
      {isLoading: true},
      () => {callback?.()}
    );
  }
    
  unsetLoading(callback?: Function){
    this.setState(
      {isLoading: false},
      () => {callback?.()}
    );
  }
    
  render(){
    let styleLabel = {
      display: "inline-block",
      color: "#2DACA6",
      cursor: "pointer"
    }
    return <div className="mainBody">
      { this.state.isLoading ? <StepLoaderSpinner title="Caricamento"/> : "" }
      <VehicleCard
        ref={this.refVCard}
        visible={false}
        user={this.user}
        showControl={true}
        successCallback={this.updateStateVehicleSave}
        editMode={false}
        requiredFields={this.requiredFields}
        setResourceListRequest={this.props.setResourceListRequest}
      />

      <div className="d-flex align-items-center">
        <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/prev-auto.svg`} style={{height: "3rem", marginRight: "1rem"}} alt=""/>
        <h1 className="titleLev3 colorPrimaryBlue mb-0 d-block">{this.currentPage?.fullTitle ?? ""}</h1>
      </div>

      <p className="mt-2">Ecco il tuo garage personale! Hai richiesto un calcolo di preventivo assicurazione per l’auto o moto? In questa sezione trovi i preventivi calcolati per tutti i tuoi veicoli, facilmente visualizzabili e raggruppati per ogni persona presente nel tuo account.</p>
      <Divider></Divider>

      <div className="mt-4 filterContainer">
        <span>Filtra</span>
        <Dropdown value={this.state.filterVehicleType}
        options={[
          {label: "Auto", value: "auto"},
          {label: "Moto", value: "moto"},
        ]} onChange={(e) => this.filterFor(e.value)} placeholder="Tipo veicolo"/>
      </div>

      <div className="mt-4">

        <Card>            
          <div className="cardTitle simpleBorderBottom">
            <h3 className="titleLev4 colorPrimaryBlue">Veicoli registrati</h3>
            <span style={styleLabel} onClick={() => this.openCard(undefined, {
              showControl: true,
              editMode: true,
              requiredFields: this.requiredFields.none,
              hideFields: []
            })}>+ Aggiungi nuovo veicolo</span>
          </div>

          <div>
            {this.state.listOfVehicle.length > 0
              ? 
                <ul className="vehicleList">
                  {
                    this.state.listOfVehicle.map((vehicle: IVehicle, index) =>
                      <li key={vehicle.id}>
                        <VehicleRow
                          vehicle={vehicle}
                          setLoading={this.setLoading}
                          unsetLoading={this.unsetLoading}
                          deleteCallback={this.deleteVehicle}
                          quotation={
                            (this.state.listOfQuotation.length === 0) ? 0 :  
                            this.state.listOfQuotation.filter(quote => quote.idVeicolo === vehicle.id).length                    
                          }
                          openCard={() => this.openCard(vehicle, {
                            header: <h3 className="colorPrimaryGreen titleLev4">{User.getVehicleSafeName(vehicle)}</h3>,
                            showControl: true,
                            editMode: false,
                            requiredFields: (vehicle.vehicleType === "auto") ? this.requiredFields.auto : this.requiredFields.moto,
                          })}
                        />
                      </li>
                    )
                  }
                </ul>
              : <p className="px-5 py-4">Nessun veicolo disponibile</p>
            }
          </div>

          {
            this.state.listOfVehicle.length > 0 &&
              <div className="cardTitle">
                <span style={styleLabel} onClick={() => this.openCard(undefined, {showControl: false, editMode: true, hideFields: [],
                successCallback: (v: IVehicle) => {this.setState({listOfVehicle: [...this.state.listOfVehicle, v]})}
                })}>+ Aggiungi nuovo veicolo</span>
              </div>
          }

        </Card>

      </div>
    
    </div>;
  }

}

const AreaPersonaleAutoMoto = (props: IPageProps) => {

  const pages = useContext(PagesContext);
  return <AreaPersonaleAutoMotoComponent pages={pages} {...props} />;

};

export default AreaPersonaleAutoMoto;