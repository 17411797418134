import { Component } from 'react';
import striptags from 'striptags';
import { AllHtmlEntities } from 'html-entities';
import { Card } from 'primereact/card';
import { DataTable, DataTableSortParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
import PersistedStore from '../redux/persistedStore';
import { step2DataSuccess, step4DataSuccess, userInfoDataSuccess } from '../redux/actions';
import * as Constants from './utils/constants';
import { IVehicle, Step1Data, Step2Data, Step3Data, Step4Data } from './utils/common';
import { isLicensePlateAutoValid, isLicensePlateMotoValid } from './utils/validate';
import QuotationService from './utils/QuotationService';
import { fetchList, formatDateAdform, encryptEmail, postData, manageOptionSelected, formataDateToString, priceFormatter, removeDuplicates } from './utils/methods';
import NoQuotation from './customs/NoQuotationComponent';
import moment from 'moment';
import WarrantyDetail from './customs/WarrantyDetail';
import QuotationColumnImage from './customs/QuotationColumnImage';
import QuotationColumnInfo from './customs/QuotationColumnInfo';
import QuotationColumnPrice from './customs/QuotationColumnPrice';
import QuotationColumnActions from './customs/QuotationColumnActions';
import { ContextFeedback } from './utils/Feedback';
import { connect } from 'react-redux';
import { StepDataDualState } from '../redux/reducers';
import RiepilogoDati from './customs/RiepilogoDati';
import withEventManager from './customs/HOC/withEventManager';
import { compose } from 'ramda';
import { IWithEventManager } from './customs/HOC/EventManager';

type FilterFunction = (company: any) => boolean;

interface FilterableItem{
  description: string,
  fullDescription?: string,
  status: boolean | undefined
}

interface CheckboxFilter{
  rateizzazione: FilterableItem,
  emissioneDiretta: FilterableItem
}

interface IStep4Props extends IWithEventManager{
  vehicle: string;
  setStep: Function;
  step2DataSuccess: Function;
  step4DataSuccess: Function;
  step1Data: StepDataDualState;
  step2Data: StepDataDualState;
  step3Data: StepDataDualState;
  step4Data: StepDataDualState;
}

interface IStep4State{
  quotations: any[], 
  expandedRows: any,
  noQuotations: any,
  options: any,
  selectedOptions: any, 
  orderQuotation: any,
  orderQuotationList: any,
  scatolaNera: any,
  progress: number,
  progressCompleted: boolean,
  displayAddLicensePlate: boolean,
  licensePlate: any,
  errors: any[],
  displayProposalAdequacy: boolean,
  upsellingResponse: any,
  upsellingVisible: boolean,
  upsellingSelectedRowData: any,
  sortField: any,
  sortOrder: any,
  warrantiesLock: boolean,
  forceShow: boolean,
  loadingFilter: boolean,
  displayMoreThan60Days: boolean,
  warrantiesFromBo: boolean,

  currentWarrantyDetail?: any | undefined,
  dialogFilterVisible: boolean,
  dialogGaranzieVisible: boolean,
  dialogRiepilogoVisible: boolean,
  dialogOrdinamentoVisible: boolean,

  filters: CheckboxFilter;
}

class Step4Component extends Component<IStep4Props, IStep4State> {
  private vehicle: any;
  private currentRowData = undefined;
  private currentRowIsScegliButton = false;
  private isChangeOption = false;
  private timeOut: any;
  private store: any;
  private abortController: AbortController = new AbortController();

  private unsubscriber: any = null;
  private _isMounted = false;

  private maxInsuranceAviabilityDate = moment().add(60, 'days').format("DD/MM/YYYY");

  // tempo max che deve passare tra un click e l'altro sulla spunta di una garanzia, prima di avviare la chiamata
  private readonly warrantiesDelay = 1500;
  private readonly blackBoxDelay = 200;

  constructor(props: any) {
    super(props);
    // this.vehicle = props.params.vehicle;

    this.vehicle = this.props.vehicle;
    this.store = PersistedStore.getDefaultStore().store;

    var optionsDataAuto = Constants.ADDITIONAL_GUARANTEES_AUTO;
    var optionsDataMoto = Constants.ADDITIONAL_GUARANTEES_MOTO;
    
    var orderQuotationListData = [{ "id": "default", "label": "Scegli" }, { "id": "price_desc", "label": "Ordina per meno caro" }, { "id": "price_asc", "label": "Ordina per più costoso" }, { "id": "company", "label": "Ordina per compagnia" }, { "id": "massimale_rca", "label": "Massimale RCA" }];

    let step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;

    this.state = {
      quotations: [],
      expandedRows: null,
      noQuotations: [],
      options: this.vehicle === "auto" ? optionsDataAuto : optionsDataMoto,
      selectedOptions: (step4Data.selectedOptions !== undefined) ? removeDuplicates(step4Data.selectedOptions) : [],
      orderQuotation: orderQuotationListData[1],
      orderQuotationList: orderQuotationListData,
      scatolaNera: (step3Data.includeSatProducts !== undefined) ? !(step3Data.includeSatProducts) : false,
      progress: 0,
      progressCompleted: false,
      displayAddLicensePlate: false,
      licensePlate: '',
      errors: [],
      displayProposalAdequacy: false,
      upsellingResponse: {},
      upsellingVisible: false,
      upsellingSelectedRowData: undefined,
      sortField: "total_price",
      sortOrder: 1,
      warrantiesLock: false,
      forceShow: false,
      loadingFilter: false,
      displayMoreThan60Days: false,
      warrantiesFromBo: (step4Data.warrantiesFromBo !== undefined) ? step4Data.warrantiesFromBo : false,

      currentWarrantyDetail: undefined,
      dialogFilterVisible: false,
      dialogGaranzieVisible: false,
      dialogRiepilogoVisible: false,
      dialogOrdinamentoVisible: false,

      filters: {
        rateizzazione: {
          description: "Rateizzabile",
          fullDescription: "Mostra solo le compagnie che permettono la rateizzazione",
          status: undefined
        },
        emissioneDiretta: {
          description: "Emissione immediata",
          fullDescription: "Mostra solo le compagnie che permettono l'emissione immediata",
          status: undefined
        }
      }
    };


    // this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    // this.imageCompanyBodyTemplate = this.imageCompanyBodyTemplate.bind(this);
    // this.actionsBodyTemplate = this.actionsBodyTemplate.bind(this);
    this.handleRedirectToQuotation = this.handleRedirectToQuotation.bind(this);
    this.validation = this.validation.bind(this);
    this.addLicensePlate = this.addLicensePlate.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onQuotationReceived = this.onQuotationReceived.bind(this);
    this.onChangeOrderQuotations = this.onChangeOrderQuotations.bind(this);
    this.onQuotationWarrantiesReceived = this.onQuotationWarrantiesReceived.bind(this);
    this.wrrantiesDoneCallBack = this.wrrantiesDoneCallBack.bind(this);
    this.validateInsuranceDate = this.validateInsuranceDate.bind(this);

    this.handleWarrantyDetail = this.handleWarrantyDetail.bind(this);
    this.handleShowUpselling = this.handleShowUpselling.bind(this);

    this.onSelectScatolaNera = this.onSelectScatolaNera.bind(this);

    this.setFilter = this.setFilter.bind(this);
    this.filterRateizzazione = this.filterRateizzazione.bind(this);
    this.filterEmissioneDiretta = this.filterEmissioneDiretta.bind(this);
  }

  componentDidMount() {
    //fix azzero il numero di chiamate da fare
    console.log("Step4 mounted");
    this._isMounted = true;
    this.props.setStep(4)
    QuotationService.resetRequestCounter();
    this.subscribeToStoreChanges();
  }
  
  componentWillUnmount(): void {
    this._isMounted = false;
    console.log("Step4 unmounted");
    this.abortController.abort()
    QuotationService.resetRequestCounter();
    // this.unsubscriber?.();
  }

  /**
   * Return HTML list of array object.
   * 
   * @param {any} data Element list
   * @returns {string} HTML list
   */
  listBodyTemplate(data: any): string {
    var output: any = '';
    if (data.length) {
      output = <ul>{data.map((item: any, i: any) => <li key={item + i}>{item}</li>)}</ul>;
    }
    return output;
  }

  /**
   * Return HTML of data inforamation.
   * 
   * @param {any} item Data information
   * @returns {boolean} true if item is the best warranty else false
   */
  private isBestWarranty(item: any, quotations?: any[]) : boolean{
    return false;
    /*const localQuotation : any[] = quotations ? quotations : this.state.quotations;
    const best : any = localQuotation.reduce((prev, curr) => prev.total_price < curr.total_price ? prev : curr, item);
    return best === item;*/
  }

  /**
   * Return HTML template item for quotation not available.
   * 
   * @param {any }data 
   * @returns HTML tempalte
   */
  noQuotationItemTemplate(data: any) {
    if (!data) {
      return;
    }
    return (
      <div className="p-col-12">
        <div className="no-quotation-item">
          <div className="row">
            <div className="company-image col-md-12 col-lg-3">
              <img src={Constants.SEISICURO_INSURANCE_COMPANY_LOGO_URL(data.company_logo)} onError={(e: any) => e.target.src = Constants.SEISICURO_FALLBACK_IMAGE} alt={data.name} />
            </div>
            <div className="company-description col-md-12 col-lg-9">
              <div className="product-description" dangerouslySetInnerHTML={{ __html: data.error_message }}></div>
            </div>
          </div>
        </div>
      </div>
    );

  }

  /**
   * On change checkbox options -- gestisce le garanie
   * 
   * @param {any} e Option change event
   */
  onOptionChange(e: any) {
    if (typeof this.timeOut === 'number') {
      // al nuovo click cancello il vecchio.. non faccio partire la chiamata
      clearTimeout(this.timeOut);
    }

    let selectedOptions = [...this.state.selectedOptions];

    let warranties = "1";

    if (e.checked) {
      // selectedOptions.filter((opt: {key: string, value: string}) => opt.value !== e.value).push(e.value);

      !selectedOptions.some((opt: {key: string, value: string}) => opt.value === e.value) && selectedOptions.push(e.value);
    }
    else {
      for (let i = 0; i < selectedOptions.length; i++) {
        const selectedOption = selectedOptions[i];

        if (selectedOption.key === e.value.key) {
          selectedOptions.splice(i, 1);
          break;
        }
      }
    }

    // evito che parta un nuovo ciclo di quotazioni
    this.isChangeOption = true;

    this.setState({ selectedOptions: selectedOptions, warrantiesFromBo: false }, () => {
      for (let j = 0; j < selectedOptions.length; j++) {
        // vecchia versione: warranties += `,${selectedOptions[j].value}`;
        // nel caso in cui selectedOptions[N] === 1
        // mi ritrovavo con warranties = 1,1  ---> cioè andavo a chiedere alla QuoteServlet stringhe del tipo: 1,1,1,7,7
        if (selectedOptions[j].value && !warranties.includes(selectedOptions[j].value)) {
          warranties += `,${selectedOptions[j].value}`;
        }
      }

      const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;
      // fix: salvo le garanzie accessorie spuntate! sta roba si regge sullo sputo!
      this.props.step4DataSuccess({selectedOptions: manageOptionSelected(warranties, this.vehicle) }, this.vehicle);

      QuotationService.warranties = warranties;
      QuotationService.setCallback(this.onQuotationWarrantiesReceived);

      this.timeOut = setTimeout(() => {
        this.setState({ forceShow: true, quotations: [], noQuotations: [], loadingFilter: true, warrantiesLock: true }, () => {
          QuotationService.executeWarrantiesRequest(
            step4Data.prevId ?? "",
            step4Data.calcoloId ?? "",
            this.vehicle === 'auto' ? '1' : '2', true, this.timeOut,
            this.abortController.signal
          );
          this.trackFilterApplied();
        });

      }, this.warrantiesDelay);
    });
  }

  /**
   * Filter quotations by statola nera
   * 
   * @param {any} e Scatola nera select value
   */
  onSelectScatolaNera(e: any) {
    if (typeof this.timeOut === 'number') {
      // al nuovo click cancello il vecchio.. non faccio partire la chiamata
      clearTimeout(this.timeOut);
    }
    const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;

    this.timeOut = setTimeout(() => {
      this.setState({ scatolaNera: e.value, forceShow: true, quotations: [], noQuotations: [], loadingFilter: true, warrantiesLock: true, warrantiesFromBo: false });

      QuotationService.hideBlackBox = e.value;
      QuotationService.setCallback(this.onQuotationWarrantiesReceived);

      QuotationService.executeWarrantiesRequest(
        step4Data.prevId ?? "",
        step4Data.calcoloId ?? "",
        this.vehicle === 'auto' ? '1' : '2', true, this.timeOut,
        this.abortController.signal
      );
      this.trackFilterApplied();
    }, this.blackBoxDelay);
  }

  /**
   * Handles the data received by the various Warranties QuotServlet requests.
   * 
   * @param quotations the received quotation list
   * @param noQuotations the received noQuotation list
   * @param progress the current progress bar value
   */
  onQuotationWarrantiesReceived(quotations: any[], noQuotations: any[], progress: number) {
    // tolgo i <br> in eccesso
    for (let i = 0; i < quotations.length; i++) {
      if (quotations[i].short_info) {
        let pulito = quotations[i].short_info.replace("+ <br/>", "+ ");
        pulito = pulito.replace("+ <br>", "+ ");
        quotations[i].short_info = pulito;
      }
    }

    if (quotations.length > 0 && noQuotations.length > 0) {
      this.setState({ progress: progress, quotations: quotations, noQuotations: noQuotations, progressCompleted: true, loadingFilter: false, warrantiesLock: false });
    } else if (quotations.length > 0) {
      this.setState({ progress: progress, quotations: quotations, progressCompleted: true, loadingFilter: false, warrantiesLock: false });
    } else if (noQuotations.length > 0) {
      this.setState({ progress: progress, noQuotations: noQuotations, progressCompleted: true, loadingFilter: false, warrantiesLock: false });
    } else if (noQuotations.length === 0 && quotations.length === 0) {
      this.setState({ progress: progress, progressCompleted: true, loadingFilter: false, warrantiesLock: false });
    } else {
      this.setState({ loadingFilter: false, warrantiesLock: false });
    }
  }

  /**
   * Change quotation order list
   * 
   * @param {any} selected Quotation order selected
   */
  onChangeOrderQuotations(selected: any) {
    if (selected.value.id === 'price_desc') {
      this.setState({ orderQuotation: selected.value, sortField: "total_price", sortOrder: 1 });
    }
    if (selected.value.id === 'price_asc') {
      this.setState({ orderQuotation: selected.value, sortField: "total_price", sortOrder: -1 });
    }
    if (selected.value.id === 'company') {
      this.setState({ orderQuotation: selected.value, sortField: "company_name", sortOrder: 1 });
    }
    if (selected.value.id === 'massimale_rca') {
      this.setState({ orderQuotation: selected.value, quotations: this.state.quotations.sort((a: any, b: any) => Number(a.massimale_order) - Number(b.massimale_order))});
    }
  }

  /**
   * Subscribes to any step4 redux store change, which will be used to trigger further QuotServlet requests.
   */
  subscribeToStoreChanges() {
    if(this._isMounted){
  
      if (this.isChangeOption === true) {
        // la semplicemente spunta sulle garanzie, deve seguire un altro flusso
        this.isChangeOption = false;
        return 
      }
      this.unsubscriber = this.store.subscribe(() => {

        const step4Data: Step4Data = this.store.getState().step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;
        
        if (!step4Data.warrantiesFromBo) {
          if(
            (!this.state.progressCompleted && (step4Data.quotation ?? [])?.length > 0) || (step4Data.noQuotation?.length)
          ) {
            var loadedRequestCounter: number = step4Data.requestCounter && !isNaN(step4Data.requestCounter) 
              ? step4Data.requestCounter 
              : 1;
            var progress = Math.round((100 / 6) * (loadedRequestCounter - 1));

            const refineQuotation : any[] = (step4Data.quotation ?? []).map((elem: any, index : number, self : any[]) => {
              elem.best_price = this.isBestWarranty(elem, self);
              return elem;
            })

            this.setState({
              // quotations: step4Data.quotation, 
              quotations: refineQuotation, 
              noQuotations: step4Data.noQuotation, 
              progress: progress, 
              progressCompleted: progress >= 100
            });
            
            // avvio il processo di quotazione
            if (loadedRequestCounter === 1) {
              QuotationService.loadRequestCounterFromStorage();
              QuotationService.setCallback(this.onQuotationReceived);
              QuotationService.setWarrantiesDoneCallBack(this.wrrantiesDoneCallBack);
            } 

            if (loadedRequestCounter <= QuotationService.getMaxRequestsNumber()) {
              if ((Array.isArray(step4Data.selectedOptions) && step4Data.selectedOptions.length > 0)) {
                QuotationService.updateWarranties(step4Data.selectedOptions);
              }
              QuotationService.executeRequest(step4Data.prevId ?? "", step4Data.calcoloId ?? "", this.vehicle === 'auto' ? '1' : '2', false, this.abortController.signal);
            }

            if (loadedRequestCounter === QuotationService.getMaxRequestsNumber()) {
              this.trackSendSuccess();
            }
          }
        }
        else{
          this.setState({
            quotations: (step4Data.quotation !== undefined) ? step4Data.quotation : [],
            noQuotations: (step4Data.noQuotation !== undefined) ? step4Data.noQuotation : [],
            selectedOptions: (step4Data.selectedOptions !== undefined) ? step4Data.selectedOptions : [],
            progress: 100,
            progressCompleted: true,
          });
        }
      });
    }
  }

  wrrantiesDoneCallBack() {
    this.setState({warrantiesLock: false});
  }

  /**
   * Handles the data received by the various QuotServlet requests.
   * 
   * @param quotations the received quotation list
   * @param noQuotations the received noQuotation list
   * @param progress the current progress bar value
   * @param requestIndex il numero di richieste inviate -- fix i cazzoni non salvano il numero delle richieste
   */
  onQuotationReceived(quotations: any[], noQuotations: any[], progress: number, requestIndex: number) {
    // tolgo i <br> in eccesso

    if(this._isMounted){
      const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;
  
      for (let i = 0; i < quotations.length; i++) {
        if(quotations[i].short_info) {
          let pulito =  quotations[i].short_info.replace("+ <br/>", "+ ");
          pulito =  pulito.replace("+ <br>", "+ ");
          quotations[i].short_info = pulito;
        }
      }
  
      if (quotations.length > 0 && noQuotations.length > 0) {
        this.setState({ progress: progress, quotations: quotations, noQuotations: noQuotations, progressCompleted: progress >= 100, loadingFilter: false});
      } else if (quotations.length > 0) {
        this.setState({ progress: progress, quotations: quotations, progressCompleted: progress >= 100, loadingFilter: false});
      } else if (noQuotations.length > 0) {
        this.setState({ progress: progress, noQuotations: noQuotations, progressCompleted: progress >= 100, loadingFilter: false});
      } else if (noQuotations.length === 0 && quotations.length === 0) {
        this.setState({ progress: progress, progressCompleted: progress >= 100, loadingFilter: false});
      } else {
        this.setState({loadingFilter: false});
      }
  
      // fixs aggiorno il numero delle richieste inviate! e evito che vada in loop
      this.props.step4DataSuccess({ requestCounter: requestIndex }, this.vehicle);
  
      if (requestIndex <= QuotationService.getMaxRequestsNumber()) {
        QuotationService.executeRequest(
          step4Data.prevId ?? "",
          step4Data.calcoloId ?? "",
          this.vehicle === 'auto' ? '1' : '2', false,
          this.abortController.signal
        );
      }
    }
  }

  /**
   * Validate and add license plate
   */
  addLicensePlate() {
    const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;
    if (this.validation()) {
      postData(Constants.SEISICURO_POST_TARGA_AGGIORANTA(this.vehicle, this.state.licensePlate.toUpperCase(), (step4Data?.prevId ?? 0) as number)
      ).then(response => {
        if (response.status !== 400) {
          // let newStep2Data: Step2Data = { ...this.store.getState().step2Data[this.vehicle], vehicleLicensePlateNumber: this.state.licensePlate, knowLicensePlate: true };
          let newStep2Data: Step2Data = { vehicleLicensePlateNumber: this.state.licensePlate, knowLicensePlate: true };
          this.props.step2DataSuccess(newStep2Data, this.vehicle);
          this.setState({ displayAddLicensePlate: false });
          this.handleShowUpselling(this.currentRowData, this.currentRowIsScegliButton);
        }
      });
    }
    else {
      return;
    }
  }

  private setFilter(e: any, filterKey: string){

    const keyOfFilter: keyof CheckboxFilter = filterKey as keyof CheckboxFilter;

    const settedFilter = {
      [keyOfFilter]: {
        ...this.state.filters[keyOfFilter],
        status: e.checked
      }
    }

    this.setState({
      filters: {
        ...this.state.filters,
        ...settedFilter
      }
    })
  }

  private filterRateizzazione: FilterFunction = (company) => {
    return this.state.filters.rateizzazione.status === true
      ? (company?.info_rateizzazione?.rateizzazione?.numero ?? undefined) !== undefined && company.info_rateizzazione.rateizzazione.numero !== 1 && company.info_rateizzazione.rateizzazione.numero !== "1"
      : true;
  }

  private filterEmissioneDiretta: FilterFunction = (company) =>{
    const trueValues: any[] = ["1"];
    return this.state.filters.emissioneDiretta.status === true
      ? trueValues.includes(company?.info_rateizzazione?.emissione_diretta?.valore)
      : true;
  }

  private computeFilter(quotations: any[]){

    let filterFunctions: FilterFunction[] = [];
    Object.entries(this.state.filters).map(([filterKey, filter]) => {
      switch(filterKey){
        case "rateizzazione":
          filterFunctions.push(this.filterRateizzazione);
        break;
        case "emissioneDiretta":
          filterFunctions.push(this.filterEmissioneDiretta);
        break;
      }
    })

    return quotations.filter(q => filterFunctions.reduce((acc, f) =>  acc && f(q), true))

  }


  render() {

    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;

    let done = (step4Data?.requestCounter && step4Data.requestCounter >= 7);
    return (
      <>
        {(this.state.forceShow || (this.state.noQuotations?.length > 0 || this.state.quotations?.length > 0) || done === true) &&
          <div id="step-4">

            <Dialog
              className="mobileFullScreen"
              visible={this.state.dialogGaranzieVisible}
              onHide={() => {this.setState({dialogGaranzieVisible: false})}}
              modal={true}
              resizable={false}
              draggable={false}
              blockScroll={true}
              header="Garanzie"
              footer={<div className="pt-4">
                <span onClick={(e: any) => {this.setState({dialogGaranzieVisible: false})}} className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">Chiudi</span>
              </div>}
            >
              <div className="pt-3">
                <GaranzieComponent
                  options={this.state.options}
                  selectedOptions={this.state.selectedOptions}
                  progressCompleted={this.state.progressCompleted}
                  warrantiesLock={this.state.warrantiesLock}
                  changeCallback={(e: any) => {this.onOptionChange(e)}}
                />
                <Divider />
                <ScatolaNeraComponent
                  vehicle={this.vehicle}
                  scatolaNera={this.state.scatolaNera}
                  progressCompleted={this.state.progressCompleted}
                  warrantiesLock={this.state.warrantiesLock}
                  onChangeCallback={(e : any) => this.onSelectScatolaNera(e)}
                />
              </div>
            </Dialog>

            <Dialog
              className="mobileFullScreen"
              visible={this.state.dialogFilterVisible}
              onHide={() => {this.setState({dialogFilterVisible: false})}}
              modal={true}
              resizable={false}
              draggable={false}
              blockScroll={true}
              header="Filtra per"
              style={{width: '350px'}}
              breakpoints={{ '576px': '80vw' }}
              footer={<div className="">
                <span onClick={(e: any) => {this.setState({dialogFilterVisible: false})}} className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">Chiudi</span>
              </div>}
            >
              <div className="pt-3">
                <FilterComponent
                  filters={this.state.filters}
                  setFilter={this.setFilter}
                />
              </div>
            </Dialog>

            

            <Dialog
              className="mobileFullScreen"
              visible={this.state.dialogRiepilogoVisible}
              onHide={() => {this.setState({dialogRiepilogoVisible: false})}}
              modal={true}
              resizable={false}
              draggable={false}
              blockScroll={true}
              header="Riepilogo"
              footer={<div className="pt-4">
                <span onClick={(e: any) => {this.setState({dialogRiepilogoVisible: false})}} className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">Chiudi</span>
              </div>}
            >
              <div className="pt-3">
                <RiepilogoDati vehicle={this.vehicle}/>
              </div>            
            </Dialog>



            <Dialog
              className="mobileFullScreen bottomDialog"
              visible={this.state.dialogOrdinamentoVisible}
              onHide={() => {this.setState({dialogOrdinamentoVisible: false})}}
              modal={true}
              resizable={false}
              draggable={false}
              blockScroll={true}
              position="bottom"
              header="Ordina"
              footer={<div className="pt-4">
                <span onClick={(e: any) => {this.setState({dialogOrdinamentoVisible: false})}} className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">Chiudi</span>
              </div>}
            >
              <div className="pt-3">
                <OrdinamentoComponent
                  orderQuotation={this.state.orderQuotation}
                  orderQuotationList={this.state.orderQuotationList}
                  onChangeCallback={(selected : any) => this.onChangeOrderQuotations(selected)}
                />
              </div>            
            </Dialog>
          

            <Dialog header="Inserisci la targa" visible={this.state.displayAddLicensePlate} style={{ width: '50vw' }} onHide={() => this.setState({ displayAddLicensePlate: false })}>
              <p>Non hai inserito la targa del veicolo. È necessario conoscere la targa per procedere con il salvataggio del preventivo.</p>
              <div className="p-field p-col-12">
                <span className="p-float-label">
                  <InputText value={this.state.licensePlate} onChange={(userInput) => this.setState({ licensePlate: userInput.target.value })} onBlur={() => this.setState({licensePlate: this.state.licensePlate.trim()})} type="text" placeholder="" />
                </span>            
                <ContextFeedback
                  show={this.state.errors.includes("licensePlate")}
                  message={{msg: "Targa non valida", severity: "error"}}
                />
              </div>
              <div>
                <Button type="button" label="Salva targa" onClick={this.addLicensePlate} />
              </div>
            </Dialog>

            <Dialog header="Attenzione" style={{ width: '50vw' }} visible={this.state.displayMoreThan60Days} onHide={() => { this.setState({ displayMoreThan60Days: false }) }}>
                <p>
                  Le Compagnie non calcolano preventivi per decorrenze superiori a 60 giorni.
                  Per darti un'indicazione dei prezzi, ti mostriamo i preventivi con decorrenza {this.maxInsuranceAviabilityDate}.
                  Ti invieremo i preventivi aggiornati e acquistabili il {this.maxInsuranceAviabilityDate} all'indirizzo e-mail che hai inserito nel preventivo.
                </p>
            </Dialog>

            {
              this.state.upsellingResponse && this.state.upsellingResponse.garanzie !== undefined &&
              <Dialog
                draggable={false}
                modal={true}
                header={<h1 className="titleLev4 colorPrimaryBlue">{AllHtmlEntities.decode(striptags(this.state.upsellingResponse.garanzie[0].titoloMessaggioDaVisualizzare))}</h1>} 
                visible={this.state.upsellingVisible} 
                style={{ width: "30rem", maxWidth: "90%;" }} onHide={() => this.setState({ upsellingVisible: false })}
              >
                <p>
                  {AllHtmlEntities.decode(striptags(this.state.upsellingResponse.garanzie[0].messaggioDaVisualizzare))}
                </p>
                <div className="text-center">
                  <Button 
                    type="button"
                    className="p-button p-component customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                    label={`AGGIUNGI LA GARANZIA A SOLI ${priceFormatter(this.state.upsellingResponse.garanzie[0].premioLordoGaranziaProposta)}`} 
                      onClick={() => this.handleRedirectToQuotation(this.state.upsellingSelectedRowData, true, this.state.upsellingResponse)} />
                </div>
                <div className="text-center">
                  <Button className="customBtn aLike" style={{marginTop: 10}} type="button" label="PROSEGUI SENZA AGGIUNGERE ALCUNA GARANZIA" 
                      onClick={() => this.handleRedirectToQuotation(this.state.upsellingSelectedRowData, false, this.state.upsellingResponse)} />
                </div>
              </Dialog>
            }

            <Dialog visible={this.state?.expandedRows ? true : false}
              onHide={() => this.setState({ expandedRows: undefined })}
              modal={true}
              resizable={false}
              draggable={false}
              header={() => <h3 className="titleLev4 colorPrimaryBlue"> {this.state.currentWarrantyDetail?.company_name}</h3>}
              breakpoints={{'960px': '75vw'}}
              style={{ width: '800px' }}
            >
              <WarrantyDetail
                currentWarrantyDetail={this.state.currentWarrantyDetail}
                displayProposalAdequacy={this.state.displayProposalAdequacy}            
              />
            </Dialog>

            <Card className="mb-5 progressbar-block">
              {(this.state.progressCompleted === false && done === false) &&
                <div id="flex_container">
                    <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/6sicuro-logo-animato.gif`} alt="attendi il caricamento dei risultati - 6sicuro" id="attesa_caricamento_smal" />
                  <div>
                    <div><span className="titleLev5 colorPrimaryBlue">{step3Data.contractorName && step3Data.contractorName}, stiamo ancora analizzando i prezzi ma abbiamo trovato già alcune offerte.</span></div>
                    <div><span className="text-2 text-left">Attendi il caricamento di tutti i prezzi per scegliere la miglior assicurazione.</span></div>
                  </div>
                </div>
              }
              {(this.state.progressCompleted === true || done === true) &&
                <div>
                  <div id="flex_container">
                    { /* icona -- ricerca conclusa */
                      (this.state.loadingFilter === false && this.state.quotations && Array.isArray(this.state.quotations)) && // this.state.quotations.length > 0 && 
                      <i className="pi pi-check-circle" id="ricerca_conclusa"></i>
                    }
                    
                    {/* icona -- Attesa ricerca risultati */
                      (this.state.loadingFilter === true) &&
                        <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/6sicuro-logo-animato.gif`} alt="attendi il caricamento dei risultati - 6sicuro" id="attesa_caricamento_smal" />
                    }

                    { /* Attesa ricerca risultati */
                      (step4Data.warrantiesFromBo === false || step4Data.warrantiesFromBo === undefined) &&
                      (this.state.quotations && Array.isArray(this.state.quotations))  &&

                      <span>
                        <span className="titleLev5 colorPrimaryBlue">
                          <span>{step3Data.contractorName && step3Data.contractorName + ','} ecco </span>
                          {this.state.quotations && Array.isArray(this.state.quotations) && this.state.quotations.length > 0 
                            ? this.state.quotations.length
                            : (this.state.loadingFilter ? "..." : 0)
                          }
                          <span> preventivi con le caratteristiche richieste a partire da </span>
                        </span>
                        <span className="price">
                          {Array.isArray(this.state.quotations) && this.state.quotations.length > 0
                            ? priceFormatter([...this.state.quotations]
                                ?.sort((a: any, b: any) => a.total_price - b.total_price)[0].total_price
                              )
                            : (this.state.loadingFilter ? "..." : 0)}.
                        </span>
                        <span className="d-block mt-2" id="anti-spam">A breve <strong>riceverai una mail</strong> con i dati del preventivo: <strong>se non la trovi, controlla nello spam e segnala come <em>non spam</em></strong> per rimanere aggiornato.​</span>
                        
                      </span>
                    }

                    { /* Terminatocon risultati -- flusso BO */
                      step4Data.warrantiesFromBo === true &&
                      ((done === true || this.state.loadingFilter === false) && this.state.quotations && Array.isArray(this.state.quotations)) &&

                      <span>
                        <span className="text-3 boResponse">
                          <span>Preventivo caricato con successo: </span>
                          <span id="anti-spam">Caricate {this.state.quotations.length} quotazioni</span>
                        </span>
                      </span>
                    }
                  </div>
                </div>
              }
              <div className="btnFiltriContainer" style={{overflowX: "auto"}}>
                <span
                  className="customBtn bgColorPrimaryWhite colorBlue borderPrimaryBlue hoverBgColorDarkgreen hoverColorWhite solidBorder d-flex py-2"
                  style={{paddingRight: "10px", paddingLeft: "10px"}}
                  onClick={() => {this.setState({dialogGaranzieVisible: true})}}
                >
                  <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/filtra.svg`} className="filterIcon" alt=""/>
                  Garanzie
                </span>
                <span
                  className="customBtn bgColorPrimaryWhite colorBlue borderPrimaryBlue hoverBgColorDarkgreen hoverColorWhite solidBorder d-flex py-2 ms-2"
                  style={{paddingRight: "10px", paddingLeft: "10px"}}
                  onClick={() => {this.setState({dialogFilterVisible: true})}}
                >
                  <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/filtro.svg`} className="filterIcon" alt=""/>
                  Filtra
                </span>
                <span
                  className="customBtn bgColorPrimaryWhite colorBlue borderPrimaryBlue hoverBgColorDarkgreen hoverColorWhite solidBorder d-flex py-2 ms-2"
                  style={{paddingRight: "10px", paddingLeft: "10px"}}
                  onClick={() => {this.setState({dialogOrdinamentoVisible: true})}}
                >
                  <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/ordina.svg`} className="filterIcon" alt=""/>
                  Ordina
                </span>
                <span
                  className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite py-2 ms-2"
                  onClick={() => {this.setState({dialogRiepilogoVisible: true})}}
                >Riepilogo</span>
              </div>
            </Card>
            <div className="row justify-content-between">
              <div className="col-lg-3 col-xxl-3 order-lg-1 order-2 sidebar-first hideMobile">
                <div className="block scegli-garanzie">
                  <GaranzieComponent
                    options={this.state.options}
                    selectedOptions={this.state.selectedOptions}
                    progressCompleted={this.state.progressCompleted}
                    warrantiesLock={this.state.warrantiesLock}
                    changeCallback={(e: any) => {this.onOptionChange(e)}}
                  />
                </div>

                {/* Ordinamento */}
                <OrdinamentoComponent
                  orderQuotation={this.state.orderQuotation}
                  orderQuotationList={this.state.orderQuotationList}
                  onChangeCallback={(selected : any) => this.onChangeOrderQuotations(selected)}
                />

                {/* Scatola nera */}
                <ScatolaNeraComponent
                  vehicle={this.vehicle}
                  scatolaNera={this.state.scatolaNera}
                  progressCompleted={this.state.progressCompleted}
                  warrantiesLock={this.state.warrantiesLock}
                  onChangeCallback={(e : any) => this.onSelectScatolaNera(e)}
                />

                {/* Filtraggio */}
                <div>
                  <h2 className="titleLev6 colorPrimaryBlue mb-3">Filtra per</h2>
                  <FilterComponent
                    filters={this.state.filters}
                    setFilter={this.setFilter}
                  />

                  {/* Riepilogo */}
                  <div className="mt-4">
                    <RiepilogoDati vehicle={this.vehicle}/>
                  </div>
                </div>

              </div>

              <div className="col-lg-9 order-1 quotation-list">
                <Card className="mb-5 quotations nopadding">
                  <div className="card">
                    <DataTable
                      id="quotazioni"
                      onSort={(event) => this.onDataTableSort(event)} 
                      sortField={this.state.sortField}
                      sortOrder={this.state.sortOrder} 
                      value={this.computeFilter(this.state.quotations ?? [])}
                      dataKey="company_id"
                      emptyMessage="Nessun risultato"
                      className="quotationTable"
                      rowClassName={(item) => this.isBestWarranty(item) ? {"best": true} : {"best": false}}
                      autoLayout={true}
                    >
                      <Column
                        field="company_name"
                        className="company-name"
                        header="Compagnia"
                        sortable
                        body={item => <QuotationColumnImage company={item}/>}
                      />

                      <Column
                        field="short_info"
                        className="short-info"
                        header="Informazioni"
                        body={item => <QuotationColumnInfo company={item} />}
                      />
                      
                      <Column
                        field="total_price"
                        className="total-price"
                        header="Premio annuo"
                        sortable
                        body={item => <QuotationColumnPrice company={item}
                        onDetailClick={this.handleWarrantyDetail}/>}
                      />

                      <Column
                        field="action_choose"
                        className="action-choose"
                        header="Azioni"
                        body={item => <QuotationColumnActions company={item} disabled={!this.state.progressCompleted}
                        onClick={this.handleShowUpselling}/>}
                      />

                    </DataTable>
                  </div>
                </Card>

                {
                  this.state.noQuotations && this.state.noQuotations.length > 0 &&
                  <Card className="no-quotations">
                    <div className="card">
                      <NoQuotation noQuotations={this.state.noQuotations} />
                    </div>
                  </Card>
                }
              </div>
            </div>
          </div>
        }
      </>
    );
  }

  // ------------------------------------------------------------------------------------------------------------------------------

  private getDataToTrack(){
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    return {
      prodotto: this.vehicle,
      gender: step1Data.gender,
      dataNascita: formatDateAdform(dateOfBirth),
      titoloStudio: step1Data.degree,
      professione: step1Data.profession,
      dataImmatricolazione: `${
        step2Data.vehicleRegistrationMonth !== undefined &&
        step2Data.vehicleRegistrationMonth < 10
          ? `0${step2Data.vehicleRegistrationMonth}`
          : step2Data.vehicleRegistrationMonth
      }/${step2Data.vehicleRegistrationYear}`,
      annoAcquisto: step2Data.vehicleBuyYear,
      veichleBrand: step2Data.vehicleBrandLabel,
      veichleModel: step2Data.vehicleModelLabel,
      emailKript: encryptEmail(step3Data.contractorEmail),
      email: step3Data.contractorEmail,
      targa: step2Data.vehicleLicensePlateNumber,
      residenza: step3Data.contractorCityOfResidence?.id?.split('(')[0].trim(),
      provincia: step3Data.contractorCityOfResidence?.id.split('(')[1].replace(')', ''),
      compagniaAssicurativa: step3Data.currentInsuranceCompany,
      calcoloId: step4Data.calcoloId,
      decorrenzaAssicurazione: formataDateToString(step3Data.newInsuranceStart),
      actualInsuranceCompany: step3Data.currentInsuranceCompany,
      bersani: step3Data.bersaniDecree === "si" ? '1' : '0',
      telefono: step3Data.contractorPhoneNumber,
      name: step3Data.contractorName,
      surname: step3Data.contractorSurname,
      mktgAssicurativo: step3Data.acceptGdprPrivacyPolicy102,
      mktgNonAssicurativo: step3Data.acceptGdprPrivacyPolicy102,
      newsletter: step3Data.acceptGdprPrivacyPolicy22,
      profilazione: step3Data.acceptGdprPrivacyPolicy3,
      cessioneAssicurativo: step3Data.acceptGdprPrivacyPolicy104,
      cessioneNonAssicurativo: step3Data.acceptGdprPrivacyPolicy104,
      gdpr: step3Data.acceptSeiSicuroPrivacyPolicy
    }
  }

  /**
   * Trak tag-manager: notifica successo
   */
  private trackSendSuccess() {
    this.props.trackEvent?.({
      name: "quoteSuccess",
      data:  this.getDataToTrack()
    });
  }


  /**
   * Trak tag-manager: notifica garanzia accessoria selezionata
   */
  private trackFilterApplied() {
    this.props.trackEvent?.({
      name: "filterApplied",
      data:  this.getDataToTrack()
    });
  }

  

  /**
   * Handles the DataTable component custom sorting and its related UI interactions.
   * 
   * @param event the DataTable's sort event
   */
  private onDataTableSort(event: DataTableSortParams) {
    //console.log(event);
    if(event.sortField === "company_name" && event.sortOrder === 1) {
      this.setState({sortField: event.sortField, sortOrder: event.sortOrder, orderQuotation: this.state.orderQuotationList[3]});
    } else if(event.sortField === "total_price" && event.sortOrder === 1) {
      this.setState({sortField: event.sortField, sortOrder: event.sortOrder, orderQuotation: this.state.orderQuotationList[1]});
    } else if(event.sortField === "total_price" && event.sortOrder === -1) {
      this.setState({sortField: event.sortField, sortOrder: event.sortOrder, orderQuotation: this.state.orderQuotationList[2]});
    } else {
      this.setState({sortField: event.sortField, sortOrder: event.sortOrder, orderQuotation: this.state.orderQuotationList[0]});
    }
  }

  /**
   * Form validation.
   * 
   * @returns {boolean} Validation check
   */
  private validation(): boolean {
    let current_errors: any = [];

    if (!this.validateInsuranceDate()) {
      return false;
    }

    if (this.vehicle === 'moto') {
      if (!isLicensePlateMotoValid(this.state.licensePlate)) {
        current_errors.push('licensePlate');
      }
    }
    else {
      if (!isLicensePlateAutoValid(this.state.licensePlate)) {
        current_errors.push('licensePlate');
      }
    }

    this.setState({ errors: current_errors });

    return current_errors.length === 0;
  }

  /**
   * handler of visible/hidden state of warranty detail Dialog
   * 
   * @param company clicked company
   */
  private handleWarrantyDetail(company: any) {
    let openedRow = this.state.expandedRows;
    if (openedRow === undefined || openedRow === null) {
      openedRow = {};
    }

    let companyId = company?.company_id;

    if (openedRow[companyId] === undefined ) {
      openedRow[companyId] = true;
    } else {
      openedRow[companyId] = undefined;
    }

    this.setState({ expandedRows: openedRow, currentWarrantyDetail: company});
  }

  /**
   * Shows the upselling popup to the user, or redirects to the insurance company's quotation page if no upselling options are available.
   * 
   * @param rowData the insurance company data object
   * @param isScegliButton whether the "Scegli" button was pressed to get here
   * @returns 
   */
  private handleShowUpselling(rowData: any, isScegliButton = false) {
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;

    if (isScegliButton) {
      this.props.trackEvent?.({
        name: "vai-quotazione",
        data:  this.getDataToTrack()
      });
    }
    else{
      this.props.trackEvent?.({
        name: "salva-quotazione",
        data:  this.getDataToTrack()
      });
    }
    
    if (!step2Data.vehicleOwned && !step2Data.knowLicensePlate) {
      this.currentRowData = rowData;
      this.currentRowIsScegliButton = isScegliButton;
      this.setState({ displayAddLicensePlate: true });
      return;
    }

    if (!this.validateInsuranceDate()) {
      return;
    }

    fetchList(
      Constants.SEISICURO_GET_UPSELLING(
        this.vehicle, `${step2Data.vehicleLicensePlateNumber}`, rowData.company_id, rowData.total_price, QuotationService.warranties, rowData.inclusioni ?? '',
        `${step4Data.prevId}`, `${step4Data.calcoloId}`, step4Data.fromMailSereno !== undefined ? step4Data.fromMailSereno : false, new Date().getTime()
      ), 
      (result: any) => {
        if(result.premioConUpselling !== undefined) {
          this.setState({ upsellingResponse: result, upsellingVisible: true, upsellingSelectedRowData: rowData });
        } else {
          this.handleRedirectToQuotation(rowData, false, result);
        }
    });
  }

  /**
   * Redirect to the insurance company's quotation page.
   * 
   * @param rowData quotation data object 
   * @param upselling whether to use upselling
   * @param upsellingPrice the total upselling price
   */
  private handleRedirectToQuotation(rowData: any, upselling: boolean, upsellingResponse?: any) {
    // trak se non arriva dal BO -- segnalare brainpull
    this.props.trackEvent?.({
      name: "acquista-ora",
      data:  this.getDataToTrack()
    });
    let redirTo = this.assembleRedirectUrl(rowData, upselling, upsellingResponse);
    window.open(redirTo, '_blank');
  }

  /**
   * Computes an insurance company's redirect url.
   * 
   * @param rowData the insurance company data object
   * @param upselling whether to use upselling
   * @param upsellingResponse the upselling response data
   * @returns {string} the computed redirect url
   */
  assembleRedirectUrl(rowData: any, upselling: boolean, upsellingResponse: any): string {
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;

    return `/preventivi/assicurazione-${this.vehicle}/salvataggio-in-corso?` +
      `cid=${rowData.company_id}` +
      `&plate=${step2Data.vehicleLicensePlateNumber}` +
      `&price=${upselling ? Number(upsellingResponse.premioConUpselling) : rowData.total_price}` +
      `&prodotto=${this.vehicle === "auto" ? 1 : 2}` +
      `&pid=${step4Data.prevId}` +
      `&calcoloId=${step4Data.calcoloId}` +
      `&ups=${upselling}` +
      `&w=${QuotationService.warranties}` +
      (rowData.inclusioni ? `&w_i=${rowData.inclusioni}` : '&w_i=undefined') + 
      (rowData.IdPacchetto ? `&pack=${rowData.IdPacchetto}` : '&pack=undefined') +
      ((upselling) 
      ? ((upsellingResponse && upsellingResponse.garanzie && upsellingResponse.garanzie[0] && upsellingResponse.garanzie[0].idGaranzia)
        ? "&w_u=" + upsellingResponse?.garanzie[0]?.idGaranzia
        : "&w_u=undefined")
      : "") +
      `&n_ma=${step2Data.vehicleBrandLabel}` +
      `&n_mo=${step2Data.vehicleModelLabel}`;
  }

  private validateInsuranceDate() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    if (step3Data.newInsuranceStart) {
      let newInsuranceStartObject = new Date(step3Data.newInsuranceStart);
      let insuranceWrapper = moment(newInsuranceStartObject);

      if (insuranceWrapper.isAfter(moment().add(60, 'days'))) {
        this.setState({ displayMoreThan60Days: true});
        return false;
      }
    }

    this.setState({ displayMoreThan60Days: false});
    return true;
  }
}

// Required to access react-router's params (i.e. ":vehicle" in App.tsx).
const Step4 = (props: any) => (
  <Step4Component {...props} />
);


class ScatolaNeraComponent extends Component<{
  vehicle: IVehicle
  scatolaNera: boolean
  progressCompleted: boolean
  warrantiesLock: boolean
  onChangeCallback: Function
}, {}>{
  private vehicle: any;
  
  constructor(props: any) {
    super(props);
    this.vehicle = props.vehicle;
  }

  private changeMethod = (e: any) => {
    this.props.onChangeCallback(e);
  }

  render() {
    return <div>
      {this.vehicle === "auto" && 
        <div className="block mt-3">
          <div className="row">
            <div className="col-2">
              <InputSwitch
                checked={this.props.scatolaNera}
                onChange={this.changeMethod} 
                disabled={!this.props.progressCompleted || this.props.warrantiesLock === true} />
              </div>
            <div className="col-10"><span>Nascondi prodotti con scatola nera</span></div>
          </div>
        </div>      
      }
    </div>
  }
}

class OrdinamentoComponent extends Component<{
    orderQuotation: any
    orderQuotationList: any
    onChangeCallback: Function
},
{}>{

  private changeMethod = (selected: any) => {
    this.props.onChangeCallback(selected);
  }

  render() {
    return <div className="order-block">
      <label>Ordina risultati per</label>
      <Dropdown
        value={this.props.orderQuotation}
        options={this.props.orderQuotationList}
        onChange={this.changeMethod} 
        optionLabel="label"
        placeholder=""
        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE} />
    </div>
  }
}

class GaranzieComponent extends Component<{
  options: any
  selectedOptions: any
  progressCompleted: boolean
  warrantiesLock: boolean
  changeCallback: Function
}, {}>{

  render() {
    return <div className="scegli-garanzie">
      {
        this.props.options.map((option: any) => {
          return (
            <div key={option.key} className="p-field-checkbox">
              <div className="row w-100">
                <div className="col-9 flexCheckbox">
                  <Checkbox 
                    inputId={option.key} name="option" value={option} 
                    onChange={(e: any) => {this.props.changeCallback(e)}} 
                    checked={option.key === 'garanzia-1' ? true : this.props.selectedOptions.some((item: any) => item.key === option.key)} 
                    disabled={option.key === 'garanzia-1' || (!this.props.progressCompleted || this.props.warrantiesLock === true)} /> {/** || !this.state.warrantiesLock */}
                  <label htmlFor={option.key}>{option.name}</label>
                </div>
                <div className="col-3">
                  <Tooltip target=".custom-target-icon" />
                  <i className="custom-target-icon pi pi-info-circle" data-pr-tooltip={option.description} style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                  </i>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  }
}

interface FilterComponentProps {
  filters: CheckboxFilter
  setFilter: Function
}

const FilterComponent: React.FC<FilterComponentProps> = (props) => {

  const {filters} = props;

  return <div className="filter-selector">
    {
      Object.entries(filters)
        .map(([key, value]) => <div key={`filter-${key}`} className="p-field-checkbox">
          <div className="row w-100">
            <div className="col-9 flexCheckbox">
              <Checkbox 
                inputId={`filter-${key}`}
                name="filters"
                onChange={(e: any) => {props.setFilter(e, key)}} 
                checked={value.status}
              />
              <label htmlFor={`filter-${key}`}>{value.description}</label>
            </div>
            {
              value?.fullDescription &&
              <div className="col-3">
                <Tooltip target=".custom-target-icon" />
                <i className="custom-target-icon pi pi-info-circle" data-pr-tooltip={value?.fullDescription} style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                </i>
              </div>
            }
          </div>
        </div>)
    }
  </div>

}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    setStep: (step: number) => dispatch(userInfoDataSuccess({currentStep: step})),
    step2DataSuccess: (payload: any, vehicle: string) => dispatch(step2DataSuccess(payload, vehicle)),
    step4DataSuccess: (payload: any, vehicle: string) => dispatch(step4DataSuccess(payload, vehicle)),
  }
};

const mapState = (state: any) => {
  return {
    step1Data: state.step1Data,
    step2Data: state.step2Data,
    step3Data: state.step3Data,
    step4Data: state.step4Data,
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

// export default withRouter(connect(mapState, mapDispatch)(Step4)); // non uso più il withRouter e l'hook useParams perché prendo il selettore di veicolo dallo store currentVehicleSelector
export default compose(connect(mapState, mapDispatch), withEventManager)(Step4);
