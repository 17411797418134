import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Component } from "react";
import UserService, { AccountAlreadyRegisteredException } from "./utils/UserService";
import { passwordSecurityLevel } from "./utils/validate";
import * as Constants from './utils/constants';
import { PasswordStrength } from "./customs/PasswordInput";
import {ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Card } from "primereact/card";
import { Link, Redirect, useHistory } from "react-router-dom";
import '../scss/area-personale-home.scss';
import User from "./utils/User";

interface IRegistrationPageProps{
  history: ReturnType<typeof useHistory>;
}
interface IRegistrationPageState{
  name: string;
    surname: string;
    phone: string;
    email: string;
    password: string;
    rePassword: string;
    passwordLiveChecking: boolean | undefined;
    passwordStrength: PasswordStrength | undefined;
    emailChecking: boolean;
    emailValid: boolean | undefined;
    emailRegistered: boolean;
    phoneChecking: boolean;
    phoneValid: boolean | undefined;

    ivass: boolean;
    cgu: boolean;
    privacy: boolean;
    marketing: boolean;
    profilazione: boolean;
    cessione: boolean;
    newsletter: boolean;
    
    errors: any[];
    errorTexts: IFeedbackText[];
    submitting: boolean;
    feedbackClass: string;
    feedbackDialog: boolean;
    feedbackHeader: JSX.Element;
    feedbackFooter: JSX.Element;
    feedbackBody: JSX.Element;
    activeIndex: number;
}

class RegistrationPageComponent extends Component<IRegistrationPageProps, IRegistrationPageState> {
  
  private previousMail : string;
  private previousPhone : string;
  
  constructor(props: any) {
    super(props);

    this.state = {
      name: "",
      surname: "",
      phone: "",
      email: "",
      password: "",
      passwordLiveChecking: undefined,
      passwordStrength: undefined,
      rePassword: "",
      emailValid: undefined,    // status of mail validation, true if valid and not registered
      emailChecking: false,     // ongoing mail validation
      emailRegistered: false,    // user registered but not logged
      phoneValid: undefined,    // status of phone validation, true if valid and not registered
      phoneChecking: false,     // ongoing phone validation
      
      ivass: false,
      cgu: false,
      privacy: false,
      marketing: false,
      profilazione: false,
      cessione: false,
      newsletter: false,

      errors: [],
      errorTexts: [],
      submitting: false,
      feedbackClass: "",
      feedbackDialog: false,
      feedbackHeader: <></>,
      feedbackFooter: <></>,
      feedbackBody: <></>,
      activeIndex: -1,
    }

    this.previousMail = this.state.email || "";
    this.previousPhone = this.state.phone || "";

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.togglePrivacyDetails = this.togglePrivacyDetails.bind(this);
  }

  private togglePrivacyDetails(){
    this.setState({
      activeIndex: (this.state.activeIndex === 0 ? -1 : 0)
    });
  }

  render() {

    return User.isAlive()
    ? <Redirect to="/preventivi/area-personale" />
    : <>
        <Dialog
          className={`customDialog ${this.state.feedbackClass}`}
          visible={this.state.feedbackDialog}
          onHide={() => {
            this.setState({
              feedbackDialog: false,
            }, () => {
              if(this.state.feedbackClass === "success"){
                this.props.history.push("/preventivi/area-personale/login");
              }
            })
          }}
          header={this.state.feedbackHeader}
          footer={this.state.feedbackFooter}
          resizable={false}
          draggable={false}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <>{this.state.feedbackBody}</>
              </div>
            </div>
          </div>
        </Dialog>

        <div className="pr-body">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-xxl-4 col-xl-6 col-lg-8 col-12"> 
                <Card className="pr-form">
                  <div id="registration-panel" className="p-fluid p-xl-5 p-3 mt-4">

                    <h3 className="titleLev4 colorPrimaryBlue">
                      Crea il tuo account 6sicuro
                    </h3>
                    <p className="mt-lg-4 mt-2">
                      Ehi, 6 dei nostri? Registra i tuoi dati e accedi alla tua area personale 6sicuro. Qui potrai fare comodamente richiesta dei tuoi preventivi assicurazione, confrontare i preventivi già calcolati e condividere il tuo account con le persone a te più care.
                    </p>

                    <FeedbackBox items={this.state.errorTexts}/>

                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2">
                        <label className="mt-2" htmlFor="nameReg">Nome</label>
                        <InputText
                          id="nameReg"
                          placeholder="Nome"
                          name="name"
                          autoComplete="given-name"
                          type="text"
                          onChange={e => this.onChangeHandler(e.target.value, "name")}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("name")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "name") ??
                            {msg: "Inserire un nome valido", severity: "error"}
                          }
                        />
                      </div>
                      <div className="col-12 col-lg-6 mb-2">
                        <label className="mt-2" htmlFor="surnameReg">
                          Cognome
                        </label>
                        <InputText
                          id="surnameReg"
                          placeholder="Cognome"
                          name="surname"
                          autoComplete="family-name"
                          type="text"
                          onChange={e => this.onChangeHandler(e.target.value, "surname")}
                        />              
                        <ContextFeedback
                          show={this.state.errors.includes("surname")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "surname") ??
                            {msg: "Inserire un cognome valido", severity: "error"}
                          }
                        />
                      </div>
                      <div className="col-12 col-lg-6 mb-2">
                        <div className="p-fluid">
                          <label className="mt-2" htmlFor="emailReg">E-mail</label>
                          {this.state.emailChecking || this.state.emailValid !== undefined
                            ? <span className="p-input-icon-right">
                              {this.state.emailChecking
                                ? <i className="pi pi-spin pi-spinner" />
                                : this.state.emailValid !== undefined
                                  ? this.state.emailValid
                                    ? <i className="pi pi-check" />
                                    : <i className="pi pi-times" />
                                  : <></>
                              }
                                {this.emailInputElement()}
                              </span>
                            : this.emailInputElement()
                          }                
                          <ContextFeedback
                            show={this.state.errors.includes("email")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "email") ??
                              {msg: "Inserisci un indirizzo email valido", severity: "error"}
                            }
                          />
                        </div>
                      </div>   
                      <div className="col-12 col-lg-6 mb-2">
                        <label className="mt-2" htmlFor="phoneReg">
                          Cellulare
                        </label>
                        <InputText
                          id="phoneReg"
                          placeholder="Cellulare"
                          name="phone"
                          keyfilter={/^\d+(\.\d{1,2})?$/}
                          autoComplete="tel"
                          type="tel"
                          value={this.state.phone}
                          onChange={e => this.onChangeHandler(e.target.value, "phone")}
                          onBlur={e => this.phoneHandler(e.target.value)}
                        />              
                        <ContextFeedback
                          show={this.state.errors.includes("phone")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "phone") ??
                            {msg: "Inserire un numero di telefono valido", severity: "error"}
                          }
                        />
                      </div>       
                      <div className="col-12 col-lg-6 mb-2">
                        <label className="mt-2" htmlFor="passReg">
                          Password
                        </label>
                        <Password
                          id="passReg"
                          placeholder="Password"
                          promptLabel="Inserisci la password"
                          name="password"
                          onChange={(e: any) => this.onChangeHandler(e.target.value, "password")}
                          mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
                          strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
                          weakLabel="Inserisci almeno 6 caratteri, una lettera maiuscola e minuscola, oppure una minuscola e un numero, oppure una maiuscola e un numero."
                          mediumLabel="Per una maggiore sicurezza inserisci almeno 8 caratteri, una lettera maiuscola, una minuscola, un numero e un carattere speciale (! @ # % $ “ *)."
                          strongLabel="6 ok!"
                          toggleMask
                          feedback={true}
                          appendTo="self"
                        />              
                        <div id="contextMsg"></div>
                        <ContextFeedback
                          show={this.state.errors.includes("password")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "password") ??
                            {msg: "Inserisci una password valida", severity: "error"}
                          }
                        />
                      </div>
                      <div className="col-12 col-lg-6 mb-2">
                        <label className="mt-2" htmlFor="rePassReg">
                          Conferma password
                        </label>
                        <Password
                          id="rePassReg"
                          placeholder="Password"
                          name="rePassword"
                          onChange={e => this.onChangeHandler(e.target.value, "rePassword")}
                          toggleMask
                          feedback={false}
                        />              
                        <ContextFeedback
                          show={this.state.errors.includes("rePassword")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "rePassword") ??
                            {msg: "Password di conferma diversa", severity: "error"}
                          }
                        />
                      </div>
                    </div>
                    
                    {/* START NUOVA GDPR */}
                    <div className="row mt-4">
                      <div className="col-12">
                        <h3 className="titleLev5">Informativa IVASS</h3>
                      </div>
                      <div className="col-12 registrationPrivacy">
                        <div className="mb-2">
                          <div className="simpleCheckBlock">
                            <Checkbox
                              checked={this.state.ivass ?? false}
                              onChange={e => this.onChangeHandler(e.checked, "ivass")}
                            />
                            <ContextFeedback
                              show={this.state.errors.includes("ivass")}
                              message={{msg: "Dichiarare la presa visione dell'informativa precontrattuale prima di procedere", severity: "error"}}
                            />
                            <p>
                              Dichiaro di aver letto l'informativa precontrattuale di cui alla normativa vigente ai sensi del d.lgs. 209/2005 
                              (Codice delle Assicurazioni Private) e leggi e regolamenti collegati e di averne stampato o salvato copia. 
                              Clicca sui link per visualizzare i documenti Allegati&nbsp;
                              <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.6sicuro.it/wp-content/uploads/2023/11/SPA-Allegato-3_rev-2023-howden.pdf">3</a>,&nbsp;
                              <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.6sicuro.it/wp-content/uploads/2023/11/SPA-Allegato-4_rev-2023-Howden.pdf">4</a>&nbsp;
                              e&nbsp;
                              <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.6sicuro.it/wp-content/uploads/2023/11/SPA-Allegato-4TER_rev-2023-howden.pdf">4 TER</a>. (obbligatorio)
                            </p>
                          </div>
                          <div style={{paddingLeft: "1.2rem"}}>
                            <p style={{marginBottom: "0.5rem"}}>
                              • In ottemperanza all'art. 56 comma 2 e 3 del Reg. IVASS 40/2018, dichiaro di avere salvato o stampato, letto, compreso ed archiviato su supporto duraturo gli Allegati 3, e 4 TER. In ottemperanza all'art. 9 comma 4 del Reg. IVASS 23/2008, dichiaro altresì di avere stampato, letto, compreso ed archiviato su supporto duraturo la tabella contenente le informazioni sui livelli provvigionali percepiti dalle compagnie assicurative con cui 6sicuro brand di Howden ha rapporti di affari nel ramo RCA.
                            </p>
                            <p style={{marginBottom: "0.5rem"}}>
                              • In ottemperanza all'art. 34 del D.L. 1/2012 e dell'art. 6 del Documento di Consultazione 49/2012, dichiaro di aver preso visione delle note informative relative ai prodotti assicurativi, già predisposte e pubblicate sui siti internet aziendali delle compagnie assicurative di cui il preventivatore di 6sicuro (brand di Howden) fornisce le quotazioni e raggiungibili dalla pagina note informative. 
                            </p>
                            <p>
                              • In ottemperanza all'art.61 del Reg. IVASS 40/2018, dichiaro di voler ricevere tramite l’email comunicata in fase di accesso  la documentazione precontrattuale, i preventivi e la documentazione contrattuale, nonché ogni altra comunicazione proveniente da Howden, con riferimento ai contratti in corso e a quelli futuri. Sono consapevole della possibilità di modificare la mia scelta in qualsiasi momento, Condizioni generali del servizio.
                            </p>
                          </div>
                        </div>

                        <div>
                          <label className="simpleCheckBlock">
                            <Checkbox
                              checked={this.state.cgu}
                              onChange={e => this.onChangeHandler(e.checked, "cgu")}
                            />
                            <p>
                              Accetto le condizioni generali del servizio di comparazione offerto da 6sicuro 
                              che dichiaro di aver letto e compreso al seguente <a href="https://www.6sicuro.it/condizioni-generali-di-utilizzo/" target="blank">link</a>&nbsp;
                              (necessario per usufruire dei servizi offerti dal Sito).</p>
                          </label>
                          <ContextFeedback
                            show={this.state.errors.includes("cgu")}
                            message={{msg: "Accettare le condizioni generali di utilizzo prima di procedere", severity: "error"}}
                          />
                        </div>

                        <div className="col-12">
                          <h3 className="titleLev5">Informativa privacy</h3>
                        </div>
                        <div>
                          <label className="simpleCheckBlock">
                            <Checkbox
                              checked={this.state.privacy ?? false}
                              onChange={e => this.onChangeHandler(e.checked, "privacy")}
                            />
                            <p>Dichiaro di aver letto <a href="https://www.6sicuro.it/privacy/" target="blank">l’informativa sulla privacy</a> redatta ai sensi del Regolamento (UE) 2016/679 ("GDPR") e specificatamente gli artt. 2.1.a, 2.1.b, 2.1.c, 2.1.d, 2.1.e, 2.i.f, 2.1.g, 2.1.h, 2.1.i. (obbligatorio)</p>
                          </label>
                          <ContextFeedback
                            show={this.state.errors.includes("privacy")}
                            message={{msg: "Dichiarare la presa visione dell'informativa sulla privacy prima di procedere", severity: "error"}}
                          />
                        </div>

                        <div>
                          <label className="simpleCheckBlock">
                            <Checkbox
                              checked={this.state.newsletter ?? false}
                              onChange={e => this.onChangeHandler(e.checked, "newsletter")}
                            />
                            <p>Acconsento all’invio della newsletter (art. 2.1.e dell’informativa) (facoltativo)</p>
                          </label>
                        </div>
                        <div>
                          <label className="simpleCheckBlock">
                            <Checkbox
                              checked={this.state.marketing ?? false}
                              onChange={e => this.onChangeHandler(e.checked, "marketing")}
                            />
                            <p>Acconsento al trattamento dei dati per le finalità di comunicazione e commerciali operate direttamente da Howden (art. 2.1.f dell’informativa) (facoltativo)</p>
                          </label>  
                        </div>
                        <div>
                          <label className="simpleCheckBlock">
                            <Checkbox
                              checked={this.state.profilazione ?? false}
                              onChange={e => this.onChangeHandler(e.checked, "profilazione")}
                            />
                            <p>Acconsento al trattamento dei dati per le attività di profilazione (art. 2.1.g dell’informativa) (facoltativo)</p>
                          </label>
                        </div>
                        <div>
                          <label className="simpleCheckBlock">
                            <Checkbox
                              checked={this.state.cessione ?? false}
                              onChange={e => this.onChangeHandler(e.checked, "cessione")}
                            />
                            <p>Acconsento al trattamento dei dati per la le finalità di comunicazione, cessione o concessione in uso di dati a terze parti per finalità proprie di queste ultime, per le seguenti categorie (art. 2.1.i dell’informativa) (facoltativo):</p>
                          </label>
                        </div>
                        <div style={{paddingLeft: "1.2rem"}}>
                          <p style={{marginBottom: "0.5rem"}}>• Bancario, finanziario, assicurativo</p>
                          <p style={{marginBottom: "0.5rem"}}>• Automotive</p>
                          <p style={{marginBottom: "0.5rem"}}>• Beni di largo consumo</p>
                          <p style={{marginBottom: "0.5rem"}}>• Telecomunicazioni, telefonia, informatica</p>
                          <p style={{marginBottom: "0.5rem"}}>• Servizi alla persona e tempo libero</p>
                        </div>
                      </div>                  
                    </div>
                    {/* END NUOVA GDPR */}

                    <div className="row mt-4 mb-4">
                      <div className="col-12">
                        <Button
                          className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                          onClick={() => {
                            this.handleFormSubmit();
                          }}
                          loading={this.state.submitting}
                          label="Crea un account 6sicuro"
                        />
                      </div>
                    </div>

                    { false &&
                      <div>
                        <Divider className="my-lg-5 my-3" align="center">
                          <div className="inline-flex align-items-center">
                            <b className="colorPrimaryBlue">oppure</b>
                          </div>
                        </Divider>

                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 text-center">
                              <p className="mb-0">Hai già un account? <Link to="/preventivi/area-personale/login" className="aLike">Accedi</Link></p>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 text-center">
                            <div className="text-center">
                              <Button className="customBtn bgColorGrey colorPrimaryBlue hoverBgColorDarkgreen hoverColorWhite">
                                Accedi con Facebook
                              </Button>
                              <br />
                              <Button className="customBtn bgColorGrey colorPrimaryBlue hoverBgColorDarkgreen hoverColorWhite mt-2">
                                Accedi con Google
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    <Divider className="my-lg-5 my-3"></Divider>

                    <div className="row">
                      <div className="col-12 text-center">
                        <p className="mb-0">Hai già un account? <Link to="/preventivi/area-personale/login" className="aLike">Accedi</Link></p>
                      </div>
                    </div>

                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>;
  }

  private emailInputElement() : JSX.Element{
    return <InputText
      id="emailReg"
      placeholder="E-mail"
      name="email"
      autoComplete="email"
      type="email"
      value={this.state?.email}
      onChange={e => this.onChangeHandler(e.target.value, "email")}
      onBlur={(selected) => { this.emailHandler(selected.target.value) }}
    />
  }

  private emailHandler(candidateEmail: string, skipPrevValueControl:boolean = false){

    if(skipPrevValueControl || candidateEmail !== this.previousMail){

      let emailValidation : {
        emailValid: boolean;
        emailRegistered: boolean;
        emailChecking: boolean;
      };

      if(candidateEmail === ""){
        emailValidation = {
          emailValid: false,
          emailRegistered: false,
          emailChecking: false
        }
        this.setState({...emailValidation })
      }
      else{
        this.setState({emailChecking: true}, () => {

          UserService.call(Constants.WEB_SITE_API_SERVER_URL("/utils/misc/validate"), {
            method: "post",
            body: JSON.stringify({
              email: candidateEmail ?? "",
              check_user_exists: true
            })
          })
            .then(result => {
              
              this.setState({emailChecking: false}, () => {
                let textErrors : any[] = this.state.errorTexts.filter(elem => elem.field !== "email");

                if(!result?.email.valid){
                  textErrors.push({field: "email", msg: "Inserisci un indirizzo email valido"});
                  this.setState({
                    emailValid: false,
                    emailRegistered: false,
                    errors: this.state.errors.includes("email") ? this.state.errors : [...this.state.errors, "email"],
                    errorTexts: textErrors
                  })
                }
                else{
                  if(result?.email.user_exists){
                    textErrors.push({field: "email", msg: "Sei già registrato con questo indirizzo"});
                    this.setState({
                      emailValid: true,
                      emailRegistered: true,
                      errors: this.state.errors.filter(e => e !== "email"),
                      errorTexts: textErrors
                    })
                  }
                  else{
                    this.setState({
                      emailValid: true,
                      emailRegistered: false,
                      errors: this.state.errors.filter(e => e !== "email"),
                      errorTexts: textErrors
                    });
                  }
                }
              })
            })
          
        })
      }
    }
  }

  private phoneHandler(candidatePhone: string, skipPrevValueControl:boolean = false, callback?: Function){
    if(skipPrevValueControl || candidatePhone !== this.previousPhone){
      if(candidatePhone === ""){
        this.setState({phoneChecking: false, phoneValid: false })
      }
      else{
        this.setState({phoneChecking: true}, () => {

          UserService.call(Constants.WEB_SITE_API_SERVER_URL("/utils/misc/validate"), {
            method: "post",
            body: JSON.stringify({phone: candidatePhone ?? ""})
          })
            .then(result => {
              result?.phone.valid
                ? this.setState({phoneChecking: false, phoneValid: true, errors: this.state.errors.filter(e => e !== "phone")})
                : this.setState({phoneChecking: false, phoneValid: false, errors: this.state.errors.includes("phone") ? this.state.errors : [...this.state.errors, "phone"]})
            })
            .catch((err: any) => {
              this.setState({phoneChecking: false, phoneValid: false,errors: this.state.errors.includes("phone") ? this.state.errors : [...this.state.errors, "phone"]})
            })
        })
      }
    }
  }

  private passwordValidation(){
    let current_errors: any = [];
    const validation = passwordSecurityLevel(this.state.password);

    if(!validation.valid){
      current_errors.push("Assicurati che la password sia lunga almeno 6 lettere e contenga almeno una lettera minuscola e una maiuscola");
      this.setState({passwordStrength: undefined})
      
    }
    else{
      this.setState({
        passwordStrength: validation.strong
        ? PasswordStrength.STRONG
        : validation.medium
          ? PasswordStrength.MEDIUM
          : PasswordStrength.WEAK,
        errorTexts: this.state.errorTexts.filter(e => e.field !== "password"),
        errors: this.state.errors.filter(e => e !== "password"),
      })
    }
    

    if(current_errors.length > 0){
      let errorTexts = this.state.errorTexts.filter(e => e.field !== "password");
      errorTexts.push({field: "password", msg: current_errors.join("<\br>")});
      this.setState({errorTexts: errorTexts});
    }

    return current_errors;
  }

  private rePasswordValidation(){
    let current_errors: any = [];
    if(this.state.rePassword !== this.state.password){
      current_errors.push("La password di conferma è diversa")
    }
    return current_errors;
  }

  /**
   * Reset form element validation
   * @param element
   * @returns
   */
   resetValidation(element: any) {
    this.setState({ errors: this.state.errors.filter((item: any) => item !== element) });
    return;
  }

  private onChangeHandler(selected: any, componentName: string) {
    if (componentName === "phone") {
      selected = selected.substring(0, 10);
      this.setState({
        phone: selected,
      })
    }

    if(componentName === "password"){
      const passValidation = passwordSecurityLevel(this.state.password);
      
      let newState : any = {
        [componentName]: selected,
        passwordStrength: undefined
      };

      if(passValidation.valid){
        newState.passwordStrength = passValidation.strong
          ? PasswordStrength.STRONG
          : passValidation.medium
            ? PasswordStrength.MEDIUM
            : PasswordStrength.WEAK;
        newState.passwordLiveChecking = true;
      }
      else{
        newState.passwordLiveChecking = false;
      }

      this.setState({ ...this.state, ...newState});
    }
    else{
      this.setState({ ...this.state, [componentName]: selected });
      this.resetValidation(componentName);
    }
  }

  private validation(specificField: undefined | string = undefined) : boolean{
    let current_errors: any = [];

    if (specificField === "email" || !specificField){
      if(this.state?.emailValid === undefined || this.state?.emailValid === false){
        current_errors.push("email");
      }
    }

    if (specificField === "phone" || !specificField){
      if(this.state?.phoneValid === undefined || this.state?.phoneValid === false){
        current_errors.push("phone");
      }
    }

    if (specificField === "name" || !specificField){
      if(this.state?.name === undefined || this.state.name.length === 0){
        current_errors.push("name");
      }
    }

    if (specificField === "surname" || !specificField){
      if(this.state?.surname === undefined || this.state.surname.length === 0){
        current_errors.push("surname");
      }
    }

    if (specificField === "password" || !specificField) {
      const passErrs : any[] = this.passwordValidation();
      if(passErrs.length > 0){
        current_errors.push("password");
      }
    }

    if (specificField === "rePassword" || !specificField) {
      const passErrs : any[] = this.rePasswordValidation();
      if(passErrs.length > 0){
        current_errors.push("rePassword");
      }
    }

    if (specificField === "ivass" || !specificField){
      if(this.state?.cgu === false){
        current_errors.push("ivass");
      }
    }

    if (specificField === "cgu" || !specificField){
      if(this.state?.cgu === false){
        current_errors.push("cgu");
      }
    }

    if (specificField === "privacy" || !specificField){
      if(this.state?.privacy === false){
        current_errors.push("privacy");
      }
    }

    if (specificField === undefined) {
      this.setState({ errors: current_errors });
      setTimeout(() => {
        if (current_errors.length > 0) {
          let errorFields = document.getElementsByClassName("error-field");
          let errorField = errorFields.length > 0 ? errorFields[0] : undefined;
          if (errorField) {
            //console.log(errorField);
            window.scrollTo(
              0,
              (errorField as any).offsetTop -
                (errorField as any).offsetHeight -
                100
            );
          }
        }
      }, 300);
    }
    else {
      this.setState({ errors: [...this.state.errors, ...current_errors] });
    }

    return current_errors.length === 0;
  }

  private formSubmit() {
    this.setState({submitting: true}, () => {

      UserService.call(
        Constants.WEB_SITE_API_SERVER_URL('/users/register'),
        {
          method: "post",
          body: JSON.stringify({
            email: this.state.email,
            password: this.state.password,
            name: this.state.name,
            surname: this.state.surname,
            phone: this.state.phone,
            privacy: [
              {
                "type": "allegati3e4ter",
                "consent": this.state.ivass,
                "channels": []
              },
              {
                "type": "cgu",
                "consent": this.state.cgu,
                "channels": []
              },
              {
                "type": "generale",
                "consent": this.state.privacy,
                "channels": ["email", "phone", "whatsapp", "sms"]
              },
              {
                  "type": "commerciale",
                  "consent": this.state.marketing,
                  "channels": ["email", "phone", "whatsapp", "sms"]
              },
              {
                  "type": "newsletter",
                  "consent": this.state.newsletter,
                  "channels": []
              },
              {
                  "type": "profilazione",
                  "consent": this.state.profilazione,
                  "channels": []
              },
              {
                  "type": "cessione",
                  "consent": this.state.cessione,
                  "channels": ["email", "phone", "whatsapp", "sms"]
              }
            ]
          })
        },
        (response: any) => {
          if(response?.success === false){
              // caso di errore applicativo
              if(response.error_code === "ALREADY_REGISTERED"){
                throw new AccountAlreadyRegisteredException("Utente già registrato");
              }
              else if(response.error_code === "GENERIC"){
                throw new Error("Contatta il nostro supporto tecnico");
              }
          }
          else{
            let newState:any = {
              submitting: false,
              feedbackClass: "success",
              feedbackDialog: true,
              feedbackHeader: <h3 className="titleLev4 colorPrimaryBlue">{this.state.name}, hai quasi completato</h3>,
              feedbackBody: <p>Per ultimare la procedura, clicca sul link che ti abbiamo inviato per email. Assicurati di controllare anche nella cartella spam!</p>,
            }
    
            if(response.status === "error"){
              newState.feedbackClass = "error";
              newState.feedbackHeader = <h3 className="titleLev4 colorErrorFeedback">Qualcosa è andato storto</h3>;
              newState.feedbackBody = <p>{response.msg}</p>;
            }
            this.setState({...newState});
          }
        },
        (err: any) => {
          let newState: any = {
            submitting: false,
            feedbackClass: "error",
            feedbackDialog: true,
            feedbackHeader: <h3 className="titleLev4 colorErrorFeedback">Qualcosa è andato storto</h3>,
            feedbackBody: err instanceof AccountAlreadyRegisteredException
            ? <p>{err.message}</p>
            : <p>Contatta il nostro supporto tecnico</p>
          }
          
          this.setState({...newState});
        }
      );

    })
  }

  private handleFormSubmit() {
    if (!this.validation()) {
      return;
    }
    this.formSubmit();
  }
}

const RegistrationPage = (props: any) => {
  return <RegistrationPageComponent {...props} history={useHistory()} />
}

export default RegistrationPage;