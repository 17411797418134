import { IFieldDescriptor, IPerson, IVehicle, ICompany } from "./common";
import { WEB_SITE_API_SERVER_URL } from "./constants";
import CookieService from "./CookieService";
import { cap_pad_zeros } from "./methods";
import UserService, { AccountNotActivatedException, GenericException, MissingParametersException, PasswordException, TokenExpiredException, TokenExpireException, UnauthorizedException } from "./UserService";
import PersistedStore from "../../redux/persistedStore";
import { step1DataReset, step2DataReset, step3DataReset, userInfoDataSuccess } from "../../redux/actions";
import * as Constants from './constants';
import { isLicensePlateAutoValid, isLicensePlateMotoValid, isNameValid, isStreetAddres, isSurnameValid } from "./validate";
import { IFeedbackText } from "./Feedback";

export interface IValidationResponse{
  status: boolean;
  error_fields: string[];
  error_messages: IFeedbackText[];
}

interface IValidationFieldResponse{
  field: string;
  notify: boolean;
  invalid: boolean
}
export interface IPrivacyStatus{
  type: string;
  consent: boolean;
  channels: string[];
}
export default class User {

  private static obj: User | undefined = undefined;
  private static store: any;

  id: number | string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  privacy: IPrivacyStatus[];

  userData: IPerson;

  people: IPerson[];
  vehicles: IVehicle[];
  companies: ICompany[];

  
  //constanti per precaricamento e validazione campi della persona
  public static readonly FIELDS_VEHICLE_AUTO: string = "vehicle_auto";
  public static readonly FIELDS_VEHICLE_MOTO: string = "vehicle_moto";
  public static readonly FIELDS_VEHICLE: string = "vehicle";
  private static keepAliveTS: ReturnType<typeof setInterval>;
  private static readonly KEEP_ALIVE_INTERVAL: number = 120000; //2 minuti
  private static LOGGED_STATUS: boolean = false;

  private constructor(){
    this.id = -1;
    this.name = "";
    this.surname = "";
    this.email = "";
    this.phone = "";
    this.privacy = [];
  
    this.userData = {};
  
    this.people = [];
    this.vehicles = [];
    this.companies = [];
  }

  public setInitialState(): User{
    this.id = -1;
    this.name = "";
    this.surname = "";
    this.email = "";
    this.phone = "";
    this.privacy = [];
  
    this.userData = {};
  
    this.people = [];
    this.vehicles = [];
    this.companies = [];

    User.LOGGED_STATUS = false;

    return this;
  }

  public static resetKeepAliveTimer(){
    if(User.keepAliveTS){
      clearInterval(User.keepAliveTS)
    }
  }
  public static setKeepAliveTimer(callback?: Function){
    User.keepAliveTS = setInterval(() => {
      User.keepAlive()
        .then((response) => {
          callback?.()
        })
        .catch((error) => {
          console.log("catch in setKeepAliveTimer");
        })
    }, User.KEEP_ALIVE_INTERVAL);
  }

  private static keepAlive(callback?: Function){
    return UserService.call(
      WEB_SITE_API_SERVER_URL('/users/keep-alive'),
      {
          method: "POST",
          body: {}
      },
      (response: any) => {
        if(response?.success === false){
          if (response.error_code === "TOKEN_EXPIRED" || response.error_code === "MISSING_TOKEN") {
            // Token expired
            throw new TokenExpiredException("Login scaduto");
          }
        }
        else if(response?.success === true){
          User.LOGGED_STATUS = true;
        }
      },
      (error: any) => {
        if(error instanceof TokenExpiredException){
          // mi trovo in questa exception solo se i cookie sono stati cancellati manualmente
          // è stato manomesso il JWT, in ambedue i casi non ha senso aprire il login panel e fillare la mail
          // resetto la condizione dell'utente nello store e i dati di preventivazione

          if(!User.store){
            User.store = PersistedStore.getDefaultStore().store;
          }

          User.logout();
          ["auto", "moto"].forEach((v: string) => {
            User.resetStepData(v);
          })
          User.store.dispatch(userInfoDataSuccess({
            logged: false,
            openLogin: false,
            email: "",
            userData: JSON.stringify(User.getInstance())
          }))
        }
      }
    )
  }

  public static isAlive(){
    return User.LOGGED_STATUS;
  }

  private init(data: any){
    this.id = data.id;
    this.name = data.name;
    this.surname = data.surname;
    this.email = data.email;
    this.phone = data.phone;

    data.userData.dateOfBirth = data.userData?.dateOfBirth
      ? new Date(data.userData?.dateOfBirth)
      : undefined;
    if (isStreetAddres(data.userData.address ?? "")){
      let parsedAddress = data.userData.address.split(",");
      data.userData.address = parsedAddress[0].trim()
      data.userData.addressNumber = parsedAddress.pop().trim();
    }
    this.userData = data.userData;
    this.privacy = data.privacy;

    this.people = data.people?.map((p: any) => {
      let refinedFields: any = {};
      if (p.dateOfBirth) {
        refinedFields.dateOfBirth = new Date(p.dateOfBirth);
      }
      if (p.postalCodeOfResidence) {
        refinedFields.postalCodeOfResidence = cap_pad_zeros(p.postalCodeOfResidence);
      }
      if (isStreetAddres(p.address ?? "")){
        refinedFields.addressNumber = p.address.split(",").pop().trim();
      }
      if(p.cityOfBirth){
        if(p.cityOfBirth.id === null || p.cityOfBirth.id === undefined){
          p.cityOfBirth = undefined;
        }
      }
      if(p.cityOfResidence){
        if(p.cityOfResidence.id === null || p.cityOfResidence.id === undefined) p.cityOfResidence = undefined;
      }
      if(p.countryOfBirth){
        if(p.countryOfBirth?.id === undefined || p.countryOfBirth.id === null) p.countryOfBirth = undefined;
      }
      if (!p?.email) {
        refinedFields.email = this.email;
      }
      if (!p?.phone) {
        refinedFields.phone = this.phone;
      }
        
      return {
        ...p,
        ...refinedFields,
      };
    });

    this.vehicles = data?.vehicles?.map((v: any) => {
      delete v.vehicleUse;
      delete v.vehicleParkingLocation;
      return v
    });

    this.companies = data?.companies;

    return this;
  }

  static getInstance(): User {
    if(User.obj !== undefined){
      return User.obj
    }
    else{
      User.obj = new User();
    }

    return User.obj;
  }

  getPeople(customFilter: Function | undefined = undefined, addUserData: boolean = true, sortFunction?: sortPeopleFunction): IPerson[] {
    let people: IPerson[] = [];
    if(addUserData){
      people = [this.userData, ...this.people];
    }
    else{
      people = this.people;
    }

    people = (customFilter
      ? people.filter((p: IPerson) => customFilter(p))
      : people)
        .map((p: IPerson) => {return {email: this.userData.email, phone: this.userData.phone, ...p}}) //mi arricuro che sia sempre avvalorato email e phone

    if(sortFunction) people.sort(sortFunction)
    
    return people;
  }

  getPersonById(idPerson: number | string | undefined): IPerson | undefined {
    return this.userData?.id === idPerson
      ? this.userData
      : this.people.find((p) => p.id === idPerson);
  }

  setPerson(person: IPerson){
    if(person?.dateOfBirth){
      person.dateOfBirth = new Date(person.dateOfBirth);
    }
    
    if(person?.id === undefined){
      this.people.push({email: this.userData.email, phone: this.userData.phone, ...person});
    }
    else{
      if(person.id === this.userData.id){
        let dataToUpdate: any = {}
        if(person.phone !== this.userData.phone){
          dataToUpdate.phone = person.phone;
        }
        if(person.email !== this.userData.email){
          dataToUpdate.email = person.email;
        }
        if(JSON.stringify(dataToUpdate) !== "{}"){
          this.people = this.people.map((p: IPerson) => {return {...p, ...dataToUpdate}})
        }
        this.userData = person;
      }
      else{
        this.people = [...this.people.filter((p: IPerson) => p.id !== person.id), {email: this.userData.email, phone: this.userData.phone, ...person}]        
      }
    }
  }

  removePerson(id: string){
    this.people = this.people.filter((p: IPerson) => p.id !== id);
  }

  public static getPersonSafeName(p: IPerson | undefined): string {
    return p?.name || p?.surname
      ? (`${p?.name ?? ""} ${p?.surname ?? ""}`).trim()
      : "Senza nome";
  }

  getVehicles(customFilter: Function | undefined = undefined, sortFunction?: sortPeopleFunction): IVehicle[] {
    let vehicles: IVehicle[] = [];

    vehicles = customFilter
      ? this.vehicles.filter((v: IVehicle) => customFilter(v))
      : this.vehicles;

    if(sortFunction) vehicles.sort(sortFunction)

    return vehicles;
  }

  getVehicleById(idVehicle: number | string | undefined): IVehicle | undefined {
    return this.vehicles.find((p) => p.id === idVehicle);
  }

  setVehicle(vehicle: IVehicle){
    this.vehicles = [...this.vehicles.filter((v: IVehicle) => v.id !== vehicle.id), vehicle]
  }

  removeVehicle(id: string){
    this.vehicles = this.vehicles.filter((v: IVehicle) => v.id !== id);
  }

  public static getVehicleSafeName(v: IVehicle | undefined): string {
    return `${v?.vehicleName ?? ""} ${(v?.vehicleLicensePlateNumber ?? "") !== ""
      ? "(" + v?.vehicleLicensePlateNumber?.toUpperCase() + ")"
      : ""
    }`.trim();
  }

  getCompanies(customFilter: Function | undefined = undefined, sortFunction?: sortPeopleFunction): IPerson[] {
    let companies: ICompany[] = this.companies;

    companies = customFilter
      ? companies.filter((p: ICompany) => customFilter(p))
      : companies

    if(sortFunction) companies.sort(sortFunction)
    
    return companies;
  }

  getCompanyById(idCompany: number | string | undefined): ICompany | undefined {
    return this.companies.find((p) => p.id === idCompany);
  }

  setCompany(company: ICompany){
    this.companies = [...this.companies.filter((c: ICompany) => c.id !== company.id), company]
  }

  removeCompany(id: string){
    this.companies = this.companies.filter((c: ICompany) => c.id !== id);
  }

  public upsert(entity: string, data: IPerson | IVehicle) {
    let url: string = "";
    let callback: Function;
    if (entity === "person") {
      url = WEB_SITE_API_SERVER_URL("/persons/upsert");
      callback = (data: IPerson) => this.setPerson(data);
    }
    else if (entity === "vehicle") {
      url = WEB_SITE_API_SERVER_URL("/vehicles/upsert");
      callback = (data: IVehicle) => this.setVehicle(data);
    }
    else if (entity === "company") {
      url = WEB_SITE_API_SERVER_URL("/companies/upsert");
      callback = (data: ICompany) => this.setCompany(data);
    }

    return UserService.call(url,{
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((returnData: IVehicle | IPerson) => {
        if(entity === "vehicle"){
          delete (returnData as any).vehicleUse;
          delete (returnData as any).vehicleParkingLocation;
        }
        callback(returnData)
        return returnData;
      })
  }

  public static isUser(object: any): object is User {
    return object instanceof User;
  }

  /**
   * Valida i dati di una persona
   * 
   * NOTA: ho introdotto il parametro personRole, in quanto nello step3 (riga 1386), gli errori del contraente
   * vengono filtrati in base alla string "contractor"...
   * 
   * @param personData 
   * @param fields 
   * @param user 
   * @param personRole la tipologia della figura da validare (contractor, driver..)
   * @returns 
   */
  public static async validatePerson (personData: IPerson, fields: IFieldDescriptor[], personRole?: string): Promise<IValidationResponse> {
    let fields_toCheck: string[] = fields.map((elem: IFieldDescriptor) => elem.field);
    let mandatory_fields: string[] = fields.filter((elem: IFieldDescriptor) => elem.validation.mandatory)
      .map((elem: IFieldDescriptor) => elem.field);

    let validation: IValidationResponse = {
      status: true,
      error_fields: [],
      error_messages: [],
    };

    let promises: Promise<IValidationFieldResponse>[] = [];

    if (fields_toCheck?.includes("gender")) {
      promises.push(Promise.resolve({
        field: "gender",
        notify: !personData?.gender,
        invalid: mandatory_fields.includes("gender") ? !personData?.gender : false,
      }));
    }

    if (fields_toCheck?.includes("dateOfBirth")) {
      promises.push(Promise.resolve({
        field: "dateOfBirth",
        notify: !(personData?.dateOfBirth instanceof Date) || isNaN(personData.dateOfBirth.getTime()),
        invalid: mandatory_fields.includes("dateOfBirth")
          ? personData?.dateOfBirth === undefined || !(
            !isNaN((personData.dateOfBirth as Date)?.getTime()) ||
            !isNaN((new Date(personData.dateOfBirth)?.getTime()))
          )
          : false,
      }));

    }

    if (fields_toCheck?.includes("bornInItaly")) {
      promises.push(Promise.resolve({
        field: "bornInItaly",
        notify: personData?.bornInItaly === undefined,
        invalid: mandatory_fields.includes("bornInItaly") ? personData?.bornInItaly === undefined : false,
      }))
    }

    if (fields_toCheck?.includes("countryOfBirth") && personData?.bornInItaly === false) {
      promises.push(Promise.resolve({
        field: "countryOfBirth",
        notify: !personData?.countryOfBirth?.id,
        invalid: mandatory_fields.includes("countryOfBirth") ? !personData?.countryOfBirth?.id : false,
      }))
    }

    if(fields_toCheck?.includes("cityOfBirth") && personData?.bornInItaly === true){
      promises.push(Promise.resolve({
        field: "cityOfBirth",
        notify: !personData?.cityOfBirth?.id,
        invalid: mandatory_fields.includes("cityOfBirth") ? !personData?.cityOfBirth?.id : false,
      }))
    }

    if (fields_toCheck?.includes("children")) {
      personData.children = personData?.children ? personData.children.sort((a,b) => (a < b) ? -1 : (a > b) ? 1 : 0) : undefined;

      promises.push(Promise.resolve({
        field: "children",
        notify: !personData?.children ||
          !(personData.children.join(',') === '0' || personData.children.join(',') === '1' || personData.children.join(',') === '2' || personData.children.join(',') === '1,2'),
          invalid: mandatory_fields.includes("children")
            ? (!personData?.children ||
              !(personData.children.join(',') === '0' || personData.children.join(',') === '1' || personData.children.join(',') === '2' || personData.children.join(',') === '1,2'))
            : false,
      }));
    }

    if (fields_toCheck?.includes("youngestChild")) {
      
      if(personData?.children?.includes(1)){
        promises.push(Promise.resolve({
          field: "youngestChild",
          notify: personData?.youngestChild === undefined || isNaN(parseInt(personData.youngestChild as unknown as string)),
          invalid: mandatory_fields.includes("youngestChild") ? personData?.youngestChild === undefined || isNaN(parseInt(personData.youngestChild as unknown as string)) : false
        }));
      }
    }

    if (fields_toCheck?.includes("civilState")) {
      promises.push(Promise.resolve({
        field: "civilState",
        notify: !personData?.civilState,
        invalid: mandatory_fields.includes("civilState") ? !personData?.civilState : false
      }));
    }

    if (fields_toCheck?.includes("degree")) {
      promises.push(Promise.resolve({
        field: "degree",
        notify: !personData?.degree,
        invalid: mandatory_fields.includes("degree") ? !personData?.degree : false
      }));
    }

    if (fields_toCheck?.includes("profession")) {
      promises.push(Promise.resolve({
        field: "profession",
        notify: !personData?.profession,
        invalid: mandatory_fields.includes("profession") ? !personData?.profession : false
      }));
    }

    if (fields_toCheck?.includes("name")) {
      promises.push(Promise.resolve({
        field: "name",
        notify: !personData?.name || !isNameValid(personData.name),
        invalid: mandatory_fields.includes("name") ? (!personData?.name || !isNameValid(personData.name)) : false
      }));
    }

    if (fields_toCheck?.includes("surname")) {
      promises.push(Promise.resolve({
        field: "surname",
        notify: !personData?.surname || !isSurnameValid(personData.surname),
        invalid: mandatory_fields.includes("surname") ? (!personData?.surname || !isSurnameValid(personData.surname)) : false
      }));
    }

    if (fields_toCheck?.includes("cityOfResidence")) {
      promises.push(Promise.resolve({
        field: `${personRole ?? ""}${personRole ?? false ? "CityOfResidence" : "cityOfResidence"}`,
        notify: !personData?.cityOfResidence?.id,
        invalid: mandatory_fields.includes("cityOfResidence") ? (!personData?.cityOfResidence?.id) : false
      }));
    }
    
    if (fields_toCheck?.includes("postalCodeOfResidence")) {
      promises.push(Promise.resolve({
        // field: (personRole ?? "") + "postalCodeOfResidence",
        field: `${personRole ?? ""}${personRole ?? false ? "PostalCode" : "postalCodeOfResidence"}`,
        notify: !personData?.postalCodeOfResidence,
        invalid: mandatory_fields.includes("postalCodeOfResidence") ? !personData?.postalCodeOfResidence : false
      }));
    }

    if (fields_toCheck?.includes("address")) {

      // promises.push(Promise.resolve({
      //   field: "address",
      //   notify: !personData?.address ||
      //     !isStreetAddres(personData.address) ||
      //     !Constants.PREFIX_ADDRESS.includes(personData.address.toLowerCase().split(' ')[0]),
      //   invalid: mandatory_fields.includes("address")
      //     ? (!personData?.address ||
      //       !isStreetAddres(personData.address) ||
      //       !Constants.PREFIX_ADDRESS.includes(personData.address.toLowerCase().split(' ')[0]))
      //       : false
      // }));
      promises.push(Promise.resolve({
        // field: (personRole ?? "") + "address",
        field: `${personRole ?? ""}${personRole ?? false ? "Address" : "address"}`,
        notify: !personData?.address ||
        personData?.address === "" ||
          !Constants.PREFIX_ADDRESS.includes(personData.address.toLowerCase().split(' ')[0]),
        invalid: mandatory_fields.includes("address")
          ? (!personData?.address ||
            personData?.address === "" ||
            !Constants.PREFIX_ADDRESS.includes(personData.address.toLowerCase().split(' ')[0]))
          : false
      }));
    }

    if(fields_toCheck?.includes("addressNumber")){
      const addressNumb = (personData.addressNumber === undefined || personData.addressNumber === "") ? undefined : personData.addressNumber;
      const regex_civico = new RegExp('[0-9]+([\\/ A-z-]){0,9}(s|,)?', 'g');
      const valid_civico = regex_civico.exec(addressNumb ?? "") !== null ? true : false;

      promises.push(Promise.resolve({
        // field: (personRole ?? "") + "addressNumber",
        field: `${personRole ?? ""}${personRole ?? false ? "AddressNumber" : "addressNumber"}`,
        // nel caso in cui personData?.addressNumber === "" Number(personData?.addressNumber ?? NaN) ritorna 0!!!
        // notify: isNaN(Number(personData?.addressNumber ?? NaN)),
        notify: !valid_civico,
        invalid: mandatory_fields.includes("addressNumber")
          // ? isNaN(Number(personData?.addressNumber ?? NaN))
          ? !valid_civico
          : false
      }));
    }
    
    if (fields_toCheck?.includes("italianDrivingLicense")) {
      promises.push(Promise.resolve({
        field: "italianDrivingLicense",
        notify: personData?.italianDrivingLicense === undefined,
        invalid: mandatory_fields.includes("italianDrivingLicense") ? personData?.italianDrivingLicense === undefined : false
      }));
    }

    if (fields_toCheck?.includes("drivingLicenseAge")) {
      promises.push(Promise.resolve({
        field: "drivingLicenseAge",
        notify: !personData?.drivingLicenseAge,
        invalid: mandatory_fields.includes("drivingLicenseAge") ? !personData?.drivingLicenseAge : false
      }));
    }

    if (fields_toCheck?.includes("email")) {
      promises.push(UserService.call(WEB_SITE_API_SERVER_URL("/utils/misc/validate"), {
        method: "post",
        body: JSON.stringify({
          email: personData?.email ?? "",
          check_user_exists: personData?.id === undefined ? true : false})
      })
        .then(result => {
          let json: {
            field: string,
            notify: boolean,
            invalid: boolean
          } = {
            field: "email",
            notify: false,
            invalid: false,
          }
          
          json.notify = !result?.email.valid || (
            result?.email?.user_exists !== undefined
              ? result.email.user_exists
              : false
            );
          json.invalid = mandatory_fields.includes("email")
            ? (!result?.email.valid || (
              result?.email?.user_exists !== undefined
                ? result.email.user_exists
                : false
              ))
            : false;
          return json;
        })
      );
    }

    if (fields_toCheck?.includes("phone")) {
      promises.push(UserService.call(WEB_SITE_API_SERVER_URL("/utils/misc/validate"), {
        method: "post",
        body: JSON.stringify({phone: personData?.phone ?? ""})
      })
        .then(result => {
          let json: {
            field: string,
            notify: boolean,
            invalid: boolean
          } = {
            field: "phone",
            notify: false,
            invalid: false,
          }
          json.notify = !result?.phone.valid;
          json.invalid = mandatory_fields.includes("phone") ? !result?.phone.valid : false;
          return json;
        })
      )
    };

    await Promise.allSettled(promises).then((results) => {
      results.forEach((fieldResponse) => {
        if (fieldResponse.status === "fulfilled") {
          if (fieldResponse.value !== null) {
            const {field, invalid, notify} = fieldResponse.value;
            if(notify){
              validation.error_fields.push(field);
              let descriptor: IFieldDescriptor | undefined = fields.find((descriptor: IFieldDescriptor) => descriptor.field === field)
              if(descriptor){
                validation.error_messages.push({
                  field: field,
                  msg: descriptor.validation.fail_msg,
                  severity: descriptor.validation.fail_severity
                });
              }
            }
            if(validation.status && invalid){
              validation.status = false;
            }
          }
        }
      });
    });

    return validation;
  }

  public static async validateVehicle(vehicleData: IVehicle, fields: IFieldDescriptor[], allowedValues?: any): Promise<IValidationResponse>{

    let fields_toCheck: string[] = fields.map((elem: IFieldDescriptor) => elem.field);
    let mandatory_fields: string[] = fields.filter((elem: IFieldDescriptor) => elem.validation.mandatory)
      .map((elem: IFieldDescriptor) => elem.field);

    let validation: IValidationResponse = {
      status: true,
      error_fields: [],
      error_messages: [],
    };

    let promises: Promise<IValidationFieldResponse>[] = [];

    if (fields_toCheck?.includes("vehicleType")) {
      promises.push(Promise.resolve({
        field: "vehicleType",
        notify: vehicleData?.vehicleType === undefined ||
          !["auto", "moto"].includes(vehicleData.vehicleType) ||
          (typeof(fields) === "string" && [User.FIELDS_VEHICLE_AUTO, User.FIELDS_VEHICLE_MOTO].includes(fields)
          ? vehicleData.vehicleType !== (fields === User.FIELDS_VEHICLE_AUTO ? "auto" : "moto")
          : false),
        invalid: !mandatory_fields.includes("vehicleType")
          ? false
          : (vehicleData?.vehicleType === undefined ||
              !["auto", "moto"].includes(vehicleData.vehicleType) ||
              (typeof(fields) === "string" && [User.FIELDS_VEHICLE_AUTO, User.FIELDS_VEHICLE_MOTO].includes(fields)
              ? vehicleData.vehicleType !== (fields === User.FIELDS_VEHICLE_AUTO ? "auto" : "moto")
              : false))
      }));
    }

    if (fields_toCheck?.includes("vehicleName")) {
      promises.push(Promise.resolve({
        field: "vehicleName",
        notify: !vehicleData?.vehicleName,
        invalid: !mandatory_fields.includes("vehicleName") ? false : !vehicleData?.vehicleName
      }));
    }

    if (fields_toCheck?.includes("vehicleLicensePlateNumber")) {
      if (vehicleData.vehicleOwned || (vehicleData as any)?.knowLicensePlate) {
        promises.push(Promise.resolve({
          field: "vehicleLicensePlateNumber",
          notify: vehicleData?.vehicleLicensePlateNumber === undefined || vehicleData.vehicleLicensePlateNumber === "" ||
            !["auto", "moto"].includes(vehicleData?.vehicleType ?? "") ||
            !(vehicleData.vehicleType === "auto"
              ? isLicensePlateAutoValid(vehicleData.vehicleLicensePlateNumber)
              : isLicensePlateMotoValid(vehicleData.vehicleLicensePlateNumber)
            ),
          invalid: !mandatory_fields.includes("vehicleLicensePlateNumber")
            ? false
            : vehicleData?.vehicleLicensePlateNumber === undefined || vehicleData.vehicleLicensePlateNumber === "" ||
            !["auto", "moto"].includes(vehicleData?.vehicleType ?? "") ||
              !(vehicleData.vehicleType === "auto"
                ? isLicensePlateAutoValid(vehicleData.vehicleLicensePlateNumber)
                : isLicensePlateMotoValid(vehicleData.vehicleLicensePlateNumber)
              )
        }));
      }
    }

    if (fields_toCheck?.includes("vehicleRegistrationYear")) {
      promises.push(Promise.resolve({
        field: "vehicleRegistrationYear",
        notify: vehicleData?.vehicleRegistrationYear === undefined || isNaN(vehicleData.vehicleRegistrationYear) || vehicleData.vehicleRegistrationYear > (new Date().getFullYear()) || vehicleData.vehicleRegistrationYear < 1888,
        invalid: !mandatory_fields.includes("vehicleRegistrationYear")
          ? false
          : vehicleData?.vehicleRegistrationYear === undefined || isNaN(vehicleData.vehicleRegistrationYear) || vehicleData.vehicleRegistrationYear > (new Date().getFullYear()) || vehicleData.vehicleRegistrationYear < 1888
      }));
    }

    if (fields_toCheck?.includes("vehicleRegistrationMonth")) {
      promises.push(Promise.resolve({
        field: "vehicleRegistrationMonth",
        notify: vehicleData?.vehicleRegistrationMonth === undefined || isNaN(vehicleData.vehicleRegistrationMonth) || vehicleData.vehicleRegistrationMonth > 12 || vehicleData.vehicleRegistrationMonth < 1,
        invalid: !mandatory_fields.includes("vehicleRegistrationMonth")
          ? false
          : vehicleData?.vehicleRegistrationMonth === undefined || isNaN(vehicleData.vehicleRegistrationMonth) || vehicleData.vehicleRegistrationMonth > 12 || vehicleData.vehicleRegistrationMonth < 1
      }));
    }

    if (fields_toCheck?.includes("vehicleBuyYear")) {
      promises.push(Promise.resolve({
        field: "vehicleBuyYear",
        notify: vehicleData?.vehicleBuyYear === undefined ||
          isNaN(vehicleData.vehicleBuyYear) ||
          vehicleData.vehicleBuyYear > (new Date().getFullYear()) ||
          vehicleData.vehicleBuyYear < 1888 ||
          (vehicleData?.vehicleRegistrationYear
            ? vehicleData.vehicleBuyYear < vehicleData.vehicleRegistrationYear
            : false
          ),
        invalid: !mandatory_fields.includes("vehicleBuyYear")
          ? false
          : vehicleData?.vehicleBuyYear === undefined ||
            isNaN(vehicleData.vehicleBuyYear) ||
            vehicleData.vehicleBuyYear > (new Date().getFullYear()) ||
            vehicleData.vehicleBuyYear < 1888 ||
            (vehicleData?.vehicleRegistrationYear
              ? vehicleData.vehicleBuyYear < vehicleData.vehicleRegistrationYear
              : false
            )
      }));
    }

    if (fields_toCheck?.includes("vehicleBrand")) {
      promises.push(Promise.resolve({
        field: "vehicleBrand",
        notify: vehicleData?.vehicleBrand === undefined || vehicleData?.vehicleBrand === "" || vehicleData?.vehicleBrand === null ||
          (allowedValues?.brandList
            ? !allowedValues.brandList.some((item: any) => item.id === vehicleData.vehicleBrand)
            : false
          ),
        invalid: !mandatory_fields.includes("vehicleBrand")
          ? false
          : (vehicleData?.vehicleBrand === undefined || vehicleData?.vehicleBrand === "" || vehicleData?.vehicleBrand === null ||
            (allowedValues?.brandList
              ? !allowedValues.brandList.some((item: any) => item.id === vehicleData.vehicleBrand)
              : false
            ))
      }));
    }

    if (fields_toCheck?.includes("vehicleModel")) {
      promises.push(Promise.resolve({
        field: "vehicleModel",
        notify: vehicleData?.vehicleModel === undefined || vehicleData?.vehicleModel === "" ||  vehicleData?.vehicleModel === null ||
          (allowedValues?.modelList
            ? !allowedValues.modelList.some((item: any) => item.id === vehicleData.vehicleModel)
            : false
          ),
        invalid: !mandatory_fields.includes("vehicleModel")
            ? false
            : (vehicleData?.vehicleModel === undefined ||  vehicleData?.vehicleModel === "" || vehicleData?.vehicleModel === null ||
              (allowedValues?.modelList              
                ? !allowedValues.modelList.some((item: any) => item.id === vehicleData.vehicleModel)
                : false
              ))
      }));
    }

    if (fields_toCheck?.includes("vehicleFitting")) {
      promises.push(Promise.resolve({
        field: "vehicleFitting",
        notify: vehicleData?.vehicleFitting === undefined || vehicleData?.vehicleFitting === "" || vehicleData?.vehicleFitting === "##" ||
          (allowedValues?.fittingList
            ? !allowedValues.fittingList.some((item: any) => item.id === vehicleData.vehicleFitting)
            : false  
          ),
        invalid: !mandatory_fields.includes("vehicleFitting")
          ? false
          : (vehicleData?.vehicleFitting === undefined || vehicleData?.vehicleFitting === "" || vehicleData?.vehicleFitting === "##" ||
            (allowedValues?.fittingList
              ? !allowedValues.fittingList.some((item: any) => item.id === vehicleData.vehicleFitting)
              : false  
            ))
      }));
    }

    if (fields_toCheck?.includes("vehicleOwned")) {
      promises.push(Promise.resolve({
        field: "vehicleOwned",
        notify: !(vehicleData?.vehicleOwned === true || vehicleData?.vehicleOwned === false),
        invalid: !mandatory_fields.includes("vehicleOwned")
          ? false
          : !(vehicleData?.vehicleOwned === true || vehicleData?.vehicleOwned === false)
      }));
    }

    if (fields_toCheck?.includes("vehicleAntitheft")) {
      promises.push(Promise.resolve({
        field: "vehicleAntitheft",
        notify: vehicleData?.vehicleAntitheft === undefined || vehicleData?.vehicleAntitheft === "" || vehicleData?.vehicleAntitheft === null ||
          (allowedValues?.antitheftList
            ? !allowedValues.antitheftList.some((item: any) => item.value === vehicleData.vehicleAntitheft)
            : false  
          ),
        invalid: !mandatory_fields.includes("vehicleAntitheft")
          ? false
          : (vehicleData?.vehicleAntitheft === undefined || vehicleData?.vehicleAntitheft === "" ||  vehicleData?.vehicleAntitheft === null ||
            (allowedValues?.antitheftList
              ? !allowedValues.antitheftList.some((item: any) => item.value === vehicleData.vehicleAntitheft)
              : false  
            )) 
      }));
    }

    if (fields_toCheck?.includes("vehicleFuelType")) {
      promises.push(Promise.resolve({
        field: "vehicleFuelType",
        notify: vehicleData?.vehicleFuelType === undefined || vehicleData?.vehicleFuelType === "" ||
          (allowedValues?.fuelTypeList
            ? !allowedValues.fuelTypeList.some((item: any) => item.id === vehicleData.vehicleFuelType)
            : false  
          ),
        invalid: !mandatory_fields.includes("vehicleFuelType")
          ? false
          : (vehicleData?.vehicleFuelType === undefined || vehicleData?.vehicleFuelType === "" ||
            (allowedValues?.fuelTypeList
              ? !allowedValues.fuelTypeList.some((item: any) => item.id === vehicleData.vehicleFuelType)
              : false  
            ))
      }));
    }

    if (fields_toCheck?.includes("vehicleEngineDisplacement")) {
      promises.push(Promise.resolve({
        field: "vehicleEngineDisplacement",
        notify: vehicleData?.vehicleEngineDisplacement === undefined || vehicleData?.vehicleEngineDisplacement === "" ||
          (allowedValues?.engineDisplacementList
            ? !allowedValues.engineDisplacementList.some((item: any) => item.id === vehicleData.vehicleEngineDisplacement)
            : false  
          ),
        invalid: !mandatory_fields.includes("vehicleEngineDisplacement")
          ? false
          : (vehicleData?.vehicleEngineDisplacement === undefined || vehicleData?.vehicleEngineDisplacement === "" ||
            (allowedValues?.engineDisplacementList
              ? !allowedValues.engineDisplacementList.some((item: any) => item.id === vehicleData.vehicleEngineDisplacement)
              : false  
            ))
      }));
    }

    if (fields_toCheck?.includes("vehicleTowbarMounted")) {
      promises.push(Promise.resolve({
        field: "vehicleTowbarMounted",
        notify: !(vehicleData?.vehicleTowbarMounted === true || vehicleData?.vehicleTowbarMounted === false),
        invalid: !mandatory_fields.includes("vehicleTowbarMounted")
          ? false
          : !(vehicleData?.vehicleTowbarMounted === true || vehicleData?.vehicleTowbarMounted === false)
      }));
    }

    if (fields_toCheck?.includes("vehicleGplMounted")) {
      promises.push(Promise.resolve({
        field: "vehicleGplMounted",
        notify: !(vehicleData?.vehicleGplMounted === true || vehicleData?.vehicleGplMounted === false),
        invalid: !mandatory_fields.includes("vehicleGplMounted")
          ? false
          : !(vehicleData?.vehicleGplMounted === true || vehicleData?.vehicleGplMounted === false)
      }));
    }

    await Promise.allSettled(promises)
      .then((results) => {
        results.forEach((fieldResponse) => {
          if (fieldResponse.status === "fulfilled") {
            const {field, invalid, notify} = fieldResponse.value;
            if(notify){
              validation.error_fields.push(field);
            }
            if(validation.status && invalid){
              validation.status = false;
            }
          }
        });
      });

    return validation;
  }


  public static async validateCompany (companyData: ICompany, fields: IFieldDescriptor[]):  Promise<IValidationResponse> {
    let fields_toCheck: string[] = fields.map((elem: IFieldDescriptor) => elem.field);
    let mandatory_fields: string[] = fields.filter((elem: IFieldDescriptor) => elem.validation.mandatory)
      .map((elem: IFieldDescriptor) => elem.field);

    let validation: IValidationResponse = {
      status: true,
      error_fields: [],
      error_messages: [],
    };

    let promises: Promise<IValidationFieldResponse>[] = [];

    if (fields_toCheck?.includes("name")) {
      promises.push(Promise.resolve({
        field: "name",
        notify: !companyData?.name,
        invalid: mandatory_fields.includes("name") ? !companyData?.name : false,
      }));
    }

    if (fields_toCheck?.includes("legal_name")) {
      promises.push(Promise.resolve({
        field: "legal_name",
        notify: !companyData?.legal_name,
        invalid: mandatory_fields.includes("legal_name") ? !companyData?.legal_name : false,
      }));
    }

    if (fields_toCheck?.includes("vat")) {
      promises.push(Promise.resolve({
        field: "vat",
        notify: !companyData?.vat,
        invalid: mandatory_fields.includes("vat") ? !companyData?.vat : false,
      }));
    }

    if (fields_toCheck?.includes("registered_office_address")) {
      promises.push(Promise.resolve({
        field: "registered_office_address",
        notify: !companyData?.registered_office_address,
        invalid: mandatory_fields.includes("registered_office_address") ? !companyData?.registered_office_address : false,
      }));
    }





    /*if (fields_toCheck?.includes("registered_office_addressNumber")) {
      promises.push(Promise.resolve({
        field: "registered_office_addressNumber",
        notify: !companyData?.registered_office_addressNumber,
        invalid: mandatory_fields.includes("registered_office_addressNumber") ? !companyData?.registered_office_addressNumber : false,
      }));
    }

    if (fields_toCheck?.includes("registered_office_cityOfResidence")) {
      promises.push(Promise.resolve({
        field: "registered_office_cityOfResidence",
        notify: !companyData?.registered_office_cityOfResidence,
        invalid: mandatory_fields.includes("registered_office_cityOfResidence") ? !companyData?.registered_office_cityOfResidence : false,
      }));
    }

    if (fields_toCheck?.includes("registered_office_codeOfResidence")) {
      promises.push(Promise.resolve({
        field: "registered_office_codeOfResidence",
        notify: !companyData?.registered_office_postalCode,
        invalid: mandatory_fields.includes("registered_office_codeOfResidence") ? !companyData?.registered_office_postalCode : false,
      }));
    }*/

    if (fields_toCheck?.includes("referent_first_name")) {
      promises.push(Promise.resolve({
        field: "referent_first_name",
        notify: !companyData?.referent_first_name,
        invalid: mandatory_fields.includes("referent_first_name") ? !companyData?.referent_first_name : false,
      }));
    }

    if (fields_toCheck?.includes("referent_last_name")) {
      promises.push(Promise.resolve({
        field: "referent_last_name",
        notify: !companyData?.referent_last_name,
        invalid: mandatory_fields.includes("referent_last_name") ? !companyData?.referent_last_name : false,
      }));
    }

    if (fields_toCheck?.includes("referent_email")) {
      promises.push(Promise.resolve({
        field: "referent_email",
        notify: !companyData?.referent_email,
        invalid: mandatory_fields.includes("referent_email") ? !companyData?.referent_email : false,
      }));
    }

    if (fields_toCheck?.includes("referent_phone")) {
      promises.push(Promise.resolve({
        field: "referent_phone",
        notify: !companyData?.referent_phone,
        invalid: mandatory_fields.includes("referent_phone") ? !companyData?.referent_phone : false,
      }));
    }


    await Promise.allSettled(promises).then((results) => {
      results.forEach((fieldResponse) => {
        if (fieldResponse.status === "fulfilled") {
          if (fieldResponse.value !== null) {
            const {field, invalid, notify} = fieldResponse.value;
            if(notify){
              validation.error_fields.push(field);
              let descriptor: IFieldDescriptor | undefined = fields.find((descriptor: IFieldDescriptor) => descriptor.field === field)
              if(descriptor){
                validation.error_messages.push({
                  field: field,
                  msg: descriptor.validation.fail_msg,
                  severity: descriptor.validation.fail_severity
                });
              }
            }
            if(validation.status && invalid){
              validation.status = false;
            }
          }
        }
      });
    });

    return validation;
  }

  /**
   * Set the envirorment in case of valid JWT
   */
  public static loginWithJWT(jwt: string): Promise<User>{
    
    return UserService.call(
      WEB_SITE_API_SERVER_URL('/users/my-data'),
      undefined,
      (response: any) => {
        if(response?.success === false){
          // token scaduto pulisco gli step data
          let error: Error = new Error();
          if(response.error_code === "TOKEN_EXPIRED" || response.error_code === "MISSING_TOKEN"){
            error = new TokenExpireException("Sessione scaduta");
          }
          if(response.error_code === "UNAUTHORIZED"){
            error = new UnauthorizedException("Sessione scaduta");
          }
          if(response.error_code === "MISSING_PARAMS"){
            error = new MissingParametersException("Sessione scaduta");
          }

          return Promise.reject(error);
        }
        else{
          let user: User = User.getInstance();
          user.init(response);

          User.LOGGED_STATUS = true;
          User.setKeepAliveTimer()

          return Promise.resolve(user);

        }
      },
      (error: Error) => {
    
        User.LOGGED_STATUS = false;
        User.resetKeepAliveTimer();

        if(error instanceof TokenExpireException){
          throw error;
        }
        if(error instanceof UnauthorizedException || error instanceof MissingParametersException){
          
          if(error instanceof UnauthorizedException){
            User.flushJWT()
          }

          ["auto", "moto"].forEach(vehicle => User.resetStepData(vehicle));
        }
        throw new Error("");
      }
    )
  }

  /**
   * Login by email and password, doesn't set anithing itsself
   * the JWT cookie will be automatically set in case of success by UserService 
   * @param email email of user 
   * @param password passwor of user 
   * @param vehicle type of vehicle "auto" "moto"
   * @param errorCallback callback in case of failure, should handle PasswordException and AccountNotActivatedException error
   */
  public static login(
    email: string,
    password: string
  ): Promise<User>{

    return UserService.call(
      WEB_SITE_API_SERVER_URL('/users/login'),
      {
        method: "post",
        body: JSON.stringify({
          email: email,
          password: password
        })
      },
      (response: any) => {
        if(response?.success === false){
          // caso di errore applicativo
          let error: Error;
          if(response.error_code === "LOGIN_FAILED" || response.error_code === "VALIDATION_ERROR"){
            error = new PasswordException("Password o email errata");
          }
          // 406 account non attivato con magic link
          else if(response.error_code === "USER_NOT_ACTIVATED"){
            error = new AccountNotActivatedException("Prima di procedere controlla la tua email e attiva il tuo account");
          }
          else{
            error = new GenericException("Errore generico");
          }
          return Promise.reject(error);
        }
        else{
          let user: User = User.getInstance();
          user.init(response);
          User.LOGGED_STATUS = true;
          User.setKeepAliveTimer()

          return Promise.resolve(user);

        }
      }
    );
  }

  /** Logout, delete JWT cookie
   * @param vehicle type of vehicle "auto" "moto"
   */
  public static logout(vehicle?: string){
    
    User.getInstance().setInitialState();
    User.resetKeepAliveTimer();
    User.flushJWT();

  }


  public static flushJWT(callback?: Function){
    CookieService.deleteCookie({
      name: 'jwt',
      path: "/",
      domain: `.${Constants.COOKIE_DEFAULT_DOMAIN}`,
    });

    callback?.();
  }
  
  /**
   * reset the stored data of quotation journey of specific type of vehicle "auto" "moto"
   * @param vehicle 
   */
  public static resetStepData(vehicle: string){

    if(!User.store){
      User.store = PersistedStore.getDefaultStore().store;
    }

    User.store.dispatch(step1DataReset(vehicle));
    User.store.dispatch(step2DataReset(vehicle));
    User.store.dispatch(step3DataReset(vehicle));
  }

  public isEqual(u: any | undefined): boolean{
    return u instanceof User && JSON.stringify(this) === JSON.stringify(u)
  }

  public static emailChecker(candidateEmail: string, callback?: Function): Promise<{emailValid: boolean; emailRegistered?: boolean}>{

    
    if(candidateEmail === ""){
      let emailValidation : {
        emailValid: boolean;
        emailRegistered?: boolean;
      } = {
        emailValid: false
      };
      callback?.(emailValidation);
      return Promise.resolve(emailValidation)
    }
    else{
      return UserService.call(Constants.WEB_SITE_API_SERVER_URL("/utils/misc/validate"), {
        method: "post",
        body: JSON.stringify({
          email: candidateEmail ?? "",
          check_user_exists: true
        })
      })
        .then((result: {
          email: {
            email: string;
            user_exists: boolean;
            valid: boolean;
            valid_dns: boolean;
            valid_syntax: boolean
          }
        }) => {

          let emailValidation : {
            emailValid: boolean;
            emailRegistered?: boolean;
          } = {
            emailValid: result?.email.valid ?? false,
            emailRegistered: result?.email.user_exists ?? false
          };
          

          return emailValidation;
          // callback?.(emailValidation);
        })
        
    }
  }

}

export type sortPeopleFunction = (p1: IPerson, p2: IPerson) => number
export type sortVehicleFunction = (v1: IVehicle, v2: IVehicle) => number