import { domain_from_url } from "./methods";

export const STORAGE_VERSION = 4.0;

export const ENV_SERVER_URL: any = () => {
    switch(process.env.REACT_APP_ENV) {
        case 'standalone':
            return ['http://localhost:3000', 'http://locale.6sicuro.it:8087', 'http://locale.6sicuro.it:8087/preventivi'];
        case 'remoto':
            return ['http://localhost:3000', 'https://staging.6sicuro.it', 'https://static.6sicuro-cdn.it/static/seisicurotest'];
        case 'brainpull':
            return ['http://6sicuro.lan:3000', 'https://staging.6sicuro.it', 'https://static.6sicuro-cdn.it/static/seisicurotest'];
        case 'brainpull_on_80':
            return ['http://6sicuro.lan', 'https://staging.6sicuro.it', 'https://static.6sicuro-cdn.it/static/seisicurotest'];
        case 'sviluppo':
            return ['http://locale.6sicuro.it:8087', 'http://locale.6sicuro.it:8087', 'https://static.6sicuro-cdn.it/static/seisicurotest'];
        case 'stagingTest':
            return ['https://staging.6sicuro.it', 'https://staging.6sicuro.it', 'https://static.6sicuro-cdn.it/static/seisicurotest'];
        case 'stagingDbTest':
            return ['https://stagingtestdb.6sicuro.it', 'https://stagingtestdb.6sicuro.it', 'https://static.6sicuro-cdn.it/static/stagingtestdb'];
        case 'stagingCompanies':
            return ['https://stagingcompanies.6sicuro.it', 'https://stagingcompanies.6sicuro.it', 'https://static.6sicuro-cdn.it/static/stagingcompagnie'];
        case 'produzione':
            return ['https://www.6sicuro.it', 'https://www.6sicuro.it', 'https://static.6sicuro-cdn.it/static/produzione/6sicuro'];
        default:
            return ['http://6sicuro.lan', 'https://staging.6sicuro.it', 'http://6sicuro.lan/bp_static'];
    }
}
export const API_SERVER_URL: any = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'standalone':
        case 'remoto':
            //return 'http://localhost:8096';
        case 'sviluppo':
        case 'stagingTest':
        case 'stagingDbTest':
        case 'stagingCompanies':
            return 'https://stagingapi.6sicuro.it';
        case 'produzione':
            return 'https://api.6sicuro.it';
        default:
            return 'https://stagingapi.6sicuro.it';
    }
}

export const CAPTCHA_VALIDATION_URL = `${API_SERVER_URL()}/api/captcha/validate`

export const WEB_SITE_API_SERVER_URL: any = (endpoint : string) => {
    let url = "";
    switch (process.env.REACT_APP_ENV) {
        case 'brainpull':
            url = `https://staginguserservice.6sicuro.it/api/v1`;
            break;
        case 'brainpull_on_80':
            url = 'https://staginguserservice.6sicuro.it/api/v1';
            break;
        case 'produzione':
            url = 'https://userservice.6sicuro.it/api/v1';
            break;
        default:
            url = 'https://staginguserservice.6sicuro.it/api/v1';
            break;
    }
    return `${url}${endpoint}`
}
export const BASE_STEP_URL = ENV_SERVER_URL()[0];
export const SEISICURO_SERVER_URL = ENV_SERVER_URL()[1];
export const SEISICURO_STATIC_CONTENT_URL = `${ENV_SERVER_URL()[2]}`;
export const SEISICURO_STATIC_CONTENT_URL_WITH_FOLDER = `${ENV_SERVER_URL()[2]}/contenuti_statici_new`;

export const STEP1_AUTO_URL_POSTFIX = "assicurazione-auto";
export const STEP1_MOTO_URL_POSTFIX = "assicurazione-moto";
export const STEP2_URL_POSTFIX = "dati-veicolo";
export const STEP3_URL_POSTFIX = "opzioni";
export const STEP4_URL_POSTFIX = "quotazioni";
export const STEP5_URL_POSTFIX = "salvataggio-in-corso";
export const STEP5_ERROR_URL_POSTFIX = "salvataggio-in-errore";
export const NOTE_INFORMATIVE = "note-informative";
export const SEISICURO_INSURANCE_COMPANY_LOGO_URL = (imageFile: string) => `${ENV_SERVER_URL()[2]}/img/loghi/${imageFile}`;

export const SEISICURO_CONTACT_URL = `${SEISICURO_SERVER_URL}/preventivi/contenutiStatici/6sicuro/6sicuro.vcf`;
export const SEISICURO_STEP_URL = (step: number, vehicle: string, edit: boolean = false) => {
    switch(step){
        case -1:
            return `${BASE_STEP_URL}/preventivi/assicurazione-${vehicle}/${NOTE_INFORMATIVE}`;
        case 1:
            if(edit){
                return `${BASE_STEP_URL}/preventivi/assicurazione-${vehicle}?edit=1`;
            }
            else{
                return `${BASE_STEP_URL}/preventivi/assicurazione-${vehicle}`;
            }
        case 2:
            return `${BASE_STEP_URL}/preventivi/assicurazione-${vehicle}/${STEP2_URL_POSTFIX}`;
        case 3:
            return `${BASE_STEP_URL}/preventivi/assicurazione-${vehicle}/${STEP3_URL_POSTFIX}`;
        case 4:
            return `${BASE_STEP_URL}/preventivi/assicurazione-${vehicle}/${STEP4_URL_POSTFIX}`;
        default:
            return `${BASE_STEP_URL}/preventivi/assicurazione-${vehicle}`;
    }
};
export const SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL = (step: number, vehicle: string, edit: boolean = false) => {
    return SEISICURO_STEP_URL(step, vehicle, edit).substring(BASE_STEP_URL.length)
}

export const SEISICURO_STEP_LOADER_URL = (step: number, vehicle: string) => `${BASE_STEP_URL}/preventivi/assicurazione-${vehicle}/${STEP4_URL_POSTFIX}`;
export const SEISICURO_AJAX_CITY_OF_BIRTH_ENDPOINT = (request: string, type: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=comuni&term=${request}&tipo=${type}`;
export const SEISICURO_AJAX_COUNTRY_OF_BIRTH_ENDPOINT = (request: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=stati&term=${request}&tipo=`;
export const SEISICURO_AJAX_CIVIL_STATE_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=statoCivile`;
export const SEISICURO_AJAX_DEGREE_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=titoloDiStudio`;
export const SEISICURO_AJAX_PROFESSION_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=professione`;
export const SEISICURO_AJAX_CAR_BRAND_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=marcheAuto`;
export const SEISICURO_AJAX_TOPONIMI_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=toponimi`;
export const SEISICURO_AJAX_MOTO_BRAND_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=marcaMoto&term=`;
export const SEISICURO_AJAX_CAR_MODEL_ENDPOINT = (registrationYear: number, registrationMonth: number, brandId: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=modello&annoimm=${registrationYear}&term=${brandId}&meseimm=${registrationMonth}`;
export const SEISICURO_AJAX_MOTO_MODEL_ENDPOINT = (registrationYear: number, registrationMonth: number, brandId: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=modelloMoto&annoimm=${registrationYear}&term=${brandId}&meseimm=${registrationMonth}`;
export const SEISICURO_AJAX_CAR_FUEL_TYPE_ENDPOINT = (registrationYear: number, registrationMonth: number, brandId: string, modelId: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=alimentazione&marcaId=${brandId}&annoimm=${registrationYear}&term=${modelId}&meseimm=${registrationMonth}`;
export const SEISICURO_AJAX_CAR_ENGINE_DISPLACEMENT_ENDPOINT = (registrationYear: number, registrationMonth: number, brandId: string, modelId: string, fuelType: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=cilindrata&marcaId=${brandId}&annoimm=${registrationYear}&term=${modelId}&alimentazioneId=${fuelType}&meseimm=${registrationMonth}`;
export const SEISICURO_AJAX_CAR_FITTING_ENDPOINT = (registrationYear: number, registrationMonth: number, brandId: string, modelId: string, fuelType: string, displacement: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=allestimento&cilindrataId=${displacement}&marcaId=${brandId}&annoimm=${registrationYear}&term=${modelId}&alimentazioneId=${fuelType}&meseimm=${registrationMonth}`;
export const SEISICURO_AJAX_MOTO_FITTING_ENDPOINT = (registrationYear: number, registrationMonth: number, brandId: string, modelId: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=allestimentoMoto&marcaId=${brandId}&annoimm=${registrationYear}&term=${modelId}&meseimm=${registrationMonth}`;
export const SEISICURO_AJAX_ANTITHEFT_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=antifurto`;
export const SEISICURO_AJAX_VEHICLE_USE_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=utilizzoTipico`;
export const SEISICURO_AJAX_PARKING_LOCATION_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=parcheggioNotturno`;
export const SEISICURO_FORM_POST_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/preventivatore-new/form`;
export const SEISICURO_FORM_POST_QUOTAZIONI_ENDPOINT = (vehicle: string) => `${SEISICURO_SERVER_URL}/preventivi/assicurazione-${vehicle}/quotazioni`;
export const SEISICURO_AJAX_POSTAL_CODE_ENDPOINT = (cityId: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=cap&term=${cityId}`;
export const SEISICURO_AJAX_PAYMENT_OPTIONS_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=opzioniPagamento`;
export const SEISICURO_AJAX_INSURANCE_STATE_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=statoAssicurativo`;
export const SEISICURO_AJAX_INSURANCE_CLASS_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=classeDiMerito`;
export const SEISICURO_AJAX_INSURANCE_CLASS_AGE_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=anzianitaPrimaClasse`;
export const SEISICURO_AJAX_INSURANCE_COMPANY_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=compagniaAssicurazioneAttuale`;
export const SEISICURO_AJAX_INSURANCE_AGE_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=etaAssicurativa`;
export const SEISICURO_AJAX_INSURANCE_BERSANI_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=bersaniApplicabilita`;
export const SEISICURO_AJAX_INSURANCE_BERSANI_CLASS_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=classeDiMeritoBersani`;
export const SEISICURO_AJAX_INSURANCE_PEOPLE_COINCIDE_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=figureStessaPersona`;
export const SEISICURO_AJAX_INSURANCE_YOUNGEST_DRIVER_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=etaConducentePiuGiovaneAuto`;
export const SEISICURO_AJAX_INSURANCE_YOUNGEST_FAMILY_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=etaResidentePiuGiovane`;
export const SEISICURO_AJAX_CAR_LICENSE_TYPE_ENDPOINT = (vehicleType: string) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=tipoPatente${vehicleType === "auto" ? "Auto" : "Moto"}`;
export const SEISICURO_AJAX_LICENSE_POINTS_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=puntiPatente`;
export const SEISICURO_AJAX_DRUNK_DRIVING_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=multeEbbrezza`;
export const SEISICURO_AJAX_LICENSE_SUSPENSIONS_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=numeroSospensioniPatente`;
export const SEISICURO_AJAX_LICENSE_SUSPENSION_MONTHS_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=mesiSospensioniPatente`;
export const SEISICURO_AJAX_ACCIDENT_YEAR_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=annoIncidente`;
export const SEISICURO_AJAX_ACCIDENT_RESPONSIBILITY_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=responsabilitaIncidente`;
export const SEISICURO_GET_PRIVACY_EMAIL = (email: any) => `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=mail&term=${email}`;
export const SEISICURO_AJAX_HIGHEST_DISPLACEMENT_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=cilindrataMassima`;
export const SEISICURO_AJAX_CONSECUTIVE_DRIVING_MONTHS_ENDPOINT = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=mesiGuidaConsecutiva`;
export const SEISICURO_AJAX_REDUX_STATE_ENDPOINT = (alfa: number, beta: number, delta?: number | undefined, redirectToStep1?: string) => (delta !== undefined)
    ? `${SEISICURO_SERVER_URL}/preventivi/preventivi-json?epsilon=${delta}&alfa=${alfa}&beta=${beta}&convenzioneId=1&step1=${redirectToStep1}`
    : `${SEISICURO_SERVER_URL}/preventivi/preventivi-json?alfa=${alfa}&beta=${beta}&convenzioneId=1&step1=${redirectToStep1}`;

export const SEISICURO_AJAX_ADDRESS_VALIDATION = (query: any) => `${API_SERVER_URL()}/place/autocomplete?${query}&useGcp=false`; // `http://localhost:8081/place/autocomplete?${query}&useGcp=false`; //
export const SEISICURO_AJAX_ADDRESS_DETAILS = (query: any) => `${API_SERVER_URL()}/place/detail?${query}&useGcp=false`; // `http://localhost:8081/place/detail?${query}&useGcp=false`; //  

export const SEISICURO_AJAX_QUOTATION_SAVE_ENDPOINT = (vehicle: string, plate: string, company_id: string, warranties: string, warrantiesUpselling: string, warrantiesIncluded: string, prevId: string, calcoloId: string, idPacchetto: string, timestamp: number) => `${SEISICURO_SERVER_URL}/preventivi/mobile/6sicuro/${vehicle}/salvaPreventivoCompagnia?plate=${plate}&company_id=${company_id}&prodotto=${vehicle === "auto" ? 1 : 2}&warranties=${warranties}&warrantiesUpselling=${warrantiesUpselling}&warrantiesIncluded=${warrantiesIncluded}&previd=${prevId}&calcoloId=${calcoloId}&idPacchetto=${idPacchetto}&requote=false&convenzione=000000001&_=${timestamp}`;
export const SEISICURO_AJAX_QUOTATION_SAVE_ERROR_ENDPOINT = (vehicle: string, company_id: string, prevId: string) => `${ENV_SERVER_URL()[0]}/preventivi/assicurazione-${vehicle}/salvataggio-in-errore?cid=${company_id}&pid=${prevId}`;
export const SEISICURO_GET_PREV_ID = (timestamp: number, vehicle: string, alreadyQuoted: boolean) => `${SEISICURO_SERVER_URL}/preventivi/desktop/6sicuro/${vehicle}/previd?alreadyQuoted=${alreadyQuoted}&_=${timestamp}`;
export const SEISICURO_GET_QUOTE_INFORMATION = (warranties: string, primavolta: boolean, previd: string, calcoloId: string, prodotto: string, ups: boolean, hideBlackBox: boolean, timestamp: number) => 
    `${SEISICURO_SERVER_URL}/preventivi/QuotServlet?warranties=${warranties}&primavolta=${primavolta}&previd=${previd}&calcoloId=${calcoloId}&prodotto=${prodotto}&ups=${ups}&hideBlackBox=${hideBlackBox}&_=${timestamp}`;
export const SEISICURO_GET_ADDITIONALQUOTE_INFORMATION = (warranties: string, primavolta: boolean, previd: string, calcoloId: string, prodotto: string, ups: boolean, hideBlackBox: boolean, timestamp: number) => `${SEISICURO_SERVER_URL}/preventivi/QuotServlet?warranties=${warranties}&primavolta=${primavolta}&previd=${previd}&calcoloId=${calcoloId}&prodotto=${prodotto}&ups=${ups}&hideBlackBox=${hideBlackBox}&_=${timestamp}&reactMaxChiamate=true`;

export const SEISICURO_GET_UPSELLING = (vehicle: string, licensePlate: string, companyId: string, price: number, warranties: string, warrantiesIncluded: string, prevId: string, calcoloId: string, requote: boolean, timestamp: number) => `${SEISICURO_SERVER_URL}/preventivi/mobile/6sicuro/${vehicle}/upselling?plate=${licensePlate}&company_id=${companyId}&price=${price}&warranties=${warranties}&warrantiesIncluded=${warrantiesIncluded}&prodotto=${vehicle === "auto" ? 1 : 2}&previd=${prevId}&calcoloId=${calcoloId}&requote=${requote}&livello=2&_=${timestamp}`;

export const SEISICURO_POST_TARGA_AGGIORANTA = (vehicle: string, targa: string, prvid: number) => `${SEISICURO_SERVER_URL}/preventivi/mobile/6sicuro/${vehicle}/aggiornaTarga?targa=${targa}&prvid=${prvid}`;

export const SEISICURO_FETCF_DATI_4RUOTE = () => `${API_SERVER_URL()}/api/infocarAuto/`; // 'http://localhost:8096/api/infocarAuto/'
export const SEISICURO_FETCF_DATI_VEICOLO = (mese: string, anno: string, codall: string) => `${API_SERVER_URL()}/api/infocarAuto/additionalInfo/${mese}/${anno}/${codall}`;  // `http://localhost:8096/api/infocarAuto/additionalInfo/${anno}/${mese}/${codall}`
export const SEISICURO_FETCF_DATI_4RUOTE_MOTO = () => `${API_SERVER_URL()}/api/infocarMoto/`; // 'http://localhost:8096/api/infocarMoto/';
export const SEISICURO_FETCF_DATI_VEICOLO_MOTO = (mese: string, anno: string, codall: string) => `${API_SERVER_URL()}/api/infocarMoto/additionalInfo/${mese}/${anno}/${codall}`;  // `http://localhost:8096/api/infocarMoto/additionalInfo/${anno}/${mese}/${codall}`;

export const SEISICURO_VALIDATION_ENDPOINT = (type: string) => `${API_SERVER_URL()}/api/validation/${type}`;
export const SEISICURO_VALIDATION_PHONE = `${SEISICURO_SERVER_URL}/preventivi/mobile/6sicuro/auto/field/contraente+numeroCellulare`;
export const SEISICURO_LOGIN_ENDPOINT = (type: string) => `${API_SERVER_URL()}/api/users/${type}`;

export const SEISICURO_NOTE = `${SEISICURO_SERVER_URL}/preventivi/AjaxController?op=noteInformative`;
export const MAX_STEPS_NUMBER = 4;
export const DOB_YEAR_RANGE_MAX = 100;
export const DOB_YEAR_RANGE_MIN = 18;
export const CALENDAR_DATE_FORMAT = "dd/mm/yy";
export const DROPDOWN_EMPTY_MESSAGE = "Nessun elemento trovato";
export const VEHICLE_REGISTRATION_YEAR_MAX = 30;
export const MINOR_MAX_AGE = 17;
export const MONTHS_LIST_IT = [{label: "Gennaio", value: 1}, {label: "Febbraio", value: 2}, {label: "Marzo", value: 3}, {label: "Aprile", value: 4}, {label: "Maggio", value: 5}, {label: "Giugno", value: 6}, {label: "Luglio", value: 7}, {label: "Agosto", value: 8}, {label: "Settembre", value: 9}, {label: "Ottobre", value: 10}, {label: "Novembre", value: 11}, {label: "Dicembre", value: 12},]
export const UNIX_TIMESTAMP_START_YEAR = 1970;
export const LICENSE_MIN_AGE_AUTO = 18;
export const LICENSE_MIN_AGE_MOTO = 16;
export const MAX_ACCIDENTS = 15;
export const QUOTATION_TOTAL_LABEL = "Totale";
export const REQUEST_STATE_EMAIL_IN_PROGRESS = "in progress";
export const REQUEST_STATE_EMAIL_DONE = "done";
export const SEISICURO_FALLBACK_IMAGE = `${SEISICURO_STATIC_CONTENT_URL}/img/placeholder.png`;
export const PREFIX_ADDRESS = ['via', 'viale', 'corso', 'piazza', 'strada', 'contrada', 'località', 'vicolo', 'largo', 'vico', 'frazione', 'piazzale', 'traversa', 'salita', 'quartiere', 'ca\'', 'piazzetta', 'valico', 'rione', 'circonvallazione', 'statale']; 
export const TRACKING_COOKIE_NAME = '6sicuro_cookie_notice_accepted';
export const ENABLE_REDIRECT = true;
export const minRequestMs = 6000;
export const STORAGE_INFO_KEY = "storageInfo";
export const SEISICURO_STEP_CHECK = (step: number) => `${SEISICURO_SERVER_URL}/preventivi/check-step${step}`;
export const MISSING_FIELD_TEXT = "Campo obbligatorio";


export const COOKIE_DEFAULT_DOMAIN = domain_from_url(BASE_STEP_URL);

export const AUTH_CLIENT_ID = "test1";
export const AUTH_CLIENT_SECRET = "secret1";

export const ADDITIONAL_GUARANTEES_AUTO = [
    { name: 'RCA', key: 'garanzia-1', value: '1', title: '', description: 'La polizza che copre i danni causati a terzi da parte del veicolo assicurato: in base all\'art. 22 del Codice delle Assicurazioni.' },
    { name: 'Rinuncia alla rivalsa', key: 'garanzia-14', value: '14', title: 'Zero rivalsa per ebbrezza', description: "La rinuncia alla rivalsa per guida in stato di ebbrezza annulla l''azione della compagnia se commetti un incidente in stato di ebbrezza ai sensi dell''art. 186 n. 7 del Codice della Strada. Da sapere: Verifica le condizioni delle singole compagnie assicurative per conoscere i limiti del tasso alcolemico da non superare." },
    { name: 'Bonus Protetto', key: 'garanzia-15', value: '15', title: 'Bonus protetto', description: "La garanzia Bonus protetto annulla l'evoluzione in malus della classe di merito della compagnia nel caso di sinistri pagati con responsabilità principale o per comulo di responsabilità. Da sapere: Consulta le condizioni delle singole compagnie assicurative per conoscere le regole evolutive della classe di merito." },
    { name: 'Assistenza stradale', key: 'garanzia-7', value: '7', title: 'Assistenza stradale', description: "Garantisce, in caso di sinistro o guasto, piccoli interventi di riparazione o il soccorso stradale del veicolo. Da sapere:  ciascuna compagnia offre soluzioni differenti, ma di norma tutte mettono a disposizione l'assistenza stradale 24 ore su 24, 7 giorni a settimana, con un numero verde dedicato e a una rete di officine convenzionate." },
    { name: 'Infortuni del conducente', key: 'garanzia-6', value: '6', title: 'Infortuni del conducente', description: "Risarcisce il conducente del veicolo, l'unica persona non assicurata dall'RCA, in caso di incidente con colpa. Da sapere: sono previste penali in caso di comportamenti pericolosi, come la guida in stato di ebbrezza o senza cintura di sicurezza. In caso di morte dell'assicurato, saranno gli eredi a beneficiare del risarcimento." },
    { name: 'Tutela legale', key: 'garanzia-8', value: '8', title: 'Tutela legale', description: "Risarcisce le spese legali che si rendano necessarie in conseguenza ad un sinistro. Da sapere: prima di poter fare causa alla controparte, devi sempre ottenere l'autorizzazione dalla tua assicurazione!." },
    { name: 'Furto e incendio', key: 'garanzia-2', value: '2', title: 'Furto e incendio', description: "Risarcisce in caso di furto del veicolo, danni a seguito del tentativo dello stesso e in caso di incendio, rapina, esplosione o azione da fulmine. Da sapere:  le garanzie furto e incendio, nelle modalità descritte, non coprono gli oggetti all'interno del veicolo. In caso di danneggiamento del veicolo, ti verranno liquidate solo le parti danneggiate." },
    { name: 'Eventi naturali', key: 'garanzia-10', value: '10', title: 'Eventi naturali', description: "Risarcisce i danni subiti dall'auto causati da un evento naturale (es. grandine). Da sapere: La garanzia eventi naturali non copre l'incendio. Se desideri anche questo tipo di copertura, devi stipulare la garanzia 'Furto e incendio'." },
    { name: 'Eventi sociopolitici', key: 'garanzia-9', value: '9', title: 'Eventi sociopolitici', description: "Risarcisce i danni al tuo veicolo subiti in seguito a manifestazioni, atti terroristici o, più in generale, per vandalismi da parte d'ignoti. Da sapere: per avere diritto al risarcimento devi presentare alla tua assicurazione la denuncia contro ignoti con l'elenco dei danni subiti." },
    { name: 'Cristalli', key: 'garanzia-5', value: '5', title: 'Cristalli', description: "Risarcisce per la rottura dei cristalli del veicolo da persone o imprevisti. Da sapere: la garanzia cristalli copre sostituzione e riparazione del parabrezza, del lunotto posteriore e dei finestrini in caso di rottura; i danni minori come rigature o scheggiature di norma sono esclusi dalla copertura." },
    { name: 'Kasko', key: 'garanzia-3', value: '3', title: 'Kasko', description: "Risarcisce per i danni al tuo veicolo in caso di sinistro con colpa con altri veicoli, urto, ribaltamento o uscita di strada. Da sapere: puoi allargare la copertura ad altri eventi come sommosse, cariche della polizia e atti vandalici; ovviamente, se scegli una protezione maggiore dovrai corrispondere un premio più alto." },
    { name: 'Collisione', key: 'garanzia-4', value: '4', title: 'Collisione', description: "Risarcisce per i danni al tuo veicolo in caso di sinistro con colpa con altri veicoli. Da sapere: per avere diritto al risarcimento devi presentare alla tua assicurazione la denuncia del sinistro con gli estremi di tutti i guidatori e delle vetture coinvolte nell'incidente." },
];
export const ADDITIONAL_GUARANTEES_MOTO = [
    { name: 'RCA', key: 'garanzia-1', value: '1', title: '', description: 'La polizza che copre i danni causati a terzi da parte del veicolo assicurato: in base all\'art. 22 del Codice delle Assicurazioni.' },
    { name: 'Rinuncia alla rivalsa', key: 'garanzia-14', value: '14', title: 'Zero rivalsa per ebbrezza', description: "La rinuncia alla rivalsa per guida in stato di ebbrezza annulla l''azione della compagnia se commetti un incidente in stato di ebbrezza ai sensi dell''art. 186 n. 7 del Codice della Strada. Da sapere: Verifica le condizioni delle singole compagnie assicurative per conoscere i limiti del tasso alcolemico da non superare." },
    { name: 'Bonus Protetto', key: 'garanzia-15', value: '15', title: 'Bonus protetto', description: "La garanzia Bonus protetto annulla l'evoluzione in malus della classe di merito della compagnia nel caso di sinistri pagati con responsabilità principale o per comulo di responsabilità. Da sapere: Consulta le condizioni delle singole compagnie assicurative per conoscere le regole evolutive della classe di merito." },
    { name: 'Assistenza stradale', key: 'garanzia-7', value: '7', title: 'Assistenza stradale', description: "Garantisce, in caso di sinistro o guasto, piccoli interventi di riparazione o il soccorso stradale del veicolo. Da sapere:  ciascuna compagnia offre soluzioni differenti, ma di norma tutte mettono a disposizione l'assistenza stradale 24 ore su 24, 7 giorni a settimana, con un numero verde dedicato e a una rete di officine convenzionate." },
    { name: 'Infortuni del conducente', key: 'garanzia-6', value: '6', title: 'Infortuni del conducente', description: "Risarcisce il conducente del veicolo, l'unica persona non assicurata dall'RCA, in caso di incidente con colpa. Da sapere: sono previste penali in caso di comportamenti pericolosi, come la guida in stato di ebbrezza o senza cintura di sicurezza. In caso di morte dell'assicurato, saranno gli eredi a beneficiare del risarcimento." },
    { name: 'Tutela legale', key: 'garanzia-8', value: '8', title: 'Tutela legale', description: "Risarcisce le spese legali che si rendano necessarie in conseguenza ad un sinistro. Da sapere: prima di poter fare causa alla controparte, devi sempre ottenere l'autorizzazione dalla tua assicurazione!." },
    { name: 'Furto e incendio', key: 'garanzia-2', value: '2', title: 'Furto e incendio', description: "Risarcisce in caso di furto del veicolo, danni a seguito del tentativo dello stesso e in caso di incendio, rapina, esplosione o azione da fulmine. Da sapere:  le garanzie furto e incendio, nelle modalità descritte, non coprono gli oggetti all'interno del veicolo. In caso di danneggiamento del veicolo, ti verranno liquidate solo le parti danneggiate." },
    { name: 'Sospendibilità', key: 'garanzia-11', value: '11', title: 'Sospendibilità', description: "Se durante il corso dell'anno preferisci sospendere la tua polizza moto perché non utilizzi il mezzo, selezionando questa clausola hai la possibilità di 'congelare' la tua assicurazione fino a quando non deciderai di riattivarla." },
];

// settare a false per nascondere la possibilità di visualizzare quotazioni con flag NOCARD
export const ENABLE_NO_CARD = false;