
import { AllHtmlEntities } from 'html-entities';
import { Chip } from 'primereact/chip';
import striptags from 'striptags';

interface IShortInfo{
  field: string;
  value: string;
}


const ShortInfoRow = (props : {item: IShortInfo, rowKey: number}) => {

  const {item: info, rowKey} = props;
  let row = null;

  switch(info.field){
    case "Prodotto":
      row = <td><strong>{info.value}</strong></td>;
      break;
    case "Tipo guida":
      row = <td><strong>Guida {info.value.toLowerCase()}</strong></td>;
      break;
    default:
      row = <td><strong>{info.field}</strong>: <span>{info.value}</span></td>
      break;
  }

  return <tr key={rowKey}>{ row }</tr>
}

const QuotationColumnInfo = (props: {company: any}) => {

  const {company} = props;
  let refinedPair: IShortInfo[] = [];
  let include : string[] = [];

  for(const row of company.short_info.split("<br/>")){
    const refinedItem = row.replace(/(<([^>]+)>)/gi, "");
    const [field, value] = refinedItem.split(":");
    if((field ?? "") !== "" && (value ?? "") !== ""){
      if(field === "Include"){
        include.push(value);
      }
      else{
        refinedPair.push({field: field, value: value})
      }
    }
  }

  if(include.length > 0){
    refinedPair.push({field: "Include", value: include.join(', ')})
  }
  
  return (
    <div className="short-info-container">
      { refinedPair.length > 0
          ? <table className="infoTable">
            <tbody>
              {refinedPair
                .map((info : IShortInfo, index: number) => <ShortInfoRow item={info} key={index} rowKey={index}/>)}
            </tbody>
          </table>
          : <></>
      }
      <Chip className="plusInfo" label={AllHtmlEntities.decode(striptags(company.promo, [], ' '))} />
      {
        company?.info_rateizzazione?.emissione_diretta?.valore === "1" &&
          <Chip className="plusInfo" label="Emissione immediata" />
      }
      {
        company?.info_rateizzazione?.rateizzazione?.numero && company.info_rateizzazione.rateizzazione.numero !== 1 && company.info_rateizzazione.rateizzazione.numero !== "1" &&
          <Chip className="plusInfo" label={`Rateizzabile in ${company.info_rateizzazione.rateizzazione.numero} rate`} />
      }
    </div>
  )
}

export default QuotationColumnInfo;