import { Component } from 'react';
import { Checkbox, } from 'primereact/checkbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ToggleButton } from 'primereact/togglebutton';
import '../scss/privacy-row.scss';

/*export interface IPrivacyRowProps{
  title: string;
  text: JSX.Element;
  more?: JSX.Element;
  labelMore?: string;
  labelLess?: string;
  touchpoints?: string[];
}*/

export class PrivacyRow extends Component<{
  rowid: string,
  consent?: boolean,
  consentMethods?: string[],
  title?: JSX.Element,
  text: JSX.Element,
  more?: JSX.Element,
  labelMore?: string,
  labelLess?: string,
  touchpoints?: string[],
  consentChangeable?: boolean,
  action?: Function,
  actionMethods?: Function,
  className?: string,
}, {touchpoints: any[], consent: boolean | undefined}>{

  constructor(props: any) {
    super(props);

    const touchpoint_list = [
      {id: "1", fieldName: "email", label: "E-mail", required: true, checked: true},
      {id: "2", fieldName: "phone", label: "Telefono", required: false, checked: false},
      {id: "3", fieldName: "whatsapp", label: "Whatsapp & Messenger", checked: false},
      {id: "4", fieldName: "sms", label: "SMS", required: false, checked: false},
    ]
    let real_touchpoints = new Array()
    for(let elem of touchpoint_list){
      if(props.touchpoints?.includes(elem.fieldName)){
        real_touchpoints.push(elem);
      }
    }

    this.state = {
      touchpoints: real_touchpoints,
      consent: this.props.consent,
    }

  }

  private changeMethod = (e: any) => {    
    for(let i = 0; i<this.state.touchpoints.length; i++){
      if(this.state.touchpoints[i].id == e.value){
        if(e.value != "1"){ // id=1 è la mail, non puè essere deselezionata
          this.state.touchpoints[i].checked = !this.state.touchpoints[i].checked;
          if(this.props.actionMethods !== undefined)
            this.props.actionMethods(this.state.touchpoints[i].fieldName);
          else
            console.log("not setted");
        }
        else{
          this.state.touchpoints[i].checked = true;
          console.log("forcing");
        }
      }
    }
    this.setState({touchpoints: this.state.touchpoints});
  }

  private changeConsent = (e: any) => {    
    //this.setState({consent: !this.state.consent})
    if(this.props.action !== undefined)
      this.props.action();
  }

  /*
  
    const mypromise = new Promise(
      (resolve, reject) => {
        resolve([
          {id: "1", label: "E-mail", required: true},
          {id: "2", label: "Telefono", required: false},
          {id: "3", label: "Whatsapp & Messenger", required: false},
          {id: "4", label: "SMS", required: false},
        ])
      }
    )
    .then(result => {
      this.setState({param: result})
    })
  
  */

  render() {

    return <div className={"privacyRow" + (((this.props.consentChangeable === false) && this.props.consent) ? " forcedOn " : " ") + (this.props?.className)}>
      <div className='d-flex justify-content-between'>
        {this.props?.title}
        <ToggleButton onChange={this.changeConsent} checked={this.props.consent} />
      </div>
      <div className="smallParagraph">
        {this.props.text}
      </div>

      {(() => {
        if(this.props.more !== undefined){
          return (<Accordion className="privacyAccordion mt-4">
            <AccordionTab header={ this.props.labelMore }>
                { this.props.more }
            </AccordionTab>
          </Accordion>)
        }
      })()}

      {(() => {
        if(
          (this.props.touchpoints !== undefined) &&
          (this.props.consent)
        )
        {

          return (<div><span className="smallTitle mt-4 mb-2 d-block">Metodo di contatto</span>
          <div className="methodList smallParagraph">        
              {
                this.state.touchpoints?.map(item => <div key={this.props.rowid+"_method_"+item.id} className={"singleMethod method_"+item.id}>
                  <Checkbox inputId={this.props.rowid+"_method_"+item.id} value={item.id} onChange={this.changeMethod} checked={this.props.consentMethods?.includes(item.fieldName)}/>
                  <label htmlFor={this.props.rowid+"_method_"+item.id}>{item.label}</label>
                </div>)
              }
          </div></div>)
        }
      })()}
    </div>
  }
}

export default PrivacyRow
