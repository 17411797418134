
import * as Constants from '../utils/constants';
import CookieService from './CookieService';

export class ServerError extends Error{

  data?: any | undefined;

  constructor(message : string = "Server Error", data?: any){
      super(message);
      this.data = data;
  }

}
export class UnauthorizedException extends Error{

  data?: any | undefined;

  constructor(message : string = "Utente non loggato", data?: any){
      super(message);
      this.data = data;
  }

}
export class TokenExpireException extends Error{

  data?: any | undefined;

  constructor(message : string = "Sessione scaduta", data?: any){
      super(message);
      this.data = data;
  }
}
export class MissingParametersException extends Error{

  data?: any | undefined;

  constructor(message : string = "Parametri mancanti", data?: any){
      super(message);
      this.data = data;
  }

}

export class GenericException extends Error{

  private data: {field: string, msg: string}

  constructor(msg: string, field: string = "generic"){
    super(msg);
    this.data = {
      field: field,
      msg: msg
    }
  }

  public getData(){
    return this.data;
  }
  
}

export class PasswordException extends Error{

  private data: {field: string, msg: string}

  constructor(msg: string, field: string = "generic"){
    super(msg);
    this.data = {
      field: field,
      msg: msg
    }
  }

  public getData(){
    return this.data;
  }
  
}

export class AccountAlreadyRegisteredException extends Error{

  private data: {field: string, msg: string}

  constructor(msg: string, field: string = "generic"){
    super(msg);
    this.data = {
      field: field,
      msg: msg
    }
  }

  public getData(){
    return this.data;
  }
  
}

export class AccountNotActivatedException extends Error{

  private data: {field: string, msg: string}

  constructor(msg: string, field: string = "generic"){
    super(msg);
    this.data = {
      field: field,
      msg: msg
    }
  }

  public getData(){
    return this.data;
  }
  
}

export class TokenExpiredException extends Error{

  private data: {field: string, msg: string}

  constructor(msg: string, field: string = "generic"){
    super(msg);
    this.data = {
      field: field,
      msg: msg
    }
  }

  public getData(){
    return this.data;
  }
  
}

export class AlreadyRegisterException extends Error{

  private data: {field: string, msg: string}

  constructor(msg: string, field: string = "generic"){
    super(msg);
    this.data = {
      field: field,
      msg: msg
    }
  }

  public getData(){
    return this.data;
  }
  
}

export class WrongPasswordException extends Error{

  private data: {field: string, msg: string}

  constructor(msg: string, field: string = "password"){
    super(msg);
    this.data = {
      field: field,
      msg: msg
    }
  }

  public getData(){
    return this.data;
  }
  
}


export class ValidationErrorException extends Error{

  private data: {field: string, msg: string}

  constructor(msg: string, field: string = "generic"){
    super(msg);
    this.data = {
      field: field,
      msg: msg
    }
  }

  public getData(){
    return this.data;
  }
  
}


export class DuplicateException extends Error{

  private data: {field: string, msg: string}

  constructor(msg: string, field: string = "generic"){
    super(msg);
    this.data = {
      field: field,
      msg: msg
    }
  }

  public getData(){
    return this.data;
  }
  
}

export default class UserService{

  static readonly expire : number = 365*24*3600*1000;  //1 anno

  public static call(endpoint: string, parameter?: any, resolve?: Function, reject?: Function){

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.append('Content-Type', 'application/json; charset="utf-8"');
    
    if(CookieService.getCookie('jwt')){
      requestHeaders.append('Authorization', `Bearer ${CookieService.getCookie('jwt') ?? ""}`);
    }
    
    for(const [h_name, h_value] of parameter?.headers?.entries() ?? []){
      if(requestHeaders.has(h_name)){
        requestHeaders.delete(h_name);
      }
      requestHeaders.append(h_name, h_value);
    };

    parameter !== undefined
      ? parameter.headers = requestHeaders
      : parameter = {headers: requestHeaders}

    return fetch(endpoint, parameter)
      .then(result => {
          if(result.headers.get("content-type") !== "application/json"){
            throw new Error("Server doesn't response");
          }

          let json : any = result.json();
          
          const token = result.headers.get("x-token") ? result.headers.get("x-token") : undefined;
          if(token){
            
            let expireTime : Date;
            expireTime = new Date();
            expireTime.setTime(expireTime.getTime() + UserService.expire);
            // if(result.headers.get("x-token-exp")){
            //   expireTime = new Date(result.headers.get("x-token-exp") as string);
            // }
            // else{
            //   expireTime = new Date();
            //   expireTime.setTime(expireTime.getTime() + UserService.expire);
            // }
           
            // fix per potersi loggare da localhost:3000 puntando al server remoto di staging
            if (Constants.COOKIE_DEFAULT_DOMAIN?.includes("localhost")) {
              CookieService.writeCookie({
                name: "jwt",
                value: token,
                domain: "localhost",
                path: "/preventivi",
                hostOnly: true,
                expires: expireTime,
                SameSite: "no_restriction"
              });
            }
            else {
              CookieService.writeCookie({
                name: "jwt",
                value: token,
                domain: `.${Constants.COOKIE_DEFAULT_DOMAIN}`,
                expires: expireTime
              });
            }

          }
          return json;

      })
      .then(responseJson => {

        // Token JWT scaduto
        if (responseJson?.error_code === "TOKEN_EXPIRED") {
          // forzo la cancellazione del cookie
          let param: any = {};
          if (Constants.COOKIE_DEFAULT_DOMAIN?.includes("localhost")) {
            param = {
              name: "jwt",
              domain: "localhost",
              path: "/preventivi"
            };
          }
          else{
            param = {
              name: "jwt",
              domain: `.${Constants.COOKIE_DEFAULT_DOMAIN}`,
              path: "/"
            };
          }
          CookieService.deleteCookie(param);
        }

        return resolve?.(responseJson) ?? responseJson;
      })
      .catch((err: Error) => {
        if(typeof reject === "function"){
          return reject?.(err)
        }
        else{
          throw err;
        }
      })

  }

}