import { Component, useContext } from "react";
import { Card } from "primereact/card";
import { SelectButton } from "primereact/selectbutton";
import * as Constants from "./utils/constants";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import {
  I4RuoteFitting,
  IFieldDescriptor,
  IVehicle,
  IVehicleFitting,
  Step1Data,
  Step2Data,
  /*Vehicle,*/
} from "./utils/common";
import { Slider } from "primereact/slider";
import {
  step2DataFailure,
  step2DataSuccess,
  userInfoDataSuccess,
} from "../redux/actions";
import {
  fetchList,
  postData/*, getProgressValue*/,
  isNullOrUndefined,
  fixDirtyBackendErrorString,
  formatDateAdform,
  sortJson,
  removeFocusClass,
  offsetFirstErrorField,
} from "./utils/methods";
// import { ProgressBar } from 'primereact/progressbar';
import CookieService from "./utils/CookieService";
import moment from "moment";
import User, { IValidationResponse } from "./utils/User";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import { VehicleFieldBuilder } from "./utils/VehicleFieldBuilder";
import { connect } from "react-redux";
import { StepDataDualState } from "../redux/reducers";
import { useHistory } from "react-router-dom";
import withEventManager from "./customs/HOC/withEventManager";
import { compose } from "ramda";
import { IWithEventManager } from "./customs/HOC/EventManager";
import { DataList, IDataList } from "./customs/dataListContext";

interface IStep2Props extends IWithEventManager, IDataList{
  step1Data: StepDataDualState;
  step2Data: StepDataDualState;
  userInfoDataSuccess: Function;
  step2DataSuccess: Function;
  step2DataFailure: Function;
  fromMailSereno: boolean;
  vehicle: string;
  history: ReturnType<typeof useHistory>;
  setResourceListRequest: Function;
}

interface Step2State {  
  vehicleBrandList?: any[];
  carModelList?: any[];
  fuelTypeList?: any[];
  engineDisplacementList?: any[];
  vehicleFittingList?: IVehicleFitting[];
  yearBuyList: any[];
  yearList: any[];
  plateLoading: boolean;
  loading: boolean; // flag di stato per il caricamento, diventa false quanto tutte le info asincrone sono state caricate
  errors: string[];
  errorTexts: IFeedbackText[];
  submitting: boolean;  // flag di stato per lo spinner del pulsante continua
}

interface IVehicleInfoRequest{
  vehicleRegistrationYear?: number;
  vehicleRegistrationMonth?: number;
  vehicleBrand?: string;
  vehicleModel?: string;
  vehicleFuelType?: string;
  vehicleEngineDisplacement?: string;
}

class Step2Component extends Component<IStep2Props, Step2State> {
  private vehicle: string;
  private monthList: any[] = [];

  private oldLicensePlateValue: any;
  private isCachedValue: boolean;

  private readonly vehicleFields : any;
  private abortController: AbortController = new AbortController();

  constructor(props: any) {
    super(props);
    this.vehicle = props.vehicle

    const v_k = this.vehicle as keyof StepDataDualState;

    this.vehicleFields = {
      step: {},
      vehicle: {
        auto: VehicleFieldBuilder("auto", false),
        moto: VehicleFieldBuilder("moto", false),
        none: VehicleFieldBuilder("", false)
      }
    };

    const vehicle: Step2Data = this.props.step2Data[v_k] as Step2Data;
    this.monthList = Constants.MONTHS_LIST_IT;

    let yearList: any[] = [];
    let yearBuyList: any[] = [];
    let currentYear = new Date().getFullYear();
    for (let i = 0; i <= Constants.VEHICLE_REGISTRATION_YEAR_MAX; i++) {
      yearList.push({
        label: (currentYear - i).toString(),
        value: currentYear - i,
      });
      if (vehicle?.vehicleRegistrationYear === undefined) {
        yearBuyList.push({
          label: (currentYear - i).toString(),
          value: currentYear - i,
        });
      }
    }

    if (vehicle?.vehicleRegistrationYear) {
      yearBuyList = [];
      for (let i = vehicle?.vehicleRegistrationYear; i <= currentYear; i++) {
        yearBuyList.push({ label: i.toString(), value: i });
      }
    }

    this.state = {
      plateLoading: false,
      errors: [],
      loading: false,
      submitting: false,      
      errorTexts: [],
      yearBuyList,
      yearList
    }

    this.isCachedValue = false;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.monthIndexToName = this.monthIndexToName.bind(this);
    this.trakSendLoaded = this.trakSendLoaded.bind(this);
    this.submitToNode = this.submitToNode.bind(this);
    this.getClearPlateDependentFields = this.getClearPlateDependentFields.bind(this);

  }

  componentDidMount() {

    let resourseRequestMask: any = {};
    if(!(this.props?.parkingLocationList?.length ?? false)){
      resourseRequestMask.parkingLocationList = true;
    }
    
    if(!(this.props?.vehicleUseList?.length ?? false)){
      resourseRequestMask.vehicleUseList = true;
    }
    
    if(!(this.props?.antitheftList?.length ?? false)){
      resourseRequestMask.antitheftList = true;
    }

    if(JSON.stringify(resourseRequestMask) != "{}"){
      this.props.setResourceListRequest(resourseRequestMask)
    }

    if (this.state.vehicleBrandList === undefined) {
      if (this.vehicle === "moto") {
        fetchList(
          Constants.SEISICURO_AJAX_MOTO_BRAND_ENDPOINT,
          (result: any) => {
            let sortedBrands = result.sort((a: any, b: any) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;
              return 0;
            });
            this.setState({ vehicleBrandList: sortedBrands });
          }
        );
      }
      else {
        fetchList(
          Constants.SEISICURO_AJAX_CAR_BRAND_ENDPOINT,
          (result: any) => {
            let sortedBrands = result.sort((a: any, b: any) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;
              return 0;
            });
            this.setState({ vehicleBrandList: sortedBrands });
          }
        );
      }
    }

    // fissa le varie tendine vuote legate ai veicoli!
    // senza quando un preventivo è incopleto.. al caricamento della pagina le tendne restno vuote! e per poter modficare il valore devo modficare la tendina sovrastante!
    this.fetchModels(false);
    this.fetchVehicleFittings(false);
    this.fetchFuelType(false);
    this.fetchEngineDisplacement(false);

    if(!(window as any)?.Cookiebot?.hasResponse){
      window.addEventListener("CookiebotOnAccept", this.trakSendLoaded)
      window.addEventListener("CookiebotOnDecline", this.trakSendLoaded)
    }
    else{
      this.trakSendLoaded();
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  render() {
    // const progress = getProgressValue();
    const vehicleData: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;

    return (
      <div id="step-2-form">
        {/* <div className="progress-bar-steps">
          <ProgressBar value={progress}></ProgressBar>
        </div> */}

        <Card className="pr-form">
          <div className="p-fluid p-4 p-lg-5">
            <h1 className="titleLev4 colorPrimaryBlue mb-4">Dati veicolo</h1>
            <div className="row">
              
              <FeedbackBox items={this.state.errorTexts}/>

              <div className="col-12 mt-4 mb-4">
                <label>Seleziona fra le seguenti opzioni</label>
                <SelectButton
                  value={vehicleData.vehicleOwned}
                  unselectable={false}
                  options={[
                    { label: "Già di proprietà", value: true },
                    { label: "Ancora da acquistare", value: false },
                  ]}
                  onChange={(selected) =>
                    this.onChangeHandler(selected.value, "vehicleOwned")
                  }
                />
                <ContextFeedback
                  show={this.state.errors.includes("vehicleOwned")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "vehicleOwned") ??
                    {msg: "Campo obbligatorio", severity: "error"}
                  }
                />
              </div>
              {this.isVisible("knowLicensePlate") && (
                <div className="col-12 mb-4">
                  <SelectButton
                    value={vehicleData.knowLicensePlate}
                    unselectable={false}
                    options={[
                      { label: "Conosco la targa", value: true },
                      { label: "Non conosco la targa", value: false },
                    ]}
                    onChange={(selected) =>
                      this.onChangeHandler(selected.value, "knowLicensePlate")
                    }
                  />
                  <ContextFeedback
                    show={this.state.errors.includes("knowLicensePlate")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "knowLicensePlate") ??
                      {msg: "Campo obbligatorio", severity: "error"}
                    }
                  />
                </div>
              )}
              {this.isVisible("vehicleLicensePlateNumber") && (
                <div className="col-12 mb-4">
                  <label htmlFor="targa">
                    Targa della tua {this.vehicle}
                  </label>
                  <InputText
                    type="text"
                    placeholder="Targa"
                    value={vehicleData.vehicleLicensePlateNumber ?? ""}
                    id="targa"
                    onFocus={() => {
                      this.oldLicensePlateValue =
                      vehicleData.vehicleLicensePlateNumber ?? "";
                    }}
                    onChange={(selected) =>this.onChangeHandler(selected.target.value, "vehicleLicensePlateNumber")}
                    onBlur={() => this.fetchVeicleInfo4Ruote()}
                  />
                  <ContextFeedback
                    show={this.state.errors.includes("vehicleLicensePlateNumber")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "vehicleLicensePlateNumber") ??
                      {msg: "Targa non valida", severity: "error"}
                    }
                  />
                </div>
              )}
              <div className="immatricolation-fields col-12">
                <div className="row">
                  <div className="col-xl-6 col-12 mb-4">
                    <label>Anno immatricolazione</label>
                    <Dropdown
                      value={vehicleData.vehicleRegistrationYear}
                      options={this.state.yearList}
                      onChange={(selected) =>
                        this.onChangeHandler(
                          selected.value,
                          "vehicleRegistrationYear"
                        )
                      }
                      optionLabel="label"
                      placeholder="Seleziona..."
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      onBlur={() => this.validation("vehicleRegistrationYear")}
                      onHide={() => removeFocusClass()}
                      disabled={
                        vehicleData.infoFrom4Ruote !== undefined &&
                        vehicleData.infoFrom4Ruote === true
                      }
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleRegistrationYear")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleRegistrationYear") ??
                        {msg: "Inserisci l'anno di immatricolazione", severity: "error"}
                      }
                    />
                  </div>
                  <div className="col-xl-6 col-12 mb-4">
                    <label>Mese immatricolazione</label>
                    <Dropdown
                      value={vehicleData.vehicleRegistrationMonth}
                      options={this.monthList}
                      onChange={(selected) =>
                        this.onChangeHandler(
                          selected.value,
                          "vehicleRegistrationMonth"
                        )
                      }
                      optionLabel="label"
                      placeholder="Seleziona..."
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      onHide={() => removeFocusClass()}
                      disabled={
                        vehicleData.infoFrom4Ruote !== undefined &&
                        vehicleData.infoFrom4Ruote === true
                      }
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleRegistrationMonth")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleRegistrationMonth") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mb-4">
                <label>Anno acquisto</label>
                <Dropdown
                  value={vehicleData.vehicleBuyYear}
                  options={this.state.yearBuyList}
                  onChange={(selected) =>
                    this.onChangeHandler(selected.value, "vehicleBuyYear")
                  }
                  onHide={() => removeFocusClass()}
                  optionLabel="label"
                  placeholder="Seleziona"
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                  onBlur={() => this.validation("vehicleBuyYear")}
                />
                <ContextFeedback
                  show={this.state.errors.includes("vehicleBuyYear")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "vehicleBuyYear") ??
                    {msg: "Inserisci l'anno di acquisto", severity: "error"}
                  }
                />
                <ContextFeedback
                  show={this.state.errors.includes("vehicleBuyYearVsVehicleRegistrationYear")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "vehicleBuyYearVsVehicleRegistrationYear") ??
                    {msg: "L'anno di acquisto non può essere precedente all'anno di immatricolazione", severity: "error"}
                  }
                />
              </div>

              {/* se non provengo da 4ruote */}
              {(vehicleData.infoFrom4Ruote === undefined || vehicleData.infoFrom4Ruote === false) &&
                <>
                  <div className="col-12 mb-4">
                    <label>Marca</label>
                    <Dropdown
                      value={vehicleData.vehicleBrand}
                      options={this.state.vehicleBrandList}
                      onChange={(selected) =>
                        this.onChangeHandler(selected.value, "vehicleBrand")
                      }
                      onHide={() => removeFocusClass()}
                      optionValue="id"
                      optionLabel="value"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleBrand")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleBrand") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>

                  <div className="col-12 mb-4">
                    <label>Modello</label>
                    <Dropdown
                      value={vehicleData.vehicleModel}
                      options={this.state.carModelList}
                      onChange={(selected) =>
                        this.onChangeHandler(selected.value, "vehicleModel")
                      }
                      onHide={() => removeFocusClass()}
                      optionValue="id"
                      optionLabel="value"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleModel")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleModel") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>

                  {this.isVisible("auto") &&
                    <>
                      <div className="col-12 mb-4">
                        <label>Alimentazione</label>
                        <Dropdown
                          value={vehicleData.vehicleFuelType}
                          options={this.state.fuelTypeList}
                          onChange={(selected) =>
                            this.onChangeHandler(selected.value, "vehicleFuelType")
                          }
                          onHide={() => removeFocusClass()}
                          optionValue="id"
                          optionLabel="value"
                          placeholder="Seleziona"
                          emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("vehicleFuelType")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "vehicleFuelType") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                      
                      {/* se l'alimentazione non è elettrica */}
                      {vehicleData.vehicleFuelType !== "E" &&
                        <div className="col-12 mb-4">
                          <label>Cilindrata</label>
                          <Dropdown
                            value={vehicleData.vehicleEngineDisplacement}
                            options={this.state.engineDisplacementList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "vehicleEngineDisplacement")}
                            onHide={() => removeFocusClass()}
                            optionValue="id"
                            optionLabel="value"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("vehicleEngineDisplacement")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "vehicleEngineDisplacement") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                      }
                    </>
                  }
                </>
              }
              
              {/** TODO to refine */}
              {/* {(this.state?.vehicleFittingList ?? [])?.length > 0 && (this.state.vehicleFittingList as IVehicleFitting[])[0]?.value && */}
                  <div className="col-12 mb-4">
                    <label>Allestimento</label>
                    <Dropdown
                      value={vehicleData.vehicleFitting}
                      options={this.state.vehicleFittingList}
                      onChange={(selected) => this.onChangeHandler(selected.value, "vehicleFitting")}
                      onHide={() => removeFocusClass()}
                      optionValue="id"
                      optionLabel="value"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleFitting")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleFitting") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
              {/* } */}

              {vehicleData?.infoFrom4Ruote === true &&
                ((vehicleData?.vehicleFitting ?? "") !== "" || vehicleData.veicleFullDescription !== undefined) &&
                <div className="col-12 mb-4">
                  Marca e Modello:{" "}
                  <strong>{vehicleData.veicleFullDescription}</strong>
                </div>
              }

              {this.isVisible("auto") &&
                <>
                  {vehicleData.vehicleFuelType !== "E" &&
                    <div className="col-12 mb-4">
                      <label>Hai montato un impianto GPL/metano?</label>
                      <SelectButton
                        value={vehicleData.vehicleGplMounted}
                        options={[
                          { label: "Si", value: true },
                          { label: "No", value: false },
                        ]}
                        onChange={(selected) => this.onChangeHandler(selected.value, "vehicleGplMounted")}
                        className="smallBtn"
                        unselectable={false}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("vehicleGplMounted")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "vehicleGplMounted") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  }

                  <div className="col-12 mb-4">
                    <label>Hai montato il gancio da traino?</label>
                    <SelectButton
                      value={vehicleData.vehicleTowbarMounted}
                      options={[
                        { label: "Si", value: true },
                        { label: "No", value: false },
                      ]}
                      onChange={(selected) =>
                        this.onChangeHandler(selected.value, "vehicleTowbarMounted")
                      }
                      className="smallBtn"
                      unselectable={false}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleTowbarMounted")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleTowbarMounted") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>

                  <div className="col-12 mb-4">
                    <label>Quante auto hai nel nucleo familiare?</label>
                    <SelectButton
                      value={vehicleData.moreThanOneVehicle}
                      options={[
                        { label: "1 auto", value: false },
                        { label: "2 o più auto", value: true },
                      ]}
                      onChange={(selected) =>
                        this.onChangeHandler(selected.value, "moreThanOneVehicle")
                      }
                      className="regularBtn"
                      unselectable={false}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("moreThanOneVehicle")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "moreThanOneVehicle") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                </>
              }

              <div className="col-12 mb-4">
                <label>
                  KM percorsi in un anno:{" "}
                  <span className="color-text">
                    {vehicleData.kilometersPerYear
                      ? this.formatKmPerYear(vehicleData.kilometersPerYear)
                      : 5000}{" "}
                    km
                  </span>
                </label>
                <Slider
                  className="my-3"
                  value={vehicleData.kilometersPerYear ?? 5000}
                  min={5000}
                  max={100000}
                  step={5000}
                  onChange={(selected) =>
                    this.onChangeHandler(selected.value, "kilometersPerYear")
                  }
                />
                <ContextFeedback
                  show={this.state.errors.includes("kilometersPerYear")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "kilometersPerYear") ??
                    {msg: "Campo obbligatorio", severity: "error"}
                  }
                />
                <div className="row">
                  <div className="col-6">
                    <div className="text-start slider-value">&lt; 5.000</div>
                  </div>
                  <div className="col-6">
                    <div className="text-end slider-value">&gt; 100.000</div>
                  </div>
                </div>
              </div>

              {this.isVisible("auto") && (
                <div className="col-12 mb-4">
                  <label>Che utilizzo fai dell'auto?</label>
                  <Dropdown
                    value={vehicleData.vehicleUse}
                    options={this.props.vehicleUseList}
                    onChange={(selected) =>
                      this.onChangeHandler(selected.value, "vehicleUse")
                    }
                    onHide={() => removeFocusClass()}
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Seleziona"
                    emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                  />
                  <ContextFeedback
                    show={this.state.errors.includes("vehicleUse")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "vehicleUse") ??
                      {msg: "Campo obbligatorio", severity: "error"}
                    }
                  />
                </div>
              )}

              <div className="col-12 mb-4">
                <label>Tipologia di antifurto</label>
                <Dropdown
                  value={vehicleData.vehicleAntitheft}
                  options={this.props.antitheftList}
                  onChange={(selected) =>
                    this.onChangeHandler(selected.value, "vehicleAntitheft")
                  }
                  onHide={() => removeFocusClass()}
                  optionValue="value"
                  optionLabel="label"
                  placeholder="Seleziona"
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                />
                <ContextFeedback
                  show={this.state.errors.includes("vehicleAntitheft")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "vehicleAntitheft") ??
                    {msg: "Campo obbligatorio", severity: "error"}
                  }
                />
              </div>
              
              <div className="col-12">
                <label>
                  Solitamente dove parcheggi il veicolo durante la notte?
                </label>
                <Dropdown
                  value={vehicleData.vehicleParkingLocation}
                  options={this.props.parkingLocationList}
                  onChange={(selected) =>
                    this.onChangeHandler(selected.value, "vehicleParkingLocation")
                  }
                  onHide={() => removeFocusClass()}
                  optionValue="value"
                  optionLabel="label"
                  placeholder="Seleziona"
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                />
                <ContextFeedback
                  show={this.state.errors.includes("vehicleParkingLocation")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "vehicleParkingLocation") ??
                    {msg: "Campo obbligatorio", severity: "error"}
                  }
                />
              </div>
            </div>
          </div>

          <div className="step-btn-container p-fluid p-4 p-lg-5">
            <div className="row">
              <div className="col-6 button-back">
                <Button
                  className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                  type="button"
                  label="Indietro"
                  onClick={() => {
                    this.props.userInfoDataSuccess({
                      direction: "backwards",
                      nextStep: 2
                    });
                    this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle))
                  }}
                />
              </div>
              <div className="col-6 button-next">
                <Button
                  className="customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                  type="button"
                  label="Continua"
                  onClick={() => this.handleFormSubmit() }
                  loading={this.state.submitting}
                />
              </div>
            </div>
          </div>
        </Card>

        {/* PopUp Servizio targhe */}
        {this.state !== undefined && this.state?.plateLoading && (
          <Dialog
            header=""
            className="update_popUp"
            visible={this.state.plateLoading}
            closable={false}
            draggable={false}
            style={{ width: "50vw" }}
            onHide={() => {}}
          >
            <div className="row">
              <div className="col-12">
                <p className="col_title">
                  Tentativo di recupero dei dati veicolo in corso
                </p>
                <div className="row text-center">
                  <div className="image-loader-update">
                    <img
                      src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/6sicuro-logo-animato.gif`}
                      alt="attendi il caricamento dei risultati - 6sicuro"
                      id="attesa_caricamento"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </div>
    );
  }

  // ------------------------------------------------------------------------------------------------------------------------------

  private getDataToTrack(){
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const vehicleData: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    return {
      prodotto: this.vehicle,
      gender: step1Data.gender,
      dataNascita: formatDateAdform(dateOfBirth),
      titoloStudio: step1Data.degree,
      professione: step1Data.profession,
      dataImmatricolazione: `${
        vehicleData.vehicleRegistrationMonth !== undefined &&
        vehicleData.vehicleRegistrationMonth < 10
          ? `0${vehicleData.vehicleRegistrationMonth}`
          : vehicleData.vehicleRegistrationMonth
      }/${vehicleData.vehicleRegistrationYear}`,
      annoAcquisto: vehicleData.vehicleBuyYear,
      veichleBrand: vehicleData.vehicleBrandLabel,
      veichleModel: vehicleData.vehicleModelLabel,
    }
  }

  /**
   * Trak tag-manager: notifica errore
   */
  private trackSendFailure() {
    this.props.trackEvent?.({
      name: "step2GuestError",
      data: {
        ...this.getDataToTrack(),
        errors: this.state.errors,
        errorTexts: this.state.errorTexts,
      }
    });
  }

  /**
   * Trak tag-manager: notifica successo
   */
  private trackSendSuccess(callbackFunction: Function) {
    this.props.trackEvent?.({
      name: "step2GuestSuccess",
      data: this.getDataToTrack(),
      callback: callbackFunction
    });
  }

  /**
   * Track tag-mamager: notifica pagina caricata
   */
  private trakSendLoaded() {
    this.props.trackEvent?.({
      name: "step2GuestLoaded",
      data: this.getDataToTrack()
    });
  }

  private getClearPlateDependentFields() {
    return {
      vehicleRegistrationMonth: undefined,
      vehicleRegistrationYear: undefined,
      vehicleBuyYear: undefined,
      vehicleBrand: undefined,
      vehicleModel: undefined,
      vehicleFitting: undefined,
      vehicleTowbarMounted: undefined,
      vehicleAntitheft: undefined,
      vehicleFuelType: undefined,
      vehicleGplMounted: undefined,
      vehicleEngineDisplacement: undefined,
      veicleFullDescription: undefined,
      infoFrom4Ruote: false,
    };
  }

  /**
   * Formatta i Km annui
   * @param n il numero da formattare
   * @returns una stringa con dove n  è suddiviso da punti
   */
  private formatKmPerYear(n: any) {
    return (Math.round(n * 100) / 100).toLocaleString();
  }

  /**
   * Know license plate form field visibility check.
   *
   * @returns {boolean} whether the "know license plate" question in the form should be visible
   */
  private isVisibleKnowLicensePlate() {
    
    return !((this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data)?.vehicleOwned ?? true);
    
    // let is_visible;
    // if (owned === undefined) {
    //   is_visible = false;
    // } else {
    //   is_visible = owned ? false : true;
    // }
    // return is_visible;
  }
  
  /**
   * License plate form field visibility check.
   *
   * @returns {boolean} whether the license plate form field is visible
   */
  private isVisibleLicensePlate() {
    const owned: boolean = (this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data)?.vehicleOwned ?? false;
    const knowLicensePlate: boolean = (this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data)?.knowLicensePlate ?? false;

    return owned || knowLicensePlate;

    // let is_visible;
    // if (this.state?.vehicleOwned) {
    //   is_visible = true;
    // } else {
    //   if (this.state.knowLicensePlate === undefined) {
    //     is_visible = false;
    //   } else {
    //     is_visible = this.state.knowLicensePlate ? true : false;        
    //   }
    // }

    // return is_visible;    
  }

  /**
   * Check if current field is viewable.
   *
   * @param {string} current_field The current field
   *
   * @returns {boolean} Visibility check
   */
  private isVisible(current_field: string): boolean {
    let is_visible;

    switch (current_field) {
      case "auto":
        is_visible = this.vehicle === "auto";
        break;
      case "moto":
        is_visible = this.vehicle === "moto";
        break;
      case "knowLicensePlate":
        is_visible = this.isVisibleKnowLicensePlate();
        break;
      case "vehicleLicensePlateNumber":
        is_visible = this.isVisibleLicensePlate();
        break;
      default:
        is_visible = false;
    }

    return is_visible;
  }

  /**
   * Saves the current internal component state to Redux.
   */
  private saveReduxState(bodyData: Step2Data) {
    this.props.step2DataSuccess(bodyData, this.vehicle);
  }

  /**
   * Handles various state change events related to the form's components.
   *
   * @param {any} selected The selected object
   * @param {string} componentName The name of the component to handle
   */
  private async onChangeHandler(selected: any, componentName: string) {

    let dataToDispatch: Step2Data = {};
    let callback: Function | undefined = undefined;

    switch (componentName) {
      
      case "vehicleRegistrationYear":
        dataToDispatch[componentName] = selected;
        dataToDispatch.vehicleBuyYear = undefined;
        callback = () => this.fetchModels(true, {[componentName]: selected});
        break;

      case "vehicleRegistrationMonth":   
        dataToDispatch[componentName] = selected;
        callback = () => this.fetchModels(true, {[componentName]: selected});
        break;

      case "vehicleBrand":
        dataToDispatch[componentName] = selected;
        dataToDispatch.vehicleBrandLabel = this.state.vehicleBrandList?.find((elem: {id: string, value: string}) => elem.id === selected)?.value;
        callback = () => this.fetchModels(true, {[componentName]: selected});
        break;

      case "vehicleModel":
        dataToDispatch[componentName] = selected;
        dataToDispatch.vehicleModelLabel = this.state.carModelList?.find((elem: {id: string, value: string}) => elem.id === selected)?.value;
        callback = (selected: any) => {
          this.fetchFuelType(true, {[componentName]: selected});
          const vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
          let params: IVehicleInfoRequest = {...vehicle, vehicleModel: selected}
          this.fetchVehicleFittings(true, params);
        }
        break;

      case "vehicleFuelType":
        dataToDispatch[componentName] = selected;
        dataToDispatch.vehicleEngineDisplacement = undefined;
        dataToDispatch.vehicleFitting = undefined;
        if(selected === "E"){
          dataToDispatch.vehicleGplMounted = false;
        }

        this.setState(
          {vehicleFittingList: []},
          () => {
            this.props.step2DataSuccess(dataToDispatch, this.vehicle);
            this.fetchEngineDisplacement(true, false, {[componentName]: selected})
          }
        );
        return;

      case "vehicleEngineDisplacement":
        dataToDispatch[componentName] = selected;
        dataToDispatch.vehicleFitting = undefined;
        callback = (selected: any) => {
          const vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
          let params: IVehicleInfoRequest = {...vehicle, vehicleEngineDisplacement: selected}
          this.fetchVehicleFittings(true, params);
        };
        break;

      case "vehicleFitting":
        dataToDispatch[componentName] = selected;
        dataToDispatch.vehicleFittingLabel = this.state.vehicleFittingList?.find((elem: {id: string, value: string}) => elem.id === selected)?.value;
        callback = (selected: any) => {
          const vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
          
          if (vehicle?.infoFrom4Ruote/* && this.isCachedValue === false*/) {
            this.fetchVeicleInfoByFitting(selected); // carico le restanti info del veicolo dal dal DB
          }
        }
        break;

      case "knowLicensePlate":
        dataToDispatch[componentName] = selected;
        if(!selected){
          dataToDispatch.vehicleLicensePlateNumber = undefined;
          dataToDispatch.vehicleBrand = undefined;
          dataToDispatch.vehicleBrandLabel = undefined;
          dataToDispatch.vehicleModel = undefined;
          dataToDispatch.vehicleModelLabel = undefined;
          dataToDispatch.vehicleEngineDisplacement = undefined;
          dataToDispatch.vehicleFuelType = undefined;
          dataToDispatch.infoFrom4Ruote = false;
          dataToDispatch.vehicleFitting = undefined;
          dataToDispatch.vehicleFittingLabel = undefined;
          dataToDispatch.veicleFullDescription = undefined;
          dataToDispatch.vehicleRegistrationYear = undefined;
          dataToDispatch.vehicleRegistrationMonth = undefined;
          dataToDispatch.vehicleBuyYear = undefined;
          dataToDispatch.vehicleGplMounted = undefined;
          dataToDispatch.vehicleTowbarMounted = undefined;
          dataToDispatch.vehicleAntitheft = undefined;
        }
        
        this.setState(
          {
            plateLoading: false,
            vehicleFittingList: [],
          },
          () => this.saveReduxState(dataToDispatch)
        );
        return;

      case "vehicleOwned":
        const vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;

        dataToDispatch[componentName] = selected;
        if(vehicle.vehicleOwned === false){
          dataToDispatch.knowLicensePlate = true
        }
        
        break;
      
      case "vehicleLicensePlateNumber":
        dataToDispatch[componentName] = selected;
        dataToDispatch.knowLicensePlate = selected !== "";

        break;

      default:
        dataToDispatch[componentName as keyof Step2Data] = selected;
        break;
    }

    this.resetValidation(componentName)
    this.saveReduxState(dataToDispatch)
    callback?.(selected);
  }


  /**
   * Resetta i campi della form legati al veicolo e cerca i modelli in base al anno / mese di immatricolazione e la marca
   * (aggiorna la lista / tendina dei modelli disponibili)
   * Inoltre si occupa di resettare la lista degli anni in cui il veicolo è acquistabile
   */
  fetchModels(isPageLoaded: boolean = true, overrideVehicle?: {vehicleRegistrationYear?: number, vehicleRegistrationMonth?: number, vehicleBrand?: string}) {
    let vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    let dataToDispacth: Step2Data = {}

    if(overrideVehicle){
      vehicle = {...vehicle, ...overrideVehicle};
    }

    // resetto la lista degli anni aquisto
    if (vehicle?.vehicleRegistrationYear) {
      let currentYear = new Date().getFullYear();
      let yearBuyList: any[] = [];

      for (let i = vehicle.vehicleRegistrationYear; i <= currentYear; i++) {
        yearBuyList.push({ label: i.toString(), value: i });
      }
      this.setState({yearBuyList})
    }


    if (vehicle.infoFrom4Ruote) {
      return false;
    }

    if (this.vehicle === "moto") {

      if (isPageLoaded) {
        dataToDispacth = {
          vehicleModel: undefined,
          vehicleFuelType: undefined,
          vehicleEngineDisplacement: undefined,
          vehicleFitting: undefined,
        }
        
        this.setState({
          fuelTypeList: [],
          engineDisplacementList: [],
          vehicleFittingList: [],
          carModelList: [],
        }, () => {
          this.saveReduxState(dataToDispacth);
        });
        
        // this.setState({
        //   vehicleModel: undefined,
        //   vehicleFuelType: undefined,
        //   vehicleEngineDisplacement: undefined,
        //   vehicleFitting: undefined,

        //   fuelTypeList: [],
        //   engineDisplacementList: [],
        //   vehicleFittingList: [],
        //   carModelList: [],
        // });
      }

      if (
        vehicle.vehicleRegistrationYear !== undefined &&
        vehicle.vehicleRegistrationMonth !== undefined &&
        vehicle.vehicleBrand !== undefined && 
        vehicle.vehicleBrand !== ""
      ) {
        fetchList(
          Constants.SEISICURO_AJAX_MOTO_MODEL_ENDPOINT(
            vehicle.vehicleRegistrationYear,
            vehicle.vehicleRegistrationMonth,
            vehicle.vehicleBrand
          ),
          (result: any) => {
            let sortedModels = result.sort((a: any, b: any) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;
              return 0;
            });
            this.setState({ carModelList: sortedModels }/*, () => this.saveReduxState()*/);
          }
        );
      }
    }
    else {
      if (isPageLoaded) {
        dataToDispacth = {
          vehicleModel: undefined,
          vehicleFuelType: undefined,
          vehicleEngineDisplacement: undefined,
          vehicleFitting: undefined,
        }
        
        this.setState({
          fuelTypeList: [],
          engineDisplacementList: [],
          vehicleFittingList: [],
          carModelList: [],
        }, () => {
          this.saveReduxState(dataToDispacth);
        });
      }
      if (
        vehicle.vehicleRegistrationYear !== undefined &&
        vehicle.vehicleRegistrationMonth !== undefined &&
        vehicle.vehicleBrand !== undefined &&
        vehicle.vehicleBrand !== ""
      ) {
        fetchList(
          Constants.SEISICURO_AJAX_CAR_MODEL_ENDPOINT(
            vehicle.vehicleRegistrationYear,
            vehicle.vehicleRegistrationMonth,
            vehicle.vehicleBrand
          ),
          (result: any) => {
            let sortedModels = result.sort((a: any, b: any) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;
              return 0;
            });
            this.setState({ carModelList: sortedModels });
          }
        );
      }
    }
  }

  fetchVehicleFittings(clearData: boolean = true, parameters: IVehicleInfoRequest | undefined = undefined) {
    let dataToDispacth: Step2Data = {}
    let requestParams: IVehicleInfoRequest = {};

    if(parameters){
      requestParams = {...parameters};
    }
    else{
      const vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;

      vehicle?.vehicleRegistrationYear && (requestParams.vehicleRegistrationYear = vehicle.vehicleRegistrationYear);
      vehicle?.vehicleRegistrationMonth && (requestParams.vehicleRegistrationMonth = vehicle.vehicleRegistrationMonth);
      vehicle?.vehicleBrand && (requestParams.vehicleBrand = vehicle.vehicleBrand);
      vehicle?.vehicleModel && (requestParams.vehicleModel = vehicle.vehicleModel);
      vehicle?.vehicleFuelType && (requestParams.vehicleFuelType = vehicle.vehicleFuelType);
      vehicle?.vehicleEngineDisplacement && (requestParams.vehicleEngineDisplacement = vehicle.vehicleEngineDisplacement);
    }

    // if (vehicle.infoFrom4Ruote) {
    //   return false;
    // }

    if (this.vehicle === "moto") {
      if (clearData) {
        dataToDispacth = {
          vehicleFuelType: undefined,
          vehicleEngineDisplacement: undefined,
          vehicleFitting: undefined,
          vehicleFittingLabel: undefined,
        }

        this.setState({
          fuelTypeList: [],
          engineDisplacementList: [],
        }, () => {
          this.saveReduxState(dataToDispacth)
        });
      }

      if (
        requestParams?.vehicleRegistrationYear !== undefined &&
        requestParams?.vehicleRegistrationMonth !== undefined &&
        requestParams?.vehicleBrand !== undefined &&
        requestParams?.vehicleModel !== undefined
      ) {
        fetchList(
          Constants.SEISICURO_AJAX_MOTO_FITTING_ENDPOINT(
            requestParams.vehicleRegistrationYear,
            requestParams.vehicleRegistrationMonth,
            requestParams.vehicleBrand,
            requestParams.vehicleModel
          ),
          (result: any) => {
            let sortedFittings = result.sort((a: any, b: any) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;
              return 0;
            });

            let nonRicordoElem: any | undefined = {};
            sortedFittings
              .filter((elem: any) => {
                if(elem.value !== "Non lo ricordo"){
                  nonRicordoElem = {...elem, value: "Non lo ricordo - scegli per me!"}
                }
                return elem
              })
            
            if(nonRicordoElem !== undefined){
              sortedFittings.push(nonRicordoElem)
            }

            this.setState(
              { vehicleFittingList: sortedFittings },
              () => {
                if (clearData && sortedFittings.length === 1) {
                  this.onChangeHandler(sortedFittings[0].id, "vehicleFitting");
                }
              }
            );
            
            const vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;

            const fittingLabel : string = sortedFittings.find((fitting: IVehicleFitting) => fitting.id === vehicle?.vehicleFitting)?.value;
            if(fittingLabel){
              this.saveReduxState({vehicleFittingLabel: fittingLabel})
            }
            else{
              this.saveReduxState({vehicleFittingLabel: undefined})
            }
          }
        );
      }
    }
    else {

      if (clearData) {
        dataToDispacth = {
          vehicleFitting: undefined,
          vehicleFittingLabel: undefined
        }

        this.setState({
          // engineDisplacementList: [],
          vehicleFittingList: [],
        }, () => {
          this.saveReduxState(dataToDispacth)
        });
      }
      
      if (
        requestParams?.vehicleRegistrationYear !== undefined &&
        requestParams?.vehicleRegistrationMonth !== undefined &&
        requestParams?.vehicleBrand !== undefined &&
        requestParams?.vehicleModel !== undefined &&
        requestParams?.vehicleFuelType !== undefined &&
        requestParams?.vehicleEngineDisplacement !== undefined
      ) {
        fetchList(
          Constants.SEISICURO_AJAX_CAR_FITTING_ENDPOINT(
            requestParams.vehicleRegistrationYear,
            requestParams.vehicleRegistrationMonth,
            requestParams.vehicleBrand,
            requestParams.vehicleModel,
            requestParams.vehicleFuelType,
            requestParams.vehicleEngineDisplacement
          ),
          (result: any) => {
            let sortedFittings = result.sort((a: any, b: any) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;
              return 0;
            });

            let nonRicordoElem: any | undefined = {};
            sortedFittings
              .filter((elem: any) => {
                if(elem.value !== "Non lo ricordo"){
                  nonRicordoElem = {...elem, value: "Non lo ricordo - scegli per me!"}
                }
                return elem
              })
            
            if(nonRicordoElem !== undefined){
              sortedFittings.push(nonRicordoElem)
            }

            this.setState({
              vehicleFittingList: sortedFittings
            });

            const vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;

            const fittingLabel : string = sortedFittings.find((fitting: IVehicleFitting) => fitting.id === vehicle?.vehicleFitting)?.value;
            if(fittingLabel){
              this.saveReduxState({vehicleFittingLabel: fittingLabel})
            }
            else{
              this.saveReduxState({vehicleFittingLabel: undefined})
            }

            if (clearData && sortedFittings.length === 1) {
              this.onChangeHandler(sortedFittings[0].id, "vehicleFitting"); // call to fetchVeicleInfoByFitting
            }
          }
        );
      }
    }
  }

  fetchFuelType(isPageLoaded: boolean = true, overrideParams?: {
    vehicleRegistrationYear?: number,
    vehicleRegistrationMonth?: number,
    vehicleBrand?: string,
    vehicleModel?: string,
  }) {
    let vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;

    if(overrideParams){
      vehicle = {...vehicle, ...overrideParams};
    }
    
    if (vehicle.infoFrom4Ruote) {
      return false;
    }

    if (
      vehicle?.vehicleRegistrationYear !== undefined &&
      vehicle?.vehicleRegistrationMonth !== undefined &&
      vehicle?.vehicleBrand !== undefined &&
      vehicle?.vehicleModel !== undefined
    ) {
      fetchList(
        Constants.SEISICURO_AJAX_CAR_FUEL_TYPE_ENDPOINT(
          vehicle.vehicleRegistrationYear,
          vehicle.vehicleRegistrationMonth,
          vehicle.vehicleBrand,
          vehicle.vehicleModel
        ),
        (result: any) => {
          let sortedFuelTypes = result.sort((a: any, b: any) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });
          this.setState({ fuelTypeList: sortedFuelTypes });
          if (isPageLoaded && sortedFuelTypes.length === 1) {
            this.onChangeHandler(sortedFuelTypes[0].id, "vehicleFuelType");
          }
        }
      );
    }
  }

  fetchEngineDisplacement(isPageLoaded: boolean = true, forceLoad: boolean = false, overrideParams?: {
    vehicleRegistrationYear?: number,
    vehicleRegistrationMonth?: number,
    vehicleBrand?: string,
    vehicleModel?: string,
    vehicleFuelType?: string,
  }) {
    let vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;

    if(overrideParams){
      vehicle = {...vehicle, ...overrideParams};
    }
    
    if (vehicle.infoFrom4Ruote && forceLoad === false) {
      return false;
    }

    if (
      vehicle?.vehicleRegistrationYear !== undefined &&
      vehicle?.vehicleRegistrationMonth !== undefined &&
      vehicle?.vehicleBrand !== undefined &&
      vehicle?.vehicleModel !== undefined &&
      vehicle?.vehicleFuelType !== undefined
    ) {
      // (registrationYear: number, registrationMonth: number, brandId: string, modelId: string)

      fetchList(
        Constants.SEISICURO_AJAX_CAR_ENGINE_DISPLACEMENT_ENDPOINT(
          vehicle.vehicleRegistrationYear,
          vehicle.vehicleRegistrationMonth,
          vehicle.vehicleBrand,
          vehicle.vehicleModel,
          vehicle.vehicleFuelType
        ),
        (result: any) => {
          let mappedResult = result.map((element: any) => {
            return { ...element, value: element.value.trim() };
          });

          this.setState({
            engineDisplacementList: mappedResult,
            plateLoading: false
          });

          if (isPageLoaded && mappedResult.length === 1) {
            this.onChangeHandler(mappedResult[0].id, "vehicleEngineDisplacement");
          }
      });
    }
  }

  // fittingId nella forma anno#mese#codiceAllestimento
  fetchVeicleInfoByFitting(fittingId?: string, parameters: IVehicleInfoRequest | undefined = undefined) {
    let requestParams : IVehicleInfoRequest = {};
    let requestFittingId: string | undefined = undefined;
    const vehicle: Step2Data = {...this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data, ...parameters};

    if(fittingId){
      requestFittingId = fittingId;
    }

    if(parameters){
      requestParams = {...parameters};
    }
    else{
      vehicle?.vehicleRegistrationYear && (requestParams.vehicleRegistrationYear = vehicle.vehicleRegistrationYear);
      vehicle?.vehicleRegistrationMonth && (requestParams.vehicleRegistrationMonth = vehicle.vehicleRegistrationMonth);

      // se non ti ho ancora settato il fittingID
      if(requestFittingId === undefined){
        vehicle?.vehicleFitting && (requestFittingId = vehicle.vehicleFitting);
      }
    }
    
    if (requestParams.vehicleRegistrationYear && requestParams.vehicleRegistrationMonth && requestFittingId) {
      
      this.adjustVeicleInfoByFitting(requestFittingId)
        .then((v: IVehicle) => {

          let dataToDispatch: Step2Data = {};

          dataToDispatch = {
            ...vehicle,
            ...v,
            vehicleGplMounted: v.vehicleFuelType === "E" ? false : vehicle?.vehicleGplMounted
          }
            
          this.setState(
            {plateLoading: false},
            () => this.saveReduxState(dataToDispatch)
          );
        })
        .catch((erro) => {
          this.setState(
            {plateLoading: false},
            () => this.saveReduxState({infoFrom4Ruote:false})
          );
        });

      const [anno, mese, codAll] = (requestFittingId ?? "").split("#");

      const url = this.vehicle === "auto"
        ? Constants.SEISICURO_FETCF_DATI_VEICOLO(anno, mese, codAll)
        : Constants.SEISICURO_FETCF_DATI_VEICOLO_MOTO(anno, mese, codAll);

      fetch(
        url, {
          signal: this.abortController.signal,
          credentials: "include",
          headers: {
            /** AUTH_REDACTED */
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          let dataToDispatch: Step2Data = {};

          dataToDispatch = {
            vehicleBrand: result.codmar,
            vehicleBrandLabel: result.desmar,
            vehicleModel: result.codmod,
            vehicleEngineDisplacement: result.cilind,
            vehicleModelLabel: result.desmod,
            vehicleFuelType: result.tipcom,
            infoFrom4Ruote: true,
            vehicleFittingLabel: (this.state.vehicleFittingList ?? []).find((fitting: IVehicleFitting) => fitting.id === requestFittingId)?.value ?? "",
            veicleFullDescription: result.desmar + " " + result.desmod,
            vehicleFitting: requestFittingId,
            vehicleGplMounted: result.tipcom === "E" ? false : vehicle?.vehicleGplMounted
          }
            
          this.setState(
            {plateLoading: false},
            () => this.saveReduxState(dataToDispatch)
          );
        })
        .catch((erro) => {
          this.setState(
            {plateLoading: false},
            () => this.saveReduxState({infoFrom4Ruote:false})
          );
        });
    }
  }

  /**
   * In caso di info cachate va chiamato questo metodo per recuperare le informazioni mancanti
   * le label che il nodo non salva
   * @param fittingId 
   * @returns 
   */
  adjustVeicleInfoByFitting(fittingId: string): Promise<IVehicle> {
    const [anno, mese, codAll] = fittingId.split("#");

    const url =
      this.vehicle === "auto"
        ? Constants.SEISICURO_FETCF_DATI_VEICOLO(anno, mese, codAll)
        : Constants.SEISICURO_FETCF_DATI_VEICOLO_MOTO(anno, mese, codAll);

    return fetch(url, {
      signal: this.abortController.signal,
      credentials: "include",
      headers: {
        /** AUTH_REDACTED */
      },
    })
      .then((response) => response.json())
      .then((result) => {
        
        return {
          vehicleBrand: result.codmar,
          vehicleBrandLabel: result.desmar,
          vehicleModel: result.codmod,
          vehicleModelLabel: result.desmod,
          vehicleEngineDisplacement: result.cilind,
          vehicleFitting: fittingId,
          vehicleFittingLabel: result.desall,
          vehicleFuelType: result.tipcom,
          infoFrom4Ruote: true,
          veicleFullDescription: result.desmar + " " + result.desmod,
        }
      })
  }

  /**
   * Reset element validation
   * @param element
   * @returns
   */
  resetValidation(element: any, callback?: Function) {
    if (element === "knowLicensePlate") {
      this.setState({errors: [], errorTexts: []})
    }
    else {
      this.setState({
        errors: this.state.errors.filter((item: string) => item !== element),
        errorTexts: this.state.errorTexts.filter((item: IFeedbackText) => item.field !== element),
      }, () => callback?.());
    }
    return;
  }

  /**
   * Data la targa recupera le informazioni sul veicolo da 4Ruote
   */
  fetchVeicleInfo4Ruote() {
    const vehicle: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    
    if (vehicle?.vehicleLicensePlateNumber === this.oldLicensePlateValue) {
      return
    }
    else if(vehicle?.vehicleLicensePlateNumber === ""){
      this.setState({
        errors: this.state.errors.filter((err: string) => err !== "vehicleLicensePlateNumber"),
        errorTexts: this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "vehicleLicensePlateNumber")
      })
      return
    }
    
    this.setState(
      { plateLoading: true},
      () => {

    
        const licensePlateErrorMessage = vehicle.vehicleType === "moto"
          ? "Non è stato possibile recuperare i dati della moto."
          : "Non è stato possibile recuperare i dati dell'auto.";

        this.validation("vehicleLicensePlateNumber")
          .then((result: IValidationResponse) => {        
    
            const {status} = result;
            const value: string | undefined = vehicle.vehicleLicensePlateNumber;
    
            if(!status){
              let err = new Error("Targa non valida");
              err.name = "VAL_PLATE_ERR";
              throw err;
            }
            const url = this.vehicle === "auto"
              ? Constants.SEISICURO_FETCF_DATI_4RUOTE()
              : Constants.SEISICURO_FETCF_DATI_4RUOTE_MOTO();
    
            postData(url, {parametro: {Targa: value}})
              .then((response) => response.json())
              .then((data) => {
                let data4Ruote = data.infobikeDaTarga !== undefined
                  ? data.infobikeDaTarga
                : data.infocarDaTarga;

                // 03/02/2023 il servizio targa 4Ruote/2Ruote cambia la struttra del JSON
                // adesso prevede che le moto possano avere diversi allestimenti e li ritorna nel campo
                // listaMotoMultipliElementi
                
                let listaAllestimenti : undefined | any[];

                if(data.infobikeDaTarga !== undefined){
                  // moto
                  listaAllestimenti = data4Ruote?.lista !== undefined
                    ? data4Ruote.lista
                    : data4Ruote?.listaMotoMultipliElementi?.lista ?? []
                }
                else{
                  //auto
                  listaAllestimenti = data4Ruote?.lista
                }
    
                if (
                  (data.ErroreInterno === undefined || data.ErroreInterno === "") &&
                  ((data4Ruote !== undefined && listaAllestimenti !== undefined) || data.cached !== undefined)
                ) {
    
                  let currentYear = new Date().getFullYear();
                  
                  // versione letta dal DB
                  if (data.cached !== undefined) {
                    this.isCachedValue = true;
    
                    let cached = data.cached;
                    
                    if (cached.codmar && cached.codmod && cached.cilind && cached.desmar && cached.desmod && cached.codall) {
    
                      let dateParts = cached.dataImmatricolazione.split("/"); // -- FORMATO MM/gg/YYYY
                      let testDate = moment(dateParts[2] + "-" + dateParts[0] + "-" + dateParts[1]);
                      let dateObj = testDate.toDate();
    
                      let yearBuyList: any[] = [];
                      for (let i = dateObj.getFullYear(); i <= currentYear; i++) {
                        yearBuyList.push({ label: i.toString(), value: i });
                      }

                      this.setState({
                          yearBuyList
                        },
                        () => {

                          this.adjustVeicleInfoByFitting(cached.codall)
                            .then((refinedVehicle: Step2Data) => {
    
                              this.setState(
                                {
                                  vehicleFittingList: [],
                                  plateLoading: false
                                },
                                () => {
                                  let dataToDispatch: Step2Data = {
                                    ...refinedVehicle,
                                    vehicleRegistrationYear: dateObj.getFullYear(),
                                    vehicleRegistrationMonth: dateObj.getMonth() + 1,
                                    is4RuoteValid: true,
                                    infoFrom4Ruote: true,
                                  }
        
                                  this.saveReduxState(dataToDispatch)
                                  this.fetchVehicleFittings(false, dataToDispatch);
                                }
                              );
                            })
                            .catch((err) => {
                              this.setState(
                                {
                                  plateLoading: false,
                                  errors: this.state.errors.includes("generic") ? this.state.errors : [...this.state.errors, "generic"],
                                  errorTexts: [...this.state.errorTexts.filter((err: IFeedbackText) => err.field !== "generic"),
                                  {field: "generic", msg: licensePlateErrorMessage, severity: "warning"}]
                                },
                                () => this.saveReduxState({infoFrom4Ruote:false})
                              );
                            })
                        }
                      )
                      
                    }
                    else {
                      this.setState(
                        {                          
                          plateLoading: false,
                          errors: this.state.errors.includes("vehicleLicensePlateNumber")
                            ? this.state.errors
                            : [...this.state.errors, "vehicleLicensePlateNumber"],
                          errorTexts: [...this.state.errorTexts.filter((err: IFeedbackText) => err.field !== "vehicleLicensePlateNumber"),
                          {field: "vehicleLicensePlateNumber", msg: "licensePlateErrorMessage", severity: "warning"}]
                        },
                        () => this.saveReduxState({infoFrom4Ruote: false})
                      );
                    }
                  }
                  else{
                  // versione letta da 4Ruote
                  // if (data4Ruote !== undefined && listaAllestimenti !== undefined) {
                    this.isCachedValue = false;
    
                    let dateParts = data4Ruote.dataImmatricolazione.split("/"); // -- FORMATO MM/gg/YYYY
                    let testDate = moment(
                      dateParts[2] + "-" + dateParts[0] + "-" + dateParts[1]
                    );
                    let dateObj = testDate.toDate();
    
                    let arrOption = Array.isArray(listaAllestimenti)
                      ? listaAllestimenti
                      : [listaAllestimenti];
      
                    const vehicle4RuoteFittingList: IVehicleFitting[] = this.refine4RuoteVehicleFittings((Array.isArray(listaAllestimenti)
                      ? listaAllestimenti
                      : [listaAllestimenti]));
    
                    let yearBuyList: any[] = [];
                    for (let i = dateObj.getFullYear(); i <= currentYear; i++) {
                      yearBuyList.push({ label: i.toString(), value: i });
                    }
    
                    this.setState(
                      {
                        yearBuyList,
                        plateLoading: false,
                        vehicleFittingList: vehicle4RuoteFittingList,
                      }, 
                      () => {
                        let dataToDispatch: Step2Data = {
                          vehicleRegistrationYear: dateObj.getFullYear(),
                          vehicleRegistrationMonth: dateObj.getMonth() + 1,
                          vehicleBrand: "",
                          vehicleModel: undefined,
                          vehicleFitting: undefined,
                          vehicleFuelType: undefined,
                          vehicleEngineDisplacement: undefined,
                          veicleFullDescription: undefined,
                          infoFrom4Ruote: true,
                          is4RuoteValid: true
                        }
                        this.saveReduxState(dataToDispatch);
    
                        const fittingList : IVehicleFitting[] = this.state?.vehicleFittingList ?? [];
                        // viene autoselezionato il primo per forzare il carimanto delgi id brand, modell ecc
                        if (fittingList.length > 0) {
                          this.fetchVeicleInfoByFitting(fittingList[0].id, dataToDispatch)
                        }                      
                      }
                    );
                  }
    
                }
                else {
                  this.setState(
                    {
                      plateLoading: false,
                      errors: [...this.state.errors.filter(err => err !== "vehicleLicensePlateNumber"), "vehicleLicensePlateNumber"],
                      errorTexts: [
                        ...this.state.errorTexts.filter(feedback => feedback.field !== "vehicleLicensePlateNumber"),
                        { field: "vehicleLicensePlateNumber", msg: licensePlateErrorMessage, severity: "warning"                      
                      }]
                    },
                    () => this.saveReduxState({
                      infoFrom4Ruote: false,
                      is4RuoteValid: false,
                      vehicleBrand: "",
                      vehicleModel: undefined,
                      vehicleFitting: undefined,
                      vehicleFuelType: undefined,
                      vehicleEngineDisplacement: undefined,
                      veicleFullDescription: undefined
                    })
                  );
                }
              })
              .catch((erro) => {
                this.setState(
                  { plateLoading: false },
                  () => {
                    // let err = new Error("Targa non riconosciuta");
                    // err.name = "VAL_PLATE_UNRECOGNIZED";
                    // throw err;
    
                    let newState: any = {
                      plateLoading: false,
                      errorTexts: [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "vehicleLicensePlateNumber"), { field: "vehicleLicensePlateNumber", msg: licensePlateErrorMessage, severity: "warning"}],
                      errors: [...this.state.errors.filter((err: string) => err !== "vehicleLicensePlateNumber"), "vehicleLicensePlateNumber"]
                    }
    
                    this.setState(
                      {...newState},
                      () => {
                        let dataToDispatch: Step2Data = {
                          is4RuoteValid: false,
                          infoFrom4Ruote: false
                        };
    
                        this.saveReduxState(dataToDispatch);
                      }
                    )
                  }
                )
              });
          })
          .catch((err: Error) => {
    
            let newState: any = {
              plateLoading: false,
              errorTexts: [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "vehicleLicensePlateNumber"), { field: "vehicleLicensePlateNumber", msg: licensePlateErrorMessage, severity: "warning"}],
              errors: [...this.state.errors, this.state.errors.filter((err: string) => err !== "vehicleLicensePlateNumber"), "vehicleLicensePlateNumber"]
            }
    
            if(err.name === "VAL_PLATE_UNRECOGNIZED"){
              newState.errors = [...newState.errors.filter((err: string) => err !== "vehicleLicensePlateNumber"), "vehicleLicensePlateNumber"];
            }
            else if(err.name === "VAL_PLATE_ERR"){
              newState.carModelList = [];
              newState.fuelTypeList = [];
              newState.engineDisplacementList = [];
              newState.vehicleFittingList = [];
            }
    
            this.setState(
              {...newState},
              () => {
                let dataToDispatch: Step2Data = {
                  is4RuoteValid: false,
                  infoFrom4Ruote: false
                };
                
                if(err.name === "VAL_PLATE_ERR"){
                  dataToDispatch.veicleFullDescription = undefined
                  dataToDispatch = {...dataToDispatch, ...this.getClearPlateDependentFields()}
                }
    
                this.saveReduxState(dataToDispatch);
    
                if(err.name === "VAL_PLATE_ERR"){
                  this.fetchModels(false);
                  this.fetchVehicleFittings(false);
                  this.fetchFuelType(false);
                  this.fetchEngineDisplacement(false);
                }
              }
            );
          })
      }
    );
  }

  /**
   * Trasforma la risposta di 4ruote in allestimenti 6sicuro.
   * Si aspetta una lista del tipo
   *
   * list: [
   *  0: {
   *    anno: "2020"
   *    categoria: "A"
   *    codiceInfocar: "135376"   // codiceInfobike
   *    codiceInfocarAM: "202002135376" // codiceInfobikePRG
   *    descrizione: "Yaris 1.5 Hybrid 5 porte Business"
   *    descrizioneCompleta: "TOYOTA Yaris 1.5 Hybrid 5 porte Business"
   *    fineImmatricolazione: "000000"
   *    fineVendita: "000000"
   *    inizioImmatricolazione: "202002"
   *    inizioVendita: "202002"
   *    mese: "02"
   *  }, .....
   * ]
   *
   * @param respose4Ruote la lista dei allestimenti provenienti da 4ruote
   * @returns una lista di allestimenti nel formato 6sicuro (in cui il parametro gsoline è ininfluente),
   *  del tipo: [ { gasoline: 1, id: "9#2019#125514", value: "Berlingo PureTech 110 Stop&Start M Live ... " }, ... ]
   */
  private refine4RuoteVehicleFittings(respose4Ruote: I4RuoteFitting[]): IVehicleFitting[] {
    return respose4Ruote.map((ele: I4RuoteFitting) => {

      ele?.datoAllestimentoTargaBike && (ele = ele.datoAllestimentoTargaBike);

      let mese = ele.mese !== undefined
        ? parseInt(ele.mese, 10)
        : "0";

      let anno = ele.anno !== undefined
          ? parseInt(ele.anno, 10)
          : parseInt(ele?.progressivoAllestimento ?? "0", 10); // aggiungo ?? "0" solo per non avere errore di sintassi

      let code = ele.codiceInfocar !== undefined
          ? ele.codiceInfocar
          : ele?.codiceInfobike ?? "0"; // aggiungo ?? "0" solo per non avere errore di sintassi

      let descr = ele.descrizione !== undefined
          ? ele.descrizione
          : ele?.descrizione ?? "0"; // aggiungo ?? "0" solo per non avere errore di sintassi

      return {
        gasoline: 1,
        id: `${mese}#${anno}#${code}`,
        value: descr,
      };
    });
  }

  /**
   * Converts a month index number (1-12) to the corresponding italian month name.
   *
   * @param index
   * @returns
   */
  private monthIndexToName(index: number | undefined): string | undefined {
    if (index === undefined) {
      return undefined;
    } else {
      return Constants.MONTHS_LIST_IT.find(
        (element) => element.value === index
      )?.label.toLowerCase();
    }
  }

  /**
   * Form validation.
   *
   * @param specificField whether to check validity of a specific field
   * @returns {boolean} Validation check
   */
  private validation(specificField: undefined | string = undefined): Promise<IValidationResponse> {

    const vehicleData: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;

    const vehicle : IVehicle = {
      vehicleName: vehicleData?.vehicleName,
      vehicleType: vehicleData?.vehicleType,
      vehicleLicensePlateNumber: vehicleData?.vehicleLicensePlateNumber,
      vehicleRegistrationMonth: vehicleData?.vehicleRegistrationMonth,
      vehicleRegistrationYear: vehicleData?.vehicleRegistrationYear,
      vehicleBuyYear: vehicleData?.vehicleBuyYear,
      vehicleBrand: vehicleData?.vehicleBrand,
      vehicleModel: vehicleData?.vehicleModel,
      vehicleModelLabel: vehicleData?.vehicleModelLabel,
      vehicleFitting: vehicleData?.vehicleFitting,
      veicleFullDescription: vehicleData?.veicleFullDescription,
      vehicleOwned: vehicleData?.vehicleOwned,
      vehicleAntitheft: vehicleData?.vehicleAntitheft,
      vehicleFuelType: vehicleData?.vehicleFuelType,
      vehicleEngineDisplacement: vehicleData?.vehicleEngineDisplacement,
      vehicleTowbarMounted: vehicleData?.vehicleTowbarMounted,
      vehicleGplMounted: vehicleData?.vehicleGplMounted,
    }

    let fieldsToValidate : any = JSON.parse(JSON.stringify(this.vehicleFields));

    if(specificField !== undefined){
      // fieldsToValidate = (fieldsToValidate.step?.[specificField] ?? fieldsToValidate.vehicle[this.vehicle ?? "none"]?.[specificField]) as IFieldDescriptor[]

      if(fieldsToValidate.step?.[specificField]){
        fieldsToValidate = [fieldsToValidate.step?.[specificField]] as IFieldDescriptor[]
      }
      else if(fieldsToValidate.vehicle[this.vehicle ?? "none"]?.[specificField]){
        fieldsToValidate = [fieldsToValidate.vehicle[this.vehicle ?? "none"]?.[specificField]] as IFieldDescriptor[]
      }
      else{
        // backup di sicurezza
        fieldsToValidate = [];
      }
    }
    else{      
      delete fieldsToValidate?.vehicle[this.vehicle ?? "none"]?.vehicleName;
      
      if(vehicle?.vehicleFuelType === "E"){
        delete fieldsToValidate?.vehicle[this.vehicle ?? "none"]?.vehicleGplMounted;
        delete fieldsToValidate?.vehicle[this.vehicle ?? "none"]?.vehicleEngineDisplacement;
      }
      
      fieldsToValidate = Object.entries(fieldsToValidate?.vehicle[this.vehicle ?? "none"]).map(([, value]) => value) as IFieldDescriptor[]
    }

    let validation: IValidationResponse = {
      status: true,
      error_fields: [],
      error_messages: [],
    };
    
    let promises : Promise<IValidationResponse>[] = [];
    promises.push(User.validateVehicle(
      vehicle as IVehicle,        
      fieldsToValidate/*,
      {

        brandList: this.state?.vehicleBrandList,
        modelList: this.state?.carModelList,
        fittingList: this.state?.vehicleFittingList,
        fuelTypeList: this.state?.fuelTypeList,
        engineDisplacementList: this.state?.engineDisplacementList,
        antitheftList: this.state?.antitheftList
      }*/)
      // .then((result: IValidationResponse) => {
      //   return result;
      // })
    );

    if ((specificField === "knowLicensePlate" || !specificField) && isNullOrUndefined(vehicleData.knowLicensePlate)) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["knowLicensePlate"],
        error_messages: []
      }));
    }
    
    if ((specificField === "kilometersPerYear" || !specificField) && !vehicleData.kilometersPerYear) {
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["kilometersPerYear"],
          error_messages: []
        }));
    }

    if ((specificField === "vehicleParkingLocation" || !specificField) && !vehicleData.vehicleParkingLocation ) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["vehicleParkingLocation"],
        error_messages: []
      }));
    }
  
    if (this.vehicle === "auto" && 
    ((specificField === "vehicleUse" || !specificField) && !vehicleData.vehicleUse &&
      this.props?.vehicleUseList !== undefined && !this.props.vehicleUseList.some((item: any) => item.value === vehicleData.vehicleUse))
    ) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["vehicleUse"],
        error_messages: []
      }));
    }
    
    return Promise.allSettled(promises)
      .then((results) => {
        results.forEach((fieldResponse) => {
          if (fieldResponse.status === "fulfilled") {
            const {status, error_fields, error_messages} = fieldResponse.value;
            
            validation.status = validation.status && status;

            error_fields.forEach((item: string) => {
              if(!validation.error_fields.includes(item)){
                validation.error_fields.push(item)
              }
            })

            error_messages.forEach((item: IFeedbackText) => {
              validation.error_messages = validation.error_messages.filter((storedText: IFeedbackText) => storedText.field !== item.field);
              validation.error_messages.push(item);
            })
          }
        })
        return validation;
      });

  }

  /**
   * Check and append state data to form
   *
   * @param {any} formData The form data
   * @param {string } key The data key
   * @param {any} stateVariable The state variable
   */
  private formDataAppend(formData: any, key: string, stateVariable: any) {
    if (stateVariable !== undefined) {
      formData.append(key, `${stateVariable}`);
      //console.log(formData);
    }
  }


  private submitToNode() {
    const vehicleData: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    
    let formData = new URLSearchParams();
    formData.append("vehicle", this.vehicle);
    formData.append("step", "2");

    this.formDataAppend(formData, "veicolo+ricordaLaTarga", vehicleData.vehicleOwned);

    if (vehicleData.vehicleOwned ? true : vehicleData.knowLicensePlate) {
      this.formDataAppend(formData, "veicolo+targa", `${vehicleData.vehicleLicensePlateNumber ?? ""}`);
    }
    if (
      !this.isVisibleLicensePlate() &&
      vehicleData.knowLicensePlate !== undefined
    ) {
      this.formDataAppend(formData, "veicolo+nonConosceLaTarga", !vehicleData.knowLicensePlate);
    }
    this.formDataAppend(formData, "veicolo+meseDiImmatricolazione", this.monthIndexToName(vehicleData.vehicleRegistrationMonth));
    this.formDataAppend(formData, "veicolo+annoDiImmatricolazione", vehicleData.vehicleRegistrationYear);
    this.formDataAppend(formData, "veicolo+annoAcquisto", vehicleData.vehicleBuyYear);
    this.formDataAppend(formData, "veicolo+marca", vehicleData.vehicleBrand);
    this.formDataAppend(formData, "veicolo+modello+id", vehicleData.vehicleModel);
    this.formDataAppend(formData, "veicolo+allestimento+id", vehicleData.vehicleFitting);

    if (this.isVisible("auto")) {
      this.formDataAppend(formData, "veicolo+gancioTraino", vehicleData.vehicleTowbarMounted);
      this.formDataAppend(formData, "veicolo+alimentazione", vehicleData.vehicleFuelType);
      this.formDataAppend(formData, "utilizzoTipico", vehicleData.vehicleUse);
      if (vehicleData.vehicleFuelType !== "E") {
        this.formDataAppend(formData, "veicolo+impiantoGPL", vehicleData.vehicleGplMounted);
        this.formDataAppend(formData, "veicolo+cilindrata", vehicleData.vehicleEngineDisplacement);
      } else if (vehicleData.vehicleFuelType === "E") {
        this.formDataAppend(formData, "veicolo+cilindrata", 0);
      }
    }
    if (this.isVisible("auto")) {
      this.formDataAppend(formData, "contraente+numeroAuto", vehicleData.moreThanOneVehicle ? "dueOPiu" : "una");
    } else {
      this.formDataAppend(formData, "contraente+numeroAuto", "una");
    }
    this.formDataAppend(formData, "chilometriPerAnno", vehicleData.kilometersPerYear ? vehicleData.kilometersPerYear : 0);
    this.formDataAppend(formData, "veicolo+antifurto", vehicleData.vehicleAntitheft);
    this.formDataAppend(formData, "parcheggioNotturno", vehicleData.vehicleParkingLocation);

    // Step 1 data
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    
    formData.append("contraente+partitaIva", "");
    formData.append("contraente+denominazioneSociale", "");
    formData.append("contraente+settoreMerceologico", "");
    this.formDataAppend(formData, "contraente+sesso", step1Data.gender);
    if (!isNullOrUndefined(step1Data.dateOfBirth)) {
      let dateOfBirth = new Date(step1Data.dateOfBirth as number);
      formData.append("contraente+dataDiNascita+giorno", dateOfBirth.getDate().toString());
      formData.append("contraente+dataDiNascita+mese", dateOfBirth.toLocaleString("it-IT", { month: "long" }));
      formData.append("contraente+dataDiNascita+anno", dateOfBirth.getFullYear().toString());
    }

    formData.append("contraente+natoInItalia", `${step1Data.bornInItaly}`);
    if (step1Data.bornInItaly) {
      formData.append("contraente+luogoDiNascitaItalia+id", `${step1Data.cityOfBirth?.id}`);
    } else {
      formData.append("contraente+luogoDiNascitaEstero+id", `${step1Data.countryOfBirth?.id}`);
    }
    this.formDataAppend(formData, "contraente+statoCivile", step1Data.civilState);
    this.formDataAppend(formData, "contraente+titoloDiStudio", step1Data.degree);
    this.formDataAppend(formData, "contraente+professione", step1Data.profession);

    this.setState({ loading: true });
    fetch(Constants.SEISICURO_FORM_POST_ENDPOINT, {
      signal: this.abortController.signal,
      method: "post",
      credentials: "include",
      body: formData,
      headers: {
        "Content-Type":
          'application/x-www-form-urlencoded; charset="utf-8"' /** AUTH_REDACTED */,
      },
    })
      .then((response) => {
        if (response.ok) {
          this.saveReduxState({ step2Ok: true });
          //console.log(response);
          fetch(Constants.SEISICURO_STEP_CHECK(2), {
            signal: this.abortController.signal,
            credentials: "include",
            headers: {
              /** AUTH_REDACTED */
            },
          }).then((response) => {
            response.json().then((responseJson) => {
              if (
                Object.keys(responseJson[0]).length > 0 &&
                !(
                  Object.keys(responseJson[0])[0] === "veicolo.targa" &&
                  !this.isVisibleLicensePlate()
                )
              ) {
                this.setState({
                  errorTexts: [{
                    field: "generic",
                    msg: Object.keys(responseJson[0])
                    .map((element) => fixDirtyBackendErrorString(responseJson[0][element]))
                    .join("<\br>")
                  }],
                  submitting: false,
                }, () => {
                  this.trackSendFailure();
                  window.scrollTo(0, offsetFirstErrorField());
                });
              } else {
                this.trackSendSuccess(() => this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(3, this.vehicle)));
              }
            });
          });
        }
        else {
          //console.log(response);
          this.props.step2DataFailure(this.vehicle);
          this.setState({ loading: false }, () => {
            this.trackSendFailure();
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        //console.log(error);
        this.props.step2DataFailure(this.vehicle);
        this.setState({ loading: false }, () => {
          this.trackSendFailure();
        });
      });
  }

  /**
   * Form submit.
   *
   */
  private handleFormSubmit() {

    this.props.userInfoDataSuccess({
      direction: "forwards",
      nextStep: 3
    });
    
    this.setState({submitting: true}, () => {
      this.validation()
        .then((result: any) => {
          const {status, error_fields, error_messages} = result;
          if(status){
            this.submitToNode();
          }
          else{
            this.trackSendFailure();
            this.setState({
              submitting: false,
              errors: error_fields,
              errorTexts: error_messages ?? []
            }, () => {
              window.scrollTo(0, offsetFirstErrorField());
            });
          }
        })
    })

  }
}

const mapState = (state: any) => {
  return {
    step1Data: state.step1Data,
    step2Data: state.step2Data,
    fromMailSereno: state.step4Data.fromMailSereno
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    step2DataSuccess: (payload: any, vehicle: string) => dispatch(step2DataSuccess(payload, vehicle)),
    step2DataFailure: (vehicle: string) => dispatch(step2DataFailure(vehicle)),
  }
};

// Required to access react-router's params (i.e. ":vehicle" in App.tsx).
const Step2Guest = (props: any) => {
  const history: ReturnType<typeof useHistory> = useHistory();
  const lists: IDataList = useContext(DataList);
  
  return <Step2Component {...props} history={history} {...lists} setResourceListRequest={props.setResourceListRequest}/>
};

export default compose(connect(mapState, mapDispatch), withEventManager)(Step2Guest);
