
import * as Constants from '../utils/constants';

const QuotationColumnImage = (props: any) => {

  const {company} = props;

  return (
    <div>
      {process.env.REACT_APP_ENV !== "brainpull" &&
        <img src={Constants.SEISICURO_INSURANCE_COMPANY_LOGO_URL(company?.company_logo)} onError={(e: any) => e.target.src = Constants.SEISICURO_FALLBACK_IMAGE} alt={company?.company_name} className="company-image" />
      }
    </div>
  )
}

export default QuotationColumnImage;