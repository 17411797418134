import { IPageProps } from "./customs/AreaPersonalePageMaker";

import { Button } from "primereact/button";
import * as Constants from './utils/constants';
import { Card } from "primereact/card";
import { Component, useContext } from "react";
import UserService, { GenericException, WrongPasswordException } from "./utils/UserService";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import { Password } from "primereact/password";
import { Dialog } from "primereact/dialog";
import { Redirect } from "react-router-dom";
import StepLoaderSpinner from "./StepLoaderSpinner";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";

import '../scss/area-personale-chiudi-account.scss';
import User from "./utils/User";
interface IGestioneAccountProps extends IPageProps{
  pages: IPageDescriptor[];
}

class AreaPersonaleChiudiAccountComponent extends  Component<IGestioneAccountProps,   
{
    password: string;
    closureChoice: string;
    closureCheckbox: boolean;
    submitting: boolean;
    isReady: boolean;
    isClosed: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    errors: string[];
    errorTexts: IFeedbackText[];
}> {
    private pages: IPageDescriptor[];
    private currentPage: IPageDescriptor | undefined;
    private closureList: any[];

    constructor(props: IGestioneAccountProps){        
        super(props);

        this.pages = this.props.pages;
        this.state = {
            password: '',
            closureChoice: '',
            closureCheckbox: false,
            submitting: false,
            isReady: false,
            isClosed: false,
            isLoading: false,
            isSuccess: false,

            errors: [],
            errorTexts: [],
        }

        this.onHideHandler = this.onHideHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.closureList =[
          { label: "Non utilizzo più l'account", value: "Non utilizzo più l'account"},
          { label: "Ho un altro account", value: "Ho un altro account"},
          { label: "Voglio creare un altro account", value: "Voglio creare un altro account"},
          { label: "Problemi relativi alla sicurezza dell'account", value: "Problemi relativi alla sicurezza dell'account"},
          { label: "Problemi relativi alla privacy", value: "Problemi relativi alla privacy"},
          { label: "Non voglio fornire un motivo", value: "Non voglio fornire un motivo"},
        ];

        this.currentPage = this.pages.find((p: IPageDescriptor) => p.name === this.props.page?.name);        
    }

    render() {
        const condition = this.state.isClosed
        if (condition) {
            return <Redirect to='/preventivi/area-personale/account-rimosso'></Redirect>
        }        

        return <>
          {!this.state.isLoading
            ? <div>
                <div className="d-flex align-items-center">          
                    <h1 className="titleLev3 colorPrimaryBlue mb-0 d-block">{this.currentPage?.fullTitle ?? ""}</h1>
                </div>
                <p className="mt-2">Chiudendo il tuo account non potrai più visualizzare i preventivi già calcolati delle tue assicurazioni o acquistare nuove polizze. Non avrai più accesso alle tue informazioni e a quelle delle persone associate al tuo account e non potrai restare aggiornato sulle nostre promozioni esclusive a te dedicate.</p>
                <Card className="closure-card mb-5">
                  <div className="closure-content p-4">
                    <div className="closure-div-img">
                      <img alt="Assicurazioni online 6sicuro" src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/attenzione.svg`}/>
                    </div>
                    <div className="closure-div-p">
                      <p>
                        Il tuo account sarà disponibile ancora per i prossimi 15 giorni, dopodiché sarà eliminato.
                      </p>
                      <p>
                        Se invece deciderai di accedere di nuovo nei prossimi 15 giorni, il tuo account sarà lasciato attivo, e dovrai fare una nuova richiesta di cancellazione.
                      </p>
                    </div>
                  </div>
                </Card>
                <h4 className="titleLev5 colorPrimaryBlue mb-4 d-block">Perché vuoi chiudere il tuo account?</h4>
                <div className="col-8 mb-4">
                  <label></label>
                  <Dropdown
                    value={this.state.closureChoice}
                    options={this.closureList}
                    onChange={(selected) =>
                      this.onChangeHandler(selected.value, "closureChoice")
                    }
                    onHide={() => {}}
                    placeholder="Seleziona..."
                    optionLabel="label"
                    emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                  />
                  <ContextFeedback
                    show={this.state.errors.includes("closureChoice")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "closureChoice") ??
                      {msg: "Selezionare un'opzione", severity: "error"}
                    }
                  />
                </div>

                <div className="p-field-checkbox col-12 closure-content">
                  <Checkbox
                    inputId="closureCheckbox"
                    onChange={(selected) =>
                      this.onChangeHandler(
                        selected.checked,
                        "closureCheckbox"
                      )
                    }
                    checked={this.state.closureCheckbox}
                  />
                  <label htmlFor="closureCheckbox">
                    Sì, desidero chiudere definitivamente l'account 6sicuro e cancellare i miei dati.
                  </label>                
                  <ContextFeedback
                    show={this.state.errors.includes("closureCheckbox")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "closureCheckbox") ??
                      {msg: "Seleziona la checkbox per proseguire", severity: "error"}
                    }
                  />
                </div>
                <div className="row">
                  <div className="col-12">
                    <Button
                      id="submitButton"
                      label="Chiudi il mio account"
                      className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                      onClick={() => this.setState({isReady: true})}
                      disabled={(!this.state.closureChoice || !this.state.closureCheckbox)}
                    />
                  </div>
                </div>

                <Dialog
                  visible={this.state.isReady}
                  breakpoints={{ "960px": "75vw" }}
                  header={<h3 className="titleLev5 colorPrimaryBlue">Inserisci la tua password per completare la richiesta</h3>}
                  onHide={this.onHideHandler}  
                  modal={true}
                  resizable={false}
                  draggable={false}
                  id="accountDeactivationConfirmation"
                  >
                  <div>                
                    <FeedbackBox items={this.state.errorTexts}/>
                    <div className="row">
                      <div className="col-12 mb-2">  
                        <label htmlFor="password">Password</label>
                        <Password
                          id="password"
                          placeholder="Password"
                          name="password"
                          autoComplete="password"
                          type="password"
                          value={this.state?.password}                            
                          onChange={event => this.onChangeHandler(event.target.value, "password")}
                          toggleMask
                          feedback={false}
                        />                        
                        <ContextFeedback
                          show={this.state.errors.includes("password")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "password") ??
                            {msg: "Password non corretta", severity: "error"}
                          }
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <Button
                          id="submitButton"
                          label="Invia richiesta"
                          className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                          onClick={() => {this.onSubmit()}}
                          loading={this.state.submitting}
                          disabled={this.state.isSuccess}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            :<StepLoaderSpinner id="spinnerClosingAccount" title="Stiamo verificando la tua richiesta."/>
          }
          </>;
    }

    private onHideHandler() {
      if(this.state.isSuccess) {
        this.setState({isLoading: true})
        setTimeout(() => {            
            this.setState({isClosed: true})            
        }, 3000)
      } else {
        this.setState({isReady: false})
        this.resetValidation("password")
      }
    }

    private onChangeHandler(value: any, componentName: string) {
      this.setState({ ...this.state, [componentName]: value })
      this.resetValidation(componentName)
    }

    resetValidation(element: any) {
        this.setState({
          errors: this.state.errors.filter((item: any) => item !== element),
          errorTexts: []    
        });
        return;
    }

    private onSubmit() {
      if(this.state.password){
        this.setState({submitting: true}, () => {
          UserService.call(
            Constants.WEB_SITE_API_SERVER_URL('/users/turn-off'),
            {
              method: "POST",
              body: JSON.stringify({
                    password: this.state.password,
                    reason_why: this.state.closureChoice
                })
            },
            (response: any) => {            
              if(response?.success === false){
                // Caso di errore applicativo
                if(response.error_code === "GENERIC"){
                    throw new GenericException("Impossibile completare la richiesta");
                }

                if(response.error_code === "PASSWORD_WRONG"){
                    throw new WrongPasswordException("Password errata.");
                }

                throw new GenericException("Impossibile completare la richiesta");                              
              } else {                
                this.setState({isReady: true, isSuccess: true, password: '', submitting: false, isLoading: true})
                setTimeout(() => {            
                    this.setState({isClosed: true})
                    User.logout()
                }, 2000)
              }
          },
          (err: any) => {
            this.setState({isReady: true, isSuccess: false, password: '', submitting: false})

            if (err instanceof GenericException) {
                this.setState({ errorTexts: [err.getData()]});
            } else if (err instanceof WrongPasswordException) {
                    this.setState({errorTexts: [err.getData()], errors: ["password"]});
            } else {
              this.setState({errorTexts: [{ field: "generic", msg: "Servizio al momento non disponibile riprovare più tardi." }]});
            }           
          });
        })
      }
    }
}

const AreaPersonaleChiudiAccount = (props: IPageProps) => {

  const pages = useContext(PagesContext);
  return <AreaPersonaleChiudiAccountComponent pages={pages} {...props} />;

};

export default AreaPersonaleChiudiAccount;