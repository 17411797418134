import { useContext } from "react";
import { Link } from "react-router-dom";
import PagesContext, { IPageDescriptor } from "./HOC/useAreaPersonalePages";
import '../../scss/area-personale-menu.scss';
import * as Constants from '../utils/constants';
import React from "react";

interface IAreaPersonaleMenu{
  url: string;
  pages: IPageDescriptor[];
}

const AreaPersonaleMenuComponent = (props: IAreaPersonaleMenu) => {

  const {url, pages} = props;
  const activePageBaseUrl : string = url.split("/").slice(0, 4).join("/");
  let subPages : IPageDescriptor[] = [];
  let subPagesName : string[] = pages
    .filter((p: IPageDescriptor) => p?.submenu && p.submenu.length > 0)
    .map((p: IPageDescriptor) => [...(p.submenu as string[])])
    .flat();

  return <ul className="sideMenu">
    {pages
      .filter((p: IPageDescriptor) => (p?.inMenu ?? true) && !subPagesName.includes(p.name))
      .map((page: IPageDescriptor, index: number) => {

        const {directAction = false, url, fullTitle, component, componentParams = {}, name, icon, submenu = []} = page;

        return <li key={`${name}`} className="mt-2 mb-0">
          {!directAction
            ? <>
                <Link
                    className={`customBtn d-flex align-items-center ${activePageBaseUrl === url ? "activePage bgColorPrimaryGreen colorYellow hoverColorYellow" : "bgColorGrey colorPrimaryBlue hoverBgColorPrimaryGreen hoverColorYellow"} py-2`}
                    to={url}
                  >
                    {
                      (icon === undefined) ? '' : <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/${icon}.svg`} style={{marginRight: "1rem"}} alt=""/>
                    }
                    {fullTitle}
                </Link>
                {submenu.length > 0 &&
                  (subPages = pages.filter((page: IPageDescriptor) => submenu.includes(page.name) && (page?.inMenu ?? true))).length > 0 &&
                  <ul className="areaSubLink">
                    {subPages.map((page: IPageDescriptor) => {
                        const {directAction = false, url, name, fullTitle, component, componentParams = {}, inMenu = true} = page;

                        return !directAction
                          ? <li key={`sub-${name}`}>
                              <Link
                                  className={`customBtn d-flex align-items-center ${activePageBaseUrl === url ? "activePage bgColorPrimaryGreen colorYellow hoverColorYellow" : " bgColorGrey colorPrimaryBlue hoverBgColorPrimaryGreen hoverColorYellow"} py-2`}
                                  to={url}
                                >
                                  {
                                    (icon === undefined) ? '' : <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/${icon}.svg`} style={{marginRight: "1rem"}} alt=""/>
                                  }
                                  {fullTitle}
                              </Link>
                            </li>
                          : <li key={`sub-${name}`}>{React.createElement(component, componentParams)}</li>
                      })
                    }
                  </ul>
                }
              </>
            : React.createElement(component, componentParams)
          }
        </li>
      }
        
    )}
  </ul>
}

const AreaPersonaleMenu = (props: {url: string}) => {

  const pages : IPageDescriptor[] = useContext(PagesContext);

  return <AreaPersonaleMenuComponent {...props} pages={pages}/>
}
export default AreaPersonaleMenu;