import "../scss/area-personale-chiudi-account.scss";
import Lottie from "lottie-react";
import nipoti from "../nipoti.json";

const AccountRimossoPage = (props: any) => {
  return <>
      <div className="pr-body">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-8 col-10">
              <Lottie animationData={nipoti} loop={true} />
            </div>
            <div className="col-xxl-5 col-xl-6 col-lg-8 col-10">
              <div>
                <div className="d-flex align-items-center">
                  <h1 className="titleLev3 colorPrimaryBlue mb-0 d-block">
                    Account rimosso
                  </h1>
                </div>
                <p className="mt-3 mb-1">
                  Abbiamo ricevuto la richiesta di chiusura del tuo account.
                  <br />
                  Provvederemo a cancellare dai nostri database tutti i tuoi
                  dati.
                </p>
                <div className="fontPrimaryGreen">Il team 6sicuro</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>;
};

export default AccountRimossoPage;
