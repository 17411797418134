import { Component } from "react";
import { userInfoDataSuccess } from "../../redux/actions";
import {IFieldDescriptor, IPerson, UserInfo} from "../utils/common";
import * as Constants from "../utils/constants";
import { fixBadAddress } from '../utils/methods';
import User from "../utils/User";
import { Step3ContractorBuilder } from "../utils/PersonFieldsBuilder";
import { FeedbackBox, IFeedbackText } from "../utils/Feedback";
import '../../scss/recap-contractor.scss';
import { Divider } from "primereact/divider";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

interface IContractorRecapProps {
  contractor: IPerson;
  vehicle: any;
  onContractorUpdate?: Function;
  personCard: any;
  personCardHideFields?: string[];
  personCardRequiredFields?: any;
  userInfoDataSuccess: Function;
  userInfo: UserInfo
}

interface IContractorRecapState{
  errors: string[];
  errorTexts: IFeedbackText[];
}

class ContractorRecap extends Component<IContractorRecapProps, IContractorRecapState>{

  private fields: any;

  constructor(props: any) {
    super(props);
    this.fields = this.props?.personCardRequiredFields ?? Step3ContractorBuilder()

    this.state = {
      errors: [],
      errorTexts: [],
    }

    this.showPersonCard = this.showPersonCard.bind(this);
  }

  private validFullAddress(person: IPerson){
    return (person?.address ?? false ? true : false) &&
      (person?.postalCodeOfResidence ?? false ? true : false) &&
      (person?.cityOfResidence?.value ?? false ? true : false) &&
      (person?.addressNumber ?? false ? true : false)
  }

  componentDidMount(): void {

    User.validatePerson(this.props.contractor, Object.entries(this.fields).map(([, value]) => value) as IFieldDescriptor[])
      .then(result => {

        const {error_fields: errors, error_messages: errorTexts} = result;
        
        if(this.state.errors !== errors){

          this.setState({
            errors: errors,
            errorTexts: errorTexts,
          }, () => {
            if(this.state.errors.length > 0){
              this.showPersonCard(this.props.contractor)
            }
          })
        }
      })
  }

  getSnapshotBeforeUpdate(prevProps: Readonly<IContractorRecapProps>, prevState: Readonly<IContractorRecapState>) {
    if(JSON.stringify(prevProps.contractor) !== JSON.stringify(this.props.contractor)){
      return {error_recalc: true}
    }
    return null;
  }

  componentDidUpdate(prevProps: Readonly<IContractorRecapProps>, prevState: Readonly<IContractorRecapState>, snapshot?: any): void {
    if(snapshot?.error_recalc){
      User.validatePerson(this.props.contractor, Object.entries(this.fields).map(([, value]) => value) as IFieldDescriptor[])
        .then(result => {

          const {error_fields: errors, error_messages: errorTexts} = result;

          if(this.state.errors !== errors){
            this.setState({
              errors: errors,
              errorTexts: errorTexts
            }, () => {
              if(this.state.errors.length > 0){
                this.showPersonCard(this.props.contractor)
              }
            })
          }
        })
    }
  }

  private showPersonCard(person: IPerson){
    this.props.personCard?.current.show({
      person: person,
      header: <h3 className="colorPrimaryGreen titleLev4">Dettaglio utente {User.getPersonSafeName(person)}</h3>,
      errors: this.state.errors,
      errorTexts: this.state.errorTexts,
      showControl: false,
      editMode: true,
      requiredFields: this.fields,
      hideFields: this.props.personCardHideFields || [],
      successCallback: this.props.onContractorUpdate
    })
  }

  render(){
    const person : IPerson = this.props.contractor;
    const validationFailed: boolean = this.state.errors.length > 0 ?? false;
    const fields = this.props.personCardRequiredFields ?? Step3ContractorBuilder();

    let age: number | undefined = undefined;
    if(person?.dateOfBirth){
      const timeDiff = Math.abs(Date.now() - person.dateOfBirth.getTime());
      age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
    }

    // Età del figlio minore
    // {(person?.children?.length === 2 || (person?.children?.length === 1 && person.children[0] === 1)) &&
    //   <><label><span className="text-bold">Età del figlio più piccolo</span>: {person.youngestChild}</label><br/></>
    //   }

    return <>
      <div className="recapContractor row mb-2">
        <div className="col-xxl-6 col-12">
          { /*<label><span className="text-bold">Nome</span>: {person.name}</label><br/>
          <label><span className="text-bold">Cognome</span>: {person.surname}</label><br/>*/ }
          {age && <label className="text-bold">{age} anni, {person.civilState}</label>}
          
          {
            this.validFullAddress(person) &&
              <label>
                <span className="text-bold">Residente in:</span> {`${fixBadAddress(person.address)}, ${person.addressNumber}, ${person.postalCodeOfResidence}, ${person.cityOfResidence?.value}`}
              </label>
          }
        </div>
        <div className="col-xxl-6 col-12">
          <label>
            {person?.children?.length === 1 ?
              (person.children[0] === 0 ?
                "Senza figli" :
                (person.children[0] === 1 ?
                "Figli minorenni" :
                "Figli maggiorenni"
                )
              ) :
              (person?.children?.length === 2 ? "Figli minorenni e maggiorenni" : "")}
          </label>
          <label>
            <span className="text-bold">Patente</span> {person.italianDrivingLicense ? "italiana" : "non italiana"}{person.drivingLicenseAge ?? false ? ` e conseguita a ${person.drivingLicenseAge} anni` : "" }
          </label>
        </div>
      </div>

        {validationFailed &&
          <div className="row mb-4">
            <div className="col-12 mt-2 mb-2">
              <FeedbackBox
                filter={{severity: "warn", field: "generic"}}
                items={[{
                  field: "generic",
                  msg: `I dati del contraente sono incompleti, inseriscili prima di procedere. Dati mancanti: ${this.state.errors
                    .map((field: string) => fields[field]?.notification_label ?? fields[field]?.label_human_readable ?? field)
                    .join(", ")}`,
                  severity: "warn"
                }]}
              />
            </div>
          </div>
        }

        <div className="row mb-4">
          {validationFailed
            ? <>
              <div className="col-xxl-4 col-lg-5 col-12">
                  <b className="aLike" onClick={() => this.showPersonCard(person)}>Inserisci i dati mancanti</b>
                </div>

                <div className="col-xxl-4 col-lg-2 col-12">
                  <Divider className="my-3" align="center">
                    <div className="inline-flex align-items-center">
                      <small className="mt-0" style={{color: "#545D69"}}>oppure</small>
                    </div>
                  </Divider>
                </div>

                <div className="col-xxl-4 col-lg-5 col-12 text-xxl-end">
                  <Link
                    className="aLike"
                    to={Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.props.vehicle)}
                    onClick={(evt) => {this.props.userInfoDataSuccess({
                      direction: "backwards",
                      nextStep: 2
                    })}}
                  >Seleziona un altro contraente</Link>
                </div>
              </>
            : <div className="col-12">
                <Link
                  className="aLike"
                  to={Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.props.vehicle)}
                  onClick={(evt) => {this.props.userInfoDataSuccess({
                    direction: "backwards",
                    nextStep: 2
                  })}}
                >Seleziona un altro contraente</Link>
              </div>
          }
      </div>
    </>
  }

}

const mapState = (state: any) => {
  return {
    userInfo: state.userInfoData.user,
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any, vehicle: string) => dispatch(userInfoDataSuccess(payload, vehicle))
  }
};

export default connect(mapState, mapDispatch)(ContractorRecap);