import { fetchList } from "./methods";
import * as Constants from './constants';
import PersistedStore from '../../redux/persistedStore';
import { StepDataDualState } from "../../redux/reducers";
import { Step4Data } from "./common";

/**
 * Handles all QuotServlet requests
 */
export default class QuotationService {
  private static requestCounter = 1;
  private static callbackFunction: Function;
  private static wrrantiesDoneCallBack: Function;
  private static quotations: any[] = [];
  private static noQuotations: any[] = [];
  public static warranties = '1';
  public static hideBlackBox = true;

  private static readonly maxRequests = 6;
  private static readonly percentPerCall = 100 / QuotationService.maxRequests;
  private static readonly primavolta = true;
  private static readonly ups = false;
  

  /**
   * Loads the request counter from storage: this is useful in case it was cleared by a page refresh.
   */
  public static loadRequestCounterFromStorage () {

    const store = PersistedStore.getDefaultStore().store.getState();
    const vehicle: string = store.userInfoData.user.currentVehicleSelector;
    const step4Data: Step4Data = store.step4Data[vehicle as keyof StepDataDualState] as Step4Data;

    let loadedRequestCounter = step4Data.requestCounter;
    if (loadedRequestCounter && !isNaN(loadedRequestCounter)) {
      this.requestCounter = loadedRequestCounter;
    }
  }

  /**
   * Sets the QuotServlet request success callback function
   * 
   * @param callbackFunction the callback function
   */
  public static setCallback (callbackFunction: Function) {
    this.callbackFunction = callbackFunction;
  }

  /**
   * Imposta la funzione di callback, da chiamare al termine del processo di quotazione
   * @param callbackFunction la funzione da richiamare
   */
  public static setWarrantiesDoneCallBack (callbackFunction: Function) {
    this.wrrantiesDoneCallBack = callbackFunction;
  }

  /**
   * Imposta le garanzie accessorie da richiedere in fase di quotazione (basandosi su quelle lette nello stato dello step4)
   * @param selectedOptions l'array delle garanzie selezionate
   */
  public static updateWarranties (selectedOptions: any[] | undefined) {
    // let newWarrantiesString: string = '1';
    let newWarrantiesArr: string[] = ["1"];

    if (selectedOptions !== undefined) {
      // selectedOptions.forEach(o => {
      //   newWarrantiesString += "," + o.value;
      // });

      selectedOptions.forEach((option: {value: string, key: string}) => {
        !newWarrantiesArr.some((value: string) => value === option.value) && newWarrantiesArr.push(option.value);
      })
    }
    
    // this.warranties = newWarrantiesString;
    this.warranties = newWarrantiesArr.join(",");
  }

  /**
   * Reimposta le garanzie accessorie al valore di default
   */
  public static resetWarranties () {
    this.warranties = '1';
  }
  
  /**
   * Funzione che reimposta il numero delle richieste inviate al BE
   */
  public static resetRequestCounter () {
    this.requestCounter = 1;
  }

  /**
   * Executes a QuotServlet request
   * 
   * @param prevId prevId of the request
   * @param calcoloId calcoloId of the request
   * @param prodotto prodotto of the request
   * @param ignoreCounter whether to ignore the maximum request counter limit
   * @returns 
   */
  public static executeRequest (prevId: string, calcoloId: string, prodotto: string, ignoreCounter: boolean = false, signal?: any) {
    if (!ignoreCounter && this.requestCounter === this.maxRequests + 1) {
      if (this.wrrantiesDoneCallBack !== undefined) {
        this.wrrantiesDoneCallBack();
      }
      return;
    }

    let requestTime = new Date().getTime();

    fetchList(
      Constants.SEISICURO_GET_QUOTE_INFORMATION(this.warranties, this.primavolta, prevId, calcoloId, prodotto, this.ups, this.hideBlackBox, Math.round(+new Date() / 1000)), 
      (result: any) => {
        if(!ignoreCounter) {
          this.requestCounter++;
        }

        // let elapsed = new Date().getTime() - requestTime;

        // if (
        //   this.callbackFunction !== undefined && result !== undefined && result.quotations !== undefined && result.quotations.length > 0 && result.noquotazioni?.quotations.length > 0
        // ) {
        //   this.quotations = this.computeTotalPrices(result.quotations);
        //   this.noQuotations = result.noquotazioni.quotations;
        //   setTimeout(() => {
        //     this.callbackFunction(this.quotations, this.noQuotations, Math.round(this.percentPerCall * (this.requestCounter - 1)), this.requestCounter);
        //   }, (elapsed < Constants.minRequestMs) ? (Constants.minRequestMs - elapsed) : 0);
        // }
        // else if (this.callbackFunction !== undefined && result !== undefined && result.quotations !== undefined && result.quotations.length > 0) {
        //   this.quotations = this.computeTotalPrices(result.quotations);
        //   setTimeout(() => {
        //     this.callbackFunction(this.quotations, [], Math.round(this.percentPerCall * (this.requestCounter - 1)), this.requestCounter);
        //   }, (elapsed < Constants.minRequestMs) ? (Constants.minRequestMs - elapsed) : 0);
        // }
        // else if (this.callbackFunction !== undefined && result !== undefined && result.noquotazioni?.quotations.length > 0) {
        //   this.noQuotations = result.noquotazioni.quotations;
        //   setTimeout(() => {
        //     this.callbackFunction([], this.noQuotations, Math.round(this.percentPerCall * (this.requestCounter - 1)), this.requestCounter);
        //   }, (elapsed < Constants.minRequestMs) ? (Constants.minRequestMs - elapsed) : 0);
        // }
        // else if (this.callbackFunction !== undefined && result !== undefined && result.quotations === undefined) {
        //   setTimeout(() => {
        //     this.callbackFunction([], [], Math.round(this.percentPerCall * (this.requestCounter - 1)), this.requestCounter);
        //   }, (elapsed < Constants.minRequestMs) ? (Constants.minRequestMs - elapsed) : 0);
        // }

        if(this.callbackFunction !== undefined && result !== undefined){
          const elapsed = new Date().getTime() - requestTime;
          const restTime: number = (elapsed < Constants.minRequestMs) ? (Constants.minRequestMs - elapsed) : 0;
          
          let callbackQuotation: any[] = [];
          let callbackNoQuotation: any[] = [];

          if(result?.quotations?.length > 0){
            this.quotations = this.computeTotalPrices(result.quotations);
            callbackQuotation = this.quotations;
          }
          
          if(result?.noquotazioni?.quotations.length > 0){
            this.noQuotations = result.noquotazioni.quotations;
            callbackNoQuotation = this.noQuotations;
          }

          setTimeout(() => this.callbackFunction(
              callbackQuotation,
              callbackNoQuotation,
              Math.round(this.percentPerCall * (this.requestCounter - 1)),
              this.requestCounter
            ),
            restTime);

        }

      }, undefined, undefined, signal);
  }

  /**
   * Ritorna il numero totale di richieste da eseguire
   * @returns il numero totale (il massimo) di richieste da eseguire
   */
  public static getMaxRequestsNumber () {
    return this.maxRequests;
  }
  
  /**
   * Executes a QuotServlet request for Warranties
   *
   * @param prevId prevId of the request
   * @param calcoloId calcoloId of the request
   * @param prodotto prodotto of the request
   * @param ignoreCounter whether to ignore the maximum request counter limit
   * @param warrantiesTimeOut timer ID to clear
   * @returns 
   */
  public static executeWarrantiesRequest (prevId: string, calcoloId: string, prodotto: string, ignoreCounter: boolean = false, warrantiesTimeOut: any, signal?: any) {
    if ((!ignoreCounter && this.requestCounter > this.maxRequests) || this.callbackFunction === undefined) {
      return;
    }

    fetchList(
      Constants.SEISICURO_GET_ADDITIONALQUOTE_INFORMATION(this.warranties, false, prevId, calcoloId, prodotto, this.ups, this.hideBlackBox, Math.round(+new Date() / 1000)),
      (result: any) => {

        if (!ignoreCounter) {
          this.requestCounter++;
        }

        // if (
        //   result !== undefined && result.quotations !== undefined && result.quotations.length > 0 &&
        //   result !== undefined && result.noquotazioni?.quotations.length > 0
        // ) {
        //   this.quotations = this.computeTotalPrices(result.quotations);
        //   this.noQuotations = result.noquotazioni.quotations;

        //   this.callbackFunction(this.quotations, this.noQuotations, Math.round(this.percentPerCall * (this.requestCounter - 1)), this.requestCounter);
        // } 
        // else if (result !== undefined && result.quotations !== undefined && result.quotations.length > 0) {
        //   this.quotations = this.computeTotalPrices(result.quotations);

        //   this.callbackFunction(this.quotations, [], Math.round(this.percentPerCall * (this.requestCounter - 1)), this.requestCounter);
        // } 
        // else if (result !== undefined && result.noquotazioni?.quotations.length > 0) {
        //   this.noQuotations = result.noquotazioni.quotations;

        //   this.callbackFunction([], this.noQuotations, Math.round(this.percentPerCall * (this.requestCounter - 1)), this.requestCounter);
        // } 
        // else if (result.quotations === undefined || (result.quotations !== undefined && result.quotations.length === 0)) {
        //   this.callbackFunction([], [], Math.round(this.percentPerCall * (this.requestCounter - 1)), this.requestCounter);
        // }


        if(this.callbackFunction !== undefined && result !== undefined){
          let callbackQuotation: any[] = [];
          let callbackNoQuotation: any[] = [];

          if(result?.quotations?.length > 0){
            this.quotations = this.computeTotalPrices(result.quotations);
            callbackQuotation = this.quotations;
          }
          
          if(result?.noquotazioni?.quotations.length > 0){
            this.noQuotations = result.noquotazioni.quotations;
            callbackNoQuotation = this.noQuotations;
          }

          this.callbackFunction(
            callbackQuotation,
            callbackNoQuotation,
            Math.round(this.percentPerCall * (this.requestCounter - 1)),
            this.requestCounter
          );

        }

        warrantiesTimeOut = undefined;
      }, undefined, undefined, signal
    );
  }

  /**
   * Carica le quotazioni "salvate" di un preventivo identificato da padre, calcolo, prodotto
   * @param prevId il preventivo padre 
   * @param calcoloId il calcolo id
   * @param prodotto il prodotto (auto / moto)
   * @param warrantiesString le garanzie accessorie salvate
   */
  public static loadSavedQuotatinoRequest (prevId: string, calcoloId: string, prodotto: string, warrantiesString: string, signal?: any) {
    fetchList(
      Constants.SEISICURO_GET_ADDITIONALQUOTE_INFORMATION(warrantiesString, false, prevId, calcoloId, prodotto, this.ups, this.hideBlackBox, Math.round(+new Date() / 1000)),
      (result: any) => {
        this.quotations = (result !== undefined && result.quotations !== undefined) ? this.computeTotalPrices(result.quotations) : [];
        this.noQuotations = (result !== undefined && result.noquotazioni !== undefined && result.noquotazioni.quotations !== undefined) ? result.noquotazioni.quotations : [];

        // fix aggiorno le "garanzie spuntate"
        this.warranties = warrantiesString;

        this.callbackFunction(this.quotations, this.noQuotations);
      }, undefined, undefined, signal
    );
  }

  /**
   * Handles computation of the total prices of all received quotations, remove duplicates "Imposte" from items
   * 
   * @param quotations the original quotations list
   * @returns {any[]} a new quotations list, which includes all computed total prices in the "total_price" field
   */
  private static computeTotalPrices (quotations: any[]): any[] {
    let fixedQuotations = [];

    // bonifico: ci deve essere una sola imposta
    for (let i = 0; i < quotations.length; i++) {
      let q = quotations[i];
      let fixedItems = [];
      let totalImposte = 0;
      let totaleSSN = 0;

      for (let j = 0; j < q.items.length; j++) {
        if (q.items[j].name === "SSN") {
          totaleSSN += q.items[j].amount;
        } else if (q.items[j].name === "Imposte") {
          totalImposte += q.items[j].amount;
        } else {
          fixedItems.push(q.items[j]);
        }
      }

      fixedItems.push({
        amount: totalImposte,
        name: "Imposte",
        type: "2"
      });
      fixedItems.push({
        amount: totaleSSN,
        name: "SSN",
        type: "3"
      });

      q.items = fixedItems;

      fixedQuotations.push(q);
    }

    // calcolo il prezzo totale
    let newQuotations = [...fixedQuotations];

    for (let j = 0; j < quotations.length; j++) {
      let totalPrice = 0;

      for (let i = 0; i < quotations[j].items.length; i++) {
        totalPrice += isNaN(quotations[j].items[i].amount) ? 0 : quotations[j].items[i].amount;
      }

      newQuotations[j].total_price = totalPrice;
    }

    // items.name

    return newQuotations;
  }
}
