import { Component, ReactNode } from "react"

interface CaptchaProps {
    isManaged: boolean

    successCallBack: Function,
    setLoadingCallBack: Function
}

interface CaptchaState {
    widgetId: string | undefined
}

const invisibleSiteKey = '0x4AAAAAAAQjsjl2cpYfQ3dh' // invisibile
const managedSiteKey = '0x4AAAAAAAQnk1dfmWZbEbFn' // mamaged

declare global {
  interface Window {
    renderTurnstile: Function

    turnstile: any

    turnstileSuccess: Function
    turnstileSetLoading: Function
  }
}

class CaptchaComponent extends Component<CaptchaProps, CaptchaState> {

    constructor(props: any){
        super(props)

        this.state = {
            widgetId: undefined
        }

        // bind delle funzioni
        this.success = this.success.bind(this)
        this.setLoading = this.setLoading.bind(this)

        // rendo le funzioni globali
        window.turnstileSuccess = this.success
        window.turnstileSetLoading = this.setLoading
    }

    componentDidUpdate(prevProps: Readonly<CaptchaProps>, prevState: Readonly<CaptchaState>, snapshot?: any): void {
        if (prevProps.isManaged != this.props.isManaged) {
            // ogni volta che ridisegno il component decido se renderlo visibile o no
            const renderTurnstile = window.renderTurnstile

            this.setLoading()

            const key = (this.props.isManaged) 
                ? managedSiteKey
                : invisibleSiteKey

            this.setState({
                widgetId: renderTurnstile ( key )
            })
        }
    }

    componentDidMount(): void {
        // al primo caricamento del component renderizzo il captcha in modalità invisibile
        const renderTurnstile = window.renderTurnstile

        if (renderTurnstile && !this.props.isManaged) {
            this.setState({
                widgetId: renderTurnstile ( invisibleSiteKey )
            })
        }

        // ma voglio comunque che il submit della form sia disabilitato finchè no n ricevo il token
        this.props.setLoadingCallBack()

    }

    render(): ReactNode {
        const turnstile = window.turnstile

        if (
            turnstile !== undefined && this.state.widgetId !== undefined &&
            turnstile.isExpired !== undefined && 
            turnstile.reset !== undefined &&
            turnstile.isExpired(this.state.widgetId) 
        ) {
            turnstile.reset(this.state.widgetId)
        }

        return (
            <div 
                id='turnstile-captcha'
                className='mt-4 text-center'

                data-size='normal'
                data-theme='light'
                data-language='it-IT'

                data-callback='turnstileSuccess'
                data-expired-callback='turnstileSetLoading'
                data-before-interactive-callback='turnstileSetLoading'

                data-sitekey={
                    this.props.isManaged
                        ? managedSiteKey
                        : invisibleSiteKey
                } 
            />
        )
    }

    /**
     * Da invocare quando il token deve essere validato
     * @param token il token da validare
     */
    private success (token: any) {
        // il token va inviato al Nodo per poter essere validato
        this.props.successCallBack(token)
    }

    private setLoading () {
        if (this.props.isManaged) {
            this.props.setLoadingCallBack()
        }
    }

}

export default CaptchaComponent