import { Component } from 'react';
import * as Constants from './utils/constants';
import { Step1Data, Step2Data, Step3Data, Step4Data } from './utils/common';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { formatDateAdform, getInsuranceName, isNullOrUndefined, encryptEmail, formataDateToString, priceFormatter } from './utils/methods';
import CookieService from './utils/CookieService';
import User from "./utils/User";
import { connect } from 'react-redux';
import { StepDataDualState } from '../redux/reducers';
import withEventManager from './customs/HOC/withEventManager';
import { compose } from 'ramda';
import { IWithEventManager } from './customs/HOC/EventManager';

interface IStep5Props extends IWithEventManager{
    step1Data: StepDataDualState;
    step2Data: StepDataDualState;
    step3Data: StepDataDualState;
    step4Data: StepDataDualState;
    location: any;
    history: ReturnType<typeof useHistory>;
}

interface IStep5State{
    scriptLoad: boolean;
    fbMounted: boolean
}

class Step5Component extends Component<IStep5Props, IStep5State> {
    private vehicle: any;
    private cid: string = "";
    private cid_picture: string = "";
    private price: number = 0;
    private warranties: string = "";
    private warrantiesUpselling: string = "";
    private warrantiesIncluded: string = "";
    private idPacchetto: string = "";

    constructor(props: any) {
        super(props);
        this.vehicle = props.params.vehicle;

        const query = new URLSearchParams(this.props.location.search);
        this.cid = !isNullOrUndefined(query.get("cid"), true) ? query.get("cid") + "" : "";
        this.cid_picture = query.get("cid") + "_big.jpg";
        this.price = Number(query.get("price"));
        this.warranties = !isNullOrUndefined(query.get("w"), true) ? query.get("w") + "" : "";
        this.warrantiesUpselling = !isNullOrUndefined(query.get("w_u"), true) ? query.get("w_u") + "" : "";
        this.warrantiesIncluded = !isNullOrUndefined(query.get("w_i"), true) ? query.get("w_i") + "" : "";
        this.idPacchetto = !isNullOrUndefined(query.get("pack"), true) ? query.get("pack") + "" : "";

        this.state = { scriptLoad: false, fbMounted: false};
    }

    componentDidMount() {

        const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
        const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;



        fetch(
            Constants.SEISICURO_AJAX_QUOTATION_SAVE_ENDPOINT(
                this.vehicle,
                step2Data.vehicleLicensePlateNumber !== undefined
                    ? step2Data.vehicleLicensePlateNumber
                    : "",
                this.cid,
                this.warranties,
                this.warrantiesUpselling,
                this.warrantiesIncluded,
                step4Data.prevId !== undefined
                    ? step4Data.prevId
                    : "",
                step4Data.calcoloId !== undefined
                    ? step4Data.calcoloId
                    : "",
                this.idPacchetto,
                Math.round(+new Date() / 1000)
            ),
            {headers: {/** AUTH_REDACTED */}}
        )
            .then((response) => response.json())
            .then((result) => {
                if(!isNullOrUndefined(result)) {
                    if(result.risultato === false) {
                        // Failure
                        this.trackSendFailure(() => {
                            window.location.href = Constants.SEISICURO_AJAX_QUOTATION_SAVE_ERROR_ENDPOINT(this.vehicle, this.cid, step4Data.prevId !== undefined ? step4Data.prevId : "");
                        });
                    } else {
                        // Success
                        this.trackSendSuccess(() => {
                            if (result.url.trim() !== "") {
                                if (result.url.startsWith("/preventivi/RedirectToLink")) {
                                    window.location.href = Constants.SEISICURO_SERVER_URL + result.url;

                                    // this.props.history.push();
                                } else {
                                    window.location.href = result.url;
                                }
                            }
                        });
                    }
                }
            });
    }

    render() {
        return (
            <div className="container step5" id="step5-attesa">

                <div className="row from6sicuro2company">
                    <div className="col-md-5 white-box">
                        {/* 
                        rimappato con url locale 
                        https://static.6sicuro-cdn.it/static/seisicurotest/contenuti_statici_new/img/6sicuro-logo-comparatore.jpg
                        https://static.6sicuro-cdn.it/static/seisicurotest/img/6sicuro-logo-comparatore.jpg
                        */}
                        <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/6sicuro-logo-comparatore.jpg`} alt="Logo 6sicuro" width="200" />
                    </div>
                    <div className="col-md-2"><i className="pi pi-arrow-right"></i></div>
                    <div className="col-md-5 white-box">
                        <img src={`${Constants.SEISICURO_INSURANCE_COMPANY_LOGO_URL(this.cid_picture)}`} className="col-left" alt="Logo Compagnia" width="200" />
                    </div>
                </div>

                <div className="container row collegamento">
                    <div className="col-md-12 ci-stiamo-collegando">
                        <h3 className="titleLev4 colorPrimaryBlue">Ci stiamo collegando al sito di {getInsuranceName(this.cid)}</h3>
                    </div>
                    <div className="col-md-12 non-chiudere"> Non chiudere questa finestra, attendi qualche secondo </div>
                    <div className="col-md-12 titleLev5 colorGreen">
                        <p className="mt-3">Premio totale: {priceFormatter(this.price)}</p>
                    </div>
                </div>

                <div className="row prossimi-passi white-box">
                    <div className="col-12">
                        <h3 className="titleLev5 colorPrimaryBlue">Prossimi Passi</h3>
                        <ul className="mt-3">
                            <li>Troverai <b>il tuo preventivo</b> senza dover inserire altri dati</li>
                            <li>Potrai <b>modificare</b> il preventivo e procedere all'acquisto</li>
                            <li>Il servizio di 6sicuro.it è imparziale e <b>gratuito</b></li>
                        </ul>

                        <hr />

                        <div className="testo"><strong>Tra un ora circa ti invieremo una mail</strong> per poter recuperare tutti i tuoi <strong>preventivi salvati</strong>. <strong>Puoi</strong> quindi decidere se <strong>acquistare la polizza subito</strong> o in un secondo momento utilizzando i dati che ti saranno inviati.</div>
                        <div className="testo"><strong>Assicurati di ricevere le email di 6sicuro</strong> per ricevere gli aggiornamenti relativi a questo preventivo!
                        </div>

                        <ol>
                            <li><strong>Aggiungi</strong> il <strong>contatto</strong> di <strong>6sicuro.it</strong> alla tua rubrica da <a className="aLike" href={Constants.SEISICURO_CONTACT_URL}>questo link</a></li>
                            <li>Cercare tra le tue <strong>email</strong> quelle <strong>ricevute</strong> da <strong>6sicuro</strong> e <strong>trascinale</strong> nella tua <strong>cartella Principale</strong>.</li>
                            <li>Se trovi mail provenienti da 6sicuro.it nella tua cartella di spam <strong>segnalale</strong> come <strong>NOSPAM</strong>.</li>
                            <li>Se usi <strong>Gmail</strong> clicca sulla <strong>freccia gialla</strong> vicino alla stellina per indicare <strong>il messaggio</strong> come <strong>importante</strong>.</li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }

    // ------------------------------------------------------------------------------------------------------------------------------

  private getDataToTrack(){
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    return {
      prodotto: this.vehicle,
      gender: step1Data.gender,
      dataNascita: formatDateAdform(dateOfBirth),
      titoloStudio: step1Data.degree,
      professione: step1Data.profession,
      dataImmatricolazione: `${
        step2Data.vehicleRegistrationMonth !== undefined &&
        step2Data.vehicleRegistrationMonth < 10
          ? `0${step2Data.vehicleRegistrationMonth}`
          : step2Data.vehicleRegistrationMonth
      }/${step2Data.vehicleRegistrationYear}`,
      annoAcquisto: step2Data.vehicleBuyYear,
      veichleBrand: step2Data.vehicleBrandLabel,
      veichleModel: step2Data.vehicleModelLabel,
      emailKript: encryptEmail(step3Data.contractorEmail),
      email: step3Data.contractorEmail,
      targa: step2Data.vehicleLicensePlateNumber,
      residenza: step3Data.contractorCityOfResidence?.id?.split('(')[0].trim(),
      provincia: step3Data.contractorCityOfResidence?.id.split('(')[1].replace(')', ''),
      compagniaAssicurativa: step3Data.currentInsuranceCompany,
      calcoloId: step4Data.calcoloId,
      decorrenzaAssicurazione: formataDateToString(step3Data.newInsuranceStart),
      actualInsuranceCompany: step3Data.currentInsuranceCompany,
      bersani: step3Data.bersaniDecree === "si" ? '1' : '0',
      telefono: step3Data.contractorPhoneNumber,
      prezzoPolizzaSalvata: this.price.toFixed(2),
      name: step3Data.contractorName,
      surname: step3Data.contractorSurname,
      mktgAssicurativo: step3Data.acceptGdprPrivacyPolicy102,
      mktgNonAssicurativo: step3Data.acceptGdprPrivacyPolicy102,
      newsletter: step3Data.acceptGdprPrivacyPolicy22,
      profilazione: step3Data.acceptGdprPrivacyPolicy3,
      cessioneAssicurativo: step3Data.acceptGdprPrivacyPolicy104,
      cessioneNonAssicurativo: step3Data.acceptGdprPrivacyPolicy104,
      gdpr: step3Data.acceptSeiSicuroPrivacyPolicy
    }
  }

    /**
     * Trak tag-manager: notifica errore
     */
    private trackSendFailure(callbackFunction: Function) {
        this.props.trackEvent?.({
            name: "manualsaveError",
            data: this.getDataToTrack(),
            callback: callbackFunction
        });
    }

    /**
     * Trak tag-manager: notifica successo
     */
    private trackSendSuccess(callbackFunction: Function) {

        this.props.trackEvent?.({
            name: "manualsaveSuccess",
            data: this.getDataToTrack(),
            callback: callbackFunction
        });
    }
}

// Required to access react-router's params (i.e. ":vehicle" in App.tsx).
const Step5 = (props: any) => {
    const history: ReturnType<typeof useHistory> = useHistory();

    return <Step5Component {...props} params={useParams()} history={history}/>
}

const mapState = (state: any) => {
    return {
        step1Data: state.step1Data,
        step2Data: state.step2Data,
        step3Data: state.step3Data,
        step4Data: state.step4Data,
    };
}

export default compose(connect(mapState), withEventManager)(withRouter(Step5));
