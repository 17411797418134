import { Component, useContext } from "react";
import VehicleListItemLabel from "./VehicleListItemLabel";
import PersonListItemLabel from "./PersonListItemLabel";
import EmptyListItemLabel from "./EmptyListItemLabel";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import React from "react";


function ContextAwareToggle(props: {children: [] | string | JSX.Element, eventKey: string, callback?: Function}): JSX.Element {
  const {children, eventKey, callback} = props;
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton( eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div onClick={(e: any) => { if(!isCurrentEventKey) decoratedOnClick(e); }}>
      {children}
    </div>
  );
}

export default class RadioButtonListItemTextInfo extends Component<{
  id: string,
  type?: string,
  component?: (props: any) => JSX.Element;
  data: any,
  selected: boolean,
  action?: any
},{}>{

  render(){

    const props = {
      parentId: this.props.id,
      selected: this.props.selected,
      data: this.props.data,
      action: this.props?.action,
    };

    return <>
    <ContextAwareToggle eventKey={`${this.props.id}`}>
      <label htmlFor={this.props.id}>
        {this.props?.component
          ? React.createElement(this.props.component, {...props})
          : <>
              {this.props?.type === "vehicle" &&
                <VehicleListItemLabel {...props}/>
              }
              {this.props?.type === "person" &&
                <PersonListItemLabel {...props}/>
              }
              {this.props?.type === "empty" &&
                <EmptyListItemLabel {...props}/>
              }
            </>
        }
      </label>
    </ContextAwareToggle>
    </>
  }

}