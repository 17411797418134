import { Card } from "primereact/card";
import { connect } from "react-redux";
import * as Constants from "../utils/constants";

const Info6Sicuro = (props: {vehicle: string}) => {

  const vehicle: string = props.vehicle;

  return <>
    <Card className="pr-form mt-5">
      <div className="p-fluid p-4 px-lg-5 py-lg-5">
        <span className="d-block titleLev5">Assicurazione Auto Online: calcola il Preventivo in pochi clic</span>
        <div className="registrationPrivacy mt-3">
          <p className="mb-0">Con 6sicuro confrontare e acquistare il preventivo della tua assicurazione {vehicle} è semplice e veloce. Calcola il preventivo della tua assicurazione {vehicle} e acquista la tua Polizza RC auto direttamente online. In pochi clic, potrai visualizzare le tariffe delle compagnie assicurative online. Tutti i campi che ti chiederemo sono utili per definire, nel modo più preciso possibile, le quotazioni assicurative con il miglior rapporto qualità/prezzo e più adatte alle tue esigenze: targa veicolo, data di immatricolazione e acquisto veicolo, km percorsi in un anno, sistema di antifurto, utilizzo {vehicle === "auto" ? `dell'${vehicle}` : `della ${vehicle}`}, dove parcheggi di solito, sei ha montato il gancio di traino, il numero di {vehicle} presenti nel nucleo familiare, se possiedi una patente italiana, decorrenza nuova polizza e situazione assicurativa.</p>
        </div>
      </div>
    </Card>


    <Card className="pr-form mt-5">
      <div className="p-fluid p-4 px-lg-5 py-lg-5">
        <span className="d-block titleLev5">Offerte Assicurative Confrontate</span>
        <div className="registrationPrivacy mt-3">
          <p className="mb-0">Quota di mercato comparata: 10,81% delle assicurazioni (Fonte: ANIA - 2020).<br/><b>14 servizi di assicurazioni online confrontati:</b><br/>Allianz Direct, Conte, Conte Sat, Genertel, Genertel Quality Driver, GenialClick, Linear, Linear Autobox, Prima.it, Quixa, Quixa Black Box, Verti, Zurich Connect.</p>
        </div>
      </div>
    </Card>


    {!Constants.ENABLE_NO_CARD &&
      <Card className="pr-form mt-5">
        <div className="p-fluid p-4 px-lg-5 py-lg-5">
          <span className="d-block titleLev5">Risarcimento Diretto</span>
          <div className="registrationPrivacy mt-3">
            <p className="mb-0">Il risarcimento diretto (CARD) è una procedura tramite cui è possibile richiedere il rimborso dei danni direttamente alla propria compagnia, anziché rivolgersi alla compagnia della controparte, ad alcune condizioni. Le polizze acquistate con Prima.it e sottoscritte dalla compagnia iptiQ Germania non consentono l’accesso al Risarcimento Diretto quindi, in caso di sinistro, il danneggiato potrà sempre e solo rivolgersi alla Compagnia della controparte per ottenere il risarcimento dei danni.</p>
          </div>
        </div>
      </Card>
    }

    <Card className="pr-form mt-5">
      <div className="p-fluid p-4 px-lg-5 py-lg-5">
        <span className="d-block titleLev5">Garanzie accessorie</span>
        <div className="registrationPrivacy mt-3">
          <div className="pr-card-body p-m-0">Personalizza la tua polizza assicurativa con le <a target="_blank" rel="noreferrer" href="https://www.6sicuro.it/garanzie-accessorie">Garanzie Accessorie</a> per maggiore tutela e protezione dai rischi.<ol className="mb-0"><li><a target="_blank" rel="noreferrer" href="https://www.6sicuro.it/garanzie-accessorie/polizza-infortuni-conducente/">Infortuni del conducente</a></li><li><a target="_blank" rel="noreferrer" href="https://www.6sicuro.it/garanzie-accessorie/polizza-assistenza-stradale/">Assistenza stradale</a></li><li><a target="_blank" rel="noreferrer" href="https://www.6sicuro.it/garanzie-accessorie/polizza-furto-incendio/">Furto e incendio</a></li><li><a target="_blank" rel="noreferrer" href="https://www.6sicuro.it/garanzie-accessorie/polizza-tutela-legale/">Tutela legale</a></li><li><a target="_blank" rel="noreferrer" href="https://www.6sicuro.it/garanzie-accessorie/polizza-cristalli/">Cristalli</a></li><li><a target="_blank" rel="noreferrer" href="https://www.6sicuro.it/garanzie-accessorie/polizza-di-rinuncia-alla-rivalsa/">Rinuncia alla rivalsa</a></li></ol></div>
        </div>
      </div>
    </Card>
  </>
}

const mapState = (state: any) => {
  return {
    vehicle: state.userInfoData.user.currentVhicle ?? "auto"
  };
}

export default connect(mapState)(Info6Sicuro);