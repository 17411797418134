import { Component } from 'react';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import {Row} from 'primereact/row';
import * as Constants from '../utils/constants';
import { Dialog } from 'primereact/dialog';
import { priceFormatter } from '../utils/methods';

export default class WarrantyDetail extends Component<{
  currentWarrantyDetail: any;
  displayProposalAdequacy: boolean
}, {
  displayProposalAdequacy: boolean
}>{

  private headerGroup: JSX.Element = <ColumnGroup>
    <Row>
        <Column header="Garanzia"/>
        <Column header="Informazioni e massimali"/>
        <Column header="Scoperto franchigia"/>
        <Column header="Prezzo"/>
    </Row>
  </ColumnGroup>;

  private footerGroup: JSX.Element = <ColumnGroup>
    <Row>
        <Column footer={<span className="titleLev6 colorPrimaryBlue">Totale</span>} colSpan={3}/>
        <Column footer={<span className="titleLev6 colorPrimaryBlue">{this.getTotal()}</span>}/>
    </Row>
  </ColumnGroup>;

  constructor(props: any){
    super(props);
    this.state = {
      displayProposalAdequacy: this.props.displayProposalAdequacy
    }
  }

  private getTotal() {
    return priceFormatter(this.props.currentWarrantyDetail?.items?.reduce((prev: number, curr: any) => curr.name !== Constants.QUOTATION_TOTAL_LABEL ? prev + curr.amount : prev , 0));
  }

  render(){

    const provv_ass: string = this.props.currentWarrantyDetail?.provv_ass && !isNaN(Number(this.props.currentWarrantyDetail.provv_ass))
      ? Number(this.props.currentWarrantyDetail.provv_ass).toLocaleString("it-IT", {style: 'currency', currency: "EUR"})
      : `${this.props.currentWarrantyDetail?.provv_ass} €`
    
    const provv_perc: string = this.props.currentWarrantyDetail?.provv_perc && !isNaN(this.props.currentWarrantyDetail.provv_perc)
      ? `${Number(this.props.currentWarrantyDetail.provv_perc).toLocaleString("it-IT")} %`
      : `${this.props.currentWarrantyDetail.provv_perc}%`

    return <div className="details-subtable">

    { /*<Dialog
        visible={this.state.displayProposalAdequacy}
        onHide={() => {this.setState({displayProposalAdequacy: false})}}
        modal={true}
        resizable={false}
        draggable={false}
        blockScroll={true}
        header="Adeguatezza della proposta"
      >
        <p>
          L'intermediario dichiara di ritenere la proposta adeguata alle esigenze assicurative del contraente in base alle informazioni raccolte in fase di preventivazione, fatte salve le eventuali promozioni evidenziate, che possono essere modificate successivamente da parte del cliente sul sito internet o al call center del partner.
        </p>            
      </Dialog>*/ }
      <Dialog header="Adeguatezza della proposta" visible={this.state.displayProposalAdequacy} style={{ width: '50vw' }} onHide={() => this.setState({ displayProposalAdequacy: false })}>
        <p>L'intermediario dichiara di ritenere la proposta adeguata alle esigenze assicurative del contraente in base alle informazioni raccolte in fase di preventivazione, fatte salve le eventuali promozioni evidenziate, che possono essere modificate successivamente da parte del cliente sul sito internet o al call center del partner.</p>
      </Dialog>

      <p>{this.props.currentWarrantyDetail?.extended_info}</p>
      <div className="hideDesktop">
        {        
          this.props.currentWarrantyDetail?.items.map((item : any, index: number) => {
            return (<div key={`warr_detail_${index}`}>
              <p>
                <strong>Garanzia:</strong> {item.name}<br/>
                <strong>Massimale:</strong> {
                  item.maximum_coverage 
                  ? priceFormatter(item.maximum_coverage) 
                  : (item.information_guarantee && item.information_guarantee.trim() !== "")
                    ? priceFormatter(item.information_guarantee)
                    : (item.name !== Constants.QUOTATION_TOTAL_LABEL ? "-" : "")
                }<br/>
                <strong>Scoperto:</strong> {
                  item.excessPerc
                  ? `${item.excessPerc} % - ${priceFormatter(item.excess)}` 
                  : "-"
                }<br/>
                <strong>Prezzo:</strong> {
                  item.name !== Constants.QUOTATION_TOTAL_LABEL 
                  ? priceFormatter(item.amount)
                  : priceFormatter(this.props.currentWarrantyDetail?.total_price)
                }
              </p>
            </div>)
          })
        }
        <p>
          <span className="titleLev6 colorPrimaryBlue">Totale: {this.getTotal()}</span>
        </p>
      </div>

      <DataTable
        className="hideMobile"
        id="warrantyDetail"
        dataKey="name"
        emptyMessage="Nessun dettaglio da mostrare"
        value={this.props.currentWarrantyDetail?.items}
        stripedRows
        headerColumnGroup={this.headerGroup}
        footerColumnGroup={this.footerGroup}
      >
        <Column field="name" />
        <Column field="maximum_coverage"
          body={item => item.maximum_coverage 
              ? item.maximum_coverage.split(" + ")
                  .map((price: string) => priceFormatter(price))
                  .join(" + ")
              : (item.information_guarantee && item.information_guarantee.trim() !== "")
                ? priceFormatter(item.information_guarantee)
                : (item.name !== Constants.QUOTATION_TOTAL_LABEL ? "-" : "")}
        ></Column>
        <Column field="excessPerc"
          body={item => item.excessPerc
              ? `${item.excessPerc} % - ${priceFormatter(item.excess)}` 
              : "-"}
        />
        <Column field="amount"
          body={
            item => item.name !== Constants.QUOTATION_TOTAL_LABEL 
              ? priceFormatter(item.amount)
              : priceFormatter(this.props.currentWarrantyDetail?.total_price)
          }
        />
      </DataTable>
      <div className='mt-4'>
        <div className="row">
          <div className="col-sm-12">
            Se acquisti la polizza, riconoscerà a 6sicuro una commissione di {provv_ass} pari al {provv_perc} dell‘importo RCA comprensivo di imposte e SSN. Tale importo non incide sul premio finale della tua assicurazione.
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <span className="aLike" onClick={() => this.setState({displayProposalAdequacy: true})}>{"Adeguatezza della proposta"}</span> | <a className="aLike" target="_blank" rel="noreferrer" href={this.props.currentWarrantyDetail?.nota_info}>{"Set informativo e condizioni generali"}</a>
          </div>
        </div>
      </div>
    </div>
  }

}