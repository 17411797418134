import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const ResetPasswordConfirmationCard = () => {
    return (
      <Card className="pr-form mb-5">
          <div className="p-fluid p-5">
              <div className="row">
                  <div className="col-12">
                      <h1 className="titleLev4 colorPrimaryBlue mb-2">
                        Registrazione completata
                      </h1>
                      <p>Clicca <Link to="/preventivi/area-personale/login" className="aLike">qui</Link> per accedere alla pagina di login</p>     
                  </div>
              </div>
          </div>
      </Card>
    );
}

export default ResetPasswordConfirmationCard;