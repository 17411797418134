import { Component } from 'react';
import * as Constants from './utils/constants';
import { fetchList, getRedirectToStep, manageOptionSelected, getValidInsuranceStart, extractAddressData } from './utils/methods';
import { IPerson, IVehicle, Step1Data, Step2Data, Step3Data, Step4Data, UserInfo } from './utils/common';
import { step1DataSuccess, step2DataSuccess, step3DataSuccess, step4DataSuccess, stepDataReset, userInfoDataSuccess } from '../redux/actions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import QuotationService from './utils/QuotationService';
import CookieService from './utils/CookieService';
// import moment from "moment";
import User from './utils/User';
import { connect } from 'react-redux';
import { StepDataDualState } from '../redux/reducers';
import withEventManager from './customs/HOC/withEventManager';
import { compose } from 'ramda';
import { IWithEventManager } from './customs/HOC/EventManager';

interface IStep4LoaderProps extends IWithEventManager{
  userInfo: UserInfo;
  step3Data: StepDataDualState;
  step4Data: StepDataDualState;
  vehicle: string;
  location: ReturnType<typeof useLocation>;
  params: ReturnType<typeof useParams>;
  history: ReturnType<typeof useHistory>;
  step1DataSuccess: Function;
  step2DataSuccess: Function;
  step3DataSuccess: Function;
  step4DataSuccess: Function;
  userInfoDataSuccess: Function;
  stepDataReset: Function;
}

interface IStep4LoaderState{
  prevId: string,
  calcoloId: string,
  progress: number,
  hideSelf: boolean,
  // contractorName: string,
  loadingRemoteData: boolean,
  isStep1Request: boolean,
  error: boolean
}

/*
* esempio redirect da nodo
  // utente loggato sylvain
  http://6sicuro.lan:3000/preventivi/assicurazione-auto/quotazioni?&alfa=1903843360&beta=1146040080&epsilon=9869854&convenzioneId=1

  http://6sicuro.lan:3000/preventivi/assicurazione-auto/quotazioni?&alfa=28991010&beta=1202789680&epsilon=25568705&convenzioneId=1&quotazionitradizionali=true&garanzieAccessorieSalvate=1&salvataggioId=


https://staging.6sicuro.it/preventivi/MailSereno?gamma=CALLC&preventivo=Y&convenzioneId=1&epsilon=9869407&beta=1146051440&prod=1&isBo=1

https://staging.6sicuro.it/preventivi/MailSereno?gamma=CALLC&preventivo=Y&quotazioni=Y&convenzioneId=1&epsilon=9869407&beta=1146051440&prod=1&isBo=1

//loggato danny
http://6sicuro.lan:3000/preventivi/assicurazione-auto/quotazioni?alfa=1903830080&epsilon=9869407&beta=1146051440&convenzioneId=1
http://6sicuro.lan:3000/preventivi/assicurazione-auto/quotazioni?alfa=1903830080&goToStep1=true&epsilon=9869407&beta=1146051440&convenzioneId=1



https://staging.6sicuro.it/preventivi/MailSereno?gamma=CALLC&preventivo=Y&quotazioni=Y&convenzioneId=1&epsilon=9870316&beta=1146051440&prod=1&isBo=1

//link step1
http://6sicuro.lan:3000/preventivi/assicurazione-auto/quotazioni?alfa=1903830080&goToStep1=true&epsilon=9870316&beta=1146051440&convenzioneId=1
//link step 4
http://6sicuro.lan:3000/preventivi/assicurazione-auto/quotazioni?alfa=1903830080&epsilon=9870316&beta=1146051440&convenzioneId=1

*/

class Step4LoaderComponent extends Component<IStep4LoaderProps, IStep4LoaderState>{
  // private store: any;
  private vehicle: any;
  private usingAlfaBeta = false;
  private usingAlfaEpsilon = false;
  private abortController: AbortController = new AbortController();

  // private user: User | undefined;
  private _isMounted = false;

  constructor(props: any) {
    super(props);
    this.vehicle = props.vehicle;

    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;

    // this.user = props.user;

    this.onQuotationReceived = this.onQuotationReceived.bind(this);
    this.onQuotationsLoaded = this.onQuotationsLoaded.bind(this);
    this.handleStateRetrievalByAlfaBetaEpsilon = this.handleStateRetrievalByAlfaBetaEpsilon.bind(this);

    const query = new URLSearchParams(this.props.location.search);  // query.get ritorna null se il parametro non esiste!
    let alfa = query.get("alfa");       // il preventivo padre id
    let beta = query.get("beta");       // lo user id
    let epsilon = query.get("epsilon"); // il calcolo id
    let goToStep1 = query.get("goToStep1");     // indica se è andare alla pagina delle quotazioni
    let savedId = query.get("salvataggioId");   // id del salvataggio da visualizzare -- usato come discriminante
    // inibisce il ricalcolo e attiva il recupero del preventivo già esistente
    // si deve verificare sempre con la presenza di epsilon (calcId)

    let isLoadFromRemote = ((alfa !== null && beta !== null) || (epsilon !== null));

    this.state = { 
      prevId: alfa !== null ? Math.round(Number(alfa) / 80).toString() : '', 
      calcoloId: epsilon !== null ? epsilon : '', 
      progress: 0, 
      hideSelf: false, 
      // contractorName: (!isLoadFromRemote) ? step3Data?.contractorName ?? "" : '', 
      loadingRemoteData: isLoadFromRemote, 
      isStep1Request: (goToStep1 != null),
      error: false 
    };

    if (savedId === null && epsilon == null) {
      // trak se non arriva dal BO -- segnalare brainpull
      // preventivo da journey preventivatore/area 

      this.props.trackEvent?.({
        name: "confronta-prezzi",
        data: {}
      });
    }

    this.sortFunction = this.sortFunction.bind(this);
  }

  sortFunction(a: any, b: any){
    return a.value < b.value
      ? -1
      : a.value > b.value ? 1 : 0
  }

  componentDidMount() {

    this._isMounted = true;
    const query = new URLSearchParams(this.props.location.search);  // query.get ritorna null se il parametro non esiste!
    let alfa = query.get("alfa");       // il preventivo padre id
    let beta = query.get("beta");       // lo user id
    let epsilon = query.get("epsilon"); // il calcolo id
    let goToStep1 = query.get("goToStep1");     // indica se è andare alla pagina delle quotazioni
    let savedId = query.get("salvataggioId");   // id del salvataggio da visualizzare -- usato come discriminante

    CookieService.clearQuotationCookies( () => {
      if (savedId !== null) {
        // ricevuta richiesta per visualizzare le quotazoni salvate
        // ho a disposizione "beta": lo userId - "epsilon" il calcoloId - "convenzioneId", convenzioneId
        // this.usingAlfaBeta = false;
        this.usingAlfaEpsilon = true;
        let savedOptions = query.get("garanzieAccessorieSalvate"); // garanzie accessorie spuntate dal utente (lista separata da visrgola)
        let optionArray = manageOptionSelected(savedOptions, this.vehicle);
        this.handleStateRetrievalByBetaEpsilonWarranties(Number(alfa), Number(beta), Number(epsilon), optionArray, (goToStep1 !== null || optionArray === undefined));
      }
      else if (alfa !== null && beta !== null && epsilon === null) {
        this.usingAlfaBeta = true;
        // this.usingAlfaEpsilon = false;
        // this.props.step4DataSuccess({ fromMailSereno: true, quotation: [], noQuotation: [] }, this.vehicle);
        this.handleStateRetrievalByAlfaBetaEpsilon(Number(alfa), Number(beta), (goToStep1 !== null) ? Number(goToStep1) : undefined, (goToStep1 !== null));
      }
      else if (alfa !== null && beta !== null && epsilon !== null) {
        // nuova logica per recuperare i preventivi in base al calcolo id
        console.log("nuova logica per recuperare i preventivi in base al calcolo id");
        // this.usingAlfaBeta = false;
        this.usingAlfaEpsilon = true;
        // this.props.step4DataSuccess({ fromMailSereno: true, quotation: [], noQuotation: [] }, this.vehicle);
        this.handleStateRetrievalByAlfaBetaEpsilon(Number(alfa), Number(beta), Number(epsilon), (goToStep1 !== null));
      }
      else {
        // quotazione regolare del preventivatore
        // this.usingAlfaBeta = false;
        // this.usingAlfaEpsilon = false;
        let lastCompletedStep = getRedirectToStep(this.vehicle)
        if (Constants.ENABLE_REDIRECT && lastCompletedStep < 4) {
          this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(lastCompletedStep, this.vehicle));
          // window.location.href = Constants.SEISICURO_STEP_URL(lastCompletedStep, this.vehicle);
        }

        fetchList(
          Constants.SEISICURO_GET_PREV_ID(Math.round(+new Date() / 1000), this.vehicle, false),
          (result: any) => {
            
            if (result.prevId !== undefined && result.calcoloId !== undefined) {
    
              const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
              const step4Data: Step4Data = this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data;
    
              this.setState({ prevId: result.prevId, calcoloId: result.calcoloId });
    
              //fix azzero il numero di chiamate da fare
              QuotationService.resetRequestCounter();
              // fisso le garanzie richieste
              // if ((Array.isArray(step4Data.selectedOptions) && step4Data.selectedOptions.length > 0)) {
              //   QuotationService.updateWarranties(step4Data.selectedOptions);
              // }

              // faccio sempre aggioranre le garanzie sulla base dello stato di redux
              QuotationService.updateWarranties(step4Data.selectedOptions);
              // avvio il processo di quotazione
              QuotationService.setCallback(this.onQuotationReceived);
              QuotationService.hideBlackBox = (step3Data.includeSatProducts !== undefined) ? !(step3Data.includeSatProducts) : false;
              QuotationService.executeRequest(result.prevId, result.calcoloId, this.vehicle === 'auto' ? '1' : '2', false, this.abortController.signal);
            }
            else {
              this.setState({ error: true });
            }
          },
          () => this.setState({ error: true }),
          true,
          this.abortController.signal
        );
      }
    });
    
  }
  
  componentWillUnmount(): void {
    this._isMounted = false;
    this.abortController.abort()
  }

  private refineQuotationResponse(quote: any): any{
    let contractor: IPerson | undefined;
    let vehicle: IVehicle | undefined;
    let owner: IPerson | undefined;
    let driver: IPerson | undefined;

    const user : User = User.getInstance();

    // inizializzazione dati step 1
    let step1DataToDispatch: Step1Data = {...quote.step1Data, email: quote.step1Data.email.toLowerCase(), step1Ok: true};
    if(quote?.areaPerosnale?.idContraenteAP){
      
      const selectedFields = ["email", "phone", "gender", "name", "surname", "dateOfBirth", "civilState", "bornInItaly", "countryOfBirth", "cityOfBirth", "degree", "degreeLabel", "profession"];
      contractor = user.getPersonById(quote.areaPerosnale.idContraenteAP);
      
      if(contractor){
        let personStep1Data : Step1Data = {contractorId: quote.areaPerosnale.idContraenteAP};

        Object.entries(contractor).forEach(([key, value]) => {
          if(selectedFields.includes(key)){
            personStep1Data[key as keyof Step1Data] = value
          }
        })

        step1DataToDispatch = {...step1DataToDispatch, ...personStep1Data};
      }
    }
    quote.step1Data = {...step1DataToDispatch};

    
    // inizializzazione dati step 2
    let step2DataToDispatch: Step2Data = {
      ...quote.step2Data,
      knowLicensePlate: quote.step2Data?.licensePlate
        ? true
        : false,
      step2Ok: true
    };
    if(quote?.areaPerosnale?.idVeicoloAP){
      
      const selectedFields = ["vehicleName", "vehicleType", "vehicleLicensePlateNumber", "vehicleBuyYear", "vehicleRegistrationMonth", "vehicleRegistrationYear", "vehicleBrand", "vehicleBrandLabel", "vehicleModel", "vehicleModelLabel", "vehicleFitting", "vehicleFittingLabel", "veicleFullDescription", "vehicleOwned", "vehicleFuelType", "vehicleEngineDisplacement", "vehicleAntitheft", "vehicleTowbarMounted", "vehicleGplMounted"];
      vehicle = user.getVehicleById(quote.areaPerosnale.idVeicoloAP);
      
      if(vehicle){
        let vehicleStep2Data : Step2Data = {vehicleId: quote.areaPerosnale.idVeicoloAP};

        Object.entries(vehicle).forEach(([key, value]) => {
          if(selectedFields.includes(key)){
            vehicleStep2Data[key as keyof Step2Data] = value
          }
        })

        step2DataToDispatch = {...step2DataToDispatch, ...vehicleStep2Data}
        
      }
    }
    quote.step2Data = {...step2DataToDispatch};
    
    
    // inizializzazione dati step 3
    let step3DataToDispatch: Step3Data = {...quote.step3Data, step3Ok: true};
    
    const selectedFields = ["name", "surname", "cityOfResidence", "postalCodeOfResidence", "address", "addressToponimo", "addressNumber", "children", "youngestChild", "email", "phone", "italianDrivingLicense", "drivingLicenseAge"];
    
    if(contractor){
      let contractorStep3Data : Step3Data = {};

      Object.entries(contractor).forEach(([key, value]) => {
        if(selectedFields.includes(key)){
          contractorStep3Data[this.mapFieldPersonToStep3Contactor(key) as keyof Step3Data] = value
        }
      })

      step3DataToDispatch = {...step3DataToDispatch, ...contractorStep3Data};
    }
    
    if(quote.step3Data.contractorAndOwnerCoincide === "si"){
      if(contractor){
        step3DataToDispatch = {...step3DataToDispatch, owner: {...contractor}, ownerId: contractor.id}
      }
    }
    else if(quote?.areaPerosnale?.idProprietarioAP){
      owner = user.getPersonById(quote.areaPerosnale.idProprietarioAP);
      if(owner){
        step3DataToDispatch = {...step3DataToDispatch, owner: owner, ownerId: owner.id}
      }
    }

    if(quote.step3Data.contractorAndDriverCoincide === "si"){
      if(contractor){
        step3DataToDispatch = {...step3DataToDispatch, driver: {...contractor}, driverId: contractor.id}
      }
    }
    else if(quote?.areaPerosnale?.idConducenteAP){
      driver = user.getPersonById(quote.areaPerosnale.idConducenteAP);
      if(driver){
        step3DataToDispatch = {...step3DataToDispatch, driver: driver, driverId: driver.id}
      }
    }
    
    if(step3DataToDispatch?.driverId &&
      step3DataToDispatch?.ownerId &&
      quote.step3Data.ownerAndDriverCoincide === "si"
    ){
      step3DataToDispatch = {...step3DataToDispatch, driver: {...step3DataToDispatch.owner}, driverId: (step3DataToDispatch.owner as IPerson).id}
    }


    // refinement people addresses
    if (step3DataToDispatch?.contractorAddress) {
      const { toponimo, indirizzo, } = extractAddressData(step3DataToDispatch.contractorAddress);
      step3DataToDispatch.contractorAddress = `${toponimo} ${indirizzo}`;
    }
    
    if (step3DataToDispatch.driver?.address) {
      const { toponimo, indirizzo, } = extractAddressData(step3DataToDispatch.driver.address);
      step3DataToDispatch.driver.address = `${toponimo} ${indirizzo}`;
    }

    if (step3DataToDispatch.owner?.address) {
      const { toponimo, indirizzo, } = extractAddressData(step3DataToDispatch.owner.address);
      step3DataToDispatch.owner.address = `${toponimo} ${indirizzo}`;
    }

    if(step3DataToDispatch?.newInsuranceStart){
      const insuranceDate: Date = new Date(step3DataToDispatch.newInsuranceStart);
      const now: Date = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      if(insuranceDate < now){
        step3DataToDispatch.newInsuranceStart = now;
      }
    }
    quote.step3Data = {...step3DataToDispatch};
    
    return quote;
  }

  private mapFieldPersonToStep3Contactor(personField: string): string{
    let mappedField: string = "";
    
    switch(personField){
      case "name":
        mappedField = "contractorName";
        break;
      case "surname":
        mappedField = "contractorSurname";
        break;
      case "address":
        mappedField = "contractorAddress";
        break;
      case "addressNumber":
        mappedField = "contractorAddressNumber";
        break;
      case "cityOfResidence":
        mappedField = "contractorCityOfResidence";
        break;
      case "postalCodeOfResidence":
        mappedField = "contractorPostalCode";
        break;
      case "children":
        mappedField = "contractorChildren";
        break;
      case "youngestChild":
        mappedField = "contractorYoungestChild";
        break;
      case "email":
        mappedField = "contractorEmail";
        break;
      case "phone":
        mappedField = "contractorPhoneNumber";
        break;
      case "italianDrivingLicense":
        mappedField = "contractorItalianDrivingLicense";
        break;
      case "drivingLicenseAge":
        mappedField = "contractorDrivingLicenseAge";
        break;
    }

    return mappedField;
  }

  /**
   * RECUPERA una quotazione SALVATA in base allo user, al calcolo e alla lista delle garanzie accessorie
   * @param beta lo user ID
   * @param epsilon il calcolo ID
   * @param optionArray l'array delle garanzie accessorie da selezionare
   */
  handleStateRetrievalByBetaEpsilonWarranties(alfa: number, beta: number, epsilon: number, optionArray: any[], redirectToStep1 = false) {
    this.setState({
      hideSelf: false,
      progress: 0
    }, () => {
      // this.store = PersistedStore.clearState().store;
  
      // // pulisco l'app
      // this.store.dispatch(step1DataSuccess({}, this.vehicle));
      // this.store.dispatch(step2DataSuccess({}, this.vehicle));
      // this.store.dispatch(step3DataSuccess({}, this.vehicle));
      // this.store.dispatch(step4DataSuccess({ quotation: [], noQuotation: [], selectedOptions: optionArray, requestCounter: 6, fromMailSereno: true, warrantiesFromBo: true }, this.vehicle));
  
      let step4Data: Step4Data = {
        quotation: [],
        noQuotation: [],
        selectedOptions: optionArray,
        requestCounter: 6,
        fromMailSereno: true,
        warrantiesFromBo: true
      };
  
      // this.props.stepDataReset();
      this.props.step4DataSuccess(step4Data, this.vehicle);
  
      // è la segnalazione di un errore a BE
      if (redirectToStep1) {
        this.onEpsilonWarrantiesFails();
        return false;
      }
  
      // si aspetta alfa e beta "cifrato" -- beta è gia cifrato!
      fetchList(
        Constants.SEISICURO_AJAX_REDUX_STATE_ENDPOINT((Number(alfa) * 80), Number(beta), epsilon, (redirectToStep1 ? "Y" : "N")),
        (result: any) => {
          if (result.step1Data !== undefined && result.step2Data !== undefined && result.step3Data !== undefined) {
            
            const currentUserId : string | number = JSON.parse(this.props.userInfo.userData ?? "{}")?.id ?? -1;
            const currentUserLogged: boolean = (this.props.userInfo.logged ?? false) && currentUserId !== -1;
            let journeyData: UserInfo = {
              externalJourney: true,
              openRegistration: undefined,
              openLogin: undefined,
              email: undefined,
            };
            
            result = this.refineQuotationResponse(result);
          
            
            // !result?.areaPerosnale && (result.areaPerosnale = {});
            // result.areaPerosnale.isBo = "true";

            if(result?.areaPerosnale?.isBo === "true"){
              // come from BO
              journeyData.fromBO = true;
            }

            if(result?.areaPerosnale?.idUserAP){
    
              // 1 - 1 -> utente loggato, id divergenti -> resetto e apro popup
              // ==========
              // 1 - 0 -> loggato, id convergono -> non devo resettare userData
              // 0 - 1 -> non loggato e divergono -> resetto e apro popup
              // 0 - 0 -> non loggato id coincidono -> resetto e apro popup

              journeyData = {...journeyData, externalJourney: true};

              if((result?.areaPerosnale?.idUserAP ?? "").toString() !== ""){

                const quotationID: string = result.areaPerosnale.idUserAP.toString();
                journeyData = {
                  ...journeyData,
                  externalJourneyId: quotationID
                }

                if(!journeyData.fromBO){
                  if(currentUserLogged){

                    if(quotationID !== currentUserId.toString()){
                      User.logout(this.vehicle);
                      journeyData = {
                        ...journeyData,
                        email: result.step1Data.email,
                        // openLogin: true, //lascio la resposabilità del setting di openLogin al component App
                        logged: false,
                        userData: JSON.stringify(User.getInstance().setInitialState())
                      }
                    }
                    else{
                      // current user non loggato, l
                      journeyData = {
                        ...journeyData,
                        email: result.step1Data.email,
                        // openLogin: true, //lascio la resposabilità del setting di openLogin al component App
                        logged: false,
                        userData: JSON.stringify(User.getInstance().setInitialState())
                      }
                    }
                  }
                }
                else{
                  journeyData = {
                    ...journeyData,
                    logged: true
                  }
                }

              }

            }
            else{

              // in caso di prevantivi "guest" devo sempre inibire il popup di login  
              // forzo il flag fromBO a true
              journeyData.fromBO = true;
              
              // forzo gli stati 1..3 come completi
              result.step1Data.step1Ok = true;
              result.step2Data.step2Ok = true;
              result.step3Data.step3Ok = true;
              // riordino i valori delle varie select
              result.step2Data.vehicleBrandList?.sort(this.sortFunction);
              result.step2Data.carModelList?.sort(this.sortFunction);
              result.step2Data.vehicleFittingList?.sort(this.sortFunction);
              
              result.step2Data.carModelList = result.step2Data.carModelList.map((item: any) => {
                return {...item, value: item.value.replaceAll("�", "ª")};
              })
              result.step2Data.engineDisplacementList = result.step2Data.engineDisplacementList?.map((element: any) => { return { ...element, value: element.value.trim() } });
      
            }
            
            // fisso i problemi di visualizzazione dello step2
            result.step2Data.vehicleModelLabel = this.fixModelLabel(result.step2Data);

            const insuranceDate: Date = new Date(result.step3Data.newInsuranceStart);
            const now: Date = new Date();
            now.setHours(0);
            now.setMinutes(0);
            now.setSeconds(0);
            if(insuranceDate < now){
              result.step3Data.newInsuranceStart = now;
            }
            // if (moment(result.step3Data.newInsuranceStart).isBefore(moment(new Date(), "days"))) {
            //   result.step3Data.newInsuranceStart = moment().valueOf();
            // }

    
            if(JSON.stringify(journeyData) !== "{}"){
              // setto flag journey esterna
              this.props.userInfoDataSuccess(journeyData);
            }
    
            // imposto lo stato degli step precedenti : 1..3
            this.props.step1DataSuccess(result.step1Data, this.vehicle);
            this.props.step2DataSuccess(result.step2Data, this.vehicle);
            this.props.step3DataSuccess(result.step3Data, this.vehicle);
              
            // const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
            // se la data di decorrenza è inferiore a oggi devo tornare allo step 1
            // if (getValidInsuranceStart(step3Data?.newInsuranceStart) !== undefined) {
            QuotationService.hideBlackBox = (result.step3Data?.includeSatProducts !== undefined) ? !(result.step3Data?.includeSatProducts) : false;
  
            // Constants.SEISICURO_GET_PREV_ID( TIMESTAMP, VEICOLO, ALREDYQUOTED)
            // il paremtro ALREDYQUOTED: settato a true FA RECUPERARE UNA QUOTAZIONE ESISTENTE -- settato a false genera una nuova QUOTAZIOENE
            // recupero il CALCOLO INDICATO! (NON voglio staccare un nuovo calcolo ID! --> QUINDI alredyQuoted === true)
            fetchList(
              Constants.SEISICURO_GET_PREV_ID(Math.round(+new Date() / 1000), this.vehicle, true),
              (result: any) => {
                // NON modifico il Calcolo ID (cambierà solo se modifico il preventivo tornando allo step 1..3)! -- se no non riesco a trovare i risultati
                // -- occhio che result.previd NON corrisponde al padre!
                this.setState({ prevId: alfa.toString(), calcoloId: epsilon.toString() });
    
                QuotationService.setCallback(this.onQuotationsLoaded);
                QuotationService.loadSavedQuotatinoRequest(
                  alfa.toString(),
                  epsilon.toString(),
                  this.vehicle === 'auto' ? '1' : '2',
                  (optionArray !== null && optionArray !== undefined && optionArray.length > 0) ? optionArray.map(ele => ele.value).join(',') : '1',
                  this.abortController.signal
                );
              },
              () => this.onEpsilonWarrantiesFails(),
              false, this.abortController.signal
            );
            // }
            // else {
            //   this.onEpsilonWarrantiesFails(3);
            // }
            
          }
          else {
            this.onEpsilonWarrantiesFails();
          }
        },
        () => this.onEpsilonWarrantiesFails(),
        true,
        this.abortController.signal
      );
    });
  }

  /**
   * Handle the quotation data retrieval via the "preventivi-json" endpoint.
   * Will also either redirect the user to the step1 page or start obtaining new quotations
   * 
   * @param alfa il preventivo padre id ricevuto dalla MailSereno
   * @param beta lo user id
   * @param epsilon il calcolo id
   * @param redirectToStep1 indica se rimandare l'untente allo step-1
   */
  handleStateRetrievalByAlfaBetaEpsilon(alfa: number, beta: number, epsilon: number | undefined, redirectToStep1 = false) {

    console.log("StepLoader4 handleStateRetrievalByAlfaBetaEpsilon");
    // pulisco l'app
    // this.store = PersistedStore.clearState().store;
    
    // this.store.dispatch(step1DataSuccess({}, this.vehicle));
    // this.store.dispatch(step2DataSuccess({}, this.vehicle));
    // this.store.dispatch(step3DataSuccess({}, this.vehicle));
    
    // this.store.dispatch(step4DataSuccess(step4Result, this.vehicle));
    
    let step4Result: Step4Data = {
      quotation: [],
      noQuotation: [],
      selectedOptions: [],
      requestCounter: 1,
      warrantiesFromBo: false
    };
    // this.props.stepDataReset();
    this.props.step4DataSuccess(step4Result, this.vehicle);

    fetchList(
      Constants.SEISICURO_AJAX_REDUX_STATE_ENDPOINT(alfa, beta, (epsilon !== undefined && !isNaN(epsilon) ? epsilon : undefined), (redirectToStep1 ? "Y" : "N")),
      (result: any) => {
        if (result.step1Data !== undefined && result.step2Data !== undefined && result.step3Data !== undefined) {
          
          const currentUserId : string | number = JSON.parse(this.props.userInfo.userData ?? "{}")?.id ?? -1;
          const currentUserLogged: boolean = (this.props.userInfo.logged ?? false) && currentUserId !== -1;
          let journeyData: UserInfo = {
            externalJourney: true,
            openRegistration: undefined,
            openLogin: undefined,
            email: undefined,
          };

          result = this.refineQuotationResponse(result);

          // forzo il flag per testare il locale
          // !result?.areaPerosnale && (result.areaPerosnale = {});
          // result.areaPerosnale.isBo = "true";
          
          if(result?.areaPerosnale?.isBo === "true"){
            // come from BO
            journeyData.fromBO = true;
          }
          
          if(result?.areaPerosnale?.idUserAP){
    
            // 1 - 1 -> utente loggato, id divergenti -> resetto e apro popup
            // ==========
            // 1 - 0 -> loggato, id convergono -> non devo resettare userData
            // 0 - 1 -> non loggato e divergono -> resetto e apro popup
            // 0 - 0 -> non loggato id coincidono -> resetto e apro popup

            journeyData = {...journeyData};

            if((result?.areaPerosnale?.idUserAP ?? "").toString() !== ""){
              const quotationID: string = result.areaPerosnale.idUserAP.toString();
              journeyData = {
                ...journeyData,
                externalJourneyId: quotationID
              }

              if(!journeyData.fromBO){
                if(currentUserLogged){
                
                  if(quotationID !== currentUserId.toString()){
                    User.logout(this.vehicle);
                    journeyData = {
                      ...journeyData,
                      email: result.step1Data.email,
                      // openLogin: true, //lascio la resposabilità del setting di openLogin al component App
                      logged: false,
                      userData: JSON.stringify(User.getInstance().setInitialState())
                    }
                  }
                }
                else{
                  // current user non loggato, l
                  journeyData = {
                    ...journeyData,
                    email: result.step1Data.email,
                    // openLogin: true, //lascio la resposabilità del setting di openLogin al component App
                    logged: false,
                    userData: JSON.stringify(User.getInstance().setInitialState())
                  }
                }
              }
              else{
                journeyData = {
                  ...journeyData,
                  logged: true
                }
              }

            }

          }
          else{
            // in caso di prevantivi "guest" devo sempre inibire il popup di login  
            // forzo il flag fromBO a true
            journeyData.fromBO = true;

            result.step2Data.vehicleBrandList?.sort(this.sortFunction);
            result.step2Data.carModelList?.sort(this.sortFunction);
            result.step2Data.vehicleFittingList?.sort(this.sortFunction);

            result.step2Data.carModelList = result.step2Data.carModelList?.map((item: any) => {
              return {...item, value: item.value.replaceAll("�", "ª")};
            })
            result.step2Data.engineDisplacementList = result.step2Data.engineDisplacementList?.map((element: any) => { return { ...element, value: element.value.trim() } }); 
          }

          result.step2Data.vehicleModelLabel = this.fixModelLabel(result.step2Data);

          
          // if (moment(result.step3Data.newInsuranceStart).isBefore(moment(new Date(), "days"))) {
          //   result.step3Data.newInsuranceStart = moment().valueOf();
          // }

          // refinement people addresses
          // if (result.step3Data?.contractorAddress) {
          //   const { toponimo, indirizzo, } = extractAddressData(result.step3Data.contractorAddress);
          //   result.step3Data.contractorAddress = `${toponimo} ${indirizzo}`;
          // }
          
          // if (result.step3Data.driver?.address) {
          //   const { toponimo, indirizzo, } = extractAddressData(result.step3Data.driver.address);
          //   result.step3Data.driver.address = `${toponimo} ${indirizzo}`;
          // }

          // if (result.step3Data.owner?.address) {
          //   const { toponimo, indirizzo, } = extractAddressData(result.step3Data.owner.address);
          //   result.step3Data.owner.address = `${toponimo} ${indirizzo}`;
          // }
          
          if(JSON.stringify(journeyData) !== "{}"){
            // setto flag journey esterna
            this.props.userInfoDataSuccess(journeyData);
          }
          
          this.props.step1DataSuccess(result.step1Data, this.vehicle);
          this.props.step2DataSuccess(result.step2Data, this.vehicle);
          this.props.step3DataSuccess(result.step3Data, this.vehicle);

          this.setState({
            // contractorName: result.step3Data.contractorName,
            loadingRemoteData: false
          });
          if (!redirectToStep1) {
            // Constants.SEISICURO_GET_PREV_ID( TIMESTAMP, VEICOLO, ALREDYQUOTED)
            // il paremtro ALREDYQUOTED: settato a true FA RECUPERARE UNA QUOTAZIONE ESISTENTE -- settato a false GENERA una nuova QUOTAZIOENE
            // dato che sto riquotando CREO UN NUOVO CALCOLO! (--> QUINDI alredyQuoted === false)
            fetchList(
              Constants.SEISICURO_GET_PREV_ID(Math.round(+new Date() / 1000), this.vehicle, false),
              (result: any) => {
                if (result.prevId !== undefined && result.calcoloId !== undefined) {
                  
                  this.setState({ prevId: result.prevId, calcoloId: result.calcoloId });
                  
                  QuotationService.hideBlackBox = (result.step3Data?.includeSatProducts !== undefined) ? !(result.step3Data?.includeSatProducts) : false;
                  QuotationService.setCallback(this.onQuotationReceived);
                  QuotationService.executeRequest(result.prevId, result.calcoloId, this.vehicle === 'auto' ? '1' : '2', false, this.abortController.signal);
                }
                else {
                  this.onAlfaBetaFail();
                }
              },
              () => this.onAlfaBetaFail(),
              true,
              this.abortController.signal
            );
          }
          else {
            // window.location.href = Constants.SEISICURO_STEP_URL(1, this.vehicle);
            this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle));
          }
        }
        else {
          this.onAlfaBetaFail();
        }
      },
      (err: any) => {
        console.log(err);
        this.onAlfaBetaFail()
      },
      true,
      this.abortController.signal
    );
  }

  /**
   * If the "preventivi-json" endpoint request fails in any way an error will be shown to the user, and, if enabled, some tracking events will occur. 
   */
  onAlfaBetaFail() {
    this.props.trackEvent?.({
      name: "quoteError",
      data: {}
    });
    this.setState({ error: true });
  }

  /**
   * Se si verifica un errore rimando allo step1
   */
  onEpsilonWarrantiesFails (step?: number) {
    this.setState({error: true}, () => {
      // do il tempo di visualizzare l'errore
      setTimeout(() => {
        this.setState(
          {
            hideSelf: true,
            progress: 100,
            loadingRemoteData: false
          }, () => {
            // window.location.href = Constants.SEISICURO_STEP_URL((step !== undefined) ? step: 1, this.vehicle)

            this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(step !== undefined ? step : 1, this.vehicle));
          }
        );
      }, 1500);
    });
  }

  /**
   * Handles the data received by the various QuotServlet requests.
   * 
   * @param quotations the received quotation list
   * @param noQuotations the received noQuotation list
   * @param progress the current progress bar value
   * @param requestIndex il numero di richieste inviate
   */
  onQuotationReceived(quotations: any[], noQuotations: any[], progress: number, requestIndex: number) {
    this.clearQuotationsMarkup(quotations);

    if (quotations.length > 0 && noQuotations.length > 0) {
      this.setState({ hideSelf: true, progress: progress });
      let step4Result: Step4Data = { quotation: quotations, noQuotation: noQuotations, prevId: this.state.prevId, calcoloId: this.state.calcoloId, requestCounter: requestIndex };
      this.props.step4DataSuccess(step4Result, this.vehicle);
    }
    else if (quotations.length > 0) {
      this.setState({ hideSelf: true, progress: progress });
      let step4Result: Step4Data = { quotation: quotations, prevId: this.state.prevId, calcoloId: this.state.calcoloId, requestCounter: requestIndex };
      this.props.step4DataSuccess(step4Result, this.vehicle);
    }
    else if (noQuotations.length > 0) {
      this.setState({ hideSelf: true, progress: progress });
      let step4Result: Step4Data = { noQuotation: noQuotations, prevId: this.state.prevId, calcoloId: this.state.calcoloId, requestCounter: requestIndex };
      this.props.step4DataSuccess(step4Result, this.vehicle);
    }
    else if (quotations.length === 0 && noQuotations.length === 0 && requestIndex === 7) {
      this.setState({ hideSelf: true, progress: progress });
      let step4Result: Step4Data = { quotation: [], noQuotation: [], prevId: this.state.prevId, calcoloId: this.state.calcoloId, requestCounter: requestIndex };
      this.props.step4DataSuccess(step4Result, this.vehicle);
    }
    else {
      // tutte i rami precendenti non fanno altro che aggiornare lo stato dello step4 e innescare la subscribeToStoreChanges()
      // che da il via al vero ciclo di quotazione ... questo ramo sembra che vada in loop finche non riceve una ricsposta valida
      this.setState({ progress: progress });
      QuotationService.executeRequest(this.state.prevId, this.state.calcoloId, this.vehicle === 'auto' ? '1' : '2', false, this.abortController.signal);
    }
  }

  /**
   * Callback da eseguire dopo aver ricevuto le quotazioni associate al savataggio di un utente
   * @param quotations la lista delle quotazioni associate al utente
   */
  onQuotationsLoaded(quotations: any[], noQuotation: any[]) {
    this.setState({ hideSelf: true, progress: 100, loadingRemoteData: false }, () => {
      this.clearQuotationsMarkup(quotations);
      let step4Result: Step4Data = {
        quotation: quotations,
        noQuotation: noQuotation,
        prevId: this.state.prevId,
        calcoloId: this.state.calcoloId,
        requestCounter: 7,
        warrantiesFromBo: true
      };
      this.props.step4DataSuccess(step4Result, this.vehicle);
    });
  }

  /**
   * Data una lista di quotazioni ne ripulisce il testo (le short_info)
   * @param quotations una lista di quotazioni
   */
  clearQuotationsMarkup(quotations: any[]) {
    // tolgo i <br> in eccesso
    for (let i = 0; i < quotations.length; i++) {
      if (quotations[i].short_info) {
        let pulito = quotations[i].short_info.replace("+ <br/>", "+ ");
        pulito = pulito.replace("+ <br>", "+ ");
        quotations[i].short_info = pulito;
      }
    }
  }

  /**
   * Bonifica la label del modello (presa dallo step 2)
   * @param data i dati dello step2 (result.step2Data)
   * @returns la label bonificata se presente, udefined altrimenti
   */
  fixModelLabel(data: any) {
    // fisso il problema di visualizzazione del carattere "ª" nello step 4 derivante dalla label del 2
    let labelModello = data?.vehicleModelLabel;
    if (labelModello !== undefined) {
      return data.vehicleModelLabel.replaceAll("�", "ª");
    }
    return undefined;
  }

  render() {
    console.log("Step4Loader render");
    return (
      <>
        {
          !this.state.hideSelf &&
          <div className="progressbar-loader-a">
            {
              !this.state.error && !this.state.loadingRemoteData &&
              <>
                <div className='text-center'><span className="titleLev5 colorPrimaryBlue">Stiamo confrontando in tempo reale i preventivi più adatti alle tue esigenze.</span></div>
                <div><span className="text-2">Facciamo tutto noi, per mostrarti l’assicurazione più conveniente! Dacci solo pochi secondi…</span></div>
                {/* <div><span className="text-4">Al termine del caricamento dei prezzi, ti invieremo una mail con i dati per il tuo preventivo: se non la trovi, controlla nello spam e segnalala come non spam per non perdere gli aggiornamenti.</span></div> */}
              </>
            }
            {
              !this.state.error && this.state.loadingRemoteData && this.usingAlfaEpsilon === false &&
              <>
                <div><span className="text-1">Stiamo recuperando i dati del tuo preventivo.</span></div>
                <div><span className="text-2">Attendi il caricamento dei dati del tuo preventivo.</span></div>
              </>
            }
            {
              !this.state.error && this.state.loadingRemoteData && this.usingAlfaEpsilon === true && this.state.isStep1Request === false &&
              <>
                <div><span className="text-1">Recupero delle quotazioni salvate in corso!</span></div>
                <div><span className="text-2">Attendi il termine del caricamento dei dati del preventivo!</span></div>
              </>
            }
            {
              !this.state.error && this.state.loadingRemoteData && this.usingAlfaEpsilon === true && this.state.isStep1Request === true &&
              <>
                <div><span className="text-1">Recupero dei dati del preventivo in corso!</span></div>
                <div><span className="text-2">Attendi il termine del caricamento dei dati!</span></div>
              </>
            }
            {
              this.state.error &&
              <>
                <div><span className="text-1">Si è verificato un errore.</span></div>
                <div><span className="text-2">Riprova più tardi.</span></div>
              </>
            }
            {!this.state.error &&
              <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/6sicuro-logo-animato-grigio.gif`} alt="attendi il caricamento dei risultati - 6sicuro" id="attesa_caricamento" className="img-fluid" />
            }
          </div>
        }
      </>
    );
  }
}

const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    step1DataSuccess: (payload: any, vehicle: string) => dispatch(step1DataSuccess(payload, vehicle)),
    step2DataSuccess: (payload: any, vehicle: string) => dispatch(step2DataSuccess(payload, vehicle)),
    step3DataSuccess: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle)),
    step4DataSuccess: (payload: any, vehicle: string) => dispatch(step4DataSuccess(payload, vehicle)),
    stepDataReset: () => dispatch(stepDataReset()),
  }
};

const mapState = (state: any) => {
  return {
    step3Data: state.step3Data,
    step4Data: state.step4Data,
    userInfo: state.userInfoData.user,
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

// Required to access react-router's params (i.e. ":vehicle" in App.tsx).
const Step4Loader = (props: any) => {

  const params: ReturnType<typeof useParams> = useParams(); // mi aggiungereà la prop veihicle = auto | moto
  const history: ReturnType<typeof useHistory> = useHistory();
  const location: ReturnType<typeof useLocation> = useLocation();
  
  return <Step4LoaderComponent {...props} {...params} history={history} location={location}/>
};

export default compose(connect(mapState, mapDispatch), withEventManager)(Step4Loader);
