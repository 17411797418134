import { Card } from "react-bootstrap";
import { Divider } from "primereact/divider";
import { Component, useContext } from "react";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import * as Constants from './utils/constants';
import User from "./utils/User";
import QuoteInfo, {IQuoteDescriptor} from "./utils/QuoteInfo";
import UserService, { GenericException } from "./utils/UserService";
import StepLoaderSpinner from "./StepLoaderSpinner";
import { Dropdown } from "primereact/dropdown";
import { ICompany, IVehicle } from "./utils/common";
import CardPreventivo from "./CardPreventivo";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import '../scss/quotation-row.scss';

interface IDropdownItem{
  value: string | number;
  label: string;
}

interface IPreventiviProps extends IPageProps{
  pages: IPageDescriptor[];
  history: ReturnType<typeof useHistory>;
  contractorId: string;
  contractorType: string;
}

interface IPreventiviState{
  isLoading: boolean;
  company: ICompany | undefined;
  products: any[];
  error: boolean;
  errorMsg?: string | JSX.Element;
}

enum Status {
  LOADING,
  LOADED,
  ERROR
}

enum MonthsLimit{
  MESI_3 = 1,
  MESI_6 = 2,
  MESI_12 = 3,
  ALL = 0
}

interface ISalvati{
  status: Status;
  data: any[];
}

class ProductList extends Component<IPreventiviProps, IPreventiviState>{


  private currentPage: IPageDescriptor;
  private basePage: IPageDescriptor;
  private user: User;
  //private refVCard: any;
  private nomeAzienda: string | undefined;
  private _isMounted: boolean = false;
  private vehicleList: IDropdownItem[] = [];

  private filterDateSelector : IDropdownItem[] = [];

  constructor (props: IPreventiviProps){
    super(props);

    this.user = this.props.user as User;   

    this.currentPage = this.props.pages.find((p: IPageDescriptor) => p.name === this.props.page?.name) as IPageDescriptor;
    this.basePage = this.props.pages.find((p: IPageDescriptor) => p.name === "preventivi-veicolo") as IPageDescriptor;
    var company = User.getInstance().getCompanyById("");
    this.state = {
      company: company,
      isLoading: true,
      products: [],
      error: false
    }

    this.nomeAzienda = company?.name;

    this.filterDateSelector = [
      {label: "Tutti", value: MonthsLimit.ALL},
      {label: "Ultimi tre mesi", value: MonthsLimit.MESI_3},
      {label: "Ultimi sei mesi", value: MonthsLimit.MESI_6},
      {label: "Ultimo anno", value: MonthsLimit.MESI_12},
    ]

    this.vehicleList = this.user.vehicles
      .filter(vehicle => vehicle.vehicleLicensePlateNumber !== undefined)
      .map((vehicle: IVehicle) => {
        return {label: User.getVehicleSafeName(vehicle), value: `${vehicle.id}`};
      });

  }

  componentDidMount(){
    this._isMounted = true;
    window.onpopstate = () => {
      if(this._isMounted) {
        this.setState(
          {isLoading: true},
          () => {
            this
              .readProdottiData(this.props.contractorId)
              .then((response: any[] | Error) => this.readPreventiviDataHandler(response))
          }
        )
      }
    }

    this
      .readProdottiData(this.props.contractorId)
      .then((response: any[] | Error) => this.readPreventiviDataHandler(response))
  }

  componentDidUpdate(prevProps: Readonly<IPreventiviProps>, prevState: Readonly<IPreventiviState>, snapshot?: any): void {
    if(prevProps.contractorId !== this.props.contractorId){
      this
        .readProdottiData(this.props.contractorId)
        .then((response: any[] | Error) => this.readPreventiviDataHandler(response))
    }
  }
  
  private readProdottiData(contractorId: string): Promise<any[] | Error>{
    var url;
    if(this.props.contractorType == "business")
      url = `/quote-request/fetch?Contractor_Business_Id=${contractorId}`;
    else
      url = `/quote-request/fetch?Contractor_Type=Person`;
    return UserService.call(
      Constants.WEB_SITE_API_SERVER_URL(url),
      {method: "GET"},
      (response: any) => {
        if(response?.success === false){
          // caso di errore applicativo
          // if(response.error_code === "GENERIC" || response.error_code === "VALIDATION_ERROR"){
          //   throw new GenericException("Impossibile completare la richiesta di attivazione dell'account");
          // }
          // else{
          // }
          throw new GenericException("Siamo spiacenti non è stato possibile recuperare i prodotti collegati all'azienda");
        }
        else {
          return response
        }
      },
      (error: any) => {
        return error;
      }
    )
  }

  private readPreventiviDataHandler(response: any[] | Error){
    if(response instanceof Error){
      const error = response;
      if(error instanceof GenericException){
        this.setState({
          error: true,
          errorMsg: error.message,
          isLoading: false
        })
      }
      else{
        this.setState({
          error: true,
          errorMsg: "Si è verificato un errore",
          isLoading: false
        })
      }
    }
    else{

      const quotations = response;
      var products : any = {};
      for(let i in quotations){
        var type = quotations[i].Quote_Request_Type;
        if(products.hasOwnProperty(type)){
          products[type]++;
        }
        else
          products[type] = 1;
      }
      console.log(products);
      this.setState({
        products: products,
        isLoading: false
      });
    }
  }

  render(){
    
    let products = this.state.products;
    console.log(products)

    return <div className="mainBody">
      { this.state.isLoading ? <StepLoaderSpinner title="Caricamento"/> : "" }

      {this.state.error &&
        <>{this.state?.errorMsg}</>
      }

      {!this.state.error &&
        <>
          <div className="d-flex align-items-center">
            <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/prev-auto.svg`} style={{height: "3rem", marginRight: "1rem"}} alt=""/>
            <h1 className="titleLev3 colorPrimaryBlue mb-0 d-flex align-items-center">{this.currentPage.fullTitle ?? ""}</h1>
          </div>

          <p className="mt-2">Scopri una serenità su misura per la tua azienda! Qui puoi tenere a portata di mano tutti i preventivi già calcolati e procedere con l’acquisto in pochi semplici clic..</p>
          <Divider></Divider>


          <div className="mt-4">

            <Card>
              <div className="cardTitle borderBottom">
                <h3 className="titleLev4 colorPrimaryBlue mb-0">Prodotti assicurativi registrati</h3>
              </div>
              <hr className="noMargin"/>
              <div className="">
                {
                  Object.keys(products).length > 0
                    ? <ul className="quotationList">
                        {
                          Object.entries(products).map((elem, index) => {
                            var type = elem[0];
                            var count = elem[1];
                            var product = QuoteInfo.getQuoteInfoById(type);
                            var name = product?.name;
                            var icon = product?.icon;
                            var url;
                            if(this.props.contractorType == "business")
                              url = `/preventivi/area-personale/aziende/${this.props.contractorId}/${type}`;
                            else
                              url = `/preventivi/area-personale/beni/${type}`;
                            return <li key={`${type}`}>
                              <div className="cardTitle">
                                <div className="row align-items-center px-4">
                                  <div className="col-4 col-md-1">
                                    <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/icone_prodotti_assicurativi/${icon}`} alt="" style={{"width": "100%", height: "3rem"}}/>
                                  </div>
                                  <div className="col-8 col-md-6">
                                    <h3 className="titleLev5 colorPrimaryGreen">{name}</h3>
                                  </div>
                                  <div className="col-12 col-md-5 text-right">
                                    <span className="small">
                                      {
                                        (count > 1) ? (count + " richieste") : "1 richiesta"
                                      } di preventivi su questo prodotto
                                    </span>
                                    <Link className="mt-2" to={url}>
                                      <span className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">
                                        Visualizza richieste di preventivo
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </li>
                          })                            
                        }
                      </ul>
                    : <p className="px-5 py-4">Non ci sono richieste di preventivo disponibili</p>
                }
              </div>
            </Card>
          </div>
        </>
      }
    
    </div>;
  }

}

export default ProductList;