import { Divider } from "primereact/divider";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { StepDataDualState } from "../../redux/reducers";
import { Step1Data, Step2Data, Step3Data } from "../utils/common";
import * as Constants from '../utils/constants';
import { fixBadAddress } from "../utils/methods";

interface IRiepilogiData{
  step1Data: StepDataDualState;
  step2Data: StepDataDualState;
  step3Data: StepDataDualState;
  vehicle: string;
}

class RiepilogoDatiComponent extends Component<IRiepilogiData, {}>{

  /**
   * Dati anagrafici template
   * 
   */
  datiAnagraficiTemplate(): JSX.Element {
    const step1Data: Step1Data = this.props.step1Data[this.props.vehicle as keyof StepDataDualState] as Step1Data;
    const step3Data: Step3Data = this.props.step3Data[this.props.vehicle as keyof StepDataDualState] as Step3Data;
    let dateOfBirth = '';
    if (step1Data.dateOfBirth) {
      let dateOfBirthObject = new Date(step1Data.dateOfBirth);
      dateOfBirth = dateOfBirthObject.toLocaleString('it', { dateStyle: 'long' });
    }
    let licenseYears= '';
    if (step3Data.contractorDrivingLicenseAge && step1Data.dateOfBirth) {
      let dateOfBirthObject = new Date(step1Data.dateOfBirth);
      let now = new Date();
      licenseYears = ((now.getFullYear() - dateOfBirthObject.getFullYear()) - step3Data.contractorDrivingLicenseAge).toString();
    }

    return (
      <div className="data-summary block">
        <div className="row">
          <div className="col-10"><h3>1. Dati contraente</h3></div>
          <div className="col-2">
            {/* <a href={Constants.SEISICURO_STEP_URL(1, this.props.vehicle, true)}><i className="pi pi-pencil"></i></a> */}
            <Link to={Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.props.vehicle)}><i className="pi pi-pencil"></i></Link>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {step1Data.gender && <div><label>Contraente:</label> <span className="value">{step1Data.gender}</span></div>}
            {step1Data.dateOfBirth && <div><label>Data di nascita:</label> <span className="value">{dateOfBirth}</span></div>}
            {step1Data.cityOfBirth && <div><label>Comune di nascita:</label> <span className="value">{step1Data.cityOfBirth.value}</span></div>}
            {step1Data.civilState && <div><label>Stato civile:</label> <span className="value">{step1Data.civilState}</span></div>}
            {step1Data.professionLabel && <div><label>Professione:</label> <span className="value">{step1Data.professionLabel}</span></div>}
            {step3Data.youngestFamilyDriver && <div><label>Figli conviventi:</label> <span className="value">{step3Data.youngestFamilyDriver !== undefined && step3Data.youngestFamilyDriver !== "nessuno" ? "sì" : "no"}</span></div>}
            {step1Data.degreeLabel && <div><label>Titolo di studio:</label> <span className="value">{step1Data.degreeLabel}</span></div>}
            {step3Data.contractorDrivingLicenseAge && <div><label>Anzianità patente:</label> <span className="value">{licenseYears} anni</span></div>}
          </div>
        </div>
        <Divider />
      </div>
    );
  }

  /**
   * Dati veicolo template
   * 
   */
  datiVeicoloTemplate(): JSX.Element {
    const step2Data: Step2Data = this.props.step2Data[this.props.vehicle as keyof StepDataDualState] as Step2Data;

    return (

      <div className="data-summary block">
        <div className="row">
          <div className="col-10"><h3>2. Dati veicolo</h3>
            {step2Data.vehicleBrand && <div><label>Marca:</label> <span className="value">{step2Data.vehicleBrandLabel}</span></div>}
            {step2Data.vehicleModel && <div><label>Modello:</label> <span className="value">{step2Data.vehicleModelLabel}</span></div>}
            {step2Data.vehicleFitting && <div><label>Allestimento:</label> <span className="value">{
              step2Data.vehicleFittingLabel !== undefined && step2Data.vehicleFittingLabel?.length > 23
                ? step2Data.vehicleFittingLabel.substring(0, 24) + "..."
                : step2Data.vehicleFittingLabel
            }</span></div>}
            {
              (step2Data.vehicleOwned || (!step2Data.vehicleOwned && step2Data.knowLicensePlate)) && step2Data.vehicleLicensePlateNumber &&
              <div><label>Targa:</label> <span className="value">{step2Data.vehicleLicensePlateNumber}</span></div>
            }
            {step2Data.vehicleRegistrationMonth && step2Data.vehicleRegistrationYear && <div><label>Prima immatricolazione:</label> <span className="value">{step2Data.vehicleRegistrationMonth}/{step2Data.vehicleRegistrationYear}</span></div>}
            {step2Data.vehicleBuyYear && <div><label>Anno di acquisto del veicolo:</label> <span className="value">{step2Data.vehicleBuyYear}</span></div>}
          </div>
          <div className="col-2">
            {/* <a href={Constants.SEISICURO_STEP_URL(2, this.props.vehicle)}><i className="pi pi-pencil"></i></a> */}
            <Link to={Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(2, this.props.vehicle)}><i className="pi pi-pencil"></i></Link>
            </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
          </div>
        </div>
        <Divider />
      </div>
    );
  }

  /**
   * Dati assicurativi template
   * 
   */
  datiAssicurativiTemplate(): JSX.Element {
    const step3Data: Step3Data = this.props.step3Data[this.props.vehicle as keyof StepDataDualState] as Step3Data;
    let newInsuranceStart = '';
    if (step3Data.newInsuranceStart) {
      let newInsuranceStartObject = new Date(step3Data.newInsuranceStart);
      newInsuranceStart = newInsuranceStartObject.toLocaleString('it', { dateStyle: 'long' });
    }

    return (
      <div className="data-summary block">
        <div className="row">
          <div className="col-10"><h3>3. Dati assicurativi</h3>
            {step3Data.contractorName && <div><label>Nome:</label> <span className="value">{step3Data.contractorName}</span></div>}
            {step3Data.contractorSurname && <div><label>Cognome:</label> <span className="value">{step3Data.contractorSurname}</span></div>}
            {step3Data.contractorAddress && <div><label>Indirizzo:</label> <span className="value">{fixBadAddress(`${step3Data.contractorAddress} ${step3Data.contractorAddressNumber}`)}</span></div>}
            {step3Data.contractorPhoneNumber && <div><label>Telefono cellulare:</label> <span className="value">{step3Data.contractorPhoneNumber}</span></div>}
            {step3Data.contractorEmail && <div><label>Email:</label> <span className="value">{step3Data.contractorEmail}</span></div>}
            {step3Data.newInsuranceStart && <div><label>Decorrenza:</label> <span className="value">{newInsuranceStart}</span></div>}
            {step3Data.currentInsuranceCreditClassLabel && <div><label>Classe di merito:</label> <span className="value">{step3Data.currentInsuranceCreditClassLabel}</span></div>}
            {step3Data.cuInsuranceClass && <div><label>Classe di merito CU:</label> <span className="value">{step3Data.cuInsuranceClass}</span></div>}
            {step3Data.bersaniDecree && <div><label>Legge Bersani:</label> <span className="value">{step3Data.bersaniDecree !== "no" ? "sì" : "no"}</span></div>}
            {step3Data.pastAccidentsInfo !== undefined && <div><label>Sinistri negli ultimi 5 anni:</label> <span className="value">{step3Data.pastAccidentsInfo.find(element => element.year >= (new Date().getFullYear() - 5)) !== undefined ? "sì" : "no"}</span></div>}
            {step3Data.otherDriversExist && <div><label>Altri conducenti:</label> <span className="value">{step3Data.otherDriversExist ? "sì" : "no"}</span></div>}
          </div>
          <div className="col-2">
            {/* <a href={Constants.SEISICURO_STEP_URL(3, this.props.vehicle)}><i className="pi pi-pencil"></i></a> */}
            <Link to={Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(3, this.props.vehicle)}><i className="pi pi-pencil"></i></Link>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
          </div>
        </div>
        <Divider />
      </div>
    );
  }

  render() {
    return <div className="block dati-anagrafici">
      <h2 className="titleLev6 colorPrimaryBlue mb-3">Riepilogo dati</h2>
      {this.datiAnagraficiTemplate()}
      {this.datiVeicoloTemplate()}
      {this.datiAssicurativiTemplate()}
    </div>
  }
}

const mapState = (state: any) => {
  return {
    step1Data: state.step1Data,
    step2Data: state.step2Data,
    step3Data: state.step3Data
  };
}

const RiepilogoDati = (props: any) => {
  return <RiepilogoDatiComponent {...props}/>
}

export default connect(mapState)(RiepilogoDati)