import { Button } from "primereact/button";
import * as Constants from './utils/constants';
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import { Component } from "react";
import UserService from "./utils/UserService";
import { isEmailValidCustom, passwordSecurityLevel } from "./utils/validate";
import { Dialog } from "primereact/dialog";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import { Redirect, useParams } from "react-router-dom";
import StepLoaderSpinner from "./StepLoaderSpinner";

enum PasswordStrength{
  WEAK = 1,
  MEDIUM = 2,
  STRONG = 3,
}

class ResetPasswordPageComponent extends Component<
  {
    // No props
  }, {
    email: string,
    password: string;
    passwordLiveChecking: boolean | undefined;
    passwordStrength: PasswordStrength | undefined;
    rePassword: string;
    
    emailValid: boolean | undefined;
    emailChecking: boolean;
    token: string | undefined;

    isClosed: boolean;
    isCompleted: boolean;
    isLoading: boolean;

    errors: any[];
    errorTexts: any[];
    submitting: boolean;
  }
> {
  private previousMail : string;

  constructor(props: any) {
    super(props);

    const query = new URLSearchParams(
      window.location.search.replaceAll("%0D", "").replaceAll("%0A", "") //
        .replaceAll("%0d", "").replaceAll("%0a", "")
        .replaceAll("%09", "").replaceAll("%20", "")
    );
    
    this.state = {
      token: query.get("t") ?? "",
      email: "",
      password: "",
      passwordLiveChecking: undefined,
      passwordStrength: undefined,
      rePassword: "",

      emailValid: undefined,
      emailChecking: false,

      isClosed: false,
      isCompleted: false,
      isLoading: false,

      errors: [],
      errorTexts: [],
      submitting: false,
    };

    this.previousMail = this.state.email || "";

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onHideHandler = this.onHideHandler.bind(this);
  }

  render() {
    let errors: any[] = [];
    this.state.errorTexts.forEach((element) => {
      errors.push(
        <Message
          key={errors.length + 1}
          severity="error"
          text={element.msg}
          style={{ marginBottom: 5 }}
        />
      );
    });

    const condition = this.state.isClosed
    if (condition) {
        return <Redirect to='/preventivi/area-personale/login'></Redirect>
    }

    return (
      <>
        <div className="pr-body">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-xl-6 col-lg-8 col-10"> 
                {!this.state.isLoading
                ?      
                <div>
                <Dialog
                  visible={this.state.isCompleted}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "624px" }}
                  header={<h3 className="titleLev5 colorPrimaryBlue">Reset password avvenuto con successo</h3>}
                  onHide={this.onHideHandler}  
                  modal={true}
                  resizable={false}
                  draggable={false}
                  id="passwordResetConfirmation"
                  >
                  <div className="container-fluid">
                    <div className="row">
                      <p>Per accedere clicca sul link qui sotto</p>
                      <Button
                        className="mt-4 customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                        label="Accedi"
                        onClick={this.onHideHandler}
                      />
                    </div>
                  </div>
                </Dialog>

                <Card className="pr-form">
                  <div className="p-fluid p-5">
                    <div className="row">
                      <div className="col-12">
                        <h1 className="titleLev4 colorPrimaryBlue mb-2">
                          Reset password
                        </h1>
                        <p>
                          Inserisci i dati richiesti.
                        </p>
                      </div>
                      <FeedbackBox items={this.state.errorTexts}/>
                    </div>
                    <div className="row">
                      {errors}
                      <div className="col-12 mb-2">  
                        <label htmlFor="email">E-mail</label>
                        {this.state.emailChecking || this.state.emailValid !== undefined
                            ? <span className="p-input-icon-right">
                              {this.state.emailChecking
                                ? <i className="pi pi-spin pi-spinner" />
                                : this.state.emailValid !== undefined
                                  ? this.state.emailValid
                                    ? <i className="pi pi-check" />
                                    : <i className="pi pi-times" />
                                  : <></>
                              }
                                {this.emailInputElement()}
                              </span>
                            : this.emailInputElement()
                          }                      
                        <ContextFeedback
                          show={this.state.errors.includes("email")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "email") ??
                            {msg: "Inserisci una email valida", severity: "error"}
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mb-2">
                        <label className="mt-2" htmlFor="password">
                          Nuova password
                        </label>
                        <Password
                          id="password"
                          placeholder="Inserisci password"
                          name="password"
                          value={this.state.password}
                          onChange={(event) => this.setState({password: event.target.value})}
                          mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
                          strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
                          weakLabel="La password deve essere lungua almeno 6 lettere e contenere almeno una lettera maiuscola e minuscola"
                          mediumLabel=""
                          strongLabel=""
                          toggleMask
                          feedback={true}
                        />                        
                        <ContextFeedback
                          show={this.state.errors.includes("password")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "password") ??
                            {msg: "Inserisci una password valida", severity: "error"}
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mb-2">
                        <label className="mt-2" htmlFor="rePassword">
                          Conferma password
                        </label>
                        <Password
                          id="rePassword"
                          placeholder="Inserisci conferma password"
                          value={this.state.rePassword}
                          onChange={event => this.onChangeHandler(event.target.value, "rePassword")}
                          toggleMask
                          feedback={false}
                        ></Password>                        
                        <ContextFeedback
                          show={this.state.errors.includes("rePassword")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "rePassword") ??
                            {msg: "Verifica la password di conferma", severity: "error"}
                          }
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <Button
                          id="submitButton"
                          label="Salva modifiche"
                          className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                          onClick={() => {
                            this.handleFormSubmit();
                          }}                        
                          loading={this.state.submitting}
                        >                      
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
                </div>              
                :
                <div>
                  <StepLoaderSpinner title="Stai per essere reindirizzato alla pagina di Login"/>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  private emailInputElement() : JSX.Element{
    return <InputText
      id="email"
      placeholder="E-mail"
      name="email"
      type="email"
      value={this.state?.email}
      onChange={event => this.onChangeHandler(event.target.value, "email")}
      onBlur={(event) => { this.emailHandler(event.target.value) }}
    />
  }

  private passwordValidation(){
    let current_errors: any = [];
    const validation = passwordSecurityLevel(this.state.password);

    if(!validation.valid){
      current_errors.push("Assicurati che la password sia lunga almeno 6 lettere e contenga almeno una lettera minuscola e una maiuscola");
      this.setState({passwordStrength: undefined})
      
    }
    else{
      this.setState({
        passwordStrength: validation.strong
        ? PasswordStrength.STRONG
        : validation.medium
          ? PasswordStrength.MEDIUM
          : PasswordStrength.WEAK,
        errorTexts: this.state.errorTexts.filter(e => e.field !== "password"),
        errors: this.state.errors.filter(e => e !== "password"),
      })
    }
    
    if(current_errors.length > 0){
      let errorTexts = this.state.errorTexts.filter(e => e.field !== "password");
      errorTexts.push({field: "password", msg: current_errors.join("<\br>")});
      this.setState({errorTexts: errorTexts});
    }

    return current_errors;
  }
    
  private rePasswordValidation(){
    let current_errors: any = [];
    if(this.state.rePassword !== this.state.password){
      current_errors.push("La password di conferma è diversa")
    }
    return current_errors;
  }

  private validation(specificField: undefined | string = undefined) : boolean{
    let current_errors: any = [];
    this.setState({emailValid: false, errors: [...this.state.errors, "email"]})
    
    if ((specificField === "email" || !specificField) && (this.state?.emailValid === undefined || this.state?.emailValid === false)){
      current_errors.push("email");
    }

    if ((specificField === "password" || !specificField)) {
      const passErrs : any[] = this.passwordValidation();
      if(passErrs.length > 0){
        current_errors.push("password");
      }
    }

    if (specificField === "rePassword" || !specificField) {
      const passErrs : any[] = this.rePasswordValidation();
      if(passErrs.length > 0){
        current_errors.push("rePassword");
      }
    }

    if (specificField === undefined) {
      this.setState({ errors: current_errors });
      setTimeout(() => {
        if (current_errors.length > 0) {
          let errorFields = document.getElementsByClassName("error-field");
          let errorField = errorFields.length > 0 ? errorFields[0] : undefined;
          if (errorField) {
            window.scrollTo(
              0,
              (errorField as any).offsetTop -
                (errorField as any).offsetHeight -
                100
            );
          }
        }
      }, 300);
    }
    else {
      this.setState({ errors: [...this.state.errors, ...current_errors] });
    }

    return current_errors.length === 0;
  }
  
  /**
   * Reset form element validation
   * @param element
   * @returns
   */
   resetValidation(element: any) {    
    this.setState({
      errors: this.state.errors.filter((item: any) => item !== element),
      errorTexts: []    
    });
    return;
  }

  private onChangeHandler(selected: any, componentName: string) {

    if(componentName === "password"){
      const passValidation = passwordSecurityLevel(this.state.password);
      
      let newState : any = {
        [componentName]: selected,
        passwordStrength: undefined
      };

      if(passValidation.valid){
        newState.passwordStrength = passValidation.strong
          ? PasswordStrength.STRONG
          : passValidation.medium
            ? PasswordStrength.MEDIUM
            : PasswordStrength.WEAK;
        newState.passwordLiveChecking = true;
      }
      else{
        newState.passwordLiveChecking = false;
      }

      this.setState({ ...this.state, ...newState});
    }
    else{
      this.setState({ ...this.state, [componentName]: selected });
      this.resetValidation(componentName);
    }
  }


  private formSubmit() {
    this.setState({submitting: true}, () => {
      const formData = {
        email: this.state.email,
        new_password: this.state.password
      }

      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.append('Content-Type', 'application/json; charset="utf-8"');
      requestHeaders.append('Authorization', 'Bearer '+this.state.token);

      UserService.call(
        Constants.WEB_SITE_API_SERVER_URL('/users/password/reset/apply'),
        {
          method: "POST",
          headers: requestHeaders,
          body: JSON.stringify(formData)
        },
        (response: any) => {
          let newState:any = {
            submitting: false          
          }

          if(response?.success === false){
            // caso di errore applicativo
           if(response.error_code === "GENERIC"){
              throw new Error("Qualcosa è andato storto, verifica i dati inseriti e riprova.");
            }
        } else{
          
          let newState:any = {
            submitting: false,
            isCompleted: true
          }
  
          if(response.status === "success"){
          }
          else if(response.status === "error"){
            newState.submitting = false;
            newState.isCompleted = true;
          }
          this.setState({...newState});
        }

          this.setState({...newState});
        },
        (err: any) => {
          let textErrors : IFeedbackText[] = this.state.errorTexts.filter(elem => elem.field !== "generic");
          let errors : string[] = this.state.errors.filter(elem => elem !== "generic");
          
          textErrors.push({field: "generic", msg: err.message});
          errors.push("generic");

          let newState:{} = {
            submitting: false,
            errors: errors 
          }

          this.setState({submitting: false});
          this.setState({...newState});
        }
      )
    })
  }

  private onHideHandler() {    
      this.setState({isLoading: true})
      setTimeout(() => {            
          this.setState({isClosed: true})            
      }, 3000)        
  }

  private handleFormSubmit() {
    if (!this.validation()) {
      return;
    }
    this.formSubmit();
  }
    
  private emailHandler(candidateEmail: string, skipPrevValueControl:boolean = false, callback?: Function){
    if(skipPrevValueControl || candidateEmail !== this.previousMail){
      if(candidateEmail === ""){
        this.setState({
          emailValid: false,
          emailChecking: false,
          errors: [...this.state.errors.filter((err: string) => err !== "email")],
          errorTexts: [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "email")],
        })
      }
      else {

        this.setState({emailChecking: true}, () => {
          isEmailValidCustom(
            candidateEmail || "", false,
            (result: {email: string, valid_syntax: boolean, valid_dns: boolean, valid: boolean}) => {
              let textErrors : any[] = this.state.errorTexts.filter(elem => elem.field !== "email");
              let errors : any[] = this.state.errors.filter(elem => elem !== "email");

              if(result.valid) {
                this.setState({
                  emailValid: true,
                  emailChecking: false,
                }) 
              } else {
                textErrors.push({field: "email", msg: "Inserisci un indirizzo email valido"});
                errors.push("email");

                this.setState({
                  emailValid: false,
                  emailChecking: false,
                  errors: errors,
                  errorTexts: textErrors
                })
              }
            },
            (err: any) => { // errore validazione
              let textErrors : any[] = this.state.errorTexts.filter(elem => elem.field !== "email");
              let errors : any[] = this.state.errors.filter(elem => elem !== "email");
              
              textErrors.push({field: "email", msg: "Non è stato possibile validare l'indirizzo email"});
              errors.push("email");

              this.setState({
                emailValid: false,
                emailChecking: false,
                errors: errors,
                errorTexts: textErrors
              })
            }
          );


        })
      }
    }
  }
}

const ResetPasswordPage = (props: any) => {
  return <ResetPasswordPageComponent {...props} params={useParams()}/>
}

export default ResetPasswordPage;
