import { Component, useContext } from "react";
import { Tooltip } from "primereact/tooltip";
import { Accordion } from "react-bootstrap";
import { AjaxAutocompleteObject, AjaxEnrichedAutocompleteObject, IVehicle_WithErrors } from "../utils/common";
import { fetchList, sortJson } from "../utils/methods";
import { FeedbackBox } from "../utils/Feedback";
import * as Constants from "../utils/constants";
import User from "../utils/User";
import { VehicleFieldBuilder } from "../utils/VehicleFieldBuilder";
import { DataList, IDataList } from "./dataListContext";

interface VehicleListItemLabelProps{
  data: IVehicle_WithErrors;
  parentId: string;
  selected: boolean;
  action?: any
  antitheftList: AjaxEnrichedAutocompleteObject[],
}
interface VehicleListItemLabelState{
  fuelTypeList: AjaxAutocompleteObject[]
}
class VehicleListItemLabelComponent extends Component<VehicleListItemLabelProps, VehicleListItemLabelState>{
  private readonly fields: any;
  private abortController: AbortController = new AbortController();
  private _isMounted = false;

  constructor(props: any){
    super(props);

    this.fields = VehicleFieldBuilder(this.props.data.vehicleType, false);

    this.state = {
      fuelTypeList: []
    }
  }

  componentWillUnmount(): void {
    console.log("VehicleListItemLabelComponent unmount")
    this._isMounted = false;
    this.abortController.abort();
  }

  componentDidMount(){
    this._isMounted = true;
    
    if (
      this.props.data.vehicleRegistrationYear !== undefined &&
      this.props.data.vehicleRegistrationMonth !== undefined &&
      this.props.data.vehicleBrand !== undefined &&
      this.props.data.vehicleModel !== undefined &&
      this.props.data.vehicleFuelType !== undefined
    ) {
      fetchList(
        Constants.SEISICURO_AJAX_CAR_FUEL_TYPE_ENDPOINT(
          this.props.data.vehicleRegistrationYear,
          this.props.data.vehicleRegistrationMonth,
          this.props.data.vehicleBrand,
          this.props.data.vehicleModel
        ),
        (result: any) => {
          let refinedResult = result.map((element: any) => {
            return { ...element, value: element.value.trim() };
          });

          this.setState({fuelTypeList: refinedResult})
        }, undefined, undefined, this.abortController.signal
      );
    }

  }

  render(){

    const vehicle = this.props.data;
    let monthName: string = "";
    if (vehicle?.vehicleRegistrationMonth) {
      let date = new Date();
      date.setMonth(vehicle.vehicleRegistrationMonth - 1);
      monthName = date.toLocaleString("it-IT", { month: "long" }) + " ";
    }

    let titleParts = [];
    if(vehicle?.veicleFullDescription){
      titleParts.push(vehicle.veicleFullDescription);
    }
    if(vehicle?.vehicleLicensePlateNumber){
      titleParts.push(vehicle.vehicleLicensePlateNumber);
    }

    const mainName = `${vehicle?.vehicleBrandLabel ? vehicle.vehicleBrandLabel : ""} ${vehicle?.vehicleModelLabel ? vehicle.vehicleModelLabel : ""}`.trim();
    
    return <>
        <span className="personFieldTitle">{User.getVehicleSafeName(vehicle)}</span>
        {this.props.data?.errors && this.props.data?.errors.length > 0 && 
          <>
            <Tooltip className="yellow-tooltip" target={`#${this.props.parentId}-warning-sign`}>Informazioni incomplete</Tooltip>
            <i id={`${this.props.parentId}-warning-sign`} className="pi pi-exclamation-triangle ms-2" />                  
          </>
        }

        <Accordion.Collapse eventKey={`${this.props.parentId}`}>
          <>
            <p>
              {mainName !== "" && <><span>{mainName}</span><br/></>}
              {`Prima immatricolazione: ${monthName}${vehicle?.vehicleRegistrationYear ? vehicle.vehicleRegistrationYear : "-"}, acquistato nel ${vehicle?.vehicleBuyYear ? vehicle.vehicleBuyYear : "-"}`}
              <br/>
                                   
              {
                vehicle.vehicleType === "auto" && 
                <>              
                  {`Alimentazione: ${vehicle?.vehicleFuelType
                    ? this.state.fuelTypeList.find((elem: AjaxAutocompleteObject) => elem.id === vehicle.vehicleFuelType)?.value ?? ""
                    : "-"}, Cilindrata ${vehicle?.vehicleEngineDisplacement ? vehicle.vehicleEngineDisplacement : "-"}`}
                  <br/>

                  {vehicle?.vehicleFuelType !== 'E' ? `Impianto GPL: ${
                    vehicle?.vehicleGplMounted !== undefined
                    ? vehicle.vehicleGplMounted ? "Sì" : "No"
                    : "-"
                  }, Gancio di traino: ${
                    vehicle?.vehicleTowbarMounted !== undefined
                    ? vehicle.vehicleTowbarMounted ? "Sì" : "No"
                    : "-"
                  }` : `Gancio di traino: ${vehicle?.vehicleTowbarMounted !== undefined
                    ? vehicle.vehicleTowbarMounted ? "Sì" : "No"
                    : "-"}`}
                  <br/>
                </>
              }                        
              {`Antifurto: ${vehicle?.vehicleAntitheft 
                ? this.props.antitheftList.find((elem: AjaxEnrichedAutocompleteObject) => elem.value === vehicle.vehicleAntitheft)?.label ?? ""
                : "-"}`
              }
            </p>         
            {vehicle.errors && vehicle.errors.length > 0 &&              
              <FeedbackBox
                items={[{field:"generic", msg: `Attenzione informazioni mancanti: 
                ${vehicle.errors.map((field: string) => this.fields[field]?.notification_label ?? 
                  this.fields[field].label_human_readable ?? this.fields[field]).join(", ")}`, severity:"warn"}]}
                filter={{field:"generic", severity:"warn"}}  
              />
            }

            {this.props?.action &&
              <span {...this.props.action?.attributes}>{this.props.action.label}</span>
            }
          </>
        </Accordion.Collapse>
      </>
  }

}

const VehicleListItemLabel = (props: any) => {
  const lists: IDataList = useContext(DataList);
  return <VehicleListItemLabelComponent {...props} {...lists}/>
}

export default VehicleListItemLabel;
