import { useContext } from "react";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import PagesContext from "./customs/HOC/useAreaPersonalePages";
import QuoteList from "./QuoteList";
import { useHistory, useRouteMatch } from "react-router-dom";
import '../scss/quotation-row.scss';

export const AreaPersonalePreventiviProdottiPerson = (props: IPageProps) => {
  const pages = useContext(PagesContext);
  const {params} = useRouteMatch();
  const quoteType: string = (params as {quoteType?: string})?.quoteType ?? "";  
  return <QuoteList contractorId={""} quoteType={quoteType} contractorType={"person"} pages={pages} {...props} history={useHistory()} />;
};

export const AreaPersonalePreventiviProdottiBusiness = (props: IPageProps) => {
  const pages = useContext(PagesContext);
  const {params} = useRouteMatch();
  const companyId: string = (params as {companyId?: string})?.companyId ?? "";
  const quoteType: string = (params as {quoteType?: string})?.quoteType ?? "";  
  return <QuoteList contractorId={companyId} quoteType={quoteType} contractorType={"business"} pages={pages} {...props} history={useHistory()} />;
};