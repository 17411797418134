import { connect } from 'react-redux';
import * as Constants from '../utils/constants';
import MenuDropDown from './MenuDropDown';

interface IMenuProps{
  telClick: Function;
  vehicle: string;
}

const Menu = (props: IMenuProps) => {


  return <div className="pr-header fixed-top">
    <div className='container-fluid'>
      <div className='header-top row justify-content-center'>
        <div className='col-lg-11 col-12'>
          <div className="row align-items-center py-3">
            <div className="col-6">
              <a className="img-brand" href="https://www.6sicuro.it" title="6sicuro">
                <img alt="Assicurazioni online 6sicuro" src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/logo-navbar.svg`} width="128.6" height="50" />
              </a>
            </div>
            <div className="col-6 text-end">
              <div className="call-region">
                <a className="link" href="tel:+390281480350" onClick={(e) => props.telClick(e)} rel="noopener noreferrer" aria-label="numero di telefono 6sicuro"><i className="pi pi-phone" ></i> 0281480350</a>
              </div>
              <div className="ms-3 user-panel">
                <div className="login-region">
                  <MenuDropDown vehicle={props.vehicle}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

}

const mapState = (state: any) => {
  return {
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

export default connect(mapState)(Menu);