
import { Component} from "react";
import { step1DataSuccess, step2DataSuccess, step3DataFailure, step3DataSuccess, step4DataSuccess, userInfoDataSuccess } from "../redux/actions";
import { extractAddressData, fetchList, formatDateAdform, priceFormatter } from "./utils/methods";
import CookieService from "./utils/CookieService";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { IPerson, IVehicle, Step1Data, Step2Data, Step3Data, Step4Data } from "./utils/common";
import * as Constants from './utils/constants';
import User from "./utils/User";
import { Link, useHistory } from "react-router-dom";
import { step1InitialState, step2InitialState, step3InitialState, step4InitialState, StepDataDualState } from "../redux/reducers";
import { connect } from "react-redux";
import UserService from "./utils/UserService";
import { IWithEventManager } from "./customs/HOC/EventManager";
import withEventManager from "./customs/HOC/withEventManager";
import { compose } from "ramda";

interface ICardPreventivoProps extends IWithEventManager{
  quotation: any;
  vehicleId: number | string;
  history: ReturnType<typeof useHistory>;
  step1Data: StepDataDualState;
  step2Data: StepDataDualState;
  step4Data: StepDataDualState;
  step1DataSuccess: Function;
  step2DataSuccess: Function;
  step3DataSuccess: Function;
  step4DataSuccess: Function;
  step3DataFailure: Function;
  setVehicle: Function;
}

interface ISalvati{
  status: string;
  data: any;
}

interface ICardPreventivoState{
  error_visible: boolean;
  loading: boolean; // flag loading ricalcolo url
  errors: any[];
  insuranceStatusList: any[];
  salvati?: ISalvati;
  yetValid?: boolean; // flag che mi indica se il preventivo è ancora attivo o meno
                      // TRUE -> preventivo creato meno di 2gg fa ||
                      //         salvataggi && almeno 1 salvataggio con exp_date non ancora superata
                      // FALSE -> preventivo creato più di 2gg fa && nessun salvataggio ||
                      //         preventivo creato più di 2gg && tutti i salvataggi hanno superato exp_date 
}

class CardPreventivoComponent extends Component<ICardPreventivoProps, ICardPreventivoState> {
  private quotation : any;
  private user: User;

  constructor(props: ICardPreventivoProps){
    super(props);
    this.user = User.getInstance();
    this.state = {
      error_visible: false,
      loading: false,
      errors: [],
      insuranceStatusList: []
    }
  }

  componentDidMount(): void {
    fetchList(Constants.SEISICURO_AJAX_INSURANCE_STATE_ENDPOINT, (result: any) => {
      this.setState({ insuranceStatusList: result });
    });
  }
  private getDataToTrack(vehicle: string){
    const step1Data: Step1Data = this.props.step1Data[vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[vehicle as keyof StepDataDualState] as Step2Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    return {
      prodotto: vehicle,
      gender: step1Data.gender,
      dataNascita: formatDateAdform(step1Data.dateOfBirth),
      titoloStudio: step1Data.degree,
      professione: step1Data.profession,
      dataImmatricolazione: `${
        step2Data.vehicleRegistrationMonth !== undefined &&
        step2Data.vehicleRegistrationMonth < 10
          ? `0${step2Data.vehicleRegistrationMonth}`
          : step2Data.vehicleRegistrationMonth
      }/${step2Data.vehicleRegistrationYear}`,
      annoAcquisto: step2Data.vehicleBuyYear,
      veichleBrand: step2Data.vehicleBrandLabel,
      veichleModel: step2Data.vehicleModelLabel,
      /*emailKript: encryptEmail(this.state.contractorEmail),
      email: this.state.contractorEmail,
      telefono: this.state.contractorPhoneNumber,
      name: this.state.contractorName,
      surname: this.state.contractorSurname,
      mktgAssicurativo: this.state.acceptGdprPrivacyPolicy01,
      mktgNonAssicurativo: this.state.acceptGdprPrivacyPolicy02,
      newsletter: this.state.acceptGdprPrivacyPolicy03,
      profilazione: this.state.acceptGdprPrivacyPolicy04,
      cessioneAssicurativo: this.state.acceptGdprPrivacyPolicy05,
      cessioneNonAssicurativo: this.state.acceptGdprPrivacyPolicy06,
      gdpr: this.state.acceptGdprPrivacyPolicy,*/
    }
  }

  /**
   * Trak tag-manager: notifica errore
   */
  private trackSendFailure(vehicle : string) {
    this.props.trackEvent?.({
      name: "AP-quote_fail",
      data: {...this.getDataToTrack(vehicle), errors: this.state.errors},
    })
  }

  /**
   * Trak tag-manager: notifica successo
   */
  private trackSendSuccess(callbackFunction: Function, vehicle : string) {
    this.props.trackEvent?.({
      name: "AP-quote_success",
      data: this.getDataToTrack(vehicle),
      callback: callbackFunction()
    })
  }

  /**
   * Converts a month index number (1-12) to the corresponding italian month name.
   *
   * @param index
   * @returns
   */
   private monthIndexToName(index: number | undefined): string | undefined {
    if (index === undefined) {
      return undefined;
    } else {
      return Constants.MONTHS_LIST_IT.find(
        (element) => element.value === index
      )?.label.toLowerCase();
    }
  }

  /**
   * Visibility check for the youngest child dropdown.
   *
   * @returns {boolean} whether the youngest child dropdown should be visible
   */
   private isVisiblecontractorYoungestChild(contractorChildren : any) {
    let is_visible;
    if (contractorChildren === undefined || contractorChildren.includes(0)) {
      is_visible = false;
    }
    else {
      is_visible = contractorChildren.includes(1);
    }
    return is_visible;
  }

  /**
   * Visibility check for the owner form part
   *
   * @returns {boolean} whether the owner form part should be visible
   */
   private isVisibleOwner(step3Data : any) {
    let is_visible;
    if (step3Data.contractorAndOwnerCoincide === undefined) {
      is_visible = false;
    } else {
      is_visible = step3Data.contractorAndOwnerCoincide !== "si" ? true : false;
    }
    return is_visible;
  }

  /**
   * Visibility check for the driver form part
   *
   * @returns {boolean} whether the driver form part should be visible
   */
   private isVisibleDriver(step3Data : any) {
    let is_visible;
    if (step3Data.ownerAndDriverCoincide === undefined) {
      is_visible = false;
    } else {
      is_visible =
        (!this.isVisibleOwnerAndDriverCoincide(step3Data) &&
          step3Data.contractorAndDriverCoincide !== "si") ||
        (step3Data.ownerAndDriverCoincide !== "si" &&
          step3Data.contractorAndDriverCoincide !== "si")
          ? true
          : false;
    }
    return is_visible;
  }

  /**
   * Visibility check for the "more questions" (i.e. license points, license suspensions, etc) form part.
   *
   * @returns {boolean} whether the "more questions" (i.e. license points, license suspensions, etc) form part should be visible
   */
   private isVisibleMoreQuestions(step3Data : any) {
    let is_visible;
    if (step3Data.contractoDeclares === undefined) {
      is_visible = false;
    } else {
      is_visible = step3Data.contractoDeclares ? false : true;
    }
    return is_visible;
  }

  private isVisibleOwnerAndDriverCoincide(step3Data : any) {
    let is_visible;
    if (
      step3Data.contractorAndDriverCoincide === undefined &&
      step3Data.contractorAndOwnerCoincide === undefined
    ) {
      is_visible = false;
    } else {
      is_visible =
        step3Data.contractorAndDriverCoincide !== "si" &&
        step3Data.contractorAndOwnerCoincide !== "si";
    }
    return is_visible;
  }

  private setFormData(quote : any): URLSearchParams {
    let formData: URLSearchParams = new URLSearchParams();

    //Step 1
    formData.append("contraente.partitaIva", "");
    formData.append("contraente.denominazioneSociale", "");
    formData.append("contraente.settoreMerceologico", "");
    //
    formData.append("contraente.sesso", quote.step1Data.gender);
    formData.append("contraente.dataDiNascita+giorno", new Date(quote.step1Data.dateOfBirth).getDate().toString());
    formData.append("contraente.dataDiNascita+mese", new Date(quote.step1Data.dateOfBirth).toLocaleString("it-IT", {month: "long"}));
    formData.append("contraente.dataDiNascita+anno", new Date(quote.step1Data.dateOfBirth).getFullYear().toString());
    formData.append("contraente.natoInItalia", quote.step1Data.bornInItaly);
    if (quote.step1Data.bornInItaly) {
      formData.append("contraente.luogoDiNascitaItalia.id", quote.step1Data.cityOfBirth?.id);
    }
    else {
      formData.append("contraente.luogoDiNascitaEstero.id", quote.step1Data.countryOfBirth?.id);
    }
    formData.append("contraente.statoCivile", quote.step1Data.civilState);
    formData.append("contraente.titoloDiStudio", quote.step1Data.degree);
    formData.append("contraente.professione", quote.step1Data.profession);


    //Step 2
    if(quote.step2Data?.vehicleLicensePlateNumber){
      formData.append("veicolo.targa", quote.step2Data.vehicleLicensePlateNumber);
      formData.append("veicolo.ricordaLaTarga", "true");
    }
    else{
      formData.append("veicolo.ricordaLaTarga", "false");
    }
    formData.append("veicolo+nonConosceLaTarga", `${!quote.step2Data?.vehicleLicensePlateNumber}`);
    formData.append("veicolo.meseDiImmatricolazione", `${this.monthIndexToName(quote.step2Data.vehicleRegistrationMonth)}`);    
    formData.append("veicolo.annoDiImmatricolazione", quote.step2Data.vehicleRegistrationYear);
    formData.append("veicolo.annoAcquisto", quote.step2Data.vehicleBuyYear);
    formData.append("veicolo.marca", quote.step2Data.vehicleBrand);
    formData.append("veicolo.modello.id", quote.step2Data.vehicleModel);
    formData.append("veicolo.allestimento.id", quote.step2Data.vehicleFitting);
    formData.append("veicolo.antifurto", quote.step2Data.vehicleAntitheft);
    if (quote.vehicleType === "auto") {
      formData.append("utilizzoTipico", quote.step2Data.vehicleUse);
      formData.append("veicolo.gancioTraino", quote.step2Data.vehicleTowbarMounted);
      formData.append("veicolo.alimentazione", quote.step2Data.vehicleFuelType);
      
      if (quote.step2Data.vehicleFuelType !== "E") {
        formData.append("veicolo.impiantoGPL", `${quote.step2Data.vehicleGplMounted ?? false}`);
        formData.append("veicolo.cilindrata", `${quote.step2Data.vehicleEngineDisplacement}`);
      }
      else { 
        formData.append( "veicolo+cilindrata", "0");
      }
      
      if (quote.step3Data.includeSatProducts !== undefined) {
        formData.append("mostraOfferteBlackBox", quote.step3Data.includeSatProducts);
      }

    }
    
    formData.append("parcheggioNotturno", quote.step2Data.vehicleParkingLocation);
    formData.append("chilometriPerAnno", quote.step2Data.kilometersPerYear ? quote.step2Data.kilometersPerYear : 0);
    formData.append("contraente.numeroAuto", quote.step2Data.moreThanOneVehicle ? "dueOPiu" : "una");

    //Step 3
    formData.append("vehicle", quote.vehicleType);
    formData.append("step", "3");
    formData.append("contraente.nome", quote.step3Data.contractorName);
    formData.append("contraente.cognome", quote.step3Data.contractorSurname);
    if (quote.step3Data.contractorCityOfResidence) {
      formData.append("contraente.residenza.comune.id", (quote.step3Data.contractorCityOfResidence as any)?.id);
    }
    if (quote.step3Data.contractorPostalCode) {
      formData.append("contraente.residenza.cap", quote.step3Data.contractorPostalCode);
    }
    // formData.append("contraente.residenza.toponimo", quote.step3Data.contractorAddressToponimo);
    // let tmp_indirizzo = quote.step3Data.contractorAddress.replace(",", "").substring(0, 50);
    // formData.append("contraente.residenza.indirizzo", tmp_indirizzo);
    // formData.append("contraente.residenza.numero", quote.step3Data.contractorAddressNumber);
    if (quote.step3Data.contractorAddress) {
      let { toponimo, indirizzo, } = extractAddressData(quote.step3Data.contractorAddress);
      formData.append("contraente.residenza.toponimo", `${toponimo}`);
      formData.append("contraente.residenza.indirizzo", `${indirizzo}`);
    }
    if (quote.step3Data.contractorAddressNumber) {
      formData.append("contraente.residenza.numero", quote.step3Data.contractorAddressNumber);
    }
   
    if (quote.step3Data.contractorChildren.includes(0)) {
      formData.append("contraente.figliNo", "true");
    }
    if (quote.step3Data.contractorChildren.includes(1)) {
      formData.append("contraente.figliMinori", "true");
    }
    if (quote.step3Data.contractorChildren.includes(2)) {
      formData.append("contraente.figliMaggiori", "true");
    }
    if (this.isVisiblecontractorYoungestChild(quote.step3Data.contractorChildren) && quote.step3Data.contractorYoungestChild) {
      formData.append("contraente.etaFiglioMinore", quote.step3Data.contractorYoungestChild);
    }
    if (quote.step3Data.contractorEmail) {
      formData.append("contraente.email", quote.step3Data.contractorEmail);
    }
    if (quote.step3Data.contractorPhoneNumber) {
      formData.append("contraente.numeroCellulare", quote.step3Data.contractorPhoneNumber);
    }
    if (quote.step3Data.contractorItalianDrivingLicense) {
      formData.append("contraente.patenteItaliana", quote.step3Data.contractorItalianDrivingLicense);
    }
    if (quote.step3Data.contractorDrivingLicenseAge) {
      formData.append("contraente.etaPatente", quote.step3Data.contractorDrivingLicenseAge);
    }


    if (quote.step3Data.contractorPreferredPaymentMethod) {
      formData.append("metodoPagamento", quote.step3Data.contractorPreferredPaymentMethod);
    }
    if (quote.step3Data.newInsuranceStart) {
      formData.append("dataDecorrenzaPolizza.giorno", new Date(quote.step3Data.newInsuranceStart).getDate().toString());
      formData.append("dataDecorrenzaPolizza.mese", new Date(quote.step3Data.newInsuranceStart).toLocaleString("it-IT", {month: "long"}));
      formData.append("dataDecorrenzaPolizza.anno", new Date(quote.step3Data.newInsuranceStart).getFullYear().toString());
    }
    if (quote.step3Data.vehicleInsuranceStatus) {
      formData.append("dettagliAssicurazione.statoAssicurativo", quote.step3Data.vehicleInsuranceStatus);


      if (quote.step3Data.vehicleInsuranceStatus === "assicurataAMioNome") {
        
        if (quote.step3Data.currentInsuranceCreditClass) {
          formData.append("dettagliAssicurazione.classeDiMerito", quote.step3Data.currentInsuranceCreditClass);
        }
        if ((
          quote.step3Data.currentInsuranceCreditClass !== undefined &&
          quote.step3Data.currentInsuranceCreditClass === "c1DaOltreUnAnno" &&
          this.state.insuranceStatusList !== undefined &&
          this.state.insuranceStatusList.length > 0 &&
          quote.step3Data.vehicleInsuranceStatus ===
          this.state.insuranceStatusList[0].value
        ) && quote.step3Data.yearsInClass1) {
          formData.append("dettagliAssicurazione.anzianitaPrimaClasse", `${quote.step3Data.yearsInClass1}`);
        }
        if (quote.step3Data.currentInsuranceCompany) {
          formData.append("dettagliAssicurazione.compagniaAssicurazioneAttuale", quote.step3Data.currentInsuranceCompany);
        }
        if (quote.step3Data.currentInsuranceYears) {
          formData.append("dettagliAssicurazione.etaAssicurativa", quote.step3Data.currentInsuranceYears);
        }
        if (quote.step3Data.pastBersaniDecree !== undefined) {
          formData.append("dettagliAssicurazione.bersani", quote.step3Data.pastBersaniDecree);
        }
        if (quote.step3Data?.pastAccidentCount !== undefined) {
          formData.append("dettagliAssicurazione.numeroIncidenti", quote.step3Data.pastAccidentCount);
        }

        if (
          quote.step3Data.pastAccidentCount !== undefined
            ? quote.step3Data.pastAccidentCount > 0
            : false
        ) {
          for (let i = 0; i < quote.step3Data.pastAccidentsInfo.length; i++) {
            formData.append(`dettagliAssicurazione.attestatoDiRischio[${i}].anno`, quote.step3Data.pastAccidentsInfo[i].year);
            formData.append(`dettagliAssicurazione.attestatoDiRischio[${i}].responsabilita`, quote.step3Data.pastAccidentsInfo[i].responsibility);
          }
        }
      }
      else{
        //step3Data.vehicleInsuranceStatus "aMioNomePrimaVoltaNuovo" || "aMioNomePrimaVoltaUsato"
      
        if (quote.step3Data.bersaniDecree) {
          formData.append("dettagliAssicurazione.bersaniApplicabilita", `${quote.step3Data.bersaniDecree}`);
  
          if (quote.step3Data.bersaniDecree === "siVeicoloDelloStessoProprietario" ||
          quote.step3Data.bersaniDecree === "SiVeicoloDiFamiliareConvivente") {

            if(quote.step3Data.cuLicensePlate){
              formData.append("targaBersani", quote.step3Data.cuLicensePlate);
            }

            if (quote.step3Data.cuInsuranceClass) {
              formData.append("dettagliAssicurazione.classeDiMeritoBersani", `${quote.step3Data.cuInsuranceClass}`);
            }
          }

        }
        
      }

    }

    if (quote.step3Data.contractorAndOwnerCoincide) {
      formData.append("contraenteProprietarioNew", `${quote.step3Data.contractorAndOwnerCoincide}`);
    }
    if (quote.step3Data.contractorAndDriverCoincide) {
      formData.append("contraenteConducenteAbitualeNew", `${quote.step3Data.contractorAndDriverCoincide}`);
    }
    if (this.isVisibleOwnerAndDriverCoincide(quote.step3Data) &&
      quote.step3Data.ownerAndDriverCoincide
    ) {
      formData.append("proprietarioConducenteAbitualeNew", `${quote.step3Data.ownerAndDriverCoincide}`);
    }
    if (quote.step3Data.otherDriversExist !== undefined) {
      formData.append("contraente.altriConducenti", `${quote.step3Data.otherDriversExist}`);
    }
    if (quote.step3Data.youngestOtherDriverAge) {
      formData.append("etaConducentePiuGiovane", `${quote.step3Data.youngestOtherDriverAge}`);
    }

    if (quote.step3Data.otherDriversExist &&
      quote.step3Data.otherDriversNoviceExist !== undefined
    ) {
      formData.append("contraente.conducenteNeoPatentato", `${quote.step3Data.otherDriversNoviceExist}`);
    }
    else {
      formData.append("contraente.conducenteNeoPatentato", "false");
    }

    if (quote.step3Data.youngestFamilyDriver) {
      formData.append("contraente.etaResidentePiuGiovane", `${quote.step3Data.youngestFamilyDriver}`);
    }
    else{
      formData.append("contraente.etaResidentePiuGiovane", "nessuno");
    }

    if (quote.step3Data.contractoDeclares !== undefined) {
      formData.append("domandone", `${quote.step3Data.contractoDeclares}`);
    }
    if (quote.step3Data.includeNonCard !== undefined) {
      formData.append("mostraOfferteNoCard", `${quote.step3Data.includeNonCard}`);
    }

    if (this.isVisibleOwner(quote.step3Data)) {
      // TODO: Il vecchio preventivatore setta i prossimi quattro campi a stringa vuota, verificare se sono effettivamente necessari
      formData.append("proprietario.partitaIva", "");
      formData.append("proprietario.denominazioneSociale", "");
      formData.append("proprietario.settoreMerceologico", "");
      formData.append("proprietario.tipoSocieta", "");

      if (quote.step3Data.owner.gender !== undefined) {
        formData.append("proprietario.sesso", `${quote.step3Data.owner.gender}`);
      }
      if (quote.step3Data.owner.name) {
        formData.append("proprietario.nome", `${quote.step3Data.owner.name}`);
      }
      if (quote.step3Data.owner.surname) {
        formData.append("proprietario.cognome", `${quote.step3Data.owner.surname}`);
      }
      if (quote.step3Data.owner.dateOfBirth) {
        let ownerDate: Date | undefined = undefined;
        if(!isNaN(quote.step3Data.owner.dateOfBirth)){
          ownerDate = new Date(quote.step3Data.owner.dateOfBirth)
        }
        else if(!isNaN(quote.step3Data.owner.dateOfBirth?.getDate())){
          ownerDate = quote.step3Data.owner.dateOfBirth;
        }
        
        if(ownerDate !== undefined){
          formData.append("proprietario.dataDiNascita.giorno", ownerDate.getDate().toString());
          formData.append("proprietario.dataDiNascita.mese", ownerDate.toLocaleString("it-IT", { month: "long" }));
          formData.append("proprietario.dataDiNascita.anno", ownerDate.getFullYear().toString());
        }
      }
      if (quote.step3Data.owner.bornInItaly !== undefined) {
        formData.append("proprietario.natoInItalia", quote.step3Data.owner.bornInItaly);
      }
      if (quote.step3Data.owner.cityOfBirth) {
        formData.append("proprietario.luogoDiNascitaItalia.id", (quote.step3Data.owner.cityOfBirth as any)?.id);
      }
      if (quote.step3Data.owner.countryOfBirth) {
        formData.append("proprietario.luogoDiNascitaEstero.id", (quote.step3Data.owner.countryOfBirth as any)?.id);
      }
      if (quote.step3Data.owner.cityOfResidence) {
        formData.append("proprietario.residenza.comune.id", (quote.step3Data.owner.cityOfResidence as any)?.id);
      }
      if (quote.step3Data.owner.postalCodeOfResidence) {
        formData.append("proprietario.residenza.cap", quote.step3Data.owner.postalCodeOfResidence);
      }
      if (quote.step3Data.owner?.address) {
        //TODO: Rivedere questa parte quando avremo l'integrazione con l'API di Google Maps
        // formData.append("proprietario.residenza.toponimo", quote.step3Data.owner.addressToponimo);
        // formData.append("proprietario.residenza.indirizzo", quote.step3Data.owner.address);
        // formData.append("proprietario.residenza.numero", quote.step3Data.owner.addressNumber);
        let { toponimo, indirizzo, } = extractAddressData(quote.step3Data.owner.address);
        formData.append("proprietario.residenza.toponimo", `${toponimo}`);
        formData.append("proprietario.residenza.indirizzo", `${indirizzo}`);
      }
      if (quote.step3Data.owner?.addressNumber) {
        formData.append("proprietario.residenza.numero", quote.step3Data.owner.addressNumber);
      }
      if (quote.step3Data.owner?.civilState) {
        formData.append("proprietario.statoCivile", quote.step3Data.owner.civilState);
      }
      if (quote.step3Data.owner?.children?.includes(0)) {
        formData.append("proprietario.figliNo", "true");
      }
      if (quote.step3Data.owner?.children?.includes(1)) {
        formData.append("proprietario.figliMinori", "true");
      }
      if (quote.step3Data.owner?.children?.includes(2)) {
        formData.append("proprietario.figliMaggiori", "true");
      }
      if (quote.step3Data.owner?.degree) {
        formData.append("proprietario.titoloDiStudio", quote.step3Data.owner.degree);
      }
      if (quote.step3Data.owner?.profession) {
        formData.append("proprietario.professione", quote.step3Data.owner.profession);
      }
      if (quote.step3Data.owner?.italianDrivingLicense !== undefined) {
        formData.append("proprietario.patenteItaliana", quote.step3Data.owner.italianDrivingLicense);
      }
      if (quote.step3Data.owner?.italianDrivingLicense !== undefined) {
        formData.append("proprietario.etaPatente", quote.step3Data.owner.drivingLicenseAge);
      }
    }

    if (this.isVisibleDriver(quote.step3Data)) {
      // TODO: Il vecchio preventivatore setta i prossimi quattro campi a stringa vuota, verificare se sono effettivamente necessari
      formData.append("conducenteAbituale.partitaIva", "");
      formData.append("conducenteAbituale.denominazioneSociale", "");
      formData.append("conducenteAbituale.settoreMerceologico", "");
      formData.append("conducenteAbituale.tipoSocieta", "");
      //
      if (quote.step3Data.driver && quote.step3Data.driver.gender !== undefined) {
        formData.append("conducenteAbituale.sesso", quote.step3Data.driver.gender);
      }
      if (quote.step3Data.driver && quote.step3Data.driver.name) {
        formData.append("conducenteAbituale.nome", quote.step3Data.driver.name);
      }
      if (quote.step3Data.driver && quote.step3Data.driver.surname) {
        formData.append("conducenteAbituale.cognome", quote.step3Data.driver.surname);
      }
      if (quote.step3Data.driver && quote.step3Data.driver.bornInItaly !== undefined) {
        formData.append("conducenteAbituale.natoInItalia", quote.step3Data.driver.bornInItaly);
      }
      if (quote.step3Data.driver && quote.step3Data.driver.postalCodeOfResidence) {
        formData.append("conducenteAbituale.residenza.cap", quote.step3Data.driver.postalCodeOfResidence);
      }
      if (quote.step3Data.driver && quote.step3Data.driver.cityOfBirth) {
        formData.append("conducenteAbituale.luogoDiNascitaItalia.id", (quote.step3Data.driver.cityOfBirth as any)?.id);
      }
      if (quote.step3Data.driver && quote.step3Data.driver.countryOfBirth) {
          formData.append("conducenteAbituale.luogoDiNascitaEstero.id", `${(quote.step3Data.driver.countryOfBirth as any)?.id}`);
      }
      if (quote.step3Data.driver && quote.step3Data.driver.cityOfResidence) {
        formData.append("conducenteAbituale.residenza.comune.id", (quote.step3Data.driver.cityOfResidence as any)?.id);
      }

      if (quote.step3Data.driver &&
        quote.step3Data.driver.dateOfBirth
      ) {

        let driverDate: Date | undefined = undefined;
        if(!isNaN(quote.step3Data.driver.dateOfBirth)){
          driverDate = new Date(quote.step3Data.driver.dateOfBirth)
        }
        else if(!isNaN(quote.step3Data.driver.dateOfBirth?.getDate())){
          driverDate = quote.step3Data.driver.dateOfBirth;
        }
        
        if(driverDate !== undefined){
          formData.append("conducenteAbituale.dataDiNascita.giorno", driverDate.getDate().toString());
          formData.append("conducenteAbituale.dataDiNascita.mese", driverDate.toLocaleString("it-IT", { month: "long" }));
          formData.append("conducenteAbituale.dataDiNascita.anno", driverDate.getFullYear().toString());
        }
      }

      if (quote.step3Data.driver?.address) {
        let { toponimo, indirizzo, } = extractAddressData(quote.step3Data.driver.address);
        formData.append("conducenteAbituale.residenza.toponimo", `${toponimo}`);
        formData.append("conducenteAbituale.residenza.indirizzo", `${indirizzo}`);

        if (quote.step3Data.driver?.addressNumber) {
          formData.append("conducenteAbituale.residenza.numero", quote.step3Data.driver.addressNumber);
        }
      }
      // if (quote.step3Data.driver?.addressToponimo) {
      //   formData.append("conducenteAbituale.residenza.toponimo", quote.step3Data.driver?.addressToponimo);
      // }
      // if (quote.step3Data.driver?.address) {
      //   formData.append("conducenteAbituale.residenza.indirizzo", quote.step3Data.driver?.address);
      // }
      // if (quote.step3Data.driver?.addressNumber) {
      //   formData.append("conducenteAbituale.residenza.numero", quote.step3Data.driver?.addressNumber);
      // }
      if (quote.step3Data.driver?.civilState) {
        formData.append("conducenteAbituale.statoCivile", quote.step3Data.driver.civilState);
      }      
      if (quote.step3Data.driver?.children?.includes(0)) {
        formData.append("conducenteAbituale.figliNo", "true");
      }
      if (quote.step3Data.driver?.children?.includes(1)) {
        formData.append("conducenteAbituale.figliMinori", "true");
      }
      if (quote.step3Data.driver?.children?.includes(2)) {
        formData.append("conducenteAbituale.figliMaggiori", "true");
      }



      if (quote.step3Data.driver?.degree) {
        formData.append("conducenteAbituale.titoloDiStudio", quote.step3Data.driver.degree);
      }
      if (quote.step3Data.driver?.profession) {
        formData.append("conducenteAbituale.professione", quote.step3Data.driver.profession);
      }
      if (quote.step3Data.driver?.italianDrivingLicense !== undefined) {
        formData.append("conducenteAbituale.patenteItaliana", quote.step3Data.driver.italianDrivingLicense);
      }
      if (quote.step3Data.driver?.drivingLicenseAge !== undefined) {
        formData.append("conducenteAbituale.etaPatente", quote.step3Data.driver.drivingLicenseAge);
      }
    }

    if (quote.vehicleType === "moto"){
      formData.append("cilindrataMassima", quote.step3Data?.highestDisplacement ? `${quote.step3Data.highestDisplacement}` : "cinquanta");
      
      formData.append("mesiGuidaConsecutiva", quote.step3Data?.consecutiveDrivingMonths ? `${quote.step3Data.consecutiveDrivingMonths}` : "daZeroATre");
    }

    if (this.isVisibleMoreQuestions(quote.step3Data)) {
      formData.append("puntiPatente", quote.step3Data.licensePoints);
      formData.append("multeEbbrezza", quote.step3Data.drunkDrivingTickets);
      formData.append("numeroSospensioniPatente", quote.step3Data.licenseSuspensions);
      formData.append("mesiSospensionePatente", quote.step3Data.totalLicenseSuspensionMonths);
      if (quote.type === "car") {
        formData.append("tipoPatenteAuto", quote.step3Data.licenseType);

        if (quote.step3Data.vehicleModificationsAlloyWheels !== undefined) {
          formData.append("veicolo+cerchiInLega", quote.step3Data.vehicleModificationsAlloyWheels);
        }
        if (quote.step3Data.vehicleModificationsInvalidity !== undefined) {
          formData.append("veicolo+invaliditaFisica", quote.step3Data.vehicleModificationsInvalidity);
        }
        if (quote.step3Data.vehicleModificationsWindowTint !== undefined) {
          formData.append("veicolo+finestriniScuriti", quote.step3Data.vehicleModificationsWindowTint);
        }
        if (quote.step3Data.vehicleModificationsOther !== undefined) {
          formData.append("veicolo+altreModifiche", quote.step3Data.vehicleModificationsOther);
        }
      }
      else {
        formData.append("tipoPatenteMoto", quote.step3Data.licenseType);

        if (quote.step3Data.vehicleModificationsEngineAntitheft !== undefined) {
          formData.append("veicolo+barreAntiurtoMotore", quote.step3Data.vehicleModificationsEngineAntitheft);
        }
        if (quote.step3Data.vehicleModificationsExhaust !== undefined) {
          formData.append("veicolo+modificaScarico", quote.step3Data.vehicleModificationsExhaust);
        }
        if (quote.step3Data.vehicleModificationsDecals !== undefined) {
          formData.append("veicolo+carenaturaAerografata", quote.step3Data.vehicleModificationsDecals);
        }
        if (quote.step3Data.vehicleModificationsRemovableBags !== undefined) {
          formData.append("veicolo+borseLateraliRimovibili", quote.step3Data.vehicleModificationsRemovableBags);
        }
        if (quote.step3Data.vehicleModificationsOther !== undefined) {
          formData.append("veicolo+altreModifiche", quote.step3Data.vehicleModificationsOther);
        }
      }
    }

    formData.append("privacy.consensoCommunicazioniCommerciali", quote.step3Data.acceptGdprPrivacyPolicy01 !== undefined
      ? quote.step3Data.acceptGdprPrivacyPolicy01
      : false
    );
    formData.append("privacy.consensoCommunicazioniCommercialiNewsletter", quote.step3Data.acceptGdprPrivacyPolicy03 !== undefined
      ? quote.step3Data.acceptGdprPrivacyPolicy03
      : false
    );
    formData.append("privacy.consensoRicercaEProfilazione", quote.step3Data.acceptGdprPrivacyPolicy04 !== undefined
        ? quote.step3Data.acceptGdprPrivacyPolicy04
        : false
    );
    formData.append("privacy.consensoConcessioneATerzi", quote.step3Data.acceptGdprPrivacyPolicy05 !== undefined
        ? quote.step3Data.acceptGdprPrivacyPolicy05
        : false
    );
    formData.append("privacy.privacypreventivo", quote.step3Data.acceptSeiSicuroPrivacyPolicy !== undefined
      ? quote.step3Data.acceptSeiSicuroPrivacyPolicy
      : false
    );
    formData.append("privacy.consensoCgu", "true");
    formData.append("privacy.consensoIvass", "true");


    let formValidationData : URLSearchParams = new URLSearchParams();

    formData.forEach((value, key) => {
      let replacedKey = key.replaceAll(".", "+");
      if (value !== null) {
        formValidationData.append(replacedKey, value);
      }
    });


    if(quote.areaPerosnale.idUserAP){
      formValidationData.append("idUserAP", quote.areaPerosnale.idUserAP.toString());
    }
    if(quote.areaPerosnale.idContraenteAP){
      formValidationData.append("idContraenteAP", quote.areaPerosnale.idContraenteAP.toString());
    }
    if(quote.areaPerosnale.idVeicoloAP){
      formValidationData.append("idVeicoloAP", quote.areaPerosnale.idVeicoloAP.toString());
    }
    if(quote.areaPerosnale?.idProprietarioAP){
      formValidationData.append("idProprietarioAP", quote.areaPerosnale.idProprietarioAP.toString());
    }
    if(quote.areaPerosnale?.idConducenteAP){
      formValidationData.append("idConducenteAP", quote.areaPerosnale.idConducenteAP.toString());
    }

    return formValidationData;
  }

  private refineQuotationResponse(quote: any, vehicleSelector: string, vehicle: IVehicle): any{
    let contractor: IPerson | undefined;
    let owner: IPerson | undefined;
    let driver: IPerson | undefined;

    // inizializzazione dati step 1
    let step1DataToDispatch: Step1Data = {...(step1InitialState[vehicleSelector as keyof StepDataDualState] as Step1Data), ...quote.step1Data, step1Ok: true};
    if(quote.areaPerosnale.idContraenteAP){
      
      const selectedFields = ["email", "phone", "gender", "name", "surname", "dateOfBirth", "civilState", "bornInItaly", "countryOfBirth", "cityOfBirth", "degree", "degreeLabel", "profession"];
      contractor = this.user.getPersonById(quote.areaPerosnale.idContraenteAP);
      
      if(contractor){
        let personStep1Data : Step1Data = {contractorId: quote.areaPerosnale.idContraenteAP};

        Object.entries(contractor).forEach(([key, value]) => {
          if(selectedFields.includes(key)){
            personStep1Data[key as keyof Step1Data] = value
          }
        })

        personStep1Data?.dateOfBirth && (personStep1Data.dateOfBirth = new Date(personStep1Data.dateOfBirth).getTime())

        step1DataToDispatch = {...step1DataToDispatch, ...personStep1Data};
        quote.step1Data = {...quote.step1Data, ...personStep1Data};
      }
    }

    
    // inizializzazione dati step 2
    let step2DataToDispatch: Step2Data = {...(step2InitialState[vehicleSelector as keyof StepDataDualState] as Step2Data), ...quote.step2Data, step2Ok: true};
    if(quote.areaPerosnale.idVeicoloAP){
      
      const selectedFields = ["vehicleName", "vehicleType", "vehicleLicensePlateNumber", "vehicleBuyYear", "vehicleRegistrationMonth", "vehicleRegistrationYear", "vehicleBrand", "vehicleBrandLabel", "vehicleModel", "vehicleModelLabel", "vehicleFitting", "vehicleFittingLabel", "veicleFullDescription", "vehicleOwned", "vehicleFuelType", "vehicleEngineDisplacement", "vehicleAntitheft", "vehicleTowbarMounted", "vehicleGplMounted"];
      
      let vehicleStep2Data : Step2Data = {vehicleId: quote.areaPerosnale.idVeicoloAP};

      Object.entries(vehicle).forEach(([key, value]) => {
        if(selectedFields.includes(key)){
          vehicleStep2Data[key as keyof Step2Data] = value
        }
      })

      if(quote.vehicleType === "moto"){
        delete step2DataToDispatch.vehicleUse;
        delete step2DataToDispatch.vehicleTowbarMounted;
        delete step2DataToDispatch.vehicleFuelType;
      }

      /**
       * knowLicensePlate <- non ritornato in quote.step2Data
       * moreThanOneVehicle: false
       * kilometersPerYear: 5000
       * vehicleUse: "" <- ritornato erroneamente vuoto
       * vehicleParkingLocation: "autorimessa"
       * is4RuoteValid <- farmi ritorane is4RuoteValid
       * porre infoFrom4Ruote = is4RuoteValid
       */

      step2DataToDispatch?.knowLicensePlate === undefined && (step2DataToDispatch.knowLicensePlate = (vehicle?.vehicleLicensePlateNumber ?? "") !== "" ? true : false)

      step2DataToDispatch = {...step2DataToDispatch, ...vehicleStep2Data}
      quote.step2Data = {...quote.step2Data, ...step2DataToDispatch};
    }
    
    
    // inizializzazione dati step 3
    let step3DataToDispatch: Step3Data = {...(step3InitialState[vehicleSelector as keyof StepDataDualState] as Step3Data), ...quote.step3Data, step3Ok: true};
    
    const selectedFields = ["name", "surname", "cityOfResidence", "postalCodeOfResidence", "address", "addressToponimo", "addressNumber", "children", "youngestChild", "email", "phone", "italianDrivingLicense", "drivingLicenseAge"];
    
    if(contractor){
      let contractorStep3Data : Step3Data = {};

      Object.entries(contractor).forEach(([key, value]) => {
        if(selectedFields.includes(key)){
          contractorStep3Data[this.mapFieldPersonToStep3Contactor(key) as keyof Step3Data] = value
        }
      })

      step3DataToDispatch = {...step3DataToDispatch, ...contractorStep3Data};
      quote.step3Data = {...quote.step3Data, ...step3DataToDispatch};
    }
    
    if(quote.step3Data.contractorAndOwnerCoincide !== "si"){
      owner = this.user.getPersonById(quote.areaPerosnale.idProprietarioAP);
      if(owner){
        step3DataToDispatch = {...step3DataToDispatch, owner: owner, ownerId: owner.id}
        quote.step3Data.owner = {...quote.step3Data.owner, ...owner};
      }
    }

    if(quote.step3Data.contractorAndDriverCoincide !== "si" && quote.step3Data.ownerAndDriverCoincide !== "si"){
      driver = this.user.getPersonById(quote.areaPerosnale.idConducenteAP);
      if(driver){
        step3DataToDispatch = {...step3DataToDispatch, driver: driver, driverId: driver.id}
        quote.step3Data.driver = {...quote.step3Data.driver, ...driver};
      }
    }

    // refinement people addresses
    if (step3DataToDispatch?.contractorAddress) {
      const { toponimo, indirizzo, } = extractAddressData(step3DataToDispatch.contractorAddress);
      step3DataToDispatch.contractorAddress = `${toponimo} ${indirizzo}`;
    }
    
    if (step3DataToDispatch.driver?.address) {
      const { toponimo, indirizzo, } = extractAddressData(step3DataToDispatch.driver.address);
      step3DataToDispatch.driver.address = `${toponimo} ${indirizzo}`;
    }

    if (step3DataToDispatch.owner?.address) {
      const { toponimo, indirizzo, } = extractAddressData(step3DataToDispatch.owner.address);
      step3DataToDispatch.owner.address = `${toponimo} ${indirizzo}`;
    }

    const now: Date = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    if (quote.step3Data.newInsuranceStart){
      const startData = new Date(quote.step3Data.newInsuranceStart);

      if(!isNaN(startData.getTime())){
        if(startData < now){
          quote.step3Data.newInsuranceStart = now;
        }
        else{
          quote.step3Data.newInsuranceStart = startData;
        }
      }
      else{
        quote.step3Data.newInsuranceStart = now;
      }
    }
    else{
      quote.step3Data.newInsuranceStart = now;
    }

    step3DataToDispatch = {...step3DataToDispatch, newInsuranceStart: quote.step3Data.newInsuranceStart};
    
    if(quote.step3Data.youngestFamilyDriver === null || quote.step3Data.youngestFamilyDriver === undefined){
      quote.step3Data.youngestFamilyDriver = "nessuno";
    }
    step3DataToDispatch = {...step3DataToDispatch, youngestFamilyDriver: quote.step3Data.youngestFamilyDriver};

    // indipendentemente da cosa mi risponderà, se lo step3 è valido.. resetto la segnalazione che il preventivo deve caricare le quotazioni da un salvataggio
    let step4Result: Step4Data = {
      quotation: [],
      noQuotation: [],
      selectedOptions: [],
      requestCounter: 1,
      warrantiesFromBo: false,
      fromMailSereno: false,
    };
    this.props.step1DataSuccess(step1DataToDispatch, vehicleSelector);
    this.props.step2DataSuccess(step2DataToDispatch, vehicleSelector);
    this.props.step3DataSuccess(step3DataToDispatch, vehicleSelector);
    this.props.step4DataSuccess({...step4InitialState, ...step4Result}, vehicleSelector);

    return quote;
  }

  private mapFieldPersonToStep3Contactor(personField: string): string{
    let mappedField: string = "";
    
    switch(personField){
      case "name":
        mappedField = "contractorName";
        break;
      case "surname":
        mappedField = "contractorSurname";
        break;
      case "address":
        mappedField = "contractorAddress";
        break;
      case "addressNumber":
        mappedField = "contractorAddressNumber";
        break;
      case "cityOfResidence":
        mappedField = "contractorCityOfResidence";
        break;
      case "postalCodeOfResidence":
        mappedField = "contractorPostalCode";
        break;
      case "children":
        mappedField = "contractorChildren";
        break;
      case "youngestChild":
        mappedField = "contractorYoungestChild";
        break;
      case "email":
        mappedField = "contractorEmail";
        break;
      case "phone":
        mappedField = "contractorPhoneNumber";
        break;
      case "italianDrivingLicense":
        mappedField = "contractorItalianDrivingLicense";
        break;
      case "drivingLicenseAge":
        mappedField = "contractorDrivingLicenseAge";
        break;
    }

    return mappedField;
  }
  

  private redirectToRicalcolo(){

    let vehicle: IVehicle | undefined = this.user.getVehicleById(this.props.quotation.vehicleId);
    const vehicleSelector: string = vehicle?.vehicleType ?? "auto";
    this.props.setVehicle(vehicleSelector);
    
    if(vehicle){
      return UserService.call(
        Constants.WEB_SITE_API_SERVER_URL(`/quotes/vehicle/${this.props.quotation.vehicleId}/details/${this.props.quotation.quote_id}`),
        {method: "GET"},
        (quote: any) => {
          
          let formData = this.setFormData(this.refineQuotationResponse(quote, vehicleSelector, vehicle as IVehicle));
          let formValidationData = new URLSearchParams();
      
          formData.forEach((value, key) => {
            let replacedKey = key.replaceAll(".", "+");
            if (value !== null) {
              formValidationData.append(replacedKey, value);
            }
          });
          
          this.setState({ loading: true });
          fetch(Constants.SEISICURO_FORM_POST_ENDPOINT, {
            method: "post",
            credentials: "include",
            body: formValidationData,
            headers: {"Content-Type": 'application/x-www-form-urlencoded; charset="utf-8"' /** AUTH_REDACTED */,},
          })
            .then((response) => {
              if (response.status === 400) {
                this.setState(
                  { loading: false },
                  () => {
                    response
                      .text()
                      .then((responseText) => {
                        this.setState({ errors: [responseText] }, () => this.trackSendFailure(vehicleSelector));
                      })
                  }
                );
      
              }
              else if (response.ok) {
                this.props.step3DataSuccess({ step3Ok: true }, vehicleSelector);
                //console.log(response);
      
                fetch(Constants.SEISICURO_STEP_CHECK(3), {
                  credentials: "include",
                  headers: {
                    /** AUTH_REDACTED */
                  },
                })
                  .then((response) => {
                    response.json().then((responseJson) => {
                      if (Object.keys(responseJson[0]).length > 0) {
                        this.setState({error_visible: true});
                      }
                      else {
                        // rimanda allo step 4
                        this.trackSendSuccess(() => this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(4, vehicleSelector)), vehicleSelector);
                      }
                    });
                  })
                  .catch((error) => {
                    this.props.step3DataFailure(vehicleSelector);
                    this.setState({ loading: false, error_visible: true}, () => {
                      this.trackSendFailure(vehicleSelector);
                    });
                  });
              }
              else {
                this.props.step3DataFailure(vehicleSelector);
                this.setState({ loading: false, error_visible: true }, () => {
                  this.trackSendFailure(vehicleSelector);
                });
              }
            })
            .catch((error) => {
              this.props.step3DataFailure(vehicleSelector);
              this.setState({ loading: false, error_visible: true}, () => {
                this.trackSendFailure(vehicleSelector);
              });
            });
        },
        (error: any) => {
          this.setState({error_visible: true})
        }
      );
    }
    else{
      this.setState({error_visible: true})
    }
  }

  private get_com_img(id: string | number) : string{
    if(typeof(id) === "number"){
      id = `${id}`;
    }
    //console.log("ID: "+id+"("+(typeof id)+")");
    let array_logo = [
      "112.jpg",
      "132.jpg",
      "18.jpg",
      "23.jpg",
      "31.jpg",
      "34.jpg",
      "36.jpg",
      "38.png",
      "11.jpg",
      "17.jpg",
      "22.png",
      "26.jpg",
      "32.jpg",
      "35.png",
      "37.jpg",
      "39.png",
      "12.jpg",
      "30.jpg",
      "33.jpg",
      "47.png",
    ];
    for(let elem of array_logo){
      let split = elem.split(".");
      //console.log((typeof split[0])+" ("+split[0]+") === "+(typeof id)+" ("+id+")");
      if(split[0] === id)
        return elem;
    };
    return "";
  }

  render(){
    const quotation = this.props.quotation;
    
    return <div className="quotationRow">
      
      <Dialog
        className="customDialog error"
        header="Ops! Sembra che ci sia qualche problema"
        visible={this.state.error_visible}
        modal={true}
        resizable={false}
        draggable={false}
        onHide={() => {this.setState({error_visible: false})}}
        footer={
          <div>
              <Button label="Chiudi" className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.setState({error_visible: false})}} />
          </div>
        }>
        <p>Errore nel ricalcolo del preventivo. Contattare il servizio clienti.</p>        
      </Dialog>                   
      <div className="header align-items-end">
        <p>
          <span className="smallParagraph">Numero preventivo:</span><br/>
          <span className="titleLev6">#{quotation.quote_id}</span>
        </p>
        <div className="text-right">
          {quotation.miglior_prezzo_rca
            ? <>
                <span className="smallParagraph">Miglior prezzo RCA</span><br/>
                <h3 className={quotation.active ? "titleLev6 colorPrimaryGreen" : "titleLev6 colorLighterGrey"}>{priceFormatter(quotation.miglior_prezzo_rca)}</h3>
              </>
            : <p><b className="smallParagraph colorPrimaryGreen">Ehi, potrebbero esserci aggiornamenti di prezzo!</b></p>
          }
        </div>
      </div>
      <div className="summary smallParagraph">
        <div className="row">
          <div className="col-lg-4">
            <p className="mb-1"><strong>Contraente:</strong> {User.getPersonSafeName(this.user.getPersonById(quotation.contractor_id))}</p>
            <p className="mb-1"><strong>Data:</strong> { (new Date(quotation.created_at_datetime)).toLocaleDateString("it-IT") }</p>
          </div>
          <div className="col-lg-8 text-right xs-text-center">
            
              <span className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.redirectToRicalcolo()}}>
                {quotation.active ? "Ricalcola preventivo" : "Calcola nuovo preventivo"}
              </span>
            
          </div>
        </div>
      </div>

      {quotation.active && quotation?.salvataggi &&
        <div className="quotationList">
          <span className="titleLev6 colorPrimaryBlue">Preventivi salvati</span>
          <div className="realList mt-3">
            {quotation.salvataggi.data
              .filter((saved: any) => new Date(saved.exp_date) > new Date())
              .map((saved: any, i: number) => 
                <div className="singleQuote" key={"quote_"+i}>
                  <div className="row align-items-center">
                    <div className="col-5 col-lg-2">
                      <img src={Constants.SEISICURO_INSURANCE_COMPANY_LOGO_URL(this.get_com_img(saved.comp_id))} style={{maxWidth: "100%"}} alt=""/>
                    </div>
                    <div className="col-7 col-lg-5 col-xl-6 mb-3">
                      <div className="d-flex align-items-center justify-content-between xs-display-column">
                        <div style={{width: "100%"}}>
                          <p className="xs-text-right">
                            <span className="smallParagraph">Premio annuale</span><br/>
                            <span className="titleLev6 colorPrimaryGreen">{priceFormatter(saved.save_price)}</span>
                          </p>
                        </div>
                        
                        {quotation?.active &&
                          <div style={{marginLeft: "1rem", width: "100%"}} className="xs-text-right">
                            <span className="lblBloccato smallParagraph">Prezzo bloccato</span>
                            <p className="smallParagraph mt-1">
                              <img className="clock" src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/clock.svg`} alt=""/>
                              fino al {(new Date(saved.exp_date)).toLocaleDateString("it-IT",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                            </p>
                          </div>
                        }      
                      </div>                              
                    </div>

                    <div className="col-12 col-lg-5 col-xl-4 text-right xs-text-center">
                      {quotation?.active &&
                        <Link to={{pathname: saved.save_link}} target="_blank">
                          <span className="customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">Acquista su {saved.comp_name}</span>
                        </Link>
                      }
                    </div>                  
  
                  </div>
                </div>
              )
            }
          </div>
        </div>
      }

    </div>
  }
}

const CardPreventivo = (props: any) => {
  return <CardPreventivoComponent {...props} history={useHistory()}/>
}


const mapState = (state: any) => {
  return {
    step1Data: state.step1Data,
    step2Data: state.step2Data,
    step4Data: state.step4Data,
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    step1DataSuccess: (payload: any, vehicle: string) => dispatch(step1DataSuccess(payload, vehicle)),
    step2DataSuccess: (payload: any, vehicle: string) => dispatch(step2DataSuccess(payload, vehicle)),
    step3DataSuccess: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle)),
    step4DataSuccess: (payload: any, vehicle: string) => dispatch(step4DataSuccess(payload, vehicle)),
    step3DataFailure: (vehicle: string) => dispatch(step3DataFailure(vehicle)),
    setVehicle: (vehicle: string) => dispatch(userInfoDataSuccess({currentVehicleSelector: vehicle}))
  }
};

export default compose(connect(mapState, mapDispatch), withEventManager)(CardPreventivo);