
import * as Constants from '../utils/constants';

interface CookieData{
    name: string;
    value: string;
    domain?: string;
    path?: string;
    expires?: Date | string;
    SameSite?: string;
    hostOnly?: boolean;
}
export default class CookieService {

    /**
     * Writes the "cookies refused" cookie.
     */
    public static writeKoCookie(callBack: Function) {
        var cname = '6sicuro_cookie_notice_accepted';

        var cvalue = 'ko';
        var days = 1;
        var cdomain = '6sicuro.it';

        var dt;
        dt = new Date();
        dt.setTime(dt.getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = `${cname}=${cvalue}; domain=.${cdomain}; path=/; expires=${dt.toUTCString()}; SameSite=Lax;`

        callBack();
    };

    /**
     * Cencella un cookie in base al suo nome
     * @param cName il nome del cookie
     * @param callBack la funzione da eseguire dopo aver eliminato il cookie
     */
    public static deleteCookie(param: string | {name: string, path: string, domain: string}, callBack?: Function) {

        let cName:string = "";
        let path:string = "/";
        let domain:string = Constants.COOKIE_DEFAULT_DOMAIN || "";
        if(typeof(param) === "string"){
            cName = param;
        }
        else{
            cName = param.name;
            path = param.path;
            domain = param.domain;
        }

        document.cookie = `${cName}=; domain=${domain}; path=${path}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        callBack?.();
    }

    /**
     * Cancella tutti i cookie tramite cui il BackEnd identifica i preventivi e l'utnete (in modo da non incasinare le cose)
     * @param callBack la funzione da eseguire dopo aver eliminato i cookie
     */
    public static clearQuotationCookies(callBack: Function) {
        const cookieList = [
            "HASHCODE", "CALCOLOID", "PREVENTIVOID", "kruxid", "h"
        ];

        cookieList.forEach( cn => {
            document.cookie = cn + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        });

        callBack();
    }

    /**
     * Cerca per nome e restitiuisce il valor di un cookie
     * @param name il nome del cookie da cercare
     * @returns il valore del cookie se trovato, null altriemnti
     */
    public static getCookie(name: string): string | null {
        const nameLenPlus = (name.length + 1);
        return document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map(cookie => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null;
    }

    public static writeCookie(cookieData: CookieData){
        if(!cookieData?.domain){
            cookieData.domain = Constants.COOKIE_DEFAULT_DOMAIN;
        }
        if(!cookieData?.path){
            cookieData.path = '/';
        }
        if(!cookieData?.expires){
            const dt = new Date();
            dt.setTime(dt.getTime() + (60 * 60 * 24 * 30));
            cookieData.expires = `${dt.toUTCString()}`;
        }
        else if(cookieData.expires instanceof Date){
            cookieData.expires = `${cookieData.expires.toUTCString()}`;
        }
        else{
            const dt = new Date(cookieData.expires);
            cookieData.expires = `${dt.toUTCString()}`;
        }
        if(!cookieData?.SameSite){
            cookieData.SameSite = "Lax";
        }

        const hostOnly = (cookieData.hostOnly !== undefined) ? `; hostOnly= ${cookieData.hostOnly}` : "";

        document.cookie = `${cookieData.name}=${cookieData.value}; domain=${cookieData.domain}; path=${cookieData.path}; expires=${cookieData.expires}; samesite=${cookieData.SameSite}${hostOnly}`;

    }
}