// import moment from "moment";
import "../../scss/_entity-card.scss";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
// import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Component, createRef} from "react";
import { AjaxAutocompleteObject, IFieldDescriptor, ICompany } from "../utils/common";
import * as Constants from "../utils/constants";
import { fetchAutocomplete, fetchList /*, formataDateToString*/, getDrivingLicenseAgeOptions, isLikelyDate, removeFocusClass, replaceSpecialChars, sortJson } from "../utils/methods";
import User, { IValidationResponse } from "../utils/User";
import UserService, { DuplicateException, GenericException } from "../utils/UserService";
import InputDate from "./InputDate";
import { WEB_SITE_API_SERVER_URL } from "../utils/constants";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "../utils/Feedback";
import { Divider } from "primereact/divider";

interface ICompanyCardProps{
  showControl?: boolean;  // false => interfaccia show non mostra il controller per entrare in modalità modifica, solo modalità 'dettaglio'
  editMode?: boolean;   // true => modalità modifica, false => modalità dettaglio
  visible: boolean;
  header?: JSX.Element;
  successCallback?: Function;
  errorCallback?: Function;
  company?: string | ICompany;
  user?: User;
  hideFields?: string[]; // lista dei campi da nascondere
  requiredFields?: string[] | string | IFieldDescriptor[] | any; // lista dei campi da nascondere
  errors?: string[];
  errorTexts?: IFeedbackText[];  //{field, msg}
}
interface ICompanyCardState{
  showControl: boolean;  
  editMode: boolean;
  visible: boolean;
  header: JSX.Element;
  id?: string | number;
  company: ICompany | undefined;
  saving: boolean;
  feedbackDialog: boolean;
  feedbackStatus: string;
  feedbackBody: string;
  assetsLoading: boolean;
  autocompleteCountries: any[] | undefined;
  autocompleteCities: any[] | undefined;
  errors: string[];
  errorTexts: IFeedbackText[];  //{field, msg}
  hideFields?: string[]; // lista dei campi da nascondere
  requiredFields: string[] | string | undefined | any;
  successCallback?: Function;
  errorCallback?: Function;
  referentPhoneChecking: boolean;
  referentEMailChecking: boolean;
}

export class CompanyCard extends Component<ICompanyCardProps, ICompanyCardState>{

  private defaultHeader: JSX.Element = <h3>Header</h3>;
  private professionList: any[] = [];
  private degreeList: any[] = [];
  private civilStateList: any[] = [];
  private minorAgeList : number[];
  // private dateRef : any;

  private dialogRef: any;
  private previousPhone: string;
  private previousEmail: string;

  private addressComponentsFields: string[] = ["address", "addressNumber", "cityOfResidence", "postalCodeOfResidence"];

  constructor(props: ICompanyCardProps){
    super(props);

    let company: ICompany | undefined = undefined;
    this.previousPhone = "";
    this.previousEmail = "";
    if(this.props?.company){
      if(typeof(this.props?.company) === "string"){
        company = User.getInstance().getCompanyById(this.props.company)
      }
      else{
        company = this.props.company
      }
      this.previousPhone = company?.referent_phone ?? "";
      this.previousEmail = company?.referent_email ?? "";
    }

    this.state = {
      showControl: this.props?.showControl !== undefined ? this.props.showControl : true,
      editMode: this.props?.editMode as boolean,
      visible: this.props?.visible ? this.props.visible : false,
      header: this.props?.header ? this.props.header : this.defaultHeader,
      company: company,
      saving: false,
      feedbackDialog: false,
      feedbackStatus: "",
      feedbackBody: "",
      assetsLoading: true,
      autocompleteCountries: undefined,
      autocompleteCities: undefined,
      errors: this.props?.errors ?? [],
      errorTexts: this.props?.errorTexts ?? [],
      hideFields: this.props.hideFields,
      requiredFields: this.props.requiredFields,
      successCallback: this.props.successCallback,
      errorCallback: this.props.errorCallback,
      referentPhoneChecking: false,
      referentEMailChecking: false
    }

    this.minorAgeList = Array.from({length: Constants.MINOR_MAX_AGE}, (_, i) => i + 1)

    this.save = this.save.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.referentPhoneHandler = this.referentPhoneHandler.bind(this);
    this.defaultSuccessCallback = this.defaultSuccessCallback.bind(this);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.validation = this.validation.bind(this);

    this.dialogRef = createRef();
  }

  componentDidMount() {
    fetchList(Constants.SEISICURO_AJAX_PROFESSION_ENDPOINT, (result: any) => {
      result.sort(sortJson);
      this.professionList = result;
      this.updateLoadingState();
    });
    fetchList(Constants.SEISICURO_AJAX_CIVIL_STATE_ENDPOINT, (result: any) => {
      result.sort(sortJson);
      this.civilStateList = result;
      this.updateLoadingState();
    });
    fetchList(Constants.SEISICURO_AJAX_DEGREE_ENDPOINT, (result: any) => {
      result.sort(sortJson);
      this.degreeList = result;
      this.updateLoadingState();
    });

  }

  updateLoadingState() {
    let loaded: boolean = !(
      this.civilStateList.length > 0 &&
      this.professionList.length > 0 &&
      this.degreeList.length > 0
    );

    if (this.state.assetsLoading !== loaded) {
      this.setState({ assetsLoading: loaded });
    }
  }

  private referentPhoneHandler(candidatePhone: string, skipPrevValueControl: boolean = false){
    if(skipPrevValueControl || candidatePhone !== this.previousPhone){
      if(candidatePhone === ""){
        this.setState({
          referentPhoneChecking: false,
          errors: [...this.state.errors.filter((err: string) => err !== "referent_phone")],
          errorTexts: [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "referent_phone")]
        })
      }
      else{
        this.setState({referentPhoneChecking: true}, () => {

          UserService.call(Constants.WEB_SITE_API_SERVER_URL("/utils/misc/validate"), {
            method: "post",
            body: JSON.stringify({phone: candidatePhone ?? ""})
          })
            .then(result => {
              result?.phone.valid
                ? this.setState({
                  referentPhoneChecking: false,
                  errors: this.state.errors.filter(e => e !== "referent_phone")
                })
                : this.setState({
                  referentPhoneChecking: false,
                  errors: this.state.errors.includes("referent_phone")
                    ? this.state.errors
                    : [...this.state.errors, "referent_phone"]
                })
            })
            .catch((err: any) => {
              this.setState({
                referentPhoneChecking: false,
                errors: this.state.errors.includes("referent_phone")
                  ? this.state.errors
                  : [...this.state.errors, "referent_phone"]
              })
            })
        })
      }
    }
  }



  private referentEMailHandler(candidateEMail: string, skipPrevValueControl: boolean = false){
    if(skipPrevValueControl || candidateEMail !== this.previousEmail){
      if(candidateEMail === ""){
        this.setState({
          referentEMailChecking: false,
          errors: [...this.state.errors.filter((err: string) => err !== "referent_email")],
          errorTexts: [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "referent_email")]
        })
      }
      else{
        this.setState({referentEMailChecking: true}, () => {

          UserService.call(Constants.WEB_SITE_API_SERVER_URL("/utils/email/validate"), {
            method: "post",
            body: JSON.stringify({email: candidateEMail ?? ""})
          })
            .then(result => {
              result?.valid
                ? this.setState({
                  referentEMailChecking: false,
                  errors: this.state.errors.filter(e => e !== "referent_email")
                })
                : this.setState({
                  referentEMailChecking: false,
                  errors: this.state.errors.includes("referent_email")
                    ? this.state.errors
                    : [...this.state.errors, "referent_email"]
                })
            })
            .catch((err: any) => {
              this.setState({
                referentEMailChecking: false,
                errors: this.state.errors.includes("referent_email")
                  ? this.state.errors
                  : [...this.state.errors, "referent_email"]
              })
            })
        })
      }
    }
  }

  defaultSuccessCallback(response: {company: ICompany, validation: IValidationResponse}, callback?: Function){
    this.setState({
      saving: false,
      company: {...this.state.company, id: response.company.id},
      feedbackDialog: true,
      feedbackStatus: "success",
      feedbackBody: "Salvataggio completato con successo"
    }, () => {
      this.dialogScrollToTop();
      callback?.(User.getInstance().getCompanyById(response.company.id), response.validation)
    });

  }

  defaultErrorCallback(error?: Error){
    this.setState({
      saving: false,
      feedbackDialog: true,
      feedbackStatus: "error",
      feedbackBody: error !== undefined && (error?.message ?? "") !== "" ? error.message :  "Non è stato possibile completare il salvataggio"
    }, () => {
      this.dialogScrollToTop()
      this.state.errorCallback?.(this.state.company);
    });

  }

  render(){
    const company = this.state.company;
    let disabled : boolean = !this.state.editMode;

    // let x = company?.cityOfBirth !== undefined && (company.cityOfBirth?.id ?? undefined) !== undefined && (company.cityOfBirth?.value ?? undefined) !== undefined
    //   ? company?.cityOfBirth
    //   : {id: "12", value:"ASD"};

    return <Dialog
        id="companyCard"
        className="entityCard"
        modal={true}
        visible={this.state.visible}
        resizable={false}
        draggable={false}
        closable={!this.state.saving}
        closeOnEscape={false}
        onHide={this.hide}
        header={
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-7 col-12 realTitle">
                {this.state.header}
              </div>
              <div className="col-md-5 mt-md-0 col-12 mt-2 switchModifica">
                {
                  this.state.showControl &&                
                  <span className="editToggle">
                    <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/`+(this.state.editMode ? "lock-sbloccato" : "lock")+`.svg`} alt=""/>
                    <span className={this.state.editMode ? "colorPrimaryGray" : "colorPrimaryGreen"} onClick={() => {this.setState({editMode: !this.state.editMode})}}>{this.state.editMode ? "Blocca modifica" : "Abilita modifica"}</span>
                  </span>                
                }  
              </div>              
            </div>
          </div>
        }
        /*breakpoints={{'960px': '75vw'}}*/
        style={{ width: '600px' }}
        blockScroll={true}
      >
        <div className="container-fluid" ref={this.dialogRef}>
          
          
          <div className="row">
            
            {this.state.feedbackDialog
              ? <FeedbackBox items={[{field: "generic", msg: this.state.feedbackBody, severity: this.state.feedbackStatus}]} filter={{field: "generic",  severity: this.state.feedbackStatus}}/>
              : <FeedbackBox items={this.state.errorTexts}/>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("name")) &&
              <>
                { !(this.state?.hideFields && this.state.hideFields.includes("name")) &&
                  <div className="col-xl-12 col-12 mb-4">
                    <label htmlFor="company-name">{this.state.requiredFields?.name?.label_human_readable ?? "Nome azienda"}</label>
                    <InputText
                      onChange={(selected) => this.onChangeHandler( selected.target.value, "name" ) }
                      // onBlur={() => this.validation("name")}
                      onFocus={(e) => this.resetValidation("name")}
                      placeholder="Nome azienda"
                      name="name"
                      id="company-name"
                      autoComplete="given-name"
                      disabled={disabled}
                      value={company?.name ?? ""}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("name")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "name") ??
                        {msg: this.state.requiredFields?.name?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.name?.validation?.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
              </>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("legal_name")) &&
              <>
                { !(this.state?.hideFields && this.state.hideFields.includes("legal_name")) &&
                  <div className="col-xl-12 col-12 mb-4">
                    <label htmlFor="company-legal_name">{this.state.requiredFields?.legal_name?.label_human_readable ?? "Ragione sociale"}</label>
                    <InputText
                      onChange={(selected) => this.onChangeHandler( selected.target.value, "legal_name" ) }
                      // onBlur={() => this.validation("legal_name")}
                      onFocus={(e) => this.resetValidation("legal_name")}
                      placeholder="Ragione sociale"
                      name="legal_name"
                      id="company-legal_name"
                      autoComplete="given-legal_name"
                      disabled={disabled}
                      value={company?.legal_name ?? ""}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("legal_name")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "legal_name") ??
                        {msg: this.state.requiredFields?.legal_name?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.legal_name?.validation?.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
              </>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("vat")) &&
              <>
                { !(this.state?.hideFields && this.state.hideFields.includes("vat")) &&
                  <div className="col-xl-12 col-12 mb-4">
                    <label htmlFor="company-vat">{this.state.requiredFields?.vat?.label_human_readable ?? "Ragione sociale"}</label>
                    <InputText
                      onChange={(selected) => this.onChangeHandler( selected.target.value, "vat" ) }
                      // onBlur={() => this.validation("vat")}
                      onFocus={(e) => this.resetValidation("vat")}
                      placeholder="Partita IVA"
                      name="vat"
                      id="company-vat"
                      autoComplete="given-vat"
                      disabled={disabled}
                      value={company?.vat ?? ""}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vat")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vat") ??
                        {msg: this.state.requiredFields?.vat?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vat?.validation?.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
              </>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("registered_office_address")) &&
              <div className="col-12 mb-4">
                {/*<Address
                  cityOfResidence={company?.registered_office_cityOfResidence}
                  address={company?.registered_office_address}
                  civico={company?.registered_office_addressNumber}
                  postalCodeOfResidence={company?.registered_office_postalCode}
                  labelFullAddress="Indirizzo di residenza"
                  placeholderFullAddress="Via delle Rose, 23, Roma"
                  placeholderAddress="Via delle Rose, 23"
                  errors={this.state.errors.filter((error: string) => this.addressComponentsFields.includes(error))}
                  errorTexts={
                    this.state.errorTexts.filter((feedback: IFeedbackText) => this.addressComponentsFields.includes(feedback.field))
                  }
                  disabled={disabled}
                  onDone={(addressData: any[]) => {
                    const [address, postalCode, cityOfResidence, civico] = addressData;
                    this.updateAddress(address, postalCode, cityOfResidence, civico);
                  }}
                />*/}

                <label htmlFor="registered_office_address">{this.state.requiredFields?.registered_office_address?.label_human_readable ?? "Indirizzo sede legale"}</label>
                <InputText
                  onChange={(selected) => this.onChangeHandler( selected.target.value, "registered_office_address" ) }
                  // onBlur={() => this.validation("registered_office_address")}
                  onFocus={(e) => this.resetValidation("registered_office_address")}
                  placeholder="Indirizzo sede legale"
                  name="registered_office_address"
                  id="registered_office_address"
                  autoComplete="registered_office_address"
                  disabled={disabled}
                  value={company?.registered_office_address ?? ""}
                />
                <ContextFeedback
                  show={this.state.errors.includes("registered_office_address")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "registered_office_address") ??
                    {msg: this.state.requiredFields?.registered_office_address?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.registered_office_address?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("referent_first_name") && this.state.hideFields.includes("referent_last_name")) &&
              <>
                { !(this.state?.hideFields && this.state.hideFields.includes("referent_first_name")) &&
                  <div className="col-md-6 col-12 mb-xl-0 mb-4">
                    <label htmlFor="contraente-referent_first_name">{this.state.requiredFields?.referent_first_name?.label_human_readable ?? "Nome referente"}</label>
                    <InputText
                      onChange={(selected) => this.onChangeHandler( selected.target.value, "referent_first_name" ) }
                      // onBlur={() => this.validation("name")}
                      onFocus={(e) => this.resetValidation("referent_first_name")}
                      placeholder="Nome referente"
                      name="referent_first_name"
                      id="contraente-referent_first_name"
                      autoComplete="given-referent_first_name"
                      disabled={disabled}
                      value={company?.referent_first_name ?? ""}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("referent_first_name")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "referent_first_name") ??
                        {msg: this.state.requiredFields?.referent_first_name?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.referent_first_name?.validation?.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
                { !(this.state?.hideFields && this.state.hideFields.includes("referent_last_name")) &&
                  <div className="col-md-6 col-12 mb-4">
                    <label htmlFor="contraente-referent_last_name">Cognome referente</label>
                    <InputText
                      onChange={(selected) => this.onChangeHandler( selected.target.value, "referent_last_name" ) }
                      // onBlur={() => this.validation("referent_last_name")}
                      onFocus={(e) => this.resetValidation("referent_last_name")}
                      placeholder="Cognome"
                      name="referent_last_name"
                      id="contraente-referent_last_name"
                      autoComplete="family-name"
                      disabled={disabled}
                      value={company?.referent_last_name ?? ""}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("referent_last_name")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "referent_last_name") ??
                        {msg: this.state.requiredFields?.referent_last_name?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.referent_last_name?.validation?.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
              </>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("referent_phone")) &&
              <div className="col-12 mb-4">
                <label htmlFor="contraenete-referent_phone">Cellulare di riferimento</label>
                  <span className="p-input-icon-right d-block">
                  {this.state?.referentPhoneChecking === true && <i className="pi pi-spin pi-spinner" />}
                  <InputText
                    value={company?.referent_phone ?? ""}
                    onChange={(selected) => this.onChangeHandler( selected.target.value, "referent_phone" ) }
                    placeholder="Telefono di riferimento"
                    name="referent_phone"
                    id="contraenete-referent_phone"
                    autoComplete="tel"
                    type="tel"
                    //keyfilter={/^\d+(\.\d{1,2})?$/}
                    onBlur={(selected) => this.referentPhoneHandler(selected.target.value)}
                    disabled={disabled}
                  />
                </span>
                <ContextFeedback
                  show={this.state.errors.includes("referent_phone")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "referent_phone") ??
                    {msg: this.state.requiredFields?.referent_phone?.validation.fail_msg ?? "Inserire un numero di cellulare valido", severity: this.state.requiredFields?.referent_phone?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("referent_email")) &&
              <div className="col-12 mb-4">
                <label htmlFor="contraenete-referent_email">Email di riferimento</label>
                  <span className="p-input-icon-right d-block">
                  {this.state?.referentPhoneChecking === true && <i className="pi pi-spin pi-spinner" />}
                  <InputText
                    value={company?.referent_email ?? ""}
                    onChange={(selected) => this.onChangeHandler( selected.target.value, "referent_email" ) }
                    placeholder="E-mail di riferimento"
                    name="referent_email"
                    id="contraenete-referent_email"
                    autoComplete="email"
                    type="email"
                    //keyfilter={/^\d+(\.\d{1,2})?$/}
                    onBlur={(selected) => this.referentEMailHandler(selected.target.value)}
                    disabled={disabled}
                  />
                </span>
                <ContextFeedback
                  show={this.state.errors.includes("referent_email")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "referent_email") ??
                    {msg: this.state.requiredFields?.referent_email?.validation.fail_msg ?? "Inserire un numero di cellulare valido", severity: this.state.requiredFields?.referent_email?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }
          </div>
          
          {this.state.editMode &&
            <>
              <Divider></Divider>
              <div className="row mt-4">
                <div className="col-12">
                  <Button
                    className="customBtn d-block bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite mt-3"
                    style={{width: "100%", textAlign: "center"}}
                    onClick={this.save}
                    label="Salva"
                    loading={this.state.saving}
                  />
                </div>
              </div>
            </>
          }
        </div>

      </Dialog>

  }

  public show(params: {
    company?: ICompany | string | undefined,
    header: JSX.Element,
    hideFields: string[],
    errors: string[],
    errorTexts: IFeedbackText[],
    editMode?: boolean,
    showControl?: boolean,
    requiredFields?: string[] | string | any,
    successCallback?: Function,
    errorCallback?: Function,
  } = {header: this.defaultHeader, hideFields: [], errors: [], errorTexts: []}){

    let state : any = {
      visible: true,
      header: params.header,
      company: params.company !== undefined
        ? typeof(params.company) === "string"
          ? User.getInstance().getCompanyById(params.company)
          : params.company
        : undefined,
      errors: params.errors ?? [],
      errorTexts: params.errorTexts ?? [],
      editMode: params?.editMode !== undefined
        ? params.editMode
        : this.state.editMode,
      showControl: params?.showControl !== undefined
        ? params.showControl
        : this.state.showControl,
      hideFields: params?.hideFields !== undefined ? params.hideFields : this.state.hideFields,
      requiredFields: params?.requiredFields !== undefined ? params.requiredFields : this.state.requiredFields,
      successCallback: params?.successCallback ? params.successCallback : this.state.successCallback,
      errorCallback: params?.errorCallback ? params.errorCallback : this.state.errorCallback,
    };

    if(params?.company && typeof(params.company) !== "string" && params.company?.id){
      state.id = params.company.id
    }

    this.setState(state);
  }

  public hide(){
    this.setState({
      visible: false,
      errors: [],
      errorTexts: [],
      feedbackDialog: false,
      feedbackStatus: "",
      feedbackBody: "",
      company: undefined,
      id: undefined
    });
  }

  private save(){

    if(this.state.company){
      this.setState({
        saving: true
      }, () => {
        
        this.validation()
          .then((result: IValidationResponse) => {

            if(result.status){

              let refinedCompany: ICompany = {...this.state.company};
              this.state.hideFields?.forEach((fieldToRemove: string) => {
                const k = fieldToRemove as keyof ICompany;
                delete refinedCompany[k];
              });

              // pulisco i campi nullish
              Object.entries(refinedCompany as {}).forEach(([k, v]: [string, any]) => {
                if(v === undefined || v === null){
                  delete refinedCompany[k as  keyof ICompany];
                }
              })

              if(!refinedCompany?.id && this.state.id){
                refinedCompany.id = this.state.id;
              }

              User.getInstance().upsert("company", refinedCompany)
                .then((response: any) => {
                  if(response?.success === false){
                    if(response.error_code === "DUPLICATES_DETECTED"){
                      throw new DuplicateException("Azienda già inserita")
                    }
                    
                    throw new GenericException("Impossibile completare la richiesta. Contattare il supporto tecnico", "generic")
                  }
                  else{
                    this.defaultSuccessCallback({company: response, validation: result}, this.state.successCallback);
                  }
                })
                .catch((error: any) => {
                  if(error instanceof Error){
                    this.defaultErrorCallback(error);
                  }
                  else{
                    this.defaultErrorCallback();
                  }
                })
            }
            else{
              this.defaultErrorCallback();
            }
          })

      })
    }
  }  

  /**
   * Handles various state change events related to the form's components.
   *
   * @param {any} selected The selected object
   * @param {string} componentName The name of the component to handle
   */
  private onChangeHandler(value: any, componentName: string) {
    
    switch(componentName){
      case "indirizzo":
        const [address, postalCodeOfResidence, cityOfResidence] = value;
        let newState = {
          address: address,
          postalCodeOfResidence: postalCodeOfResidence,
          cityOfResidence: cityOfResidence
        };
        value = newState;
        this.setState({ company: { ...this.state.company , ...value }});
        break;
      case "name":
        this.setState({
          company: { ...this.state.company , name: value}
        }, () => {
          this.setState({
            header: <h3 className="colorPrimaryGreen titleLev4">{value}</h3>
          });});
        break;
      case "surname":
        this.setState({
          company: { ...this.state.company , referent_last_name: value}
        });
        break;
      case "referent_phone":
          value = value.substring(0, 10);
          this.setState({
            company: { ...this.state.company , referent_phone: value}
          });
        break;
      default:
        this.setState({ company: { ...this.state.company , [componentName]: value} });
        break;
    }

    this.resetValidation(componentName);

  }

  /**
   * permette l'aggiornamento dello stato tramite un component esterno
   * @param typeOfCompany
   * @param address
   * @param postalCode
   * @param cityOfResidence
   */
   private updateAddress(
    address: string,
    postalCode: string,
    cityOfResidence: AjaxAutocompleteObject,
    civico: string
  ) {

    // if (civico && !isStreetAddres(address)){
    //   address += `, ${civico}`;
    // }

    this.setState({
      company: {
        ...this.state.company,
        registered_office_address: address,
        /*registered_office_addressNumber: civico,
        registered_office_postalCode: postalCode,*/
      }
    }, () => {
      this.validation(["registered_office_address"/*, "registered_office_codeOfResidence", "registered_office_cityOfResidence", "registered_office_addressNumber"*/])
    });
  }

  /**
   * Reset form element validation
   * @param element
   * @returns
   */
  resetValidation(element: any) {
    this.setState({ errors: this.state.errors.filter((item: any) => item !== element) });
    return;
  }

  private async validation(specificField: undefined | string | string[] = undefined): Promise<IValidationResponse>{
    let fieldsToValidate : IFieldDescriptor[] = [];
    let initialErrors : string[] = [];
    let initialErrorTexts : IFeedbackText[] = [];
    // fix: Uncaught (in promise) TypeError: Cannot read property 'field' of undefined
    // in pratica da loggato: quando creo una companya, selezionando un comune di nascita, non viene settato requiredFields nello stato e la validazione non vine fatta..
    // da verificare se succede anche con altri campi, o è un comportamento voluto..
    
    if (this.state.requiredFields) {
      if (specificField !== undefined) {
        if (Array.isArray(specificField)) {
          fieldsToValidate = specificField.map(field => this.state.requiredFields?.[field])
        }
        else {
          fieldsToValidate = [this.state.requiredFields?.[specificField]];
        }
        let tmp = fieldsToValidate.map((descriptor: IFieldDescriptor) => descriptor.field);
        initialErrors = this.state.errors.filter((err: string) => !tmp.includes(err))
        initialErrorTexts = this.state.errorTexts.filter((feed: IFeedbackText) => !tmp.includes(feed.field))
      }
      else {
        fieldsToValidate = Object.entries(this.state.requiredFields).map(([, value]) => value) as IFieldDescriptor[];
      }
    }

    return User.validateCompany(this.state.company as ICompany, fieldsToValidate)
    .then((result: IValidationResponse) => {
      const {error_fields, error_messages} = result;
      this.setState({
        errors: [...initialErrors, ...error_fields],
        errorTexts: [...initialErrorTexts, ...error_messages]
      })
      return result;
    });
  }

  private dialogScrollToTop() {
    this.dialogRef?.current.scrollIntoView({
      behavior: "smooth"      
    })
  }
}