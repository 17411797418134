import { Component, createRef, useContext } from "react";
import { Card } from "primereact/card";
import { SelectButton } from "primereact/selectbutton";
import * as Constants from "./utils/constants";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { IFieldDescriptor, IPerson, Step1Data, UserInfo } from "./utils/common";
import {
  step1DataFailure,
  step1DataSuccess,
  userInfoDataSuccess,
  step3DataSuccess
} from "../redux/actions";
import {
  fetchAutocomplete,
  fixDirtyBackendErrorString /*, getProgressValue*/,
  isLikelyDate,
  formatDateAdform,
  removeFocusClass,
  replaceSpecialChars,
  offsetFirstErrorField,
  openLogin
} from "./utils/methods";

import CookieService from "./utils/CookieService";
import { InputText } from "primereact/inputtext";
import StepLoaderSpinner from "./StepLoaderSpinner";
import User, { IValidationResponse } from "./utils/User";
import InputDate from "./customs/InputDate";
import UserService from "./utils/UserService";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import { Step1PersonFieldsBuilder } from "./utils/PersonFieldsBuilder";
import { connect } from "react-redux";
import { StepDataDualState } from "../redux/reducers";
import { useHistory } from "react-router-dom";
import Info6Sicuro from "./customs/Info6Sicuro";
import withEventManager from "./customs/HOC/withEventManager";
import { compose } from "ramda";
import { IWithEventManager } from "./customs/HOC/EventManager";
import { DataList, IDataList } from "./customs/dataListContext";
import moment from "moment";

interface IStep1Props extends IWithEventManager, IDataList{
  userInfoDataSuccess: Function;
  step1DataSuccess: Function;
  step1DataFailure: Function;
  step1DataReset: Function;
  step3DataSuccess: Function;
  userInfo: UserInfo;
  step1Data: StepDataDualState;
  fromMailSereno: boolean;
  vehicle: string;
  history: ReturnType<typeof useHistory>;
  setResourceListRequest: Function;
}

interface IStep1State {
  autocompleteCities: any[] | undefined;
  autocompleteCountries: any[] | undefined;

  email?: string;
  
  phoneValid: boolean | undefined;
  phoneChecking: boolean;

  submitting: boolean;
  errors: string[];
  errorTexts: IFeedbackText[];
  validDateOfBirthLabel?: boolean;
  loading: boolean;
  
  s_id?: any;
  gclid?: any;
  fbclid?: any,
  msclkid?: any;
  ttclid?: any;
  fbp?: any;
  fbc?: any;
  sessionId?: any;
  utm_id?: any;
  utm_campaign?: any;
  utm_content?: any;
  utm_source?: any;
  utm_medium?: any;
  utm_name?: any;
  utm_term?: any;
}

class Step1Component extends Component<IStep1Props, IStep1State> {
  private vehicle: string;

  private previousPhone : string;
  private static emailInput: any;

  private readonly personFields : any;

  // header del popup di login da settare quando viene inserita una mail registrata ma non loggata
  private forceToLoginHeader: JSX.Element = <div>
    <h3 className="titleLev4 colorPrimaryBlue">Un preventivo tutto per te!</h3>
    <p>Accedi ai vantaggi dell'area personale. Potrai salvare il preventivo più conveniente e bloccare il miglior prezzo.</p>
  </div>;

  constructor(props: any) {
    super(props);

    this.vehicle = props.vehicle;

    this.personFields = Step1PersonFieldsBuilder(false);
    Step1Component.emailInput = createRef();

    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;

    const query = new URLSearchParams(window.location.search);
    const utm_id = query.get("utm_id") ?? sessionStorage.getItem("utm_id");
    const utm_campaign = query.get("utm_campaign") ?? sessionStorage.getItem("utm_campaign");
    const utm_content = query.get("utm_content") ?? sessionStorage.getItem("utm_content");
    const utm_source = query.get("utm_source") ?? sessionStorage.getItem("utm_source");
    const utm_medium = query.get("utm_medium") ?? sessionStorage.getItem("utm_medium");
    const utm_name = query.get("utm_name") ?? sessionStorage.getItem("utm_name");
    const utm_term = query.get("utm_term") ?? sessionStorage.getItem("utm_term");
    const gclid = query.get("gclid") ?? sessionStorage.getItem("gclid");
    const fbclid = query.get("fbclid") ?? sessionStorage.getItem("fbclid");
    const msclkid = query.get("msclkid") ?? sessionStorage.getItem("msclkid");
    const ttclid = query.get("ttclid") ?? sessionStorage.getItem("ttclid");
    const s_id = query.get("s_id") ?? sessionStorage.getItem("s_id");

    const fbp = CookieService.getCookie("_fbp");
    const fbc = CookieService.getCookie("_fbp");
    const sessionId = CookieService.getCookie(`richiestapreventivoid${this.vehicle}`)

    const isBackward : boolean = this.props.userInfo?.direction === 'backwards';

    if (!isBackward) {
      this.saveReduxState({
        gclid: (gclid && gclid !== null) ? gclid : undefined,
        fbclid: (fbclid && fbclid !== null) ? fbclid : undefined,
        msclkid: (msclkid && msclkid !== null) ? msclkid : undefined,
        ttclid: (ttclid && ttclid !== null) ? ttclid : undefined,
        s_id: (s_id && s_id !== null) ? s_id : undefined,
        utm_id: (utm_id && utm_id !== null) ? utm_id : undefined,
        utm_campaign: (utm_campaign && utm_campaign !== null) ? utm_campaign : undefined,
        utm_content: (utm_content && utm_content !== null) ? utm_content : undefined,
        utm_source: (utm_source && utm_source !== null) ? utm_source : undefined,
        utm_medium: (utm_medium && utm_medium !== null) ? utm_medium : undefined,
        utm_name: (utm_name && utm_name !== null) ? utm_name : undefined,
        utm_term: (utm_term && utm_term !== null) ? utm_term : undefined,
        fbp: (fbp && fbp !== null) ? fbp : undefined,
        fbc: (fbc && fbc !== null) ? fbc : undefined,
        sessionId: (sessionId && sessionId !== null) ? sessionId : undefined
      });
    }

    this.state = {
      errors: [],
      submitting: false,
      errorTexts: [],
      autocompleteCities: undefined,
      autocompleteCountries: undefined,

      loading: false,
      
      email: step1Data?.email ?? "",
      // inputMailPosition: (step1Data.email ?? "").length,

      phoneValid: undefined,    // status of phone validation, true if valid
      phoneChecking: false,     // ongoing phone validation

      validDateOfBirthLabel: false,
    };

    this.previousPhone = step1Data.phone ?? "";

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.saveReduxState = this.saveReduxState.bind(this);
    this.trakSendLoaded = this.trakSendLoaded.bind(this);
    this.submitToNode = this.submitToNode.bind(this);
    
    this.handleEmailHighlighting = this.handleEmailHighlighting.bind(this);

  }

  componentDidMount() {
    console.log("Step1Guest componentDidMount");
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;

    let resourseRequestMask: any = {};
    if(!(this.props?.professionList?.length ?? false)){
      resourseRequestMask.professionList = true;
    }
    
    if(!(this.props?.civilStateList?.length ?? false)){
      resourseRequestMask.civilStateList = true;
    }
    
    if(!(this.props?.degreeList?.length ?? false)){
      resourseRequestMask.degreeList = true;
    }

    if(JSON.stringify(resourseRequestMask) != "{}"){
      this.props.setResourceListRequest(resourseRequestMask)
    }
    
    this.phoneHandler(step1Data?.phone ?? "", true);

    if(!(window as any)?.Cookiebot?.hasResponse){
      window.addEventListener("CookiebotOnAccept", this.trakSendLoaded)
      window.addEventListener("CookiebotOnDecline", this.trakSendLoaded)
    }
    else{
      this.trakSendLoaded();
    }

  }
  

  private emailInputElement() : JSX.Element{
    return <span className="p-input-icon-right">
      {this.props.userInfo.emailChecking
        ? <i className="pi pi-spin pi-spinner" />
        : this.props.userInfo.step1EmailValid !== undefined &&
          (this.props.userInfo.step1EmailValid
            ? <i className="pi pi-check" />
            : this.props.userInfo.emailRegistered
              ? <i className="pi pi-user" />
              : <i className="pi pi-times" />)
      }
      <InputText
        ref={Step1Component.emailInput}
        // value={step1Data.email ?? ""}
        value={this.state.email ?? ""}
        onChange={(selected) => {

          // const htmlElem: HTMLInputElement = Step1Component.emailInput.current as HTMLInputElement
          // console.log(htmlElem.selectionStart);
          // console.log("setto: " + htmlElem.selectionStart ?? Step1Component.emailInput.current.value.length);
          // this.setState({inputMailPosition: htmlElem.selectionStart ?? Step1Component.emailInput.current.value.length})

          const email: string = selected.target.value;
          this.setState(
            {email: email},
            // () => {
            //   this.props.step1DataSuccess({email: email}, this.vehicle)
            //   this.props.step3DataSuccess({contractorEmail: email}, this.vehicle)
            // }
          )
        }}
        onBlur={(selected) => {
          this.setState({
            errors: this.state.errors.filter((err: string) => err !== "email"),
            errorTexts: this.state.errorTexts.filter((err: IFeedbackText) => err.field !== "email")
          })
          console.log("Step1Guest email set in userInfo")
          this.props.step1DataSuccess({email: this.state.email}, this.vehicle)
          this.props.step3DataSuccess({contractorEmail: this.state.email}, this.vehicle)
        }}
        placeholder="E-mail"
        name="email"
        id="contraenete-email"
        autoComplete="email"
        type="text"
        // onKeyUp={(event) => {
        //   const htmlElem: HTMLInputElement = Step1Component.emailInput.current as HTMLInputElement
        //   if(this.isPrintableCharacter(event) && (((htmlElem.selectionStart ?? 0) - (htmlElem.selectionEnd ?? 0)) !== 0)){
        //     console.log(htmlElem.value);
        //   }
        // }}
        onKeyDown={(event) => {
          const disabled: boolean = !this.props.userInfo?.step1EmailValid;
          
          if(this.isPrintableCharacter(event)){
            
            // const htmlElem: HTMLInputElement = Step1Component.emailInput.current as HTMLInputElement
            // this.setState({inputMailPosition: htmlElem.selectionStart ?? Step1Component.emailInput.current.value.length})

            if(event.key === " "){
              event.preventDefault();
            }
          }
          else{
            if (event.code === "Tab" && disabled){
              event.preventDefault();
              event.currentTarget.blur();
            }
          }

        }}
      />
    </span>
  }

  private phoneHandler(candidatePhone: string, skipPrevValueControl: boolean = false){
    if(skipPrevValueControl || candidatePhone !== this.previousPhone){
      if(candidatePhone === ""){
        this.setState({phoneChecking: false, phoneValid: false })
      }
      else{
        this.setState({phoneChecking: true}, () => {

          UserService.call(Constants.WEB_SITE_API_SERVER_URL("/utils/misc/validate"), {
            method: "post",
            body: JSON.stringify({phone: candidatePhone ?? ""})
          })
            .then(result => {
              result?.phone.valid
                ? this.setState(
                    {
                      phoneChecking: false,
                      phoneValid: true,
                      errors: this.state.errors.filter(e => e !== "phone")
                    },
                    () => this.props.step3DataSuccess({contractorPhoneNumber: candidatePhone}, this.vehicle)
                  )
                : this.setState({
                    phoneChecking: false,
                    phoneValid: false,
                    errors: this.state.errors.includes("phone")
                      ? this.state.errors
                      : [...this.state.errors, "phone"]
                    }
                  )
            })
            .catch((err: any) => {
              this.setState({phoneChecking: false, phoneValid: false,errors: this.state.errors.includes("phone") ? this.state.errors : [...this.state.errors, "phone"]})
            })
        })
      }
    }
  }

  handleEmailHighlighting(){
    const disabled = !this.props.userInfo.step1EmailValid;

    if(disabled && this.props.userInfo.emailChecking === false){
      this.setState({
        errors: [...this.state.errors.filter((err: string) => err !== "email"), "email"],
        errorTexts: [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "email"), {
          field: "email",
          msg: "Prima di procedere effettua il login o inserisci il tuo indirizzo email",
          severity: "warning"
        }],
      }, () => {
        window.scrollTo(0, offsetFirstErrorField());
      })
    }
    else{
      this.setState({
        errors: [...this.state.errors.filter((err: string) => err !== "email")],
        errorTexts: [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "email")],
      });
    }
  }

  isPrintableCharacter(event: React.KeyboardEvent<HTMLInputElement>){
    return event.key.length === 1 && !event.ctrlKey && !event.metaKey
  }

  componentDidUpdate(prevProps: Readonly<IStep1Props>, prevState: Readonly<IStep1State>, snapshot?: any): void {
    (prevProps.userInfo.step1EmailValid !== this.props.userInfo.step1EmailValid ||
    prevProps.userInfo.emailChecking !== this.props.userInfo.emailChecking) && this.handleEmailHighlighting()
  }

  render() {
    const contractorData: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const disabled = !this.props.userInfo.step1EmailValid;
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;

    let dateOfBirth: Date | undefined = undefined;
    if(step1Data.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    // const stringDate = formataDateToString(dateOfBirth);
    const stringDate: Date | undefined = contractorData?.dateOfBirth !== undefined
      ? !isNaN((new Date(contractorData.dateOfBirth)).getDate())
          ? new Date(contractorData.dateOfBirth)
          : undefined
      : undefined

    return (
      <>
        {!this.state.loading
          ?
            <div id="step-1-form">
              
              <Card className="mailChecker pr-form mb-4">
                <div className="p-fluid p-4 px-lg-5 py-lg-5">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h2 className="colorWhite titleLev4 mb-0 mb-3">Ehi, per caso ci siamo già visti?</h2>
                      <p className="colorWhite">Inizia subito a risparmiare! Inserisci il tuo indirizzo email per procedere alla preventivazione</p>
                    </div>
                    <div className="col-12">
                      <label className="colorWhite" htmlFor="contraenete-email">E-mail</label>
                      {this.emailInputElement()}
                      <ContextFeedback
                        show={this.state.errors.includes("email")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "email") ??
                          {msg: "Inserisci un indirizzo email valido", severity: "error"}
                        }
                      />
                      {this.props.userInfo?.step1EmailValid !== undefined && !this.props.userInfo.step1EmailValid && this.props.userInfo?.emailRegistered &&
                        <div className="mt-2">
                          <p className="colorWhite mb-0">Ehi mi sembra di conoscerti, loggati per procedere</p>
                          <div className="text-center">
                            <Button
                              className="customBtn bgColorGrey colorPrimaryBlue hoverBgColorDarkgreen hoverColorWhite mt-3"
                              onClick={() => openLogin(this.vehicle, contractorData?.email)}
                              label="Accedi"
                            />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </Card>

              <Card className="pr-form">
                <div className="p-fluid p-4 px-lg-5 py-lg-5" onClick={this.handleEmailHighlighting}>
    
                  <div className="row">
                    <div className="col-12">
                      <h1 className="titleLev4 colorPrimaryBlue mb-4">
                        Preventivo Assicurazione{" "}
                        {this.vehicle === "auto" ? "Auto" : "Moto"} online
                      </h1>
                      <h2 className="titleLev6 mt-4">Dati contraente</h2>
                    </div>
                  </div>

                  <div className="row">
                    
                    <FeedbackBox items={this.state.errorTexts}/>

                    {this.isVisible("gender") &&
                      <div className="col-12 mb-4">
                        <label>Sesso</label>
                        <SelectButton
                          value={contractorData.gender}
                          options={[
                            { label: "Uomo", value: "uomo" },
                            { label: "Donna", value: "donna" },
                          ]}
                          onChange={(selected) =>
                            this.onChangeHandler(selected.value, "gender")
                          }
                          className="regularBtn"
                          unselectable={false}
                          disabled={disabled}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("gender")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "gender") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                    }
                    
                    {this.isVisible("dateOfBirth") &&
                      <div className="col-12 mb-4">
                        <label
                          htmlFor="contraenete-date-of-birth"
                          className={
                            this.state.validDateOfBirthLabel ? "" : "gray-label"
                          }
                        >
                          Data di nascita
                        </label>
                        <InputDate
                          id="contraenete-date-of-birth"
                          date={stringDate}
                          onBlur={stringDate => this.onBlurHandlerDate(stringDate)}
                          disabled={disabled}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("dateOfBirth")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "dateOfBirth") ??
                            {msg: "Indica una data di nascita valida. Devi essere maggiorenne", severity: "error"}
                          }
                        />
                      </div>
                    }
                    
                    {this.isVisible("bornInItaly") &&
                      <div className="col-12 field-button mb-4">
                        <label>Paese di nascita</label>
                        <SelectButton
                          value={contractorData.bornInItaly}
                          options={[
                            { label: "Italia", value: true },
                            { label: "Altro", value: false },
                          ]}
                          onChange={(selected) =>
                            this.onChangeHandler(selected.value, "bornInItaly")
                          }
                          className="regularBtn"
                          unselectable={false}
                          disabled={disabled}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("bornInItaly")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "bornInItaly") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                    }
                    
                    {this.isVisible("countryOfBirth") &&
                      <div className="col-12 mb-4">
                        <label htmlFor="country-of-birth">Stato di nascita</label>
                        <AutoComplete
                          inputId="country-of-birth"
                          value={contractorData.countryOfBirth}
                          suggestions={this.state.autocompleteCountries}
                          completeMethod={(e) =>
                            fetchAutocomplete(
                              e,
                              Constants.SEISICURO_AJAX_COUNTRY_OF_BIRTH_ENDPOINT,
                              (result: any) => {
                                this.setState({ autocompleteCountries: result });
                              },
                              ""
                            )
                          }
                          field="value"
                          placeholder="Stato di nascita"
                          onChange={(selected) =>
                            this.onChangeHandler(selected.value, "countryOfBirth")
                          }
                          disabled={disabled}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("countryOfBirth")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "countryOfBirth") ??
                            {msg: "Inserisci lo stato di nascita", severity: "error"}
                          }
                        />
                      </div>
                    }
                    
                    {this.isVisible("cityOfBirth") &&
                      <div className="col-12 mb-4">
                        <label>Comune di nascita</label>
                        <AutoComplete
                          value={contractorData.cityOfBirth}
                          suggestions={this.state.autocompleteCities}
                          completeMethod={(e) =>
                            fetchAutocomplete(
                              e,
                              Constants.SEISICURO_AJAX_CITY_OF_BIRTH_ENDPOINT,
                              (result: any) => {
                                this.setState({ autocompleteCities: result });
                              },
                              "NASCITA"
                            )
                          }
                          field="value"
                          placeholder="Comune di nascita"
                          onChange={(selected) => this.onChangeHandler(selected.value, "cityOfBirth")}
                          disabled={disabled}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("cityOfBirth")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "cityOfBirth") ??
                            {msg: "Inserisci il comune di nascita", severity: "error"}
                          }
                        />
                      </div>
                    }
                    
                    {this.isVisible("civilState") &&
                      <div className="col-12 mb-4">
                        <label>Stato civile</label>
                        <Dropdown
                          value={contractorData.civilState}
                          options={this.props.civilStateList}
                          onChange={(selected) =>
                            this.onChangeHandler(selected.value, "civilState")
                          }
                          onHide={() => removeFocusClass()}
                          placeholder="Seleziona"
                          optionLabel="label"
                          emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          disabled={disabled}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("civilState")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "civilState") ??
                            {msg: "Inserisci lo stato civile", severity: "error"}
                          }
                        />
                      </div>
                    }
                    
                    {this.isVisible("degree") &&
                      <div className="col-xxl-5 col-12 mb-4">
                        <label>Titolo di studio</label>
                        <Dropdown
                          value={contractorData.degree}
                          options={this.props.degreeList}
                          onChange={(selected) =>
                            this.onChangeHandler(selected.value, "degree")
                          }
                          onHide={() => removeFocusClass()}
                          placeholder="Seleziona"
                          optionLabel="label"
                          emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          disabled={disabled}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("degree")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "degree") ??
                            {msg: "Inserisci il titolo di studio", severity: "error"}
                          }
                        />
                      </div>
                    }
                    
                    {this.isVisible("profession") &&
                      <div className="col-xxl-7 col-12 mb-4">
                        <label>Professione</label>
                        <Dropdown
                          value={contractorData.profession}
                          options={this.props.professionList}
                          onChange={(selected) =>
                            this.onChangeHandler(selected.value, "profession")
                          }
                          onHide={() => removeFocusClass()}
                          placeholder="Seleziona"
                          optionLabel="label"
                          emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          disabled={disabled}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("profession")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "profession") ??
                            {msg: "Inserisci la professione", severity: "error"}
                          }
                        />
                      </div>
                    }
                    
                    {this.isVisible("phone") &&
                      <div className="col-12 mb-4">
                        <label htmlFor="contraenete-phone">Cellulare</label>
                          <span className="p-input-icon-right">
                          {(this.state?.phoneValid !== undefined) &&
                            <>
                              {this.state.phoneValid
                                ? <i className="pi pi-check" />
                                : <i className="pi pi-times" />
                              }
                            </>
                          }
                          <InputText
                            value={contractorData.phone ?? ""}
                            onChange={(selected) => this.onChangeHandler( selected.target.value, "phone" ) }
                            onBlur={(selected) => this.phoneHandler(selected.target.value)}
                            placeholder="Cellulare"
                            name="phone"
                            keyfilter={/^\d+(\.\d{1,2})?$/}
                            id="contraenete-phone"
                            autoComplete="tel"
                            type="tel"
                            disabled={disabled}
                          />
                        </span>
                        <ContextFeedback
                          show={this.state.errors.includes("phone")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "phone") ??
                            {msg: "Inserire un numero di cellulare valido", severity: "error"}
                          }
                        />
                        {/*<small>l numero di telefono è richiesto per fornirti un supporto costante e rendere più comoda e sicura la tua autenticazione.</small>*/}
                      </div>
                    }
                  </div>
                </div>

                <div className="step-btn-container p-fluid p-4 px-lg-5 py-lg-5">
                  <div className="row">
                    <div className="col-12 button-next">
                      <Button
                        className="customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                        type="button"
                        label="Continua"
                        disabled={disabled}
                        onClick={() => this.handleFormSubmit()}
                        loading={this.state.submitting}
                      />

                    </div>
                  </div>
                </div>
              </Card>

              <Info6Sicuro />

            </div>
          :
            <StepLoaderSpinner title="Caricamento"/>
        }
      </>
    );
  }

  private getDataToTrack(){
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    return {
      prodotto: this.vehicle,
      gender: step1Data.gender,
      dataNascita: dateOfBirth !== undefined
        ? formatDateAdform(dateOfBirth.getTime())
        : undefined,
      titoloStudio: step1Data.degree,
      professione: step1Data.profession,
    }
  }

  /**
   * Trak tag-manager: notifica errore
   */
  private trackSendFailure() {
    this.props.trackEvent?.({
      name: "step1GuestError",
      data: {
        ...this.getDataToTrack(),
        errors: this.state.errors,
        errorTexts: this.state.errorTexts,
      }
    });
  }

  /**
   * Trak tag-manager: notifica successo
   */
  private trackSendSuccess(callbackFunction: Function) {
    this.props.trackEvent?.({
      name: "step1GuestSuccess",
      data: this.getDataToTrack(),
      callback: callbackFunction
    });
  }

  /**
   * Track tag-mamager: notifica pagina caricata
   */
  private trakSendLoaded() {
    this.props.trackEvent?.({
      name: "step1GuestLoaded",
      data: this.getDataToTrack()
    });
  }

  /**
   * Handles various state change events related to the form's components.
   *
   * @param {any} selected The selected object
   * @param {string} componentName The name of the component to handle
   */
  private onChangeHandler(selected: any, componentName: string){
    let pData: IPerson = {};

    switch(componentName){
      case "phone":
        selected = selected.substring(0, 10);
        break;
      case "cityOfBirth":
        // sostituice gli apici ’ con gli apostrofi
        selected = replaceSpecialChars(selected);
        break;
      default:
        break;
    }
    pData[componentName as keyof IPerson] = selected;

    this.resetValidation(componentName, () => {
      let errorState = {
        errors: this.state.errors.filter((err: string) => err !== "generic"),
        errorTexts: this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "generic")
      }
      if(componentName !== "email"){
        errorState.errors = errorState.errors.filter((err: string) => err !== "email")
        errorState.errorTexts = errorState.errorTexts.filter((err: IFeedbackText) => err.field !== "email")
      }

      this.setState(errorState);
    });

    this.saveReduxState(this.IPersonToStep1Data(pData));
  }

  /**
   * Check if current field is viewable.
   *
   * @param {string} current_field The current field
   * @returns {boolean} Visibility check
   */
  private isVisible(current_field: string): boolean {
    let is_visible : boolean = false;

    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    switch (current_field) {
      case "gender":
        is_visible = true;
        break;
      case "dateOfBirth":
        is_visible = step1Data.gender !== undefined;
        break;
      case "civilState":
          is_visible = dateOfBirth !== undefined && !isNaN(dateOfBirth.getTime());
        break;
      case "bornInItaly":
        is_visible = true;
        break;
      case "countryOfBirth":
        is_visible = step1Data.bornInItaly === false;
        break;
      case "cityOfBirth":
        is_visible = step1Data.bornInItaly === true;
        break;
      case "degree":
        is_visible = true;
        break;
      case 'profession':
        is_visible = true;
      //   is_visible = this.state.degree !== undefined;
        break;
      case "email": 
        is_visible = true;
        break;
      case "phone": 
        is_visible = true;
        break;
      default:
        is_visible = false;
        break;
    }

    return is_visible;
  }

  /**
   * Form validation.
   *
   * @param specificField whether to check validity of a specific field
   * @returns {Promise} Validation check
   */
  private validation(specificField: undefined | string = undefined) : Promise<IValidationResponse>{

    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }
    
    const person : IPerson = {
      gender: step1Data?.gender,
      dateOfBirth: dateOfBirth,
      civilState: step1Data?.civilState,
      degree: step1Data?.degree,
      countryOfBirth: step1Data?.countryOfBirth,
      cityOfBirth: step1Data?.cityOfBirth,
      profession: step1Data?.profession,
      bornInItaly: step1Data?.bornInItaly,
      email: step1Data?.email,
      phone: step1Data?.phone,
    }

    let fieldToValidate : IFieldDescriptor[] = [];

    if(specificField !== undefined){
      fieldToValidate = this.personFields[specificField] ? [this.personFields[specificField]] : [];
    }
    else{
      fieldToValidate = Object.entries(this.personFields).map(([, value]) => value) as IFieldDescriptor[]
    }

    let result: {
      status: boolean;
      error_fields: string[];
      error_messages: IFeedbackText[];
    } = {
      status: true,
      error_fields: [],
      error_messages: []
    };

    return Promise
      .resolve(result)
      .then((result: {status: boolean; error_fields: string[]; error_messages: IFeedbackText[]}) => {

        if(!result.status){
          return result;
        }
        else{
          return User.validatePerson(person, fieldToValidate)
           .then((result: {
             status: boolean;
             error_fields: string[];
             error_messages: IFeedbackText[];
           }) => {
             let {status, error_fields, error_messages} = result;
             if(!status){
     
               // move feedback into generic
               if(this.props.userInfo?.logged ?? false){
     
                 let wrappedTextError : string[] = [];
                 if(!status){
       
                   error_fields.forEach((errorfield : string) => {
                     const extistError : boolean = error_messages?.some((feedback: IFeedbackText) => feedback.field === errorfield && feedback?.msg) ?? false;
       
                     if(extistError){
                       wrappedTextError.push(error_messages
                         .filter((feedback: IFeedbackText) => feedback.field === errorfield && feedback?.msg)
                         .map((feedback: IFeedbackText) => feedback.msg)
                         .join(";"))
                     }
                     else{
                       wrappedTextError.push(errorfield);
                     }
                   })
                   
                   error_fields.push("user");
                   error_messages.push({
                     field: "user",
                     msg: "Assicurati di aver fornito tutti i dati necessari"
                   })
                 }
               }
     
               this.setState({errors: error_fields, errorTexts: error_messages})
     
             }
             return result;
           });
        }

      })
  }

  /**
   * Reset form element validation
   * @param element
   * @returns
   */
  resetValidation(element: string, callback?: Function) {
    this.setState({
      errors: this.state.errors.filter((item: string) => item !== element),
      errorTexts: this.state.errorTexts.filter((item: IFeedbackText) => item.field !== element),
    }, () => callback?.());
  }

  /**
   * Check and append state data to form
   *
   * @param {any} formData The form data
   * @param {string } key The data key
   * @param {any} stateVariable The state variable
   */
  private formDataAppend(formData: any, key: string, stateVariable: any) {
    if (stateVariable !== undefined) {
      formData.append(key, `${stateVariable}`);
      //console.log(formData);
    }
  }

  private IPersonToStep1Data(p: IPerson): Step1Data{
    let converted: Step1Data;
    let dateOfBirth_ts: number | undefined = undefined;
    if(p?.dateOfBirth && p.dateOfBirth instanceof Date){
      dateOfBirth_ts = p.dateOfBirth.getTime();
      converted = {...p, dateOfBirth: dateOfBirth_ts}
    }
    else{
      converted = p as Step1Data;
    }

    return converted;
  }

  /**
   * Saves the current internal component state to Redux.
   */
  private saveReduxState(bodyData: Step1Data) {
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    this.props.step1DataSuccess({...step1Data, ...bodyData}, this.vehicle);
  }

  private submitToNode() {

    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    let formData = new URLSearchParams();
    formData.append("vehicle", this.vehicle);
    formData.append("step", "1");
    // TODO: Il vecchio preventivatore setta i prossimi tre campi a stringa vuota, verificare se sono effettivamente necessari
    formData.append("contraente+partitaIva", "");
    formData.append("contraente+denominazioneSociale", "");
    formData.append("contraente+settoreMerceologico", "");
    this.formDataAppend(formData, "contraente+sesso", step1Data.gender);
    if (dateOfBirth) {
      formData.append("contraente+dataDiNascita+giorno", dateOfBirth.getDate().toString());
      formData.append("contraente+dataDiNascita+mese", dateOfBirth.toLocaleString("it-IT", {month: "long",}));
      formData.append("contraente+dataDiNascita+anno", dateOfBirth.getFullYear().toString());
    }

    formData.append("contraente+natoInItalia", `${step1Data.bornInItaly}`);
    if (step1Data.bornInItaly) {
      formData.append("contraente+luogoDiNascitaItalia+id", `${step1Data.cityOfBirth?.id}`);
    } else {
      formData.append("contraente+luogoDiNascitaEstero+id", `${step1Data.countryOfBirth?.id}`);
    }
    this.formDataAppend(formData, "contraente+statoCivile", step1Data.civilState);
    this.formDataAppend(formData, "contraente+titoloDiStudio", step1Data.degree);
    this.formDataAppend(formData, "contraente+professione", step1Data.profession);


    // tracciamenti
    if (step1Data.utm_campaign) {
      formData.append("utm_campaign", `${step1Data.utm_campaign}`);
    }
    if (step1Data.utm_content) {
      formData.append("utm_content", `${step1Data.utm_content}`);
    }
    if (step1Data.utm_source) {
      formData.append("utm_source", `${step1Data.utm_source}`);
    }
    if (step1Data.utm_medium) {
      formData.append("utm_medium", `${step1Data.utm_medium}`);
    }
    if (step1Data.utm_name) {
      formData.append("utm_name", `${step1Data.utm_name}`);
    }
    if (step1Data.utm_term) {
      formData.append("utm_term", `${step1Data.utm_term}`);
    }
    if (step1Data.utm_id) {
      formData.append("utm_id", `${step1Data.utm_id}`);
    }
    if (step1Data.gclid) {
      formData.append("gclid", `${step1Data.gclid}`);
    }
    if (step1Data.fbclid) {
      formData.append("fbclid", `${step1Data.fbclid}`);
    }
    if (step1Data.msclkid) {
      formData.append("msclkid", `${step1Data.msclkid}`);
    }
    if (step1Data.ttclid) {
      formData.append("ttclid", `${step1Data.ttclid}`);
    }

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.append('Content-Type', 'application/x-www-form-urlencoded; charset="utf-8"');

    fetch(Constants.SEISICURO_FORM_POST_ENDPOINT, {
      method: "post",
      credentials: "include",
      body: formData,
      headers: requestHeaders,
      // headers: {
      //   "Content-Type": 'application/x-www-form-urlencoded; charset="utf-8"' /** AUTH_REDACTED */,
      // },
    })
      .then((response) => {
        if (response.status === 400) {
          response.text().then((responseText) => {
            this.setState({ errors: [responseText] }, () => {
              this.trackSendFailure();
            });
          });
        }
        else if (response.ok) { 
          this.props.step1DataSuccess({ step1Ok: true }, this.vehicle);

          //console.log(response);
          fetch(Constants.SEISICURO_STEP_CHECK(1), {
            credentials: "include",
            headers: {
              /** AUTH_REDACTED */
            },
          })
            .then((response) => {
              response.json().then((responseJson) => {
                if (Object.keys(responseJson[0]).length > 0) {
                  this.setState(
                    {
                      errorTexts: [{
                        field: "generic",
                        msg: Object.keys(responseJson[0])
                          .map((element) => fixDirtyBackendErrorString(responseJson[0][element]))
                          .join("<\br>")
                      }],
                      submitting: false,
                    }, () => {
                      this.trackSendFailure();
                      window.scrollTo(0, offsetFirstErrorField());
                    }
                  );
                }
                else {
                  this.trackSendSuccess(() => this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(2, this.vehicle)));
                }
              });
            });
        }
        else {
          //console.log(response);
          this.props.step1DataFailure(this.vehicle);
          this.trackSendFailure();
          this.setState({ submitting: false });
        }
      })
      .catch((error) => {
        //console.log(error);
        this.props.step1DataFailure(this.vehicle);
        this.setState({ submitting: false }, () => this.trackSendFailure());
      });
  }

  /**
   * Form submit.
   *
   */
  private handleFormSubmit() {

    this.props.userInfoDataSuccess({
      ...this.props.userInfo,
      direction: "forwards",
      nextStep: 2
    });

    this.setState({submitting: true}, () => {

      this.validation()
        .then((result: any) => {
          const {status, error_fields, error_messages} = result;
          if(status){
            this.submitToNode()
          }
          else{
            this.setState({
              submitting: false,
              errors: error_fields,
              errorTexts: error_messages ?? []
            }, () => {
              this.trackSendFailure()
              window.scrollTo(0, offsetFirstErrorField());
            });
          }
        })
    })

  }

  // gestione inserimento InputDate
  private onBlurHandlerDate(dateString: string){
    
    if(dateString){
      if(isLikelyDate(dateString)){

        let dateParts: string[] = dateString.split("/");
        let testDate = moment(dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]);

        const [day, month, year] : [day: string, month : string, year : string] = dateString.split("/") as [string, string, string];
        const date: Date = new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00Z`);

        if (!testDate.isValid()) {
          this.setState({
            errors: [...this.state.errors.filter((error: string) => error !== "dateOfBirth"), "dateOfBirth"],
            errorTexts: [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Data invalida"}]
          }, () => this.saveReduxState({dateOfBirth: undefined}))
        }
        else{
          if(date instanceof Date && !isNaN(date.getTime())){
            const today = new Date();
            const age = today.getFullYear() - date.getFullYear();
            let maggiorenne : boolean;
            if(age > Constants.DOB_YEAR_RANGE_MIN){
              maggiorenne = true;
            }
            else if(age === Constants.DOB_YEAR_RANGE_MIN){
              if(today.getMonth() < date.getMonth()){
                // devi essere maggiorenne
                maggiorenne = false;
              }
              else if(today.getMonth() > date.getMonth()){
                //ok
                maggiorenne = true;
              }
              else{
                //check giorno
                if(today.getUTCDay() < date.getUTCDay()){
                  // devi essere maggiorenne
                  maggiorenne = false;
                }
                else{
                  // ok
                  maggiorenne = true;
                }
              }
            }
            else{
              maggiorenne = false;
            }
    
            if(maggiorenne){
              if(age > Constants.DOB_YEAR_RANGE_MAX){ // too old
                this.setState({
                  errors: [...this.state.errors.filter((e: string) => e !== "dateOfBirth"), "dateOfBirth"],
                  errorTexts: [...this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== "dateOfBirth"), {
                    field: "dateOfBirth",
                    msg: "Età invalida. Il contraente deve avere meno di 100 anni."
                  }]
                }, () => this.saveReduxState({dateOfBirth: date.getTime()}))
              }
              else{
                this.setState({
                  errors: this.state.errors.filter((e: string) => e !== "dateOfBirth"),
                  errorTexts: this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== "dateOfBirth")
                }, () => this.saveReduxState({dateOfBirth: date.getTime()}))
              }
            }
            else{
              this.setState({
                errors: [...this.state.errors.filter((e: string) => e !== "dateOfBirth"), "dateOfBirth"],
                errorTexts: [...this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Devi essere maggiorenne"}]
              }, () => this.saveReduxState({dateOfBirth: date.getTime()}))
            }
  
          }
          else{
            this.setState({
              errors: [...this.state.errors.filter((error: string) => error !== "dateOfBirth"), "dateOfBirth"],
              errorTexts: [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Data invalida"}]
            }, () => this.saveReduxState({dateOfBirth: undefined}))
          }
        }

      }
      else{
        this.setState({
          errors: [...this.state.errors.filter((error: string) => error !== "dateOfBirth"), "dateOfBirth"],
          errorTexts: [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Data invalida"}]
        }, () => this.saveReduxState({dateOfBirth: undefined}))
      }
    }
    else{
      this.setState({
        errors: [...this.state.errors.filter((error: string) => error !== "dateOfBirth"), "dateOfBirth"],
        errorTexts: [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Data invalida"}]
      }, () => this.saveReduxState({dateOfBirth: undefined}))
    }

  }

}

const mapState = (state: any) => {
  return {
    step1Data: state.step1Data,
    userInfo: state.userInfoData.user,
    fromMailSereno: state.step4Data.fromMailSereno
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    step1DataSuccess: (payload: any, vehicle: string) => dispatch(step1DataSuccess(payload, vehicle)),
    step3DataSuccess: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle)),
    step1DataFailure: (vehicle: string) => dispatch(step1DataFailure(vehicle)),
  }
};

// Required to access react-router's params (i.e. ":vehicle" in App.tsx).
const Step1Guest = (props: any) => {
  const history: ReturnType<typeof useHistory> = useHistory();
  const lists: IDataList = useContext(DataList);

  return <Step1Component {...props} history={history} {...lists}/>
};

// export default connect(mapState, mapDispatch)(Step1Guest);
export default compose(connect(mapState, mapDispatch), withEventManager)(Step1Guest);