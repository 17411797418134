import { Component, useContext } from "react";
import { Card } from "react-bootstrap";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import User from "./utils/User";
import ChangePasswordPanel from "./customs/ChangePasswordPanel";
import PrivacyRow from "./PrivacyRow";
import * as Constants from './utils/constants';
import { Divider } from "primereact/divider";
import UserService, { GenericException } from "./utils/UserService";
import StepLoaderSpinner from './StepLoaderSpinner';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface IGestioneAccountProps extends IPageProps{
  pages: IPageDescriptor[];
}
class AreaPersonaleGestioneAccountComponent extends Component<IGestioneAccountProps, {
  email: string;
  changePasswordVisibility: boolean;
  isLoading: boolean;
  consent_marketing: boolean;
  consent_newsletter: boolean;
  consent_profilazione: boolean;
  consent_cessione: boolean;
  methods_general: string[];
  methods_marketing: string[];
  methods_cessione: string[];
  error_visible: boolean;
  success_visible: boolean;
}>{

  private currentPage: IPageDescriptor | undefined;
  private closeAccountPage: IPageDescriptor | undefined;
  private pages: IPageDescriptor[];
  private user: User;
  private initialState: string;

  constructor(props: IGestioneAccountProps){
    super(props);

    this.pages = this.props.pages;
    this.user = this.props.user as User;

    this.state = {
      email: this.user.email,
      changePasswordVisibility: false,
      isLoading: true,
      
      consent_marketing: true,
      consent_newsletter: true,
      consent_profilazione: true,
      consent_cessione: true,
      methods_general: ['email', 'phone', 'whatsapp', 'sms'],
      methods_marketing: ['email', 'phone', 'whatsapp', 'sms'],
      methods_cessione: ['email', 'phone', 'whatsapp', 'sms'],

      error_visible: false,
      success_visible: false,
    }

    this.initialState = "";

    this.currentPage = this.pages.find((p: IPageDescriptor) => p.name === this.props.page?.name);
    this.closeAccountPage = this.pages.find((p: IPageDescriptor) => p.name === "chiudi-account");
    //this.setState = this.setState.bind(this);
    this.allConsentsHandler = this.allConsentsHandler.bind(this);
  }

  componentDidMount(){
    this.readConsentData();
  }

  private saveConsentData(){
    document.documentElement.scrollTop = 0;
    this.setState({isLoading: true});
    // force consensi e-mail
    let methods_general : any[] = this.state.methods_general;
    if(!methods_general.includes("email")){
      methods_general.push("email");
      methods_general.sort(this.stringArraySort);
    }
    let methods_marketing : any[] = this.state.methods_marketing;
    if(!methods_marketing.includes("email")){
      methods_marketing.push("email");
      methods_marketing.sort(this.stringArraySort);
    }
    let methods_cessione : any[] = this.state.methods_cessione;
    if(!methods_cessione.includes("email")){
      methods_cessione.push("email");
      methods_cessione.sort(this.stringArraySort);
    }    
    
    let data = [
      {
          "type": "generale",
          "consent": true,
          "channels": methods_general
      },
      {
          "type": "commerciale",
          "consent": this.state.consent_marketing,
          "channels": methods_marketing
      },
      {
          "type": "newsletter",
          "consent": this.state.consent_newsletter,
          "channels": []
      },
      {
          "type": "profilazione",
          "consent": this.state.consent_profilazione,
          "channels": []
      },
      {
          "type": "cessione",
          "consent": this.state.consent_cessione,
          "channels": methods_cessione
      }
    ];

    UserService.call(
      Constants.WEB_SITE_API_SERVER_URL('/my-privacy'),
      {
          method: "POST",
          body: JSON.stringify({data: data})
      },
      (response: any) => {
        if(response?.success === false){
            // caso di errore applicativo
            if(response.error_code === "GENERIC" || response.error_code === "VALIDATION_ERROR"){
                throw new GenericException("Impossibile completare la richiesta di attivazione dell'account");
            }                    
        } else {
          this.setState({
            success_visible: true,
            isLoading: false,
          });
        }
      },
      (err: any) => {
          this.setState({
              //isSuccess: false,
              isLoading: false
          })
          setTimeout(
              () => {console.log('waiting')},
              200
          )
          if (err instanceof GenericException) {
              this.setState({
                  //headerMessage: "Registrazione fallita",
                  //paragraphMessage: err.message
              })
          } else {
              this.setState({
                  //headerMessage: "Registrazione fallita",
                  //paragraphMessage: "Errore generico"
              })
          }
      }
    );
  }

  private readConsentData(){
    UserService.call(
      Constants.WEB_SITE_API_SERVER_URL('/my-privacy'),
      {
          method: "GET",
          /*body: JSON.stringify({
              email: this.state.email
          })*/
      },
      (response: any) => {
        if(response?.success === false){
            // caso di errore applicativo
            if(response.error_code === "GENERIC" || response.error_code === "VALIDATION_ERROR"){
                throw new GenericException("Impossibile completare la richiesta di attivazione dell'account");
            }                    
        } else {
          if(response.data){
            let newState = {
              consent_marketing: true,
              consent_newsletter: true,
              consent_profilazione: true,
              consent_cessione: true,
              methods_general: ['email', 'phone', 'whatsapp', 'sms'],
              methods_marketing: ['email', 'phone', 'whatsapp', 'sms'],
              methods_cessione: ['email', 'phone', 'whatsapp', 'sms']
            }
            for(let purpose of response.data){
              if(purpose.type === "generale"){
                newState.methods_general = (purpose.channels !== false) ? purpose.channels : [];
              }
              if(purpose.type === "commerciale"){
                newState.consent_marketing = purpose.consent;
                newState.methods_marketing = (purpose.channels !== false) ? purpose.channels : [];
              }
              if(purpose.type === "newsletter"){
                newState.consent_newsletter = purpose.consent;
              }
              if(purpose.type === "profilazione"){
                newState.consent_profilazione = purpose.consent;
              }
              if(purpose.type === "cessione"){
                newState.consent_cessione = purpose.consent;
                newState.methods_cessione = (purpose.channels !== false) ? purpose.channels : [];
              }
            }
            this.initialState = JSON.stringify([
              newState.consent_marketing,
              newState.consent_newsletter,
              newState.consent_profilazione,
              newState.consent_cessione,
              newState.methods_general.sort(this.stringArraySort),
              newState.methods_marketing.sort(this.stringArraySort),
              newState.methods_cessione.sort(this.stringArraySort)
            ]);
            this.setState(
              newState,
              () => {
                this.setState({                  
                  isLoading: false
                });
              }
            );
          }
          else
            throw new GenericException("Impossibile completare il salvataggio delle preferenze");          
        }
      }
    );
  }

  private stringArraySort(a: string, b:string){
    if (a > b)
      return -1;
    if (b > a)
        return 1;
    return 0;
  }

  private allConsentsHandler(e: any){
    var newValue : boolean = ! (
      this.state.consent_marketing &&
      this.state.consent_newsletter &&
      this.state.consent_profilazione &&
      this.state.consent_cessione
    );
    this.setState({
      consent_marketing: newValue,
      consent_newsletter: newValue,
      consent_profilazione: newValue,
      consent_cessione: newValue,
    });    
  }
  
  render(){
    let currentState = [
      this.state.consent_marketing,
      this.state.consent_newsletter,
      this.state.consent_profilazione,
      this.state.consent_cessione,
      this.state.methods_general.sort(this.stringArraySort),
      this.state.methods_marketing.sort(this.stringArraySort),
      this.state.methods_cessione.sort(this.stringArraySort)
    ];

    let showBtn = JSON.stringify(currentState) !== this.initialState;
    
    return <div className={`mainBody${this.state.isLoading ? " noShadow" : "" }`}>

      <Dialog
        className="customDialog success"
        header="Fin qui tutto bene"
        visible={this.state.success_visible}
        modal={true}
        onHide={() => {this.setState({success_visible: false})}}
        resizable={false}
        draggable={false}
        footer={
          <div>
              <Button label="Ok, ho capito" className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.setState({success_visible: false})}} />
          </div>
        }>
        <p>Le impostazioni sono state salvate con successo</p>        
      </Dialog>

      <Dialog
        className="customDialog error"
        header="Ops! Sembra che ci sia qualche problema"
        visible={this.state.error_visible}
        modal={true}
        onHide={() => {this.setState({success_visible: false})}}
        resizable={false}
        draggable={false}
        footer={
          <div>
              <Button label="Ok, ho capito" className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.setState({success_visible: false})}} />
          </div>
        }>
        <p>Errore nel salvataggio delle impostazioni</p>        
      </Dialog>

      <ChangePasswordPanel
        visible={this.state.changePasswordVisibility}
        onHide={() => this.setState({changePasswordVisibility: false})}
      />

      { this.state.isLoading ? <StepLoaderSpinner title="Caricamento"/> : "" }
      
      
      <div className="d-flex align-items-center">
        <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/gest-account.svg`} style={{height: "3rem", marginRight: "1rem"}} alt=""/>
        <h1 className="titleLev3 colorPrimaryBlue mb-0 d-block">{this.currentPage?.fullTitle ?? ""}</h1>
      </div>

      <p className="mt-2">Vogliamo garantirti il massimo controllo sulla gestione delle informazioni nel tuo account. Esprimi il tuo assenso al trattamento dei dati personali e scegli liberamente il tuo canale di comunicazione preferito con 6sicuro.</p>
      <Divider></Divider>
      <div className="row mt-4">
        <div className="col-lg-12">
          <Card title="Gestione Account">            
            <h3 className="titleLev4 colorPrimaryBlue cardTitle">Gestione account</h3>
            <div className="cardTitle">
              <div className="row justify-content-between">
                <div className="col-12">
                  <label htmlFor="email" className="d-block">E-mail</label>
                  <InputText
                    id="email"
                    type="text"
                    value={this.state.email ?? ""}
                    onChange={e => this.setState({email: e.target.value})}
                    disabled={true}
                  />
                </div>
                <div className="col-12 mt-4">
                  <span className="aLike" onClick={e => this.setState({changePasswordVisibility: true})}>Cambia la password</span>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-lg-12 mt-5">
          <Card title="Gestione contatti e privacy">
            <h3 className="titleLev4 colorPrimaryBlue cardTitle">Gestione contatti e privacy</h3>


            <PrivacyRow
              className="borderBottom"
              rowid="generale"
              consent={true}
              consentChangeable={false}
              title={<h3 className="titleLev5 colorPrimaryGreen">Condizioni generali di utilizzo</h3>}
              text={
              <p>
                Accetto le condizioni generali del servizio di comparazione offerto da 6sicuro 
                che dichiaro di aver letto e compreso al seguente <a href="https://www.6sicuro.it/condizioni-generali-di-utilizzo/" target="blank">link</a>&nbsp;
                (necessario per usufruire dei servizi offerti dal Sito).
              </p>
            }
            ></PrivacyRow>

            <PrivacyRow
              rowid="generale"
              className="borderBottom"
              consent={true}
              consentMethods={this.state.methods_general}
              consentChangeable={false}
              title={<h3 className="titleLev5 colorPrimaryGreen">Generale</h3>}
              text={
                <p>
                  Dichiaro di aver letto <a href="https://www.6sicuro.it/privacy/" target="blank">l’informativa sulla privacy</a> redatta ai sensi del Regolamento (UE) 2016/679 ("GDPR") e specificatamente gli artt. 2.1.a, 2.1.b, 2.1.c, 2.1.d, 2.1.e, 2.i.f, 2.1.g, 2.1.h, 2.1.i. (obbligatorio)
                </p>
              }
              touchpoints={["email", "phone", "whatsapp", "sms"]}
              actionMethods={(fieldName: string) => {
                let methods : string[];
                if(this.state.methods_general.includes(fieldName))
                  methods = this.state.methods_general.filter((value:string) => { return value !== fieldName });
                else{
                  methods = this.state.methods_general;
                  methods.push(fieldName);
                }
                this.setState({methods_general: methods});
              }}
            ></PrivacyRow>
            {/** 
              labelMore="Clicca qui per visualizzare i dettagli"
              labelLess="Nascondi"
              more={<div><p>• Prendo atto del trattamento dei miei dati personali per l’esecuzione dei servizi richiesti, avendo letto l'informativa sulla privacy di 6sicuro - brand di Howden Assiteca S.p.A. (necessario per usufruire dei servizi offerti dal Sito)</p>
              <p>• Prendo atto, ai sensi dell’art. 6 del Regolamento (UE) 2016/679, della cessione e/o del trasferimento dei miei dati alle compagnie assicurative partner da parte di 6sicuro (brand di Howden Assiteca S.p.A.), quali autonome titolari del trattamento laddove coinvolte nel contratto o nelle trattative richieste e nei limiti di tali finalità. (necessario per usufruire dei servizi offerti dal Sito)</p></div>}


              <div className="cardTitle pt-4">
                <p>Oppure puoi selezionare singolarmente qui di seguito i consensi che vuoi rilasciare:</p>
              </div>
            */}

            <PrivacyRow
              rowid="newsletter"
              consent={this.state.consent_newsletter}
              action={() => {this.setState({consent_newsletter: !this.state.consent_newsletter}) }}
              title={<h3 className="titleLev6">Newsletter</h3>}
              text={
                <p>
                  Acconsento all’invio della newsletter (art. 2.1.e dell’informativa) (facoltativo)
                </p>
              }
            ></PrivacyRow>

            <PrivacyRow
              rowid="marketing"
              consent={this.state.consent_marketing}
              consentMethods={this.state.methods_marketing}
              action={() => {this.setState({consent_marketing: !this.state.consent_marketing}) }}
              actionMethods={(fieldName: string) => {
                let methods : string[];
                if(this.state.methods_marketing.includes(fieldName))
                  methods = this.state.methods_marketing.filter((value:string) => { return value !== fieldName });
                else{
                  methods = this.state.methods_marketing;
                  methods.push(fieldName);
                }
                this.setState({methods_marketing: methods});
              }}
              title={<h3 className="titleLev6">Marketing</h3>}
              text={
                <p>
                  Acconsento al trattamento dei dati per le finalità di comunicazione e commerciali operate direttamente da Howden (art. 2.1.f dell’informativa) (facoltativo)
                </p>
              }
              touchpoints={["email", "phone", "whatsapp", "sms"]}
            ></PrivacyRow>

            <PrivacyRow
              rowid="profilazione"
              consent={this.state.consent_profilazione}
              action={() => {this.setState({consent_profilazione: !this.state.consent_profilazione}) }}
              title={<h3 className="titleLev6">Profilazione</h3>}
              text={
                <p>
                  Acconsento al trattamento dei dati per le attività di profilazione (art. 2.1.g dell’informativa) (facoltativo)
                </p>
              }
            ></PrivacyRow>

            <PrivacyRow
              rowid="cessione"
              consent={this.state.consent_cessione}
              consentMethods={this.state.methods_cessione}
              action={() => {this.setState({consent_cessione: !this.state.consent_cessione}) }}
              actionMethods={(fieldName: string) => {
                let methods : string[];
                if(this.state.methods_cessione.includes(fieldName))
                  methods = this.state.methods_cessione.filter((value:string) => { return value !== fieldName });
                else{
                  methods = this.state.methods_cessione;
                  methods.push(fieldName);
                }
                this.setState({methods_cessione: methods});
              }}
              title={<h3 className="titleLev6">Cessione a terzi</h3>}
              text={
                <div>
                  <p>
                    Acconsento al trattamento dei dati per la le finalità di comunicazione, cessione o concessione in uso di dati a terze parti per finalità proprie di queste ultime, per le seguenti categorie (art. 2.1.i dell’informativa) (facoltativo):
                  </p>
                  <ul>
                    <li>Bancario, finanziario, assicurativo</li>
                    <li>Automotive</li>
                    <li>Beni di largo consumo</li>
                    <li>Telecomunicazioni, telefonia, informatica</li>
                    <li>Servizi alla persona e tempo libero</li>
                  </ul>
                </div>
              }
              touchpoints={["email", "phone", "whatsapp", "sms"]}
            ></PrivacyRow>

            {
              showBtn && <div className="cardTitle cardSalvataggio text-center pt-lg-4">
                <button className="px-5 p-button p-component p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite" onClick={() => {this.saveConsentData()}}>Salva modifiche</button>
              </div>
            }


          </Card>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <p className="small">Vuoi chiudere il tuo account? <Link to={this.closeAccountPage?.url ?? ""} onClick={(e) => {
            User.resetKeepAliveTimer()
            User.setKeepAliveTimer()
          }} className="aLike">Clicca qui</Link></p>
        </div>
      </div>
    </div>
  }
  
}

const AreaPersonaleGestioneAccount = (props: IPageProps) => {

  const pages = useContext(PagesContext);
  return <AreaPersonaleGestioneAccountComponent pages={pages} {...props} />;

};
export default AreaPersonaleGestioneAccount