import { Component, useContext } from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbItemProps } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import User from "../utils/User";
import AreaPersonaleMenu from "./AreaPersonaleMenu";
import PagesContext, { IBreadcrumbsCompiler, IPageDescriptor } from "./HOC/useAreaPersonalePages";
import * as Constants from '../utils/constants';
import UserService from "../utils/UserService";

interface IPageMakerProps{
  page: JSX.Element;
  name: string;
  url: string;
}
interface IPageMakerComponentProps extends IPageMakerProps{
  pages: IPageDescriptor[];
  params: ReturnType<typeof useParams>;
}

export interface IPageProps{
  page: IPageDescriptor;
  user?: User | undefined;
  setResourceListRequest: Function;
}

class AreaPersonalePageMakerComponent extends Component<IPageMakerComponentProps, {
  menu: {},
  wpSiteDomain: string,
  insuranceStatus: string,
  insurancePercentage: string,
  insuranceLink: string,
}>{

  private breadcrumbsParts : string[];
  
  constructor(props: IPageMakerComponentProps){
    super(props);

    if(this.props.url.startsWith('/')){
      this.breadcrumbsParts = this.props.url.slice(1).split('/');
    }
    else{
      this.breadcrumbsParts = this.props.url.split('/');
    }

    this.state = {
      menu: {},
      wpSiteDomain: "",
      insuranceStatus: "",
      insurancePercentage: "",
      insuranceLink: ""
    }
  }

  private composedURL(index: number){
    return this.breadcrumbsParts.slice(0, index + 1).reduce((prev, current) => `${prev}/${current}`, "");
  }

  private getBreadcrumbsProps(){
    return this.breadcrumbsParts
      .map((urlPart: string, index: number, self: string[]) => {
        let foundPage : IPageDescriptor | undefined = this.props.pages.find((p: any) => p.url === this.composedURL(index));
        if(foundPage !== undefined){
          let url: string = foundPage.url;
          let title: string | IBreadcrumbsCompiler = foundPage.breadcrumbTitle;

          if(url.includes(":")){
            // l'url ha parametri
            url = foundPage.url;
            Object.entries(this.props.params).forEach(([key, value]) => {
              url = url.replace(`:${key}`, value as string);
            })
          }

          if(typeof title !== 'string'){
            title = (title as IBreadcrumbsCompiler)(this.props.params);
          }

          return {
            props: {
              linkAs: Link,
              linkProps: {to: url}
            },
            title: title
          }
        }
        else{
          return undefined;
        }
       
      })
      .filter((p: {props: BreadcrumbItemProps, title: string} | undefined) => p !== undefined)
      
  }

  componentDidMount(){
    this.setMenu();
    this.setInsuranceCheckupStatus();
  }

  private setMenu(){
    var domain = document.location.ancestorOrigins[0];
    var siteDomain: string = "https://www.6sicuro.it";
    /*
    if(domain == "https://www.6sicuro.it")
      siteDomain = "https://www.6sicuro.it";
    else
      siteDomain = "https://assicurativo.6sicuro.it";
    */
    var url = siteDomain+"/wp-content/themes/6sicuro2023/menu-json.php";    
    fetch(url, {
        /*headers: new Headers({
            'Authorization': 'Bearer '+jwt,
            'Content-Type': 'application/json'
        })*/
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        this.setState({
          menu: data,
          wpSiteDomain: siteDomain
        })
    });
  }

  private setInsuranceCheckupStatus(){
    UserService.call(      
      Constants.WEB_SITE_API_SERVER_URL("/checkup-survey/status"),
      {method: "GET"},
      (response: any) => {
        if(response.data.stage !== undefined){
          this.setState({
            insuranceStatus: response.data.stage,
            insurancePercentage: response.data.completion_percentage ?? "0"
          });
        }
      },
      (error: any) => {
        return error;
      }
    )    
  }

  private goToCheckup(){
    UserService.call(      
      Constants.WEB_SITE_API_SERVER_URL("/checkup-survey/make-url"),
      {method: "POST"},
      (response: any) => {
        if(response.url !== undefined){
          window.open(response.url, "_blank");
        }
      },
      (error: any) => {
        return error;
      }
    ) 
  }

  render(){
    const {page, name, url} = this.props;
    var menu : any = this.state.menu;
    var keysMenu = Object.keys(menu); 

    return <div className={"pr-body areaPersonalePage mb-0 pt-lg-5"}>
      <div className="container-fluid">

        {
          keysMenu.length > 0 &&
            <div className="row justify-content-center">
              <div className="col-lg-10 spacer">
                <div className="crossBarContainer">                  
                  
                  
                  <div className="crossBarElement">
                    <div className="mainElement">
                      <img src={`${this.state.wpSiteDomain}/wp-content/themes/6sicuro2023/assets/img/veicoli.svg`}></img>
                      <span>Assicurazione veicoli</span>
                    </div>
                    <div className="subMenu">
                      {
                        menu["veicoli"].map((elem : any) => <Link key={"menu_veicoli_" + elem.label} to={{ pathname: elem?.url }} target="_blank" className="link">
                          <img src={`${this.state.wpSiteDomain}/wp-content/themes/6sicuro2023/assets/img/svg/header/${ elem?.icon}`}></img>
                          { elem?.label }
                        </Link>)
                      }
                    </div>
                  </div>
                  <div className="crossBarElement">
                    <div className="mainElement">
                      <img src={`${this.state.wpSiteDomain}/wp-content/themes/6sicuro2023/assets/img/persone.svg`}></img>
                      <span>Assicurazione persone</span>
                    </div>
                    <div className="subMenu">
                      {
                        menu["persone"].map((elem : any) => <Link key={"menu_veicoli_" + elem.label} to={{ pathname: elem?.url }} target="_blank" className="link">
                          <img src={`${this.state.wpSiteDomain}/wp-content/themes/6sicuro2023/assets/img/svg/header/${ elem?.icon}`}></img>
                          { elem?.label }
                        </Link>)
                      }
                    </div>
                  </div>
                  <div className="crossBarElement noBorder">
                    <div className="mainElement">
                      <img src={`${this.state.wpSiteDomain}/wp-content/themes/6sicuro2023/assets/img/beni.svg`}></img>
                      <span>Assicurazione beni</span>
                    </div>
                    <div className="subMenu">
                      {
                        menu["beni"].map((elem : any) => <Link key={"menu_veicoli_" + elem.label} to={{ pathname: elem?.url }} target="_blank" className="link">
                          <img src={`${this.state.wpSiteDomain}/wp-content/themes/6sicuro2023/assets/img/svg/header/${ elem?.icon}`}></img>
                          { elem?.label }
                        </Link>)
                      }
                    </div>
                  </div>

                  {
                    (
                      this.state.insuranceStatus == "None" ||
                      this.state.insuranceStatus == "Created" ||
                      this.state.insuranceStatus == "Working" ||
                      this.state.insuranceStatus == "Completed"
                    ) && /*false &&*/
                    <div className="insuranceCheckup">
                      <div className="inner">
                        <p className="mb-1">Servizio di Insurance Checkup</p>
                        {
                          (
                            this.state.insuranceStatus == "None" ||
                            this.state.insuranceStatus == "Created" ||
                            (this.state.insuranceStatus == "Working" && this.state.insurancePercentage == "0")
                          ) &&
                            <p className="mb-0"><small>Scegli la giusta copertura. <span className="text-underline cursor-pointer" onClick={() => {this.goToCheckup()}}>Compila il questionario</span></small></p>
                        }

                        {                        
                          (this.state.insuranceStatus == "Working" && this.state.insurancePercentage != "0") &&
                          <p className="mb-0"><small>Hai compilato il {this.state.insurancePercentage}% del questionario. <span className="text-underline cursor-pointer" onClick={() => {this.goToCheckup()}}>Clicca qui.</span></small></p>
                        }

                        {                        
                          (this.state.insuranceStatus == "Completed") &&
                          <p className="mb-0"><small>Questionario completato, sarai ricontattato.</small></p>
                        }
                      </div>
                    </div>
                  }

                  


                </div>
              </div>
            </div>
        }

        
  
        { name !== "home" && 
          <div className="row justify-content-center">
            <div className="col-lg-11">
            
              <Breadcrumb>
                {
                  this.getBreadcrumbsProps()
                    .map((p: any, key: number) => <BreadcrumbItem key={key} {...p.props}>{p.title}</BreadcrumbItem>)
                }
              </Breadcrumb>
            </div>
          </div>
        }
  
        <div className="row justify-content-center">
          <div className="col-lg-11 col-xxxl-9">
  
            <div className="row justify-content-between">
  
              <div className="col-lg-3">
                <AreaPersonaleMenu url={url}/>
              </div>
  
              <div className="col-lg-9 col-xxl-8">
                {page}
              </div>
              
            </div>
  
          </div>
        </div>
      </div>

      {
        (
          this.state.insuranceStatus == "None" ||
          this.state.insuranceStatus == "Created" ||
          this.state.insuranceStatus == "Working" ||
          this.state.insuranceStatus == "Completed"
        ) &&

        <div className="bannerCheckup mt-5">
          <span className="titleLev1 d-block mb-3">Servizio di insurance check-up</span>
          <p>Scopri la giusta copertura, con una consulenza gratuita e personalizzata su ogni tua esigenza. E' semplicissimo!</p>
          <span className="customBtn bgColorGreen colorWhite hoverBgColorPrimaryGreen hoverColorWhite" onClick={() => {this.goToCheckup()}}>Compila il questionario</span>
        </div>
      }
  
      <div className="container-fluid">
        {
          (name === "home")  && 
          <div>
            <div className="row justify-content-center mt-4">
              <div className="col-lg-6 mt-4" style={{textAlign: "center"}}>
                <h3 className="colorPrimaryBlue titleLev3">Richiedi un preventivo online. È facile e veloce!</h3>
                <p>Cerchi un <strong>preventivo assicurazione auto o moto</strong>? Confrontiamo gratuitamente le tariffe delle compagnie assicurative e troviamo la <strong>soluzione migliore per te</strong>.</p>
              </div>
            </div>
  
            <div className="row justify-content-center">
              <div className="col-lg-10 mt-4">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="customCard">
                      <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/moto.jpg`} alt=""/>
                      <div className="content">
                        <div>
                          <h3 className="titleLev5">Preventivo Moto</h3>
                          <p>Scegli la giusta polizza</p>
                        </div>
  
                        <Link className="customBtn bgColorGreen colorWhite hoverBgColorPrimaryGreen hoverColorYellow" to="/preventivi/assicurazione-moto" style={{boxShadow: "none"}}>
                          Calcola il preventivo
                        </Link>
  
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="customCard">
                    <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/auto.jpg`} alt=""/>
                      <div className="content">
                        <div>
                          <h3 className="titleLev5">Preventivo Auto</h3>
                          <p>Scegli la giusta polizza</p>
                        </div>
  
                        <Link className="customBtn bgColorGreen colorWhite hoverBgColorPrimaryGreen hoverColorYellow" to="/preventivi/assicurazione-auto" style={{boxShadow: "none"}}>
                          Calcola il preventivo
                        </Link>
  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        
  
  
      </div>
    </div>
  }
}

const AreaPersonalePageMaker = (props: IPageMakerProps) => {

  const pages : IPageDescriptor[] = useContext(PagesContext);
  const params: ReturnType<typeof useParams> = useParams();
  return <AreaPersonalePageMakerComponent pages={pages} {...props} params={params}/>
}

export default AreaPersonalePageMaker;