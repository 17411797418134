import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { stepDataReset, userInfoDataSuccess } from "../redux/actions";

interface IAreaPersonaleGoToPreventivatore{
  stepDataReset: (vehicle: string) => void;
  setVehicle: (vehicle: string) => void;
  vehicle: string;
}

const AreaPersonaleGoToPreventivatore = (props: IAreaPersonaleGoToPreventivatore) => {
  
  const {vehicle, stepDataReset, setVehicle} = props;
  const history: ReturnType<typeof useHistory> = useHistory();

  const goToPreventivatore = () => {
    stepDataReset(vehicle);
    setVehicle(vehicle);
    setTimeout(() => history.push(`/preventivi/assicurazione-${vehicle}`), 100);
  }

  return <span
    className="aLike"
    onClick={() => goToPreventivatore()}
    style={{boxShadow: "none"}}
    >
    {`Calcola nuovo preventivo ${vehicle}`}
  </span>;
}
// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    stepDataReset: (vehicle: string) => dispatch(stepDataReset(vehicle)),
    setVehicle:  (vehicle: string) => dispatch(userInfoDataSuccess({currentVehicleSelector: vehicle}))
  }
};

export default connect(null, mapDispatch)(AreaPersonaleGoToPreventivatore);