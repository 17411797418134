import React, { Component, useState } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import {default as PageMaker} from "./customs/AreaPersonalePageMaker";
import {default as APAutoMoto} from "./AreaPersonaleAutoMoto";
import {default as APGestioneAccount} from "./AreaPersonaleGestioneAccount";
import {default as APGestionePersone} from "./AreaPersonaleGestionePersone";
import {default as APGestioneAziende} from "./AreaPersonaleGestioneAziende";
import {AreaPersonalePreventiviProdottiPerson as APPreventiviProdottiPerson, AreaPersonalePreventiviProdottiBusiness as APPreventiviProdottiBusiness} from "./AreaPersonalePreventiviProdotti";
import {AreaPersonaleProdottiPerson as APProdottiPerson, AreaPersonaleProdottiBusiness as APProdottiBusiness} from "./AreaPersonaleProdotti";
import {default as APPreventivi} from "./AreaPersonalePreventivi";
import {default as APChiudiAccount} from "./AreaPersonaleChiudiAccount";
import {default as APHome} from "./AreaPersonaleHome";
import {default as APGoToPreventivatore} from "./AreaPersonaleGoToPreventivatore";
import {default as APRedirectOldMailPreventivo} from "./AreaPersonaleRedirectOldMailPreventivo";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import PrivateRoute from "./customs/PrivateRoute";
import User from "./utils/User";
import { connect } from "react-redux";
import { IVehicle, ICompany } from "./utils/common";
import FetchLists, { IResourceToLoad } from "./customs/FetchLists";
import { ResourceListRequestContext } from "./PreventivatoreHub";
import QuoteInfo, { IQuoteDescriptor } from "./utils/QuoteInfo";

interface AreaPersonaleHubClassProps{
  user?: User | undefined;
  userData: string;
  path: string;
  setResourceListRequest: Function;
}
interface AreaPersonaleHubClassState{
  pages: JSX.Element[];
  user: User;
}

class AreaPersonaleHubComponent extends Component<AreaPersonaleHubClassProps, AreaPersonaleHubClassState>{

  private readonly allowedPages : IPageDescriptor[] = [
    {
      name: "home",
      url: this.props.path,
      fullTitle: "Il mio account",
      breadcrumbTitle: "Area personale",
      component: APHome,
      seo: {},
      inMenu: false
    },
    {
      name: "auto-moto",
      submenu: ["new-preventivo-auto", "new-preventivo-moto"],
      url: `${this.props.path}/auto-moto`,
      fullTitle: "Auto e Moto",
      breadcrumbTitle: "Auto e Moto",
      component: APAutoMoto,
      seo: {},
      icon: 'prev-auto'
    },
    {
      name: "prodotti",
      url: `${this.props.path}/beni/`,
      fullTitle: "Beni e persone",
      breadcrumbTitle: "Beni e persone",
      component: APProdottiPerson,
      seo: {},
      icon: 'icone_prodotti_assicurativi/beni',
      inMenu: true
    },
    {
      name: "preventivi-prodotti",
      url: `${this.props.path}/beni/:quoteType`,
      fullTitle: "Richieste di preventivo",
      breadcrumbTitle: "Preventivi",
      component: APPreventiviProdottiPerson,
      seo: {},
      icon: 'prev-auto',
      inMenu: false
    },
    {
      name: "persone",
      url: `${this.props.path}/gestione-persone`,
      fullTitle: "Gestione persone",
      breadcrumbTitle: "Gestione persone",
      component: APGestionePersone,
      seo: {},
      icon: 'gest-persone'
    },
    {
      name: "account",
      url: `${this.props.path}/gestione-account`,
      fullTitle: "Gestione account e consensi",
      breadcrumbTitle: "Gestione Account",
      component: APGestioneAccount,
      seo: {},
      icon: 'gest-account'
    },
    {
      name: "chiudi-account",
      url: `${this.props.path}/gestione-account/chiudi-account`,
      fullTitle: "Chiudi il tuo account 6sicuro.it",
      breadcrumbTitle: "Chiudi account",
      component: APChiudiAccount,
      seo: {},
      inMenu: false
    },
    {
      name: "preventivi-veicolo",
      url: `${this.props.path}/auto-moto/preventivi/:vehicleId`,
      fullTitle: "Preventivi",
      breadcrumbTitle: (params: any) => {
        if(params?.vehicleId){
          const v: IVehicle | undefined = User.getInstance().getVehicleById(params?.vehicleId);
          return `Preventivi ${User.getVehicleSafeName(v)}`
        }
        else{
          return `Preventivi`
        }
      },
      component: APPreventivi,
      seo: {},
      icon: 'prev-auto',
      inMenu: false
    },
    // redirect vecchio url con parametro in get idVeicolo al nuovo url `${this.props.path}/auto-moto/preventivi/:vehicleId`
    {
      name: "preventivi-veicolo",
      fullTitle: "Preventivi",
      url: `${this.props.path}/auto-moto/preventivi/`,
      breadcrumbTitle: "",
      component: APRedirectOldMailPreventivo as (props: any) => JSX.Element,
      seo: {},
      inMenu: false
    },
    {
      name: "aziende",
      url: `${this.props.path}/aziende`,
      fullTitle: "Gestione aziende",
      breadcrumbTitle: "Gestione aziende",
      component: APGestioneAziende,
      seo: {},
      icon: 'icone_prodotti_assicurativi/beni'
    },
    {
      name: "prodotti",
      url: `${this.props.path}/aziende/:companyId`,
      fullTitle: "Prodotti assicurativi",
      breadcrumbTitle: (params: any) => {
        if(params?.companyId){
          const company: ICompany | undefined = User.getInstance().getCompanyById(params?.companyId);
          return `Preventivi ${company?.name}`
        }
        else{
          return `Preventivi`
        }
      },
      component: APProdottiBusiness,
      seo: {},
      icon: 'prev-auto',
      inMenu: false
    },
    {
      name: "preventivi-prodotti",
      url: `${this.props.path}/aziende/:companyId/:quoteType`,
      fullTitle: "Richieste di preventivo",
      breadcrumbTitle: (params: any) => {
        if(params?.companyId){
          const quoteInfo: IQuoteDescriptor | undefined = QuoteInfo.getQuoteInfoById(params?.quoteType);
          return `${quoteInfo?.name}`
        }
        else{
          return `Preventivi`
        }
      },
      component: APPreventiviProdottiBusiness,
      seo: {},
      icon: 'prev-auto',
      inMenu: false
    },
    {
      name: "new-preventivo-auto",
      fullTitle: "Calcola nuovo preventivo auto",
      url: "",
      directAction: true,
      breadcrumbTitle: "",
      component: APGoToPreventivatore as (props: any) => JSX.Element,
      componentParams: {vehicle: "auto"},
      seo: {}
    },
    {
      name: "new-preventivo-moto",
      fullTitle: "Calcola nuovo preventivo moto",
      url: "",
      directAction: true,
      breadcrumbTitle: "",
      component: APGoToPreventivatore as (props: any) => JSX.Element,
      componentParams: {vehicle: "moto"},
      seo: {}
    },
  ]
  
  constructor(props: AreaPersonaleHubClassProps){
    super(props);

    this.state = {
      pages: [],
      user: User.getInstance()
    };

  }

  componentDidMount(): void {
    let pages = this.allowedPages
      .filter((page: IPageDescriptor) => page?.directAction !== undefined ? !page.directAction : true)
      .map((page: IPageDescriptor) =>
        React.createElement(PageMaker, {
          page: React.createElement(page.component, {page: page, user: this.state.user, setResourceListRequest: this.props.setResourceListRequest}),
          name: page.name,
          url: page.url
        })
      )

    this.setState({
      pages: pages
    })
  }
  
  componentWillUnmount(): void {
    let abortController = new AbortController();  
    abortController.abort()
  }

  static getDerivedStateFromProps(newProps: AreaPersonaleHubClassProps, prevState: AreaPersonaleHubClassState){
    let nextState: any = {};
    if(newProps.userData !== JSON.stringify(prevState.user)){
      nextState.user = User.getInstance()
    }

    return nextState;
  }

  render(){
    return <Switch>
      <PagesContext.Provider value={this.allowedPages}>
      {
        this.state.pages
        .map((page: JSX.Element, index: number) =>
          <PrivateRoute
            key={index}
            path={this.allowedPages[index].url}
            page={page}
          />
        )
      }
      </PagesContext.Provider>
    </Switch>
  }

}


const mapState = (state: any) => {
  return {
    userData: state.userInfoData.user?.userData ?? ""
  };
}

const AreaPersonaleHub = (props: any) => {
  const { path, url} = useRouteMatch();
  const [resourceListRequest, setResourceListRequest] : [resourceListRequest: IResourceToLoad, setResourceListRequest: Function] = useState({});

  return <ResourceListRequestContext.Provider value={resourceListRequest}>
    <FetchLists>
      <AreaPersonaleHubComponent {...props} path={path} url={url} setResourceListRequest={setResourceListRequest} />
    </FetchLists>
  </ResourceListRequestContext.Provider>
}

export default connect(mapState)(AreaPersonaleHub);
