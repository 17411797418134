import { Card } from "primereact/card";
import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import User from "./utils/User";
import '../scss/area-personale-home.scss';
import * as Constants from './utils/constants';

const AreaPersonaleHome = (props: IPageProps) => {

  const {page} = props;
  const pages : IPageDescriptor[] = useContext(PagesContext);
  const history: ReturnType<typeof useHistory> = useHistory();
  
  return <div className="mainBody">
    <h1 className="titleLev2 colorPrimaryBlue">{pages.find((p: IPageDescriptor) => p.name === page?.name)?.fullTitle ?? ""}</h1>

    <div className="row mt-4">
      <div className="col-lg-6 mb-3 mb-lg-4">
        <div className="cardHomeLink">
          <Link to={pages.find(p => p.name === "auto-moto")?.url ?? "/"}>
            <div className="d-flex align-items-center">
              <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/prev-auto.svg`} alt=""/>
              <h3 className="colorPrimaryBlue titleLev5 mb-0">{pages.find(p => p.name === "auto-moto")?.fullTitle ?? "/"}</h3>
            </div>

            <p>Visualizza comodamente tutte le richieste tutte le richieste di preventivo auto e moto del tuo account. Modifica i dati dei veicoli in pochi semplici clic.</p>
          </Link>
        </div>
      </div>
      <div className="col-lg-6 mb-3 mb-lg-4">
        <div className="cardHomeLink">
          <Link to={pages.find(p => p.name === "prodotti")?.url ?? "/"}>
            <div className="d-flex align-items-center">
              <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/icone_prodotti_assicurativi/beni.svg`} alt=""/>
              <h3 className="colorPrimaryBlue titleLev5 mb-0">{pages.find(p => p.name === "prodotti")?.fullTitle ?? "/"}</h3>
            </div>

            <p>Uno spazio per visualizzare e gestire le richieste di preventivo dedicate alla tua vita quotidiana e ai tuoi beni più preziosi.</p>
          </Link>
        </div>
      </div>
      <div className="col-lg-6 mb-3 mb-lg-4">
        <div className="cardHomeLink">
          <Link to={pages.find(p => p.name === "persone")?.url ?? "/"}>
            <div className="d-flex align-items-center">
              <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/gest-persone.svg`} alt=""/>
              <h3 className="colorPrimaryBlue titleLev5 mb-0">{pages.find(p => p.name === "persone")?.fullTitle ?? "/"}</h3>
            </div>            
            <p>Visualizza i dati del titolare dell’account oppure gestisci o cancella i dati delle persone con cui vuoi condividere l’area privata.</p>
          </Link>
        </div>
      </div>
      <div className="col-lg-6 mb-3 mb-lg-4">
        <div className="cardHomeLink">
          <Link to={pages.find(p => p.name === "account")?.url ?? "/"}>
            <div className="d-flex align-items-center">
              <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/gest-account.svg`} alt=""/>
              <h3 className="colorPrimaryBlue titleLev5 mb-0">{pages.find(p => p.name === "account")?.fullTitle ?? "/"}</h3>
            </div>
            <p>Modifica le credenziali e le impostazioni di sicurezza del tuo account online, gestisci i dati personali o cancella il tuo account.</p>
          </Link>
        </div>
      </div>
      <div className="col-lg-6 mb-3 mb-lg-4">
        <div className="cardHomeLink">
          <Link to={pages.find(p => p.name === "aziende")?.url ?? "/"}>
            <div className="d-flex align-items-center">
              <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/icone_prodotti_assicurativi/beni.svg`} alt=""/>
              <h3 className="colorPrimaryBlue titleLev5 mb-0">{pages.find(p => p.name === "aziende")?.fullTitle ?? "/"}</h3>
            </div>

            <p>Gestisci un’impresa? Aggiungi o modifica le informazioni aziendali e visualizza i dati relativi alla tua preventivazione assicurativa.</p>
          </Link>
        </div>
      </div>
      <div className="col-lg-6 mb-3 mb-lg-4">
        <div className="cardHomeLink assistenza">     
          <div>
            <div className="d-flex align-items-center">
              <i className="pi pi-phone"></i>
              <h3 className="colorWhite titleLev5 mb-0">Hai bisogno di assistenza?</h3>
            </div>
            <p className="colorWhite">Un nostro consulente assicurativo ti contatterà nel più breve tempo possibile.</p>
          </div>
          <Link className="d-inline-block" to={{ pathname: "https://www.6sicuro.it/nostri-contatti/" }} target="_blank">
            <span className="customBtn d-block bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite">
              Richiedi una consulenza
            </span>
          </Link>
        </div>
      </div>
    </div>

    <div className="row mt-4">
      <div className="col-12">
        <p className="small">Esci dall'account <span className="aLike" onClick={e => {
            User.logout();
            history.push("/");
          }
        }
        >Log out</span></p>
      </div>
    </div>
  </div>

}

export default AreaPersonaleHome;