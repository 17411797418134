import { Component } from "react";
import { useParams } from "react-router-dom";
import UserService, { AccountAlreadyRegisteredException, GenericException, TokenExpiredException, UnauthorizedException } from "./utils/UserService";
import * as Constants from './utils/constants';
import { Spinner } from "react-bootstrap";
import TokenExpiredCard from "./customs/TokenExpiredCard";
import UnauthorizedCard from "./customs/UnauthorizedCard";
import ResetPasswordConfirmationCard from "./customs/ResetPasswordConfirmationCard";
import GenericErrorCard from "./customs/GenericErrorCard";
import UndefinedErrorCard from "./customs/UndefinedErrorCard";

enum ResponseCode{
    SUCCESS = "SUCCESS",
    TOKEN_EXPIRED = "TOKEN_EXPIRED",
    ALREADY_ACTIVATED = "ALREADY_ACTIVATED",
    UNAUTHORIZED = "UNAUTHORIZED",
    GENERIC_ERROR = "GENERIC_ERROR"
}

class AccountActivationPageComponent extends Component<{
    params: any
}, {
    email: string,
    token: string,
    isLoading: boolean,
    headerMessage: string,
    paragraphMessage: string,
    linkUrl: string,
    linkMessage: string,
    isTokenExpired: boolean,
    responseCode: ResponseCode | undefined
}> {

    constructor(props: any) {
        super(props);

        const query = new URLSearchParams(
            window.location.search.replaceAll("%0D", "").replaceAll("%0A", "") //
                .replaceAll("%0d", "").replaceAll("%0a", "")
                .replaceAll("%09", "").replaceAll("%20", "")
        );

        this.state = {
            token: query.get("t") ?? "",
            email: query.get("e") ?? "",
            isLoading: true,
            headerMessage: "",
            paragraphMessage: "",
            linkUrl: "",
            linkMessage: "",
            isTokenExpired: false,
            responseCode: undefined
        }
    }

    componentDidMount() {        
        this.checkValidUser();
    }

    render() {
        return <div className="pr-body">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-5 col-xl-6 col-lg-8 col-10">
                        {this.state.isLoading
                            ? <div className="p-fluid p-5">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <Spinner animation="border"/>
                                    </div>
                                </div>
                            </div>
                            : <div>
                                {(this.state.responseCode === ResponseCode.SUCCESS) ? <ResetPasswordConfirmationCard/> : ""}
                                {(this.state.responseCode === ResponseCode.TOKEN_EXPIRED) ? <TokenExpiredCard/> : ""}
                                {(this.state.responseCode === ResponseCode.UNAUTHORIZED) ? <UnauthorizedCard email = {this.state.email}></UnauthorizedCard> : ""}
                                {(this.state.responseCode === ResponseCode.ALREADY_ACTIVATED) ? <UndefinedErrorCard/> : ""}                                
                                {(this.state.responseCode === ResponseCode.GENERIC_ERROR) ? <GenericErrorCard/> : ""}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    private checkValidUser() {
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.append('Authorization', `Bearer ${this.state.token}`);
        UserService.call(
            Constants.WEB_SITE_API_SERVER_URL('/users/activate'),
            {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify({
                    email: this.state.email
                })
            },
            (response: any) => {
                if(response?.success === false){
                    // Token expired
                    if (response.error_code === "TOKEN_EXPIRED") {
                        throw new TokenExpiredException("Il link di attivazione della tua area personale non è più attivo. Riceverai una nuova email di attivazione del tuo account.");
                    }
                    
                    // Token o email not valid
                    if (response.error_code === "UNAUTHORIZED") {
                        throw new UnauthorizedException("Impossibile completare la richiesta di attivazione dell'account");
                    }
                    
                    // Account già attivato
                    if (response.error_code === "ALREADY_ACTIVATED") {
                        throw new AccountAlreadyRegisteredException("Impossibile completare la richiesta di attivazione dell'account");
                    }

                    // VALIDATION_ERROR (email sintatticamente non corretta)
                    // GENERIC
                    throw new GenericException("Impossibile completare la richiesta di attivazione dell'account");
                }

                this.setState({responseCode: ResponseCode.SUCCESS, isLoading: false})
            },
            (err: any) => {
                this.setState({
                    responseCode: ResponseCode.GENERIC_ERROR,                    
                    isLoading: false
                })

                if (err instanceof TokenExpiredException) {
                    // Visualizzo messaggio erore e il servere effettua l'invio automatico della nuova mail
                    this.setState({
                        responseCode: ResponseCode.TOKEN_EXPIRED,
                    })
                }
                
                if (err instanceof UnauthorizedException) {
                    // Visualizzo messaggio + visualizzo Button per richiesta nuova mail + Contatta reparto tecnico
                    this.setState({
                        responseCode: ResponseCode.UNAUTHORIZED                    
                    })
                }
                
                if (err instanceof AccountAlreadyRegisteredException) {
                    // Visualizzo messaggio + visualizzo Button per richiesta nuova mail + Contatta reparto tecnico
                    this.setState({
                        responseCode: ResponseCode.ALREADY_ACTIVATED
                    })
                }
            }
        );
    }
}

const AccountActivationPage = (props: any) => {
    return <AccountActivationPageComponent {...props} params={useParams()}/>
}

export default AccountActivationPage;
