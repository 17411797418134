import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { Slider } from "primereact/slider";
import { Component, createRef, useContext } from "react";
import { connect } from "react-redux";
import { step2DataFailure, step2DataReset, step2DataSuccess, userInfoDataSuccess } from "../redux/actions";
import RadioButtonList from "./customs/RadioButtonList";
import { VehicleCard } from "./customs/VehicleCard";
import { IFieldDescriptor, IVehicle, Step1Data, Step2Data } from "./utils/common";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import { fixDirtyBackendErrorString, formatDateAdform, isNullOrUndefined, offsetFirstErrorField, removeFocusClass, sortJson } from "./utils/methods";
import User, { IValidationResponse, sortVehicleFunction } from "./utils/User";
import { VehicleFieldBuilder } from "./utils/VehicleFieldBuilder";
import * as Constants from "./utils/constants";
import { StepDataDualState } from "../redux/reducers";
import VehicleListItemLabel from "./customs/VehicleListItemLabel";
import EmptyListItemLabel from "./customs/EmptyListItemLabel";
import StepLoaderSpinner from "./StepLoaderSpinner";
import { useHistory } from "react-router-dom";
import { compose } from "ramda";
import withEventManager from "./customs/HOC/withEventManager";
import { IWithEventManager } from "./customs/HOC/EventManager";
import { DataList, IDataList } from "./customs/dataListContext";

interface IStep2Props extends IWithEventManager, IDataList{
  user: User;
  step1Data: StepDataDualState;
  step2Data: StepDataDualState;
  userInfoDataSuccess: Function;
  step2DataSuccess: Function;
  step2DataFailure: Function;
  step2DataReset: Function;
  fromMailSereno: boolean;
  vehicle: string;
  history: ReturnType<typeof useHistory>;
  setResourceListRequest: Function;
}

interface IStep2State {
  listOfVehicles: IVehicle[];
  plateLoading: boolean;
  loading: boolean; // flag di stato per il caricamento, diventa false quanto tutte le info asincrone sono state caricate
  errors: string[];
  errorTexts: IFeedbackText[];
  submitting: boolean;  // flag di stato per lo spinner del pulsante continua
}

class Step2Component extends Component<IStep2Props, IStep2State>{

  private vehicle: any;

  private vehicleCardRef: any;

  private readonly vehicleFields : any;
  private abortController: AbortController = new AbortController();
  private _isMounted = false;

  constructor(props: any) {
    super(props);
    this.vehicle = this.props.vehicle;

    this.vehicleFields = {
      step: {},
      vehicle: {
        auto: VehicleFieldBuilder("auto", false),
        moto: VehicleFieldBuilder("moto", false),
        none: VehicleFieldBuilder("", false)
      }
    };

    this.state = {
      listOfVehicles: [],
      plateLoading: false,
      errors: [],
      errorTexts: [],
      loading: true,
      submitting: false,
      // knowLicensePlate: this.checkIfKnowLicensePlate(vehicle),
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.monthIndexToName = this.monthIndexToName.bind(this);
    this.trakSendLoaded = this.trakSendLoaded.bind(this);
    this.submitToNode = this.submitToNode.bind(this);
    this.updateStateVehicleSave = this.updateStateVehicleSave.bind(this);
    this.handleVehicleSaveError = this.handleVehicleSaveError.bind(this);

    this.vehicleCardRef = createRef();

    this._isMounted = true;

  }

  private checkIfKnowLicensePlate(v: IVehicle | undefined): boolean{
    if((v?.vehicleLicensePlateNumber ?? "") !== ""){
      return true;
    }
    else{
      return false;
    }
  }

  sortVehicle: sortVehicleFunction = (v1, v2) => {
    let v1Name: string = v1.vehicleName?.toLowerCase() ?? "";
    let v2Name: string = v2.vehicleName?.toLowerCase() ?? "";
    return v1Name > v2Name
      ? 1
      : v1Name < v2Name
        ? -1
        : 0
  }

  enhanceListOfVehicleWithError(vehicle: IVehicle[]): Promise<IVehicle[]> {

    let fieldsToValidate : any = JSON.parse(JSON.stringify(this.vehicleFields.vehicle[this.vehicle ?? "none"]));
    delete fieldsToValidate?.vehicleName;

    let vehiclesPromises : Promise<IVehicle>[] = vehicle
        .map(async (v_validated) => {
          return User.validateVehicle(
            {...v_validated},
            Object.entries(fieldsToValidate).map(([, value]) => value) as IFieldDescriptor[])
              .then(result => {
                const {error_fields: errors, error_messages: errorTexts} = result;
                const refinedItems = {...v_validated, errors, errorTexts};
                return refinedItems;
              })
        })
  
    return Promise.allSettled(vehiclesPromises)
      .then((results) => {
        let listOfVehicles: IVehicle[] = [];
        results.forEach((fieldResponse) => {
          if (fieldResponse.status === "fulfilled") {
            listOfVehicles.push(fieldResponse.value);
          }
        })
        return listOfVehicles;
      })
  }

  loadResourceFromUser(){

    const listOfVehicles: IVehicle[] = this.props.user?.getVehicles((v: IVehicle) => v.vehicleType === this.vehicle, this.sortVehicle) ?? [];

    this.enhanceListOfVehicleWithError(listOfVehicles)
      .then((enhancedVehicles: IVehicle[]) => {
        this.setState({listOfVehicles: enhancedVehicles}, this.updateLoadingState);
      });
  }

  componentWillUnmount(): void {
    console.log("step2 unmount");
    this._isMounted = false;
    this.abortController.abort();
    this.setState = (state, callback) =>{
      return;
    };
  }

  componentDidMount() {
    console.log("Step2Logged mounted");
    this._isMounted = true;

    let resourseRequestMask: any = {};
    if(!(this.props?.parkingLocationList?.length ?? false)){
      resourseRequestMask.parkingLocationList = true;
    }
    
    if(!(this.props?.vehicleUseList?.length ?? false)){
      resourseRequestMask.vehicleUseList = true;
    }

    if(JSON.stringify(resourseRequestMask) != "{}"){
      this.props.setResourceListRequest(resourseRequestMask)
    }

    this.loadResourceFromUser()

    if(!(window as any)?.Cookiebot?.hasResponse){
      window.addEventListener("CookiebotOnAccept", this.trakSendLoaded)
      window.addEventListener("CookiebotOnDecline", this.trakSendLoaded)
    }
    else{
      this.trakSendLoaded();
    }
  }

  componentDidUpdate(prevProps: IStep2Props, prevState: IStep2State){
    !prevProps.user.isEqual(this.props.user) && this.loadResourceFromUser()
  }

  updateLoadingState() {
    let prevState: boolean = this.state.loading;
    let newState: boolean = !(this.state.listOfVehicles !== undefined);

    if (prevState !== newState) {
      this.setState({ loading: newState });
    }
  }

  updateStateVehicleSave(vehicle: IVehicle){    
    User.validateVehicle(vehicle, Object.entries(this.vehicleFields.vehicle[this.vehicle ?? "none"]).map(([, value]) => value) as IFieldDescriptor[])
      .then(result => {
        // this.props.user.upsert("vehicle", vehicle);

        const {error_fields: errors, error_messages: errorTexts} = result;

        let listOfVehicles: IVehicle[] = [...this.state.listOfVehicles.filter((v: IVehicle) => v.id !== vehicle.id), {...vehicle, errors, errorTexts} as IVehicle];

        listOfVehicles.sort(this.sortVehicle);
        this.setState({
          listOfVehicles: listOfVehicles,
          errors: errors,
          errorTexts: errorTexts,
        })
        this.saveReduxState(this.IVehicleToStep2Data(vehicle))
        this.props.userInfoDataSuccess({userData: JSON.stringify(User.getInstance())})
      })
  }

  handleVehicleSaveError(sentVehicle: IVehicle, response: any){
    this.props.step2DataReset(this.vehicle)
  }

  render() {
    
    if(this._isMounted){
      const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
      // const progress = getProgressValue();

      return <>
        {!this.state.loading
          ? <div id="step-2-form">
              {/* <div className="progress-bar-steps">
                <ProgressBar value={progress}></ProgressBar>
              </div> */}
      
              <VehicleCard
                visible={this.state.listOfVehicles.length === 0}
                user={this.props.user}
                ref={this.vehicleCardRef}
                requiredFields={this.vehicleFields.vehicle}
                hideFields={["vehicleType"]}            
                editMode={true}
                showControl={false}
                successCallback={this.updateStateVehicleSave}
                errorCallback={this.handleVehicleSaveError}
                vehicle={{vehicleType: this.vehicle}}
                header={<h3 className="colorPrimaryGreen titleLev4">Inserisci una nuova {this.vehicle === "moto" ? "moto " : "auto"}</h3>}
                setResourceListRequest={this.props.setResourceListRequest}
              />
      
              <Card className="pr-form">
                <div className="p-fluid p-4 p-lg-5">
                  <h1 className="titleLev4 colorPrimaryBlue mb-4">Dati veicolo</h1>
                  <div className="row">
                    
                    <FeedbackBox items={this.state.errorTexts}/>
      
                    <div className="col-12">
                      <label className="mb-2">Scegli il veicolo dal tuo garage o inseriscine uno</label>
                      <div className="mb-5">
                        <RadioButtonList
                          labelType={{component: VehicleListItemLabel}}
                          name="vehicle"
                          items={this.state.listOfVehicles}
                          itemsAction={{
                            label: "Modifica",
                            attributes: {
                              className: "aLike",
                              onClick: (e: Event) => {
      
                                const v: IVehicle = this.state.listOfVehicles.find((v : IVehicle) => v.id === step2Data.vehicleId) as IVehicle;
                                const {errors, errorTexts} = v as any
      
                                this.vehicleCardRef?.current?.show({
                                  vehicle: v,
                                  header: <h3 className="colorPrimaryGreen titleLev4">Dettaglio {this.vehicle === "moto" ? "moto " : "auto"} {this.props.user?.getVehicleById(step2Data.vehicleId)?.vehicleLicensePlateNumber }</h3>,
                                  errors: errors,
                                  errorTexts: errorTexts,
                                  hidefields: [],
                                  showControl: false,
                                  editMode: true
                                });
                              }
                            },
                          }}
                          value={step2Data.vehicleId}
                          valueField="id"
                          emptyItem={{
                            label: "Inserisci un nuovo veicolo",
                            component: EmptyListItemLabel,
                            onClick: (e: Event) => {
                              this.vehicleCardRef?.current?.show({
                                header: <h3 className="colorPrimaryGreen titleLev4">{this.vehicle ? `Aggiungi nuova ${this.vehicle}` : "Inserisci un nuovo veicolo"}</h3>,
                                vehicle: {vehicleType: this.vehicle},
                                errors: [],
                                errorTexts: [],
                                showControl: false,
                                editMode: true
                              });
                            }
                          }}
                          handleChange={(selected : any) => {this.onChangeHandler(selected, "vehicleSelector")}}
                        />                  
                        <ContextFeedback
                          show={this.state.errors.includes("vehicleSelector")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "vehicleSelector") ??
                            {msg: "Seleziona un veicolo", severity: "error"}
                          }
                          setParentClassName={false}
                        />
                      </div>
                    </div>
      
                    {this.isVisible("auto") && (
                      <div className="col-12 mb-4">
                        <label>Quante auto hai nel nucleo familiare?</label>
                        <SelectButton
                          value={step2Data.moreThanOneVehicle}
                          options={[
                            { label: "1 auto", value: false },
                            { label: "2 o più auto", value: true },
                          ]}
                          onChange={(selected) =>
                            this.onChangeHandler(selected.value, "moreThanOneVehicle")
                          }
                          className="regularBtn"
                          unselectable={false}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("moreThanOneVehicle")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "moreThanOneVehicle") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                    )}
      
                    <div className="col-12 mb-4">
                      <label>
                        KM percorsi in un anno:{" "}
                        <span className="color-text">
                          {step2Data.kilometersPerYear
                            ? this.formatKmPerYear(step2Data.kilometersPerYear)
                            : 0}{" "}
                          km
                        </span>
                      </label>
                      <Slider
                        className="my-3"
                        value={step2Data.kilometersPerYear}
                        min={5000}
                        max={100000}
                        step={5000}
                        onChange={(selected) =>
                          this.onChangeHandler(selected.value, "kilometersPerYear")
                        }
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("kilometersPerYear")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "kilometersPerYear") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                      <div className="row">
                        <div className="col-6">
                          <div className="text-start slider-value">&lt; 5.000</div>
                        </div>
                        <div className="col-6">
                          <div className="text-end slider-value">&gt; 100.000</div>
                        </div>
                      </div>
                    </div>
      
                    {this.isVisible("auto") && (
                      <div className="col-12 mb-4">
                        <label>Che utilizzo fai dell'auto?</label>
                        <Dropdown
                          value={step2Data.vehicleUse}
                          options={this.props.vehicleUseList}
                          onChange={(selected) =>
                            this.onChangeHandler(selected.value, "vehicleUse")
                          }
                          onHide={() => removeFocusClass()}
                          optionValue="value"
                          optionLabel="label"
                          placeholder="Seleziona"
                          emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("vehicleUse")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "vehicleUse") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                    )}
      
                    <div className="col-12">
                      <label>
                        Solitamente dove parcheggi il veicolo durante la notte?
                      </label>
                      <Dropdown
                        value={step2Data.vehicleParkingLocation}
                        options={this.props.parkingLocationList}
                        onChange={(selected) =>
                          this.onChangeHandler(selected.value, "vehicleParkingLocation")
                        }
                        onHide={() => removeFocusClass()}
                        optionValue="value"
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("vehicleParkingLocation")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "vehicleParkingLocation") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  </div>
                </div>
      
                <div className="step-btn-container p-fluid p-4 p-lg-5">
                  <div className="row">
                    <div className="col-6 button-back">
                      <Button
                        className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                        type="button"
                        label="Indietro"
                        onClick={() => {
                          this.props.userInfoDataSuccess({
                            direction: "backwards",
                            nextStep: 2
                          });
                          this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle));
                        }}
                      />
                    </div>
                    <div className="col-6 button-next">
                      <Button
                        className="customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                        type="button"
                        label="Continua"
                        onClick={() => this.handleFormSubmit() }
                        loading={this.state.submitting}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          : <StepLoaderSpinner title="Caricamento"/>
        }
      </>
    }
  }

  // ------------------------------------------------------------------------------------------------------------------------------

  private getDataToTrack(){
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const vehicleData: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    return {
      prodotto: this.vehicle,
      gender: step1Data.gender,
      dataNascita: formatDateAdform(dateOfBirth),
      titoloStudio: step1Data.degree,
      professione: step1Data.profession,
      dataImmatricolazione: `${
        vehicleData.vehicleRegistrationMonth !== undefined &&
        vehicleData.vehicleRegistrationMonth < 10
          ? `0${vehicleData.vehicleRegistrationMonth}`
          : vehicleData.vehicleRegistrationMonth
      }/${vehicleData.vehicleRegistrationYear}`,
      annoAcquisto: vehicleData.vehicleBuyYear,
      veichleBrand: vehicleData.vehicleBrandLabel,
      veichleModel: vehicleData.vehicleModelLabel,
    }
  }

  /**
   * Trak tag-manager: notifica errore
   */
   private trackSendFailure() {
    this.props.trackEvent?.({
      name: "step2LoggedError",
      data: {
        ...this.getDataToTrack(),
        errors: this.state.errors,
        errorTexts: this.state.errorTexts,
      }
    });
  }

  /**
   * Trak tag-manager: notifica successo
   */
  private trackSendSuccess(callbackFunction: Function) {
    this.props.trackEvent?.({
      name: "step2LoggedSuccess",
      data: this.getDataToTrack(),
      callback: callbackFunction
    });
  }

  /**
   * Track tag-mamager: notifica pagina caricata
   */
  private trakSendLoaded() {
    this.props.trackEvent?.({
      name: "step2LoggedLoaded",
      data: this.getDataToTrack()
    });
  }

  /**
   * Formatta i Km annui
   * @param n il numero da formattare
   * @returns una stringa con dove n  è suddiviso da punti
   */
  private formatKmPerYear(n: any) {
    return (Math.round(n * 100) / 100).toLocaleString();
  }

  /**
   * Know license plate form field visibility check.
   *
   * @returns {boolean} whether the "know license plate" question in the form should be visible
   */
  private isVisibleKnowLicensePlate() {
    
    return !((this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data)?.vehicleOwned ?? true);
  }
  
  /**
   * License plate form field visibility check.
   *
   * @returns {boolean} whether the license plate form field is visible
   */
  private isVisibleLicensePlate() {
    const owned: boolean = (this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data)?.vehicleOwned ?? false;
    const knowLicensePlate: boolean = (this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data)?.knowLicensePlate ?? false;

    return owned || knowLicensePlate;

  }

  /**
   * Check if current field is viewable.
   *
   * @param {string} current_field The current field
   *
   * @returns {boolean} Visibility check
   */
  private isVisible(current_field: string): boolean {
    let is_visible;

    switch (current_field) {
      case "auto":
        is_visible = this.vehicle === "auto";
        break;
      case "moto":
        is_visible = this.vehicle === "moto";
        break;
      case "knowLicensePlate":
        is_visible = this.isVisibleKnowLicensePlate();
        break;
      case "vehicleLicensePlateNumber":
        is_visible = this.isVisibleLicensePlate();
        break;
      default:
        is_visible = false;
    }

    return is_visible;
  }

  private IVehicleToStep2Data(v: IVehicle): Step2Data{

    const converted: Step2Data = {
      vehicleId: v.id,
      vehicleAntitheft: v.vehicleAntitheft,
      vehicleType: v.vehicleType,
      vehicleLicensePlateNumber: v.vehicleLicensePlateNumber,
      vehicleBuyYear: v.vehicleBuyYear,
      vehicleRegistrationMonth: v.vehicleRegistrationMonth,
      vehicleRegistrationYear: v.vehicleRegistrationYear,
      vehicleBrand: v.vehicleBrand,
      vehicleBrandLabel: v.vehicleBrandLabel,
      vehicleModel: v.vehicleModel,
      vehicleModelLabel: v.vehicleModelLabel,
      vehicleFitting: v.vehicleFitting,
      veicleFullDescription: v.veicleFullDescription,
      vehicleOwned: v.vehicleOwned,
      vehicleFuelType: v.vehicleFuelType,
      vehicleEngineDisplacement: v.vehicleEngineDisplacement,
      vehicleTowbarMounted: v.vehicleTowbarMounted,
      vehicleGplMounted: v.vehicleGplMounted,
      knowLicensePlate: this.checkIfKnowLicensePlate(v),
    };

    return converted;
  }

  /**
   * Saves the current internal component state to Redux.
   */
  private saveReduxState(bodyData: Step2Data) {
    this.props.step2DataSuccess(bodyData, this.vehicle);
  }

  private getVehicleEmptyState() {
    return {
      vehicleOwned: undefined,
      vehicleLicensePlateNumber: undefined,
      vehicleRegistrationMonth: undefined,
      vehicleRegistrationYear: undefined,
      vehicleBuyYear: undefined,
      vehicleBrand: undefined,
      vehicleModel: undefined,
      vehicleFitting: undefined,
      vehicleTowbarMounted: undefined,
      vehicleAntitheft: undefined,
      vehicleFuelType: undefined,
      vehicleGplMounted: undefined,
      vehicleEngineDisplacement: undefined,
      veicleFullDescription: undefined,
      infoFrom4Ruote: false,
    };
  }

  private getStateFromVehicle(vehicle: IVehicle) {
    let newState: IVehicle = {...vehicle}

    if (this.isVisible("auto")) {
      newState.vehicleTowbarMounted = vehicle.vehicleTowbarMounted ? true : false;
      newState.vehicleFuelType = vehicle.vehicleFuelType;

      if (vehicle.vehicleFuelType !== "E") {
        newState.vehicleGplMounted = vehicle.vehicleGplMounted ? true : false;
        newState.vehicleEngineDisplacement = vehicle.vehicleEngineDisplacement;
      }
      else{
        newState.vehicleGplMounted = undefined;
        newState.vehicleEngineDisplacement = undefined;
      }
    }
    else{
      newState.vehicleTowbarMounted = undefined;
      newState.vehicleTowbarMounted = undefined;
      newState.vehicleGplMounted = undefined;
      newState.vehicleEngineDisplacement = undefined;
    }

    return newState;
  }

  /**
   * Handles various state change events related to the form's components.
   *
   * @param {any} selected The selected object
   * @param {string} componentName The name of the component to handle
   */
  private async onChangeHandler(selected: any, componentName: string) {

    let dataToDispatch: Step2Data = {};

    switch (componentName) {
      case "vehicleSelector":
        if (selected !== -1) {
          const id : string = selected;
          const vData: IVehicle = this.state.listOfVehicles.find((v) => v.id === id) || {};

          /** 
           * creo una refined list, per poter passare gli eventuali field che non hanno passato la 
           * validazione alla RadioButotnList in modo da segnare l'errore
           * i campi errati verrano usati anche dalla VehicleCard per marcare i campi da riempire
           *  */
          let refinedListOfVehicle : any[] = await User.validateVehicle(
            vData,
            Object.entries(this.vehicleFields.vehicle[this.vehicle ?? "none"]).map(([, value]) => value) as IFieldDescriptor[]
          )
            .then((result: IValidationResponse) => {
              return this.state.listOfVehicles.map((refined_vehicle: any) => {
                if(refined_vehicle.id === id){
                  refined_vehicle = {...refined_vehicle, errors: result.error_fields, errorTexts: result.error_messages};
                }
                return refined_vehicle;
              });
            })

          if (vData) {
            dataToDispatch = {
              ...this.getStateFromVehicle(vData),
              knowLicensePlate: this.checkIfKnowLicensePlate(vData),
              vehicleId: id,
            };

            this.setState(
              {listOfVehicles: refinedListOfVehicle},
              () => {
                this.props.step2DataSuccess(dataToDispatch, this.vehicle)
              }
            );
            return;
          }
        }
        else {
          dataToDispatch = {
            ...this.getVehicleEmptyState(),
            vehicleId: -1,
          };

          this.setState(
            {errorTexts: []},
            () => {
              this.resetValidation("vehicleSelector", () => this.props.step2DataSuccess(dataToDispatch, this.vehicle));
              
            }
          );
          return;
        }
        break;

      default:
        dataToDispatch[componentName as keyof Step2Data] = selected;
        break;
    }

    if (componentName !== "vehicleSelector") {
      this.resetValidation(componentName)
    }

    this.props.step2DataSuccess(dataToDispatch, this.vehicle)
  }


  /**
   * Reset element validation
   * @param element
   * @returns
   */
  resetValidation(element: any, callback?: Function) {
    this.setState({
      errors: this.state.errors.filter((item: string) => item !== element),
      errorTexts: this.state.errorTexts.filter((item: IFeedbackText) => item.field !== element),
    }, () => callback?.());
  }


  // -------------------------------------------------------------------------------------------------------------------------------


  /**
   * Converts a month index number (1-12) to the corresponding italian month name.
   *
   * @param index
   * @returns
   */
  private monthIndexToName(index: number | undefined): string | undefined {
    if (index === undefined) {
      return undefined;
    } else {
      return Constants.MONTHS_LIST_IT.find(
        (element) => element.value === index
      )?.label.toLowerCase();
    }
  }

  /**
   * Form validation.
   *
   * @param specificField whether to check validity of a specific field
   * @returns {boolean} Validation check
   */
  private validation(specificField: undefined | string = undefined): Promise<IValidationResponse> {
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;

    const vehicle : IVehicle = {
      id: step2Data?.vehicleId,
      vehicleName: step2Data?.vehicleName,
      vehicleType: step2Data?.vehicleType,
      vehicleLicensePlateNumber: step2Data?.vehicleLicensePlateNumber,
      vehicleRegistrationMonth: step2Data?.vehicleRegistrationMonth,
      vehicleRegistrationYear: step2Data?.vehicleRegistrationYear,
      vehicleBuyYear: step2Data?.vehicleBuyYear,
      vehicleBrand: step2Data?.vehicleBrand,
      vehicleModel: step2Data?.vehicleModel,
      vehicleModelLabel: step2Data?.vehicleModelLabel,
      vehicleFitting: step2Data?.vehicleFitting,
      veicleFullDescription: step2Data?.veicleFullDescription,
      vehicleOwned: step2Data?.vehicleOwned,
      vehicleAntitheft: step2Data?.vehicleAntitheft,
      vehicleFuelType: step2Data?.vehicleFuelType,
      vehicleEngineDisplacement: step2Data?.vehicleEngineDisplacement,
      vehicleTowbarMounted: step2Data?.vehicleTowbarMounted,
      vehicleGplMounted: step2Data?.vehicleGplMounted,
    }

    let fieldsToValidate : any = JSON.parse(JSON.stringify(this.vehicleFields));

    if(specificField !== undefined){
      // fieldsToValidate = (fieldsToValidate.step?.[specificField] ?? fieldsToValidate.vehicle[this.vehicle ?? "none"]?.[specificField]) as IFieldDescriptor[]

      if(fieldsToValidate.step?.[specificField]){
        fieldsToValidate = [fieldsToValidate.step?.[specificField]] as IFieldDescriptor[]
      }
      else if(fieldsToValidate.vehicle[this.vehicle ?? "none"]?.[specificField]){
        fieldsToValidate = [fieldsToValidate.vehicle[this.vehicle ?? "none"]?.[specificField]] as IFieldDescriptor[]
      }
      else{
        // backup di sicurezza
        fieldsToValidate = [];
      }
    }
    else{      
      delete fieldsToValidate?.vehicle[this.vehicle ?? "none"]?.vehicleName;
      
      if(vehicle?.vehicleFuelType === "E"){
        delete fieldsToValidate?.vehicle[this.vehicle ?? "none"]?.vehicleGplMounted;
        delete fieldsToValidate?.vehicle[this.vehicle ?? "none"]?.vehicleEngineDisplacement;
      }
      
      fieldsToValidate = Object.entries(fieldsToValidate?.vehicle[this.vehicle ?? "none"]).map(([, value]) => value) as IFieldDescriptor[]
    }

    let validation: IValidationResponse = {
      status: true,
      error_fields: [],
      error_messages: [],
    };
    
    let promises : Promise<IValidationResponse>[] = [];
    if(step2Data?.vehicleId === undefined){
  
      let vehicleIdCheck: IValidationResponse = {
        status: false,
        error_fields: ["vehicleSelector"],
        error_messages: [{field: "vehicleSelector", msg: "Seleziona un veicolo o inseriscine uno nuovo"}]
      };
      
      promises.push(Promise.resolve(vehicleIdCheck));

    }
    else{
      promises.push(User.validateVehicle(
        vehicle as IVehicle,        
        fieldsToValidate
      )
        .then((result: IValidationResponse) => {
          let validation : IValidationResponse = {
            status: true,
            error_fields: [],
            error_messages: [],
          }

          const {status/*, error_fields, error_messages*/} = result;

          if(!status){
            validation.status = false;
            validation.error_fields = ["vehicleSelector"];
            validation.error_messages = [{field: "vehicleSelector", msg: " Assicurati di aver fornito tutti i dati necessari"}];
            
            return validation
          }

          return result;
        })
      );
    }

    if ((specificField === "knowLicensePlate" || !specificField) && isNullOrUndefined(step2Data.knowLicensePlate)) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["knowLicensePlate"],
        error_messages: []
      }));
    }
    
    if ((specificField === "kilometersPerYear" || !specificField) && !step2Data.kilometersPerYear) {
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["kilometersPerYear"],
          error_messages: []
        }));
    }

    if ((specificField === "vehicleParkingLocation" || !specificField) &&
      !(this.props.parkingLocationList === undefined || (this.props?.parkingLocationList.find(item => item.value === step2Data.vehicleParkingLocation) ?? false))
    ) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["vehicleParkingLocation"],
        error_messages: []
      }));
    }
  
    if (this.vehicle === "auto" && 
      ((specificField === "vehicleUse" || !specificField) && !step2Data.vehicleUse &&
        !(this.props.vehicleUseList === undefined || (this.props?.vehicleUseList.find(item => item.value === step2Data.vehicleUse) ?? false))
      )
    ) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["vehicleUse"],
        error_messages: []
      }));
    }
    
    return Promise.allSettled(promises)
      .then((results) => {
        results.forEach((fieldResponse) => {
          if (fieldResponse.status === "fulfilled") {
            const {status, error_fields, error_messages} = fieldResponse.value;
            
            validation.status = validation.status && status;

            error_fields.forEach((item: string) => {
              if(!validation.error_fields.includes(item)){
                validation.error_fields.push(item)
              }
            })

            error_messages.forEach((item: IFeedbackText) => {
              validation.error_messages = validation.error_messages.filter((storedText: IFeedbackText) => storedText.field !== item.field);
              validation.error_messages.push(item);
            })
          }
        })
        return validation;
      });

  }

  /**
   * Check and append state data to form
   *
   * @param {any} formData The form data
   * @param {string } key The data key
   * @param {any} stateVariable The state variable
   */
  private formDataAppend(formData: any, key: string, stateVariable: any) {
    if (stateVariable !== undefined) {
      formData.append(key, `${stateVariable}`);
      //console.log(formData);
    }
  }


  private submitToNode() {
    let formData = new URLSearchParams();
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;


    formData.append("vehicle", this.vehicle);
    formData.append("step", "2");

    if(this.props.user.id){
      formData.append("idUserAP", this.props.user.id.toString());
    }
    if(step1Data?.contractorId){
      formData.append("idContraenteAP", step1Data.contractorId.toString());
    }
    if(step2Data?.vehicleId){
      formData.append("idVeicoloAP", step2Data.vehicleId.toString());
    }

    this.formDataAppend(formData, "veicolo+ricordaLaTarga", step2Data.vehicleOwned);

    if (step2Data.vehicleOwned ? true : step2Data.knowLicensePlate) {
      this.formDataAppend(formData, "veicolo+targa", `${step2Data.vehicleLicensePlateNumber ?? ""}`);
    }
    if (
      !this.isVisibleLicensePlate() &&
      step2Data.knowLicensePlate !== undefined
    ) {
      this.formDataAppend(formData, "veicolo+nonConosceLaTarga", !step2Data.knowLicensePlate);
    }
    this.formDataAppend(formData, "veicolo+meseDiImmatricolazione", this.monthIndexToName(step2Data.vehicleRegistrationMonth));
    this.formDataAppend(formData, "veicolo+annoDiImmatricolazione", step2Data.vehicleRegistrationYear);
    this.formDataAppend(formData, "veicolo+annoAcquisto", step2Data.vehicleBuyYear);
    this.formDataAppend(formData, "veicolo+marca", step2Data.vehicleBrand);
    this.formDataAppend(formData, "veicolo+modello+id", step2Data.vehicleModel);
    this.formDataAppend(formData, "veicolo+allestimento+id", step2Data.vehicleFitting);

    if (this.isVisible("auto")) {
      this.formDataAppend(formData, "veicolo+gancioTraino", step2Data.vehicleTowbarMounted);
      this.formDataAppend(formData, "veicolo+alimentazione", step2Data.vehicleFuelType);
      this.formDataAppend(formData, "utilizzoTipico", step2Data.vehicleUse);
      if (step2Data.vehicleFuelType !== "E") {
        this.formDataAppend(formData, "veicolo+impiantoGPL", step2Data.vehicleGplMounted);
        this.formDataAppend(formData, "veicolo+cilindrata", step2Data.vehicleEngineDisplacement);
      }
      else if (step2Data.vehicleFuelType === "E") {
        this.formDataAppend(formData, "veicolo+cilindrata", 0);
      }
    }

    if (this.isVisible("auto")) {
      this.formDataAppend(formData, "contraente+numeroAuto", step2Data.moreThanOneVehicle ? "dueOPiu" : "una");
    }
    else {
      this.formDataAppend(formData, "contraente+numeroAuto", "una");
    }
    
    this.formDataAppend(formData, "chilometriPerAnno", step2Data.kilometersPerYear ? step2Data.kilometersPerYear : 0);
    this.formDataAppend(formData, "veicolo+antifurto", step2Data.vehicleAntitheft);
    this.formDataAppend(formData, "parcheggioNotturno", step2Data.vehicleParkingLocation);

    // Step 1 data
    formData.append("contraente+partitaIva", "");
    formData.append("contraente+denominazioneSociale", "");
    formData.append("contraente+settoreMerceologico", "");
    this.formDataAppend(formData, "contraente+sesso", step1Data.gender);
    if (!isNullOrUndefined(step1Data.dateOfBirth)) {
      let dateOfBirth = new Date(step1Data.dateOfBirth as number);
      formData.append("contraente+dataDiNascita+giorno", dateOfBirth.getDate().toString());
      formData.append("contraente+dataDiNascita+mese", dateOfBirth.toLocaleString("it-IT", { month: "long" }));
      formData.append("contraente+dataDiNascita+anno", dateOfBirth.getFullYear().toString());
    }

    formData.append("contraente+natoInItalia", `${step1Data.bornInItaly}`);
    if (step1Data.bornInItaly) {
      formData.append("contraente+luogoDiNascitaItalia+id", `${step1Data.cityOfBirth?.id}`);
    } else {
      formData.append("contraente+luogoDiNascitaEstero+id", `${step1Data.countryOfBirth?.id}`);
    }
    this.formDataAppend(formData, "contraente+statoCivile", step1Data.civilState);
    this.formDataAppend(formData, "contraente+titoloDiStudio", step1Data.degree);
    this.formDataAppend(formData, "contraente+professione", step1Data.profession);

    this.setState(
      { loading: true },
      () => {
        fetch(Constants.SEISICURO_FORM_POST_ENDPOINT, {
          signal: this.abortController.signal,
          method: "post",
          credentials: "include",
          body: formData,
          headers: {
            "Content-Type":
              'application/x-www-form-urlencoded; charset="utf-8"' /** AUTH_REDACTED */,
          },
        })
          .then((response) => {
            this.setState(
              { loading: false },
              () => {
                if (response.ok) {
                  this.props.step2DataSuccess({ step2Ok: true }, this.vehicle);
                  
                  fetch(Constants.SEISICURO_STEP_CHECK(2), {
                    signal: this.abortController.signal,
                    credentials: "include",
                    headers: {
                      /** AUTH_REDACTED */
                    },
                  })
                    .then((response) => response.json())
                    .then((responseJson) => {
                      if (
                        Object.keys(responseJson[0]).length > 0 &&
                        !(
                          Object.keys(responseJson[0])[0] === "veicolo.targa" &&
                          !this.isVisibleLicensePlate()
                        )
                      ) {
                        this.trackSendFailure();
                        this.setState({
                          errorTexts: [{
                            field: "generic",
                            msg: Object.keys(responseJson[0])
                              .map((element) => fixDirtyBackendErrorString(responseJson[0][element]))
                              .join("<\br>")
                          }],
                          submitting: false,
                        }, () => {
                          window.scrollTo(0, offsetFirstErrorField());
                        });
                      }
                      else {
                        this.trackSendSuccess(() => {
                          this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(3, this.vehicle))
                        });
                      }
                    })
                }
                else {
                  this.setState(
                    { loading: false },
                    () => {
                      this.trackSendFailure();
                      this.props.step2DataFailure(this.vehicle);
                    }
                  );
                }
              }
            )
          })
          .catch((error) => {
            this.setState(
              { loading: false },
              () => {
                this.trackSendFailure();
                this.props.step2DataFailure(this.vehicle);
              }
            );
          });
      }
    );
  }

  /**
   * Form submit.
   *
   */
  private handleFormSubmit() {

    this.props.userInfoDataSuccess({ direction: "forwards", nextStep: 3 }, this.vehicle);
    
    this.setState({submitting: true}, () => {

      this.validation()
        .then((result: any) => {
          const {status, error_fields, error_messages} = result;
          if(status){
            this.submitToNode();
          }
          else{
            this.setState({submitting: false, errors: error_fields, errorTexts: error_messages ?? []}, () => {
              this.trackSendFailure();
              window.scrollTo(0, offsetFirstErrorField());
            });
          }
        })
    })

  }

}

const mapState = (state: any) => {
  return {
    step1Data: state.step1Data,
    step2Data: state.step2Data,
    fromMailSereno: state.step4Data.fromMailSereno
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    step2DataSuccess: (payload: any, vehicle: string) => dispatch(step2DataSuccess(payload, vehicle)),
    step2DataFailure: (vehicle: string) => dispatch(step2DataFailure(vehicle)),
    step2DataReset: (vehicle: string) => dispatch(step2DataReset(vehicle)),
  }
};

const Step2Logged = (props: any) => {
  const history: ReturnType<typeof useHistory> = useHistory();
  const lists: IDataList = useContext(DataList);

  return <Step2Component {...props} history={history} {...lists}/>
}


export default compose(connect(mapState, mapDispatch), withEventManager)(Step2Logged);