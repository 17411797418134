import { Component, useContext } from "react";
import { fetchList, sortJson } from "../utils/methods";
import * as Constants from "../utils/constants";
import { Accordion } from "react-bootstrap";
import { AjaxEnrichedAutocompleteObject, IPerson, IPerson_WithErrors } from "../utils/common";
import { FeedbackBox } from "../utils/Feedback";
import { Step1PersonFieldsBuilder } from "../utils/PersonFieldsBuilder";
import { Tooltip } from "primereact/tooltip";
import { DataList, IDataList } from "./dataListContext";

class PersonListItemLabelComponent extends Component<{
  professionList: AjaxEnrichedAutocompleteObject[],
  civilStateList: AjaxEnrichedAutocompleteObject[],
  degreeList: AjaxEnrichedAutocompleteObject[],
  data: IPerson_WithErrors,
  parentId: string,
  selected: boolean,
  action?: any
},
{
  // loading: boolean;
}> {
  private readonly fields: any;
  private abortController: AbortController = new AbortController();

  constructor(props: any) {
    super(props);

    this.state = {
      // loading: true
    };

    if(this.props.action?.attributes?.onClick){
      this.props.action.attributes.onClick = this.props.action.attributes.onClick.bind(this);
    }
    
    this.fields = Step1PersonFieldsBuilder(true);
  }

  private getAge(dateOfBirth: Date){
    var today : Date = new Date();
    var age = today.getFullYear() - dateOfBirth.getFullYear();
    var m = today.getMonth() - dateOfBirth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
      age--;
    }
    return age;
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  render() {

    const person : IPerson = this.props.data;
    const errors : string[] = this.props.data?.errors || [];
    
    const civilState: string = this.props.civilStateList.find((item: AjaxEnrichedAutocompleteObject) => item.value === person.civilState, person)?.label ??  ""
    const profession: string = this.props.professionList.find((item: AjaxEnrichedAutocompleteObject) => item.value === person.profession, person)?.label ?? "";
    const bornPlace: string = person?.bornInItaly !== undefined
    ? person.bornInItaly
      ? person.cityOfBirth?.value ? `Paese di nascita: ${person.cityOfBirth.value}` : ""
      : person.countryOfBirth?.value ? `Stato di nascita: ${person.countryOfBirth.value}` : ""
    : ""


    return (
      // !this.state.loading &&
        <>          
            <span className="personFieldTitle d-flex align-items-center">
              { person.name || person.surname
                ? <>{person.name} {person.surname}</>
                : "Senza nome"
              }
              {errors && errors.length > 0 && 
                <>
                  <Tooltip className="yellow-tooltip" target={`#${this.props.parentId}-warning-sign`}>Informazioni incomplete</Tooltip>
                  <i id={`${this.props.parentId}-warning-sign`} className="pi pi-exclamation-triangle ms-2" />                  
                </>
              }
            </span>

          <Accordion.Collapse eventKey={`${this.props.parentId}`}>
            <>
              <p>
                  {`${person.gender === "donna"
                    ? "Donna"
                    : person.gender === "uomo"
                      ? "Uomo"
                      : "" //"Sesso non dichiarato"
                  }`}
                  {(person?.dateOfBirth ?? undefined) !== undefined
                    ? `, ${this.getAge(person.dateOfBirth as Date)}`
                    : "" //"età non dichiarata"
                  }
                  {civilState &&
                    <span>,&nbsp;{civilState}</span>
                  }
                  { profession &&
                    <span><br/>Professione: {profession}</span>
                  }
                  { bornPlace && <span><br/>{bornPlace}</span>}
              </p>

              {errors && errors.length > 0 &&              
                <FeedbackBox
                  items={[{field:"generic", msg: `Attenzione informazioni mancanti: 
                  ${errors.map((field: string) => this.fields[field]?.notification_label ?? 
                    this.fields[field].label_human_readable ?? this.fields[field]).join(", ")}`, severity:"warn"}]}
                    filter={{field:"generic", severity:"warn"}}
                />
              }

              {this.props?.action &&
                <span {...this.props.action?.attributes}>{this.props.action.label}</span>
              }
            </>
          </Accordion.Collapse>
        </>
    )
  }

}

const PersonListItemLabel = (props: any) => {
  const lists: IDataList = useContext(DataList);
  return <PersonListItemLabelComponent {...props} {...lists}/>;
};

export default PersonListItemLabel;
