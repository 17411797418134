import { priceFormatter } from "../utils/methods";

const QuotationColumnPrice = (props: any) => {

  const {company, onDetailClick} = props;
  
  return <div className="text-end">
    {<h3>{priceFormatter(company.total_price)}</h3>}
    <div>
      <span className="element-click" onClick={() => onDetailClick(company)}>{"Dettagli"}</span>
    </div>
  </div>

}

export default QuotationColumnPrice;