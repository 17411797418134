import { Component } from 'react';
import { useParams } from 'react-router-dom';

class NoQuotationComponent extends Component<{}, {}> {

    private list: any;

    constructor(props: any) {
        super(props);

        this.list = props.noQuotations;
    }

    render() {
        return (
            <div>
                <p className='no-quotation-container'>
                    <span id="no-quotation-head" className='no-quotation-elem'>{this.list.length} compagnie non hanno quotato: </span>
                    {
                        this.list && Array.isArray(this.list) && this.list.length > 0 &&
                        this.list.map((item: any, i, row) => {
                            return (i + 1 === row.length)
                                ? <span key={i} className='no-quotation-elem'>{item.company_name?.toLowerCase()}</span>
                                : <span key={i} className='no-quotation-elem'>{item.company_name?.toLowerCase()},</span> ;
                        })
                    }
                </p>
                <p>
                    A decorrere dal 13 luglio 2023, le soluzioni assicurative di Zurich Insurance Company Ltd non sono più distribuite da 6sicuro.it e non sarà pertanto possibile ottenere nuove quotazioni né consultare le condizioni relative a tale contratto ai sensi dell'art. 131 D.Lgs. 209/2005.
                </p>
            </div>
        );
    }
}

const NoQuotation = (props: any) => (
    <NoQuotationComponent
        {...props}
        params={useParams()}
    />
);
export default NoQuotation;