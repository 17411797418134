import "../../scss/_entity-card.scss";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Component, createRef, ForwardedRef, forwardRef, useContext} from "react";
import { AjaxAutocompleteObject, I4RuoteFitting, IFieldDescriptor, isAjaxAutocompleteObject, IVehicle, IVehicleFitting, Step2Data } from "../utils/common";
import * as Constants from "../utils/constants";
import { fetchList, postData, removeFocusClass, sortJson} from "../utils/methods";
import User, { IValidationResponse } from "../utils/User";
import UserService, { DuplicateException, GenericException } from "../utils/UserService";
import { WEB_SITE_API_SERVER_URL } from "../utils/constants";
import moment from "moment";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "../utils/Feedback";
import { Divider } from "primereact/divider";
import { DataList, IDataList } from "./dataListContext";

interface IVehicleCardProps extends IDataList{
  showControl?: boolean;  // false => interfaccia show non mostra il controller per entrare in modalità modifica, solo modalità 'dettaglio'
  editMode?: boolean;   // true => modalità modifica, false => modalità dettaglio
  visible: boolean;
  header?: JSX.Element;
  successCallback?: Function;
  errorCallback?: Function;
  vehicle?: string | IVehicle;
  user: User;
  hideFields?: string[]; // lista dei campi da nascondere
  requiredFields?: string[] | string | IFieldDescriptor[] | any; // lista dei campi da validare
  errors?: string[];
  errorTexts?: IFeedbackText[];  //{field, msg}
  onHideCallback?: Function
  ref: ForwardedRef<VehicleCardComponent>;
  setResourceListRequest: Function
}
interface IVehicleCardState{
  showControl: boolean;
  editMode: boolean;
  visible: boolean;
  header: JSX.Element;
  vehicle: IVehicle | undefined;
  knowLicensePlate?: boolean | undefined;
  // vehicleBrandList?: AjaxAutocompleteObject[];
  carBrandList: AjaxAutocompleteObject[];
  bikeBrandList: AjaxAutocompleteObject[];
  carModelList?: AjaxAutocompleteObject[];
  fuelTypeList?: AjaxAutocompleteObject[];
  engineDisplacementList?: AjaxAutocompleteObject[];
  vehicleFittingList?: IVehicleFitting[];
  is4RuoteValid?: boolean;
  infoFrom4Ruote?: boolean;
  plateLoading: boolean;
  saving: boolean;
  feedbackDialog: boolean;
  feedbackStatus: string;
  feedbackBody: string;
  errors: string[];
  errorTexts: IFeedbackText[];  //{field, msg}
  hideFields?: string[]; // lista dei campi da nascondere
  requiredFields: string[] | string | undefined | any;
  // antitheftList?: AjaxAutocompleteObject[];
  yearBuyList?: {label: string, value: number}[];
}


/* RIPORTO LA DOCUMENTAZIONE DI domenico sulas del metodo refine4RuoteVehicleFittings ex getVehicleFittings
*    anno: "2020"
*    categoria: "A"
*    codiceInfocar: "135376"   // codiceInfobike
*    codiceInfocarAM: "202002135376" // codiceInfobikePRG
*    descrizione: "Yaris 1.5 Hybrid 5 porte Business"
*    descrizioneCompleta: "TOYOTA Yaris 1.5 Hybrid 5 porte Business"
*    fineImmatricolazione: "000000"
*    fineVendita: "000000"
*    inizioImmatricolazione: "202002"
*    inizioVendita: "202002"
*    mese: "02"
*  }, .....
* */

class VehicleCardComponent extends Component<IVehicleCardProps, IVehicleCardState>{

  private defaultHeader: JSX.Element = <h3>Header</h3>;

  private monthList: {label: string, value: number}[] = [];
  private yearList: {label: string, value: number}[] = [];
  
  private isCachedValue: boolean;

  private oldLicensePlateValue: any;

  private readonly vehicleTypes: string[] = ["auto", "moto"];
  private dialogRef: any;
  private abortController: AbortController = new AbortController();

  constructor(props: IVehicleCardProps){
    super(props);

    let vehicle = typeof(this.props?.vehicle) === "string"
      ? this.props.user.getVehicleById(this.props.vehicle)
      : this.props.vehicle

    let currentYear = new Date().getFullYear();
    let yearBuyList: {label: string, value: number}[] = [];
    for (let i = 0; i <= Constants.VEHICLE_REGISTRATION_YEAR_MAX; i++) {
      this.yearList.push({
        label: (currentYear - i).toString(),
        value: currentYear - i,
      });
    }

    if (vehicle?.vehicleRegistrationYear !== undefined && vehicle?.vehicleRegistrationYear > 1888) {
      yearBuyList = this.getYearsBuyList(vehicle.vehicleRegistrationYear);
    }
    else{
      yearBuyList = [...this.yearList];
    }

    if(vehicle?.vehicleFuelType === "E"){
      vehicle.vehicleGplMounted = false;
    }

    this.state = {
      showControl: this.props?.showControl !== undefined ? this.props.showControl : true,
      editMode: this.props?.editMode as boolean,
      visible: this.props?.visible ? this.props.visible : false,
      header: this.props?.header ? this.props.header : this.defaultHeader,
      vehicle: vehicle,
      knowLicensePlate: typeof(this.props?.vehicle) !== "string"
        ? (this.props.vehicle as IVehicle)?.vehicleLicensePlateNumber !== undefined
          ? (this.props.vehicle as IVehicle).vehicleLicensePlateNumber !== "" ? true : false
          : undefined
        : undefined,
      plateLoading: false,
      saving: false,
      feedbackDialog: false,
      feedbackStatus: "",
      feedbackBody: "",
      errors: this.props?.errors ?? [],
      errorTexts: this.props?.errorTexts ?? [],
      hideFields: this.props.hideFields,
      requiredFields: vehicle?.vehicleType !== undefined
        ? vehicle.vehicleType === "moto"
          ? this.props.requiredFields.moto
          : this.props.requiredFields.auto
        : this.props.requiredFields.none,
      yearBuyList: yearBuyList,
      carBrandList: [],
      bikeBrandList: []
    }


    this.isCachedValue = false;
    this.monthList = Constants.MONTHS_LIST_IT;


    this.save = this.save.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.defaultSuccessCallback = this.defaultSuccessCallback.bind(this);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.validation = this.validation.bind(this);
    this.submit = this.submit.bind(this);
    this.initFromVehicle = this.initFromVehicle.bind(this);
    this.dialogRef = createRef();
  }

  componentDidMount() {

    let resourseRequestMask: any = {};
    if(!(this.props?.antitheftList?.length ?? false)){
      resourseRequestMask.antitheftList = true;
    }

    if(JSON.stringify(resourseRequestMask) != "{}"){
      this.props.setResourceListRequest(resourseRequestMask)
    }

    console.log("vehicle card mounted")
    // fetchList(Constants.SEISICURO_AJAX_ANTITHEFT_ENDPOINT, (result: any) => {
    //   result.sort(sortJson);
    //   this.setState({ antitheftList: result });
    // }, undefined, undefined, this.abortController.signal);

    this.fetchBrandList(this.state?.vehicle?.vehicleType ?? "auto")
      .catch(err => {
        // invaild vehicleType
        this.setState({
          carBrandList: [],
          bikeBrandList: [],
          // vehicleBrandList: []
        })
      })
    
    if(this.state?.vehicle !== undefined){
      let currentVehicle: IVehicle = {...this.state.vehicle};
      delete currentVehicle.vehicleType; 
      if(JSON.stringify(currentVehicle) !== "{}"){
        this.initFromVehicle(this.state.vehicle);
      }
    }
  }

  componentWillUnmount(): void {
    console.log("vehicle card unmounted")
  }

  private getYearsBuyList(year?: number): {label: string, value: number}[] {
    let yearBuyList = [];

    let startingYear: number | undefined;
    if(year === undefined){
      startingYear = this.state.vehicle?.vehicleRegistrationYear
    }
    else{
      startingYear = year;
    }
    
    if (startingYear !== undefined && startingYear > 1888) {
      let currentYear = new Date().getFullYear();    
      for (let i = startingYear; i <= currentYear; i++) {
        yearBuyList.push({ label: i.toString(), value: i });
      }
    }
    
    return yearBuyList;
  }

  private initFromVehicle(vehicle: IVehicle, preserveDependencies: boolean = true){

    let yearBuyList : {label: string, value: number}[] = [];

    if (vehicle?.vehicleRegistrationYear !== undefined && vehicle?.vehicleRegistrationYear > 1888) {
      yearBuyList = this.getYearsBuyList(vehicle.vehicleRegistrationYear);
    }
    else{
      yearBuyList = [...this.yearList];
    }

    if(vehicle?.vehicleFuelType === "E"){
      vehicle.vehicleGplMounted = false;
    }
    
    this.fetchBrandList(vehicle.vehicleType)
      .then((brandList: AjaxAutocompleteObject[]) : string => {
        
        if(vehicle?.vehicleBrand !== undefined && brandList.find((brand: AjaxAutocompleteObject) => brand.id === vehicle?.vehicleBrand)){
          vehicle.vehicleBrandLabel = brandList.find(b => b.id === vehicle.vehicleBrand)?.value
          return vehicle.vehicleBrand as string;
        }
        else{
          vehicle.vehicleBrand = undefined;
          vehicle.vehicleBrandLabel = undefined;
          vehicle.vehicleModel = undefined;
          vehicle.vehicleModelLabel = undefined;

          vehicle.vehicleFuelType = undefined;
          vehicle.vehicleEngineDisplacement = undefined;
          vehicle.vehicleFitting = undefined;
          vehicle.vehicleFittingLabel = undefined;
          vehicle.veicleFullDescription = undefined;
          throw new Error("stop initialize step 0");
        }
      })
      .then(() => this.fetchModels(preserveDependencies, vehicle))
      .then((sortedModelList: AjaxAutocompleteObject[] | null) : string => {
        if(sortedModelList !== null){
          //la richiesta è stata eseguita
          if(vehicle?.vehicleModel){
            if(!(sortedModelList?.find((model: AjaxAutocompleteObject) => model.id === vehicle.vehicleModel) ?? false)){
              vehicle.vehicleModel = undefined;
              vehicle.vehicleModelLabel = undefined;

              vehicle.vehicleFuelType = undefined;
              vehicle.vehicleEngineDisplacement = undefined;
              vehicle.vehicleFitting = undefined;
              vehicle.vehicleFittingLabel = undefined;
              vehicle.veicleFullDescription = undefined;

              throw new Error("stop initialize step 1");
            }
            else{
              vehicle.vehicleModelLabel = this.state.carModelList?.find(m => m.id === vehicle?.vehicleModel)?.value
              return vehicle.vehicleModel as string;
            }
          }
          else{
            vehicle.vehicleModel = undefined;
            vehicle.vehicleModelLabel = undefined;

            vehicle.vehicleFuelType = undefined;
            vehicle.vehicleEngineDisplacement = undefined;
            vehicle.vehicleFitting = undefined;
            vehicle.vehicleFittingLabel = undefined;
            vehicle.veicleFullDescription = undefined;
            throw new Error("stop initialize step 1");
          }
        }
        else{
          vehicle.vehicleModel = undefined;
          vehicle.vehicleModelLabel = undefined;

          vehicle.vehicleFuelType = undefined;
          vehicle.vehicleEngineDisplacement = undefined;
          vehicle.vehicleFitting = undefined;
          vehicle.vehicleFittingLabel = undefined;
          vehicle.veicleFullDescription = undefined;
          throw new Error("stop initialize step 1");
        }
      })
      .then((model: string) => {        

        return vehicle.vehicleType === "auto"
          ? this.fetchFuelType(preserveDependencies, vehicle)
              .then((sortedFuelList: AjaxAutocompleteObject[] | null) => {
                if(sortedFuelList !== null){
                  if(vehicle?.vehicleFuelType){
                    if(!(sortedFuelList?.find((fuel: AjaxAutocompleteObject) => fuel.id === vehicle.vehicleFuelType) ?? false)){
                      vehicle.vehicleFuelType = undefined;
      
                      vehicle.vehicleEngineDisplacement = undefined;
                      vehicle.vehicleFitting = undefined;
                      vehicle.vehicleFittingLabel = undefined;
                      vehicle.veicleFullDescription = undefined;
      
                      throw new Error("stop initialize step 2");
                    }
                    else{
                      return vehicle.vehicleFuelType as string;
                    }
                  }
                  else{
                    vehicle.vehicleFuelType = undefined;
    
                    vehicle.vehicleEngineDisplacement = undefined;
                    vehicle.vehicleFitting = undefined;
                    vehicle.vehicleFittingLabel = undefined;
                    vehicle.veicleFullDescription = undefined;
                    throw new Error("stop initialize step 2");
                  }
                }
                else{
                  vehicle.vehicleFuelType = undefined;
  
                  vehicle.vehicleEngineDisplacement = undefined;
                  vehicle.vehicleFitting = undefined;
                  vehicle.vehicleFittingLabel = undefined;
                  vehicle.veicleFullDescription = undefined;
                  throw new Error("stop initialize step 2");
                }
              })
              .then(() => this.fetchEngineDisplacement(preserveDependencies, vehicle))
              .then((sortedEngineDisplacementList: AjaxAutocompleteObject[] | null) => {
                if(sortedEngineDisplacementList !== null){
                  if(vehicle?.vehicleEngineDisplacement){
                    if(!(sortedEngineDisplacementList?.find((fuel: AjaxAutocompleteObject) => fuel.id === vehicle.vehicleEngineDisplacement) ?? false)){
                      vehicle.vehicleEngineDisplacement = undefined;
      
                      vehicle.vehicleFitting = undefined;
                      vehicle.vehicleFittingLabel = undefined;
                      vehicle.veicleFullDescription = undefined;
      
                      throw new Error("stop initialize step 3");
                    }
                    else{
                      return vehicle.vehicleEngineDisplacement as string;
                    }
                  }
                  else{
                    vehicle.vehicleEngineDisplacement = undefined;
    
                    vehicle.vehicleFitting = undefined;
                    vehicle.vehicleFittingLabel = undefined;
                    vehicle.veicleFullDescription = undefined;
                    throw new Error("stop initialize step 3");
                  }
                }
                else{
                  vehicle.vehicleEngineDisplacement = undefined;
  
                  vehicle.vehicleFitting = undefined;
                  vehicle.vehicleFittingLabel = undefined;
                  vehicle.veicleFullDescription = undefined;
                  throw new Error("stop initialize step 3");
                }
              })
          : model;
      })
      .then(() => this.fetchVehicleFittings(preserveDependencies, vehicle))
      .then((sortedFittingList: IVehicleFitting[] | null) => {
        if(sortedFittingList !== null){
          //la richiesta è stata eseguita
          if(vehicle?.vehicleFitting){
            if(!(sortedFittingList?.find((fitting: IVehicleFitting) => fitting.id === vehicle.vehicleFitting) ?? false)){
              vehicle.vehicleFitting = undefined;
              vehicle.vehicleFittingLabel = undefined;
              vehicle.veicleFullDescription = undefined;

              throw new Error("stop initialize step 3");
            }
            else{
              vehicle.vehicleFittingLabel = this.state.vehicleFittingList?.find(m => m.id === vehicle?.vehicleFitting)?.value
              return vehicle.vehicleFitting as string;
            }

          }
          else{
            vehicle.vehicleFitting = undefined;
            vehicle.vehicleFittingLabel = undefined;
            vehicle.veicleFullDescription = undefined;
            throw new Error("stop initialize step 3");
          }

        }
        else{
          vehicle.vehicleFitting = undefined;
          vehicle.vehicleFittingLabel = undefined;
          vehicle.veicleFullDescription = undefined;
          throw new Error("stop initialize step 3");
        }
      })
      .then(() => {
        vehicle.veicleFullDescription = `${vehicle.vehicleBrandLabel} ${vehicle.vehicleModelLabel}`;
      })
      .catch((error: Error) => {
        console.log(error.message);
      })
      .finally(() => {
        this.setState({
          vehicle: vehicle,
          yearBuyList
        })
      });
        // }
        // else{
        //   this.setState({
        //     vehicle: vehicle,
        //     yearBuyList
        //   })
        // }

      // })
  }

  defaultSuccessCallback(response: any, callback?: Function){
    this.setState({
      vehicle: {...this.state.vehicle, id: response.id},      
      header: <h3 className="colorPrimaryGreen titleLev4">Dettaglio {this.state.vehicle?.vehicleType === "moto" ? "moto " : "auto"} {this.state.vehicle?.vehicleLicensePlateNumber}</h3>,
      feedbackDialog: true,
      feedbackStatus: "success",
      feedbackBody: "Salvataggio completato con successo"
    }, () => callback?.(this.state.vehicle));

    this.dialogScrollToTop()
  }

  defaultErrorCallback(error?: Error){
    this.setState({
      feedbackDialog: true,
      feedbackStatus: "error",
      feedbackBody: error?.message ? error.message : "Non è stato possibile completare il salvataggio"
    }, () => { this.props.errorCallback?.(this.state.vehicle) });

    this.dialogScrollToTop()
  }

  render(){    
    const vehicle = {...this.state.vehicle};
    let disabled : boolean = !this.state.editMode || !this.vehicleTypes.includes(vehicle?.vehicleType ?? "") || this.state.saving;

    return <div>
      <Dialog
        id="vehicleCard"
        className="entityCard"
        modal={true}
        visible={this.state.visible}
        resizable={false}
        draggable={false}
        onHide={this.hide}
        closable={!this.state.saving}
        closeOnEscape={false}
        header={
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-7 col-12 realTitle">
                {this.state.header}
              </div>
              <div className="col-md-5 md-0 col-12 mt-2 switchModifica">
                { this.state.showControl &&                
                  <span className="editToggle">
                    <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/`+(this.state.editMode ? "lock-sbloccato" : "lock")+`.svg`} alt=""/>
                    <span className={this.state.editMode ? "colorPrimaryGray" : "colorPrimaryGreen"} onClick={() => {this.setState({editMode: !this.state.editMode})}}>{this.state.editMode ? "Blocca modifica" : "Abilita modifica"}</span>
                  </span>                
                }
              </div>              
            </div>
          </div>
        }
        /*breakpoints={{'960px': '75vw'}}*/
        style={{ width: '600px' }}
        blockScroll={true}
      >
      <div className="container-fluid no-gutters" ref={this.dialogRef}>
        
        
        <div className="row">
          
          {this.state.feedbackDialog
            ? <FeedbackBox items={[{field: "generic", msg: this.state.feedbackBody, severity: this.state.feedbackStatus}]} filter={{field: "generic",  severity: this.state.feedbackStatus}}/>
            : <FeedbackBox items={this.state.errorTexts}/>
          }
          
          { !(this.state?.hideFields && this.state.hideFields.includes("vehicleType")) &&
            <div className="col-12 mb-4">
              <label>{this.state.requiredFields?.vehicleType.label_human_readable}</label>
              <Dropdown
                className={!this.vehicleTypes.includes(vehicle?.vehicleType ?? "") ? "p-error" : ""}
                value={vehicle?.vehicleType}
                options={[
                  { label: "Auto", value: "auto" },
                  { label: "Moto", value: "moto" },
                ]}
                onChange={(selected) => this.onChangeHandler(selected.value, "vehicleType") }
                optionLabel="label"
                placeholder="Seleziona..."
                emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                onBlur={() => this.validation( "vehicleType" )}
                onHide={() => removeFocusClass()}
                disabled={!this.state.editMode || this.state.saving}
              />
              <ContextFeedback
                show={this.state.errors.includes("vehicleType")}
                message={
                  this.state.errorTexts.find(elem => elem.field === "vehicleType") ??
                  {msg: this.state.requiredFields?.vehicleType?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleType?.validation.fail_severity ?? "error"}
                }
              />
            </div>
          }

          { !(this.props?.hideFields && this.props.hideFields.includes("vehicleName")) &&
            <div className="col-12 mb-4">
              <label htmlFor="vehicleName">{this.state.requiredFields?.vehicleName.label_human_readable}</label>
              <InputText
                id="vehicleName"
                placeholder="Nome veicolo"
                value={vehicle?.vehicleName ?? ""}
                name="vehicleName"
                onChange={(selected) => this.onChangeHandler( selected.target.value, "vehicleName" ) }
                disabled={disabled}
              />
              <ContextFeedback
                show={this.state.errors.includes("vehicleName")}
                message={
                  this.state.errorTexts.find(elem => elem.field === "vehicleName") ??
                  {msg: this.state.requiredFields?.vehicleName?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleName?.validation.fail_severity ?? "error"}
                }
              />
            </div>
          }

          { !(this.props?.hideFields && this.props.hideFields.includes("vehicleOwned")) &&
            <div className="col-12 mb-4">
              <label>{this.state.requiredFields?.vehicleOwned.label_human_readable}</label>
              <SelectButton
                value={vehicle?.vehicleOwned}
                unselectable={false}
                options={[
                  { label: "Già di proprietà", value: true },
                  { label: "Ancora da acquistare", value: false },
                ]}
                onChange={(selected) => this.onChangeHandler(selected.value, "vehicleOwned") }
                disabled={disabled}
              />
              <ContextFeedback
                show={this.state.errors.includes("vehicleOwned")}
                message={
                  this.state.errorTexts.find(elem => elem.field === "vehicleOwned") ??
                  {msg: this.state.requiredFields?.vehicleOwned?.notification_label ?? this.state.requiredFields?.vehicleOwned?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleOwned?.validation.fail_severity ?? "error"}
                }
              />
            </div>
          }

          {this.isVisible("knowLicensePlate") && (
            <div className="col-12 mb-4">
              <SelectButton
                value={this.state.knowLicensePlate}
                unselectable={false}
                options={[
                  { label: "Conosco la targa", value: true },
                  { label: "Non conosco la targa", value: false },
                ]}
                onChange={(selected) => this.onChangeHandler(selected.value, "knowLicensePlate")}
                disabled={disabled}
              />
              <ContextFeedback
                show={this.state.errors.includes("knowLicensePlate")}
                message={
                  this.state.errorTexts.find(elem => elem.field === "knowLicensePlate") ??
                  {msg: this.state.requiredFields?.knowLicensePlate?.notification_label ?? this.state.requiredFields?.knowLicensePlate?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.knowLicensePlate?.validation.fail_severity ?? "error"}
                }
              />
            </div>
          )}

          {/* { !(this.props?.hideFields && this.props.hideFields.includes("vehicleLicensePlateNumber")) && */}
          {this.isVisible("vehicleLicensePlateNumber") &&
            <div className="col-12 mb-4">
              <label htmlFor="targa">{this.state.requiredFields?.vehicleLicensePlateNumber.label_human_readable}</label>
              <InputText
                type="text"
                placeholder="Targa"
                value={vehicle?.vehicleLicensePlateNumber ?? ""}
                id="targa"
                onFocus={() => { this.oldLicensePlateValue = vehicle?.vehicleLicensePlateNumber ?? "" }}
                onChange={(selected) => this.onChangeHandler(selected.target.value, "vehicleLicensePlateNumber")
                }
                onBlur={() => this.fetchVeicleInfo4Ruote()}
                disabled={disabled}
              />
              <ContextFeedback
                show={this.state.errors.includes("vehicleLicensePlateNumber")}
                message={
                  this.state.errorTexts.find(elem => elem.field === "vehicleLicensePlateNumber") ??
                  {msg: this.state.requiredFields?.vehicleLicensePlateNumber?.notification_label ?? this.state.requiredFields?.vehicleLicensePlateNumber?.validation.fail_msg ?? "Targa non valida", severity: this.state.requiredFields?.vehicleLicensePlateNumber?.validation.fail_severity ?? "error"}
                }
              />
            </div>
          }


          { !(this.props?.hideFields && this.props.hideFields.includes("vehicleRegistrationYear") && this.props.hideFields.includes("vehicleRegistrationMonth")) &&
            <div className="immatricolation-fields col-12">
              <div className="row">
                { !(this.props?.hideFields && this.props.hideFields.includes("vehicleRegistrationYear")) && 
                  <div className="col-xl-6 col-12 mb-4">
                    <label>{this.state.requiredFields?.vehicleRegistrationYear.label_human_readable}</label>
                    <Dropdown
                      value={vehicle?.vehicleRegistrationYear}
                      options={this.yearList}
                      onChange={(selected) => {
                        this.onChangeHandler(selected.value, "vehicleRegistrationYear")}
                      }
                      optionLabel="label"
                      placeholder="Seleziona..."
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      onBlur={() => {}/*this.validation("vehicleRegistrationYear") */}
                      onHide={() => removeFocusClass()}
                      disabled={
                        disabled ||
                        (this.state.infoFrom4Ruote !== undefined &&
                        this.state.infoFrom4Ruote === true)
                      }
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleRegistrationYear")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleRegistrationYear") ??
                        {msg: this.state.requiredFields?.vehicleRegistrationYear?.notification_label ?? this.state.requiredFields?.vehicleRegistrationYear?.validation.fail_msg ?? "Inserisci l'anno di immatricolazione", severity: this.state.requiredFields?.vehicleRegistrationYear?.validation.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
                { !(this.props?.hideFields && this.props.hideFields.includes("vehicleRegistrationMonth")) && 
                  <div className="col-xl-6 col-12 mb-4">
                    <label>{this.state.requiredFields?.vehicleRegistrationMonth.label_human_readable}</label>
                    <Dropdown
                      value={vehicle?.vehicleRegistrationMonth}
                      options={this.monthList}
                      onChange={(selected) => this.onChangeHandler(selected.value, "vehicleRegistrationMonth")}
                      optionLabel="label"
                      placeholder="Seleziona..."
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      onHide={() => removeFocusClass()}
                      disabled={
                        disabled ||
                        (this.state.infoFrom4Ruote !== undefined &&
                        this.state.infoFrom4Ruote === true)
                      }
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleRegistrationMonth")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleRegistrationMonth") ??
                        {msg: this.state.requiredFields?.vehicleRegistrationMonth?.notification_label ?? this.state.requiredFields?.vehicleRegistrationMonth?.validation.fail_msg ?? "Inserisci il mese di immatricolazione", severity: this.state.requiredFields?.vehicleRegistrationMonth?.validation.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
              </div>
            </div>
          }

          { !(this.props?.hideFields && this.props.hideFields.includes("vehicleBuyYear")) && 
            <div className="col-12 mb-4">
              <label>{this.state.requiredFields?.vehicleBuyYear.label_human_readable}</label>
              <Dropdown
                value={vehicle?.vehicleBuyYear}
                options={this.state.yearBuyList}
                onChange={(selected) =>
                  this.onChangeHandler(selected.value, "vehicleBuyYear")
                }
                onHide={() => removeFocusClass()}
                optionLabel="label"
                placeholder="Seleziona"
                emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                // onBlur={() => this.validation("vehicleBuyYear")}
                disabled={disabled}
              />
              <ContextFeedback
                show={this.state.errors.includes("vehicleBuyYear")}
                message={
                  this.state.errorTexts.find(elem => elem.field === "vehicleBuyYear") ??
                  {msg: this.state.requiredFields?.vehicleBuyYear?.notification_label ?? this.state.requiredFields?.vehicleBuyYear?.validation.fail_msg ?? "Inserisci l'anno di acquisto", severity: this.state.requiredFields?.vehicleBuyYear?.validation.fail_severity ?? "error"}
                }
              />
            </div>
          }

          {(this.state.infoFrom4Ruote === undefined || this.state.infoFrom4Ruote === false) &&
            <>
              { !(this.props?.hideFields && this.props.hideFields.includes("vehicleBrand")) &&
                <div className="col-12 mb-4">
                  <label>{this.state.requiredFields?.vehicleBrand.label_human_readable}</label>
                  <Dropdown
                    value={vehicle?.vehicleBrand}
                    options={(this.state.vehicle?.vehicleType ?? "auto") === "auto"
                      ? this.state.carBrandList
                      : this.state.bikeBrandList
                    }
                    onChange={(selected) =>
                      this.onChangeHandler(selected.value, "vehicleBrand")
                    }
                    onHide={() => removeFocusClass()}
                    optionValue="id"
                    optionLabel="value"
                    placeholder="Seleziona"
                    emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    disabled={disabled}
                  />
                  <ContextFeedback
                    show={this.state.errors.includes("vehicleBrand")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "vehicleBrand") ??
                      {msg: this.state.requiredFields?.vehicleBrand?.notification_label ?? this.state.requiredFields?.vehicleBrand?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleBrand?.validation.fail_severity ?? "error"}
                    }
                  />
                </div>
              }
              
              { !(this.props?.hideFields && this.props.hideFields.includes("vehicleModel")) &&
                <div className="col-12 mb-4">
                  <label>{this.state.requiredFields?.vehicleModel.label_human_readable}</label>
                  <Dropdown
                    value={vehicle?.vehicleModel}
                    options={this.state.carModelList}
                    onChange={(selected) =>
                      this.onChangeHandler(selected.value, "vehicleModel")
                    }
                    onHide={() => removeFocusClass()}
                    optionValue="id"
                    optionLabel="value"
                    placeholder="Seleziona"
                    emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    disabled={disabled}
                  />
                  <p className="small">Assicurati di aver selezionato Anno di immatricolazione, Mese di immatricolazione e Marca</p>
                  <ContextFeedback
                    show={this.state.errors.includes("vehicleModel")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "vehicleModel") ??
                      {msg: this.state.requiredFields?.vehicleModel?.notification_label ?? this.state.requiredFields?.vehicleModel?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleModel?.validation.fail_severity ?? "error"}
                    }
                  />
                </div>
              }

              {vehicle?.vehicleType === "auto" &&
                <>
                  {!(this.state?.hideFields && this.state.hideFields.includes("vehicleFuelType")) &&
                    <div className="col-12 mb-4">
                      <label>{this.state.requiredFields?.vehicleFuelType.label_human_readable}</label>
                      <Dropdown
                        value={vehicle?.vehicleFuelType}
                        options={this.state.fuelTypeList}
                        onChange={(selected) =>
                          this.onChangeHandler(selected.value, "vehicleFuelType")
                        }
                        onHide={() => removeFocusClass()}
                        optionValue="id"
                        optionLabel="value"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                        disabled={disabled}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("vehicleFuelType")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "vehicleFuelType") ??
                          {msg: this.state.requiredFields?.vehicleFuelType?.notification_label ?? this.state.requiredFields?.vehicleFuelType?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleFuelType?.validation.fail_severity ?? "error"}
                        }
                      />
                    </div>
                  }

                  {!(this.props?.hideFields && this.props.hideFields.includes("vehicleEngineDisplacement")) && vehicle.vehicleFuelType !== "E" &&
                    <div className="col-12 mb-4">
                      <label>{this.state.requiredFields?.vehicleEngineDisplacement.label_human_readable}</label>
                      <Dropdown
                        value={vehicle?.vehicleEngineDisplacement}
                        options={this.state.engineDisplacementList}
                        onChange={(selected) =>
                          this.onChangeHandler(
                            selected.value,
                            "vehicleEngineDisplacement"
                          )
                        }
                        onHide={() => removeFocusClass()}
                        optionValue="id"
                        optionLabel="value"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                        disabled={disabled}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("vehicleEngineDisplacement")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "vehicleEngineDisplacement") ??
                          {msg: this.state.requiredFields?.vehicleEngineDisplacement?.notification_label ?? this.state.requiredFields?.vehicleEngineDisplacement?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleEngineDisplacement?.validation.fail_severity ?? "error"}
                        }
                      />
                    </div>
                  }
                </>
              }
            </>
          }

          {!(this.props?.hideFields && this.props.hideFields.includes("vehicleFitting")) &&
            <>
              {/*{!(this.state.vehicleFittingList === undefined || (Array.isArray(this.state.vehicleFittingList) && this.state.vehicleFittingList.length === 0) || this.state.vehicleFittingList[0] === undefined ||
                this.state.vehicleFittingList[0].value === "") && */}
                <div className="col-12 mb-4">
                  <label>{this.state.requiredFields?.vehicleFitting.label_human_readable}</label>
                  <Dropdown
                    value={vehicle?.vehicleFitting}
                    options={this.state.vehicleFittingList}
                    onChange={(selected) =>
                      this.onChangeHandler(
                        selected.value,
                        "vehicleFitting"
                      )
                    }
                    onHide={() => removeFocusClass()}
                    optionValue="id"
                    optionLabel="value"
                    placeholder="Seleziona"
                    emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    disabled={disabled}
                  />
                  <ContextFeedback
                    show={this.state.errors.includes("vehicleFitting")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "vehicleFitting") ??
                      {msg: this.state.requiredFields?.vehicleFitting?.notification_label ?? this.state.requiredFields?.vehicleFitting?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleFitting?.validation.fail_severity ?? "error"}
                    }
                  />
                </div>
              {/*}*/}
            </>
          }

          {!(this.props?.hideFields && this.props.hideFields.includes("vehicleFitting")) &&
            <>
              {this.state?.infoFrom4Ruote === true && ((vehicle?.vehicleFitting !== undefined && vehicle?.vehicleFitting !== "") || vehicle?.veicleFullDescription !== undefined) &&
                <div className="col-12 mb-4">
                  Marca e Modello:{" "}
                  <strong>{vehicle?.veicleFullDescription}</strong>
                </div>
              }
            </>
          }
          
          {vehicle?.vehicleType === "auto" && !(this.props?.hideFields && this.props.hideFields.includes("vehicleGplMounted")) && vehicle?.vehicleFuelType !== "E" &&
            <div className="col-12 mb-4">
              <label>{this.state.requiredFields?.vehicleGplMounted.label_human_readable}</label>
              <SelectButton
                value={vehicle?.vehicleGplMounted}
                options={[
                  { label: "Si", value: true },
                  { label: "No", value: false },
                ]}
                onChange={(selected) =>
                  this.onChangeHandler(selected.value, "vehicleGplMounted")
                }
                className="smallBtn"
                unselectable={false}
                disabled={disabled}
              />
              <ContextFeedback
                show={this.state.errors.includes("vehicleGplMounted")}
                message={
                  this.state.errorTexts.find(elem => elem.field === "vehicleGplMounted") ??
                  {msg: this.state.requiredFields?.vehicleGplMounted?.notification_label ?? this.state.requiredFields?.vehicleGplMounted?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleGplMounted?.validation.fail_severity ?? "error"}
                }
              />
            </div>
          }
          
          {vehicle?.vehicleType === "auto" && !(this.props?.hideFields && this.props.hideFields.includes("vehicleTowbarMounted")) &&
            <div className="col-12 mb-4">
              <label>{this.state.requiredFields?.vehicleTowbarMounted.label_human_readable}</label>
              <SelectButton
                value={vehicle?.vehicleTowbarMounted}
                options={[
                  { label: "Si", value: true },
                  { label: "No", value: false },
                ]}
                onChange={(selected) =>
                  this.onChangeHandler(
                    selected.value,
                    "vehicleTowbarMounted"
                  )
                }
                className="smallBtn"
                unselectable={false}
                disabled={disabled}
              />
              <ContextFeedback
                show={this.state.errors.includes("vehicleTowbarMounted")}
                message={
                  this.state.errorTexts.find(elem => elem.field === "vehicleTowbarMounted") ??
                  {msg: this.state.requiredFields?.vehicleTowbarMounted?.notification_label ?? this.state.requiredFields?.vehicleTowbarMounted?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleTowbarMounted?.validation.fail_severity ?? "error"}
                }
              />
            </div>
          }


          {!(this.props?.hideFields && this.props.hideFields.includes("vehicleAntitheft")) &&
            <div className="col-12 mb-4">
              <label>Tipologia di antifurto</label>
              <Dropdown
                value={vehicle?.vehicleAntitheft}
                options={this.props.antitheftList}
                onChange={(selected) =>
                  this.onChangeHandler(selected.value, "vehicleAntitheft")
                }
                onHide={() => removeFocusClass()}
                optionValue="value"
                optionLabel="label"
                placeholder="Seleziona"                              
                emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                disabled={disabled}
              />
              <ContextFeedback
                show={this.state.errors.includes("vehicleAntitheft")}
                message={
                  this.state.errorTexts.find(elem => elem.field === "vehicleAntitheft") ??
                  {msg: this.state.requiredFields?.vehicleAntitheft?.notification_label ?? this.state.requiredFields?.vehicleAntitheft?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.vehicleAntitheft?.validation.fail_severity ?? "error"}
                }
              />
            </div>
          }
                
        </div>
        
        {this.state.editMode &&
          <>
            <Divider></Divider>
            <div className="row mt-4">
              <div className="col-12">
                <Button
                  className="customBtn d-block bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite mt-3"
                  style={{width: "100%", textAlign: "center"}}
                  onClick={this.save}
                  label="Salva"
                  loading={this.state.saving}
                />
              </div>
            </div>
          </>
        }
      </div>

      </Dialog>

      {/* PopUp Servizio targhe */}
      {this.state !== undefined && this.state?.plateLoading && (
        <Dialog
          header=""
          className="update_popUp"
          visible={this.state.plateLoading}
          closable={false}
          draggable={false}
          style={{ width: "50vw" }}
          onHide={() => {}}
        >
          <div className="row">
            <div className="col-12">
              <p className="col_title">
                Tentativo di recupero dei dati veicolo in corso
              </p>
              <div className="row text-center">
                <div className="image-loader-update">
                  <img
                    src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/6sicuro-logo-animato.gif`}
                    alt="attendi il caricamento dei risultati - 6sicuro"
                    id="attesa_caricamento"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  }

  public show(params: {
    vehicle?: IVehicle | string | undefined,
    header: JSX.Element,
    hideFields: string[],
    errors: string[],
    errorTexts: IFeedbackText[],
    editMode?: boolean,
    showControl?: boolean,
    vehicleFittingList?: [],
    requiredFields?: string[] | string | any
  } = {header: this.defaultHeader, hideFields: [], errors: [], errorTexts: []}){

    let knowLicensePlate: boolean | undefined;
    if(params?.vehicle && typeof(params?.vehicle) !== "string"){
      if(params.vehicle?.vehicleOwned !== undefined){
        if(params.vehicle.vehicleOwned === false && (params.vehicle?.vehicleLicensePlateNumber ?? "") === ""){
          knowLicensePlate = false;
        }
        else{
          knowLicensePlate = true;
        }
      }
      else{
        knowLicensePlate = undefined;
      }
    }
    else{
      knowLicensePlate = undefined;
    }

    let state : any = {
      visible: true,
      header: params.header,
      // vehicle: params.vehicle !== undefined
      //   ? typeof(params.vehicle) === "string"
      //     ? this.props.user.getVehicleById(params.vehicle?.vehicleLicensePlateNumber)
      //     : params.vehicle
      //   : undefined,
      knowLicensePlate: knowLicensePlate,
      errors: params.errors ?? [],
      errorTexts: params.errorTexts ?? [],
      editMode: params?.editMode !== undefined
        ? params.editMode
        : this.state.editMode,
      showControl: params?.showControl !== undefined
        ? params.showControl
        : this.state.showControl,
      hideFields: params?.hideFields !== undefined ? params.hideFields : this.state.hideFields,
      requiredFields: params?.requiredFields !== undefined ? params.requiredFields : this.state.requiredFields
    };


    // if(state?.vehicle?.vehicleFuelType === "E"){
    //   state.vehicle.vehicleGplMounted = false;
    // }

    let vehicleLicensePlateNumber: string | undefined =
      params.vehicle !== undefined
          ? typeof(params.vehicle) === "string"
            ? this.props.user.getVehicleById(params.vehicle)?.vehicleLicensePlateNumber
            : params.vehicle?.vehicleLicensePlateNumber
          : undefined;

    if (vehicleLicensePlateNumber) {
      this.is4RoutePlate(vehicleLicensePlateNumber, params?.vehicle as IVehicle)
        .then((validation: {is4RuoteValid: boolean, infoFrom4Ruote: boolean, fittingList?:  IVehicleFitting[]}) => {
          state.is4RuoteValid = validation.is4RuoteValid;
          state.infoFrom4Ruote = validation.infoFrom4Ruote;

          this.setState(
            state,
            () => {
              if(params.vehicle !== undefined){
                let vehicle: IVehicle;
                if(typeof(params.vehicle) === "string"){
                  // ho passato un id
                  vehicle = this.props.user.getVehicleById(params.vehicle) ?? {}
                }
                else{
                  vehicle = {...params.vehicle}
                }

                let yearBuyList : {label: string, value: number}[] = [];
                if (vehicle?.vehicleRegistrationYear !== undefined && vehicle?.vehicleRegistrationYear > 1888) {
                  yearBuyList = this.getYearsBuyList(vehicle.vehicleRegistrationYear);
                }
                else{
                  yearBuyList = [...this.yearList];
                }


                vehicle.veicleFullDescription = vehicle?.veicleFullDescription ?? "" !== ""
                  ? vehicle?.veicleFullDescription
                  : `${vehicle.vehicleBrandLabel} ${vehicle.vehicleModelLabel}`.trim()

                if(this.state.is4RuoteValid){
                  this.setState({
                    yearBuyList,
                    vehicle,
                    vehicleFittingList: validation?.fittingList ?? []
                  }, () => {
                    // NOTE in questa callback potremmo controllare l'esistenza del fitting nella lista vehicleFittingList e nel caso di assenza aggiungerlo manualmente
                    const autoAdd: boolean = false;

                    if(vehicle?.vehicleFitting && vehicle.vehicleFitting !== "" && vehicle.vehicleFitting !== "##"){
                      this.adjustVeicleInfoByFitting(vehicle?.vehicleFitting)
                        .then((refinedVehicle: IVehicle) => {
                          this.setState({
                            vehicle: {...this.state.vehicle, ...refinedVehicle}
                          }
                          , () => {
                            this.fetchVehicleFittings(true, this.state.vehicle)
                              .then((sortedFittingList: IVehicleFitting[] | null) => {
                                if(sortedFittingList !== null){
                                  this.setState({
                                    vehicleFittingList: sortedFittingList
                                  })
                                }
                              })
                          }
                        )
                      })
                      .catch((err: any) => {
                        // if((this.state.vehicleFittingList ?? []).length === 1){
                        //   this.setState({
                        //     vehicle: {
                        //       ...this.state.vehicle,
                        //       vehicleFitting: (this.state.vehicleFittingList as IVehicleFitting[])[0].id
                        //     }
                        //   })
                        // }
                      })
                    }

                    if(autoAdd &&
                      this.state.vehicle?.vehicleFitting !== undefined &&
                      !(this.state.vehicleFittingList?.find((fitting: IVehicleFitting) => fitting.id === this.state.vehicle?.vehicleFitting) ?? false)
                    ){
                      const addFitting: IVehicleFitting = {
                        id: this.state.vehicle?.vehicleFitting as string,
                        value: this.state.vehicle?.vehicleFitting ?? "",
                        gasoline: 1
                      }

                      this.setState({
                        vehicleFittingList: [...(this.state?.vehicleFittingList ?? []), addFitting]
                      })
                    }
                  });
                }
                else{
                  this.initFromVehicle(vehicle);
                }
              }
            }
          )
        })
        .catch((err: Error) => {

        })
    }
    else {
      state.is4RuoteValid = false;
      state.infoFrom4Ruote = false;

      this.setState(
        state,
        () => {
          if(params.vehicle !== undefined){
            let v: IVehicle;
            if(typeof(params.vehicle) === "string"){
              // ho passato un id
              v = this.props.user.getVehicleById(params.vehicle) ?? {}
            }
            else{
              v = {...params.vehicle}
            }

            this.setState({
              vehicle: v
            }, () => this.initFromVehicle(v))
            
          }
        
      })
    }
  }

  public hide(){
    this.props?.onHideCallback?.();
    this.setState({
      visible: false,
      errors: [],
      errorTexts: [],
      feedbackDialog: false,
      feedbackStatus: "",
      feedbackBody: "",
      knowLicensePlate: undefined,
      is4RuoteValid: undefined,
      infoFrom4Ruote: undefined,
      plateLoading: false,
      carModelList: [],
      fuelTypeList: [],
      engineDisplacementList: [],
      vehicleFittingList: [],
      vehicle: undefined,
      saving: false,
      yearBuyList: undefined
    });
  }

  private submit() {

    this.props.user.upsert("vehicle", this.state?.vehicle ?? {})
      .then((response: any) => {
        if(response?.success === false){
          if(response.error_code === "DUPLICATES_DETECTED"){
            throw new DuplicateException("Veicolo già presente", "generic")
          }

          throw new GenericException("Impossibile completare la richista. Contattare il supporto tecnico", "generic")
        }
        else{
          this.setState({saving: false}, () => {
            this.defaultSuccessCallback(response,  this.props.successCallback);
          });
        }
      })
      .catch((error: any) => {
        this.setState({saving: false}, () => {
          if(error instanceof DuplicateException){
            this.defaultErrorCallback(error)
          }
          else {
            this.defaultErrorCallback();
          }
        });
      })

    // UserService.call(
    //   WEB_SITE_API_SERVER_URL("/vehicles/upsert"),
    //   {
    //     method: "POST",
    //     body: JSON.stringify(this.state.vehicle),
    //   },
    //   (response: any) => {
    //       if(response?.success === false){
    //         if(response.error_code === "DUPLICATES_DETECTED"){
    //           throw new DuplicateException("Veicolo già presente", "generic")
    //         }

    //         throw new GenericException("Impossibile completare la richista. Contattare il supporto tecnico", "generic")
    //       }
    //       else{
    //         this.setState({saving: false}, () => {
    //           this.defaultSuccessCallback(response,  this.props.successCallback);
    //         });
    //       }
    //   },
    //   (error: any) => {
    //     this.setState({saving: false}, () => {
    //       if(error instanceof DuplicateException){
    //         this.defaultErrorCallback(error)
    //       } else {
    //         this.defaultErrorCallback();
    //       }
    //     });
    //   }
    // );

  }

  private save(){
    if(this.state.vehicle){
      this.setState({
        saving: true
      }, () => {
        this.validation()
          .then((result: IValidationResponse) => {
            if(result.status){
              this.submit();
            }
            else{
              this.setState({saving: false}, this.defaultErrorCallback);
            }          
          })
      })
    }
  }

  /**
   * Handles various state change events related to the form's components.
   *
   * @param {any} selected The selected object
   * @param {string} componentName The name of the component to handle
   */
  private onChangeHandler(selected: any, componentName: string) {
    let newState: any = {};
    switch (componentName) {
      case "vehicleType":
        if(["auto", "moto"].includes(selected)){

          if((selected === "auto" && this.state.carBrandList.length === 0) ||
          (selected === "moto" && this.state.bikeBrandList.length === 0)){
            
            this.fetchBrandList(selected)
            .then((brandList: AjaxAutocompleteObject[]) => {

              let newState: {} = {
                vehicle: {
                  ...this.state.vehicle,
                  [componentName]: selected,
                  vehicleLicensePlateNumber: "",
                  vehicleBrand: "",
                  vehicleBrandLabel: "",
                  vehicleModel: "",
                  vehicleModelLabel: "",
                  vehicleFitting: "",
                  vehicleBuyYear: 0,
                  vehicleEngineDisplacement: "",
                  vehicleFuelType: "",
                  vehicleAntitheft: "",
                  veicleFullDescription: "",
                  vehicleRegistrationYear: 0,
                  vehicleRegistrationMonth: 0
                },
                carModelList: [],
                engineDisplacementList: [],
                fuelTypeList: [],          
                vehicleFittingList: [],
                yearBuyList: [],
                plateLoading: false,
                infoFrom4Ruote: false,            
              };
            
              this.setState({
                ...newState,
                requiredFields: (selected === "auto")
                  ? this.props.requiredFields.auto
                  : this.props.requiredFields.moto
              });
    
            })
            .catch(err => {
              // invaild vehicleType
              this.setState({
                carBrandList: [],
                bikeBrandList: [],
                // vehicleBrandList: []
              })
            })
          }
          else{
            // ho già la lista fetchta
            
              this.setState({
                vehicle: {
                  ...this.state.vehicle,
                  [componentName]: selected
                },
                requiredFields: (selected === "auto")
                  ? this.props.requiredFields.auto
                  : this.props.requiredFields.moto
              });
          }
        }


        break;

      case "vehicleRegistrationYear":
        let yearBuyList = this.getYearsBuyList(parseInt(selected));
        this.setState({
          errors: [],
          errorTexts: [],
          yearBuyList,
          vehicle:{
            ...this.state.vehicle,      
            [componentName]: selected,
            vehicleBuyYear: undefined,
          }
        }, () => {
          this.fetchModels();
        });
        break;
      case "vehicleRegistrationMonth":                  
        this.setState({
          vehicle: {
            ...this.state.vehicle,
            [componentName]: selected
          }
        }, () => {
          this.fetchModels();
        });
        break;
      case "vehicleBrand":
        this.setState({
          ...this.state,
          carModelList: [],
          vehicleFittingList: [],
          engineDisplacementList: [],
          fuelTypeList: [],
          vehicle: {
            ...this.state.vehicle,
            [componentName]: selected,
            vehicleBrandLabel: (this.state.vehicle?.vehicleType ?? "auto") === "auto"
              ? this.state.carBrandList?.find((brand: AjaxAutocompleteObject) => brand.id === selected)?.value
              : this.state.bikeBrandList?.find((brand: AjaxAutocompleteObject) => brand.id === selected)?.value
          }
        }, () => {
          this.fetchModels();
        });
        break;
      case "vehicleBuyYear":
        this.setState({
          vehicle: {
            ...this.state.vehicle,
            [componentName]: selected
          }
        }, () => {
          this.validation("vehicleBuyYear");
        })
        break;

      case "vehicleModel":
        this.setState({
          vehicle: {
            ...this.state.vehicle,
            [componentName]: selected,
            vehicleModelLabel: this.state.carModelList?.find((model: AjaxAutocompleteObject) => model.id === selected)?.value
          }
        }, () => {
          if(this.state.vehicle?.vehicleType === "auto"){
            this.fetchFuelType();
          }
          else if(this.state.vehicle?.vehicleType === "moto"){
            this.fetchVehicleFittings();
          }
        });
        break;
      case "vehicleFuelType":
        let data: any = {
          vehicleFuelType: selected
        };
        if(selected === "E"){
          data.vehicleGplMounted = false;
        }

        this.setState({
          vehicle: {
            ...this.state.vehicle,
            ...data
          },
        }, () => {
            this.fetchEngineDisplacement()
          }
        );
        break;

      case "vehicleEngineDisplacement":
        this.setState({
          vehicle: {  
            ...this.state.vehicle,
            [componentName]: selected,
          }}
          ,
          () => {
            this.fetchVehicleFittings();
          }
        );
        break;

      case "vehicleFitting":
        this.setState({
          vehicle: {
            ...this.state.vehicle,
            [componentName]: selected,
            vehicleFittingLabel: this.state.vehicleFittingList?.find((fitting: AjaxAutocompleteObject) => fitting.id === selected)?.value
          }
        }, () => {
          if (this.state.infoFrom4Ruote) {
            this.fetchVeicleInfoByFitting(); // carico le restanti info del veicolo dal dal DB
          }
        });
        break;
      case "knowLicensePlate":        
        if (selected === false) {
          newState = {
            vehicle: {
              ...this.state.vehicle,
              vehicleLicensePlateNumber: "",
              vehicleBrand: "",
              vehicleBrandLabel: "",
              vehicleModel: "",
              vehicleModelLabel: "",
              vehicleFitting: "",
              vehicleBuyYear: 0,
              vehicleEngineDisplacement: "",
              vehicleFuelType: "",
              vehicleAntitheft: "",
              veicleFullDescription: "",
              vehicleRegistrationYear: 0,
              vehicleRegistrationMonth: 0
            },            
            engineDisplacementList: [],
            fuelTypeList: [],
            carModelList: [],
            vehicleFittingList: [],
            plateLoading: false,
            infoFrom4Ruote: false
          };
        }                
        
        this.setState({
          ...newState,
          knowLicensePlate: selected
        });
        break;
    
      case "vehicleOwned":
        this.setState({vehicle: {...this.state.vehicle, [componentName]: selected } }, () => {
          if (this.state.vehicle?.vehicleOwned === false) {
            this.setState({
              knowLicensePlate: true
            });
          }          
        });                            
        break;
      case "vehicleLicensePlateNumber":        
        this.setState({vehicle: {...this.state.vehicle, [componentName]: selected.toUpperCase() }})
        break;

      default:
        this.setState({
          vehicle: {
            ...this.state.vehicle,
            [componentName]: selected
          }
        });
        break;
    }

    this.resetValidation(componentName);    
  }

  /**
   * Reset form element validation
   * @param element
   * @returns
   */
  resetValidation(element: any) {
    if (element === "knowLicensePlate") {
      this.setState({errors: [], errorTexts: []})
    }
    else {
      this.setState({
        errors: this.state.errors.filter((item: string) => item !== element),
        errorTexts: this.state.errorTexts.filter((item: IFeedbackText) => item.field !== element),
      });
    }
  }

  private async validation(specificField: undefined | string = undefined): Promise<{
    status: boolean;
    error_fields: string[];
    error_messages: IFeedbackText[];
  }>{

    let fieldsToValidate : IFieldDescriptor[];
    if(specificField !== undefined){
      fieldsToValidate = [this.state.requiredFields?.[specificField]];
    }
    else{
      fieldsToValidate = this.state.requiredFields;
    }

    return User.validateVehicle(
      this.state.vehicle as IVehicle,
      Object.entries(fieldsToValidate).map(([, value]) => value) as IFieldDescriptor[],
    )
      .then((result: {
        status: boolean;
        error_fields: string[];
        error_messages: IFeedbackText[];
      }) => {
        const {error_fields, error_messages} = result;
        this.setState({errors: error_fields, errorTexts: error_messages})
        return result;
      });
  }

  // Visualizzo il campo "Inserisci la targa" se sono owner/proprietario dell'auto o se ho selezionato il campo conosco la targa!!!
  /**
   * License plate form field visibility check.
   *
   * @returns {boolean} whether the license plate form field is visible
   */
  private isVisibleLicensePlate() {
    let is_visible;
    if (this.state?.vehicle?.vehicleOwned) {
      is_visible = true;
    } else {
      if (this.state.knowLicensePlate === undefined) {
        is_visible = false;
      } else {
        is_visible = this.state.knowLicensePlate ? true : false;
      }
    }

    return is_visible;
  }

  // Visualizza il button "conosco la targa/non conosco la targa" sulla base della selezione "già di proprieta/ancora da acquistare"
  // Se l auto è di propriertà visuallizzo "inserisci targa"
  /**
   * Know license plate form field visibility check.
   *
   * @returns {boolean} whether the "know license plate" question in the form should be visible
   */
  private isVisibleKnowLicensePlate() {
    let is_visible;
    if (this.state?.vehicle?.vehicleOwned === undefined) {
      is_visible = false;
    } else {
      is_visible = this.state?.vehicle?.vehicleOwned ? false : true;
    }
    return is_visible;
  }

  /**
   * Check if current field is viewable.
   *
   * @param {string} current_field The current field
   *
   * @returns {boolean} Visibility check
   */
   private isVisible(current_field: string): boolean {
    let is_visible;

    switch (current_field) {
      // case "auto":
      //   is_visible = this.state?.vehicle?.vehicleType === "auto";
      //   break;
      // case "moto":
      //   is_visible = this.state?.vehicle?.vehicleType === "moto";
      //   break;
      case "knowLicensePlate":
        is_visible = this.isVisibleKnowLicensePlate();
        break;
      case "vehicleLicensePlateNumber":
        is_visible = this.isVisibleLicensePlate();
        break;
      default:
        is_visible = false;
    }

    return is_visible;
  }

  /**
   * 
   * @param vehicleType auto | moto
   * @returns lista dei brand per il tipo di veicolo selezionato
   */
  fetchBrandList(vehicleType: string = "auto"): Promise<AjaxAutocompleteObject[]>{
    if (["auto", "moto"].includes(vehicleType)) {
    
      let brandList : AjaxAutocompleteObject[] = [];
      if(vehicleType === "auto"){
        brandList = this.state.carBrandList;
      }
      else if(vehicleType === "moto"){
        brandList = this.state.bikeBrandList;
      }
      
      if(brandList.length === 0){
        const url = vehicleType === "moto"
          ? Constants.SEISICURO_AJAX_MOTO_BRAND_ENDPOINT
          : Constants.SEISICURO_AJAX_CAR_BRAND_ENDPOINT;
  
        return fetchList(url, undefined, undefined, undefined, this.abortController.signal)
          .then((result: AjaxAutocompleteObject[]) => {
            result.sort((a: any, b: any) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;
              return 0;
            });

            this.setState({
              // vehicleBrandList: result,
              carBrandList: vehicleType === "auto" ? result : this.state.carBrandList,
              bikeBrandList: vehicleType === "moto" ? result : this.state.bikeBrandList
            });

            return result;
          });
      }
      else{
        return Promise.resolve(brandList)
      }
    }
    else{
      throw new Error("invalid vehicleType");
    }   
  }

  /**
   * Resetta i campi della form legati al veicolo e cerca i modelli in base al anno / mese di immatricolazione e la marca
   * (aggiorna la lista / tendina dei modelli disponibili)
   * Inoltre si occupa di resettare la lista degli anni in cui il veicolo è acquistabile
   */
  // sostituire flag isPageLoad con clearDependencies
  fetchModels(preserveDependencies: boolean = false, v?: IVehicle) : Promise<null | AjaxAutocompleteObject[]> {

    let newState = {};
    if (!preserveDependencies) {
      newState = {
        vehicle:{
          ...this.state.vehicle,
          vehicleModel: undefined,
          vehicleModelLabel: undefined,
          vehicleFuelType: undefined,
          vehicleEngineDisplacement: undefined,
          vehicleFitting: undefined,
          vehicleFittingLabel: undefined,
          veicleFullDescription: undefined
        },
        carModelList: [],
        fuelTypeList: [],
        engineDisplacementList: [],
        vehicleFittingList: [],
      };
    }

    let vehicle: IVehicle;
    if(v !== undefined){
      vehicle = v;
    }
    else{
      vehicle = this.state.vehicle ?? {};
    }

    if (
      vehicle?.vehicleRegistrationYear !== undefined &&
      vehicle?.vehicleRegistrationMonth !== undefined &&
      vehicle?.vehicleBrand !== undefined && vehicle?.vehicleBrand !== ""
    ) {
      let url: string;
      if (vehicle?.vehicleType === "moto") {
        url = Constants.SEISICURO_AJAX_MOTO_MODEL_ENDPOINT(
          vehicle.vehicleRegistrationYear,
          vehicle.vehicleRegistrationMonth,
          vehicle.vehicleBrand
        );
      }
      else{
        url = Constants.SEISICURO_AJAX_CAR_MODEL_ENDPOINT(
          vehicle.vehicleRegistrationYear,
          vehicle.vehicleRegistrationMonth,
          vehicle.vehicleBrand
        )
      }
      
      return fetchList(url, undefined, undefined, undefined, this.abortController.signal)
        .then((result: AjaxAutocompleteObject[]) => {
          let sortedModels = result.sort((a: any, b: any) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });

          this.setState({
            ...newState,
            carModelList: sortedModels
          });

          if (!preserveDependencies && sortedModels.length === 1) {
            this.onChangeHandler(sortedModels[0].id, "vehicleModel");
          }

          return sortedModels;
        })
    }
    else{
      return new Promise(() => {this.setState(newState); return null})
    }

  }

  // NOTE fetch only if vehicleType is "auto"
  fetchFuelType(preserveDependencies: boolean = false, v?: IVehicle) : Promise<null | AjaxAutocompleteObject[]> {
    // if (this.state.infoFrom4Ruote) {
    // //  return false;
    // }

    let newState = {};
    if (!preserveDependencies) {
      newState = {
        vehicle:{
          ...this.state.vehicle,
          vehicleEngineDisplacement: undefined,
          vehicleFitting: undefined,
          vehicleFittingLabel: undefined,
          veicleFullDescription: undefined
        },
        engineDisplacementList: [],
        vehicleFittingList: [],
      }
    }

    let vehicle: IVehicle;
    if(v !== undefined){
      vehicle = v;
    }
    else{
      vehicle = this.state.vehicle ?? {};
    }

    if (
      vehicle?.vehicleRegistrationYear !== undefined &&
      vehicle?.vehicleRegistrationMonth !== undefined &&
      vehicle?.vehicleBrand !== undefined &&
      vehicle?.vehicleModel !== undefined    
    ) {
      
      const url: string = Constants.SEISICURO_AJAX_CAR_FUEL_TYPE_ENDPOINT(
        vehicle?.vehicleRegistrationYear,
        vehicle?.vehicleRegistrationMonth,
        vehicle?.vehicleBrand,
        vehicle?.vehicleModel
      );

      return fetchList(url, undefined, undefined, undefined, this.abortController.signal)
        .then((result: AjaxAutocompleteObject[]) => {
          let sortedFuelTypes = result.sort((a: AjaxAutocompleteObject, b: AjaxAutocompleteObject) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });

          this.setState({
            ...newState,
            fuelTypeList: sortedFuelTypes
          });

          if (!preserveDependencies && sortedFuelTypes.length === 1) {
            this.onChangeHandler(sortedFuelTypes[0].id, "vehicleFuelType");
          }

          return sortedFuelTypes;
        })
    }
    else{
      return new Promise(() => {this.setState(newState); return null})
    }
  }

  // NOTE fetch only if vehicleType is "auto"
  fetchEngineDisplacement(preserveDependencies: boolean = false, v?: IVehicle) : Promise<null | AjaxAutocompleteObject[]> {

    let newState = {};
    if (!preserveDependencies) {
      newState = {
        vehicle:{
          ...this.state.vehicle,
          vehicleFitting: undefined,
          vehicleFittingLabel: undefined,
          veicleFullDescription: undefined
        },
        vehicleFittingList: []
      }
    }

    let vehicle: IVehicle;
    if(v !== undefined){
      vehicle = v;
    }
    else{
      vehicle = this.state.vehicle ?? {};
    }

    if (
      vehicle?.vehicleRegistrationYear !== undefined &&
      vehicle?.vehicleRegistrationMonth !== undefined &&
      vehicle?.vehicleBrand !== undefined &&
      vehicle?.vehicleModel !== undefined &&
      vehicle?.vehicleFuelType !== undefined
    ) {

      const url : string = Constants.SEISICURO_AJAX_CAR_ENGINE_DISPLACEMENT_ENDPOINT(
        vehicle?.vehicleRegistrationYear,
        vehicle?.vehicleRegistrationMonth,
        vehicle?.vehicleBrand,
        vehicle?.vehicleModel,
        vehicle?.vehicleFuelType
      )

      return fetchList(url, undefined, undefined, undefined, this.abortController.signal)
        .then((result: AjaxAutocompleteObject[]) => {
          let sortedEngineDisplacement = result.map((element: AjaxAutocompleteObject) => {
              return { ...element, value: element.value.trim() };
            })
            .sort((a: AjaxAutocompleteObject, b: AjaxAutocompleteObject) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;
              return 0;
            });

          this.setState({
            ...newState,
            engineDisplacementList: sortedEngineDisplacement
          })

          if (!preserveDependencies && sortedEngineDisplacement.length === 1) {
            this.onChangeHandler(sortedEngineDisplacement[0].id, "vehicleEngineDisplacement");
          }

          return sortedEngineDisplacement;
        });
    }
    else{
      return new Promise(() => {this.setState(newState); return null;})
    }
  }

  fetchVehicleFittings(preserveDependencies: boolean = false, v?: IVehicle) : Promise<null | IVehicleFitting[]>{
    // if (this.state.infoFrom4Ruote) {
    //   return false;
    // }

    let newState = {};
    if (!preserveDependencies) {
      newState = {
        vehicle:{
          ...this.state.vehicle,
          veicleFullDescription: undefined
        },
      }
    }

    let vehicle: IVehicle;
    if(v !== undefined){
      vehicle = v;
    }
    else{
      vehicle = this.state.vehicle ?? {};
    }

    let url : string = "";
    let validation: boolean;
    if (vehicle?.vehicleType === "moto") {
      validation = vehicle?.vehicleRegistrationYear !== undefined &&
      vehicle?.vehicleRegistrationMonth !== undefined &&
      vehicle?.vehicleBrand !== undefined &&
      vehicle?.vehicleModel !== undefined;

      if(validation){
        url = Constants.SEISICURO_AJAX_MOTO_FITTING_ENDPOINT(
          vehicle.vehicleRegistrationYear as number,
          vehicle.vehicleRegistrationMonth as number,
          vehicle.vehicleBrand as string,
          vehicle.vehicleModel as string
        )
      }
    }
    else{
      validation = vehicle?.vehicleRegistrationYear !== undefined &&
      vehicle?.vehicleRegistrationMonth !== undefined &&
      vehicle?.vehicleBrand !== undefined &&
      vehicle?.vehicleModel !== undefined &&
      vehicle?.vehicleFuelType !== undefined &&
      vehicle?.vehicleEngineDisplacement !== undefined;

      if(validation){
        url = Constants.SEISICURO_AJAX_CAR_FITTING_ENDPOINT(
          vehicle?.vehicleRegistrationYear as number,
          vehicle?.vehicleRegistrationMonth as number,
          vehicle?.vehicleBrand as string,
          vehicle?.vehicleModel as string,
          vehicle?.vehicleFuelType as string,
          vehicle?.vehicleEngineDisplacement as string
        )
      }
    }

    if(validation && url){
      return fetchList(url, undefined, undefined, undefined, this.abortController.signal)
        .then((result: IVehicleFitting[]) => {
          let sortedFittings = result.sort((a: IVehicleFitting, b: IVehicleFitting) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });
          this.setState({
            ...newState,
            vehicleFittingList: sortedFittings
          });
          if (!preserveDependencies && sortedFittings.length === 1) {
            this.onChangeHandler(sortedFittings[0].id, "vehicleFitting");
          }

          return sortedFittings;
        })
    }
    else{
      return new Promise(() => {this.setState(newState); return null;})
    }
  }

  // setta le label brand, model, fulldescription e avvalora i rispettivi campi di vehicleBrand, vehicleModel, vehicleEngineDisplacement, vehicleFuelType, vehicleFitting, vehicleGplMounted
  // i campi vehicleBrand, vehicleModel, vehicleEngineDisplacement, vehicleFuelType, vehicleFitting, vehicleGplMounted potrebbero già essere stati settati
  fetchVeicleInfoByFitting(vehicleFittingId?: string, v?: IVehicle) {

    let vehicle: IVehicle;
    if(v !== undefined){
      vehicle = v;
    }
    else{
      vehicle = this.state.vehicle ?? {};
    }

    if (
      vehicle?.vehicleRegistrationYear !== undefined &&
      vehicle?.vehicleRegistrationMonth !== undefined &&
      (vehicle?.vehicleFitting !== undefined || vehicleFittingId !== undefined)
    ) {
      let requestFittingId = vehicleFittingId ?? vehicle?.vehicleFitting ?? "";

      this.adjustVeicleInfoByFitting(requestFittingId)
        .then((v: IVehicle) => {
          this.setState({
            vehicle: {
              ...vehicle,
              ...v,
              vehicleGplMounted: v.vehicleFuelType === "E" ? false : vehicle?.vehicleGplMounted
            },
            plateLoading: false,
            infoFrom4Ruote: true,
          });
        })
        .catch((erro) => {
          this.setState(
            {
              plateLoading: false,
              infoFrom4Ruote: false,
            }
          );
        });
    }
  }


  /**
   * Il metodo espande il set dei dati del veicolo sulla base dell'id dell'allestimenti
   * nel caso si provenga da dati veicolo cachati aggiugnerà le label
   * nel caso si provenda da dati di 4 ruote recupererà label e gli id di brand e modello, alimentazione, cilindrata
   * @param fittingId 
   * @returns 
   */
  adjustVeicleInfoByFitting(fittingId: string): Promise<IVehicle> {
    const [mese, anno, codAll] = fittingId.split("#");

    const url =
    this.state.vehicle?.vehicleType === "auto"
        ? Constants.SEISICURO_FETCF_DATI_VEICOLO(mese, anno, codAll)
        : Constants.SEISICURO_FETCF_DATI_VEICOLO_MOTO(mese, anno, codAll);

    return fetch(url, {
      signal: this.abortController.signal,
      credentials: "include",
      headers: {
        /** AUTH_REDACTED */
      },
    })
      .then((response) => response.json())
      .then((result) => {
        let dataToDispatch: IVehicle = {};

        return dataToDispatch = {
          vehicleBrand: result.codmar,
          vehicleBrandLabel: result.desmar,
          vehicleModel: result.codmod,
          vehicleModelLabel: result.desmod,
          vehicleEngineDisplacement: result.cilind,
          vehicleFitting: fittingId,
          vehicleFittingLabel: result.desall,
          vehicleFuelType: result.tipcom,
          veicleFullDescription: `${result.desmar} ${result.desmod}`,
        }
      })
  }

  /**
   * contatta il nodo per recuperare le informazioni della targa
   * se la targa è stata già ricercata verrà restituita una versione cachata del data dal DB
   * se la targa non è mai stata ricercata verrà contattato il servizio targa diRuote 
   * 
   * @param targa 
   * @param vehicleType 
   * @returns 
   */
  private fetchInfo(targa: string, vehicleType: string = "auto"): Promise<any>{

    const url = vehicleType === "auto"
      ? Constants.SEISICURO_FETCF_DATI_4RUOTE()
      : Constants.SEISICURO_FETCF_DATI_4RUOTE_MOTO();

    return postData(url, { parametro: { Targa: targa } }, this.abortController.signal)
      .then((result) => result.json());
  }

  /**
   * 
   * @param targa 
   * @param vehicle 
   * @param returnFittingList 
   * @returns 
   */
  is4RoutePlate(targa: string, vehicle: IVehicle, returnFittingList: boolean = true): Promise<{is4RuoteValid: boolean, infoFrom4Ruote: boolean, fittingList?: IVehicleFitting[]}>{

    if(!["auto", "moto"].includes(vehicle?.vehicleType ?? "")){
      throw new Error("Vehicle type not provided");
    }
      
    return this.fetchInfo(targa, vehicle?.vehicleType)
      .then(async (data) => {

        let validation: {is4RuoteValid: boolean, infoFrom4Ruote: boolean, fittingList?: IVehicleFitting[]};

        let data4Ruote = data.infobikeDaTarga !== undefined
            ? data.infobikeDaTarga
            : data.infocarDaTarga;
                          
        // 03/02/2023 il servizio targa 4Ruote/2Ruote cambia la struttra del JSON
        // adesso prevede che le moto possano avere diversi allestimenti e li ritorna nel campo
        // listaMotoMultipliElementi

        let listaAllestimenti : undefined | any[];

        if(data.infobikeDaTarga !== undefined){
          // moto
          listaAllestimenti = data4Ruote?.lista !== undefined
            ? data4Ruote.lista
            : data4Ruote?.listaMotoMultipliElementi?.lista ?? []
        }
        else{
          //auto
          listaAllestimenti = data4Ruote?.lista
        }

        if (
          (data.ErroreInterno === undefined || data.ErroreInterno === "") &&
          (data.cached !== undefined || (data4Ruote !== undefined && listaAllestimenti !== undefined))
        ) {

          if (data.cached !== undefined) {
            // versione letta dal DB
            let cached = data.cached;
            if (cached.codmar && cached.codmod && cached.cilind && cached.desmar && cached.desmod) {

              validation = {
                is4RuoteValid: true,
                infoFrom4Ruote: true
              }
              
              if(returnFittingList){
                validation.fittingList = [];
                if(vehicle?.vehicleFitting){
                  let expandedCachedData: IVehicle = await this.adjustVeicleInfoByFitting(vehicle?.vehicleFitting)
                  validation.fittingList = await this.fetchVehicleFittings(true, {...vehicle, ...expandedCachedData}) ?? []
                }
              }
            }
            else {
              validation = {
                is4RuoteValid: false,
                infoFrom4Ruote: false
              }
            }
          }
          else {
            // versione letta da 4Ruote

            validation = {
              is4RuoteValid: true,
              infoFrom4Ruote: true
            }
              
            if(returnFittingList){
              validation.fittingList = this.refine4RuoteVehicleFittings(Array.isArray(listaAllestimenti)
                ? listaAllestimenti
                : [listaAllestimenti]
              )
            }
          }
        } 
        else {
          validation = {
            infoFrom4Ruote: false,
            is4RuoteValid: false
          }
        }
        return validation;
      })
      .catch(err => {
        return {
          is4RuoteValid: false,
          infoFrom4Ruote: false
        }
      })
  }

  /**
   * Data la targa recupera le informazioni sul veicolo da 4Ruote
   * viene chiamata solo quando cambia la targa
   */
  fetchVeicleInfo4Ruote() {
    if (!this.state.vehicle?.vehicleLicensePlateNumber ||
      (this.state.vehicle?.vehicleLicensePlateNumber === undefined) ||
      (this.state.vehicle?.vehicleLicensePlateNumber === this.oldLicensePlateValue)) {
        return
    }
    else if(this.state.vehicle?.vehicleLicensePlateNumber === ""){
      this.setState({
        errors: this.state.errors.filter((err: string) => err !== "vehicleLicensePlateNumber"),
        errorTexts: this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "vehicleLicensePlateNumber")
      })
      return
    }

    this.setState(
      { plateLoading: true},
      () => {

        const licensePlateErrorMessage =
          this.state.vehicle?.vehicleType === "moto"
            ? "Non è stato possibile recuperare i dati della moto."
            : "Non è stato possibile recuperare i dati dell'auto."

        this.validation("vehicleLicensePlateNumber")
          .then((result: IValidationResponse) => {

            const {status} = result;
            
            if(!status){
              let err = new Error("Targa non valida");
              err.name = "VAL_PLATE_ERR";
              throw err;
            }
            
            this.setState(
              {infoFrom4Ruote: true },
              () => {
                
                const targa: string = this.state.vehicle?.vehicleLicensePlateNumber as string;

                this.fetchInfo(targa, this.state.vehicle?.vehicleType)
                  .then((data) => {
                    let data4Ruote = data.infobikeDaTarga !== undefined
                      ? data.infobikeDaTarga
                      : data.infocarDaTarga;
                    let currentYear = new Date().getFullYear();

                    let listaAllestimenti : undefined | any[];
                          
                    // 03/02/2023 il servizio targa 4Ruote/2Ruote cambia la struttra del JSON
                    // adesso prevede che le moto possano avere diversi allestimenti e li ritorna nel campo
                    // listaMotoMultipliElementi

                    if(data.infobikeDaTarga !== undefined){
                      // moto
                      listaAllestimenti = data4Ruote?.lista !== undefined
                        ? data4Ruote.lista
                        : data4Ruote?.listaMotoMultipliElementi?.lista ?? []
                    }
                    else{
                      //auto
                      listaAllestimenti = data4Ruote?.lista
                    }
      
                    if (
                      (data.ErroreInterno === undefined || data.ErroreInterno === "") &&
                      (data.cached !== undefined || (data4Ruote !== undefined && listaAllestimenti !== undefined))
                    ) {
                      if (data.cached !== undefined) {
                        // versione letta dal DB
                        this.isCachedValue = true;
      
                        let cached = data.cached;
      
                        let dateParts = cached.dataImmatricolazione.split("/"); // -- FORMATO MM/gg/YYYY
                        let testDate = moment(dateParts[2] + "-" + dateParts[0] + "-" + dateParts[1]);
                        let dateObj = testDate.toDate();
      
                        let yearBuyList : any[] = [];
                        for (let i = dateObj.getFullYear(); i <= currentYear; i++) {
                          yearBuyList.push({ label: i.toString(), value: i });
                        }
      
                        if (cached.codmar && cached.codmod && cached.cilind && cached.desmar && cached.desmod && cached.codall) {


                          this.adjustVeicleInfoByFitting(cached.codall)
                            .then((refinedVehicle: any) => {

                                this.setState(
                                  {
                                    errors: [...this.state.errors.filter((err: string) => err !== "vehicleLicensePlateNumber")],
                                    errorTexts: [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "vehicleLicensePlateNumber")],
                                    vehicle:{
                                      ...this.state.vehicle,
                                      ...refinedVehicle,
                                      vehicleRegistrationYear: dateObj.getFullYear(),
                                      vehicleRegistrationMonth: dateObj.getMonth() + 1,
                                    },
                                    carModelList: [],
                                    fuelTypeList: [],
                                    engineDisplacementList: [],
                                    vehicleFittingList: [],
                                    is4RuoteValid: true,
                                    yearBuyList: yearBuyList,
                                    plateLoading: false
                                  },
                                  () => {
                                    // la richiesta cachata non ritorna la lista degli allestimenti che diversamente viene ritornata nel caso di ruote
                                    this.fetchVehicleFittings(true)
                                      .then((fittingList: IVehicleFitting[] | null) => {
                                        if(fittingList !== null){
                                          this.setState({
                                            vehicle:{
                                              ...this.state.vehicle,
                                              vehicleFittingLabel: fittingList.find((fitting: IVehicleFitting) => fitting.id === this.state.vehicle?.vehicleFitting)?.value
                                            }
                                          }, () => {

                                            if(fittingList.length > 0){
                                              this.fetchVeicleInfoByFitting((this.state.vehicle?.vehicleFitting as string))
                                            }

                                          })
                                        }
                                      })
                                  }
                                );

                            })
                        }
                        else {
                          this.setState({yearBuyList: yearBuyList}, () => {
                            let err = new Error("Targa non riconosciuta");
                            err.name = "VAL_PLATE_UNRECOGNIZED";
                            throw err;
                          });
                        }
                      }
                      else {
                        // versione letta da 4Ruote
                        this.isCachedValue = false;
      
                        let dateParts = data4Ruote.dataImmatricolazione.split("/"); // -- FORMATO MM/gg/YYYY
                        let testDate = moment(
                          dateParts[2] + "-" + dateParts[0] + "-" + dateParts[1]
                        );
                        let dateObj = testDate.toDate();
      
                        const vehicle4RuoteFittingList: IVehicleFitting[] = this.refine4RuoteVehicleFittings((Array.isArray(listaAllestimenti)
                          ? listaAllestimenti
                          : [listaAllestimenti]));
      
                        let yearBuyList : any[]= [];
                        for (let i = dateObj.getFullYear(); i <= currentYear; i++) {
                          yearBuyList.push({ label: i.toString(), value: i });
                        }
      
                        this.setState(
                          {
                            yearBuyList: yearBuyList,
                            vehicle: {
                              ...this.state.vehicle,
                              vehicleRegistrationYear: dateObj.getFullYear(),
                              vehicleRegistrationMonth: dateObj.getMonth() + 1,
                              vehicleBrand: undefined,
                              vehicleBrandLabel: undefined,
                              vehicleModel: undefined,
                              vehicleModelLabel: undefined,
                              vehicleFuelType: undefined,
                              vehicleEngineDisplacement: undefined,
                              vehicleFitting: undefined,
                              vehicleFittingLabel: undefined,
                              veicleFullDescription: undefined,
                            },
                            carModelList: [],
                            fuelTypeList: [],
                            engineDisplacementList: [],
                            vehicleFittingList: vehicle4RuoteFittingList,
                            is4RuoteValid: true,
                          },
                          () => {
                            const fittingList : IVehicleFitting[] = this.state?.vehicleFittingList ?? [];
                            
                            if(fittingList.length > 0){
                              this.fetchVeicleInfoByFitting(fittingList[0].id)

                              this.setState({
                                vehicle:{
                                  ...this.state.vehicle,
                                  vehicleFittingLabel: fittingList[0].value
                                }
                              })
                            }
                          }
                        );
                      }
                    } 
                    else {
                      let err = new Error("Targa non riconosciuta");
                      err.name = "VAL_PLATE_UNRECOGNIZED";
                      throw err;
                    }
                  })
                  .catch((erro) => {
                    // let err = new Error("Targa non riconosciuta");
                    // err.name = "VAL_PLATE_UNRECOGNIZED";
                    // throw err;

                    let resettedState: any = {
                      plateLoading: false,
                      infoFrom4Ruote: false,
                      is4RuoteValid: false,
                      errors: ["vehicleLicensePlateNumber"],
                      carModelList: [],
                      fuelTypeList: [],
                      engineDisplacementList: [],
                      vehicleFittingList: [],
                      vehicle: {
                        ...this.state.vehicle,
                        vehicleBrand: undefined,
                        vehicleBrandLabel: undefined,
                        vehicleModel: undefined,
                        vehicleModelLabel: undefined,
                        vehicleFuelType: undefined,
                        vehicleEngineDisplacement: undefined,
                        vehicleFitting: undefined,
                        vehicleFittingLabel: undefined,
                        veicleFullDescription: undefined,
                        vehicleBuyYear: undefined,
                        vehicleRegistrationMonth: 0,
                        vehicleRegistrationYear: 0,
                      },
                      errorTexts: [{ field: "vehicleLicensePlateNumber", msg: licensePlateErrorMessage, severity: "warning"}]
                    }
                    this.setState(resettedState);
                  });
              }
            );
          })
          .catch((err: Error) => {

            let resettedState: any = {
              plateLoading: false,
              infoFrom4Ruote: false,
              is4RuoteValid: false,
              errors: ["vehicleLicensePlateNumber"]
            }
            
            if(err.name === "VAL_PLATE_ERR"){
              resettedState.errorTexts = [{ field: "vehicleLicensePlateNumber", msg: licensePlateErrorMessage, severity: "warning"}];
            }
            else if(err.name === "VAL_PLATE_UNRECOGNIZED"){
              resettedState.carModelList = [];
              resettedState.fuelTypeList = [];
              resettedState.engineDisplacementList = [];
              resettedState.vehicleFittingList = [];
              resettedState.vehicle = {
                ...this.state.vehicle,
                vehicleBrand: undefined,
                vehicleBrandLabel: undefined,
                vehicleModel: undefined,
                vehicleModelLabel: undefined,
                vehicleFuelType: undefined,
                vehicleEngineDisplacement: undefined,
                vehicleFitting: undefined,
                vehicleFittingLabel: undefined,
                veicleFullDescription: undefined,
                vehicleBuyYear: undefined,
                vehicleRegistrationMonth: 0,
                vehicleRegistrationYear: 0,
              };
              resettedState.errorTexts = [{ field: "vehicleLicensePlateNumber", msg: licensePlateErrorMessage, severity: "warning"}]
            }

            this.setState(resettedState, () => {
              if(err.name === "VAL_PLATE_ERR"){
                this.fetchModels(true);
                this.fetchFuelType(true);
                this.fetchEngineDisplacement(true);
                this.fetchVehicleFittings(true);
              }
            });
          });
      
    })
  }

  /**
   * Trasforma la risposta di 4ruote in allestimenti 6sicuro.
   * Si aspetta una lista del tipo
   *
   * list: [
   *  0: {
   *    anno: "2020"
   *    categoria: "A"
   *    codiceInfocar: "135376"   // codiceInfobike
   *    codiceInfocarAM: "202002135376" // codiceInfobikePRG
   *    descrizione: "Yaris 1.5 Hybrid 5 porte Business"
   *    descrizioneCompleta: "TOYOTA Yaris 1.5 Hybrid 5 porte Business"
   *    fineImmatricolazione: "000000"
   *    fineVendita: "000000"
   *    inizioImmatricolazione: "202002"
   *    inizioVendita: "202002"
   *    mese: "02"
   *  }, .....
   * ]
   *
   * @param respose4Ruote la lista dei allestimenti provenienti da 4ruote
   * @returns una lista di allestimenti nel formato 6sicuro (in cui il parametro gsoline è ininfluente),
   *  del tipo: [ { gasoline: 1, id: "9#2019#125514", value: "Berlingo PureTech 110 Stop&Start M Live ... " }, ... ]
   */
  private refine4RuoteVehicleFittings(respose4Ruote: I4RuoteFitting[]): IVehicleFitting[] {
    return respose4Ruote.map((ele: I4RuoteFitting) => {

      ele?.datoAllestimentoTargaBike && (ele = ele.datoAllestimentoTargaBike);

      let mese = ele.mese !== undefined
        ? parseInt(ele.mese, 10)
        : "0";

      let anno = ele.anno !== undefined
          ? parseInt(ele.anno, 10)
          : parseInt(ele?.progressivoAllestimento ?? "0", 10); // aggiungo ?? "0" solo per non avere errore di sintassi

      let code = ele.codiceInfocar !== undefined
          ? ele.codiceInfocar
          : ele?.codiceInfobike ?? "0"; // aggiungo ?? "0" solo per non avere errore di sintassi

      let descr = ele.descrizione !== undefined
          ? ele.descrizione
          : ele?.descrizione ?? "0"; // aggiungo ?? "0" solo per non avere errore di sintassi

      return {
        gasoline: 1,
        id: `${mese}#${anno}#${code}`,
        value: descr,
      };
    });
  }

  private dialogScrollToTop() {
    this.dialogRef?.current.scrollIntoView({         
      behavior: "smooth"      
    })        
  }
}

export const VehicleCard = forwardRef((props: any, ref: ForwardedRef<VehicleCardComponent>) => {
  const lists: IDataList = useContext(DataList);

  return <VehicleCardComponent {...props} ref={ref} {...lists} setResourceListRequest={props.setResourceListRequest} />
})