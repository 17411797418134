import React, { useContext } from "react";
import { Component } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import {step3DataFailure, step3DataReset, step3DataSuccess, step4DataSuccess, userInfoDataSuccess } from "../redux/actions";
import * as Constants from "./utils/constants";
import { AjaxAutocompleteObject, IAccidentInfo, IFieldDescriptor, IPerson, Step1Data, Step2Data, Step3Data, Step4Data } from "./utils/common";
import { fetchAutocomplete, fetchList, getDrivingLicenseAgeOptions,  getNumberList, fixDirtyBackendErrorString, formatDateAdform, formataDateToString, isLikelyDate, sortJson, getJsonValues, encryptEmail, extractAddressData, removeFocusClass, replaceSpecialChars, getValidInsuranceStart, offsetFirstErrorField
} from "./utils/methods";
import { isLicensePlateAutoValid, isLicensePlateMotoValid } from "./utils/validate";

import { useHistory } from "react-router-dom";
import User, { IValidationResponse } from "./utils/User";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import { PersonFieldsBuilder, Step3ContractorBuilder, Step3DriverFieldsBuilder, Step3OwnerFieldsBuilder } from "./utils/PersonFieldsBuilder";
import Address from "./customs/Address";
import InputDate from "./customs/InputDate";
import { isStreetAddres } from './utils/validate';
import { StepDataDualState } from "../redux/reducers";
import { connect } from "react-redux";
import { IWithEventManager } from "./customs/HOC/EventManager";
import { compose } from "ramda";
import withEventManager from "./customs/HOC/withEventManager";
import { DataList, IDataList } from "./customs/dataListContext";
import moment from "moment";
import CaptchaComponent from "./captcha/Captcha";


interface IStep3Props extends IWithEventManager, IDataList{
  step1Data: StepDataDualState;
  step2Data: StepDataDualState;
  step3Data: StepDataDualState;
  userInfoDataSuccess: Function;
  step3DataSuccess: Function;
  step3DataFailure: Function;
  step3DataReset: Function;
  step4DataSuccess: Function;
  fromMailSereno: boolean;
  vehicle: string;
  history: ReturnType<typeof useHistory>;
  setResourceListRequest: Function;
}

interface IStep3State{
  autocompleteCities: any[];
  autocompleteCountries: any[];
  postalCodesList: any[];
  postalCodesOwnerList: any[];
  postalCodesDriverList: any[];
  contractorYoungestChildAgeList: any[];
  drivingLicenseAgeList: any[];
  paymentMethodsList: any[];
  insuranceStatusList: any[];
  toponimiList: any[];
  insuranceClassList: any[];
  yearsInClass1List: any[];
  yearsInsuredList: any[];
  insuranceCompaniesList: any[];
  pastAccidentCountList: any[];
  bersaniDecreeStatusList: any[];
  peopleCoincideList: any[];
  youngestOtherDriverAgeList: any[];
  youngestFamilyDriverAgeList: any[];
  insuranceClassListBersani: any[];
  licenseTypesList: any[];
  licensePointsList: any[];
  drunkDrivingTicketsList: any[];
  licenseSuspensionsList: any[];
  licenseSuspensionMonthsList: any[];
  accidentYearList: any[];
  accidentResponsibilityList: any[];
  highestDisplacementList: any[];
  consecutiveDrivingMonthsList: any[];
  iscontractorEmailValid: boolean;
  
  acceptAllOptionalCollection: boolean | undefined;

  detailIvassIsVisible: boolean | undefined;
  detailGeneraleIsVisible: boolean | undefined;
  
  ownerDrivingLicenseAgeList: any[];
  driverDrivingLicenseAgeList: any[];
  serverSideCheckErrorDriverDob: string;
  serverSideCheckErrorDriverAddress: string;
  serverSideCheckErrorDriverLicenseAge: string;
  serverSideCheckErrorOwnerDob: string;
  serverSideCheckErrorOwnerAddress: string;
  serverSideCheckErrorOwnerLicenseAge: string;
  serverSideCheckErrornewInsuranceStart: string;
  serverSideCheckErrorInsuranceClass: string;

  loading: boolean;
  errors: string[];
  errorTexts: IFeedbackText[];

  submitting: boolean;
  activeIndex: number;
  activeIndexIvass: number;

  showTurnstileManaged: boolean;
  turnstileToken: string | undefined;
}


class Step3Component extends Component<IStep3Props, IStep3State> {
  private user: User | undefined;
  private vehicle: string;
  private privacyGdprCollection: HTMLDivElement | null = null;
  private privacySeiSicuroCollection: HTMLDivElement | null = null;

  private currentPersonCardType : string = "";

  private readonly stepFields : any;

  constructor(props: any) {
    super(props);
    this.vehicle = this.props.vehicle;

    this.stepFields = {
      step: {},
      owner: Step3OwnerFieldsBuilder(),
      driver: Step3DriverFieldsBuilder(),
      contractor: Step3ContractorBuilder()
    };

    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;

    let minorAgeOptions = getNumberList(Constants.MINOR_MAX_AGE);
    let pastAccidentCountOptions = getNumberList(Constants.MAX_ACCIDENTS);
    let drivingLicenseAgeOptions = getDrivingLicenseAgeOptions(step1Data?.dateOfBirth, this.vehicle === "moto" ? true : false);


    let policy3 = (step3Data?.acceptGdprPrivacyPolicy3 && step3Data.acceptGdprPrivacyPolicy3 === true);
    let policy102 = (step3Data?.acceptGdprPrivacyPolicy102 && step3Data.acceptGdprPrivacyPolicy102 === true);
    let policy104 = (step3Data?.acceptGdprPrivacyPolicy104 && step3Data.acceptGdprPrivacyPolicy104 === true);

    this.state = {
      autocompleteCities: [],
      autocompleteCountries: [],
      postalCodesList: [],
      postalCodesOwnerList: [],
      postalCodesDriverList: [],
      toponimiList: [],
      paymentMethodsList: [],
      insuranceStatusList: [],
      insuranceClassList: [],
      yearsInClass1List: [],
      yearsInsuredList: [],
      insuranceCompaniesList: [],
      pastAccidentCountList: pastAccidentCountOptions,
      bersaniDecreeStatusList: [],
      peopleCoincideList: [],
      youngestOtherDriverAgeList: [],
      youngestFamilyDriverAgeList: [],
      insuranceClassListBersani: [],
      licenseTypesList: [],
      licensePointsList: [],
      drunkDrivingTicketsList: [],
      licenseSuspensionsList: [],
      licenseSuspensionMonthsList: [],
      accidentYearList: [],
      accidentResponsibilityList: [],
      highestDisplacementList: [],
      consecutiveDrivingMonthsList: [],
      contractorYoungestChildAgeList: minorAgeOptions,
      
      ownerDrivingLicenseAgeList: getDrivingLicenseAgeOptions(step3Data.owner?.dateOfBirth),
      
      drivingLicenseAgeList: drivingLicenseAgeOptions,
      driverDrivingLicenseAgeList: getDrivingLicenseAgeOptions(step3Data.driver?.dateOfBirth),
      
      acceptAllOptionalCollection: policy102 && policy104 && policy3,
      
      detailIvassIsVisible: false,
      detailGeneraleIsVisible: false,
      
      iscontractorEmailValid: false,
      loading: false,
      serverSideCheckErrorInsuranceClass: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorDriverAddress: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorDriverDob: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorDriverLicenseAge: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorOwnerAddress: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorOwnerDob: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrorOwnerLicenseAge: Constants.MISSING_FIELD_TEXT,
      serverSideCheckErrornewInsuranceStart: Constants.MISSING_FIELD_TEXT,

      errors: [],
      errorTexts: [],

      submitting: false,
      
      activeIndex: -1,
      activeIndexIvass: -1,

      showTurnstileManaged: false,
      turnstileToken: undefined
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.searchCountry = this.searchCountry.bind(this);
    this.showMoreGenerale = this.showMoreGenerale.bind(this);
    this.showMoreIvass = this.showMoreIvass.bind(this);
    this.trakSendLoaded = this.trakSendLoaded.bind(this);
    
    this.saveReduxState = this.saveReduxState.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.onBlurHandlerDate = this.onBlurHandlerDate.bind(this);
    this.togglePrivacyDetails = this.togglePrivacyDetails.bind(this);
    this.toggleIvassDetails = this.toggleIvassDetails.bind(this);

    this.saveCaptchaToken = this.saveCaptchaToken.bind(this)
    this.disableFormSubmit = this.disableFormSubmit.bind(this)
    
  }

  private togglePrivacyDetails(){
    this.setState({
      activeIndex: (this.state.activeIndex === 0 ? -1 : 0)
    });
  }

  private toggleIvassDetails(){
    this.setState({
      activeIndexIvass: (this.state.activeIndexIvass === 0 ? -1 : 0)
    });
  }  

  componentDidMount() {

    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    let resourseRequestMask: any = {};
    if(!(this.props?.professionList?.length ?? false)){
      resourseRequestMask.professionList = true;
    }
    
    if(!(this.props?.civilStateList?.length ?? false)){
      resourseRequestMask.civilStateList = true;
    }
    
    if(!(this.props?.degreeList?.length ?? false)){
      resourseRequestMask.degreeList = true;
    }

    if(JSON.stringify(resourseRequestMask) != "{}"){
      this.props.setResourceListRequest(resourseRequestMask)
    }

    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_STATE_ENDPOINT,
      (result: any) => {
        this.setState({ insuranceStatusList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_CLASS_ENDPOINT,
      (result: any) => {
        this.setState({ insuranceClassList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_COMPANY_ENDPOINT,
      (result: any) => {
        result.sort(sortJson);
        this.setState({ insuranceCompaniesList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_BERSANI_ENDPOINT,
      (result: any) => {
        this.setState({ bersaniDecreeStatusList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_PEOPLE_COINCIDE_ENDPOINT,
      (result: any) => {
        this.setState({ peopleCoincideList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_CAR_LICENSE_TYPE_ENDPOINT(this.vehicle),
      (result: any) => {
        this.setState({ licenseTypesList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_LICENSE_POINTS_ENDPOINT,
      (result: any) => {
        this.setState({ licensePointsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_DRUNK_DRIVING_ENDPOINT,
      (result: any) => {
        this.setState({ drunkDrivingTicketsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_LICENSE_SUSPENSIONS_ENDPOINT,
      (result: any) => {
        this.setState({ licenseSuspensionsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_LICENSE_SUSPENSION_MONTHS_ENDPOINT,
      (result: any) => {
        this.setState({ licenseSuspensionMonthsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_BERSANI_CLASS_ENDPOINT,
      (result: any) => {
        this.setState({ insuranceClassListBersani: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_YOUNGEST_FAMILY_ENDPOINT,
      (result: any) => {
        this.setState({ youngestFamilyDriverAgeList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_YOUNGEST_DRIVER_ENDPOINT,
      (result: any) => {
        this.setState({ youngestOtherDriverAgeList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_AGE_ENDPOINT,
      (result: any) => {
        this.setState({ yearsInsuredList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_INSURANCE_CLASS_AGE_ENDPOINT,
      (result: any) => {
        this.setState({ yearsInClass1List: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_PAYMENT_OPTIONS_ENDPOINT,
      (result: any) => {
        result.sort(sortJson);
        this.setState({ paymentMethodsList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_ACCIDENT_YEAR_ENDPOINT,
      (result: any) => {
        this.setState({ accidentYearList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_ACCIDENT_RESPONSIBILITY_ENDPOINT,
      (result: any) => {
        this.setState({ accidentResponsibilityList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_HIGHEST_DISPLACEMENT_ENDPOINT,
      (result: any) => {
        this.setState({ highestDisplacementList: result });
      }
    );
    fetchList(
      Constants.SEISICURO_AJAX_CONSECUTIVE_DRIVING_MONTHS_ENDPOINT,
      (result: any) => {
        this.setState({ consecutiveDrivingMonthsList: result });
      }
    );
    fetchList(Constants.SEISICURO_AJAX_TOPONIMI_ENDPOINT, (result: any) => {
      this.setState({ toponimiList: getJsonValues(result) });
    });

    if(step3Data.driverId !== undefined || step3Data.ownerId !== undefined){
      this.props.step3DataSuccess({driverId: undefined, ownerId: undefined}, this.vehicle)
    }

    if(!(window as any)?.Cookiebot?.hasResponse){
      window.addEventListener("CookiebotOnAccept", this.trakSendLoaded)
      window.addEventListener("CookiebotOnDecline", this.trakSendLoaded)
    }
    else{
      this.trakSendLoaded();
    }

  }

  // ------------------------------------------------------------------------------------------------------------------------------

  private getDataToTrack(){
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    return {
      prodotto: this.vehicle,
      gender: step1Data.gender,
      dataNascita: formatDateAdform(dateOfBirth),
      titoloStudio: step1Data.degree,
      professione: step1Data.profession,
      dataImmatricolazione: `${
        step2Data.vehicleRegistrationMonth !== undefined &&
        step2Data.vehicleRegistrationMonth < 10
          ? `0${step2Data.vehicleRegistrationMonth}`
          : step2Data.vehicleRegistrationMonth
      }/${step2Data.vehicleRegistrationYear}`,
      annoAcquisto: step2Data.vehicleBuyYear,
      veichleBrand: step2Data.vehicleBrandLabel,
      veichleModel: step2Data.vehicleModelLabel,
      emailKript: encryptEmail(step3Data.contractorEmail),
      email: step3Data.contractorEmail,
      telefono: step3Data.contractorPhoneNumber,
      name: step3Data.contractorName,
      surname: step3Data.contractorSurname,
      mktgAssicurativo: step3Data.acceptGdprPrivacyPolicy102,
      mktgNonAssicurativo: step3Data.acceptGdprPrivacyPolicy102,
      newsletter: step3Data.acceptGdprPrivacyPolicy22,
      profilazione: step3Data.acceptGdprPrivacyPolicy3,
      cessioneAssicurativo: step3Data.acceptGdprPrivacyPolicy104,
      cessioneNonAssicurativo: step3Data.acceptGdprPrivacyPolicy104,
      gdpr: step3Data.acceptSeiSicuroPrivacyPolicy,
    }
  }

  /**
   * Trak tag-manager: notifica errore
   */
  private trackSendFailure(error_data?: any) {
    let errorToSend: any = {};
    if(error_data){
      errorToSend = {
        errors: error_data
      }
    }
    else{
      errorToSend = {
        errors: this.state.errors,
        errorTexts: this.state.errorTexts
      }
    }
    
    this.props.trackEvent?.({
      name: "step3GuestError",
      data: {
        ...this.getDataToTrack(),
        ...errorToSend
      }
    });
  }

  /**
   * Trak tag-manager: notifica successo
   */
  private trackSendSuccess(callbackFunction: Function) {
    this.props.trackEvent?.({
      name: "step3GuestSuccess",
      data: this.getDataToTrack(),
      callback: callbackFunction
    });
  }

  /**
   * Track tag-mamager: notifica pagina caricata
   */
  private trakSendLoaded() {
    this.props.trackEvent?.({
      name: "step3GuestLoaded",
      data: this.getDataToTrack()
    });
  }

  /**
   * Assembles the month picker component contained in the Calendar component
   *
   * @param e
   * @returns {any} the component which will represent the month picker
   */
  private monthNavigatorTemplate(e: any) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  /**
   * Assembles the year picker component contained in the Calendar component
   *
   * @param e
   * @returns {any} the component which will represent the year picker
   */
  private yearNavigatorTemplate(e: any) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  private accidentalDetail() : JSX.Element[] {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    
    return (step3Data?.pastAccidentsInfo ?? [])?.map((item, i) => 
      <div className="col-12" key={i}>
        <div className="row">
          <div className="col-12">
            <p>Incidente {i + 1}</p>
          </div>
          <div className="col-xxl-3 col-4 mb-4">
            <label>Anno</label>
            <Dropdown
              value={item.year}
              options={this.state.accidentYearList}
              onChange={(selected) =>
                this.onChangeHandler(
                  {
                    index: i,
                    field: "year",
                    value: selected.value
                  },
                  `pastAccidentYear${i}`
                )
              }
              onHide={() => removeFocusClass()}
              optionLabel="label"
              placeholder="Seleziona"
              emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
            />
            <ContextFeedback
              show={this.state.errors.includes(`pastAccidentYear${i}`)}
              message={
                this.state.errorTexts.find(elem => elem.field === `pastAccidentYear${i}`) ??
                {msg: "Campo obbligatorio", severity: "error"}
              }
            />
          </div>
          <div className="col-xxl-9 col-8 mb-4">
            <label>Responsabilità</label>
            <Dropdown
              value={item.responsibility}
              options={this.state.accidentResponsibilityList}
              onChange={(selected) =>
                this.onChangeHandler(
                  {
                    index: i,
                    field: "responsibility",
                    value: selected.value,
                  },
                  `pastAccidentRespondibility${i}`
                )
              }
              onHide={() => removeFocusClass()}
              optionLabel="label"
              placeholder="Seleziona"
              emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
            />
            <ContextFeedback
              show={this.state.errors.includes(`pastAccidentRespondibility${i}`)}
              message={
                this.state.errorTexts.find(elem => elem.field === `pastAccidentRespondibility${i}`) ??
                {msg: "Campo obbligatorio", severity: "error"}
              }
            />
          </div>
        </div>
      </div>
    );
  }

  private mapFieldsPerson2Contractor(fields: string[]){
    return fields.map((name: string) => {
      let mapped : string;
      switch(name){
        case "name":
          mapped = "contractorName";
          break;
        case "surname":
          mapped = "contractorSurname";
          break;
        case "address":
          mapped = "contractorAddress";
          break;
        case "cityOfResidence":
          mapped = "contractorCityOfResidence";
          break;
        case "postalCodeOfResidence":
          mapped = "contractorPostalCode";
          break;
        case "children":
          mapped = "contractorChildren";
          break;
        case "youngestChild":
          mapped = "contractorYoungestChild";
          break;
        case "email":
          mapped = "contractorEmail";
          break;
        case "phone":
          mapped = "contractorPhoneNumber";
          break;
        case "italianDrivingLicense":
          mapped = "contractorItalianDrivingLicense";
          break;
        case "drivingLicenseAge":
          mapped = "contractorDrivingLicenseAge";
          break;
        default:
          mapped = name;
          break;
      }

      return mapped;
    })
  }

  render() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    const dateOfBirthMin = new Date();
    dateOfBirthMin.setFullYear(
      dateOfBirthMin.getFullYear() - Constants.DOB_YEAR_RANGE_MAX
    );
    const dateOfBirthMax = new Date();
    dateOfBirthMax.setFullYear(
      dateOfBirthMax.getFullYear() - Constants.DOB_YEAR_RANGE_MIN
    );

    const ownerBirthDate: Date | undefined = step3Data?.owner?.dateOfBirth !== undefined
      ? step3Data.owner.dateOfBirth instanceof Date
        ? !isNaN(step3Data?.owner?.dateOfBirth?.getDate()) ? step3Data.owner.dateOfBirth : undefined
        : !isNaN((new Date(step3Data.owner.dateOfBirth)).getDate()) ? new Date(step3Data.owner.dateOfBirth) : undefined
      : undefined

    const driverBirthDate: Date | undefined = step3Data?.driver?.dateOfBirth !== undefined
      ? step3Data.driver.dateOfBirth instanceof Date
        ? !isNaN(step3Data?.driver?.dateOfBirth?.getDate()) ? step3Data.driver.dateOfBirth : undefined
        : !isNaN((new Date(step3Data.driver.dateOfBirth)).getDate()) ? new Date(step3Data.driver.dateOfBirth) : undefined
      : undefined

    return (
      <div id="step-3-form">

        <Card className="pr-form">
          <div className="p-fluid p-4 p-lg-5">
            <h1 className="titleLev4 colorPrimaryBlue">Dati assicurativi</h1>
            <div className="row">
                    
              <FeedbackBox items={this.state.errorTexts}/>

              <div className="col-12 mt-4 mb-1">
                <div className="row">
                  <h3 className="titleLev5">Il contraente</h3>
                  <div className="col-xl-6 col-12 mb-4">
                    <label htmlFor="contraenete-nome">Nome</label>
                    <InputText
                      value={step3Data.contractorName ?? ""}
                      onChange={(selected) => this.onChangeHandler(selected.target.value, "contractorName")}
                      placeholder="Nome"
                      name="name"
                      id="contraenete-nome"
                      autoComplete="given-name"
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorName")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorName") ??
                        {msg: "Inserire il nome del contraente", severity: "error"}
                      }
                    />
                  </div>
                  <div className="col-xl-6 col-12 mb-4">
                    <label htmlFor="contraenete-cognome">Cognome</label>
                    <InputText
                      value={step3Data.contractorSurname ?? ""}
                      onChange={(selected) => this.onChangeHandler(selected.target.value, "contractorSurname")}
                      placeholder="Cognome"
                      name="name"
                      id="contraenete-cognome"
                      autoComplete="family-name"
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorSurname")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorSurname") ??
                        {msg: "Inserire il cognome del contraente", severity: "error"}
                      }
                    />
                  </div>
    
                  {/* <div className='"col-12 error-address-container mb-4'> */}
                  <div className='"col-12 mb-4'>
                    <Address
                      cityOfResidence={step3Data.contractorCityOfResidence}
                      address={step3Data.contractorAddress}
                      civico={step3Data.contractorAddressNumber}
                      postalCodeOfResidence={step3Data.contractorPostalCode}
                      labelFullAddress="Indirizzo di residenza"
                      placeholderFullAddress="Via delle Rose, 23, Roma"
                      placeholderAddress="Via delle Rose, 23"
                      onDone={(addressData: any[]) => {
                        if(addressData){
                          const [address, postalCode, cityOfResidence, civico] = addressData;
                          this.updateAddress("contractor", address, postalCode, cityOfResidence, civico)
                        }
                      }}
                      errors={
                        this.state.errors.filter((error: string) => error.startsWith("contractor"))
                          .map((r: string) => {
                            let string = r.replace("contractor", "");
                            return `${string[0].toLowerCase()}${string.slice(1)}`
                          })
                      }
                      errorTexts={
                        this.state.errorTexts
                          .filter((feedback: IFeedbackText) => feedback.field.startsWith("contractor"))
                          .map((feedback: IFeedbackText) => {
                            let string = feedback.field.replace("contractor", "");
                            return {
                              msg: feedback?.msg,
                              field: `${string[0].toLowerCase()}${string.slice(1)}`,
                              severity: feedback?.severity
                            }
                          })
                      }
                    />
                  </div>
    
                  <div className="col-12 mb-4 field-owner-children">
                    <label>Figli</label>
                    <SelectButton
                      value={(step3Data.contractorChildren ?? [])}
                      options={[
                        { label: "Senza figli", value: 0 },
                        { label: "Figli minorenni", value: 1 },
                        { label: "Figli maggiorenni", value: 2 },
                      ]}
                      onChange={(selected) =>
                        this.onChangeHandler(selected.value, "contractorChildren")
                      }
                      multiple
                      unselectable={step3Data?.contractorChildren?.length !== 1} // fix selezione numero figli
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorChildren")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorChildren") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  {this.isVisible("contractorYoungestChild") && (
                    <div className="col-12 mb-4">
                      <label>Quanti anni ha il figlio più piccolo?</label>
                      <Dropdown
                        value={step3Data.contractorYoungestChild}
                        options={this.state.contractorYoungestChildAgeList}
                        onChange={(selected) =>
                          this.onChangeHandler(
                            selected.value,
                            "contractorYoungestChild"
                          )
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("contractorYoungestChild")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "contractorYoungestChild") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
    
                  <div className="col-12 mb-4">
                    <label>Hai una patente italiana?</label>
                    <SelectButton
                      value={step3Data.contractorItalianDrivingLicense}
                      options={[
                        { label: "Si", value: true },
                        { label: "No", value: false },
                      ]}
                      onChange={(selected) => this.onChangeHandler(selected.value, "contractorItalianDrivingLicense")}
                      className="smallBtn"
                      unselectable={false}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorItalianDrivingLicense")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorItalianDrivingLicense") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label>A che età hai conseguito la patente?</label>
                    <Dropdown
                      value={step3Data.contractorDrivingLicenseAge}
                      options={this.state.drivingLicenseAgeList}
                      onChange={(selected) =>
                        this.onChangeHandler(
                          selected.value,
                          "contractorDrivingLicenseAge"
                        )
                      }
                      onHide={() => removeFocusClass()}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorDrivingLicenseAge")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorDrivingLicenseAge") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12">
                <label>Metodo di pagamento preferito</label>
                <Dropdown
                  value={step3Data.contractorPreferredPaymentMethod}
                  options={this.state.paymentMethodsList}
                  onChange={(selected) =>
                    this.onChangeHandler(
                      selected.value,
                      "contractorPreferredPaymentMethod"
                    )
                  }
                  onHide={() => removeFocusClass()}
                  optionLabel="label"
                  placeholder="Seleziona"
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                />
                <ContextFeedback
                  show={this.state.errors.includes("contractorPreferredPaymentMethod")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "contractorPreferredPaymentMethod") ??
                    {msg: "Campo obbligatorio", severity: "error"}
                  }
                />
                <div>
                  <small>
                    La compilazione del campo non vincola in nessun modo
                    all'acquisto ed è possibile modificarla in un secondo
                    momento
                  </small>
                </div>
              </div>
            </div>
            <Divider/>
            {this.isVisible("ownerFilled") && (
              <div>
                <div className="row">
                  <div className="col-12 mb-4">
                    <h3 className="titleLev5 mb-2">Situazione Assicurativa</h3>
                    <label>Data di decorrenza nuova polizza</label>
                    <Calendar
                      id="navigatorstemplate"
                      value={getValidInsuranceStart(step3Data.newInsuranceStart ? new Date(step3Data.newInsuranceStart) : new Date())}
                      onChange={(selected) => this.onChangeHandler(selected.value, "newInsuranceStart")}
                      minDate={new Date()}
                      monthNavigatorTemplate={this.monthNavigatorTemplate}
                      yearNavigatorTemplate={this.yearNavigatorTemplate}
                      showIcon
                      locale="it"
                      dateFormat={Constants.CALENDAR_DATE_FORMAT}
                      placeholder="Seleziona"
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("newInsuranceStart")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "newInsuranceStart") ??
                        {msg: this.state.serverSideCheckErrornewInsuranceStart, severity: "error"}
                      }
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label>La tua {this.vehicle} è</label>
                    <Dropdown
                      value={step3Data.vehicleInsuranceStatus}
                      options={this.state.insuranceStatusList}
                      onChange={(selected) => this.onChangeHandler(selected.value, "vehicleInsuranceStatus")}
                      onHide={() => removeFocusClass()}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("vehicleInsuranceStatus")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "vehicleInsuranceStatus") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  {this.isVisible("assicurataAMioNome") &&
                    <div className="col-12 mb-4">
                      <label>Classe di merito di assegnazione</label>
                      <Dropdown
                        value={step3Data.currentInsuranceCreditClass}
                        options={this.state.insuranceClassList}
                        onChange={(selected) =>
                          this.onChangeHandler(
                            selected.value,
                            "currentInsuranceCreditClass"
                          )
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("currentInsuranceCreditClass")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "currentInsuranceCreditClass") ??
                          {msg: this.state.serverSideCheckErrorInsuranceClass, severity: "error"}
                        }
                      />
                    </div>
                  }
                  {this.isVisible("yearsInClass1List") && (
                    <div className="col-12 mb-4">
                      <label>Da quanti anni sei in classe 1?</label>
                      <Dropdown
                        value={step3Data.yearsInClass1}
                        options={this.state.yearsInClass1List}
                        onChange={(selected) =>
                          this.onChangeHandler(selected.value, "yearsInClass1")
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("yearsInClass1")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "yearsInClass1") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("assicurataAMioNome") && (
                    <div className="col-12 mb-4">
                      <label>Compagnia assicurativa attuale</label>
                      <Dropdown
                        value={step3Data.currentInsuranceCompany}
                        options={this.state.insuranceCompaniesList}
                        onChange={(selected) =>
                          this.onChangeHandler(
                            selected.value,
                            "currentInsuranceCompany"
                          )
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("currentInsuranceCompany")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "currentInsuranceCompany") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("assicurataAMioNome") && (
                    <div className="col-12 mb-4">
                      <label>Da quanti anni sei assicurato?</label>
                      <Dropdown
                        value={step3Data.currentInsuranceYears}
                        options={this.state.yearsInsuredList}
                        onChange={(selected) =>
                          this.onChangeHandler(
                            selected.value,
                            "currentInsuranceYears"
                          )
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("currentInsuranceYears")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "currentInsuranceYears") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("assicurataAMioNome") && (
                    <div className="col-12 mb-4">
                      <label>
                        Quanti incidenti hai avuto (con colpa o senza colpa)?
                      </label>
                      <Dropdown
                        value={step3Data.pastAccidentCount?.toString()}
                        options={this.state.pastAccidentCountList}
                        onChange={(selected) => this.onChangeHandler(Number(selected.value), "pastAccidentCount")}
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("pastAccidentCount")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "pastAccidentCount") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("accidentDetails") &&
                    <>
                      {this.accidentalDetail()}
                    </>
                  }
                  {this.isVisible("bersaniDecree") && (
                    <div className="col-12 mb-4">
                      <label>Decreto Bersani</label>
                      <Dropdown
                        value={step3Data.bersaniDecree}
                        options={this.state.bersaniDecreeStatusList}
                        onChange={(selected) =>
                          this.onChangeHandler(selected.value, "bersaniDecree")
                        }
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("bersaniDecree")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "bersaniDecree") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("cuLicensePlate") && (
                    <div className="col-12 mb-4">
                      <label>Targa del veicolo di cui utilizzi la CU</label>
                      <InputText
                        value={step3Data.cuLicensePlate ?? ""}
                        onChange={(selected) => this.onChangeHandler(selected.target.value,"cuLicensePlate")
                        }
                        onBlur={(evt) => {
                          let invalid : Boolean = false;

                          // superamento legge bersani
                          if (
                            !isLicensePlateAutoValid(evt.target.value ?? "") &&
                            !isLicensePlateMotoValid(evt.target.value ?? "")
                          ) {
                            invalid = true;
                          }

                          /*
                          if(this.vehicle === "auto" && !isLicensePlateAutoValid(evt.target.value ?? "")){
                            invalid = true;
                          }
                          else if(this.vehicle === "moto" && !(isLicensePlateMotoValid(evt.target.value ?? "")) ){
                            invalid = true;
                          }
                          */
                          
                          if(invalid){
                            this.setState({
                              errors: this.state.errors.includes("cuLicensePlate")
                                ? this.state.errors
                                : [...this.state.errors, "cuLicensePlate"],
                              errorTexts: [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "cuLicensePlate"), {field: "cuLicensePlate", msg: "Targa non valida"}]
                            });
                          }
                        }}
                        placeholder="Targa"
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("cuLicensePlate")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "cuLicensePlate") ??
                          {msg: "Targa non valida", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("cuInsuranceClass") && (
                    <div className="col-12 mb-4">
                      <label>Classe di merito (CU) di assegnazione</label>
                      <Dropdown
                        value={step3Data.cuInsuranceClass}
                        options={this.state.insuranceClassListBersani}
                        onChange={(selected) => this.onChangeHandler(selected.value, "cuInsuranceClass")}
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("cuInsuranceClass")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "cuInsuranceClass") ??
                          {msg: "Targa non valida", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("assicurataAMioNome") && (
                    <div className="col-12 mb-4">
                      <label>
                        Hai usufruito in passato del Decreto Bersani?
                      </label>
                      <SelectButton
                        value={step3Data.pastBersaniDecree}
                        options={[
                          { label: "Si", value: true },
                          { label: "No", value: false },
                        ]}
                        onChange={(selected) => this.onChangeHandler(selected.value, "pastBersaniDecree")}
                        unselectable={false}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("pastBersaniDecree")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "pastBersaniDecree") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}

                  <div className="col-12">
                    <Divider className="divider-fields" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h3 className="titleLev5 mb-2">Il proprietario</h3>
                  </div>
                  <div className="col-12 mb-4">
                    <label>
                      {`Il proprietario e il contraente della tua ${this.vehicle} coincidono?`}
                    </label>
                    <Dropdown
                      value={step3Data.contractorAndOwnerCoincide ?? ""}
                      options={this.state.peopleCoincideList}
                      onChange={(selected) => this.onChangeHandler(selected.value, "contractorAndOwnerCoincide")}
                      onHide={() => removeFocusClass()}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorAndOwnerCoincide")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorAndOwnerCoincide") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>

                  {this.isVisible("owner") && (
                    <>
                      <div className="row">
                        <div className="col-12 mb-1">
                          <h3 className="titleLev6 mb-2">
                            Informazioni sul proprietario
                          </h3>
                        </div>
                        <div className="col-12 mb-4">
                          <label>Sesso</label>
                          <SelectButton
                            value={step3Data.owner?.gender}
                            options={[
                              { label: "Uomo", value: "uomo" },
                              { label: "Donna", value: "donna" },
                            ]}
                            onChange={(selected) =>
                              this.onChangeHandler(
                                selected.value,
                                "genderOwner"
                              )
                            }
                            className="regularBtn"
                            unselectable={false}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("genderOwner")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "genderOwner") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-xxl-6 col-12 mb-4">
                          <label>Nome</label>
                          <InputText
                            value={step3Data.owner?.name ?? ""}
                            onChange={(selected) =>
                              this.onChangeHandler(
                                selected.target.value,
                                "nameOwner"
                              )
                            }
                            placeholder="Nome"
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("nameOwner")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "nameOwner") ??
                              {msg: "Inserire il nome del proprietario", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-xxl-6 col-12 mb-4">
                          <label>Cognome</label>
                          <InputText
                            value={step3Data.owner?.surname ?? ""}
                            onChange={(selected) =>
                              this.onChangeHandler(
                                selected.target.value,
                                "surnameOwner"
                              )
                            }
                            placeholder="Cognome"
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("surnameOwner")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "surnameOwner") ??
                              {msg: "Inserire il cognome del proprietario", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <label
                            className={step3Data.owner?.validDateOfBirthLabel
                                ? ""
                                : "gray-label"
                            }
                          >
                            Data di nascita
                          </label>
                          <InputDate
                            id="contraenete-date-of-birth"
                            date={ownerBirthDate}
                            onFocus={(e) => this.currentPersonCardType = "owner"}
                            onBlur={stringDate => {
                              this.onBlurHandlerDate(stringDate, (updatedStep3Data: Step3Data) => {
                                this.setState({ownerDrivingLicenseAgeList: getDrivingLicenseAgeOptions(updatedStep3Data.owner?.dateOfBirth)})
                              })
                            }}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("dateOfBirthOwner")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "dateOfBirthOwner") ??
                              {msg: this.state.serverSideCheckErrorOwnerDob, severity: "error"}
                            }
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 mb-4">
                          <label>Luogo di nascita</label>
                          <SelectButton
                            value={step3Data.owner?.bornInItaly}
                            options={[
                              { label: "Italia", value: true },
                              { label: "Altro", value: false },
                            ]}
                            onChange={(selected) =>
                              this.onChangeHandler(
                                selected.value,
                                "bornInItalyOwner"
                              )
                            }
                            className="regularBtn"
                            unselectable={false}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("bornInItalyOwner")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "bornInItalyOwner") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        {step3Data.owner?.bornInItaly && (
                          <div className="col-12 mb-4">
                            <label>Comune di nascita</label>
                            <AutoComplete
                              value={step3Data.owner?.cityOfBirth}
                              suggestions={this.state.autocompleteCities}
                              completeMethod={(e) =>
                                fetchAutocomplete(
                                  e,
                                  Constants.SEISICURO_AJAX_CITY_OF_BIRTH_ENDPOINT,
                                  (result: any) => {
                                    this.setState({
                                      autocompleteCities: result,
                                    });
                                  },
                                  "NASCITA"
                                )
                              }
                              field="value"
                              placeholder="Comune di nascita"
                              onChange={(selected) =>
                                this.onChangeHandler(
                                  selected.value,
                                  "cityOfBirthOwner"
                                )
                              }
                            />
                            <ContextFeedback
                              show={this.state.errors.includes("cityOfBirthOwner")}
                              message={
                                this.state.errorTexts.find(elem => elem.field === "cityOfBirthOwner") ??
                                {msg: "Campo obbligatorio", severity: "error"}
                              }
                            />
                          </div>
                        )}
                        {step3Data.owner?.bornInItaly === false && (
                          <div className="col-12 mb-4">
                            <label>Paese di nascita</label>
                            <AutoComplete
                              value={step3Data.owner?.countryOfBirth}
                              suggestions={this.state.autocompleteCountries}
                              completeMethod={this.searchCountry}
                              field="value"
                              placeholder="Paese di nascita"
                              onChange={(selected) => this.onChangeHandler(selected.value, "countryOfBirthOwner")}
                            />
                            <ContextFeedback
                              show={this.state.errors.includes("countryOfBirthOwner")}
                              message={
                                this.state.errorTexts.find(elem => elem.field === "countryOfBirthOwner") ??
                                {msg: "Campo obbligatorio", severity: "error"}
                              }
                            />
                          </div>
                        )}

                        <div className="col-12">
                          <div className="row">
                            <Address
                              cityOfResidence={step3Data.owner?.cityOfResidence}
                              address={step3Data.owner?.address}
                              civico={step3Data.owner?.addressNumber}
                              postalCodeOfResidence={step3Data.owner?.postalCodeOfResidence}
                              labelFullAddress="Indirizzo di residenza"
                              placeholderFullAddress="Via delle Rose, 23, Roma"
                              placeholderAddress="Via delle Rose, 23"
                              onDone={(addressData: any[]) => {
                                if(addressData){
                                  const [address, postalCode, cityOfResidence, civico] = addressData;
                                  this.updateAddress("owner", address, postalCode, cityOfResidence, civico)
                                }
                              }}
                              errors={this.state.errors
                                .filter((error: string) => error.endsWith("Owner"))
                                .map((error: string) => error.replace("Owner", ""))
                              }
                              errorTexts={this.state.errorTexts
                                .filter((feedback: IFeedbackText) => feedback.field.endsWith("Owner"))
                                .map((feedback: IFeedbackText) => {
                                  return {...feedback, field: feedback.field.replace("Owner", "")}
                                })
                              }
                            />

                            <div className="col-12 mt-4 mb-4">
                              <label>Stato civile</label>
                              <Dropdown
                                value={step3Data.owner?.civilState}
                                options={this.props.civilStateList}
                                onChange={(selected) =>
                                  this.onChangeHandler(
                                    selected.value,
                                    "civilStateOwner"
                                  )
                                }
                                onHide={() => removeFocusClass()}
                                optionLabel="label"
                                placeholder="Stato civile"
                                emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                              />
                              <ContextFeedback
                                show={this.state.errors.includes("civilStateOwner")}
                                message={
                                  this.state.errorTexts.find(elem => elem.field === "civilStateOwner") ??
                                  {msg: "Inserisci lo stato civile", severity: "error"}
                                }
                              />
                            </div>
                            <div className="col-12 mb-4 field-owner-children">
                              <label>Figli</label>
                              <SelectButton
                                value={step3Data.owner?.children}
                                options={[
                                  { label: "Senza figli", value: 0 },
                                  { label: "Figli minorenni", value: 1 },
                                  { label: "Figli maggiorenni", value: 2 },
                                ]}
                                onChange={(selected) =>
                                  this.onChangeHandler(
                                    selected.value,
                                    "childrenOwner"
                                  )
                                }
                                multiple
                                unselectable={step3Data?.owner?.children?.length !== 1} // fix selezione numero figli
                              />
                              <ContextFeedback
                                show={this.state.errors.includes("childrenOwner")}
                                message={
                                  this.state.errorTexts.find(elem => elem.field === "childrenOwner") ??
                                  {msg: "Campo obbligatorio", severity: "error"}
                                }
                              />
                            </div>
                            <div className="col-xxl-5 col-12 mb-4">
                              <label>Titolo di studio</label>
                              <Dropdown
                                value={step3Data.owner?.degree}
                                options={this.props.degreeList}
                                onChange={(selected) =>
                                  this.onChangeHandler(
                                    selected.value,
                                    "degreeOwner"
                                  )
                                }
                                onHide={() => removeFocusClass()}
                                optionLabel="label"
                                placeholder="Seleziona"
                                emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                              />
                              <ContextFeedback
                                show={this.state.errors.includes("degreeOwner")}
                                message={
                                  this.state.errorTexts.find(elem => elem.field === "degreeOwner") ??
                                  {msg: "Inserisci il titolo di studio del proprietario", severity: "error"}
                                }
                              />
                            </div>
                            <div className="col-xxl-7 col-12 mb-4">
                              <label>Professione</label>
                              <Dropdown
                                value={step3Data.owner?.profession}
                                options={this.props.professionList}
                                onChange={(selected) =>
                                  this.onChangeHandler(
                                    selected.value,
                                    "professionOwner"
                                  )
                                }
                                onHide={() => removeFocusClass()}
                                optionLabel="label"
                                placeholder="Seleziona"
                                emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                              />
                              <ContextFeedback
                                show={this.state.errors.includes("professionOwner")}
                                message={
                                  this.state.errorTexts.find(elem => elem.field === "professionOwner") ??
                                  {msg: "Inserisci la professione del proprietario", severity: "error"}
                                }
                              />
                            </div>
                            <div className="col-12 mb-4">
                              <label>
                                Il proprietario ha una patente italiana?
                              </label>
                              <SelectButton
                                value={step3Data.owner?.italianDrivingLicense}
                                options={[
                                  { label: "Si", value: true },
                                  { label: "No", value: false },
                                ]}
                                onChange={(selected) =>
                                  this.onChangeHandler(
                                    selected.value,
                                    "italianDrivingLicenseOwner"
                                  )
                                }
                                className="smallBtn"
                                unselectable={false}
                              />
                              <ContextFeedback
                                show={this.state.errors.includes("italianDrivingLicenseOwner")}
                                message={
                                  this.state.errorTexts.find(elem => elem.field === "italianDrivingLicenseOwner") ??
                                  {msg: "Campo obbligatorio", severity: "error"}
                                }
                              />
                            </div>
                            <div className="col-12 mb-4">
                              <label>A che età ha preso la patente?</label>
                              <Dropdown
                                value={step3Data.owner?.drivingLicenseAge}
                                options={this.state.ownerDrivingLicenseAgeList}
                                onChange={(selected) => this.onChangeHandler(selected.value, "drivingLicenseAgeOwner")}
                                onHide={() => removeFocusClass()}
                                optionLabel="label"
                                placeholder="Seleziona"
                                emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                              />
                              <ContextFeedback
                                show={this.state.errors.includes("drivingLicenseAgeOwner")}
                                message={
                                  this.state.errorTexts.find(elem => elem.field === "drivingLicenseAgeOwner") ??
                                  {msg: this.state.serverSideCheckErrorOwnerLicenseAge, severity: "error"}
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-12">
                    <Divider className="divider-fields" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h3 className="titleLev5 mb-2">Il conducente</h3>
                  </div>
                  <div className="col-12 mb-4">
                    <label>
                      {`Il conducente abituale e il contraente della tua ${this.vehicle} coincidono?`}
                    </label>
                    <Dropdown
                      value={step3Data.contractorAndDriverCoincide}
                      options={this.state.peopleCoincideList}
                      onChange={(selected) =>
                        this.onChangeHandler(
                          selected.value,
                          "contractorAndDriverCoincide"
                        )
                      }
                      onHide={() => removeFocusClass()}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractorAndDriverCoincide")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractorAndDriverCoincide") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  {(step3Data.contractorAndDriverCoincide !== undefined && step3Data.contractorAndDriverCoincide !== "si") && 
                    <div className="col-12 mb-4">
                      <label>
                        {`Il conducente abituale e il proprietario della tua ${this.vehicle} coincidono?`}
                      </label>
                      <Dropdown
                        value={step3Data.ownerAndDriverCoincide}
                        options={this.state.peopleCoincideList}
                        onChange={(selected) => this.onChangeHandler(selected.value, "ownerAndDriverCoincide")}
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("ownerAndDriverCoincide")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "ownerAndDriverCoincide") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  }

                  {this.isVisible("driver") && step3Data.ownerAndDriverCoincide !== "si" &&
                    <div className="row">
                      <div className="col-12 mb-1">
                        <h3 className="titleLev6 mb-2">Informazioni sul conducente abituale</h3>
                      </div>
                      <div className="col-12 mb-4">
                        <label>Sesso</label>
                        <SelectButton
                          value={step3Data.driver?.gender}
                          options={[
                            { label: "Uomo", value: "uomo" },
                            { label: "Donna", value: "donna" },
                          ]}
                          onChange={(selected) =>
                            this.onChangeHandler(
                              selected.value,
                              "genderDriver"
                            )
                          }
                          className="regularBtn"
                          unselectable={false}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("genderDriver")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "genderDriver") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                      <div className="col-xxl-6 col-12 mb-4">
                        <label>Nome</label>
                        <InputText
                          value={step3Data.driver?.name ?? ""}
                          onChange={(selected) =>
                            this.onChangeHandler(
                              selected.target.value,
                              "nameDriver"
                            )
                          }
                          placeholder="Nome"
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("nameDriver")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "nameDriver") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                      <div className="col-xxl-6 col-12 mb-4">
                        <label>Cognome</label>
                        <InputText
                          value={step3Data.driver?.surname ?? ""}
                          onChange={(selected) =>
                            this.onChangeHandler(
                              selected.target.value,
                              "surnameDriver"
                            )
                          }
                          placeholder="Cognome"
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("surnameDriver")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "surnameDriver") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                      <div className="col-12 mb-4">
                        <label
                          className={step3Data.driver?.validDateOfBirthLabel
                              ? ""
                              : "gray-label"
                          }
                        >
                          Data di nascita
                        </label>
                        <InputDate
                          id="contraenete-date-of-birth"
                          date={driverBirthDate}
                          onFocus={(e) => {this.currentPersonCardType = "driver"}}
                          onBlur={stringDate => {
                            this.onBlurHandlerDate(stringDate, (updatedStep3Data: Step3Data) => {
                              this.setState({
                                driverDrivingLicenseAgeList: getDrivingLicenseAgeOptions(updatedStep3Data.driver?.dateOfBirth)
                              })
                            })
                          }}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("dateOfBirthDriver")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "dateOfBirthDriver") ??
                            {msg: this.state.serverSideCheckErrorDriverDob, severity: "error"}
                          }
                        />
                      </div>
                      <div className="col-12 mb-4">
                        <label>Luogo di nascita</label>
                        <SelectButton
                          value={step3Data.driver?.bornInItaly}
                          options={[
                            { label: "Italia", value: true },
                            { label: "Altro", value: false },
                          ]}
                          onChange={(selected) =>
                            this.onChangeHandler(
                              selected.value,
                              "bornInItalyDriver"
                            )
                          }
                          className="regularBtn"
                          unselectable={false}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("bornInItalyDriver")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "bornInItalyDriver") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                      {step3Data.driver?.bornInItaly && (
                        <div className="col-12 mb-4">
                          <label>Comune di nascita</label>
                          <AutoComplete
                            value={step3Data.driver?.cityOfBirth}
                            suggestions={this.state.autocompleteCities}
                            completeMethod={(e) =>
                              fetchAutocomplete(
                                e,
                                Constants.SEISICURO_AJAX_CITY_OF_BIRTH_ENDPOINT,
                                (result: any) => {
                                  this.setState({
                                    autocompleteCities: result,
                                  });
                                },
                                "NASCITA"
                              )
                            }
                            field="value"
                            placeholder="Comune di nascita"
                            onChange={(selected) =>
                              this.onChangeHandler(
                                selected.value,
                                "cityOfBirthDriver"
                              )
                            }
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("cityOfBirthDriver")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "cityOfBirthDriver") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                      )}
                      {step3Data.driver?.bornInItaly === false && (
                        <div className="col-12 mb-4">
                          <label>Paese di nascita</label>
                          <AutoComplete
                            value={step3Data.driver?.countryOfBirth}
                            suggestions={this.state.autocompleteCountries}
                            completeMethod={this.searchCountry}
                            field="value"
                            placeholder="Paese di nascita"
                            onChange={(selected) =>
                              this.onChangeHandler(
                                selected.value,
                                "countryOfBirthDriver"
                              )
                            }
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("countryOfBirthDriver")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "countryOfBirthDriver") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                      )}
                      <div className="col-12">
                        <div className="row">
                          <Address
                            cityOfResidence={step3Data.driver?.cityOfResidence}
                            address={step3Data.driver?.address}
                            civico={step3Data.driver?.addressNumber}
                            postalCodeOfResidence={step3Data.driver?.postalCodeOfResidence}
                            labelFullAddress="Indirizzo di residenza"
                            placeholderFullAddress="Via delle Rose, 23, Roma"
                            placeholderAddress="Via delle Rose, 23"
                            onDone={(addressData: any[]) => {
                              if(addressData){
                                const [address, postalCode, cityOfResidence, civico] = addressData;
                                this.updateAddress("driver", address, postalCode, cityOfResidence, civico)
                              }
                            }}
                            errors={this.state.errors
                              .filter((error: string) => error.endsWith("Driver"))
                              .map((error: string) => error.replace("Driver", ""))
                            }
                            errorTexts={this.state.errorTexts
                              .filter((feedback: IFeedbackText) => feedback.field.endsWith("Driver"))
                              .map((feedback: IFeedbackText) => {
                                return {...feedback, field: feedback.field.replace("Driver", "")}
                              })
                            }
                          />

                          <div className="col-12 mt-4 mb-4">
                            <label>Stato civile</label>
                            <Dropdown
                              value={step3Data.driver?.civilState}
                              options={this.props.civilStateList}
                              onChange={(selected) =>
                                this.onChangeHandler(
                                  selected.value,
                                  "civilStateDriver"
                                )
                              }
                              onHide={() => removeFocusClass()}
                              optionLabel="label"
                              placeholder="Stato civile"
                              emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                            />
                            <ContextFeedback
                              show={this.state.errors.includes("civilStateDriver")}
                              message={
                                this.state.errorTexts.find(elem => elem.field === "civilStateDriver") ??
                                {msg: "Inserisci lo stato civile", severity: "error"}
                              }
                            />
                          </div>
                          <div className="col-12 field-owner-children mb-4">
                            <label>Figli</label>
                            <SelectButton
                              value={step3Data.driver?.children}
                              options={[
                                { label: "Senza figli", value: 0 },
                                { label: "Figli minorenni", value: 1 },
                                { label: "Figli maggiorenni", value: 2 },
                              ]}
                              onChange={(selected) =>
                                this.onChangeHandler(
                                  selected.value,
                                  "childrenDriver"
                                )
                              }
                              multiple
                              unselectable={step3Data?.driver?.children?.length !== 1} // fix selezione numero figli
                            />
                            <ContextFeedback
                              show={this.state.errors.includes("childrenDriver")}
                              message={
                                this.state.errorTexts.find(elem => elem.field === "childrenDriver") ??
                                {msg: "Campo obbligatorio", severity: "error"}
                              }
                            />
                          </div>
                          <div className="col-xxl-5 col-12 mb-4">
                            <label>Titolo di studio</label>
                            <Dropdown
                              value={step3Data.driver?.degree}
                              options={this.props.degreeList}
                              onChange={(selected) =>
                                this.onChangeHandler(
                                  selected.value,
                                  "degreeDriver"
                                )
                              }
                              onHide={() => removeFocusClass()}
                              optionLabel="label"
                              placeholder="Seleziona"
                              emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                            />
                            <ContextFeedback
                              show={this.state.errors.includes("degreeDriver")}
                              message={
                                this.state.errorTexts.find(elem => elem.field === "degreeDriver") ??
                                {msg: "Inserisci il titolo di studio", severity: "error"}
                              }
                            />
                          </div>
                          <div className="col-xxl-7 col-12 mb-4">
                            <label>Professione</label>
                            <Dropdown
                              value={step3Data.driver?.profession}
                              options={this.props.professionList}
                              onChange={(selected) =>
                                this.onChangeHandler(
                                  selected.value,
                                  "professionDriver"
                                )
                              }
                              onHide={() => removeFocusClass()}
                              optionLabel="label"
                              placeholder="Seleziona"
                              emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                            />
                            <ContextFeedback
                              show={this.state.errors.includes("professionDriver")}
                              message={
                                this.state.errorTexts.find(elem => elem.field === "professionDriver") ??
                                {msg: "Inserisci la professione", severity: "error"}
                              }
                            />
                          </div>
                          <div className="col-12 mb-4">
                            <label>
                              Il conducente abituale ha una patente italiana?
                            </label>
                            <SelectButton
                              value={step3Data.driver?.italianDrivingLicense}
                              options={[
                                { label: "Si", value: true },
                                { label: "No", value: false },
                              ]}
                              onChange={(selected) =>
                                this.onChangeHandler(
                                  selected.value,
                                  "italianDrivingLicenseDriver"
                                )
                              }
                              className="smallBtn"
                              unselectable={false}
                            />
                            <ContextFeedback
                              show={this.state.errors.includes("italianDrivingLicenseDriver")}
                              message={
                                this.state.errorTexts.find(elem => elem.field === "italianDrivingLicenseDriver") ??
                                {msg: "Campo obbligatorio", severity: "error"}
                              }
                            />
                          </div>
                          <div className="col-12 mb-4">
                            <label>A che età ha preso la patente?</label>
                            <Dropdown
                              value={step3Data.driver?.drivingLicenseAge}
                              options={this.state.driverDrivingLicenseAgeList}
                              onChange={(selected) => this.onChangeHandler(selected.value, "drivingLicenseAgeDriver")}
                              onHide={() => removeFocusClass()}
                              optionLabel="label"
                              placeholder="Seleziona"
                              emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                            />
                            <ContextFeedback
                              show={this.state.errors.includes("drivingLicenseAgeDriver")}
                              message={
                                this.state.errorTexts.find(elem => elem.field === "drivingLicenseAgeDriver") ??
                                {msg: this.state.serverSideCheckErrorDriverLicenseAge, severity: "error"}
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <div className="col-12">
                    <Divider className="divider-fields" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h3 className="titleLev5 mb-2">Altre informazioni</h3>
                  </div>
                  <div className="col-12 mb-4">
                    <label>Ci sono altri conducenti?</label>
                    <SelectButton
                      value={step3Data.otherDriversExist}
                      options={[
                        { label: "Si", value: true },
                        { label: "No", value: false },
                      ]}
                      onChange={(selected) => this.onChangeHandler(selected.value, "otherDriversExist")}
                      className="smallBtn"
                      unselectable={false}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("otherDriversExist")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "otherDriversExist") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  {this.isVisible("youngestOtherDriverAge") && (
                    <div className="col-12 mb-4">
                      <label>Indica l'età del più giovane</label>
                      <Dropdown
                        value={step3Data.youngestOtherDriverAge}
                        options={this.state.youngestOtherDriverAgeList}
                        onChange={(selected) => this.onChangeHandler(selected.value, "youngestOtherDriverAge")}
                        onHide={() => removeFocusClass()}
                        optionLabel="label"
                        placeholder="Seleziona"
                        emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("youngestOtherDriverAge")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "youngestOtherDriverAge") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  )}
                  {this.isVisible("otherDriversNoviceExist") && (
                    <div>
                      <div className="col-12 mb-4">
                        <label>
                          Tra questi, vi è qualcuno patentato da meno di due
                          anni?
                        </label>
                        <SelectButton
                          value={step3Data.otherDriversNoviceExist}
                          options={[
                            { label: "Si", value: true },
                            { label: "No", value: false },
                          ]}
                          onChange={(selected) => this.onChangeHandler(selected.value, "otherDriversNoviceExist")}
                          className="smallBtn"
                          unselectable={false}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("otherDriversNoviceExist")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "otherDriversNoviceExist") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-12 mb-4">
                    <label>
                      Ci sono familiari residenti con il proprietario e/o con il
                      conducente patentati e minori di 26 anni? Indica l'età del
                      più giovane
                    </label>
                    <Dropdown
                      value={step3Data.youngestFamilyDriver}
                      options={this.state.youngestFamilyDriverAgeList}
                      onChange={(selected) => this.onChangeHandler(selected.value, "youngestFamilyDriver")}
                      onHide={() => removeFocusClass()}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("youngestFamilyDriver")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "youngestFamilyDriver") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label>
                      Il conducente dichiara di avere una patente italiana mai
                      sospesa da 5 anni e con 20 punti o più, non aver mai preso
                      multe per stato di ebbrezza e non aver modificato il
                      veicolo.
                    </label>
                    <SelectButton
                      value={step3Data.contractoDeclares}
                      options={[
                        { label: "Si", value: true },
                        { label: "No", value: false },
                      ]}
                      onChange={(selected) => this.onChangeHandler(selected.value, "contractoDeclares")}
                      className="smallBtn"
                      unselectable={false}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("contractoDeclares")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "contractoDeclares") ??
                        {msg: "Campo obbligatorio", severity: "error"}
                      }
                    />
                  </div>
                  
                  {this.isVisible("moreQuestions") &&
                    <>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <label>Tipo di patente</label>
                          <Dropdown
                            value={step3Data.licenseType}
                            options={this.state.licenseTypesList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "licenseType")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("licenseType")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "licenseType") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <label>Punti nella patente</label>
                          <Dropdown
                            value={step3Data.licensePoints}
                            options={this.state.licensePointsList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "licensePoints")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("licensePoints")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "licensePoints") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <label>Multe per stato di ebbrezza</label>
                          <Dropdown
                            value={step3Data.drunkDrivingTickets}
                            options={this.state.drunkDrivingTicketsList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "drunkDrivingTickets")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("drunkDrivingTickets")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "drunkDrivingTickets") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <label>Numero sospensioni patente</label>
                          <Dropdown
                            value={step3Data.licenseSuspensions}
                            options={this.state.licenseSuspensionsList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "licenseSuspensions")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("licenseSuspensions")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "licenseSuspensions") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <label>Totale mesi sospensione patente</label>
                          <Dropdown
                            value={step3Data.totalLicenseSuspensionMonths}
                            options={this.state.licenseSuspensionMonthsList}
                            onChange={(selected) => this.onChangeHandler(selected.value, "totalLicenseSuspensionMonths")}
                            onHide={() => removeFocusClass()}
                            optionLabel="label"
                            placeholder="Seleziona"
                            emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                          />
                          <ContextFeedback
                            show={this.state.errors.includes("totalLicenseSuspensionMonths")}
                            message={
                              this.state.errorTexts.find(elem => elem.field === "totalLicenseSuspensionMonths") ??
                              {msg: "Campo obbligatorio", severity: "error"}
                            }
                          />
                        </div>
                      </div>
    
                      {this.isVisible("carModifications") &&
                        <div>
                          <Divider className="divider-fields" />
                          <div className="row">
                            <div className="p-col-12">
                              <h3 className="titleLev6 mb-2">Modifiche effettuate al veicolo</h3>
                            </div>
                            <div className="p-field-checkbox p-col-12">
                              <Checkbox
                                inputId="vehicleMod1"
                                onChange={(event) => this.props.step3DataSuccess({vehicleModificationsAlloyWheels: event.checked})}
                                checked={step3Data.vehicleModificationsAlloyWheels}
                              />
                              <label htmlFor="vehicleMod1">Cerchi in lega</label>
                            </div>
                            <div className="p-field-checkbox p-col-12">
                              <Checkbox
                                inputId="vehicleMod2"
                                onChange={(event) => this.props.step3DataSuccess({vehicleModificationsInvalidity: event.checked})}
                                checked={step3Data.vehicleModificationsInvalidity}
                              />
                              <label htmlFor="vehicleMod2">
                                Dovute ad invalidità fisica
                              </label>
                            </div>
                            <div className="p-field-checkbox p-col-12">
                              <Checkbox
                                inputId="vehicleMod3"
                                onChange={(event) => this.props.step3DataSuccess({vehicleModificationsWindowTint: event.checked})}
                                checked={step3Data.vehicleModificationsWindowTint}
                              />
                              <label htmlFor="vehicleMod3">Finestrini scuriti</label>
                            </div>
                            <div className="p-field-checkbox p-col-12">
                              <Checkbox
                                inputId="vehicleMod4"
                                onChange={(event) => this.props.step3DataSuccess({vehicleModificationsOther: event.checked})}
                                checked={step3Data.vehicleModificationsOther}
                              />
                              <label htmlFor="vehicleMod4">Altro</label>
                            </div>
                          </div>
                        </div>
                      }
                      {this.isVisible("motorCycleModifications") &&
                        <div>
                          <Divider className="divider-fields" />
                          <div className="row">
                            <div className="col-12">
                              <h3 className="titleLev6 mb-2">Modifiche effettuate alla moto</h3>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod1"
                                onChange={(event) => this.props.step3DataSuccess({vehicleModificationsEngineAntitheft: event.checked})}
                                checked={step3Data.vehicleModificationsEngineAntitheft}
                              />
                              <label htmlFor="vehicleMod1">
                                Barre antiurto del motore
                              </label>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod2"
                                onChange={(event) => this.props.step3DataSuccess({vehicleModificationsExhaust: event.checked})}
                                checked={step3Data.vehicleModificationsExhaust}
                              />
                              <label htmlFor="vehicleMod2">
                                Modifica dello scarico
                              </label>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod3"
                                onChange={(event) => this.props.step3DataSuccess({vehicleModificationsDecals: event.checked})}
                                checked={step3Data.vehicleModificationsDecals}
                              />
                              <label htmlFor="vehicleMod3">
                                Carenatura aerografata
                              </label>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod3"
                                onChange={(event) => this.props.step3DataSuccess({vehicleModificationsRemovableBags: event.checked})}
                                checked={step3Data.vehicleModificationsRemovableBags}
                              />
                              <label htmlFor="vehicleMod3">
                                Borse laterali rimovibili
                              </label>
                            </div>
                            <div className="p-field-checkbox col-12">
                              <Checkbox
                                inputId="vehicleMod4"
                                onChange={(event) => this.props.step3DataSuccess({vehicleModificationsOther: event.checked})}
                                checked={step3Data.vehicleModificationsOther}
                              />
                              <label htmlFor="vehicleMod4">Altro</label>
                            </div>
                            <Divider className="divider-fields" />
                          </div>
                        </div>
                      }
                    </>
                  }

                  {this.isVisible("satelliteTracker") && this.vehicle === "auto" &&
                    <div className="col-12 mb-4">
                      <label>
                        Desideri visualizzare anche offerte di prodotti
                        satellitari?
                      </label>
                      <SelectButton
                        value={step3Data.includeSatProducts}
                        options={[
                          { label: "Si", value: true },
                          { label: "No", value: false },
                        ]}
                        onChange={(selected) => this.onChangeHandler(selected.value, "includeSatProducts")}
                        className="smallBtn"
                        unselectable={false}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("includeSatProducts")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "includeSatProducts") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  }

                  {Constants.ENABLE_NO_CARD &&
                    <div className="col-12 mb-4">
                      <label>
                        Vuoi visualizzare anche i risultati di compagnie non aderenti alla CARD? Ti indicheremo sempre quali sono CARD/noCARD.
                      </label>
                      <SelectButton
                        value={step3Data?.includeNonCard}
                        options={[
                          { label: "Si", value: true },
                          { label: "No", value: false },
                        ]}
                        onChange={(selected) => this.onChangeHandler(selected.value, "includeNonCard")}
                        className="smallBtn"
                        unselectable={false}
                      />
                      <ContextFeedback
                        show={this.state.errors.includes("includeNonCard")}
                        message={
                          this.state.errorTexts.find(elem => elem.field === "includeNonCard") ??
                          {msg: "Campo obbligatorio", severity: "error"}
                        }
                      />
                    </div>
                  }

                  {this.isVisible("vehicleIsMotorCycle") &&
                    <>
                      <div className="col-12 mb-4">
                        <label>
                          Qual è la cilindrata più alta che hai guidato sino ad
                          oggi?
                        </label>
                        <Dropdown
                          value={step3Data.highestDisplacement}
                          options={this.state.highestDisplacementList}
                          onChange={(selected) => this.onChangeHandler(selected.value, "highestDisplacement")}
                          onHide={() => removeFocusClass()}
                          optionLabel="label"
                          placeholder="Seleziona"
                          emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("highestDisplacement")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "highestDisplacement") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                      
                      <div className="col-12 mb-4">
                        <label>
                          Da quanti mesi consecutivi guidi il tuo veicolo?
                        </label>
                        <Dropdown
                          value={step3Data.consecutiveDrivingMonths}
                          options={this.state.consecutiveDrivingMonthsList}
                          onChange={(selected) => this.onChangeHandler(selected.value, "consecutiveDrivingMonths")}
                          onHide={() => removeFocusClass()}
                          optionLabel="label"
                          placeholder="Seleziona"
                          emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("consecutiveDrivingMonths")}
                          message={
                            this.state.errorTexts.find(elem => elem.field === "consecutiveDrivingMonths") ??
                            {msg: "Campo obbligatorio", severity: "error"}
                          }
                        />
                      </div>
                    </>
                  }
                </div>

                <Divider className="divider-fields" />

                {/* START NUOVA GDPR */}
                <div className="row mt-4">
                  <div className="col-12">
                    <h3 className="titleLev5">Informativa IVASS</h3>
                  </div>
                  <div className="col-12 registrationPrivacy">
                    <div className="mb-2">
                      <div className="simpleCheckBlock">
                        <Checkbox
                          checked={step3Data.acceptIvass106 ?? false}
                          onChange={e => this.onChangeHandler(e.checked, "acceptIvass106")}
                        />
                        <ContextFeedback
                          show={this.state.errors.includes("acceptIvass106")}
                          message={{msg: "Dichiarare la presa visione dell'informativa precontrattuale prima di procedere", severity: "error"}}
                        />
                        <p>
                          Dichiaro di aver letto l'informativa precontrattuale di cui alla normativa vigente ai sensi del d.lgs. 209/2005 
                          (Codice delle Assicurazioni Private) e leggi e regolamenti collegati e di averne stampato o salvato copia. 
                          Clicca sui link per visualizzare i documenti Allegati&nbsp;
                          <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.6sicuro.it/wp-content/uploads/2023/11/SPA-Allegato-3_rev-2023-howden.pdf">3</a>,&nbsp;
                          <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.6sicuro.it/wp-content/uploads/2023/11/SPA-Allegato-4_rev-2023-Howden.pdf">4</a>&nbsp;
                          e&nbsp;
                          <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.6sicuro.it/wp-content/uploads/2023/11/SPA-Allegato-4TER_rev-2023-howden.pdf">4 TER</a>. (obbligatorio)
                        </p>
                      </div>
                      <div style={{paddingLeft: "1.2rem"}}>
                        <p style={{marginBottom: "0.5rem"}}>
                          • In ottemperanza all'art. 56 comma 2 e 3 del Reg. IVASS 40/2018, dichiaro di avere salvato o stampato, letto, compreso ed archiviato su supporto duraturo gli Allegati 3, e 4 TER. In ottemperanza all'art. 9 comma 4 del Reg. IVASS 23/2008, dichiaro altresì di avere stampato, letto, compreso ed archiviato su supporto duraturo la tabella contenente le informazioni sui livelli provvigionali percepiti dalle compagnie assicurative con cui 6sicuro brand di Howden ha rapporti di affari nel ramo RCA.
                        </p>
                        <p style={{marginBottom: "0.5rem"}}>
                          • In ottemperanza all'art. 34 del D.L. 1/2012 e dell'art. 6 del Documento di Consultazione 49/2012, dichiaro di aver preso visione delle note informative relative ai prodotti assicurativi, già predisposte e pubblicate sui siti internet aziendali delle compagnie assicurative di cui il preventivatore di 6sicuro (brand di Howden) fornisce le quotazioni e raggiungibili dalla pagina note informative. 
                        </p>
                        <p style={{marginBottom: "0.5rem"}}>
                          • In ottemperanza all'art.61 del Reg. IVASS 40/2018, dichiaro di voler ricevere tramite l’email comunicata in fase di accesso  la documentazione precontrattuale, i preventivi e la documentazione contrattuale, nonché ogni altra comunicazione proveniente da Howden, con riferimento ai contratti in corso e a quelli futuri. Sono consapevole della possibilità di modificare la mia scelta in qualsiasi momento, Condizioni generali del servizio.
                        </p>
                      </div>
                    </div>

                    <div>
                      <label className="simpleCheckBlock">
                        <Checkbox
                          checked={step3Data.acceptCgu105}
                          onChange={e => this.onChangeHandler(e.checked, "acceptCgu105")}
                        />
                        <p>
                          Accetto le condizioni generali del servizio di comparazione offerto da 6sicuro 
                          che dichiaro di aver letto e compreso al seguente <a href="https://www.6sicuro.it/condizioni-generali-di-utilizzo/" target="blank">link</a>&nbsp;
                          (necessario per usufruire dei servizi offerti dal Sito).</p>
                      </label>
                      <ContextFeedback
                        show={this.state.errors.includes("acceptCgu105")}
                        message={{msg: "Accettare le condizioni generali di utilizzo prima di procedere", severity: "error"}}
                      />
                    </div>

                    <div className="col-12">
                      <h3 className="titleLev5">Informativa privacy</h3>
                    </div>

                    <div>
                      <label className="simpleCheckBlock">
                        <Checkbox
                          checked={step3Data.acceptSeiSicuroPrivacyPolicy ?? false}
                          onChange={e => this.onChangeHandler(e.checked, "acceptSeiSicuroPrivacyPolicy")}
                        />
                        <p>Dichiaro di aver letto <a href="https://www.6sicuro.it/privacy/" target="blank">l’informativa sulla privacy</a> redatta ai sensi del Regolamento (UE) 2016/679 ("GDPR") e specificatamente gli artt. 2.1.a, 2.1.b, 2.1.c, 2.1.d, 2.1.e, 2.i.f, 2.1.g, 2.1.h, 2.1.i. (obbligatorio)</p>
                      </label>
                      <ContextFeedback
                        show={this.state.errors.includes("acceptSeiSicuroPrivacyPolicy")}
                        message={{msg: "Dichiarare la presa visione dell'informativa sulla privacy prima di procedere", severity: "error"}}
                      />
                    </div>

                    <div>
                      <label className="simpleCheckBlock">
                        <Checkbox
                          checked={step3Data.acceptGdprPrivacyPolicy22 ?? false}
                          onChange={e => this.onChangeHandler(e.checked, "acceptGdprPrivacyPolicy22")}
                        />
                        <p>Acconsento all’invio della newsletter (art. 2.1.e dell’informativa) (facoltativo)</p>
                      </label>
                    </div>
                    <div>
                      <label className="simpleCheckBlock">
                        <Checkbox
                          checked={step3Data.acceptGdprPrivacyPolicy102 ?? false}
                          onChange={e => this.onChangeHandler(e.checked, "acceptGdprPrivacyPolicy102")}
                        />
                        <p>Acconsento al trattamento dei dati per le finalità di comunicazione e commerciali operate direttamente da Howden (art. 2.1.f dell’informativa) (facoltativo)</p>
                      </label>  
                    </div>
                    <div>
                      <label className="simpleCheckBlock">
                        <Checkbox
                          checked={step3Data.acceptGdprPrivacyPolicy3 ?? false}
                          onChange={e => this.onChangeHandler(e.checked, "acceptGdprPrivacyPolicy3")}
                        />
                        <p>Acconsento al trattamento dei dati per le attività di profilazione (art. 2.1.g dell’informativa) (facoltativo)</p>
                      </label>
                    </div>
                    <div>
                      <label className="simpleCheckBlock">
                        <Checkbox
                          checked={step3Data.acceptGdprPrivacyPolicy104 ?? false}
                          onChange={e => this.onChangeHandler(e.checked, "acceptGdprPrivacyPolicy104")}
                        />
                        <p>Acconsento al trattamento dei dati per la le finalità di comunicazione, cessione o concessione in uso di dati a terze parti per finalità proprie di queste ultime, per le seguenti categorie (art. 2.1.i dell’informativa) (facoltativo): </p>
                      </label>
                    </div>
                    <div style={{paddingLeft: "1.2rem"}}>
                      <p style={{marginBottom: "0.5rem"}}>• Bancario, finanziario, assicurativo</p>
                      <p style={{marginBottom: "0.5rem"}}>• Automotive</p>
                      <p style={{marginBottom: "0.5rem"}}>• Beni di largo consumo</p>
                      <p style={{marginBottom: "0.5rem"}}>• Telecomunicazioni, telefonia, informatica</p>
                      <p style={{marginBottom: "0.5rem"}}>• Servizi alla persona e tempo libero</p>
                    </div>
                  </div>                  
                </div>
                {/* END NUOVA GDPR */}

              </div>
            )}
          </div>

          <div className="step-btn-container p-fluid p-4 p-lg-5">
            <div className="row">
              <div className="col-6 button-back">
                <Button
                  className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                  type="button"
                  label="Indietro"
                  onClick={() => {
                    this.props.userInfoDataSuccess({
                      direction: "backwards",
                      nextStep: 3
                    });
                    this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(2, this.vehicle));
                  }}
                />
              </div>
              <div className="col-6 button-next">
                <Button
                  className="customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                  type="button"
                  label="Continua"
                  onClick={() => {
                    this.props.userInfoDataSuccess({ direction: "forwards", nextStep: 4 });
                    this.handleFormSubmit();
                  }}
                  loading={this.state.loading}
                />
              </div>

              <div className="col-12">
                <CaptchaComponent 
                  isManaged={ this.state.showTurnstileManaged === true }
                  successCallBack={this.saveCaptchaToken}
                  setLoadingCallBack={this.disableFormSubmit}
                />
                <ContextFeedback
                    show={this.state.errors.includes('["captcha"]')}
                    message={
                      {msg: "Compilare il controllo anti bot", severity: "error"}
                    }
                  />
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  private disableFormSubmit () {
    this.setState(
      { loading: true }
    )
  }

  /** 
   * salva il token generato dal capthca e abilita l'invio della form
   */
  private saveCaptchaToken (cToken: any) {
    this.setState ({
      turnstileToken: cToken
    }, () => {
      this.setState(
        { loading: false }
      )
    })
  }


  /**
   * Show/hide the gdpr sub checkboxes.
   */
  private showMoreGenerale() {
    this.setState(
      {
        detailGeneraleIsVisible: !this.state.detailGeneraleIsVisible,
      },
      () => {
        if (
          this.state.detailGeneraleIsVisible &&
          this.privacyGdprCollection !== null
        ) {
          this.privacyGdprCollection.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    );
  }

  /**
   * Show/hide the privacy sub checkboxes.
   */
  private showMoreIvass() {
    this.setState(
      {
        detailIvassIsVisible:
          !this.state.detailIvassIsVisible,
      },
      () => {
        if (
          this.state.detailIvassIsVisible &&
          this.privacySeiSicuroCollection !== null
        ) {
          this.privacySeiSicuroCollection.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    );
  }

  /**
   * Visibility check for the youngest child dropdown.
   *
   * @returns {boolean} whether the youngest child dropdown should be visible
   */
  private isVisiblecontractorYoungestChild() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;
    if (
      step3Data.contractorChildren === undefined ||
      step3Data.contractorChildren.includes(0)
    ) {
      is_visible = false;
    } else {
      is_visible = step3Data.contractorChildren.includes(1);
    }
    return is_visible;
  }

  /**
   * Visibility check for the bersani decree dropdown.
   *
   * @returns {boolean} whether the bersani decree dropdown should be visible
   */
  private isVisibleBersaniDecree() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return (
      step3Data.vehicleInsuranceStatus === "aMioNomePrimaVoltaNuovo" ||
      step3Data.vehicleInsuranceStatus === "aMioNomePrimaVoltaUsato"
    );
  }

  /**
   * Visibility check for the "assicurata a mio nome" form part.
   *
   * @returns whether the "assicurata a mio nome" form part should be visible
   */
  private isVisibleAssicurataAMioNome() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return step3Data.vehicleInsuranceStatus === "assicurataAMioNome";
  }

  /**
   * Visibility check for the "years in class 1".
   *
   * @returns {boolean} whether the "years in class 1" dropdown should be visible
   */
  private isVisibleYearsInClass1List() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return (
      step3Data.currentInsuranceCreditClass !== undefined &&
      this.state.insuranceStatusList !== undefined &&
      this.state.insuranceStatusList.length > 0 &&
      step3Data.currentInsuranceCreditClass === "c1DaOltreUnAnno" && step3Data.vehicleInsuranceStatus ===
        this.state.insuranceStatusList[0].value
    );
  }

  /**
   * Visibility check for the cu license form part.
   *
   * @returns {boolean} whether the cu license form part should be visible
   */
  private isVisibleCuLicense() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return (
      step3Data.vehicleInsuranceStatus !== "assicurataAMioNome" &&
      (step3Data.bersaniDecree === "siVeicoloDelloStessoProprietario" ||
      step3Data.bersaniDecree === "SiVeicoloDiFamiliareConvivente")
    );
  }

  /**
   * Visibility check of the "owner and driver coincide" dropdown.
   *
   * @returns {boolean} whether "owner and driver coincide" dropdown should be visible
   */
  private isVisibleOwnerAndDriverCoincide() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;
    if (
      step3Data.contractorAndDriverCoincide === undefined &&
      step3Data.contractorAndOwnerCoincide === undefined
    ) {
      is_visible = false;
    } else {
      is_visible =
        step3Data.contractorAndDriverCoincide !== "si" &&
        step3Data.contractorAndOwnerCoincide !== "si";
    }
    return is_visible;
  }

  /**
   * Visibility check for the "youngest other driver age" dropdown.
   *
   * @returns {boolean} whether the "youngest other driver age" dropdown should be visible
   */
  private isVisibleYoungestOtherDriverAge() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    return step3Data.otherDriversExist ? true : false;
  }

  /**
   * Visibility check for the "more questions" (i.e. license points, license suspensions, etc) form part.
   *
   * @returns {boolean} whether the "more questions" (i.e. license points, license suspensions, etc) form part should be visible
   */
  private isVisibleMoreQuestions() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;
    if (step3Data.contractoDeclares === undefined) {
      is_visible = false;
    } else {
      is_visible = step3Data.contractoDeclares ? false : true;
    }
    return is_visible;
  }

  /**
   * Visibility check for the owner form part
   *
   * @returns {boolean} whether the owner form part should be visible
   */
  private isVisibleOwner() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;
    if (step3Data.contractorAndOwnerCoincide === undefined) {
      is_visible = false;
    } else {
      is_visible = step3Data.contractorAndOwnerCoincide !== "si" ? true : false;
    }
    return is_visible;
  }

  /**
   * Visibility check for the driver form part
   *
   * @returns {boolean} whether the driver form part should be visible
   */
  private isVisibleDriver() {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;
    if (step3Data.ownerAndDriverCoincide === undefined) {
      is_visible = false;
    } else {
      is_visible =
        (!this.isVisibleOwnerAndDriverCoincide() &&
          step3Data.contractorAndDriverCoincide !== "si") ||
        (step3Data.ownerAndDriverCoincide !== "si" &&
          step3Data.contractorAndDriverCoincide !== "si")
          ? true
          : false;
    }
    return is_visible;
  }

  /**
   * Check if current field is viewable.
   *
   * @param {string} current_field The current field
   *
   * @returns {boolean} Visibility check
   */
  private isVisible(current_field: string): boolean {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let is_visible;

    switch (current_field) {
      case "ownerFilled":
        is_visible =
          step3Data.contractorName !== undefined &&
          step3Data.contractorSurname !== undefined &&
          step3Data.contractorCityOfResidence !== undefined &&
          step3Data.contractorPostalCode !== undefined &&
          step3Data.contractorAddress !== undefined &&
          step3Data.contractorChildren !== undefined &&
          step3Data.contractorEmail !== undefined &&
          step3Data.contractorPhoneNumber !== undefined &&
          step3Data.contractorItalianDrivingLicense !== undefined &&
          step3Data.contractorDrivingLicenseAge !== undefined &&
          step3Data.contractorPreferredPaymentMethod !== undefined;
        break;
      case "contractorYoungestChild":
        is_visible = this.isVisiblecontractorYoungestChild();
        break;
      case "bersaniDecree":
        is_visible = this.isVisibleBersaniDecree();
        break;
      case "assicurataAMioNome":
        is_visible = this.isVisibleAssicurataAMioNome();
        break;
      case "accidentDetails":
        is_visible = this.isVisibleAssicurataAMioNome() && step3Data.pastAccidentCount !== undefined
          ? step3Data.pastAccidentCount > 0
          : false;
        break;
      case "yearsInClass1List":
        is_visible = this.isVisibleYearsInClass1List();
        break;
      case "cuLicensePlate":
      case "cuInsuranceClass":
        is_visible = this.isVisibleCuLicense();
        break;
      case "ownerAndDriverCoincide":
        is_visible = this.isVisibleOwnerAndDriverCoincide();
        break;
      case "youngestOtherDriverAge":
      case "otherDriversNoviceExist":
        is_visible = this.isVisibleYoungestOtherDriverAge();
        break;
      case "moreQuestions":
        is_visible = this.isVisibleMoreQuestions();
        break;
      case "owner":
        is_visible = this.isVisibleOwner();
        break;
      case "driver":
        is_visible = this.isVisibleDriver();
        break;
      case "carModifications":
        is_visible = this.isVisibleMoreQuestions() && this.vehicle === "auto";
        break;
      case "motorCycleModifications":
        is_visible = this.isVisibleMoreQuestions() && this.vehicle === "moto";
        break;
      case "satelliteTracker":
        is_visible = this.vehicle === "auto";
        break;
      case "vehicleIsMotorCycle":
        is_visible = this.vehicle === "moto";
        break;
      default:
        // is_visible = false;
        is_visible = true;
        break;
    }

    return is_visible;
  }

  private getContractorFromStore(): IPerson{
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    let contractor: IPerson = {};

    step1Data?.gender ?? (contractor.gender = step1Data.gender);
    step3Data?.contractorName ?? (contractor.name = step3Data.contractorName);
    step3Data?.contractorSurname ?? (contractor.surname = step3Data.contractorSurname);
    step1Data?.dateOfBirth ?? (contractor.dateOfBirth = new Date(step1Data.dateOfBirth as number));
    step1Data?.bornInItaly ?? (contractor.bornInItaly = step1Data.bornInItaly);
    step1Data?.cityOfBirth ?? (contractor.cityOfBirth = step1Data.cityOfBirth);
    step1Data?.countryOfBirth ?? (contractor.countryOfBirth = step1Data.countryOfBirth);
    step3Data?.contractorCityOfResidence ?? (contractor.cityOfResidence = step3Data.contractorCityOfResidence);
    step3Data?.contractorPostalCode ?? (contractor.postalCodeOfResidence = step3Data.contractorPostalCode);
    step3Data?.contractorAddress ?? (contractor.address = step3Data.contractorAddress);
    step3Data?.contractorAddressNumber ?? (contractor.addressNumber = step3Data.contractorAddressNumber);
    step3Data?.contractorAddressToponimo ?? (contractor.addressToponimo = step3Data.contractorAddressToponimo);
    step1Data?.civilState ?? (contractor.civilState = step1Data.civilState);
    step3Data?.contractorChildren ?? (contractor.children = step3Data.contractorChildren);
    step1Data?.degree ?? (contractor.degree = step1Data.degree);
    step1Data?.profession ?? (contractor.profession = step1Data.profession);
    step3Data?.contractorItalianDrivingLicense ?? (contractor.italianDrivingLicense = step3Data.contractorItalianDrivingLicense);
    step3Data?.contractorDrivingLicenseAge ?? (contractor.drivingLicenseAge = step3Data.contractorDrivingLicenseAge);
    step1Data?.email ?? (contractor.email = step3Data.contractorEmail);
    step1Data?.phone ?? (contractor.phone = step3Data.contractorPhoneNumber);
    step3Data?.contractorYoungestChild ?? (contractor.youngestChild = step3Data.contractorYoungestChild);

    return contractor;
  }

  /**
   * Handle the DropDown changes evaluating condition to fetch choices.
   *
   * @param selected The selected object
   * @param componentName The name of the component tp handle
   */
  private onChangeHandler(selected: any, componentName: string) {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    var newSelected;
    var senzaFigli: any;
    const contractor: IPerson = this.getContractorFromStore();

    switch (componentName) {
      case "contractorCityOfResidence":

        this.setState({ postalCodesList: [] }, () => this.props.step3DataSuccess({
            contractorCityOfResidence: selected.value,
            contractorPostalCode: undefined
          })
        );

        if (selected.value.id !== undefined) {
          fetchList(
            Constants.SEISICURO_AJAX_POSTAL_CODE_ENDPOINT(selected.value.id),
            (result: any) => {
              this.setState({ postalCodesList: result }, this.props.step3DataSuccess({
                contractorPostalCode: undefined
              }));
              if (result.length === 1) {
                this.onChangeHandler(result[0].id, "contractorPostalCode");
              }
            }
          );
        }
        break;
      case "contractorChildren":
        let childrenData: Step3Data = {};
        let childrenStored: number[] = step3Data?.contractorChildren ?? [];

        // questo evento change si triggera solo se viene effettivamente cambiato il valore della multiselect
        // se sono qui è perché è cambiato almeno un valore dell'array
    
        // se provengo da uno stato nel quale ho dichiarato di non aver figli ([0]), devo ripulire il value da 0
        if (childrenStored.includes(0)) {
          childrenData = {
            contractorChildren: selected.filter((arrayItem: any) => arrayItem !== 0).sort((a: any, b: any) => (a < b) ? -1 : (a > b) ? 1 : 0)
          };
        }
        // se provengo da uno stato nel quale ho dichiarato di avere almeno un figlio ([1] | [1,2]) e il nuovo valore è nessuno figlio ([0]) lo sovrascrivo direttamente
        else if (selected.includes(0)) {
          childrenData = {
            contractorChildren: [0],
            contractorYoungestChild: ""
          }
        }
        else{
          childrenData = {contractorChildren: selected};
        }

        this.saveReduxState(childrenData);
        break;
      case "contractorAndOwnerCoincide":
        {
          const dataToDispacth: Step3Data = {
            contractorAndOwnerCoincide: selected
          };
  
          if(selected === "si"){
            dataToDispacth.owner = contractor;
  
            if(step3Data.ownerAndDriverCoincide === "si"){ //se il proprietario coincide con il conducente
              dataToDispacth.driver = dataToDispacth.owner;
              dataToDispacth.ownerAndDriverCoincide = undefined;
              dataToDispacth.contractorAndDriverCoincide = "si";
            }
          }
          else{
            if(step3Data.contractorAndOwnerCoincide === "si"){  // se prima coincidevano
              dataToDispacth.owner = undefined;
            }
  
            if(step3Data.ownerAndDriverCoincide === "si"){ //se il proprietario coincide con il conducente
              dataToDispacth.driver = undefined;
            }
          }
          
  
          this.saveReduxState(dataToDispacth)
        }
        break;
      case "contractorAndDriverCoincide":
        const dataToDispacth: Step3Data = {
          contractorAndDriverCoincide: selected,
          driver: selected === "si"
            ? contractor
            : step3Data.contractorAndDriverCoincide !== "si"
              ? step3Data.driver
              : {},
        }

        this.saveReduxState(dataToDispacth)
        break;
      case "ownerAndDriverCoincide":
        {
          const dataToDispacth: Step3Data = {
            ownerAndDriverCoincide: selected,
            driver: selected === "si"
              ? step3Data.owner
              : step3Data.ownerAndDriverCoincide !== "si"
                ? step3Data.driver
                : {},
          }
  
          this.saveReduxState(dataToDispacth)
        }
        break;
      case "pastAccidentCount":
        let pastAccidentsInfo = step3Data.pastAccidentsInfo as IAccidentInfo[] ?? [];
        let dataAccidentToDispact: Step3Data = {};
        if (selected !== undefined && pastAccidentsInfo.length > selected) {
          dataAccidentToDispact = {
            pastAccidentCount: selected.toString(),
            pastAccidentsInfo: pastAccidentsInfo.splice(0, selected)
          }
        }
        else if (selected !== undefined && pastAccidentsInfo.length < selected) {
          const length: number = pastAccidentsInfo.length;
          for (let i = 0; i < selected - length; i++) {
            pastAccidentsInfo.push({
              year: undefined,
              responsibility: undefined,
            });
          }
          dataAccidentToDispact = {
            pastAccidentCount: selected.toString(),
            pastAccidentsInfo: pastAccidentsInfo
          }
        }
        else if (selected === 0 || selected === "0") {
          dataAccidentToDispact = {
            pastAccidentCount: 0,
            pastAccidentsInfo: []
          }
        }

        if(JSON.stringify(dataAccidentToDispact) !== "{}"){
          this.saveReduxState(dataAccidentToDispact)
        }
        break;
      case "accidentDetails":
      case "pastAccidentYear0":
      case "pastAccidentRespondibility0":
      case "pastAccidentYear1":
      case "pastAccidentRespondibility1":
      case "pastAccidentYear2":
      case "pastAccidentRespondibility2":
      case "pastAccidentYear3":
      case "pastAccidentRespondibility3":
      case "pastAccidentYear4":
      case "pastAccidentRespondibility4":
      case "pastAccidentYear5":
      case "pastAccidentRespondibility5":
      case "pastAccidentYear6":
      case "pastAccidentRespondibility6":
      case "pastAccidentYear7":
      case "pastAccidentRespondibility7":
      case "pastAccidentYear8":
      case "pastAccidentRespondibility8":
      case "pastAccidentYear9":
      case "pastAccidentRespondibility9":
      case "pastAccidentYear10":
      case "pastAccidentRespondibility10":
      case "pastAccidentYear11":
      case "pastAccidentRespondibility11":
      case "pastAccidentYear12":
      case "pastAccidentRespondibility12":
      case "pastAccidentYear13":
      case "pastAccidentRespondibility13":
      case "pastAccidentYear14":
      case "pastAccidentRespondibility14":
        {
          let newDetails = step3Data.pastAccidentsInfo as any[];
          newDetails[selected.index][selected.field] = selected.value;
  
          const dataToDispacth: Step3Data = {pastAccidentsInfo: newDetails}
  
          this.saveReduxState(dataToDispacth)
        }
        break;

      case "acceptSeiSicuroPrivacyPolicy":
        this.setState({acceptAllOptionalCollection: selected}, () => {
          this.saveReduxState({
            acceptSeiSicuroPrivacyPolicy: selected,
          })
        });
        break;
      case "acceptGdprPrivacyPolicy22":
        this.saveReduxState({acceptGdprPrivacyPolicy22: selected})
        break;

      case "acceptGdprPrivacyPolicy3":
        this.setState(
          {acceptAllOptionalCollection: step3Data.acceptGdprPrivacyPolicy104 && step3Data.acceptGdprPrivacyPolicy102 && selected},
          () => this.saveReduxState({acceptGdprPrivacyPolicy3: selected})
        );
        break;
      case "acceptGdprPrivacyPolicy102":
        this.setState(
          {acceptAllOptionalCollection: step3Data.acceptGdprPrivacyPolicy104 && step3Data.acceptGdprPrivacyPolicy3 && selected},
          () => this.saveReduxState({acceptGdprPrivacyPolicy102: selected})
        );
        break;
      case "acceptGdprPrivacyPolicy104":
        this.setState(
          {acceptAllOptionalCollection: step3Data.acceptGdprPrivacyPolicy102 && step3Data.acceptGdprPrivacyPolicy3 && selected},
          () => this.saveReduxState({acceptGdprPrivacyPolicy104: selected})
        );
        break;

      case "acceptAllOptionalCollection":
        this.setState(
          {acceptAllOptionalCollection: selected},
          () => this.saveReduxState({
            acceptGdprPrivacyPolicy102: selected,
            acceptGdprPrivacyPolicy3: selected,
            acceptGdprPrivacyPolicy104: selected
          })
        );
        break;

      case "genderOwner":
        this.saveReduxState({owner: {...step3Data.owner, gender: selected}})
        break;
      case "nameOwner":
        this.saveReduxState({owner: {...step3Data.owner, name: selected}})
        break;
      case "surnameOwner":
        this.saveReduxState({owner: {...step3Data.owner, surname: selected}})
        break;
      case "dateOfBirthOwner":
        this.setState(
          {ownerDrivingLicenseAgeList: getDrivingLicenseAgeOptions(selected)},
          () => this.saveReduxState({owner: {...step3Data.owner, dateOfBirth: selected}})
        );
        break;
      case "bornInItalyOwner":
        this.saveReduxState({owner: {...step3Data.owner, bornInItaly: selected}})
        break;
      case "cityOfBirthOwner":
        this.saveReduxState({owner: {...step3Data.owner, cityOfBirth: replaceSpecialChars(selected)}})
        break;
      case "countryOfBirthOwner":
        this.saveReduxState({owner: {...step3Data.owner, countryOfBirth: selected}})
        break;
      case "cityOfResidenceOwner":
        {
          let dataToDispacth: Step3Data = {}
  
          if(selected.value === ""){
            this.setState(
              {postalCodesOwnerList: []},
              () => {
                dataToDispacth = {
                  owner: {
                    ...step3Data.owner,
                    cityOfResidence: selected.value,
                    postalCodeOfResidence: undefined
                  }
                }
                this.saveReduxState(dataToDispacth)
              }
            )
          }
          else if(selected.value?.id !== undefined){
            fetchList(
              Constants.SEISICURO_AJAX_POSTAL_CODE_ENDPOINT(selected.value.id),
              (result: any) => {
                dataToDispacth =  {
                  owner: {
                    ...step3Data.owner,
                    cityOfResidence: selected.value,
                    postalCodeOfResidence: undefined
                  }
                }
                this.setState(
                  {postalCodesOwnerList: result},
                  () => {
                    this.saveReduxState(dataToDispacth)
                    if(result.length === 1){
                      this.onChangeHandler(result[0].id, "postalCodeOfResidenceOwner");
                    }
                  }
                )
              }
            );
          }
          else{
            dataToDispacth = {
              owner: {
                ...step3Data.owner,
                cityOfResidence: selected.value
              }
            }
            this.saveReduxState(dataToDispacth)
          }
        }

        break;
      case "postalCodeOfResidenceOwner":
        this.saveReduxState({owner: {...step3Data.owner, postalCodeOfResidence: selected}})
        break;
      case "addressOwner":
        this.saveReduxState({owner: {...step3Data.owner, address: selected}})
        break;
      case "civilStateOwner":
        this.saveReduxState({owner: {...step3Data.owner, civilState: selected}})
        break;
      case "childrenOwner":
        {
          let dataToDispacth: Step3Data = {}

          if (step3Data?.owner?.children?.includes(0)) {
            newSelected = selected.filter((arrayItem: any) => arrayItem !== 0);
            dataToDispacth = {owner: {...step3Data.owner, children: newSelected}}
          }
          else if (selected.includes(0)) {
            senzaFigli = [0];
            dataToDispacth = {owner: {...step3Data.owner, children: senzaFigli}}
          }
          else {
            dataToDispacth = {owner: {...step3Data.owner, children: selected}}
          }
          this.saveReduxState(dataToDispacth)
        }
        break;
      case "degreeOwner":
        this.saveReduxState({owner: {...step3Data.owner, degree: selected}})
        break;
      case "professionOwner":
        this.saveReduxState({owner: {...step3Data.owner, profession: selected}})
        break;
      case "italianDrivingLicenseOwner":
        this.saveReduxState({owner: {...step3Data.owner, italianDrivingLicense: selected}})
        break;
      case "drivingLicenseAgeOwner":
        this.saveReduxState({owner: {...step3Data.owner, drivingLicenseAge: selected}})
        break;

      case "genderDriver":
        this.saveReduxState({driver: {...step3Data.driver, gender: selected}})
        break;
      case "nameDriver":
        this.saveReduxState({driver: {...step3Data.driver, name: selected}})
        break;
      case "surnameDriver":
        this.saveReduxState({driver: {...step3Data.driver, surname: selected}})
        break;
      case "dateOfBirthDriver":
        this.setState({driverDrivingLicenseAgeList: getDrivingLicenseAgeOptions(selected)}, () => this.saveReduxState({driver: {...step3Data.driver, dateOfBirth: selected}}));
        break;
      case "bornInItalyDriver":
        this.saveReduxState({driver: {...step3Data.driver, bornInItaly: selected}})
        break;
      case "cityOfBirthDriver":
        this.saveReduxState({driver: {...step3Data.driver, cityOfBirth: replaceSpecialChars(selected)}})
        break;
      case "countryOfBirthDriver":
        this.saveReduxState({driver: {...step3Data.driver, countryOfBirth: selected}})
        break;
      case "cityOfResidenceDriver":
        {
          let dataToDispacth: Step3Data = {}
          if(selected.value === ""){
            this.setState(
              {postalCodesDriverList: []},
              () => {
                dataToDispacth = {
                  driver: {
                    ...step3Data.driver,
                    cityOfResidence: selected.value,
                    postalCodeOfResidence: undefined
                  }
                }
                this.saveReduxState(dataToDispacth)
              }
            )
          }
          else if(selected.value?.id !== undefined){
            fetchList(
              Constants.SEISICURO_AJAX_POSTAL_CODE_ENDPOINT(selected.value.id),
              (result: any) => {
                dataToDispacth =  {
                  driver: {
                    ...step3Data.driver,
                    cityOfResidence: selected.value,
                    postalCodeOfResidence: undefined
                  }
                }
                this.setState(
                  {postalCodesDriverList: result},
                  () => {
                    if(result.length === 1){
                      this.onChangeHandler(result[0].id, "postalCodeOfResidenceDriver");
                    }
                    this.saveReduxState(dataToDispacth)
                  }
                )
              }
            );
          }
          else{
            dataToDispacth = {
              driver: {
                ...step3Data.driver,
                cityOfResidence: selected.value
              }
            }
            this.saveReduxState(dataToDispacth)
          }
        }

        break;
      case "postalCodeOfResidenceDriver":
        this.saveReduxState({driver: {...step3Data.driver, postalCodeOfResidence: selected}})
        break;
      case "addressDriver":
        this.saveReduxState({driver: {...step3Data.driver, address: selected}})
        break;
      case "civilStateDriver":
        this.saveReduxState({driver: {...step3Data.driver, civilState: selected}})
        break;
      case "childrenDriver":
        {
          let dataToDispacth: Step3Data = {}
          if (step3Data?.driver?.children?.includes(0)) {
            newSelected = selected.filter((arrayItem: any) => arrayItem !== 0);
            dataToDispacth = {driver: {...step3Data.driver, children: newSelected}}
          }
          else if (selected.includes(0)) {
            senzaFigli = [0];
            dataToDispacth = {driver: {...step3Data.driver, children: senzaFigli}}
          }
          else {
            dataToDispacth = {driver: {...step3Data.driver, children: selected}}
          }
          this.saveReduxState(dataToDispacth)
        }

        break;
      case "degreeDriver":
        this.saveReduxState({driver: {...step3Data.driver, degree: selected}})
        break;
      case "professionDriver":
        this.saveReduxState({driver: {...step3Data.driver, profession: selected}})
        break;
      case "italianDrivingLicenseDriver":
        this.saveReduxState({driver: {...step3Data.driver, italianDrivingLicense: selected}})
        break;
      case "drivingLicenseAgeDriver":
        this.saveReduxState({driver: {...step3Data.driver, drivingLicenseAge: selected}})
        break;
      default:
        this.saveReduxState({[componentName as keyof Step3Data]: selected})
        break;
    }
    this.resetValidation(componentName);
  }

  /**
   * Reset element validation
   * @param element
   * @returns
   */
  resetValidation(element: any) {
    this.setState({
      errors: this.state.errors.filter((item: any) => item !== element),
    });
    return;
  }
  

  /**
   * Form validation.
   *
   * @param specificField whether to check validity of a specific field
   * @returns {boolean} Validation check
   */
  private validation(specificField?: any): Promise<IValidationResponse> {
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    let validation: IValidationResponse = {
      status: true,
      error_fields: [],
      error_messages: [],
    };
    let promises : Promise<IValidationResponse>[] = [];

    const contractor : IPerson = {
      name: step3Data.contractorName,
      surname: step3Data.contractorSurname,
      address: step3Data.contractorAddress,
      addressNumber: step3Data.contractorAddressNumber,
      cityOfResidence: step3Data.contractorCityOfResidence,
      postalCodeOfResidence: step3Data.contractorPostalCode,
      children: step3Data.contractorChildren,
      youngestChild: step3Data.contractorYoungestChild,
      email: step3Data.contractorEmail,
      phone: step3Data.contractorPhoneNumber,
      italianDrivingLicense: step3Data.contractorItalianDrivingLicense,
      drivingLicenseAge: step3Data.contractorDrivingLicenseAge,
    }
    
    promises.push(User.validatePerson (contractor, Object.entries(this.stepFields.contractor).map(([, value]) => value) as IFieldDescriptor[], "contractor")
      .then((result: IValidationResponse) => {
        let wrappedValidation : IValidationResponse;
        let {status, error_fields, error_messages} = result;

        wrappedValidation = {
          status: status,
          error_fields: this.mapFieldsPerson2Contractor(error_fields),
          error_messages: error_messages.map((feedback: IFeedbackText) => {
            return {field: this.mapFieldsPerson2Contractor([feedback.field])[0], msg: feedback?.msg, severity: feedback?.severity}
          })
        }
        return wrappedValidation
      })
    );

    if(step3Data?.contractorPreferredPaymentMethod === undefined ||
      step3Data.contractorPreferredPaymentMethod === "" ||
      !(this.state?.paymentMethodsList === undefined || (this.state.paymentMethodsList.find(item => item.value === step3Data.contractorPreferredPaymentMethod) ?? false))
    ){
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["contractorPreferredPaymentMethod"],
        error_messages: [{field: "contractorPreferredPaymentMethod", msg: "Seleziona un metodo di pagamento"}]
      }));
    }

    if (this.isVisible("ownerFilled")) {

      if(step3Data?.newInsuranceStart){
        const startData: Date = new Date(step3Data.newInsuranceStart);
        const now: Date = new Date();
        let error: boolean = false;
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        
        if(startData instanceof Date && !isNaN(startData.getTime())){
          if(startData < now){
            error = true;
          }
        }
        else{
          error = true;
        }

        if(error){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["newInsuranceStart"],
            error_messages: [{field: "newInsuranceStart", msg: "Seleziona una data valida"}]
          }));
        }
      }
      else{
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["newInsuranceStart"],
          error_messages: [{field: "newInsuranceStart", msg: "Seleziona una data valida"}]
        }));
      }

      if(step3Data?.vehicleInsuranceStatus === undefined ||
        step3Data.vehicleInsuranceStatus === "" ||
        !(this.state.insuranceStatusList === undefined || (this.state?.insuranceStatusList.find(item => item.value === step3Data.vehicleInsuranceStatus) ?? false))
      ){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["vehicleInsuranceStatus"],
          error_messages: [{field: "vehicleInsuranceStatus", msg: "Seleziona un'opzione"}]
        }));
      }

      if (this.isVisibleYearsInClass1List()) {
        if(step3Data?.yearsInClass1 === undefined ||
          !(this.state.yearsInClass1List === undefined || (this.state?.yearsInClass1List.find(item => item.value === step3Data.yearsInClass1) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["yearsInClass1"],
            error_messages: [{field: "yearsInClass1", msg: "Seleziona un'opzione'"}]
          }));
        }
      }

      if (this.isVisibleAssicurataAMioNome()) {

        if(step3Data?.currentInsuranceCreditClass === undefined ||
          !(this.state.insuranceClassList === undefined || (this.state?.insuranceClassList.find(item => item.value === step3Data.currentInsuranceCreditClass) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["currentInsuranceCreditClass"],
            error_messages: [{field: "currentInsuranceCreditClass", msg: "Seleziona un'opzione"}]
          }));
        }

        if(step3Data?.currentInsuranceCompany === undefined ||
          !(this.state.insuranceCompaniesList === undefined || (this.state?.insuranceCompaniesList.find(item => item.value === step3Data.currentInsuranceCompany) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["currentInsuranceCompany"],
            error_messages: [{field: "currentInsuranceCompany", msg: "Seleziona un'opzione"}]
          }));
        }

        if(step3Data?.currentInsuranceYears === undefined ||
          !(this.state.yearsInsuredList === undefined || (this.state?.yearsInsuredList.find(item => item.value === step3Data.currentInsuranceYears) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["currentInsuranceYears"],
            error_messages: [{field: "currentInsuranceYears", msg: "Seleziona un'opzione"}]
          }));
        }

        if(step3Data?.pastAccidentCount === undefined ||
          !(this.state.pastAccidentCountList === undefined || (this.state?.pastAccidentCountList.find(item => item.value === step3Data.pastAccidentCount?.toString()) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["pastAccidentCount"],
            error_messages: [{field: "pastAccidentCount", msg: "Seleziona un'opzione"}]
          }));
        }

        if(step3Data?.pastBersaniDecree === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["pastBersaniDecree"],
            error_messages: [{field: "pastBersaniDecree", msg: "Seleziona un'opzione"}]
          }));
        }
      }

      if (this.isVisibleBersaniDecree()) {
        if(step3Data?.bersaniDecree === undefined ||
          !(this.state.bersaniDecreeStatusList === undefined || (this.state?.bersaniDecreeStatusList.find(item => item.value === step3Data.bersaniDecree) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["bersaniDecree"],
            error_messages: [{field: "bersaniDecree", msg: "Seleziona un'opzione"}]
          }));
        }
      }

      if (this.isVisibleCuLicense()) {

        // superamento bersani
        if (
          !isLicensePlateAutoValid(step3Data.cuLicensePlate ?? "") &&
          !isLicensePlateMotoValid(step3Data.cuLicensePlate ?? "")
        ) {
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["cuLicensePlate"],
            error_messages: [{ field: "cuLicensePlate", msg: "Targa non valida" }]
          }));
        }

        if(step3Data?.cuInsuranceClass === undefined || step3Data.cuInsuranceClass === "" ||
          !(this.state.insuranceClassListBersani === undefined || (this.state?.insuranceClassListBersani.find(item => item.value === step3Data.cuInsuranceClass) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["cuInsuranceClass"],
            error_messages: [{field: "cuInsuranceClass", msg: "Targa non valida"}]
          }));
        }
      }


      if(!step3Data?.contractorAndOwnerCoincide){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["contractorAndOwnerCoincide"],
          error_messages: [{field: "contractorAndOwnerCoincide", msg: "Seleziona un'opzione"}]
        }));
      }

      if(!step3Data?.contractorAndDriverCoincide){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["contractorAndDriverCoincide"],
          error_messages: [{field: "contractorAndDriverCoincide", msg: "Seleziona un'opzione"}]
        }));
      }

      if (this.isVisibleOwnerAndDriverCoincide()) {
        if(!step3Data?.contractorAndDriverCoincide){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["ownerAndDriverCoincide"],
            error_messages: [{field: "ownerAndDriverCoincide", msg: "Seleziona un'opzione"}]
          }));
        }
      }

      if(step3Data?.otherDriversExist === undefined){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["otherDriversExist"],
          error_messages: [{field: "otherDriversExist", msg: "Campo obbligatorio"}]
        }));
      }

      if (this.isVisibleYoungestOtherDriverAge()) {
        if(step3Data?.youngestOtherDriverAge === undefined || step3Data?.youngestOtherDriverAge === "" ||
          !(this.state.youngestOtherDriverAgeList === undefined || (this.state?.youngestOtherDriverAgeList.find(item => item.value === step3Data.youngestOtherDriverAge) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["youngestOtherDriverAge"],
            error_messages: [{field: "youngestOtherDriverAge", msg: "Targa non valida"}]
          }));
        }

        if(step3Data?.otherDriversNoviceExist === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["otherDriversNoviceExist"],
            error_messages: [{field: "otherDriversNoviceExist", msg: "Campo obbligatorio"}]
          }));
        }
      }

      if(step3Data?.youngestFamilyDriver === undefined || step3Data.youngestFamilyDriver === "" ||
        !(this.state.youngestFamilyDriverAgeList === undefined || (this.state?.youngestFamilyDriverAgeList.find(item => item.value === step3Data.youngestFamilyDriver) ?? false))
      ){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["youngestFamilyDriver"],
          error_messages: [{field: "youngestFamilyDriver", msg: "Campo obbligatorio"}]
        }));
      }

      if(step3Data?.contractoDeclares === undefined){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["contractoDeclares"],
          error_messages: [{field: "contractoDeclares", msg: "Campo obbligatorio"}]
        }));
      }

      if (this.isVisible("satelliteTracker")) {
        if(step3Data?.includeSatProducts === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["includeSatProducts"],
            error_messages: [{field: "includeSatProducts", msg: "Campo obbligatorio"}]
          }));
        }
      }

      if(step3Data?.includeNonCard === undefined){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["includeNonCard"],
          error_messages: [{field: "includeNonCard", msg: "Campo obbligatorio"}]
        }));
      }
      
      if (this.isVisibleMoreQuestions()) {

        if(step3Data?.licenseType === undefined || step3Data?.licenseType === "" ||
          !(this.state.licenseTypesList === undefined || (this.state?.licenseTypesList.find(item => item.value === step3Data.licenseType) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["licenseType"],
            error_messages: [{field: "licenseType", msg: "Campo obbligatorio"}]
          }));
        }

        if(step3Data?.licensePoints === undefined || step3Data?.licensePoints === "" ||
          !(this.state.licensePointsList === undefined || (this.state?.licensePointsList.find(item => item.value === step3Data.licensePoints) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["licensePoints"],
            error_messages: [{field: "licensePoints", msg: "Campo obbligatorio"}]
          }));
        }

        if(step3Data?.drunkDrivingTickets === undefined ||
          !(this.state.drunkDrivingTicketsList === undefined || (this.state?.drunkDrivingTicketsList.find(item => item.value === step3Data.drunkDrivingTickets) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["drunkDrivingTickets"],
            error_messages: [{field: "drunkDrivingTickets", msg: "Campo obbligatorio"}]
          }));
        }

        if(step3Data?.licenseSuspensions === undefined ||
          !(this.state.licenseSuspensionsList === undefined || (this.state?.licenseSuspensionsList.find(item => item.value === step3Data.licenseSuspensions) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["licenseSuspensions"],
            error_messages: [{field: "licenseSuspensions", msg: "Campo obbligatorio"}]
          }));
        }

        if(step3Data?.totalLicenseSuspensionMonths === undefined ||
          !(this.state.licenseSuspensionMonthsList === undefined || (this.state?.licenseSuspensionMonthsList.find(item => item.value === step3Data.totalLicenseSuspensionMonths) ?? false))
        ){
          promises.push(Promise.resolve({
            status: false,
            error_fields: ["totalLicenseSuspensionMonths"],
            error_messages: [{field: "totalLicenseSuspensionMonths", msg: "Campo obbligatorio"}]
          }));
        }
      }

      if (this.isVisibleOwner()) {
        // validazione proprietario
        promises.push(User.validatePerson(step3Data.owner ?? {}, Object.entries(this.stepFields.owner).map(([, value]) => value) as IFieldDescriptor[])
          .then((result: IValidationResponse) => {
            let wrappedValidation : IValidationResponse;

            const {status, error_fields, error_messages} = result;

            wrappedValidation = {
              status: status,
              error_fields: error_fields.map((field: string) => `${field}Owner`),
              error_messages: error_messages.map((feedback: IFeedbackText) => {
                return {...feedback, field: `${feedback.field}Owner`}
              })
            }
            return wrappedValidation
          })
        );
      }

      if (this.isVisibleDriver()) {
        // validazione conducente
        promises.push(User.validatePerson(step3Data.driver ?? {}, Object.entries(this.stepFields.driver).map(([, value]) => value) as IFieldDescriptor[])
          .then((result: IValidationResponse) => {
            let wrappedValidation : IValidationResponse;

            const {status, error_fields, error_messages} = result;

            wrappedValidation = {
              status: status,
              error_fields: error_fields.map((field: string) => `${field}Driver`),
              error_messages: error_messages.map((feedback: IFeedbackText) => {
                return {...feedback, field: `${feedback.field}Driver`}
              })
            }
            return wrappedValidation
          })
        );
      }

    }

    if (this.isVisible("vehicleIsMotorCycle")) {

      if(step3Data?.highestDisplacement === undefined || step3Data.highestDisplacement === "" ||
        !(this.state.highestDisplacementList === undefined || (this.state?.highestDisplacementList.find(item => item.value === step3Data.highestDisplacement) ?? false))
      ){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["highestDisplacement"],
          error_messages: [{field: "highestDisplacement", msg: "Campo obbligatorio"}]
        }));
      }

      if(step3Data?.consecutiveDrivingMonths === undefined || step3Data?.consecutiveDrivingMonths === "" ||
        !(this.state.consecutiveDrivingMonthsList === undefined || (this.state?.consecutiveDrivingMonthsList.find(item => item.value === step3Data.consecutiveDrivingMonths) ?? false))
      ){
        promises.push(Promise.resolve({
          status: false,
          error_fields: ["consecutiveDrivingMonths"],
          error_messages: [{field: "consecutiveDrivingMonths", msg: "Campo obbligatorio"}]
        }));
      }
      
    }

    if (!step3Data?.acceptSeiSicuroPrivacyPolicy) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["acceptSeiSicuroPrivacyPolicy"],
        error_messages: [{field: "acceptSeiSicuroPrivacyPolicy", msg: "Campo necessario"}]
      }));
    }

    if (!step3Data?.acceptIvass106) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["acceptIvass106"],
        error_messages: [{ field: "acceptIvass106", msg: "Campo obbligatorio" }]
      }));
    }

    if (!step3Data?.acceptCgu105) {
      promises.push(Promise.resolve({
        status: false,
        error_fields: ["acceptCgu105"],
        error_messages: [{ field: "acceptCgu105", msg: "Campo necessario" }]
      }));
    }

    if (this.isVisible("accidentDetails")) {

      step3Data.pastAccidentsInfo?.forEach((item, i) => {
        if(item.year === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: [`pastAccidentYear${i}`],
            error_messages: [{field: `pastAccidentYear${i}`, msg: "Campo obbligatorio"}]
          }));
        }
        if(item.responsibility === undefined){
          promises.push(Promise.resolve({
            status: false,
            error_fields: [`pastAccidentRespondibility${i}`],
            error_messages: [{field: `pastAccidentRespondibility${i}`, msg: "Campo obbligatorio"}]
          }));
        }
      })

    }


    return Promise.allSettled(promises)
      .then((results) => {
        results.forEach((fieldResponse) => {
          if (fieldResponse.status === "fulfilled") {
            const {status, error_fields, error_messages} = fieldResponse.value;
            
            validation.status = validation.status && status;

            error_fields.forEach((item: string) => {
              if(!validation.error_fields.includes(item)){
                validation.error_fields.push(item)
              }
            })

            error_messages.forEach((item: IFeedbackText) => {
              validation.error_messages = validation.error_messages.filter((storedText: IFeedbackText) => storedText.field !== item.field);
              validation.error_messages.push(item);
            })
          }
        })
        return validation;
      });
  }

  /**
   * Saves the current internal component state to Redux.
   */
  private saveReduxState(bodyData: Step3Data) {
    this.props.step3DataSuccess(bodyData, this.vehicle);
  }

  private formSubmit() {
    let formData = this.setFormData();
    let formValidationData = new URLSearchParams();
    formData.forEach((value, key) => {
      let replacedKey = key.replaceAll(".", "+");
      if (value !== null) {
        formValidationData.append(replacedKey, value);
      }
    });
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    if(this.user?.id){
      formValidationData.append("idUserAP", this.user.id.toString());
    }
    if(step1Data.contractorId){
      formValidationData.append("idContraenteAP", step1Data.contractorId.toString());
    }
    if(step2Data.vehicleId){
      formValidationData.append("idVeicoloAP", step2Data.vehicleId.toString());
    }
    if(step3Data.ownerId){
      formValidationData.append("idProprietarioAP", step3Data.ownerId.toString());
    }
    if(step3Data.driverId){
      formValidationData.append("idConducenteAP", step3Data.driverId.toString());
    }

    // recupero la validazione di 4 ruote
    formValidationData.append("is4RuoteValid", step2Data.is4RuoteValid
      ? `${step2Data.is4RuoteValid}`
      : "false"
    );

    // recupero il captcha
    if (this.state.turnstileToken) {
      formValidationData.append("cToken", this.state.turnstileToken);
      formValidationData.append("isHidden", (this.state.showTurnstileManaged ? "false" : "true"));
    }

    // indipendentemente da cosa mi risponderà, se lo step3 è valido.. resetto la segnalazione che il preventivo deve caricare le quotazioni da un salvataggio
    let step4Result: Step4Data = {
      quotation: [],
      noQuotation: [],
      selectedOptions: [],
      requestCounter: 1,
      warrantiesFromBo: false,
      fromMailSereno: false,
    };
    this.props.step4DataSuccess(step4Result, this.vehicle)
    this.setState(
      { loading: true },
      () => {

        fetch(Constants.SEISICURO_FORM_POST_ENDPOINT, {
          method: "post",
          credentials: "include",
          body: formValidationData,
          headers: {
            "Content-Type": 'application/x-www-form-urlencoded; charset="utf-8"' /** AUTH_REDACTED */,
          },
        })
          .then((response) => {
            if (response.status === 400) {
              response
                .text()
                .then((responseText) => {
                  this.setState({ 
                    errors: [responseText],
                    showTurnstileManaged: this.state.showTurnstileManaged === true || (responseText === '["captcha"]')
                  }, () => this.trackSendFailure);
                })
                .catch((error) => {
                  this.setState({ loading: false }, () => this.trackSendFailure);
                });
    
              this.setState({ loading: false });
            }
            else if (response.ok) {
              this.props.step3DataSuccess({ step3Ok: true }, this.vehicle);
              fetch(Constants.SEISICURO_STEP_CHECK(3), {
                credentials: "include",
                headers: {
                  /** AUTH_REDACTED */
                },
              }).then((response) => {
                response.json().then((responseJson) => {
                  if (Object.keys(responseJson[0]).length > 0) {
                    let specificErrors = [];
                    let genericErrors = [];
                    
                    let newServerSideCheckErrorInsuranceClass =
                      Constants.MISSING_FIELD_TEXT;
                    let newServerSideCheckErrorDriverAddress =
                      Constants.MISSING_FIELD_TEXT;
                    let newServerSideCheckErrorDriverDob =
                      Constants.MISSING_FIELD_TEXT;
                    let newServerSideCheckErrorDriverLicenseAge =
                      Constants.MISSING_FIELD_TEXT;
                    let newServerSideCheckErrorOwnerAddress =
                      Constants.MISSING_FIELD_TEXT;
                    let newServerSideCheckErrorOwnerDob =
                      Constants.MISSING_FIELD_TEXT;
                    let newServerSideCheckErrorOwnerLicenseAge =
                      Constants.MISSING_FIELD_TEXT;
                    let newServerSideCheckErrornewInsuranceStart =
                      Constants.MISSING_FIELD_TEXT;

                    for (let i = 0; i < Object.keys(responseJson[0]).length; i++) {
                      if (
                        responseJson[0][Object.keys(responseJson[0])[i]] !==
                        undefined
                      ) {
                        switch (Object.keys(responseJson[0])[i]) {
                          case "veicolo.targa":
                            if (!step2Data.vehicleOwned && !step2Data.knowLicensePlate) {
                              // Do nothing, ignore server side validation in this case.
                            }
                            else {
                              genericErrors.push(responseJson[0]["veicolo.targa"]);
                            }
                            break;
                          case "dettagliAssicurazione.attestatoDiRischio":
                            for (let i = 0; i < (step3Data?.pastAccidentCount ?? 0); i++) { // for (let i = 0; i < (step3Data?.pastAccidentCount ?? []); i++) --- ????
                              specificErrors.push(`pastAccidentYear${i}`);
                              specificErrors.push(`pastAccidentRespondibility${i}`);
                            }
                            break;
                          case "dettagliAssicurazione.classeDiMerito":
                            specificErrors.push("currentInsuranceCreditClass");
                            newServerSideCheckErrorInsuranceClass =
                              fixDirtyBackendErrorString(responseJson[0]["dettagliAssicurazione.classeDiMerito"]);
                            break;
                          case "conducenteAbituale.residenza.indirizzo":
                            specificErrors.push("addressDriver");
                            newServerSideCheckErrorDriverAddress = fixDirtyBackendErrorString(responseJson[0]["conducenteAbituale.residenza.indirizzo"]);
                            break;
                          case "conducenteAbituale.dataDiNascita":
                            specificErrors.push("dateOfBirthDriver");
                            newServerSideCheckErrorDriverDob = fixDirtyBackendErrorString(responseJson[0]["conducenteAbituale.dataDiNascita"]);
                            break;
                          case "conducenteAbituale.etaPatente":
                            specificErrors.push("drivingLicenseAgeDriver");
                            newServerSideCheckErrorDriverLicenseAge = fixDirtyBackendErrorString(responseJson[0]["conducenteAbituale.etaPatente"]);
                            break;
                          case "proprietario.residenza.indirizzo":
                            specificErrors.push("addressOwner");
                            newServerSideCheckErrorOwnerAddress = fixDirtyBackendErrorString(responseJson[0]["proprietario.residenza.indirizzo"]);
                            break;
                          case "contraente.residenza.numero":
                            specificErrors.push("contractorAddress");
                            newServerSideCheckErrorOwnerAddress = fixDirtyBackendErrorString(responseJson[0]["contraente.residenza.numero"]);
                            break;
                          case "proprietario.dataDiNascita":
                            specificErrors.push("dateOfBirthOwner");
                            newServerSideCheckErrorOwnerDob = fixDirtyBackendErrorString(responseJson[0]["proprietario.dataDiNascita"]);
                            break;
                          case "proprietario.etaPatente":
                            specificErrors.push("drivingLicenseAgeOwner");
                            newServerSideCheckErrorOwnerLicenseAge = fixDirtyBackendErrorString(responseJson[0]["proprietario.etaPatente"]);
                            break;
                          case "dataDecorrenzaPolizza":
                            specificErrors.push("newInsuranceStart");
                            newServerSideCheckErrornewInsuranceStart = fixDirtyBackendErrorString(responseJson[0]["dataDecorrenzaPolizza"]);
                            break;
                          case "dettagliAssicurazione.bersaniApplicabilita":
                            specificErrors.push("bersaniDecree");
                            break;
                          case "dettagliAssicurazione.bersani":
                            specificErrors.push("pastBersaniDecree");
                            break;
                          case "etaConducentePiuGiovane":
                            specificErrors.push("youngestOtherDriverAge");
                            break;
                          case "dettagliAssicurazione.statoAssicurativo":
                            specificErrors.push("vehicleInsuranceStatus");
                            break;
                          case "contraente.altriConducenti":
                            specificErrors.push("otherDriversExist");
                            break;
                          default:
                            genericErrors.push(fixDirtyBackendErrorString(responseJson[0][Object.keys(responseJson[0])[i]]));
                        }
                        this.setState({
                          errors: [...this.state.errors, ...specificErrors],
                          errorTexts: [{field: "generic", msg: genericErrors}],
                          loading: false,
                          serverSideCheckErrorInsuranceClass: newServerSideCheckErrorInsuranceClass,
                          serverSideCheckErrorDriverAddress: newServerSideCheckErrorDriverAddress,
                          serverSideCheckErrorDriverDob: newServerSideCheckErrorDriverDob,
                          serverSideCheckErrorDriverLicenseAge: newServerSideCheckErrorDriverLicenseAge,
                          serverSideCheckErrorOwnerAddress: newServerSideCheckErrorOwnerAddress,
                          serverSideCheckErrorOwnerDob: newServerSideCheckErrorOwnerDob,
                          serverSideCheckErrorOwnerLicenseAge: newServerSideCheckErrorOwnerLicenseAge,
                          serverSideCheckErrornewInsuranceStart: newServerSideCheckErrornewInsuranceStart,
                        }, () => {
                          window.scrollTo(0, offsetFirstErrorField());
                        });
    
                      }
                    }
                    this.trackSendFailure({errors: [...this.state.errors, ...specificErrors], errorTexts: [{field: "generic", msg: genericErrors}]});
                  }
                  else {
                    this.trackSendSuccess(() => {
                      // rimanda allo step 4
                      this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(4, this.vehicle));
                    });
                  }
                });
              });
            }
            else {
              this.setState({ loading: false }, () => {
                this.trackSendFailure();
                this.props.step3DataFailure(this.vehicle);
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false }, () => {
              this.trackSendFailure();
              this.props.step3DataFailure(this.vehicle);
            });
          });
      }
    );
  }

  /**
   * Form submit.
   *
   */
  private handleFormSubmit() {    
    this.setState({submitting: true}, () => {
      this.validation()
        .then((result: any) => {
          const {status, error_fields, error_message} = result;
          if(status){
            this.formSubmit();
          }
          else{
            this.setState({
              submitting: false,
              errors: error_fields,
              errorTexts: error_message ?? []
            }, () => {
              this.trackSendFailure();
              window.scrollTo(0, offsetFirstErrorField());
            });
          }
        })
    })
  }

  /**
   * Converts a month index number (1-12) to the corresponding italian month name.
   *
   * @param index
   * @returns
   */
  private monthIndexToName(index: number | undefined): string | undefined {
    if (index === undefined) {
      return undefined;
    }
    else {
      return Constants.MONTHS_LIST_IT.find((element) => element.value === index)?.label.toLowerCase();
    }
  }

  /**
   * Assembles the combined step1/step2/step3 form data, which will be sent via POST to the backend.
   *
   * @returns {URLSearchParams} the assembled form data
   */
  private setFormData() {
    let formData = new URLSearchParams();
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;

    //Step 1
    formData.append("contraente.partitaIva", "");
    formData.append("contraente.denominazioneSociale", "");
    formData.append("contraente.settoreMerceologico", "");
    //
    if (step1Data.gender) {
      formData.append("contraente.sesso", `${step1Data.gender}`);
    }
    if (step1Data.dateOfBirth) {
      formData.append("contraente.dataDiNascita+giorno", new Date(step1Data.dateOfBirth).getDate().toString());
      formData.append("contraente.dataDiNascita+mese", new Date(step1Data.dateOfBirth).toLocaleString("it-IT", {month: "long"}));
      formData.append("contraente.dataDiNascita+anno", new Date(step1Data.dateOfBirth).getFullYear().toString());
    }
    formData.append("contraente.natoInItalia", `${step1Data.bornInItaly} `);
    if (step1Data.bornInItaly) {
      formData.append("contraente.luogoDiNascitaItalia.id", `${step1Data.cityOfBirth?.id}`);
    }
    else {
      formData.append("contraente.luogoDiNascitaEstero.id", `${step1Data.countryOfBirth?.id}`);
    }
    if (step1Data.civilState) {
      formData.append("contraente.statoCivile", `${step1Data.civilState}`);
    }
    if (step1Data.degree) {
      formData.append("contraente.titoloDiStudio", `${step1Data.degree}`);
    }
    if (step1Data.profession) {
      formData.append("contraente.professione", `${step1Data.profession}`);
    }

    //Step 2
    formData.append("veicolo.ricordaLaTarga", `${step2Data.vehicleOwned || step2Data.knowLicensePlate}`);
    if (step2Data.vehicleOwned || step2Data.knowLicensePlate) {
      formData.append("veicolo.targa", `${step2Data.vehicleLicensePlateNumber}`);
    }
    formData.append("veicolo+nonConosceLaTarga", `${!(step2Data.vehicleOwned || step2Data.knowLicensePlate)}`);
    formData.append("veicolo.meseDiImmatricolazione", `${this.monthIndexToName(step2Data.vehicleRegistrationMonth)}`);
    formData.append("veicolo.annoDiImmatricolazione", `${step2Data.vehicleRegistrationYear} `);
    formData.append("veicolo.annoAcquisto", `${step2Data.vehicleBuyYear}`);
    formData.append("veicolo.marca", `${step2Data.vehicleBrand}`);
    formData.append("veicolo.modello.id", `${step2Data.vehicleModel}`);
    formData.append("veicolo.allestimento.id", `${step2Data.vehicleFitting}`);
    formData.append("contraente.numeroAuto", `${step2Data.moreThanOneVehicle ? "dueOPiu" : "una"}`);
    formData.append("chilometriPerAnno", `${step2Data.kilometersPerYear ? step2Data.kilometersPerYear : 0}`);
    formData.append("veicolo.antifurto", `${step2Data.vehicleAntitheft}`);
    if (this.vehicle === "auto") {
      formData.append("utilizzoTipico", `${step2Data.vehicleUse}`);
      formData.append("veicolo.gancioTraino", `${step2Data.vehicleTowbarMounted}`);
      formData.append("veicolo.alimentazione", `${step2Data.vehicleFuelType}`);
      
      if (step2Data.vehicleFuelType !== "E") {
        formData.append("veicolo.impiantoGPL", `${step2Data.vehicleGplMounted ?? false}`);
        formData.append("veicolo.cilindrata", `${step2Data.vehicleEngineDisplacement}`);
      }
      else { 
        formData.append( "veicolo+cilindrata", "0");
      }

      if (step3Data.includeSatProducts !== undefined) {
        formData.append("mostraOfferteBlackBox", `${step3Data.includeSatProducts}`);
      }
    }
    formData.append(
      "parcheggioNotturno",
      `${step2Data.vehicleParkingLocation}`
    );

    //Step 3
    formData.append("vehicle", this.vehicle);
    formData.append("step", "3");
    if (step3Data.contractorName) {
      formData.append("contraente.nome", `${step3Data.contractorName}`);
    }
    if (step3Data.contractorSurname) {
      formData.append("contraente.cognome", `${step3Data.contractorSurname}`);
    }
    if (step3Data.contractorCityOfResidence) {
      formData.append("contraente.residenza.comune.id", `${(step3Data.contractorCityOfResidence as any)?.id}`);
    }
    if (step3Data.contractorPostalCode) {
      formData.append("contraente.residenza.cap", `${step3Data.contractorPostalCode}`);
    }
    if (step3Data.contractorAddress) {
      let { toponimo, indirizzo, } = extractAddressData(step3Data.contractorAddress);
      formData.append("contraente.residenza.toponimo", `${toponimo}`);
      formData.append("contraente.residenza.indirizzo", `${indirizzo}`);
    }
    if (step3Data.contractorAddressNumber) {
      formData.append("contraente.residenza.numero", step3Data.contractorAddressNumber);
    }
    if ((step3Data.contractorChildren ?? []).includes(0)) {
      formData.append("contraente.figliNo", "true");
    }
    if ((step3Data.contractorChildren ?? []).includes(1)) {
      formData.append("contraente.figliMinori", "true");
    }
    if ((step3Data.contractorChildren ?? []).includes(2)) {
      formData.append("contraente.figliMaggiori", "true");
    }
    if (this.isVisiblecontractorYoungestChild() && step3Data.contractorYoungestChild) {
      formData.append("contraente.etaFiglioMinore", `${step3Data.contractorYoungestChild}`);
    }
    if (step3Data.contractorEmail) {
      formData.append("contraente.email", `${step3Data.contractorEmail}`);
    }
    if (step3Data.contractorPhoneNumber) {
      formData.append("contraente.numeroCellulare", `${step3Data.contractorPhoneNumber}`);
    }
    if (step3Data.contractorItalianDrivingLicense) {
      formData.append("contraente.patenteItaliana", `${step3Data.contractorItalianDrivingLicense}`);
    }
    if (step3Data.contractorDrivingLicenseAge) {
      formData.append("contraente.etaPatente", `${step3Data.contractorDrivingLicenseAge}`);
    }
    if (step3Data.contractorPreferredPaymentMethod) {
      formData.append("metodoPagamento", `${step3Data.contractorPreferredPaymentMethod}`);
    }
    if (step3Data.newInsuranceStart) {
      const insuranceDate = new Date(step3Data.newInsuranceStart);
      formData.append("dataDecorrenzaPolizza.giorno", insuranceDate.getDate().toString());
      formData.append("dataDecorrenzaPolizza.mese", insuranceDate.toLocaleString("it-IT", {month: "long"})
      );
      formData.append("dataDecorrenzaPolizza.anno", insuranceDate.getFullYear().toString());
    }
    if (step3Data.vehicleInsuranceStatus) {
      formData.append("dettagliAssicurazione.statoAssicurativo", `${step3Data.vehicleInsuranceStatus}`);
    }
    if (this.isVisible("assicurataAMioNome")) {
      if (step3Data.currentInsuranceCreditClass) {
        formData.append("dettagliAssicurazione.classeDiMerito", `${step3Data.currentInsuranceCreditClass}`);
      }
      if (this.isVisible("yearsInClass1List") && step3Data.yearsInClass1) {
        formData.append("dettagliAssicurazione.anzianitaPrimaClasse", `${step3Data.yearsInClass1}`);
      }
      if (step3Data.currentInsuranceCompany) {
        formData.append("dettagliAssicurazione.compagniaAssicurazioneAttuale", `${step3Data.currentInsuranceCompany}`);
      }
      if (step3Data.currentInsuranceYears) {
        formData.append("dettagliAssicurazione.etaAssicurativa", `${step3Data.currentInsuranceYears}`);
      }
      if (step3Data.pastBersaniDecree !== undefined) {
        formData.append("dettagliAssicurazione.bersani", `${step3Data.pastBersaniDecree}`);
      }
      if (step3Data?.pastAccidentCount !== undefined) {
        formData.append("dettagliAssicurazione.numeroIncidenti", `${step3Data.pastAccidentCount}`);
      }
      if (this.isVisible("accidentDetails")) {
        for (let i = 0; i < (step3Data.pastAccidentsInfo ?? []).length; i++) {
          formData.append(
            `dettagliAssicurazione.attestatoDiRischio[${i}].anno`,
            (step3Data.pastAccidentsInfo ?? [])[i].year
          );
          formData.append(
            `dettagliAssicurazione.attestatoDiRischio[${i}].responsabilita`,
            (step3Data.pastAccidentsInfo ?? [])[i].responsibility
          );
        }
      }
    }
    if (this.isVisible("bersaniDecree") && step3Data.bersaniDecree) {
      formData.append("dettagliAssicurazione.bersaniApplicabilita", `${step3Data.bersaniDecree}`);
    }
    if (this.isVisible("cuLicensePlate") && step3Data.cuLicensePlate) {
      formData.append("targaBersani", `${step3Data.cuLicensePlate}`);
    }
    if (this.isVisible("cuInsuranceClass") && step3Data.cuInsuranceClass) {
      formData.append("dettagliAssicurazione.classeDiMeritoBersani", `${step3Data.cuInsuranceClass}`);
    }
    if (step3Data.contractorAndOwnerCoincide) {
      formData.append("contraenteProprietarioNew", `${step3Data.contractorAndOwnerCoincide}`);
    }
    if (step3Data.contractorAndDriverCoincide) {
      formData.append("contraenteConducenteAbitualeNew", `${step3Data.contractorAndDriverCoincide}`);
    }
    if (this.isVisibleOwnerAndDriverCoincide() && step3Data.ownerAndDriverCoincide) {
      formData.append("proprietarioConducenteAbitualeNew", `${step3Data.ownerAndDriverCoincide}`);
    }
    if (step3Data.otherDriversExist !== undefined) {
      formData.append("contraente.altriConducenti", `${step3Data.otherDriversExist}`);
    }
    if (this.isVisible("youngestOtherDriverAge") && step3Data.youngestOtherDriverAge) {
      formData.append("etaConducentePiuGiovane", `${step3Data.youngestOtherDriverAge}`);
    }

    if (this.isVisible("otherDriversNoviceExist") && step3Data.otherDriversNoviceExist !== undefined) {
      formData.append("contraente.conducenteNeoPatentato", `${step3Data.otherDriversNoviceExist}`);
    } else {
      formData.append("contraente.conducenteNeoPatentato", "false");
    }

    if (step3Data.youngestFamilyDriver) {
      formData.append("contraente.etaResidentePiuGiovane", `${step3Data.youngestFamilyDriver}`);
    }
    if (step3Data.contractoDeclares !== undefined) {
      formData.append("domandone", `${step3Data.contractoDeclares}`);
    }
    if (step3Data.includeNonCard !== undefined) {
      formData.append("mostraOfferteNoCard", `${step3Data.includeNonCard}`);
    }

    if (this.isVisibleOwner() && step3Data.owner) {
      // TODO: Il vecchio preventivatore setta i prossimi quattro campi a stringa vuota, verificare se sono effettivamente necessari
      formData.append("proprietario.partitaIva", "");
      formData.append("proprietario.denominazioneSociale", "");
      formData.append("proprietario.settoreMerceologico", "");
      formData.append("proprietario.tipoSocieta", "");

      const ownerDateOfBirth = new Date(step3Data.owner.dateOfBirth ?? "");

      if (step3Data.owner.gender !== undefined) {
        formData.append("proprietario.sesso", `${step3Data.owner.gender}`);
      }
      if (step3Data.owner.name) {
        formData.append("proprietario.nome", `${step3Data.owner.name}`);
      }
      if (step3Data.owner.surname) {
        formData.append("proprietario.cognome", `${step3Data.owner.surname}`);
      }
      if (ownerDateOfBirth && !isNaN(ownerDateOfBirth.getDate())) {
        formData.append("proprietario.dataDiNascita.giorno", ownerDateOfBirth.getDate().toString());
        formData.append("proprietario.dataDiNascita.mese", ownerDateOfBirth.toLocaleString("it-IT", {month: "long"}));
        formData.append("proprietario.dataDiNascita.anno", ownerDateOfBirth.getFullYear().toString());
      }
      if (step3Data.owner.bornInItaly !== undefined) {
        formData.append("proprietario.natoInItalia", `${step3Data.owner.bornInItaly}`);
      }
      if (step3Data.owner.cityOfBirth) {
        formData.append("proprietario.luogoDiNascitaItalia.id", `${(step3Data.owner.cityOfBirth as any)?.id}`);
      }
      if (step3Data.owner.countryOfBirth) {
        formData.append("proprietario.luogoDiNascitaEstero.id", `${(step3Data.owner.countryOfBirth as any)?.id}`);
      }
      if (step3Data.owner.cityOfResidence) {
        formData.append("proprietario.residenza.comune.id", `${(step3Data.owner.cityOfResidence as any)?.id}`);
      }
      if (step3Data.owner.postalCodeOfResidence) {
        formData.append("proprietario.residenza.cap", `${step3Data.owner.postalCodeOfResidence}`);
      }
      if (step3Data.owner?.address) {
        //TODO: Rivedere questa parte quando avremo l'integrazione con l'API di Google Maps
        let { toponimo, indirizzo, } = extractAddressData(step3Data.owner.address);
        formData.append("proprietario.residenza.toponimo", `${toponimo}`);
        formData.append("proprietario.residenza.indirizzo", `${indirizzo}`);
      }
      if (step3Data.owner?.addressNumber) {
        formData.append("proprietario.residenza.numero", step3Data.owner.addressNumber);
      }
      if (step3Data.owner?.civilState) {
        formData.append("proprietario.statoCivile", `${step3Data.owner.civilState}`);
      }
      if (step3Data.owner?.children?.includes(0)) {
        formData.append("proprietario.figliNo", "true");
      }
      if (step3Data.owner?.children?.includes(1)) {
        formData.append("proprietario.figliMinori", "true");
      }
      if (step3Data.owner?.children?.includes(2)) {
        formData.append("proprietario.figliMaggiori", "true");
      }
      if (step3Data.owner?.degree) {
        formData.append("proprietario.titoloDiStudio", step3Data.owner.degree);
      }
      if (step3Data.owner?.profession) {
        formData.append("proprietario.professione", step3Data.owner.profession);
      }
      if (step3Data.owner?.italianDrivingLicense !== undefined) {
        formData.append("proprietario.patenteItaliana", `${step3Data.owner.italianDrivingLicense}`);
      }
      if (step3Data.owner?.italianDrivingLicense !== undefined) {
        formData.append("proprietario.etaPatente", `${step3Data.owner.drivingLicenseAge}`);
      }
    }

    if (this.isVisibleDriver() && step3Data.driver) {
      // TODO: Il vecchio preventivatore setta i prossimi quattro campi a stringa vuota, verificare se sono effettivamente necessari
      formData.append("conducenteAbituale.partitaIva", "");
      formData.append("conducenteAbituale.denominazioneSociale", "");
      formData.append("conducenteAbituale.settoreMerceologico", "");
      formData.append("conducenteAbituale.tipoSocieta", "");

      const driverDateOfBirth = new Date(step3Data.driver.dateOfBirth ?? "");
      
      if (step3Data.driver.gender !== undefined) {
        formData.append("conducenteAbituale.sesso", `${step3Data.driver.gender}`);
      }
      if (step3Data.driver.name) {
        formData.append("conducenteAbituale.nome", `${step3Data.driver.name}`);
      }
      if (step3Data.driver.surname) {
        formData.append("conducenteAbituale.cognome", `${step3Data.driver.surname}`);
      }
      if (step3Data.driver.bornInItaly !== undefined) {
        formData.append("conducenteAbituale.natoInItalia", `${step3Data.driver.bornInItaly}`);
      }
      if (step3Data.driver.postalCodeOfResidence) {
        formData.append("conducenteAbituale.residenza.cap", `${step3Data.driver.postalCodeOfResidence}`);
      }
      if (step3Data.driver.cityOfBirth) {
        formData.append("conducenteAbituale.luogoDiNascitaItalia.id", `${(step3Data.driver.cityOfBirth as any)?.id}`);
      }
      if (step3Data.driver.countryOfBirth) {
        formData.append("conducenteAbituale.luogoDiNascitaEstero.id", `${(step3Data.driver.countryOfBirth as any)?.id}`);
      }
      if (step3Data.driver.cityOfResidence) {
        formData.append("conducenteAbituale.residenza.comune.id", `${(step3Data.driver.cityOfResidence as any)?.id}`);
      }
      if (driverDateOfBirth && !isNaN(driverDateOfBirth.getDate())) {
        formData.append("conducenteAbituale.dataDiNascita.giorno", driverDateOfBirth.getDate().toString());
        formData.append("conducenteAbituale.dataDiNascita.mese", driverDateOfBirth.toLocaleString("it-IT", {month: "long"}));
        formData.append("conducenteAbituale.dataDiNascita.anno", driverDateOfBirth.getFullYear().toString());
      }
      if (step3Data.driver?.address) {
        let { toponimo, indirizzo, } = extractAddressData(step3Data.driver.address);
        formData.append("conducenteAbituale.residenza.toponimo", `${toponimo}`);
        formData.append("conducenteAbituale.residenza.indirizzo", `${indirizzo}`);

        if (step3Data.driver?.addressNumber) {
          formData.append("conducenteAbituale.residenza.numero", step3Data.driver.addressNumber);
        }
      }
      if (step3Data.driver?.civilState) {
        formData.append("conducenteAbituale.statoCivile", `${step3Data.driver.civilState}`);
      }
      if (step3Data.driver?.children?.includes(0)) {
        formData.append("conducenteAbituale.figliNo", "true");
      }
      if (step3Data.driver?.children?.includes(1)) {
        formData.append("conducenteAbituale.figliMinori", "true");
      }
      if (step3Data.driver?.children?.includes(2)) {
        formData.append("conducenteAbituale.figliMaggiori", "true");
      }
      if (step3Data.driver?.degree) {
        formData.append("conducenteAbituale.titoloDiStudio", step3Data.driver.degree);
      }
      if (step3Data.driver?.profession) {
        formData.append("conducenteAbituale.professione", step3Data.driver.profession);
      }
      if (step3Data.driver?.italianDrivingLicense !== undefined) {
        formData.append("conducenteAbituale.patenteItaliana", `${step3Data.driver.italianDrivingLicense}`);
      }
      if (step3Data.driver?.italianDrivingLicense !== undefined) {
        formData.append("conducenteAbituale.etaPatente", `${step3Data.driver.drivingLicenseAge}`);
      }
    }

    if (this.props.vehicle === "moto"){
      if(step3Data.highestDisplacement) {
        formData.append("cilindrataMassima", `${step3Data.highestDisplacement}`);
      }
      if (step3Data.consecutiveDrivingMonths) {
        formData.append("mesiGuidaConsecutiva", `${step3Data.consecutiveDrivingMonths}`);
      }
    }

    if (this.isVisibleMoreQuestions()) {
      formData.append("puntiPatente", `${step3Data.licensePoints}`);
      formData.append("multeEbbrezza", `${step3Data.drunkDrivingTickets}`);
      formData.append("numeroSospensioniPatente", `${step3Data.licenseSuspensions}`);
      formData.append("mesiSospensionePatente", `${step3Data.totalLicenseSuspensionMonths}`);
      if (this.vehicle === "auto") {
        formData.append("tipoPatenteAuto", `${step3Data.licenseType}`);

        if (step3Data.vehicleModificationsAlloyWheels !== undefined) {
          formData.append("veicolo+cerchiInLega", `${step3Data.vehicleModificationsAlloyWheels}`);
        }
        if (step3Data.vehicleModificationsInvalidity !== undefined) {
          formData.append("veicolo+invaliditaFisica", `${step3Data.vehicleModificationsInvalidity}`);
        }
        if (step3Data.vehicleModificationsWindowTint !== undefined) {
          formData.append("veicolo+finestriniScuriti", `${step3Data.vehicleModificationsWindowTint}`);
        }
        if (step3Data.vehicleModificationsOther !== undefined) {
          formData.append("veicolo+altreModifiche", `${step3Data.vehicleModificationsOther}`);
        }
      }
      else {
        formData.append("tipoPatenteMoto", `${step3Data.licenseType}`);

        if (step3Data.vehicleModificationsEngineAntitheft !== undefined) {
          formData.append("veicolo+barreAntiurtoMotore", `${step3Data.vehicleModificationsEngineAntitheft}`);
        }
        if (step3Data.vehicleModificationsExhaust !== undefined) {
          formData.append("veicolo+modificaScarico", `${step3Data.vehicleModificationsExhaust}`);
        }
        if (step3Data.vehicleModificationsDecals !== undefined) {
          formData.append("veicolo+carenaturaAerografata", `${step3Data.vehicleModificationsDecals}`);
        }
        if (step3Data.vehicleModificationsRemovableBags !== undefined) {
          formData.append("veicolo+borseLateraliRimovibili", `${step3Data.vehicleModificationsRemovableBags}`);
        }
        if (step3Data.vehicleModificationsOther !== undefined) {
          formData.append("veicolo+altreModifiche", `${step3Data.vehicleModificationsOther}`);
        }
      }
    }

    formData.append("privacy.consensoCommunicazioniCommerciali", `${step3Data.acceptGdprPrivacyPolicy102 !== undefined
      ? step3Data.acceptGdprPrivacyPolicy102
      : false
    }`);
    formData.append("privacy.consensoCommunicazioniCommercialiNewsletter", `${step3Data.acceptGdprPrivacyPolicy22 !== undefined
      ? step3Data.acceptGdprPrivacyPolicy22
      : false
    }`);
    formData.append("privacy.consensoRicercaEProfilazione", `${step3Data.acceptGdprPrivacyPolicy3 !== undefined
      ? step3Data.acceptGdprPrivacyPolicy3
      : false
    }`);
    formData.append("privacy.consensoConcessioneATerzi", `${step3Data.acceptGdprPrivacyPolicy104 !== undefined
      ? step3Data.acceptGdprPrivacyPolicy104
      : false
    }`);

    formData.append("privacy.consensoCgu", `${step3Data.acceptCgu105 !== undefined
      ? step3Data.acceptCgu105
      : false
    }`);
    formData.append("privacy.consensoIvass", `${step3Data.acceptIvass106 !== undefined
      ? step3Data.acceptIvass106
      : false
    }`);
    formData.append("privacy.allegato3e4ter", `${step3Data.acceptIvass106 !== undefined
      ? step3Data.acceptIvass106
      : false
    }`);

    formData.append("privacy.privacypreventivo", `${
      step3Data.acceptSeiSicuroPrivacyPolicy !== undefined
        ? step3Data.acceptSeiSicuroPrivacyPolicy
        : false
    }`);

    return formData;
  }

  /**
   * Handles the foreign country (i.e. not Italy) autocomplete.
   *
   * @param event the country search event generated by the component
   */
  private searchCountry(event: any) {
    if (event.query.trim().length >= 2) {
      fetch(Constants.SEISICURO_AJAX_COUNTRY_OF_BIRTH_ENDPOINT(event.query.trim()),
        {
          credentials: "include",
          headers: {
            /** AUTH_REDACTED */
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          this.setState({ autocompleteCountries: result });
        });
    }
    else {
      this.setState({ autocompleteCountries: [] });
    }
  }

  /**
   * permette l'aggiornamento dello stato tramite un component esterno
   * @param typeOfPerson
   * @param address
   * @param postalCode
   * @param cityOfResidence
   */
  private updateAddress(
    typeOfPerson: string,
    address: string,
    postalCode: string,
    cityOfResidence: AjaxAutocompleteObject,
    civico: string
  ) {

    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let fieldsToValidate : IFieldDescriptor[] = [];
    const allFields: any = PersonFieldsBuilder();
    const fieldsKeys: string[] = ["address", "addressNumber", "cityOfResidence", "postalCodeOfResidence"];
    fieldsToValidate = fieldsKeys.map((key: string) => allFields[key])

    let dataToDispacth: Step3Data = {};

    if (civico && !isStreetAddres(address)){
      address += `, ${civico}`;
    }

    if (typeOfPerson === "contractor") {
      dataToDispacth = {
        contractorCityOfResidence: cityOfResidence,
        contractorAddress: address,
        contractorAddressNumber: civico,
        contractorPostalCode: postalCode,
      }

      const dataToValidate: IPerson = {
        cityOfResidence: cityOfResidence,
        address: address,
        addressNumber: civico,
        postalCodeOfResidence: postalCode,
      }

      User.validatePerson(dataToValidate, fieldsToValidate)
        .then((result: IValidationResponse) => {

          let fieldsKeysRemapped: string[] = fieldsKeys.map((err: string) => `contractor${err[0].toUpperCase()}${err.slice(1)}`);

          let {error_fields, error_messages} = result;
          error_fields = error_fields
            .map((err: string) => `contractor${err[0].toUpperCase()}${err.slice(1)}`);
          
          error_messages = error_messages
            .map((feedback: IFeedbackText) => {
              let string = `contractor${feedback.field[0].toUpperCase()}${feedback.field.slice(1)}`
              return {
                msg: feedback?.msg,
                field: string,
                severity: feedback?.severity
              }
            });
                      
          this.setState({
            errors: [...this.state.errors.filter((err: string) => !fieldsKeysRemapped.includes(err)), ...error_fields],
            errorTexts: [...this.state.errorTexts
              .filter((feed: IFeedbackText) => !fieldsKeysRemapped.includes(feed.field)),
              ...error_messages]
          }, () => this.props.step3DataSuccess(dataToDispacth, this.vehicle))
          return result;
        });
    }
    if (typeOfPerson === "owner") {
      dataToDispacth = {
        owner: {
          ...step3Data.owner,
          cityOfResidence: cityOfResidence,
          address: address,
          addressNumber: civico,
          postalCodeOfResidence: postalCode,
        }
      }

      User.validatePerson(dataToDispacth.owner as IPerson, fieldsToValidate)
        .then((result: IValidationResponse) => {

          let fieldsKeysRemapped: string[] = fieldsKeys.map((err: string) => `${err}Owner`);

          let {error_fields, error_messages} = result;
          error_fields = error_fields
            .map((err: string) => `${err}Owner`);
          
          error_messages = error_messages
            .map((feedback: IFeedbackText) => {
              let string = `${feedback.field}Owner`
              return {
                msg: feedback?.msg,
                field: string,
                severity: feedback?.severity
              }
            });
                      
          this.setState({
            errors: [...this.state.errors.filter((err: string) => !fieldsKeysRemapped.includes(err)), ...error_fields],
            errorTexts: [...this.state.errorTexts
              .filter((feed: IFeedbackText) => !fieldsKeysRemapped.includes(feed.field)),
              ...error_messages]
          }, () => this.props.step3DataSuccess(dataToDispacth, this.vehicle))
          return result;
        });
    }
    if (typeOfPerson === "driver") {
      dataToDispacth = {
        driver: {
          ...step3Data.driver,
          cityOfResidence: cityOfResidence,
          address: address,
          addressNumber: civico,
          postalCodeOfResidence: postalCode,
        }
      }
      User.validatePerson(dataToDispacth.driver as IPerson, fieldsToValidate)
        .then((result: IValidationResponse) => {

          let fieldsKeysRemapped: string[] = fieldsKeys.map((err: string) => `${err}Driver`);

          let {error_fields, error_messages} = result;
          error_fields = error_fields
            .map((err: string) => `${err}Driver`);
          
          error_messages = error_messages
            .map((feedback: IFeedbackText) => {
              let string = `${feedback.field}Driver`
              return {
                msg: feedback?.msg,
                field: string,
                severity: feedback?.severity
              }
            });
                      
          this.setState({
            errors: [...this.state.errors.filter((err: string) => !fieldsKeysRemapped.includes(err)), ...error_fields],
            errorTexts: [...this.state.errorTexts
              .filter((feed: IFeedbackText) => !fieldsKeysRemapped.includes(feed.field)),
              ...error_messages]
          }, () => this.props.step3DataSuccess(dataToDispacth, this.vehicle))
          return result;
        });
    }
  }

  // gestione inserimento InputDate
  private onBlurHandlerDate(dateString: string, callback?: Function){

    const personTypes: string[] = ["owner", "driver"];
    let errorFieldName: string = "";
    let newState : any = {};
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    let dataToDispatch : Step3Data = {};
    
    if(personTypes.includes(this.currentPersonCardType)){
          
      let personState : IPerson = {};
      if(this.currentPersonCardType === "owner"){
        personState = {...step3Data.owner};
        errorFieldName = "dateOfBirthOwner";
      }
      if(this.currentPersonCardType === "driver"){
        personState = {...step3Data.driver};
        errorFieldName = "dateOfBirthDriver";
      }

      if(dateString){
        if(isLikelyDate(dateString)){

          let dateParts: string[] = dateString.split("/");
          let testDate = moment(dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]);
          
          const [day, month, year] : [day: string, month : string, year : string] = dateString.split("/") as [string, string, string];
          
          const date = new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00Z`);

          if (!testDate.isValid()) {
    
            dataToDispatch[this.currentPersonCardType as keyof Step3Data] = {
              ...personState,
              dateOfBirth: undefined
            }
            
            newState = {
              errors: [...this.state.errors.filter((e: string) => e !== errorFieldName), errorFieldName],
              errorTexts: [...this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== errorFieldName), {field: errorFieldName, msg: "Data invalida"}]
            }
          }
          else{
            if(date instanceof Date && !isNaN(date.getTime())){
  
              const today = new Date();
              const age = today.getFullYear() - date.getFullYear();
              let maggiorenne : boolean;
              if(age > Constants.DOB_YEAR_RANGE_MIN){
                maggiorenne = true;
              }
              else if(age === Constants.DOB_YEAR_RANGE_MIN){
                if(today.getMonth() < date.getMonth()){
                  // devi essere maggiorenne
                  maggiorenne = false;
                }
                else if(today.getMonth() > date.getMonth()){
                  //ok
                  maggiorenne = true;
                }
                else{
                  //check giorno
                  if(today.getUTCDay() < date.getUTCDay()){
                    // devi essere maggiorenne
                    maggiorenne = false;
                  }
                  else{
                    // ok
                    maggiorenne = true;
                  }
                }
              }
              else{
                maggiorenne = false;
              }
      
              dataToDispatch[this.currentPersonCardType as keyof Step3Data] = {
                ...personState,
                dateOfBirth: date
              }
    
              if(maggiorenne){
                if(age > Constants.DOB_YEAR_RANGE_MAX){ // too old
                  newState = {
                    errors: [...this.state.errors.filter((e: string) => e !== "dateOfBirth"), "dateOfBirth"],
                    errorTexts: [...this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== "dateOfBirth"), {
                      field: "dateOfBirth",
                      msg: "Età invalida. Il contraente deve avere meno di 100 anni."
                    }]
                  }
                }
                else{
                  newState = {
                    errors: this.state.errors.filter((e: string) => e !== errorFieldName),
                    errorTexts: this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== errorFieldName)
                  }
                }
              }
              else{
                newState = {
                  errors: [...this.state.errors.filter((e: string) => e !== errorFieldName), errorFieldName],
                  errorTexts: [...this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== errorFieldName), {field: errorFieldName, msg: "Devi essere maggiorenne"}]
                }
              }
            }
            else{
              newState = {
                errors: [...this.state.errors.filter((e: string) => e !== errorFieldName), errorFieldName],
                errorTexts: [...this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== errorFieldName), {field: errorFieldName, msg: "Data invalida"}]
              }
            }
          }
        }
        else{
    
          dataToDispatch[this.currentPersonCardType as keyof Step3Data] = {
            ...personState,
            dateOfBirth: undefined
          }
          
          newState = {
            errors: [...this.state.errors.filter((error: string) => error !== errorFieldName), errorFieldName],
            errorTexts: [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== errorFieldName), {field: errorFieldName, msg: "Data non valida"}]
          };
        }
      }
      else{
    
        dataToDispatch[this.currentPersonCardType as keyof Step3Data] = {
          ...personState,
          dateOfBirth: undefined
        }
        
        newState = {
          errors: [...this.state.errors.filter((error: string) => error !== errorFieldName), errorFieldName],
          errorTexts: [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== errorFieldName), {field: errorFieldName, msg: "Data non valida"}]
        };
      }

      this.setState(newState, () => {
        this.props.step3DataSuccess(dataToDispatch, this.vehicle);
        callback?.(dataToDispatch);
      });
    }
    
    this.currentPersonCardType = "";
  }
}

const mapState = (state: any) => {
  return {
    userInfo: state.userInfoData.user,
    step1Data: state.step1Data,
    step2Data: state.step2Data,
    step3Data: state.step3Data,
    fromMailSereno: state.step4Data.fromMailSereno
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    step3DataSuccess: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle)),
    step3DataFailure: (vehicle: string) => dispatch(step3DataFailure(vehicle)),
    step3DataReset: (vehicle: string) => dispatch(step3DataReset(vehicle)),
    step4DataSuccess: (payload: any, vehicle: string) => dispatch(step4DataSuccess(payload, vehicle))
  }
};

const Step3Guest = (props: any) => {
  const history: ReturnType<typeof useHistory> = useHistory();
  const lists: IDataList = useContext(DataList);

  return <Step3Component {...props} history={history} {...lists} />
};

export default compose(connect(mapState, mapDispatch), withEventManager)(Step3Guest);

