import { combineReducers } from 'redux'
import { Step1InitialState, Step2InitialState, Step3InitialState, Step4InitialState, UserInfoInitialState, StorageInfo } from '../components/utils/common';
import { 
    STEP1_DATA_FAILURE, STEP1_DATA_SUCCESS, STEP2_DATA_FAILURE, STEP2_DATA_SUCCESS, STEP3_DATA_FAILURE, STEP3_DATA_SUCCESS, STEP4_DATA_SUCCESS, USERINFO_SUCCESS, USERINFO_FAILURE, STEP1_DATA_RESET, STEP2_DATA_RESET, STEP3_DATA_RESET, USERINFO_RESET, STEP4_DATA_RESET, STEP_DATA_RESET
} from './actions';
import User from '../components/utils/User';

export interface StepDataDualState {
    auto: Step1InitialState | Step2InitialState | Step3InitialState | Step4InitialState;
    moto: Step1InitialState | Step2InitialState | Step3InitialState | Step4InitialState;
}
interface Step1DualState extends StepDataDualState {
    auto: Step1InitialState;
    moto: Step1InitialState;
    storageInfo: StorageInfo;
}
interface Step2DualState extends StepDataDualState {
    auto: Step2InitialState;
    moto: Step2InitialState;
    storageInfo: StorageInfo;
}
interface Step3DualState extends StepDataDualState {
    auto: Step3InitialState;
    moto: Step3InitialState;
    storageInfo: StorageInfo;
}
interface Step4DualState extends StepDataDualState {
    auto: Step4InitialState;
    moto: Step4InitialState;
    storageInfo: StorageInfo;
}
interface UserInfoDualState {
    user: UserInfoInitialState;
    storageInfo: StorageInfo;
}

export const step1InitialState: Step1DualState = {
    auto: {
        gender: undefined,
        dateOfBirth: undefined,
        bornInItaly: undefined,
        civilState: undefined,
        countryOfBirth: undefined,
        degree: undefined,
        profession: undefined,
        error: false,
    },
    moto: {
        gender: undefined,
        dateOfBirth: undefined,
        bornInItaly: undefined,
        civilState: undefined,
        countryOfBirth: undefined,
        degree: undefined,
        profession: undefined,
        error: false,
    },
    storageInfo: {
        version: 4.0
    }
};

export const step2InitialState: Step2DualState = {
    auto: {
        vehicleOwned: undefined,
        knowLicensePlate: undefined,
        vehicleLicensePlateNumber: undefined,
        vehicleRegistrationMonth: undefined,
        vehicleRegistrationYear: undefined,
        vehicleBuyYear: undefined,
        vehicleBrand: undefined,
        vehicleBrandLabel: undefined,
        vehicleModelLabel: undefined,
        vehicleModel: undefined,
        vehicleFuelType: undefined,
        vehicleEngineDisplacement: undefined,
        vehicleFitting: undefined,
        vehicleGplMounted: false,
        vehicleTowbarMounted: false,
        moreThanOneVehicle: false,
        kilometersPerYear: 10000,
        vehicleAntitheft: undefined,
        vehicleUse: undefined,
        vehicleParkingLocation: undefined,
        error: false,
    },
    moto: {
        vehicleOwned: undefined,
        knowLicensePlate: undefined,
        vehicleLicensePlateNumber: undefined,
        vehicleRegistrationMonth: undefined,
        vehicleRegistrationYear: undefined,
        vehicleBuyYear: undefined,
        vehicleBrand: undefined,
        vehicleBrandLabel: undefined,
        vehicleModelLabel: undefined,
        vehicleModel: undefined,
        vehicleFuelType: undefined,
        vehicleEngineDisplacement: undefined,
        vehicleFitting: undefined,
        vehicleGplMounted: undefined,
        vehicleTowbarMounted: undefined,
        moreThanOneVehicle: false,
        kilometersPerYear: 10000,
        vehicleAntitheft: undefined,
        vehicleUse: undefined,
        vehicleParkingLocation: undefined,
        error: false,
    },
    storageInfo: {
        version: 4.0
    }
};

export const step3InitialState: Step3DualState = {
    auto: {
        contractorName: undefined,
        contractorSurname: undefined,
        contractorCityOfResidence: undefined,
        contractorPostalCode: undefined,
        contractorAddress: undefined,
        contractorChildren: undefined,
        contractorYoungestChild: undefined,
        contractorEmail: undefined,
        contractorPhoneNumber: undefined,
        contractorItalianDrivingLicense: true,
        contractorDrivingLicenseAge: 18,
        contractorPreferredPaymentMethod: undefined,
        newInsuranceStart: new Date(),
        vehicleInsuranceStatus: undefined,
        currentInsuranceCreditClass: undefined,
        yearsInClass1: undefined,
        currentInsuranceCompany: undefined,
        currentInsuranceYears: undefined,
        bersaniDecree: undefined,
        cuLicensePlate: undefined,
        cuInsuranceClass: undefined,
        pastBersaniDecree: undefined,
        pastAccidentCount: undefined,
        contractorAndOwnerCoincide: undefined,
        contractorAndDriverCoincide: undefined,
        ownerAndDriverCoincide: undefined,
        otherDriversExist: undefined,
        youngestOtherDriverAge: undefined,
        otherDriversNoviceExist: undefined,
        youngestFamilyDriver: undefined,
        contractoDeclares: true,
        includeSatProducts: true,
        includeNonCard: true,
        licenseType: undefined,
        licensePoints: undefined,
        drunkDrivingTickets: undefined,
        licenseSuspensions: undefined,
        totalLicenseSuspensionMonths: undefined,
        vehicleModificationsAlloyWheels: undefined,
        vehicleModificationsInvalidity: undefined,
        vehicleModificationsWindowTint: undefined,
        vehicleModificationsOther: undefined,
        pastAccidentsInfo: [],
        vehicleModificationsEngineAntitheft: undefined,
        vehicleModificationsExhaust: undefined,
        vehicleModificationsDecals: undefined,
        vehicleModificationsRemovableBags: undefined,
        highestDisplacement: undefined,
        consecutiveDrivingMonths: undefined,
        error: false,
        isInvalidOwnerAddress: false,

        acceptSeiSicuroPrivacyPolicy: false,
        acceptGdprPrivacyPolicy102: false,
        acceptGdprPrivacyPolicy22: false,
        acceptGdprPrivacyPolicy3: false,
        acceptGdprPrivacyPolicy104: false,
        acceptCgu105: false,
        acceptIvass106: false,
    },
    moto: {
        contractorName: undefined,
        contractorSurname: undefined,
        contractorCityOfResidence: undefined,
        contractorPostalCode: undefined,
        contractorAddress: undefined,
        contractorChildren: undefined,
        contractorYoungestChild: undefined,
        contractorEmail: undefined,
        contractorPhoneNumber: undefined,
        contractorItalianDrivingLicense: true,
        contractorDrivingLicenseAge: 18,
        contractorPreferredPaymentMethod: undefined,
        newInsuranceStart: new Date(),
        vehicleInsuranceStatus: undefined,
        currentInsuranceCreditClass: undefined,
        yearsInClass1: undefined,
        currentInsuranceCompany: undefined,
        currentInsuranceYears: undefined,
        bersaniDecree: undefined,
        cuLicensePlate: undefined,
        cuInsuranceClass: undefined,
        pastBersaniDecree: undefined,
        pastAccidentCount: undefined,
        contractorAndOwnerCoincide: undefined,
        contractorAndDriverCoincide: undefined,
        ownerAndDriverCoincide: undefined,
        otherDriversExist: undefined,
        youngestOtherDriverAge: undefined,
        otherDriversNoviceExist: undefined,
        youngestFamilyDriver: undefined,
        contractoDeclares: true,
        includeSatProducts: true,
        includeNonCard: true,
        licenseType: undefined,
        licensePoints: undefined,
        drunkDrivingTickets: undefined,
        licenseSuspensions: undefined,
        totalLicenseSuspensionMonths: undefined,
        vehicleModificationsAlloyWheels: undefined,
        vehicleModificationsInvalidity: undefined,
        vehicleModificationsWindowTint: undefined,
        vehicleModificationsOther: undefined,
        pastAccidentsInfo: [],
        vehicleModificationsEngineAntitheft: undefined,
        vehicleModificationsExhaust: undefined,
        vehicleModificationsDecals: undefined,
        vehicleModificationsRemovableBags: undefined,
        highestDisplacement: undefined,
        consecutiveDrivingMonths: undefined,
        error: false,
        isInvalidOwnerAddress: false,
        acceptSeiSicuroPrivacyPolicy: false,
        acceptGdprPrivacyPolicy102: false,
        acceptGdprPrivacyPolicy22: false,
        acceptGdprPrivacyPolicy3: false,
        acceptGdprPrivacyPolicy104: false,
        acceptCgu105: false,
        acceptIvass106: false,
    },
    storageInfo: {
        version: 4.0
    }
};

export const step4InitialState: Step4DualState = {
    auto: {
        quotation: undefined,
        prevId: undefined,
        calcoloId: undefined,
        error: false,
        requestCounter: 1
    },
    moto: {
        quotation: undefined,
        prevId: undefined,
        calcoloId: undefined,
        error: false,
        requestCounter: 1
    },
    storageInfo: {
        version: 4.0
    }
};

export const userInfoInitialState: UserInfoDualState = {
    user: {
        name: undefined,
        error: false,
        direction: "forwards",
        nextStep: 2,
        userData: JSON.stringify(User.getInstance()),
        logged: false,
        openLogin: false,
        openRegistration: false,
        emailChecking: false,
        emailRegistered: false
    },
    storageInfo: {
        version: 4.0
    },
}

function userInfoData (state = userInfoInitialState, action: any) {
    if (action.type === USERINFO_SUCCESS) {
        return { ...state, ...{user: {...state.user, ...action.payload, loading: false, error: false}}};
    } else if (action.type === USERINFO_FAILURE) {
        let newState = { ...state };
        newState.user = { ...state.user, error: true }
        return newState;
    }
    else if(action.type === USERINFO_RESET){
        return userInfoInitialState;
    }
    return state;
}

function step1Data(state = step1InitialState, action: any) {
    if (action.type === STEP1_DATA_SUCCESS) {
        let newState = {...state};
        if(action.vehicle === "auto") {
            newState.auto = {...state.auto, ...action.payload, loading: false, error: false}
        } else {
            newState.moto = {...state.moto, ...action.payload, loading: false, error: false}
        }
        return newState;
    } else if (action.type === STEP1_DATA_FAILURE) {
        let newState = {...state};
        if(action.vehicle === "auto") {
            newState.auto = {...state.auto, error: true}
        } else {
            newState.moto = {...state.moto, error: true}
        }
        return newState;
    }
    else if(action.type === STEP1_DATA_RESET || action.type === STEP_DATA_RESET){
        return step1InitialState;
    }
    return state;
}

function step2Data(state = step2InitialState, action: any) {
    if (action.type === STEP2_DATA_SUCCESS) {
        let newState = {...state};
        if(action.vehicle === "auto") {
            newState.auto = {...state.auto, ...action.payload, loading: false, error: false}
        } else {
            newState.moto = {...state.moto, ...action.payload, loading: false, error: false}
        }
        return newState;
    } else if (action.type === STEP2_DATA_FAILURE) {
        let newState = {...state};
        if(action.vehicle === "auto") {
            newState.auto = {...state.auto, error: true}
        } else {
            newState.moto = {...state.moto, error: true}
        }
        return newState;
    }
    else if(action.type === STEP2_DATA_RESET || action.type === STEP_DATA_RESET){
        return step2InitialState;
    }
    return state;
}

function step3Data(state = step3InitialState, action: any) {
    if (action.type === STEP3_DATA_SUCCESS) {
        let newState = {...state};
        if(action.vehicle === "auto") {
            newState.auto = {...state.auto, ...action.payload, loading: false, error: false}
        } else {
            newState.moto = {...state.moto, ...action.payload, loading: false, error: false}
        }
        return newState;
    } else if (action.type === STEP3_DATA_FAILURE) {
        let newState = {...state};
        if(action.vehicle === "auto") {
            newState.auto = {...state.auto, error: true}
        } else {
            newState.moto = {...state.moto, error: true}
        }
        return newState;
    }
    else if(action.type === STEP3_DATA_RESET || action.type === STEP_DATA_RESET){
        return step3InitialState;
    }
    return state;
}

function step4Data(state = step4InitialState, action: any) {
    if (action.type === STEP4_DATA_SUCCESS) {
        let newState = {...state};
        if(action.vehicle === "auto") {
            newState.auto = {...state.auto, ...action.payload, loading: false, error: false}
        } else {
            newState.moto = {...state.moto, ...action.payload, loading: false, error: false}
        }
        return newState;
    }
    else if (action.type === STEP1_DATA_FAILURE) {
        let newState = {...state};
        if(action.vehicle === "auto") {
            newState.auto = {...state.auto, error: true}
        } else {
            newState.moto = {...state.moto, error: true}
        }
        return newState;
    }
    else if(action.type === STEP4_DATA_RESET || action.type === STEP_DATA_RESET){
        return step4InitialState;
    }
    return state;
}

const reducers = combineReducers({
    step1Data,
    step2Data,
    step3Data,
    step4Data,
    userInfoData
});

export default reducers;