/**
 * Collezione di method helpers per 6 Sicuro.
 * Tutti gli helpers sono documentati.
 */

import { Dropdown } from "primereact/dropdown";
import * as Constants from './constants';
import { createBrowserHistory } from "history";
import jsonrepair from "jsonrepair";
import { /*Person,*/Step1Data, Step2Data, Step3Data, UserInfo } from "./common";
import moment from "moment";
import { WEB_SITE_API_SERVER_URL } from "./constants";
import { isCompleteAddress } from './validate';
import PersistedStore from "../../redux/persistedStore";
import { userInfoDataSuccess } from "../../redux/actions";
import { isNumber } from "util";
import { useState } from "react";
import { IResourceToLoad } from "../customs/FetchLists";

/**
 * Renders a DropDown Navigator Template.
 * e.g, monthNavigatorTemplate={this.monthNavigatorTemplate} diventa
 * (e) => dropdownNavigatorTemplate(e, 'p-ml-2');
 * @param e
 * @param className A classnam for the component
 * @returns The DropDown component
 */
export const dropdownNavigatorTemplate = (e: any, className: string) => {
  return (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={(event) => e.onChange(event.originalEvent, event.value)}
      className={className}
      style={{ lineHeight: 1 }}
    />
  );
};

/**
 * Fetch a list of values from the endpoint.
 *
 * @param {string} endpoint Endpoint URL
 * @param {any} task Falllback function
 * @returns {Array} Result of values
 */
export const fetchList = (endpoint: string, task?: any, errorTask?: any, repairJson = false, signal?: any) => {
  let fetchParams: any = {
    credentials: 'include' ,
    headers: {/** AUTH_REDACTED */},
  }
  if(signal){
    fetchParams.signal = signal;
  }
  
  if(repairJson) {
    return fetch(endpoint, fetchParams)
    .then((response) => response.text())
    .then((result) => {
      //console.log("Repairing");
      task?.(JSON.parse(jsonrepair(result)));
      return result;
    })
    .catch((error) => {
      // console.log(error);
      errorTask?.(error);
      return error
    });
    // return [];
  }
  else {
    return fetch(endpoint, fetchParams)
      .then((response) => response.json())
      .then((result) => {
        task?.(result);
        return result;
      })
      .catch((error) => {
        // console.log(error);
        errorTask?.(error);
        return error;
      });
    // return [];
  }
};

/**
 * Retrieve all the AutoComplete Results for the query
 * e.g., completeMethod={(e) => fetchAutocomplete(e, Constants.SEISICURO_AJAX_CITY_OF_BIRTH_ENDPOINT)}
 * @param event The event object
 * @param endpoint è una funzione(query) con cui costruire la request
 * @param {any} task Falllback function
 */
export const fetchAutocomplete = (event: any, endpoint: any, task: any, type: string) => {
  let query = event.query.trim();
  if (query.length < 2 || query === "undefined") {
    task([]);
    return [];
  }

  let epuredQuery = replaceSpecialChars(query);

  fetch(endpoint(epuredQuery, type), {credentials: 'include' , headers: {/** AUTH_REDACTED */}})
    .then((response) => response.json())
    .then((result) => {
      task(result);
      return result;
    });
};

export const clearIOsApostrofe = (str: string): string => {
  return str.replace("”", '"')
  .replace("“", '"')
  .replace("’","'")
  .replace("‘","'")
  .replace(/[\u2018\u2019]/g, "'")
  .replace(/[\u201C\u201D]/g, '"')
  .replace(/[\u2013\u2014]/g, '-')
  .replace(/[\u2026]/g, '...')
}

/**
 * Recupera i possiili indirizzi validi dal servizio 
 * @param query The value to search
 * @param session The optional session attribute
 * @param task Falllback function
 * @returns 
 */
export const fetcAddressAutoComplete = (query: any, session: any, error: any, task: any, placeId?: any) => {
  let tmp = query.trim();
  if (tmp.length < 2 || tmp === "undefined") {
    task([]);
    return [];
  }

  // arriva {key: "Italia, 08100, Nuoro, Via dell'Agrifoglio 7", value: "here:af:streetsection:wTXVwdIdsbOrjyw1"}
  let queryString = (placeId !== undefined)
    ? "placeId=" + placeId.value + "&"
    : "";

  if (session !== undefined && session !== "undefined" && session !== null && session !== "") {
    queryString += "session=" + session + "&";
  }
  queryString += (placeId !== undefined)
    ? "description=" + tmp.split(' ').join('+')
    : "input=" + clearIOsApostrofe(tmp).split(' ').join('+');

  let toCall = (placeId !== undefined) ? Constants.SEISICURO_AJAX_ADDRESS_DETAILS(queryString) : Constants.SEISICURO_AJAX_ADDRESS_VALIDATION(queryString);

  fetch(toCall, { credentials: 'include', headers: {/** AUTH_REDACTED */ } })
    .then((response) => response.json())
    .then((result) => {
      task(result);
      return result;
    })
    .catch(() => {
      error();
    });
  
}

/**
 * Incornicia un componente del form con il dom necessario per la grafica di 6 sicuro.
 * @param react_component
 * @param label
 * @param className
 * @returns
 */
export const frameComponent = (
  react_component: React.Component,
  label: string,
  className: string
) => {
  return (
    <div className={className}>
      <span className="p-float-label">
        {react_component}
        <label>{label}</label>
      </span>
    </div>
  );
};

/**
 * Return an array of number list.
 * 
 * @param {number} maxNumber 
 * @returns {Array} The number list
 */
export function getNumberList(maxNumber: number): {label: string, value: string}[] {
  let i = 0;
  let array = [];
  for (i = 0; i <= maxNumber; i++) {
    array.push({ label: i.toString(), value: i.toString() });
  }

  return array;
}

/**
 * Return the driving license age options
 * 
 * @param {any} dataValue 
 * @returns {Array} The driving license age options
 */
export function getDrivingLicenseAgeOptions(dataValue: any, useMotoMinAge = false) : {label: string, value: number}[]{
  let i = 0;
  let array = [];
  let userAge = Math.abs(new Date(new Date().getTime() - new Date(dataValue).getTime()).getUTCFullYear() - Constants.UNIX_TIMESTAMP_START_YEAR)
  for (i = (useMotoMinAge ? Constants.LICENSE_MIN_AGE_MOTO : Constants.LICENSE_MIN_AGE_AUTO); i <= userAge; i++) {
    array.push({ label: `${i} anni`, value: i });
  }
  return array;
}

export function getProgressValue() {
  const history: any = createBrowserHistory();
  let step = -1;
  if (history.location.pathname.endsWith(Constants.STEP1_AUTO_URL_POSTFIX) || history.location.pathname.endsWith(Constants.STEP1_MOTO_URL_POSTFIX)) {
    step = 0;
  } else if (history.location.pathname.endsWith(Constants.STEP2_URL_POSTFIX)) {
    step = 1;
  } else if (history.location.pathname.endsWith(Constants.STEP3_URL_POSTFIX)) {
    step = 2;
  } else if (history.location.pathname.endsWith(Constants.STEP4_URL_POSTFIX)) {
    step = 3;
  }
  let progress = (step * 100) / Constants.MAX_STEPS_NUMBER;
  
  return progress;
}

export function getRedirectToStep(vehicle: string): number {
  const store = PersistedStore.getDefaultStore().store;
  let step1Data: Step1Data = store.getState().step1Data[vehicle];
  let step2Data: Step2Data = store.getState().step2Data[vehicle];
  let step3Data: Step3Data = store.getState().step3Data[vehicle];

  if(step1Data.step1Ok !== undefined && step2Data.step2Ok !== undefined && step3Data.step3Ok !== undefined) {
    return 4;
  } else if(step1Data.step1Ok !== undefined && step2Data.step2Ok !== undefined) {
    return 3;
  } else if(step1Data.step1Ok !== undefined) {
    return 2;
  } else {
    return 1;
  }
}

export function isNullOrUndefined(object: any, includeUndefinedString = false) {
  if(!includeUndefinedString) {
    return (object === null || object === undefined);
  } else {
    return (object === null || object === undefined || object === "undefined");
  }
}

export function fixDirtyBackendErrorString(backendString: string) {
  return backendString.replaceAll("�", "è").replaceAll("''", "'").replaceAll('etè', 'età').replaceAll('puè', 'può');
}

export function formatDateAdform(date: number | string | Date | undefined) {
  if(date === undefined) {
    return '01/01/1970';
  }
  else{
    if(typeof date === "number"){
      let dateSplit = new Date(date - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0].split('-');
      return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
    }
    else{
      let testDate = new Date(date);
      if(isNaN(testDate?.getDate())){
        return undefined
      }
      else{
        formataDateToString(testDate);
      }

    }
  
  }
}

/** Converte un Date in un string */
export function formataDateToString (value: Date | string | undefined) {
  // console.log(value);
  if (value === undefined) {
    return undefined;
  }
  else{
    let testDate = new Date(value);
    if(isNaN(testDate?.getDate())){
      return undefined
    }
    else{
    
      let dd = String(testDate.getDate()).padStart(2, '0');
      let mm = String(testDate.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = testDate.getFullYear();
    
      return `${dd}/${mm}/${yyyy}`;
    }
  }
}

/** Verifica se l'oggetto passato è un possibile data */
export function isLikelyDate(dateString: any) {
  return (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString));
}

export function getInsuranceName(company_id: string) {
  if (company_id === "1")
      return "Sai";
  if (company_id === "2")
      return "Ubi";
  if (company_id === "3")
      return "Fondiaria Sai";
  if (company_id === "4")
      return "Milano";
  if (company_id === "5")
      return "Generali";
  if (company_id === "6")
      return "Meie";
  if (company_id === "7")
      return "Aviva";
  if (company_id === "8")
      return "Toro";
  if (company_id === "9")
      return "Reale Mutua";
  if (company_id === "10")
      return "Zurich";
  if (company_id === "11")
      return "Verti";
  if (company_id === "12")
      return "Genialloyd";
  if (company_id === "13")
      return "Unipol";
  if (company_id === "14")
      return "Allianz R.A.S.";
  if (company_id === "15")
      return "Aurora";
  if (company_id === "16")
      return "Nuova Maa";
  if (company_id === "17")
      return "Linear";
  if (company_id === "18")
      return "Zurich Connect";
  if (company_id === "19")
      return "Royal&SunAlliance";
  if (company_id === "20")
      return "Assitalia";
  if (company_id === "22" || company_id === "35")
      return "Conte.it";
  if (company_id === "23")
      return "Quixa";
  if (company_id === "24")
      return "LinearSat";
  if (company_id === "25")
      return "Intesa SanPaolo Assicura";
  if (company_id === "26")
      return "Tua Assicurazioni";
  if (company_id === "27")
      return "Sasa";
  if (company_id === "30")
      return "Quixa Black Box";
  if (company_id === "31")
      return "Ben";
  if (company_id === "32")
      return "Genialclick";
  if (company_id === "33" || company_id === "34")
      return "Prima";
  if (company_id === "35")
      return "ConTe SAT";
  if (company_id === "36")
      return "Direct Assicurazioni";
  if (company_id === "37")
      return "Linear Autobox";
  if (company_id === "38")
      return "Genertel";
  if (company_id === "39")
      return "Genertel Telematic";
  if (company_id === "40")
    return "Genertel RFS";
  if (company_id === "112")
      return "Allianz Direct";
  if (company_id === "132")
      return "Genialclick";
  return "";
}

/**
 * Funzione per rioridnare le select, va applicata ad un array di oggetti / json del tipo [{"value":"XXX","label":"YYY"}, ..]
 * @param a un oggetto {"value":"XXX","label":"YYYY"}
 * @param b un oggetto {"value":"AAA","label":"BBBB"}
 * @returns -1 se a è più picolo di b, 1 se b è maggiore, 0 altrimenti
 */
export function sortJson(a: any, b: any) {
  let av = a.value.toLowerCase();
  let bv = b.value.toLowerCase();
  if (av < bv) {
    return -1;
  }
  if (av > bv) {
    return 1;
  }
  return 0;
}

/**
 * 
 * @param json Dato un array di coppie {id: "", value: ""} ne estrae le chiavi "value" in lowercase
 * @returns un array contenente tutti i valori "value" trovati
 */
export function getJsonValues(json: any[] | undefined) {
  let values: any[] = [];

  if (json !== undefined) {
    json.forEach(ele => {
      values.push(ele?.value.toLowerCase());
    });
  }
  
  return values;
}

/**
 * Cifra una stringa / email
 * @param valore l'email da cifrare
 * @returns il valore cifrato
 */
export function encryptEmail (valore: any) {
  const encrypt_alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
  const encrypt_numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '1', '2', '3', '4', '5', '6', '7']
  let crypted = ''
  if (valore !== undefined) {
    for (let i = 0; i < valore.length; i++) {
      const existsAlphabet = encrypt_alphabet.indexOf(valore[i].toUpperCase())
      const existsNumber = encrypt_numbers.indexOf(valore[i])
      if (existsAlphabet !== - 1) { crypted = crypted + encrypt_alphabet[existsAlphabet + 5] }
      else if (existsNumber !== - 1) { crypted = crypted + encrypt_numbers[existsNumber + 7] }
      else { crypted = crypted + valore[i] }   // tutto il resto non viene criptato
    }
  }
  return crypted;
}

/**
 * Esegue una POST sincrona verso il server, ignorando la risposta
 * @param url url da chiamare
 * @param data i dati da mettere nel playload
 * @returns la risposta del server
 */
export async function postData(url: string = '', data: object = {}, signal?: any)/* : Promise<any>*/{
  let fetchParameter: any = {
    method: 'POST',
    credentials: 'include', 
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json; charset="utf-8"', /** AUTH_REDACTED */ },
    body: JSON.stringify(data)
  }

  if(signal){
    fetchParameter.signal = signal;
  }

  // return fetch( url, fetchParameter);
  const response = await fetch( url, fetchParameter);
  return response;
}

/**
 * Data una stringa delle eventuali garanzie salvate, le splitta in un array
 * @param savedOptions l'eventuale lista di garanzie salvate, separate da virgola
 * @returns un array contenente gli id delle garanzie accessorie da spuntare, undefined se non è possibile fare il parsng della stringa
 */
export function manageOptionSelected (savedOptions: string | null, veicle: string) : any[] {
  const ammisibleValues = (veicle === "auto")
    ? Constants.ADDITIONAL_GUARANTEES_AUTO.map(aga => aga.value)
    : Constants.ADDITIONAL_GUARANTEES_MOTO.map(agm => agm.value);

  let garanieArray: any[] = [];

  if (savedOptions === null || savedOptions === "-1") {
    return [];
  } else {
    let tmpGaranieArray = (savedOptions.includes(",")) ? savedOptions.split(',') : [savedOptions];

    tmpGaranieArray.forEach(id => {
      if (ammisibleValues.includes(id)) {
        garanieArray.push(
          { key: "garanzia-" + id, value: id }
        );
      }
    });

    return garanieArray;
  }
}

/**
 * Regex che individua i civici al interno di un indirizzo!
 */
export const regex_only_civico = /(\b\d{1,4}(([\\\/\- ]?)([A-z]{1,3}))?( |,|$)?\b)/g; // /[0-9]+([\\\/\- A-z]){0,9}(\s|,|$)?/g;

/**
 * Splits the unified address string to "toponimo", "indirizzo", "numero".
 * 
 * @param address the unified address string (i.e. "Via test 123")
 * @returns {string, string, number}
 */
export function extractAddressData (address: string) {
  let toponimo: string | undefined = undefined;
  let indirizzo: string | undefined = undefined;
  let numero: string | undefined = undefined;

  // a seguito delle modifiche bp, ora caricando da mail nel indirizzo puo arrivare una
  // stringa conpleta: "Via delle rose, 1, Roma (RO), ... Italia"
  if (isCompleteAddress(address)) { 
    // dato che finisce con Italia: tutte le parti del indirizzo sono presenti e separate da virgola!
    const { singleAddress, civico, cap, city } = destructMapAddress(address);
    let splitAddress = singleAddress.split(' ');

    toponimo = splitAddress.shift().normalize("NFD").replace(/\p{Diacritic}/gu, "");
    numero = civico;
    indirizzo = '';
    
    for (let i = 0; i < splitAddress.length; i++) {
      if (indirizzo.length > 0) {
        indirizzo += ' ';
      }
      indirizzo += splitAddress[i];
    }
  } else if (address.length > 0) { // se è vuoto è inutile procedere
    // non posso fare assunzioni sulla struttura..
    numero = getCivico(address);
    let civicoFinded = false;

    let splitAddress = address.split(' ');

    if (splitAddress && splitAddress.length >= 2) {
      toponimo = splitAddress.shift()?.normalize("NFD").replace(/\p{Diacritic}/gu, "");

      indirizzo = "";

      for (let i = 0; i < splitAddress.length && !civicoFinded; i++) {
        if (!numero || (numero && !splitAddress[i].startsWith((numero ? numero : "") ))) {
          indirizzo += splitAddress[i];

          if (i < splitAddress.length - 1) {
            indirizzo += ' ';
          }
        } else {
          civicoFinded = true;
        }
      }
    }
  }
  
  const rt = toponimo?.trim();
  const rn = numero?.trim();
  // devo eliminare eventuali doppioni .. ormai il DB è pieno di valori duplicati e/o troncati
  const ri = indirizzo?.replaceAll((rt ? rt : ''), '').replaceAll((rn ? rn : ''), '').replace(',', '').trim();

  return { toponimo: rt?.toLowerCase(), indirizzo: ri, numero: rn?.toLowerCase() };
}

export function destructMapAddress (fullAddress: string | {value: string, label: string}) {
  // Via Dell'agrifoglio, 7, 56128 Pisa (PI), Italia
  // Via Dell'agrifoglio 7, 56128 Pisa (PI), Italia

  // NOTE
  // ho dovuto aggiungere il tipo {value: string, label: string} al paramentro in input
  // sembra che se l'utente clicca la suggest di here e molto rapidamente clicca fuori della input
  // fullAddress si del tipo aggiunto
  // o risolto aggiundo il controllo di tipo e nel caso di non stringa prendendo il parametro value
  const addParts = (fullAddress !== undefined && fullAddress !== null)
    ? typeof(fullAddress) === "string"
      ? fullAddress.split(",")
      : fullAddress.value.split(",")
    : [];

  if (addParts.length > 2) {
    if (addParts.length === 3) {
      const capCity: string[] = addParts[1].trim().split(" ");

      const jAddParts: any = {
        singleAddress: addParts[0],
        civico: "",
        // in realtà capCity può essere ["08100", "Nuoro", "(NU)"] -- nella versione orginale mancava il cap..
        cap: capCity.length > 2 ? capCity[0] : undefined,
        city: capCity.length > 2 ? capCity[1] + " " + capCity[2] : undefined,
      };
      return jAddParts;
    }
    else if (addParts.length === 4) {
      const capCity: string[] = addParts[2].trim().split(" ");

      const jAddParts: any = {
        singleAddress: addParts[0],
        civico: (addParts[1]) ? addParts[1] : "",
        cap: capCity.length > 2 ? capCity[0] : undefined,
        city: capCity.length > 2 ? capCity[1] + " " + capCity[2] : undefined,
      };
      return jAddParts;
    }
  }
  
  return false;
}

/**
 * se presente rimuove il "numero civico" da un indirizzo
 * @param address l'indirizzo
 * @returns un indirizzo senza civico
 */
export function removeCivico (address: string) {
  /*
  ----------------
  non può più funzionare.. attualmente un civico può essere separato da solo spazio

  let splitAddress = address.split(',');

  if (splitAddress.length >= 2) {
    return splitAddress[0]
  }
  ----------------

  NOTA: nel caso di indirizzi completi, rimpiazza solo la prima occorrenza

  Via Dell'agrifoglio, 7, 56128 Pisa (PI), Italia   ----> "7,"
  Via Dell'agrifoglio 7, 56128 Pisa (PI), Italia    ----> "7,"

  Altri test case:
  
  Via Dell'agrifoglio, 7, 56128 Pisa (PI), Italia   ----> "7"
  Via Dell'agrifoglio 7, 56128 Pisa (PI), Italia    ----> "7"
  Via Dell'agrifoglio, 7\b                          ----> "7\b"
  Via Dell'agrifoglio, 7/b, 43121                   ----> "7/b"
  Via Dell'agrifoglio 7-d                           ----> "7-d"
  Dell'agrifoglio 13 d                              ----> "13 d"
  Via Dell'agrifoglio, 56128 Pisa (PI), Italia      ----> NULLA

  */
  return address.replace(regex_only_civico, '')
    .replaceAll(', , ', ', ')    // Via Dell'agrifoglio, , 56128 Pisa (PI), Italia
    .replaceAll(' , ', ', ')    // VVia Dell'agrifoglio , 56128 Pisa (PI), Italia
    .replaceAll('  ', '').trim();
}

function getCivico (address: any) {
  let civico =  address.match(regex_only_civico);
  return (civico) ? civico[0] : undefined; // può essere null -> lo voglio undefined
}

export function fixBadAddress(address: any) {
  if (address !== undefined && address !== null) {

    // rimuovo i doppini "puri" (in particolare i toponimi) 
    // da
    // Via Via Via Platani, 10 10 10, 00172 Roma(RM), Italia
    // diventa
    // Via Platani, 10 10, 00172 Roma (RM), Italia
    address = address.split(' ').filter(function (search: string, index: number, array: string[]) {
      return index === array.indexOf(search);
    }).join(' ');

    // rimuovo eventuali civici doppi
    let civico = address.match(regex_only_civico);

    if (civico) {
      let c = civico[0].trim();

      let first = address.indexOf(c);
      let second = address.indexOf(c, (first + 1));

      while (second > 0 && second !== (first + 1)) {
        address = address.replace(c, '');
        second = address.indexOf(c, (first + 1));
      }
    }

    return address;
  }

  return "";
}

/** rimuove la classe che indica il focus e rende gialle le label */
export function removeFocusClass() {
  const boxes = document.querySelectorAll('.p-inputwrapper-focus');
  boxes.forEach( e => {
    e.classList.remove('p-inputwrapper-focus');
  });
}

/**
 * sostituisce i caratteri "non valdi" in una stringa
 * @param value la stringa da controllare
 * @returns una stringa "bonificata"
 */
export function replaceSpecialChars (value: any) {
  return (value && typeof value === "string")
    ? value.replace(/’/g, "'").replace(/‘/g, "'")
      .replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
    : value;
}

export function domain_from_url(url: string) {
  var result
  var match
  if (match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
      result = match[1]
      if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
          result = match[1]
      }
  }
  return result
}

export function cap_pad_zeros(cap: string | number){
  let pad_cap : string;
  if(typeof(cap) === "number"){
    pad_cap = cap.toString();
  }
  else{
    pad_cap = cap;
  }

  if(pad_cap.length < 5){
    pad_cap = pad_cap.padStart(5, "0");
  }

  return pad_cap;
}

export function getValidInsuranceStart(newInsuranceStart: any) {
  return (newInsuranceStart === undefined || (newInsuranceStart !== undefined && moment(newInsuranceStart).isBefore(new Date(), 'day')))
    ? undefined
    : new Date(newInsuranceStart);
}

/**
 * Map authatication user id, provided by Authenticatin service to local website db id
 * 
 * @param {string|number} id id provided by Authenticatin service
 * @param {?Function} [succCallback] optional success callback
 * @param {?Function} [errCallback] optional error callback
 */
export function getLoacalUserId(id: string | number, succCallback?: Function, errCallback?: Function){
  const endpoint = `${WEB_SITE_API_SERVER_URL()}reguser/${id}`;
  fetch(endpoint)
    .then(result => {
      if(!result.headers.get("content-type")?.includes("application/json")){
        throw new Error("Test");
      }
      return result.json();
    })
    .then(response => {
      if(response.status === "success"){
        succCallback ? succCallback(response) : (() => {})();
      }
      else if(response.status === "error"){
        errCallback ? errCallback(response) : (() => {})();
      }
    })
    .catch(err => {
      errCallback ? errCallback(err) : console.log(err);
    })
}

/**
 * Ritorna la distanza dal top della pagina del primo campo che ha dato errore
 */
export function offsetFirstErrorField(): number{
  let y: number = 0;
  const menuH: number = (document.querySelector("#preventivatoreStepNavigator")?.getBoundingClientRect().height ?? 0) + (document.querySelector("#root > .pr-root > .pr-header")?.getBoundingClientRect().height ?? 0);
  if(document.querySelectorAll(".error-container")?.length > 0){
    const firstError = document.querySelectorAll(".error-container")[0] as HTMLElement;
    y = firstError.offsetTop - menuH - 100
  }
  else{
    y = 0;
  }
  return y;
}

// callback da usare quando viene inserita una mail registrata ma non loggata
export function openLogin(vehicle: string, email?: string){
  const store = PersistedStore.getDefaultStore().store;
  const data: UserInfo = {
    openLogin: true,
    openRegistration: false,
  }
  if(email !== undefined) data.email = email;

  return store.dispatch(userInfoDataSuccess(data, vehicle));
}

export function priceFormatter(value: string | number, currency: string = "EUR", locale: string = "it-IT"): string{
  let numberValue: number;
  if(typeof value === "string"){
    let rifeinedValue = value
      .replaceAll(".", "")
      .replaceAll("€", "")
      .replaceAll("&euro;", "")
      .replaceAll("euro;", "")
      .replaceAll("euro", "")
      .trim();
    
    numberValue = +rifeinedValue;
  }
  else{
    numberValue = value;
  }
  
  if(isNaN(numberValue)){
    return (value as string)
    .replaceAll("&euro;", "€")
    .replaceAll("euro;", "€")
    .replaceAll("euro", "€")
    .trim();
  }
  else{
    return numberValue.toLocaleString(locale, {style: 'currency', currency: currency});
  }
}

export function removeDuplicates(arr: any[]) {
  return arr.filter((item: any, index: number, self: any[]) => self.indexOf(item) === index);
}