import { IFeedbackText } from "./Feedback";

export interface IAccidentInfo{
    index?: number;
    field?: string;
    value?: any;
    year?: any;
    responsibility?: any
}
export interface Step1Data {
    email?: string,
    phone?: string,
    gender?: string;
    name?: string;
    surname?: string;
    dateOfBirth?: number,
    civilState?: string,
    bornInItaly?: boolean,
    countryOfBirth?: any;
    cityOfBirth?: any;
    degree?: string;
    degreeLabel?: string;
    profession?: string;
    professionLabel?: string;

    contractorId?: number | string

    step1Ok?: boolean;
    s_id?: string;
    gclid?: string, msclkid?: string, fbclid?: string, fbp?: string, ttclid?: string, fbc?: string,
    utm_campaign?: string, utm_content?: string, utm_source?: string, utm_medium?: string, utm_name?: string, utm_term?: string, utm_id?: string,
    sessionId?: string,
    validDateOfBirthLabel?: boolean;
}

export interface Step2Data {
    vehicleId?: number | string;
    
    vehicleName?: string | undefined;
    vehicleType?: string;
    vehicleLicensePlateNumber?: string;
    vehicleBuyYear?: number;
    vehicleRegistrationMonth?: number;
    vehicleRegistrationYear?: number;
    vehicleBrand?: string;
    vehicleBrandLabel?: string;
    vehicleModel?: string;
    vehicleModelLabel?: string;
    vehicleFitting?: string;
    vehicleFittingLabel?: string;
    veicleFullDescription?: string;
    vehicleOwned?: boolean;
    vehicleFuelType?: string;
    vehicleEngineDisplacement?: string;
    vehicleAntitheft?: string;
    vehicleTowbarMounted?: boolean;
    vehicleGplMounted?: boolean;

    knowLicensePlate?: boolean;
    moreThanOneVehicle?: boolean;
    kilometersPerYear?: number;
    vehicleUse?: string;
    vehicleParkingLocation?: string;
    infoFrom4Ruote?: boolean;
    is4RuoteValid?: boolean;
    
    step2Ok?: boolean;
    hideFitting?: boolean;
}

export interface Step3Data {
    contractorName?: string, 
    contractorSurname?: string,
    contractorCityOfResidence?: AjaxAutocompleteObject,
    contractorPostalCode?: string,
    contractorAddress?: string,
    contractorAddressToponimo?: any,
    contractorAddressNumber?: any,
    contractorChildren?: number[],
    contractorYoungestChild?: number | string,
    contractorEmail?: string,
    contractorPhoneNumber?: string,
    contractorItalianDrivingLicense?: boolean,
    contractorDrivingLicenseAge?: number,
    contractorPreferredPaymentMethod?: string,
    newInsuranceStart?: Date,
    vehicleInsuranceStatus?: string,
    currentInsuranceCreditClass?: string,
    currentInsuranceCreditClassLabel?: string,
    yearsInClass1?: number,
    currentInsuranceCompany?: string,
    currentInsuranceYears?: number,
    bersaniDecree?: string,
    cuLicensePlate?: string,
    cuInsuranceClass?: string,
    pastBersaniDecree?: boolean,
    pastAccidentCount?: number,
    pastAccidentsInfo?: IAccidentInfo[], //{index, field, value}[]
    contractorAndOwnerCoincide?: string,
    contractorAndDriverCoincide?: string,
    ownerAndDriverCoincide?: string,
    otherDriversExist?: boolean,
    youngestOtherDriverAge?: string,
    otherDriversNoviceExist?: boolean,
    youngestFamilyDriver?: string,
    contractoDeclares?: boolean,
    includeSatProducts?: boolean,
    includeNonCard?: boolean,
    licenseType?: string,
    licensePoints?: string,
    drunkDrivingTickets?: number,
    licenseSuspensions?: number,
    totalLicenseSuspensionMonths?: number,
    vehicleModificationsAlloyWheels?: boolean,
    vehicleModificationsInvalidity?: boolean,
    vehicleModificationsWindowTint?: boolean,
    vehicleModificationsOther?: boolean,
    vehicleModificationsEngineAntitheft?: boolean,
    vehicleModificationsExhaust?: boolean,
    vehicleModificationsDecals?: boolean,
    vehicleModificationsRemovableBags?: boolean,
    highestDisplacement?: string,
    consecutiveDrivingMonths?: string,
    owner?: IPerson | undefined,
    driver?: IPerson | undefined,
    driverId?: number | string | undefined,
    ownerId?: number | string | undefined,

    acceptSeiSicuroPrivacyPolicy?: boolean,
    acceptGdprPrivacyPolicy102?: boolean,
    acceptGdprPrivacyPolicy22?: boolean,
    acceptGdprPrivacyPolicy3?: boolean,
    acceptGdprPrivacyPolicy104?: boolean,
    acceptCgu105?: boolean,
    acceptIvass106?: boolean,

    step3Ok?: boolean,
    isInvalidOwnerAddress?: boolean,
    isInvalidDriverAddress?: boolean,
    isInvalidContractorAddress?: boolean,
    mapsDisplayContractorAddress?: any,
    ownerMapsAdress?: any,
    mapsDisplayOwnerAddress?: any,
    driverMapsAdress?: any,
    mapsDisplayDriverAddress?: any
}

export interface Step4Data {
    quotation?: any[];
    noQuotation?: any[];
    prevId?: string;
    calcoloId?: string;
    requestCounter?: number;
    fromMailSereno?: boolean;
    warrantiesFromBo?:boolean; // indica che le garanzie saranno caricate da un salvataggio sotto esplicita richiesta del BO
    selectedOptions?: any[];
}

export interface UserInfo {
    name?: string | undefined;
    direction?: string;
    email?: string; // email to display into mail box of loging popup
    revive?: boolean; // tentativo di recupero sessione precedente (è scaduto il jwt)
    logged?: boolean;
    externalJourney?: boolean;   // discrimina se la sorgente della navigazione è esterna
    externalJourneyId?: string;    // lo setto solo in caso di caricamento da esterno es, journey email
    fromBO?: boolean;   // discriminale la provenienza dal BO
    userData?: string // dati dell'utente corrente
    nextStep?: number;
    openLogin?: boolean; // gestione dell'apertura del popup di login
    openRegistration?: boolean; // gestione dell'apertura del popup di ragistrazione
    // skipEmailCheck?: boolean;   // inibisce il controllo della mail dello step1Data
    step1EmailValid?: boolean;  // flag di validità o meno della mail inserita nello step1
    emailChecking?: boolean;    // stato di controllo della mail -> inizializzato a false
    emailRegistered?: boolean;     // true se la mail è registrata ma non loggata -> inizializzato a false
    currentVehicleSelector?: string// auto oppure moto
    currentStep?: number;
    urlIntentPage?: string; //in caso di caricamento di pagine ad accesso riservato agli utenti loggati, l'utente guest verrà ridirezionato alla pagina di login. urlIntentPage verrà avvolorato alla url iniziale in modo da fare il redirect in caso di acesso
}

export interface StorageInfo {
    version: number;
}

export interface Step1InitialState extends Step1Data {
    error: boolean;
}

export interface Step2InitialState extends Step2Data {
    error: boolean;
}

export interface Step3InitialState extends Step3Data {
    error: boolean;
}

export interface Step4InitialState extends Step4Data {
    error: boolean;
}

export interface UserInfoInitialState extends UserInfo {
    error: boolean;
}

export interface AjaxEnrichedAutocompleteObject{
    label: string;
    value: string;
    value_id: string;
}

export interface AjaxAutocompleteObject {
    id: string;
    value: string;
}

export interface IVehicleFitting{
  gasoline: number;
  id: string;
  value: string
}

export interface I4RuoteBikeFitting{
  progressivoAllestimento: string;
  codiceInfobike: string;
  descrizione: string
}
export interface I4RuoteFitting{
  mese?: string | undefined;
  anno?: string | undefined;
  codiceInfocar?: string | undefined;
  descrizione?: string | undefined;
  datoAllestimentoTargaBike?: I4RuoteBikeFitting;
  progressivoAllestimento?: string;
  codiceInfobike?: string;
}

export interface Driver {
    gender?: string;
    name?: string;
    surname?: string;
    dateOfBirth?: Date/* | Date[]*/;    //ho rimosso l'array di Date nonso a cosa servisse
    bornInItaly?: boolean;
    cityOfBirth?: AjaxAutocompleteObject | undefined;
    countryOfBirth?: AjaxAutocompleteObject | undefined;
    cityOfResidence?: AjaxAutocompleteObject;
    postalCodeOfResidence?: string;
    address?: string;
    addressNumber?: string | undefined; // sembra essere non mai utilizzato
    addressToponimo?: string | undefined; // sembra essere non mai utilizzato
    civilState?: string;
    // possibili valori di children
    // [1,2] rispettivamente figli minorenni e maggiorenni
    // [1] figli minorenni
    // [2] figli maggiorenni
    // [0] nessun figlio
    children?: number[];
    degree?: string;
    profession?: string;
    italianDrivingLicense?: boolean;
    drivingLicenseAge?: number;
    validDateOfBirthLabel?: boolean;
}

export interface IPerson extends Driver {
    id?: number | string;             // id dell'entità Persona non dell'utente
    email?: string;
    phone?: string;
    youngestChild?: number | string
}

export interface IPerson_WithErrors extends IPerson{
    errors?: string[];
    errorTexts?: IFeedbackText[];
}

export interface IVehicle{
    id?: number | string;
    vehicleName?: string | undefined;
    vehicleType?: string;
    vehicleOwned?: boolean;
    vehicleLicensePlateNumber?: string;
    vehicleBuyYear?: number;
    vehicleRegistrationMonth?: number;
    vehicleRegistrationYear?: number;
    vehicleBrand?: string;
    vehicleBrandLabel?: string;
    vehicleModel?: string;
    vehicleModelLabel?: string;
    vehicleFuelType?: string;
    vehicleEngineDisplacement?: string;
    vehicleFitting?: string;
    vehicleFittingLabel?: string;
    veicleFullDescription?: string;
    vehicleAntitheft?: string;
    vehicleTowbarMounted?: boolean;
    vehicleGplMounted?: boolean;
    // vehicleUse?: string;
    // vehicleParkingLocation?: string;
}

export interface IVehicle_WithErrors extends IVehicle{
    errors?: string[];
    errorTexts?: IFeedbackText[];
}

export interface ICompany{
    id?: number | string;
    legal_name?: string;
    name?: string;
    referent_email?: string;
    referent_first_name?: string;
    referent_last_name?: string;
    referent_phone?: string;
    registered_office_address?: string;
    registered_office_cityOfResidence?: AjaxAutocompleteObject;
    registered_office_addressNumber?: string;
    registered_office_postalCode?: string;
    vat?: string;
}

export interface ICompany_WithErrors extends ICompany{
    errors?: string[];
    errorTexts?: IFeedbackText[];
}

export function isAjaxAutocompleteObject(object: any): object is AjaxAutocompleteObject{
    return 'id' in object && 'value' in object;
}

export interface IFieldDescriptor{
  field: string;                   // identificativo del campo
  label_human_readable: string;    // etichetta da utilizzare nel form e nell'elenco dei campi falliti in caso feedback generico
  notification_label?: string;     // nome del campo da utilizzare nel feedback generico, se esiste deve sovrascrive label_human_readable
  validation: {
    mandatory: boolean;            // true -> fallimento della validazione bloccante, false -> fallimento della validazione non bloccante
    fail_severity: string          // tipologia di feedback da utilizzare error/warning/info/success è indipendente da mandatory
    fail_msg: string;              // messaggio da mostrare in caso di fallimento
  }
}

export const BlankPerson: IPerson = {
    gender: undefined,
    name: undefined,
    surname: undefined,
    dateOfBirth: undefined,
    bornInItaly: undefined,
    cityOfBirth: undefined,
    countryOfBirth: undefined,
    cityOfResidence: undefined,
    postalCodeOfResidence: undefined,
    address: undefined,
    addressNumber: undefined,
    addressToponimo: undefined,
    civilState: undefined,
    children: undefined,
    degree: undefined,
    profession: undefined,
    italianDrivingLicense: undefined,
    drivingLicenseAge: undefined,
    validDateOfBirthLabel: undefined,
    id: undefined,
    email: undefined,
    phone: undefined,
    youngestChild: undefined,
}