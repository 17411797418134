import { Message } from "primereact/message";
import { useEffect, useRef } from "react";

export interface IFeedbackPair{
  msg?: string | string[];
  severity?: string     // deafult error
}
export interface IFeedbackText extends IFeedbackPair{
  field: string;
}

export interface IFeedbackBoxProps {
  items: IFeedbackText[];
  filter?: {
    field?: string;     // default generic
    severity?: string;  // default error
  }
}

export interface IContextFeedbackProps{
  show: boolean;
  message: IFeedbackPair;
  setParentClassName?: boolean;  // indica se impostare al parent del nodo di feedback la classe parentClassName
}

interface IFeedbackAsset {
  msg: {
    icon: string;
    parentClassName: string;
    anchestorClassName: string;
  },
  containerClassName: string;
}

export const FeedbackBox = (props: IFeedbackBoxProps) => {

  // const {items, filter: {field = "generic", severity = "error"} = {field: "generic", severity: "error"}} = props;

  const {items, filter} = props;
  const field = filter?.field ?? "generic";
  const severity = filter?.severity ?? "error";

  

  return <>
    {items
      .filter((feedback: IFeedbackText) => feedback.field === field && (feedback?.severity === undefined || feedback.severity === severity))  // severity di default error
      .map((feedback: IFeedbackText, index) =>
        <Message
          key={index + 1}
          severity={feedback?.severity ?? "error"}
          text={feedback?.msg
            ? Array.isArray(feedback.msg)
              ? feedback.msg.join("\n")
              : feedback.msg
            : "Errore generico"
          }
          style={{ marginBottom: 5, alignItems:"baseline"}}
        />
      )
    }
  </>

}

export const ContextFeedback = (props: IContextFeedbackProps) => {

  const {show, message, setParentClassName: useParentClass} = props;
  let elementRef : any = useRef();

  const setParentClassName = useParentClass === undefined ? true : useParentClass;

  const assets : any  = {
    error: {
      msg: {
        icon: "pi pi-exclamation-circle",
        parentClassName: "p-error",
        anchestorClassName: "error-container",
      },
      containerClassName: "error-field"
    },
    warning: {
      msg: {
        icon: "pi pi-exclamation-circle",
        parentClassName: "p-warning",
        anchestorClassName: "warning-container",
      },
      containerClassName: "warning-field"
    },
    alert: {
      msg: {
        icon: "pi pi-exclamation-triangle",
        parentClassName: "p-warning p-inline-message-warn",
        anchestorClassName: "warning-container",
      },
      containerClassName: "alert-field"
    },
    info: {
      msg: {
        icon: "pi pi-exclamation-circle",
        parentClassName: "p-info",
        anchestorClassName: "info-container",
      },
      containerClassName: "info-field"
    },
    success: {
      msg: {
        icon: "pi pi-exclamation-circle",
        parentClassName: "p-success",
        anchestorClassName: "success-container",
      },
      containerClassName: "success-field"
    }
  }

  const choosenAssets : any = assets[message?.severity ?? "error"];

  const clearAllNotificationClass = (parentElement: any) => {
    Object.entries(assets).forEach((pair) => {
      const [key, value] = pair;
      if(parentElement.classList.contains((value as IFeedbackAsset).containerClassName)){
        parentElement.classList.remove((value as IFeedbackAsset).containerClassName)
      }
    })
  }

  useEffect(() => {
    const domElement = elementRef.current;
    const parentElement = domElement.parentNode;
    if(setParentClassName){
      if(!show){
        clearAllNotificationClass(parentElement);
      }
      else{
        parentElement.classList.add(choosenAssets.containerClassName);

        Object.entries(assets).forEach(([key, value]: [key:string, value: any]) => {
          if(key !== (message?.severity ?? "error")){
            if(parentElement.classList.contains(value.containerClassName)){
              parentElement.classList.remove(value.containerClassName)
            }
          }
        })
      }
    }
  })

  return <div ref={elementRef} className="contextualFeedbackblock">
    {show
      ? <div className={choosenAssets.msg.anchestorClassName}>
          <p className={choosenAssets.msg.parentClassName}>
            <i className={choosenAssets.msg.icon}></i> {message?.msg ?? ""}
          </p>
        </div>
      : <></>}
  </div>
}