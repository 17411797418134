import { Component } from "react";
import { connect } from 'react-redux';
import { Route, useHistory } from "react-router-dom";
import { userInfoDataSuccess } from "../../redux/actions";
import StepLoaderSpinner from "../StepLoaderSpinner";
import User from "../utils/User";

interface IPrivateRoutePros {
  [key: string]: any;
  logged: boolean;
  path: string;
  page: JSX.Element;
}

interface IRedirectComponent{
  setRedirectPage: Function;
  path: string;
  delay?: number;
}

const Redirect = (props: IRedirectComponent) => {

  const {path, setRedirectPage} = props;
  const delay: number = props?.delay ?? 150;
  const history: ReturnType<typeof useHistory> = useHistory();

  setRedirectPage(path);
  setTimeout(() => {
    history.push("/preventivi/area-personale/login");
  }, delay)

  return <StepLoaderSpinner title="Stai per essere reindirizzato..."/>
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    setRedirectPage: (url: string) => dispatch(userInfoDataSuccess({urlIntentPage: url})),
  }
};

const ConnectedRedirect = connect(null, mapDispatch)(Redirect)


//===============================================================

const PrivateRoute = (props: IPrivateRoutePros) => {

  const {path, logged, page} = props;

  return <Route
    exact={true}
    path={path}
    render={routeProps => {
      return logged && User.isAlive()
        ? page
        : <ConnectedRedirect path={routeProps.match.url} />
      }
    }
  />

}

const mapState = (state: any) => {
  return {
    logged: state.userInfoData.user.logged
  };
}

export default connect(mapState)(PrivateRoute)