import { IFieldDescriptor } from "./common";
import User from "./User";

const CompanyFieldsBuilder = () => {

  let companyFields: any = {
    name: {
      field: "name",
      label_human_readable: "Nome azienda",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    legal_name: {
      field: "legal_name",
      label_human_readable: "Ragione sociale",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    vat: {
      field: "vat",
      label_human_readable: "P.IVA",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    
    
    registered_office_address: {
      field: "registered_office_address",
      label_human_readable: "Indirizzo sede legale",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    /*registered_office_addressNumber: {
      field: "registered_office_addressNumber",
      label_human_readable: "Numero civico sede legale",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    registered_office_cityOfResidence: {
      field: "registered_office_cityOfResidence",
      label_human_readable: "Città sede legale",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    registered_office_codeOfResidence: {
      field: "registered_office_codeOfResidence",
      label_human_readable: "Codice postale sede legale",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },*/
    
    
    referent_first_name: {
      field: "referent_first_name",
      label_human_readable: "Nome referente",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    referent_last_name: {
      field: "referent_last_name",
      label_human_readable: "Cognome referente",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    referent_email: {
      field: "referent_email",
      label_human_readable: "E-mail di riferimento",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    referent_phone: {
      field: "referent_phone",
      label_human_readable: "Cellulare di riferimento",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
  };

  return companyFields;
}

const AreaPersonaleCompanyFieldsBuilder = () => {

  let companyFields = CompanyFieldsBuilder();

  /*personFields.gender.validation.mandatory = false;
  personFields.gender.validation.fail_severity = "warning";
  personFields.dateOfBirth.validation.mandatory = false;
  personFields.dateOfBirth.validation.fail_severity = "warning";
  personFields.bornInItaly.validation.mandatory = false;
  personFields.bornInItaly.validation.fail_severity = "warning";
  personFields.cityOfBirth.validation.mandatory = false;
  personFields.cityOfBirth.validation.fail_severity = "warning";
  personFields.countryOfBirth.validation.mandatory = false;
  personFields.countryOfBirth.validation.fail_severity = "warning";
  personFields.civilState.validation.mandatory = false;
  personFields.civilState.validation.fail_severity = "warning";
  personFields.degree.validation.mandatory = false;
  personFields.degree.validation.fail_severity = "warning";
  personFields.profession.validation.mandatory = false;
  personFields.profession.validation.fail_severity = "warning";
  personFields.name.validation.mandatory = false;
  personFields.name.validation.fail_severity = "warning";
  personFields.surname.validation.mandatory = false;
  personFields.surname.validation.fail_severity = "warning";
  personFields.italianDrivingLicense.validation.mandatory = false;
  personFields.italianDrivingLicense.validation.fail_severity = "warning";
  personFields.drivingLicenseAge.validation.mandatory = false;
  personFields.drivingLicenseAge.validation.fail_severity = "warning";
  personFields.addressNumber.validation.mandatory = false;
  personFields.addressNumber.validation.fail_severity = "warning";
  personFields.address.validation.mandatory = false;
  personFields.address.validation.fail_severity = "warning";
  personFields.cityOfResidence.validation.mandatory = false;
  personFields.cityOfResidence.validation.fail_severity = "warning";
  personFields.postalCodeOfResidence.validation.mandatory = false;
  personFields.postalCodeOfResidence.validation.fail_severity = "warning";
  personFields.children.validation.mandatory = false;
  personFields.children.validation.fail_severity = "warning";
  personFields.youngestChild.validation.mandatory = false;
  personFields.youngestChild.validation.fail_severity = "warning";*/

  return companyFields;
}

export {CompanyFieldsBuilder, AreaPersonaleCompanyFieldsBuilder as AreaPersonaleCompanyFieldsBuilder};