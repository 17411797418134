import { Button } from "primereact/button";
import { Component } from "react";
import { Card } from "react-bootstrap";
import { IFeedbackText } from "../utils/Feedback";
import UserService, { GenericException } from "../utils/UserService";
import * as Constants from '../utils/constants';
import { Dialog } from "primereact/dialog";
import { Redirect } from "react-router-dom";

class UnauthorizedCard extends Component <{
    email: string  
}, {
    submitting: boolean,
    isCompleted: boolean,
    isClosed: boolean
    errorTexts: IFeedbackText[]
}> {

  constructor(props: any) {
    super(props);
    
    this.state = {
      submitting: false,
      isCompleted: false,
      isClosed: false,
      errorTexts: []
    }
    this.onHideHandler = this.onHideHandler.bind(this);
  }

  render() {
    return this.state.isClosed
      ? <Redirect to='/preventivi/area-personale/login' />
      : <>
          <Dialog
            visible={this.state.isCompleted}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "624px" }}
            header={<h3 className="titleLev5 colorPrimaryBlue">Richiesta inviata correttamente</h3>}
            onHide={this.onHideHandler}  
            modal={true}
            resizable={false}
            draggable={false}
            id="resendRegistrationConfirmation"
          >
            <div className="container-fluid">
              <div className="row">  
                <p>Riceverai una email per compleatare la registrazione.<br/>Segui le istruzioni che ti abbiamo inviato per email.</p>
              </div>
            </div>
          </Dialog>

          <Card className="pr-form mb-5">
            <div className="p-fluid p-5">
              <div className="row">
                <div className="col-12">
                  <h1 className="titleLev4 colorPrimaryBlue mb-2">Registrazione fallita</h1>
                  <p>Impossibile completare la richiesta di attivazione dell'account</p>
                  <div className="row mt-4">
                    <div className="col-12">
                      <Button
                        id="submitButton"
                        label="Invia una nuova richiesta"
                        className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                        onClick={() => {this.onSubmit()}}
                        loading={this.state.submitting}
                      />
                    </div>
                  </div>
                  <br/>
                  Oppure contatta il nostro supporto tecnico
                </div>
              </div>
            </div>
          </Card>
        </>
  }

  private onHideHandler() {
    this.setState({isClosed: true})
  }
  

  private onSubmit() {    
    this.setState({submitting: true}, () => {
      UserService.call(
        Constants.WEB_SITE_API_SERVER_URL('/users/activation-link/resend'),
        {
          method: "POST",
          body: JSON.stringify({
            email: this.props.email
          })
        },
        (response: any) => {
          if(response?.success === false){
            // caso di errore applicativo
            if(response.error_code === "GENERIC"){
              throw new GenericException("Impossibile completare la richiesta");
            }
          }
          else {
            this.setState({isCompleted: true, submitting: false})
          }
        },
        (err: any) => {
          if (err instanceof GenericException) {
            this.setState({errorTexts: [err.getData()], submitting: false});
          }
          else {
            this.setState({errorTexts: [{ field: "generic", msg: "Servizio al momento non disponibile riprovare più tardi." }], submitting: false});
          }
        }
      );
    })
  }
}

export default UnauthorizedCard;