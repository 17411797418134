import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { Component } from "react";
import UserService, { GenericException, PasswordException, ValidationErrorException } from "../utils/UserService";
import * as Constants from '../utils/constants';
import { ContextFeedback, FeedbackBox, IFeedbackText } from "../utils/Feedback";
import { PasswordStrength } from "./PasswordInput";
import { passwordSecurityLevel } from "../utils/validate";

interface IChangePasswordPanelProps{
  visible: boolean;
  onHide: Function;
  successCallback?: Function;
}
interface IChangePasswordPanelSate{
  visible: boolean;
  oldPassword: string;
  newPassword: string;
  rePassword: string;
  passwordLiveChecking: boolean | undefined;
  passwordStrength: PasswordStrength | undefined;
  isSubmitting: boolean;
  feedbackDialog: boolean;
  feedbackStatus: string;
  feedbackBody: string;
  errors: string[];
  errorTexts: IFeedbackText[];
}
class ChangePasswordPanel extends Component<IChangePasswordPanelProps, IChangePasswordPanelSate>{

  constructor(props: IChangePasswordPanelProps){
    super(props);

    this.state = {
      visible: this.props.visible,
      oldPassword: "",
      newPassword: "",
      rePassword: "",
      passwordLiveChecking: undefined,
      passwordStrength: undefined,
      isSubmitting: false,
      feedbackDialog: false,
      feedbackStatus: "",
      feedbackBody: "",
      errors: [],
      errorTexts: [],
    }

    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.handleSave = this.handleSave.bind(this);

  }

  defaultSuccessCallback(response: any, callback?: Function){
    this.setState({     
      oldPassword: "",
      newPassword: "",
      rePassword: "",
      isSubmitting: false,
      feedbackDialog: true,
      feedbackStatus: "success",
      feedbackBody: "Salvataggio completato con successo"
    });
  }

  defaultErrorCallback(){
    this.setState({
      feedbackDialog: true,
      feedbackStatus: "error",
      feedbackBody: "Non è stato possibile completare il salvataggio"
    });
  }

  render(){
    return <Dialog
      breakpoints={{'30000px': '30vw', '960px': '75vw', '640px': '100vw'}}
      visible={this.props.visible}
      onHide={() => {
        this.setState({feedbackDialog: false});
        this.props.onHide()
      }}
      header={<h3 className="titlLev3 colorPrimaryBlue">Cambia la tua password</h3>}
      resizable={false}
      draggable={false}
    >
      <div className="row">
        {this.state.feedbackDialog
          ? <FeedbackBox items={[{field: "generic", msg: this.state.feedbackBody, severity: this.state.feedbackStatus}]} filter={{field: "generic",  severity: this.state.feedbackStatus}}/>
          : <FeedbackBox items={this.state.errorTexts}/>
        }        

        <div className="col-12">
          <p>Inserisci qui la vecchia password</p>
          <label htmlFor="oldPassword">Vecchia password</label>
          <Password
            name="oldPassword"
            placeholder="Inserisci"
            value={this.state.oldPassword}            
            onChange={event => this.onChangeHandler(event.target.value, "oldPassword")}
            toggleMask
            feedback={false}
            disabled={this.state.feedbackDialog || this.state.isSubmitting}
          />
          <ContextFeedback
            show={this.state.errors.includes("oldPassword")}
            message={
              this.state.errorTexts.find(elem => elem.field === "oldPassword") ??
              {msg: "La vecchia password non è corretta", severity: "error"}
            }
          />
        </div>
      </div>

      <Divider></Divider>

      <div className="row mt-4">
        <div className="col-12">
          <p>Inserisci qui la nuova password</p>
        </div>

        <div className="col-12">
          <label htmlFor="newPassword">Nuova password</label>
          <Password
            id="newPassword"
            placeholder="Inserisci"
            name="newPassword"
            value={this.state.newPassword}            
            onChange={event => this.onChangeHandler(event.target.value, "newPassword")}
            mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
            strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
            weakLabel="Inserisci almeno 6 caratteri, una lettera maiuscola e minuscola, oppure una minuscola e un numero, oppure una maiuscola e un numero."
            mediumLabel="Per una maggiore sicurezza inserisci almeno 8 caratteri, una lettera maiuscola, una minuscola, un numero e un carattere speciale (! @ # % $ “ *)."
            strongLabel="6 ok!"
            toggleMask
            feedback={true}
            appendTo="self"
            disabled={this.state.feedbackDialog || this.state.isSubmitting}
          />
          <ContextFeedback
            show={this.state.errors.includes("newPassword")}
            message={
              this.state.errorTexts.find(elem => elem.field === "newPassword") ??
              {msg: "Inserisci una password valida", severity: "error"}
            }
          />
        </div>
        
        <div className="col-12 mt-3">
          <label htmlFor="rePassword">Conferma password</label>
          <Password
            id="rePassword"
            placeholder="Inserisci"
            value={this.state.rePassword}            
            onChange={event => this.onChangeHandler(event.target.value, "rePassword")}
            toggleMask
            feedback={false}
            disabled={this.state.feedbackDialog || this.state.isSubmitting}
          />
          <ContextFeedback
            show={this.state.errors.includes("rePassword")}
            message={
              this.state.errorTexts.find(elem => elem.field === "rePassword") ??
              {msg: "Password di conferma diversa", severity: "error"}
            }
          />
        </div>

        <div className="col-12 mt-4" style={{textAlign: "center"}}>
          <Button
            className="p-button p-component customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
            label="Salva modifiche"
            onClick={this.handleSave}
            disabled={this.state.feedbackDialog}
            loading={this.state.isSubmitting}
          />                    
        </div>

      </div>

    </Dialog>
  }
  
  private onChangeHandler(selected: any, componentName: string) {

    if(componentName === "newPassword"){
      const passValidation = passwordSecurityLevel(this.state.newPassword);
      
      let newState : any = {
        [componentName]: selected,
        passwordStrength: undefined
      };

      if(passValidation.valid){
        newState.passwordStrength = passValidation.strong
          ? PasswordStrength.STRONG
          : passValidation.medium
            ? PasswordStrength.MEDIUM
            : PasswordStrength.WEAK;
        newState.passwordLiveChecking = true;
      }
      else{
        newState.passwordLiveChecking = false;
      }

      this.setState({ ...this.state, ...newState});
    }
    else{
      this.setState({ ...this.state, [componentName]: selected });
    }

    this.resetValidation(componentName);
  }

  /**
   * Reset form element validation
   * @param element
   * @returns
  */
  resetValidation(element: any) {
    this.setState({ errors: this.state.errors.filter((item: any) => item !== element) });
    return;
  }
  
  private validate(specificField: undefined | string = undefined) : boolean{
    let current_errors: any = [];
   
    if (specificField === "oldPassword" || !specificField) {
      const passErrs : any[] = this.oldPasswordValidation();
      if(passErrs.length > 0){
        current_errors.push("oldPassword");
      }
    }

    if (specificField === "newPassword" || !specificField) {
      const passErrs : any[] = this.newPasswordValidation();
      if(passErrs.length > 0){
        current_errors.push("newPassword");
      }
    }

    if (specificField === "rePassword" || !specificField) {
      const passErrs : any[] = this.rePasswordValidation();
      if(passErrs.length > 0){
        current_errors.push("rePassword");
      }
    }
        
    this.setState({ errors: [this.state.errors, ...current_errors] },
      () => {console.log("setSTate done")});
    
    return current_errors.length === 0;
  }

  private oldPasswordValidation(){
    let current_errors: any = [];
    if(this.state.oldPassword.length===0){
      current_errors.push("Inserire la vecchia password")
    }

    if(current_errors.length > 0){
      let errorTexts = this.state.errorTexts.filter(e => e.field !== "oldPassword");
      errorTexts.push({field: "oldPassword", msg: current_errors.join("<\br>")});
      this.setState({errorTexts: errorTexts});
    }

    return current_errors;
  }

  private newPasswordValidation(){
    let current_errors: any = [];
    const validation = passwordSecurityLevel(this.state.newPassword);

    if(!validation.valid){
      current_errors.push("Assicurati che la password sia lunga almeno 6 lettere e contenga almeno una lettera minuscola e una maiuscola");
      this.setState({passwordStrength: undefined})
      
    }
    else{
      this.setState({
        passwordStrength: validation.strong
        ? PasswordStrength.STRONG
        : validation.medium
          ? PasswordStrength.MEDIUM
          : PasswordStrength.WEAK,
        errorTexts: this.state.errorTexts.filter(e => e.field !== "newPassword"),
        errors: this.state.errors.filter(e => e !== "newPassword"),
      })
    }
    

    if(current_errors.length > 0){
      let errorTexts = this.state.errorTexts.filter(e => e.field !== "newPassword");
      errorTexts.push({field: "newPassword", msg: current_errors.join("<\br>")});
      this.setState({errorTexts: errorTexts});
    }

    return current_errors;
  }

  private rePasswordValidation(){
    let current_errors: any = [];
    if(this.state.rePassword !== this.state.newPassword){
      current_errors.push("La password di conferma è diversa")
    }


    if(current_errors.length > 0){
      let errorTexts = this.state.errorTexts.filter(e => e.field !== "rePassword");
      errorTexts.push({field: "rePassword", msg: current_errors.join("<\br>")});
      this.setState({errorTexts: errorTexts});
    }

    return current_errors;
  }

  private handleSave(){
    if(!this.validate()) { return; }
    this.save();
  }

  private save(){
    this.setState({isSubmitting: true}, () => {
      UserService.call(
        Constants.WEB_SITE_API_SERVER_URL("/users/password/change"),
        {
          method: "post",
          body: JSON.stringify({
            "old_password": this.state.oldPassword,
            "new_password": this.state.rePassword
          })
        },
        ((result: any) => {
          if(result.success === false){
            if(result.error_code === "OLD_PASSWORD_WRONG"){
              throw new PasswordException("La vecchia password non è corretta", "oldPassword")
            }

            if(result.error_code === "VALIDATION_ERROR"){
              throw new ValidationErrorException("Inserire tutti i dati richiesti", "generic")
            }
            
            throw new GenericException("Impossibile completare la richista. Contattare il supporto tecnico", "generic")
          }
          else {
            this.defaultSuccessCallback(result)
          }
        }),
        ((error: any) => {
          if(error instanceof PasswordException){
            this.setState({
              isSubmitting: false,
              errors: this.state.errors.includes("oldPassword") ? this.state.errors : [...this.state.errors, "oldPassword"],
              errorTexts: [error.getData()]            
            });
          } 
          else if(error instanceof ValidationErrorException){
            this.setState({
              isSubmitting: false,
              errors: this.state.errors.includes("generic") ? this.state.errors : [...this.state.errors, "generic"],
              errorTexts: [error.getData()]            
            });
          }          
          else if(error instanceof GenericException) {
            this.setState({
              isSubmitting: false,
              errors: this.state.errors.includes("generic") ? this.state.errors : [...this.state.errors, "generic"],
              errorTexts: [error.getData()]            
            });
          }
          else{              
            this.setState({
              isSubmitting: false,
              errorTexts: [{ field: "generic", msg: "Servizio al momento non disponibile riprovare più tardi." }]
            });
          }
        })
      )
    });
  }

}

export default ChangePasswordPanel;