const VehicleFieldBuilder = (vehicle?: string, addVehicleType?: boolean) => {

  let vehicleFields: any = {
    vehicleName: {
      field: "vehicleName",
      label_human_readable: "Nome del veicolo",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Inserisci un nome per il veicolo"
      }
    },
    vehicleLicensePlateNumber: {
      field: "vehicleLicensePlateNumber",
      label_human_readable: "Targa",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Targa non valida"
      }
    },
    vehicleBuyYear: {
      field: "vehicleBuyYear",
      label_human_readable: "Anno acquisto",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Inserisci l'anno di acquisto"
      }
    },
    vehicleRegistrationMonth: {
      field: "vehicleRegistrationMonth",
      label_human_readable: "Mese immatricolazione",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    vehicleAntitheft: {
      field: "vehicleAntitheft",
      label_human_readable: "Tipologia di antifurto",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    vehicleRegistrationYear: {
      field: "vehicleRegistrationYear",
      label_human_readable: "Anno immatricolazione",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Inserisci l'anno di immatricolazione"
      }
    },
    vehicleBrand: {
      field: "vehicleBrand",
      label_human_readable: "Marca",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    vehicleModel: {
      field: "vehicleModel",
      label_human_readable: "Modello",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    vehicleModelLabel: {
      field: "vehicleModelLabel",
      label_human_readable: "Modello",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    vehicleFitting: {
      field: "vehicleFitting",
      label_human_readable: "Allestimento",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    vehicleOwned: {
      field: "vehicleOwned",
      label_human_readable: "Veicolo di proprietà",
      notification_label: "Dichiara la proprietò del veicolo",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    }
  }

  if(vehicle === undefined || addVehicleType === true){
    vehicleFields.vehicleType = {
      field: "vehicleType",
      label_human_readable: "Tipo veicolo",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    }
  }
  
  if(vehicle === "auto"){
    vehicleFields.vehicleFuelType = {
      field: "vehicleFuelType",
      label_human_readable: "Alimentazione",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    };
    vehicleFields.vehicleEngineDisplacement = {
      field: "vehicleEngineDisplacement",
      label_human_readable: "Cilindrata",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    };
    vehicleFields.vehicleTowbarMounted = {
      field: "vehicleTowbarMounted",
      label_human_readable: "Hai montato il gancio da traino?",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    };
    vehicleFields.vehicleGplMounted = {
      field: "vehicleGplMounted",
      label_human_readable: "Hai montato un impianto GPL/metano?",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    };
  }

  return vehicleFields;

}

const AreaPersonaleAutoMotoFieldsBuilder = (vehicle?: string, addVehicleType?: boolean) => {
  let autoMotoFields = VehicleFieldBuilder(vehicle, addVehicleType);

  autoMotoFields.vehicleLicensePlateNumber.validation.mandatory = false;
  autoMotoFields.vehicleLicensePlateNumber.validation.fail_severity = "warning";
  autoMotoFields.vehicleLicensePlateNumber.validation.fail_msg= "Dato non fornito";

  autoMotoFields.vehicleBuyYear.validation.mandatory = false;
  autoMotoFields.vehicleBuyYear.validation.fail_severity = "warning";
  autoMotoFields.vehicleBuyYear.validation.fail_msg = "Dato non fornito";

  autoMotoFields.vehicleRegistrationMonth.validation.mandatory = false;
  autoMotoFields.vehicleRegistrationMonth.validation.fail_severity = "warning";
  autoMotoFields.vehicleRegistrationMonth.validation.fail_msg = "Dato non fornito";

  autoMotoFields.vehicleAntitheft.validation.mandatory = false;
  autoMotoFields.vehicleAntitheft.validation.fail_severity = "warning";
  autoMotoFields.vehicleAntitheft.validation.fail_msg = "Dato non fornito";

  autoMotoFields.vehicleRegistrationYear.validation.mandatory = false;
  autoMotoFields.vehicleRegistrationYear.validation.fail_severity = "warning";
  autoMotoFields.vehicleRegistrationYear.validation.fail_msg = "Dato non fornito";
  
  autoMotoFields.vehicleBrand.validation.mandatory = false;
  autoMotoFields.vehicleBrand.validation.fail_severity = "warning";
  autoMotoFields.vehicleBrand.validation.fail_msg = "Dato non fornito";
  
  autoMotoFields.vehicleModel.validation.mandatory = false;
  autoMotoFields.vehicleModel.validation.fail_severity = "warning";
  autoMotoFields.vehicleModel.validation.fail_msg = "Dato non fornito";
  
  autoMotoFields.vehicleModelLabel.validation.mandatory = false;
  autoMotoFields.vehicleModelLabel.validation.fail_severity = "warning";
  autoMotoFields.vehicleModelLabel.validation.fail_msg = "Dato non fornito";
  
  autoMotoFields.vehicleFitting.validation.mandatory = false;
  autoMotoFields.vehicleFitting.validation.fail_severity = "warning";
  autoMotoFields.vehicleFitting.validation.fail_msg = "Dato non fornito";
  
  autoMotoFields.vehicleOwned.validation.mandatory = false;
  autoMotoFields.vehicleOwned.validation.fail_severity = "warning";
  autoMotoFields.vehicleOwned.validation.fail_msg = "Dato non fornito";
    
  if(vehicle === "auto"){
    autoMotoFields.vehicleFuelType.validation.mandatory = false;
    autoMotoFields.vehicleFuelType.validation.fail_severity = "warning";
    autoMotoFields.vehicleFuelType.validation.fail_msg = "Dato non fornito";
    
    autoMotoFields.vehicleEngineDisplacement.validation.mandatory = false;
    autoMotoFields.vehicleEngineDisplacement.validation.fail_severity = "warning";
    autoMotoFields.vehicleEngineDisplacement.validation.fail_msg = "Dato non fornito";
    
    autoMotoFields.vehicleTowbarMounted.validation.mandatory = false;
    autoMotoFields.vehicleTowbarMounted.validation.fail_severity = "warning";
    autoMotoFields.vehicleTowbarMounted.validation.fail_msg = "Dato non fornito";
    
    autoMotoFields.vehicleGplMounted.validation.mandatory = false;
    autoMotoFields.vehicleGplMounted.validation.fail_severity = "warning";
    autoMotoFields.vehicleGplMounted.validation.fail_msg = "Dato non fornito";
  }

  return autoMotoFields;
}
export {VehicleFieldBuilder, AreaPersonaleAutoMotoFieldsBuilder};