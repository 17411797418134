import { fetchList, sortJson } from "../utils/methods";
import * as Constants from "../utils/constants";
import { DataList, IDataList } from "./dataListContext";
import { AjaxEnrichedAutocompleteObject } from "../utils/common";
import React, { useContext } from "react";
import { ResourceListRequestContext } from "../PreventivatoreHub";

export interface IResourceToLoad{
  professionList?: boolean;
  civilStateList?: boolean;
  degreeList?: boolean;
  antitheftList?: boolean;
  vehicleUseList?: boolean;
  parkingLocationList?: boolean;
}

class FetchListsComponent extends React.Component<IResourceToLoad, IDataList>{

  private abortController: AbortController = new AbortController();

  constructor(props: any){
    super(props);
    this.state = {
      professionList: [],
      civilStateList: [],
      degreeList: [],
      antitheftList: [],
      vehicleUseList: [],
      parkingLocationList: []
    }
  }

  private getOnlyResourcesProps(props: IResourceToLoad): IResourceToLoad{
    const {professionList, civilStateList, degreeList, antitheftList, vehicleUseList, parkingLocationList} = props;

    return {professionList, civilStateList, degreeList, antitheftList, vehicleUseList, parkingLocationList};
  }

  private fetchByProps(props: IResourceToLoad){

    if(props?.professionList && this.state.professionList.length == 0){
      fetchList(Constants.SEISICURO_AJAX_PROFESSION_ENDPOINT, undefined, undefined, false, this.abortController.signal)
        .then((professionList: AjaxEnrichedAutocompleteObject[]) => {
          this.setState({professionList})
        })
    }
    
    if(props?.civilStateList && this.state.civilStateList.length == 0){
      fetchList(Constants.SEISICURO_AJAX_CIVIL_STATE_ENDPOINT)
        .then((civilStateList: AjaxEnrichedAutocompleteObject[]) => {
          this.setState({civilStateList})
        })
    }
    
    if(props?.degreeList && this.state.degreeList.length == 0){
      fetchList(Constants.SEISICURO_AJAX_DEGREE_ENDPOINT, undefined, undefined, false, this.abortController.signal)
        .then((degreeList: AjaxEnrichedAutocompleteObject[]) => {
          this.setState({degreeList})
        })
    }

    if(props?.parkingLocationList && this.state.parkingLocationList.length == 0){
      fetchList(Constants.SEISICURO_AJAX_PARKING_LOCATION_ENDPOINT, undefined, undefined, false, this.abortController.signal)
        .then((parkingLocationList: AjaxEnrichedAutocompleteObject[]) => {
          parkingLocationList.sort(sortJson);
          this.setState({ parkingLocationList });
        })
    }

    if(props?.vehicleUseList && this.state.vehicleUseList.length == 0){
      fetchList(Constants.SEISICURO_AJAX_VEHICLE_USE_ENDPOINT, undefined, undefined, false, this.abortController.signal)
        .then((vehicleUseList: AjaxEnrichedAutocompleteObject[]) => {
          vehicleUseList.sort(sortJson);
          this.setState({ vehicleUseList });
        });
    }
    
    if(props?.antitheftList && this.state.antitheftList.length == 0){
      fetchList(Constants.SEISICURO_AJAX_ANTITHEFT_ENDPOINT, undefined, undefined, false, this.abortController.signal)
        .then((antitheftList: AjaxEnrichedAutocompleteObject[]) => {
          antitheftList.sort(sortJson);
          this.setState({ antitheftList });
        });
    }
  
  }

  componentDidMount(): void {
    this.fetchByProps(this.getOnlyResourcesProps(this.props));
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  componentDidUpdate(prevProps: Readonly<IResourceToLoad>, prevState: Readonly<IDataList>, snapshot?: any): void {
    
    const prevRequestProps: IResourceToLoad = this.getOnlyResourcesProps(prevProps);
    const nextRequestProps: IResourceToLoad = this.getOnlyResourcesProps(this.props);
    if(JSON.stringify(prevRequestProps) !== JSON.stringify(nextRequestProps)){
      this.fetchByProps(nextRequestProps);
    }
  }

  render(){
    return <DataList.Provider value={this.state}>
      {this.props.children}
    </DataList.Provider>
  }
  
}

const FetchLists = (props: any) => {
  const resourceListRequest: IResourceToLoad = useContext(ResourceListRequestContext)
  
  return <FetchListsComponent {...resourceListRequest} {...props}/>
}

export default FetchLists;