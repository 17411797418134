import { Component } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { isAddressCharacterValid, isCompleteAddress } from '../utils/validate';
import { fetchAutocomplete, fetchList, fetcAddressAutoComplete, extractAddressData, replaceSpecialChars, destructMapAddress, regex_only_civico, fixBadAddress } from '../utils/methods';
import * as Constants from  '../utils/constants';
import { AjaxAutocompleteObject } from '../utils/common';
import { ContextFeedback, FeedbackBox, IFeedbackText } from '../utils/Feedback';

interface AddressProps {
  cityOfResidence?: AjaxAutocompleteObject | undefined;
  address?: string | undefined;
  civico?: string | undefined;
  postalCodeOfResidence?: string | undefined;
  labelFullAddress?: string;
  placeholderFullAddress?: string;
  labelCivico?: string;
  labelComune?: string;
  labelCAP?: string;
  labelAddress?: string;
  placeholderAddress?: string;
  onDone: Function;
  disabled?: boolean;
  errors?: string[];
  errorTexts?: IFeedbackText[];
}

interface AddressState {
  cityOfResidence: AjaxAutocompleteObject | undefined;
  singleAddress: string | undefined;
  addressWithNumber: string | undefined;
  mapsCivico: string | undefined;
  autocompleteCities: any;
  postalCode: string | undefined;
  postalCodesList: any;
  showCivico: boolean;
  mapAddress: string | undefined;  // composizione di indirizzo, cap e residenza
  mapsSuggestions: any;
  mapsKey: any;
  choosenAddress: any;
  isInvalidAddress: boolean | undefined,
  useOnBlur: boolean;
  errors: string[];
  errorTexts: IFeedbackText[]
}

class Address extends Component<AddressProps, AddressState> {

  private labelFullAddress: string = "Indirizzo completo";
  private placeholderFullAddress: string = "";
  private labelCivico: string = "Numero civico";
  private labelComune: string = "Comune";
  private labelCAP: string = "CAP";
  private labelAddress: string = "Indirizzo";
  private placeholderAddress: string = "";
  private constructorInit : boolean = true;

  private previousMapAddress: string | undefined;

  private mapsSession: any = "";
  private autoCompleteMapsKey: any;

  constructor(props: any) {
    super(props);

    // sistemo l'indirizzo base, quando arriva con "parti duplicate"
    const tmp = fixBadAddress(this.props?.address);

    // se l'indirizzo arriva da un caricamento da email è gia nel fomato
    // "Via delle rose, 1, Roma (RO), ... Italia"
    let inintialMapAddress: string | undefined = (isCompleteAddress(tmp))
      ? tmp
      : this.buildMapAddres(
        tmp,
        this.props?.civico,
        this.props?.postalCodeOfResidence,
        this.props?.cityOfResidence
      );

    let recivedSingleAddress = tmp;

    if (isCompleteAddress(tmp)) {
      let { singleAddress, civico, cap, city } = destructMapAddress(tmp);
      recivedSingleAddress = singleAddress;
    } else if (!tmp.match(regex_only_civico)) {
      // indirizzo inserito "a mano" senza riconoscimento di hear!     
      recivedSingleAddress = (tmp !== undefined && tmp !== '') ? tmp : undefined;
      if (recivedSingleAddress !== undefined && this.props?.civico) { 
        recivedSingleAddress = tmp + ', ' + this.props?.civico;
      }
    }

    this.previousMapAddress = inintialMapAddress;

    const loadedCivico = this.props?.civico ?? extractAddressData(this.props?.address ?? "")?.numero;

    this.state = {
      isInvalidAddress: false,
      cityOfResidence: this.props?.cityOfResidence,
      postalCode: this.props?.postalCodeOfResidence,
      singleAddress: recivedSingleAddress,
      addressWithNumber: recivedSingleAddress,
      mapsCivico: (loadedCivico !== "") ? loadedCivico : undefined,
      mapAddress: inintialMapAddress,
      showCivico: (
        ((this.props?.address ?? undefined) !== undefined && this.props.address !== "") && 
        (!this.props?.civico) 
      ),
      autocompleteCities: [],
      postalCodesList: this.props?.postalCodeOfResidence
        ? [{ id: this.props?.postalCodeOfResidence, value: this.props?.postalCodeOfResidence }]
        : undefined,
      mapsSuggestions: [],
      mapsKey: [],
      choosenAddress: undefined,
      useOnBlur: true,
      errors: this.props?.errors ?? [],
      errorTexts: this.props?.errorTexts ?? []
    }

    this.labelInit();

    this.eventBinder();
  }
  
  componentDidMount() {
    //console.log(this.props?.errors);

    if(this.props.address || this.props.postalCodeOfResidence || this.props.cityOfResidence?.id){
      fetcAddressAutoComplete(this.state.mapAddress, this.mapsSession,
        () => {
          this.setState({
            isInvalidAddress: false,
          }, () => {
            this.constructorInit = false;
          })
        },
        (result: any) => {
          const isInvalidAddress: boolean = (result?.placeList === undefined || result?.placeList.length === 0) ? true : false;
          this.setState({
            isInvalidAddress: isInvalidAddress,
            showCivico: !isInvalidAddress && this.state.mapsCivico !== undefined ? false : true
          }, () => {
            this.constructorInit = false;
          })
        }
      )
    }
  }
  
  private eventBinder(){
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.completeAddress = this.completeAddress.bind(this);
    this.selectValue = this.selectValue.bind(this);
    this.buildMapAddres = this.buildMapAddres.bind(this);

    this.autoCompleteChange = this.autoCompleteChange.bind(this);
    this.autoCompleteBlur = this.autoCompleteBlur.bind(this);

    this.onDoneHandler = this.onDoneHandler.bind(this);

  }

  private labelInit(){
    this.labelFullAddress = this.props.labelFullAddress ?? this.labelFullAddress;
    this.placeholderFullAddress = this.props.placeholderFullAddress ?? this.labelFullAddress;
    this.labelCivico = this.props.labelCivico ?? this.labelCivico;
    this.labelComune = this.props.labelComune ?? this.labelComune;
    this.labelCAP = this.props.labelCAP ?? this.labelCAP;
    this.labelAddress = this.props.labelAddress ?? this.labelAddress;
    this.placeholderAddress = this.props.placeholderAddress ?? this.placeholderAddress;
  }

  /* shortcut per ritornare al chiamate i campi singleAddress, postalCode e cityOfResidence */
  private onDoneHandler(){
    let params: any[] = [
      this.state.singleAddress ?? "", 
      this.state.postalCode ?? "", 
      this.state.cityOfResidence ?? "", 
      this.state.mapsCivico ?? ""
    ];
    this.props.onDone(params);
  }

  render() {
    const disabled = this.props?.disabled ?? false;

    return <div>
              
      <FeedbackBox items={this.props?.errorTexts ?? []} filter={{severity: "warn"}}/>
      
      {this.state?.isInvalidAddress === undefined || this.state.isInvalidAddress === false
        ? <div className="addres_container">
            {/** prima opzione provo a validare tramite servizio targhe */}
            <label>{this.labelFullAddress}</label>
            <AutoComplete
              value={this.state.mapAddress}
              minLength={3}
              placeholder={this.placeholderFullAddress}
              completeMethod={this.completeAddress}
              onSelect={(e) => {this.selectValue(replaceSpecialChars(e.value.label), replaceSpecialChars(e.value.value))}}
              onKeyPress={this.avoidSpecialCharacters}
              onChange={this.autoCompleteChange}
              onBlur={this.autoCompleteBlur}
              suggestions={this.state.mapsSuggestions}
              delay={1000}
              field="value"
              disabled={disabled}
              onShow={() => {this.setState({useOnBlur: false})}}
              onHide={() => {this.setState({useOnBlur: true})}}
            />
            {this.state.showCivico === true &&
              <>
                <span className='civicoInput'>
                  <InputText className='mt-3'
                    id="civicoInput"
                    placeholder="N°"
                    autoFocus
                    value={this.state?.mapsCivico ?? ""} 
                    onChange={(selected) => this.onChangeHandler(selected.target.value, "addressNumber")}
                    disabled={disabled}
                  />
                </span>
              </>
            }
            <ContextFeedback
              show={(
                (this.props?.errors?.includes("cityOfResidence") ?? false) &&
                (this.props?.errors?.includes("postalCodeOfResidence") ?? false) &&
                (this.props?.errors?.includes("address") ?? false)
                ) ||
                (this.props?.errors?.includes("addressNumber") ?? false)
              }
              message={{
                msg: Array.isArray(this.props.errors) && this.props.errors.length > 0
                  ? (this.props.errors.includes("cityOfResidence") && this.props.errors.includes("postalCodeOfResidence") && this.props.errors.includes("address"))
                    ? "Inserire un indirizzo valido e completo"
                    : "Inserire il numero civico"
                  : "",
                severity: Array.isArray(this.props?.errorTexts) && this.props.errorTexts.length > 0 ? this.props.errorTexts[0]?.severity ?? "error" : "error"
              }}
            />
          </div>
        : <div>
            <div className='row mb-4'>
              <div className="col-md-12 col-lg-9 mb-4">
                <label>{this.labelComune}</label>
                <AutoComplete
                  value={this.state.cityOfResidence}
                  suggestions={this.state.autocompleteCities}
                  minLength={2}
                  completeMethod={(e) => fetchAutocomplete(
                    e,
                    Constants.SEISICURO_AJAX_CITY_OF_BIRTH_ENDPOINT, 
                    (result: any) => { this.setState({
                      autocompleteCities: result
                    }) },
                    "RESIDENZA"
                  )}
                  field="value"
                  placeholder={this.labelComune}
                  onChange={(selected) => this.onChangeHandler(selected, "cityOfResidence")} 
                  disabled={disabled}
                />                
                <ContextFeedback
                  show={this.props?.errors?.includes("cityOfResidence") ?? false}
                  message={{
                    msg: this.props?.errors?.includes("cityOfResidence") ?? false
                      ? "Inserire il comune"
                      : "",
                    severity: Array.isArray(this.props?.errorTexts) && this.props.errorTexts.length > 0 ? this.props.errorTexts[0]?.severity ?? "error" : "error"
                  }}
                />
              </div>

              {/** CAP */}
              <div className="col-md-12 col-lg-3 mb-4">
                <label>{this.labelCAP}</label>
                <Dropdown 
                  value={this.state.postalCode} 
                  options={this.state.postalCodesList}
                  onChange={(selected) => this.onChangeHandler(selected.value, "postalCodeOfResidence")}
                  optionLabel="value" 
                  placeholder={this.labelCAP}
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                  disabled={disabled}
                />                
                <ContextFeedback
                  show={this.props?.errors?.includes("postalCodeOfResidence") ?? false}
                  message={{
                    msg: this.props?.errors?.includes("cityOfResidence") ?? false
                      ? "Inserire il CAP"
                      : "",
                    severity: Array.isArray(this.props?.errorTexts) && this.props.errorTexts.length > 0 ? this.props.errorTexts[0]?.severity ?? "error" : "error"
                  }}
                />
              </div>

              {/** Indirizzo */}
              <div className="col-12 mb-4">
                <label>{this.labelAddress}</label>
                <InputText 
                  value={this.state.addressWithNumber ?? ''}
                  onChange={(selected) => this.onChangeHandler(selected.target.value, "addressWithNumber")}
                  placeholder={this.placeholderAddress}
                  name="address"
                  autoComplete="street-address"
                  onKeyDown={(event) => { 
                    if (!isAddressCharacterValid(event.key)) { 
                      event.preventDefault() 
                    }
                  }}
                  disabled={disabled}
                />                
                <ContextFeedback
                  show={(this.props?.errors?.includes("address") ?? false) || (this.props?.errors?.includes("addressNumber") ?? false)}
                  message={{
                    msg: Array.isArray(this.props.errors) && this.props.errors.length > 0
                      ? this.props.errors.includes("address")
                        ? "Inserire un indirizzo valido e completo"
                        : "Inserire il numero civico"
                      : "",
                    severity: Array.isArray(this.props?.errorTexts) && this.props.errorTexts.length > 0 ? this.props.errorTexts[0]?.severity ?? "error" : "error"
                  }}
                />
              </div>

              {!disabled &&
                <div>
                  <span className="aLike" onClick={() => {
                    this.previousMapAddress = "";
                    this.setState({
                      isInvalidAddress: false,
                      errors: [],
                      errorTexts: [],
                      cityOfResidence: undefined,
                      singleAddress: undefined,
                      mapsCivico: undefined,
                      postalCode: undefined,
                      postalCodesList: undefined,
                      mapAddress: undefined,
                    }, () => this.onDoneHandler())
                  }}>Torna alla ricerca dell'indirizzo</span>
                </div>
              }
            </div>

          </div>
      }
    </div>
  }

  private autoCompleteBlur(e?: any){
    //console.log("autoCompleteBlur");
    if(this.state.useOnBlur){
      if(this.previousMapAddress !== this.state.mapAddress && this.state.mapAddress !== ""){
        this.setFullAddressData()
      }
    }
  }

  private autoCompleteChange(e: any) {
    //console.log("autoCompleteChange");
    this.constructorInit = false;
    this.previousMapAddress = this.state.mapAddress;
    if(e.originalEvent.type === "click"){
      this.setState({
        useOnBlur: false,
        mapAddress: e.value
      })
    }
    else if(e.originalEvent.type === "change"){
      this.setState({
        mapAddress: e.value
      })
    }
  }

  // al termine della digitazione nel campo
  private completeAddress (e: any | undefined) {
    //console.log("completeAddress");
    const inputValue: string = e.query;
    this.autoCompleteMapsKey = undefined;

    fetcAddressAutoComplete(inputValue, this.mapsSession,
      () => {},
      (result: any) => {
        this.mapsSession = result.session;

        if (result.placeList !== undefined && Array.isArray(result.placeList) && result.placeList.length > 0) {
          let suggestions: any = [];
          let keys: any = [];
          result.placeList.forEach((res: any) => {
            suggestions.push({ label: res.description, value: res.description });
            keys.push({ key: res.description, value: res.placeId });
          });

          this.setState({
            useOnBlur: false,
            mapsSuggestions: suggestions,
          })
          this.autoCompleteMapsKey = keys
        }
        else {

          this.setState({
            useOnBlur: true,
            // evito che "esploda", se non trova suggerimenti
            choosenAddress: undefined,
            mapsSuggestions: undefined,
            mapsCivico: undefined
          })

          this.autoCompleteMapsKey = undefined
        }
      }
    );
  }

  private setFullAddressData(){
    //console.log("setFullAddressData");
    if(!this.constructorInit){

      if (this.state.choosenAddress?.indirizzo) {
        // provengo da una select del dropdown
        const choosenIndirizzo = this.state.choosenAddress.indirizzo;
  
        if (
          choosenIndirizzo.toponimo === '-1' ||
          choosenIndirizzo.cap === '-1' ||
          choosenIndirizzo.city === '-1' ||
          choosenIndirizzo.pv === '-1' ||
          choosenIndirizzo.state === '-1' ||
          choosenIndirizzo.address === '-1'
        ) {
          this.setState({
            isInvalidAddress: true,
            cityOfResidence: undefined,
            singleAddress: undefined,
            mapAddress: undefined,
            mapsCivico: undefined,
            postalCode: undefined,
            postalCodesList: undefined,
          });
        }
        else {

          let addressState: any = {};
          addressState = {
            mapsCivico: (choosenIndirizzo.number !== undefined && choosenIndirizzo.number !== "" && choosenIndirizzo.number !== -1) 
              ? choosenIndirizzo.number : undefined,
            singleAddress: `${choosenIndirizzo.toponimo} ${choosenIndirizzo.address}`
          }

          this.previousMapAddress = this.state.choosenAddress.placeList[0].description;
          this.setState({
            ...addressState,
            cityOfResidence: {
              id: `${choosenIndirizzo.city} (${choosenIndirizzo.pv})`,
              value: `${choosenIndirizzo.city} (${choosenIndirizzo.pv})`
            },
            postalCode: choosenIndirizzo.cap,
            mapAddress: this.state.choosenAddress.placeList[0].description,
            postalCodesList: [{ id: choosenIndirizzo.cap, value: choosenIndirizzo.cap }],
            
  
            errors: [],
            errorTexts: [],
  
            isInvalidAddress: false,
            showCivico: addressState.mapsCivico ? false : true
          }, this.onDoneHandler);
        }
  
      }
      else{
        let state: any = {};
        const fulladdressTest: any = destructMapAddress(this.state.mapAddress ?? "")
        if(fulladdressTest){
          const {singleAddress, civico, cap, city} = fulladdressTest;
          if(singleAddress && cap && city){
            state = {
              postalCode: cap,
              mapsCivico: civico,
              postalCodesList: [{id: cap, value: cap}],
              cityOfResidence: {id: city, value: city},
              singleAddress: singleAddress,
              isInvalidAddress: false,
            }
          }
          else{
            state = {
              postalCode: undefined,
              mapsCivico: undefined,
              postalCodesList: undefined,
              cityOfResidence: undefined,
              singleAddress: undefined,
              isInvalidAddress: (this.state.mapAddress !== ""),
              errors: (this.state.mapAddress !== "")
                ? [...this.state.errors.filter((error: string) => error !== "generic"), "generic"]
                : this.state.errors,
              errorTexts: (this.state.mapAddress !== "") 
                ? [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "generic"), {field: "generic", msg: `Non siamo riusciti a validare il tuo indirizzo: "${this.state.mapAddress}", inseriscilo manualmente qui sotto`, severity: "warn"}]
                : this.state.errorTexts
                
            }
          }
        }
        else{
          state = {
            postalCode: undefined,
            mapsCivico: undefined,
            postalCodesList: undefined,
            cityOfResidence: undefined,
            singleAddress: undefined,
            isInvalidAddress: (this.state.mapAddress !== "")
          }
        }
  
        this.setState({...state}, this.onDoneHandler)
  
      }
    }
  }

  /**
   * Selezione dell'indirizzo dai suggerimenti
   * @param inputValue il valore del campo
   * @param placeId l'ID del suggerimento cliccato
   */
  private selectValue (inputValue: string | undefined, placeId?: any) { 
    //console.log("selectValue");
    // evito di mandare i vecchi valori (non modificati o parziali)
    let findKey : string | undefined;
    if(placeId !== undefined){
      findKey = this.autoCompleteMapsKey.find((x: any) => x.key === placeId);
    }
    else{
      findKey = undefined;
    }

    this.setState({
      useOnBlur: false,
    }, () => {
      fetcAddressAutoComplete(inputValue, this.mapsSession,
        () => {},
        (result: any) => {
          this.mapsSession = result.session;
          this.setState({
            choosenAddress: result,
          }, () => {
            this.setFullAddressData();
            this.setState({
              useOnBlur: true
            })
          })
        },
        findKey
      );
    })
  }

  /**
   * Cambiamento stato interno al component
   * @param selected il valore modificato
   * @param componentName nome del campo che è cambiato
   */
  private onChangeHandler(selected: any, componentName: string) {
    //console.log("selectValue");
    switch (componentName) {

      case 'postalCodeOfResidence':
        this.setState({
          postalCode: selected,
          mapAddress: this.buildMapAddres(
            this.state.singleAddress,
            this.state.mapsCivico,
            selected,
            this.state.cityOfResidence
          )
        }, this.onDoneHandler)
        break;

      case 'cityOfResidence':
        const clearedCity = replaceSpecialChars(selected.value);
        this.setState({
            cityOfResidence: clearedCity,
            mapAddress: this.buildMapAddres(
              this.state.singleAddress,
              this.state.mapsCivico,
              this.state.postalCode,
              clearedCity
            )
          }, 
          () => {
            if (selected.value === '' || selected.value === undefined || selected.value.id === undefined) {
              this.setState({
                postalCodesList: [],
                postalCode: undefined
              }, this.onDoneHandler);
            }
            else{
              fetchList(
                Constants.SEISICURO_AJAX_POSTAL_CODE_ENDPOINT(selected.value.id),
                (result: any) => {
                  const postalCode = result.length === 1 ? result[0].id : undefined;
                  this.setState({
                    postalCodesList: result,
                    postalCode: postalCode,
                    mapAddress: this.buildMapAddres(
                      this.state.singleAddress,
                      this.state.mapsCivico,
                      postalCode,
                      clearedCity
                    )
                  }, this.onDoneHandler);
                },
                this.onDoneHandler
              );
            }
          }
        );
        break;

      case "addressWithNumber":
        let addressWithNumber = (selected ?? "");
        let address: string = "";
        let number = addressWithNumber.match(regex_only_civico);
        if(number){
          address = addressWithNumber.replace(number, "").replace(",", "").trim();
        }

        if (!number) number = "";
        if (number && Array.isArray(number) && number.length > 0) number = number[0];

        this.setState({
          addressWithNumber: addressWithNumber,
          mapsCivico: number,
          singleAddress: address
        }, this.onDoneHandler)
        break;

      case "singleAddress":
        let singleAddress = (selected ?? "");
        let civico = singleAddress.match(regex_only_civico);

        if (!civico) civico = "";
        if (civico && Array.isArray(civico) && civico.length > 0) civico = civico[0];

        this.setState({
          singleAddress: singleAddress, // .replace(regex_only_civico, ''),
          mapsCivico: civico
        }, this.onDoneHandler)
        break;

      case "addressNumber":
        this.setState({
          mapsCivico: selected,
          mapsSuggestions: [],
        }, this.onDoneHandler);
        break;
      
      default:
        this.setState({ ...this.state, [componentName]: selected }, this.onDoneHandler);
        break;

    }
  }

  /**
   * Restituisce una stringa rapresentante un indirizzo civico
   * -- FIX Veniva chiamata dal render!!! se in address ho già il civico lo riappende!!!
   * -- inoltre nel caso di indirizzi "completi anche di civico", non permette la cancellazione del indirizzo
   * -- dato che, ad ogni chiamata / pressione del tasto, ri-setta il value del indirizzo sempre con la stessa stringa!
   * @see fixBadAddress per la correzione degli altri bug / regression
   * @param address la "via" e il numero civico (compreso di toponimo)
   * @param civico il civico
   * @param cap il cap
   * @param city il comune (compreso di provincia)
   * @returns una stringa nel formato "Via pippo, 1, 08100 Nuoro (NU), Italia"
   */
  private buildMapAddres (address: any, civico: any, cap: any, city: any) : string {
    if (address && cap && city) {
      let isAddressWithNumber = address.includes(",") || regex_only_civico.test(address);
      let mappedAddress: string = (civico && !isAddressWithNumber)
        ? `${address}, ${civico}, ${cap} ${city.value}, Italia`
        : `${address}, ${cap} ${city.value}, Italia`;
      
      return mappedAddress.trim();
    }

    return "";
  }

  /**
   * Impedisce la digitazione di caratteri speciali
   * @param event l'evento che ha scatenato il trigger della funzione
   * @returns false se se solo se è stato digitato un carattere non ammesso
   */
  private avoidSpecialCharacters(event: any) {
    let regex = new RegExp("^[0-9A-zÀ-ú\\s'.(),/\\-]+$");
    let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);

    if (!regex.test(key)) {
      event?.preventDefault();
      return false;
    }
  }

}

export default Address;