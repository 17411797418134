import { Component, ForwardedRef, forwardRef } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import * as Constants from '../utils/constants';
import { FeedbackBox, IFeedbackText } from "../utils/Feedback";
import { InputText } from "primereact/inputtext";
import User, { IPrivacyStatus } from "../utils/User";
import { step3DataSuccess, userInfoDataSuccess } from "../../redux/actions";
import { AccountNotActivatedException, PasswordException } from "../utils/UserService";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { connect, } from "react-redux";
import { Step3Data, UserInfo } from "../utils/common";
import React from "react";

type OverrideDisplay = {
  override: boolean;
  show?: boolean;
}

interface ILoginDialogPros {
  vehicle: string;
  visible?: boolean;
  email: string;
  header?: JSX.Element;
  onHide?: (prevState: UserInfo, nextState: UserInfo) => void;
  onLogin?: (user: User, prevState: UserInfo, nextState: UserInfo) => void;
  style?: any;
  setPrivacy: Function;
  userInfoDataSuccess: Function;
  userInfo: UserInfo;
  history: ReturnType<typeof useHistory>;
  ref: ForwardedRef<any>;
  override_display: OverrideDisplay
}

interface ILoginDialogState{
  registrationDialogVisible: boolean,
  logging: boolean;    // flag per mostrare lo spinner di caricamento
  password: string,
  header?: any,
  errors: any[];
  errorTexts: IFeedbackText[];
  resetHeader: boolean; //flag per impostare il reset a default_header dell'header della dialog
}
class LoginDialogComponent extends Component< ILoginDialogPros, ILoginDialogState> {

  private readonly default_header: JSX.Element = <div className='mt-lg-4 mt-2'>
    <h3 className="titleLev5 colorPrimaryBlue">Che bello rivederti!</h3>
    <p className="mb-0">Effettua il login per accedere a tutti i vantaggi della tua area personale.</p>            
  </div>

  constructor(props: any) {
    super(props);
    
    this.state = {
      registrationDialogVisible: false,
      password: "",
      logging: false,
      header: this.props?.header ? this.props.header : this.default_header,
      resetHeader: false,
      errors: [],
      errorTexts: []
    }

    this.onRegistrationClick = this.onRegistrationClick.bind(this);
    this.defaultOnHideCallback = this.defaultOnHideCallback.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onHide = this.onHide.bind(this);
    this.handleOnHide = this.handleOnHide.bind(this);
    this.handleOnLogin = this.handleOnLogin.bind(this);
  }

  handleOnHide(prevState: UserInfo, nextState: UserInfo) {
    this.props.onHide?.(prevState, nextState) ?? this.defaultOnHideCallback(prevState, nextState);
  }

  public setOnHide(callback: (prevState: UserInfo, nextState: UserInfo) => void){
    this.handleOnHide = callback;
  }

  private defaultOnHideCallback(prevState: UserInfo, nextState: UserInfo){
    // if(!this.props.userInfo.step1EmailValid){
    //   this.props.userInfoDataSuccess({
    //     emailChecking: undefined,
    //     emailRegistered: undefined
    //   })
    // }
  }

  handleOnLogin(user: User, prevState: UserInfo, nextState: UserInfo) {
    this.props.onLogin?.(user, prevState, nextState) ?? this.defaultOnLoginCallback(user, prevState, nextState);
  }

  public setOnLogin(callback: (user: User, prevState: UserInfo, nextState: UserInfo) => void){
    this.handleOnLogin = callback;
  }


  private defaultOnLoginCallback(user: User, prevState: UserInfo, nextState: UserInfo){

    // if(prevState?.externalJourney ?? false){
    //   // gestito nella app con il setOnLogin
    // }

    if(user.id !== JSON.parse(prevState.userData ?? "{}").id){     
      User.resetStepData(this.props.vehicle)         
      let privacyToDispatch: Step3Data = {};
      user.privacy.forEach((p: IPrivacyStatus) => {
        switch(p.type){
          case "generale":
            privacyToDispatch.acceptSeiSicuroPrivacyPolicy = true
            break;
          case "commerciale":
            privacyToDispatch.acceptGdprPrivacyPolicy102 = p.consent;
            break;
          case "newsletter":
            privacyToDispatch.acceptGdprPrivacyPolicy22 = p.consent;
            break;
          case "profilazione":
            privacyToDispatch.acceptGdprPrivacyPolicy3 = p.consent;
            break;
          case "cessione":
            privacyToDispatch.acceptGdprPrivacyPolicy104 = p.consent;
            break;
          case "cgu":
            privacyToDispatch.acceptCgu105 = true;
            break;
          case "ivass":
            privacyToDispatch.acceptIvass106 = false;
            break;
        }
      })

      this.props.setPrivacy(privacyToDispatch, this.props.vehicle);
      if(this.props.userInfo.currentStep !== 1){
        this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.props.vehicle))
      }
    }
  }

  render() {

    return (
      <Dialog
        {...this.props}
        visible={this.props.override_display.override
          ? (this.props.override_display.show ?? false)
          : this.props.userInfo.openLogin ?? false
        }
        // visible={this.props.userInfo.openLogin ?? false}
        breakpoints={{'960px': '75vw'}}
        modal={true}
        onHide={this.onHide}
        header={this.state.header}
        resizable={false}
        draggable={false}
        id="login-panel"
      >
        <div className="container-fluid no-gutters">

          <FeedbackBox items={this.state.errorTexts}/>
          
          <div className="row mb-2">
            <div className="col-12">
              <label htmlFor="emailLogin">E-mail</label>
              <InputText
                id="emailLogin"
                placeholder="E-mail"
                name="email"
                autoComplete="email"
                type="email"
                value={this.props.userInfo?.email ?? ""}
                onChange={(selected) => this.props.userInfoDataSuccess({email: selected.target.value})}                                    
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label className="mt-2" htmlFor="passLogin">Password</label>
              <Password
                id="passLogin"
                placeholder="Password"
                name="password"
                toggleMask
                feedback={false}
                value={this.state.password}
                onChange={(evt) => this.setState({password: evt.target.value})}
              />
              <div className="mt-3">
                <a className="aLike" href="/preventivi/area-personale/password-dimenticata" target="_blank">Hai dimenticato la password?</a>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <Button
                className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                onClick={this.onLogin}
                label="Accedi"
                loading={this.state.logging}
              />
            </div>
          </div>

          { false &&
            <Divider className="my-5" align="center">
              <div className="inline-flex align-items-center">
                <b className='colorPrimaryBlue'>oppure</b>
              </div>
            </Divider>
          }

          <div className="row">
            <div className="col-12 text-center">
              { false &&
                <div className="text-center">
                  <Button className="customBtn bgColorGrey colorPrimaryBlue hoverBgColorDarkgreen hoverColorWhite">Accedi con Facebook</Button><br/>
                  <Button className="customBtn bgColorGrey colorPrimaryBlue hoverBgColorDarkgreen hoverColorWhite mt-2">Accedi con Google</Button>
                </div>
              }
              <div className='mt-4'>
                <Link className="aLike" to={{pathname: "https://www.6sicuro.it/nostri-contatti/"}} target="_blank">Hai bisogno di aiuto? Contattaci</Link>
                {/* <a className="aLike" href="https://www.6sicuro.it/nostri-contatti/" target="_blank" rel="noreferrer">Hai bisogno di aiuto? Contattaci</a> */}
              </div>

              <div className="text-center">Non hai ancora un account? <span className='aLike' onClick={this.onRegistrationClick}>Registrati</span></div>
            </div>        
          </div>
        </div>
      </Dialog>
    );
  }

  public setHeader(header: JSX.Element){
    this.setState({header: header});
  }

  private onRegistrationClick() {
    this.props.userInfoDataSuccess({openLogin: false, openRegistration: true})
  }

  /**
   * Gestione click sul bottone di login
   * (uso una funzione per poter gestire eventuali traking)
   */
  private onLogin() {
    // per sicurezza attivo solo se l'utente non è già loggato

    if(!User.isAlive()){
  
      this.setState({logging: true, errorTexts: []}, () => {
        User.login(
          this.props.userInfo?.email ?? "",
          this.state.password ?? ""
        )
          .then((user: User) => {
            const prevState = this.props.userInfo;
            if(!(user instanceof Error)){

              let dataToDispatch: UserInfo = {
                logged: true,
                openLogin: false,
                openRegistration: false,
                userData: JSON.stringify(user),
                // skipEmailCheck: undefined
              };
              const nextState = {...this.props.userInfo, ...dataToDispatch};
   
              this.setState({logging: false, password: ""});
              this.props.userInfoDataSuccess(dataToDispatch);

              this.handleOnLogin(user, prevState, nextState);

              this.handleOnLogin = this.defaultOnLoginCallback;
            }
          })
          .catch((err: Error) => {
            if(err instanceof PasswordException || err instanceof AccountNotActivatedException){
              this.setState({logging: false, password: "", errorTexts: [err.getData()]});
            }
            else{
              this.setState({logging: false, password: "", errorTexts: [{field: "generic", msg: "Errore generico"}]});
            }
          })
      })
    }
  }

  private onHide(){

    if(!User.isAlive()){
      this.setState({
          password: "",
          errors: [],
          errorTexts: []
        },
        () => {

          const prevState: UserInfo = this.props.userInfo;
          let payload: UserInfo = {
            openLogin: false,
            logged: false,
            userData: JSON.stringify(User.getInstance().setInitialState()),
            email: ""
          };

          this.props.userInfoDataSuccess(payload)
          const nextState = {...this.props.userInfo, ...payload};

          this.handleOnHide(prevState, nextState);

          // reimposto la callback di default
          this.handleOnHide = this.defaultOnHideCallback;
        }
      );
    }
  }
  
  public show(){
    this.props.userInfoDataSuccess({openLogin: true})
  }

}


const mapState = (state: any) => {
  return {
    userInfo: state.userInfoData.user,
    visible: state.userInfoData.user.openLogin,
    email: state.userInfoData.user.email ?? "",
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    setPrivacy: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle))
  }
};

const LoginDialog = forwardRef((props: any, ref: ForwardedRef<LoginDialogComponent>) => {
  const history: ReturnType<typeof useHistory> = useHistory();
  // const match: ReturnType<typeof useRouteMatch> = useRouteMatch({
  //   path: "/preventivi/area-personale/password-dimenticata"
  // });
  const match: ReturnType<typeof useRouteMatch> = useRouteMatch([
    "/preventivi/area-personale/login",
    "/preventivi/area-personale/password-dimenticata",
    "/preventivi/area-personale/registrazione",
    "/preventivi/area-personale/reset-password",
    "/preventivi/area-personale/conferma-attivazione-account",
    "/preventivi/area-personale/account-rimosso",
  ])
  
  
  let override_display: OverrideDisplay;

  if(match){
    override_display = {
      override: true,
      show: false
    }
  }
  else{
    override_display = {
      override: false
    }
  }


  return <LoginDialogComponent {...props} ref={ref} history={history} override_display={override_display} />
})

export default connect(mapState, mapDispatch, null, {forwardRef: true})(LoginDialog);
