import { createContext } from "react";

export type IBreadcrumbsCompiler = (params: any) => string;

export type ActionFunction = (params: any) => any;

export interface IPageDescriptor {
  url: string;
  name: string;
  fullTitle: string;
  breadcrumbTitle: string | IBreadcrumbsCompiler;
  directAction?: boolean;
  /*
    if FALSE  -> component viene innestato come pagina
    if TRUE   -> component viene innestato nel menu laterale
  */
  component: (props: any) => JSX.Element;
  componentParams?: any,
  seo: {};
  submenu?: string[];
  inMenu?: boolean;
  icon?: string;
}

const PagesContext = createContext<IPageDescriptor[]>([]);

export default PagesContext;