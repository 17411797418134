import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import PerventivatoreStepNavigator from "./customs/PreventivatoreStepNavaigator";
import NoteInformative from "./NoteInformative";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step4Loader from "./Step4Loader";
import Step5 from "./Step5";
import Step5Error from "./Step5Error";
import User from "./utils/User";
import { Component, createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { userInfoDataSuccess } from "../redux/actions";
import { StepDataDualState } from "../redux/reducers";
import { Step1Data, UserInfo } from "./utils/common";
import * as Constants from "./utils/constants";
import FetchLists, { IResourceToLoad } from "./customs/FetchLists";

interface IPreventivatoreHubProps {
  step1Data: StepDataDualState;
  userInfoData: UserInfo;
  userData: string;
  path: string;
  url: string;
  // skipEmailCheck: boolean;
  userInfoDataSuccess: Function;
  vehicle: string;
  setResourceListRequest: Function;
}

interface IPreventivatoreHubState {
  user: User;
  last_mail_checked: string | undefined;
  last_vehicle: string;
  // vehicle: string;
}

interface IEmailValidation{
  emailValid: boolean;
  emailRegistered?: boolean
}

export const ResourceListRequestContext: React.Context<IResourceToLoad> = createContext({});

class PreventivatoreHubComponent extends Component<IPreventivatoreHubProps, IPreventivatoreHubState> {

  private vehicle: string = "";
  private readonly default_vehicle: string = "auto";
  // header del popup di login da settare quando viene inserita una mail registrata ma non loggata
  private forceToLoginHeader: JSX.Element = <div>
    <h3 className="titleLev4 colorPrimaryBlue">Un preventivo tutto per te!</h3>
    <p>Accedi ai vantaggi dell'area personale. Potrai salvare il preventivo più conveniente e bloccare il miglior prezzo.</p>
  </div>;
  private static emailValidationDelay: ReturnType<typeof setTimeout> | null = null;

  constructor(props: IPreventivatoreHubProps){
    super(props);

    const step1Data: Step1Data = this.props.step1Data[this.props.vehicle as keyof StepDataDualState] as Step1Data;
    
    this.state = {
      user: User.getInstance(),
      last_mail_checked: step1Data?.email,
      last_vehicle: this.props.userInfoData?.currentVehicleSelector ?? ""
      // vehicle: this.vehicle
    }
  }

  componentDidMount() {
    // this._isMounted = true;
    
    const step1Data: Step1Data = this.props.step1Data[this.props.vehicle as keyof StepDataDualState] as Step1Data;

    //TODO remove lowercase
    if(step1Data?.email ?? false){
      console.log(`PreventivatoreHub->componentDidMount check ${step1Data.email}`);
      this.props.userInfoDataSuccess({emailChecking: true})
      const emailToCheck: string = step1Data?.email?.toLocaleLowerCase() ?? "";
      User.emailChecker(emailToCheck)
        .then((emailValidation: IEmailValidation) => {
          let dataToDispatch: UserInfo = {};

          const step1Data: Step1Data = this.props.step1Data[this.props.vehicle as keyof StepDataDualState] as Step1Data;
          const userInfoData: UserInfo = this.props.userInfoData;
          
          dataToDispatch.emailChecking = false;

          if(!(userInfoData?.fromBO || step1Data.email !== emailToCheck)){
            console.log(userInfoData?.fromBO);
            console.log(`${step1Data.email} === ${emailToCheck}`);

            if(this.state.user.id !== -1){
              // logged
              dataToDispatch.step1EmailValid = this.state.user.email === step1Data.email
            }
            else{
              //guest
              dataToDispatch.step1EmailValid = emailValidation.emailValid && !(emailValidation?.emailRegistered ?? false)
            }
            
            if(!dataToDispatch.step1EmailValid){
              dataToDispatch.emailRegistered = emailValidation?.emailRegistered;
              dataToDispatch.email = emailToCheck;
              dataToDispatch.openLogin = !dataToDispatch.step1EmailValid && (this.state.user.id === -1 && dataToDispatch.emailRegistered);
            }
          }
  
          this.props.userInfoDataSuccess(dataToDispatch)
        })
    }
    else{
      this.props.userInfoDataSuccess({
        emailChecking: undefined,
        step1EmailValid: undefined,
        email: undefined
      })
    }
  }
  
  static getDerivedStateFromProps(newProps: IPreventivatoreHubProps, prevState: IPreventivatoreHubState): Promise<IPreventivatoreHubState>{

    let nextState: any = {};
    if(newProps.userData !== JSON.stringify(prevState.user)){
      nextState.user = User.getInstance()
    }

    return nextState;
  }

  componentDidUpdate(prevProps: Readonly<IPreventivatoreHubProps>, prevState: Readonly<IPreventivatoreHubState>, snapshot?: any){

    let nextState: any = {};
    let shouldBeUpdate: boolean = false;

    let dataToDispatch: UserInfo = {}
    let do_not_dispatch: boolean = false;

    const step1Data: Step1Data = this.props.step1Data[this.props.vehicle as keyof StepDataDualState] as Step1Data;
    const email_to_check: string = step1Data?.email ?? "";

    const mailCheckCondition: boolean = !(this.props.userInfoData.emailChecking === true) &&
    prevState.last_mail_checked !== undefined &&
    (prevState.last_mail_checked !== email_to_check ||
    prevState.last_vehicle !== this.props.userInfoData.currentVehicleSelector) &&
    !(this.props.userInfoData?.fromBO ?? false);

    if(mailCheckCondition){
      if(nextState?.user !== undefined && nextState.user.id !== -1){
        // l'utente è loggato
        const user = nextState.user as User;
        if(email_to_check !== user.email){
          // l'email non corrisponde a quella dell'utente loggato
          dataToDispatch.step1EmailValid = false;
        }
        else{
          dataToDispatch.step1EmailValid = true;
        }
        // dataToDispatch.skipEmailCheck = undefined;
        dataToDispatch.emailChecking = false;
      }
      else if(prevState.user.id === -1){
        // l'utente è guest
        do_not_dispatch = true;

        if(PreventivatoreHubComponent.emailValidationDelay !== null){
          clearTimeout(PreventivatoreHubComponent.emailValidationDelay);
          console.log("skip email check")
        }
        
        console.log(`PreventivatoreHub->componentDidUpdate check ${email_to_check}`);
        PreventivatoreHubComponent.emailValidationDelay = setTimeout(
          async() => {
            console.log("fire email check")
            this.props.userInfoDataSuccess({emailChecking: true});
            await User.emailChecker(email_to_check)
              .then((emailValidation: IEmailValidation) => {
                let dataToDispatch: UserInfo = {
                  emailChecking: false,
                  emailRegistered: emailValidation?.emailRegistered,
                  step1EmailValid: emailValidation.emailValid && !(emailValidation?.emailRegistered ?? false)
                };
                
                if(!dataToDispatch.step1EmailValid){
                  dataToDispatch.email = email_to_check;
                  dataToDispatch.openLogin = emailValidation?.emailRegistered ? true : false;
                }
    
                if(prevState.last_mail_checked !== email_to_check){
                  // dataToDispatch.skipEmailCheck = false;
                }
                
                console.log("PreventivatoreHub set email checking end")
                this.props.userInfoDataSuccess(dataToDispatch)
              })
          }
          , 500
        )
        
      }
    }

    if(prevState.last_mail_checked !== email_to_check){
      shouldBeUpdate = true;
      nextState.last_mail_checked = email_to_check;
    }

    if(JSON.stringify(dataToDispatch) !== "{}"){
      if(dataToDispatch?.step1EmailValid !== undefined && !dataToDispatch.step1EmailValid){
        dataToDispatch.openLogin = true;
      }
    }
    else{
      do_not_dispatch = true;
    }

    if(!do_not_dispatch){
      console.log("PreventivatoreHub derivedState dispatch")
      this.props.userInfoDataSuccess(dataToDispatch)
    }

    if(shouldBeUpdate){
      this.setState(nextState);
    }
  }

  componentWillUnmount(): void {}

  render(){

    return <>
      <PerventivatoreStepNavigator />

      <div className="pr-body">
        <div className="container-fluid">
          {/* <div className="main-content"> */}
          <div className="row justify-content-center">
            <div className='col-xxl-5 col-xl-6 col-lg-8 col-12'>
            <Switch>
              <Route exact path="/preventivi">
                <Redirect to={`/preventivi/assicurazione-${this.props.vehicle || this.default_vehicle}`} />
              </Route>

              <Route exact={true} path={`${this.props.path}/assicurazione-:vehicle`}>
                <Step1 setResourceListRequest={this.props.setResourceListRequest}/>
              </Route>
              <Route path={`${this.props.path}/assicurazione-:vehicle/${Constants.STEP2_URL_POSTFIX}`}>
                <Step2 setResourceListRequest={this.props.setResourceListRequest}/>
              </Route>
              <Route path={`${this.props.path}/assicurazione-:vehicle/${Constants.STEP3_URL_POSTFIX}`}>
                <Step3 setResourceListRequest={this.props.setResourceListRequest}/>
              </Route>
              <Route path={`${this.props.path}/assicurazione-:vehicle/${Constants.STEP4_URL_POSTFIX}`}>
                <Step4Loader />
              </Route>
              <Route path={`${this.props.path}/assicurazione-:vehicle/${Constants.STEP4_URL_POSTFIX};jsessionid=:id`}>
                <Step4Loader />
              </Route>
              {/*salvatataggio in corso*/ }
              <Route path={`${this.props.path}/assicurazione-:vehicle/${Constants.STEP5_URL_POSTFIX}`}>
                <Step5 />
              </Route>
              {/*errore salvatataggio*/ }
              <Route path={`${this.props.path}/assicurazione-:vehicle/${Constants.STEP5_ERROR_URL_POSTFIX}`}>
                <Step5Error />
              </Route>

              <Route path={`${this.props.path}/assicurazione-:vehicle/${Constants.NOTE_INFORMATIVE}`}>
                <NoteInformative />
              </Route>
              
            </Switch>
            </div>
          </div>

          {/* </div> */}
          <div className="row justify-content-center">
            <div className='col-md-11 col-12'>
              <Route path={`${this.props.path}/assicurazione-:vehicle/${Constants.STEP4_URL_POSTFIX}`}>
                <Step4/>
              </Route>
              <Route path={`${this.props.path}/assicurazione-:vehicle/${Constants.STEP4_URL_POSTFIX};jsessionid=:id`}>
                <Step4/>
              </Route>
            </div>
          </div>
        </div>
      </div>
    </>
  }

}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
  }
};

const mapState = (state: any) => {
  return {
    step1Data: state.step1Data,
    userInfoData: state.userInfoData.user,
    userData: state.userInfoData.user?.userData ?? "",
    // skipEmailCheck: state.userInfoData.user?.skipEmailCheck ?? false,
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

const PreventivatoreHub = (props: any) => {
  
  const {/* params, isExact,*/ path, url } = useRouteMatch();
  const [resourceListRequest, setResourceListRequest] : [resourceListRequest: IResourceToLoad, setResourceListRequest: Function] = useState({});

  return <ResourceListRequestContext.Provider value={resourceListRequest}>
    <FetchLists>
      <PreventivatoreHubComponent {...props} path={path} url={url} setResourceListRequest={setResourceListRequest}/>
    </FetchLists>
  </ResourceListRequestContext.Provider>
}

export default connect(mapState, mapDispatch)(PreventivatoreHub);
