import { Redirect, useLocation } from "react-router-dom";

const AreaPersonaleRedirectOldMailPreventivo = () => {
  
  const location: ReturnType<typeof useLocation> = useLocation();

  const getVehicleId = () => location?.search.substring(1).split("&")
    .map((pair: string) => {
      const tmp = pair.split("=");
      return {key: tmp[0], value: tmp[1]}
    })
    .find((json: {key: string, value: string}) => json.key === "idVeicolo")?.value ?? "";

  return <Redirect to={`${location.pathname}${getVehicleId()}`} />;
}

export default AreaPersonaleRedirectOldMailPreventivo;