import { MenuItem } from "primereact/menuitem";
import { Steps } from "primereact/steps";
import { Component } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { userInfoDataSuccess } from "../../redux/actions";
import * as Constants from '../utils/constants';

interface IStepNavigatorProps{
  vehicle: string;
  currentStep: number;
  setp1QueryString: string;
  setStep: Function;
  history: ReturnType<typeof useHistory>
}

interface IStepNavigatorState{
  steps: MenuItem[];
}

class PerventivatoreStepNavigatorComponent extends Component<IStepNavigatorProps, IStepNavigatorState>{

  constructor(props: any){
    super(props)

    this.state = {
      steps: PerventivatoreStepNavigatorComponent.buildSteps(this.props)
    }
  
  }

  static getDerivedStateFromProps(newProps: IStepNavigatorProps, prevState: IStepNavigatorState): IStepNavigatorState{
    return {steps: PerventivatoreStepNavigatorComponent.buildSteps(newProps)};
  }

  private static buildSteps(props: IStepNavigatorProps) : MenuItem[]{
    const refine_step = props.currentStep - 1; // normalizzo secondo la logina di controllo precedentemente implementata

    const step1Url = Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, props.vehicle) + ((props.setp1QueryString !== undefined) ? props.setp1QueryString : '');

    let steps: MenuItem[] = [
      {
        label: 'Contraente',
        command: (event: any) => {
          if (refine_step > 0){
            props.setStep(0);
            props.history.push(step1Url)
          }
        },
        className: refine_step < 0 || refine_step >= 1 ? 'step-complete' : ''
      },
      {
        label: 'Veicolo',
        command: (event: any) => {
          if (refine_step < 0 || refine_step >= 2){
            props.setStep(1);
            props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(2, props.vehicle))
          }
        },
        className: refine_step < 0 || refine_step >= 2 ? 'step-complete' : '',
      },
      {
        label: 'Assicurativi',
        command: (event: any) => {
          if (refine_step < 0 || refine_step >= 3){
            props.setStep(2);
            props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(3, props.vehicle))
          }
        },
        className: refine_step < 0 || refine_step >= 3 ? 'step-complete' : ''
      },
      {
        label: 'Preventivi',
        command: (event: any) => {
          if (refine_step >= 4){
            props.setStep(3);
            props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(4, props.vehicle))
          }
        },
        className: refine_step >= 4 ? 'step-complete' : ''
      }
    ]

    return steps;
  }


  render(){

    return <div id="preventivatoreStepNavigator" className="fixed-top desktop-fixed container-fluid">
      <div className="row justify-content-center">
        <div className='col-lg-11 col-12'>
          <div id="step-row" className="row justify-contente-between align-items-center py-2">
            <div className='caption-container header-label'>
              <span className="text-brand-first">Preventivo Assicurazione</span><span className="text-brand-second">{this.props.vehicle}</span>
            </div>
            <div className='header-steps'>
              <div className="steps-header">
                <Steps className="pr-steps" model={this.state.steps} activeIndex={this.props.currentStep - 1} readOnly={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
  
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    setStep: (step: number) => dispatch(userInfoDataSuccess({currentStep: step}))
  }
};

const mapState = (state: any) => {
  const veicle = state.userInfoData.user.currentVehicleSelector;
  const step1Data = state.step1Data[veicle];

  let queryString = undefined;

  if (
    step1Data.msclkid !== undefined || step1Data.gclid !== undefined || step1Data.fbclid !== undefined || 
    step1Data.s_id !== undefined || step1Data.ttclid !== undefined || step1Data.utm_id !== undefined || 
    step1Data.utm_campaign !== undefined || step1Data.utm_content !== undefined || step1Data.utm_source !== undefined ||
    step1Data.utm_medium !== undefined || step1Data.utm_name !== undefined || step1Data.utm_term !== undefined
  ) {
    queryString =
      '?' +
        ((step1Data.msclkid !== undefined) ? 'msclkid=' + step1Data.msclkid + '&' : '') +
        ((step1Data.gclid !== undefined) ? 'gclid=' + step1Data.gclid + '&' : '') +
        ((step1Data.fbclid !== undefined) ? 'fbclid=' + step1Data.fbclid + '&' : '') +
        ((step1Data.s_id !== undefined) ? 's_id=' + step1Data.s_id + '&' : '') +
        ((step1Data.ttclid !== undefined) ? 'ttclid=' + step1Data.ttclid + '&' : '') +
        ((step1Data.utm_id !== undefined) ? 'utm_id=' + step1Data.utm_id + '&' : '') +
        ((step1Data.utm_campaign !== undefined) ? 'utm_campaign=' + step1Data.utm_campaign + '&' : '') +
        ((step1Data.utm_content !== undefined) ? 'utm_content=' + step1Data.utm_content + '&' : '') +
        ((step1Data.utm_source !== undefined) ? 'utm_source=' + step1Data.utm_source + '&' : '') +
        ((step1Data.utm_medium !== undefined) ? 'utm_medium=' + step1Data.utm_medium + '&' : '') +
        ((step1Data.utm_name !== undefined) ? 'utm_name=' + step1Data.utm_name + '&' : '') +
        ((step1Data.utm_term !== undefined) ? 'utm_term=' + step1Data.utm_term + '&' : '');

    queryString = queryString.slice(0, -1);
  }

  return {
    currentStep: state.userInfoData.user.currentStep,
    vehicle: veicle,
    setp1QueryString: queryString
  };
}

const PerventivatoreStepNavigator = (props: any) => {
  const history: ReturnType<typeof useHistory> = useHistory();

  return <PerventivatoreStepNavigatorComponent {...props} history={history}/>
}

export default connect(mapState, mapDispatch)(PerventivatoreStepNavigator);