
import { useHistory, useParams } from "react-router-dom";
import { Component } from "react";
import Step2Guest from "./Step2Guest";
import Step2Logged from "./Step2Logged";
import User from "./utils/User";
import { connect } from "react-redux";
import { userInfoDataSuccess } from "../redux/actions";
import { getRedirectToStep } from "./utils/methods";
import * as Constants from "./utils/constants";

interface IStep2Props{
  userData: string,
  vehicle: string;
  setStep: Function;
  history: ReturnType<typeof useHistory>;
  setResourceListRequest: Function;
}

interface IStep2State{
  user: User;
}

class Step2Component extends Component<IStep2Props, IStep2State>{
  private abortController: AbortController = new AbortController()

  constructor(props: any){
    super(props);

    let lastCompletedStep = getRedirectToStep(this.props.vehicle);
    if (Constants.ENABLE_REDIRECT && lastCompletedStep < 2) {
      this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(lastCompletedStep, this.props.vehicle));
    }

    this.state = {
      user: User.getInstance()
    }
  }

  componentDidMount(): void {
    this.props.setStep(2);
  }

  componentWillUnmount(): void {
    this.abortController.abort();
    this.setState = (state, callback) => {return};
  }

  static getDerivedStateFromProps(newProps: IStep2Props, prevState: IStep2State): IStep2State{
    let nextState: IStep2State = prevState;
    if(newProps.userData !== JSON.stringify(prevState.user)){
      nextState.user = User.getInstance()
    }

    return nextState;
  }

  
  render(){
    return this.state.user.id !== -1 //User.isAlive()
      ? <Step2Logged vehicle={this.props.vehicle} user={this.state.user} setResourceListRequest={this.props.setResourceListRequest}/>
      : <Step2Guest vehicle={this.props.vehicle} setResourceListRequest={this.props.setResourceListRequest}/>
  }

}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    setStep: (step: number) => dispatch(userInfoDataSuccess({currentStep: step}))
  }
};

const mapState = (state: any) => {
  return {
    userData: state.userInfoData.user?.userData ?? "",
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

const Step2 = (props: any) => {

  const params = useParams();
  const history: ReturnType<typeof useHistory> = useHistory();

  return <Step2Component {...props} {...params} history={history}/>
}

export default connect(mapState, mapDispatch)(Step2);
// export default App;