import { InputText } from "primereact/inputtext";


export enum PasswordStrength{
  WEAK = 1,
  MEDIUM = 2,
  STRONG = 3,
}

const PasswordInput = (props: {
  passwordLiveChecking: undefined | boolean;
  passwordStrength: PasswordStrength | undefined;
  id?: string;
  name?: string;
  placeholder?: string
  onChange?: Function;
}) => {

  const {passwordLiveChecking, passwordStrength, onChange, ...inputProps} = props;

  return <>
    <InputText
      autoComplete="password"
      type="password"
      onChange={e => onChange?.(e)}
      {...inputProps}
    />
    <div className={`mt-2 strengthMeter ${passwordLiveChecking === undefined || passwordLiveChecking ? "" : "fail"}`}>

      <div className={`level ${passwordStrength === PasswordStrength.WEAK || passwordStrength === PasswordStrength.MEDIUM || passwordStrength === PasswordStrength.STRONG
        ? "passed"
        : ""}`}></div>
      <div className={`level ${passwordStrength === PasswordStrength.MEDIUM || passwordStrength === PasswordStrength.STRONG
        ? "passed"
        : ""}`}></div>
      <div className={`level ${passwordStrength === PasswordStrength.STRONG
        ? "passed"
        : ""}`}></div>
    </div>
  </>
}

export default PasswordInput;