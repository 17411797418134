
import { useParams } from "react-router-dom";
import { Component } from "react";
import Step1Guest from "./Step1Guest";
import Step1Logged from "./Step1Logged";
import User from "./utils/User";
import { connect } from "react-redux";
import { userInfoDataSuccess } from "../redux/actions";

interface IStep1Props{
  userData: string,
  vehicle?: string;
  setStep: Function;
  setResourceListRequest: Function;
}

interface IStep1State{
  user: User;
}

class Step1Component extends Component<IStep1Props, IStep1State>{

  constructor(props: any){
    super(props);

    this.state = {
      user: User.getInstance()
    }
  }

  componentDidMount(): void {
    this.props.setStep(1);
  }

  static getDerivedStateFromProps(newProps: IStep1Props, prevState: IStep1State): IStep1State{
    let nextState: IStep1State = prevState;
    if(newProps.userData !== undefined && newProps.userData !== JSON.stringify(prevState.user)){
      nextState.user = User.getInstance()
    }

    return nextState;
  }

  render(){
    return this.state.user.id !== -1 //User.isAlive()
      ? <Step1Logged vehicle={this.props.vehicle} user={this.state.user} setResourceListRequest={this.props.setResourceListRequest}/>
      : <Step1Guest vehicle={this.props.vehicle} setResourceListRequest={this.props.setResourceListRequest}/>
  }

}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    setStep: (step: number) => dispatch(userInfoDataSuccess({currentStep: step}))
  }
};

const mapState = (state: any) => {
  return {
    currentStep: state.userInfoData.user?.currentStep,
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

const Step1 = (props: any) => {

  const params = useParams();

  return <Step1Component {...props} {...params}/>
}

export default connect(mapState, mapDispatch)(Step1);