import { useContext } from "react";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import PagesContext from "./customs/HOC/useAreaPersonalePages";
import ProductList from "./ProductList";
import { useHistory, useRouteMatch } from "react-router-dom";
import '../scss/quotation-row.scss';
import User from "./utils/User";

export const AreaPersonaleProdottiPerson = (props: IPageProps) => {
  const pages = useContext(PagesContext);
  const {params} = useRouteMatch();
  const companyId: string = (params as {companyId?: string})?.companyId ?? "";
  return <ProductList contractorId={""} contractorType="person" pages={pages} {...props} history={useHistory()} />;
};

export const AreaPersonaleProdottiBusiness = (props: IPageProps) => {
  const pages = useContext(PagesContext);
  const {params} = useRouteMatch();
  const companyId: string = (params as {companyId?: string})?.companyId ?? "";
  return <ProductList contractorId={companyId} contractorType="business" pages={pages} {...props} history={useHistory()} />;
};

