import { Component } from "react";

interface EmptyLabelProps {
  label: string
}

class EmptyListItemLabelComponent extends Component<{data: EmptyLabelProps}, {}> {

  render() {
    return (
      <span className="personFieldTitle">
        {this.props.data.label}
      </span>
    )
  }

}

const EmptyListItemLabel = (props: any) => {
  return <EmptyListItemLabelComponent {...props} />;
};

export default EmptyListItemLabel;
