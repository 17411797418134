import { Button } from 'primereact/button';

const QuotationColumnActions = (props: any) => {

  const {company, onClick, disabled} = props;
  
  return <div className="action-container">
    <div className="go_button">
      <Button
        className="customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
        type="button"
        label="Scegli"
        onClick={() => onClick(company, true)}
        disabled={disabled}
      />
    </div>
  </div>

}

export default QuotationColumnActions;