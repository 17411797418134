import { Component } from "react";
import { useHistory, useParams } from "react-router-dom";
import User from "./utils/User";
import { connect } from "react-redux";
import Step3Guest from "./Step3Guest";
import Step3Logged from "./Step3Logged";
import { userInfoDataSuccess } from "../redux/actions";
import { getRedirectToStep } from "./utils/methods";
import * as Constants from "./utils/constants";

interface IStep3Props{
  userData: string,
  vehicle: string;
  setStep: Function;
  history: ReturnType<typeof useHistory>;
  setResourceListRequest: Function;
}

interface IStep3State{
  user: User;
}
class Step3Component extends Component<IStep3Props, IStep3State>{

  constructor(props: any){
    super(props);

    this.state = {
      user: User.getInstance()
    }

    let lastCompletedStep = getRedirectToStep(this.props.vehicle);
    if (Constants.ENABLE_REDIRECT && lastCompletedStep < 3) {
      this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(lastCompletedStep, this.props.vehicle));
    }
  }

  componentDidMount(): void {
    this.props.setStep(3);
  }

  static getDerivedStateFromProps(newProps: IStep3Props, prevState: IStep3State): IStep3State{
    let nextState: IStep3State = prevState;
    if(newProps.userData !== JSON.stringify(prevState.user)){
      nextState.user = User.getInstance()
    }

    return nextState;
  }
  
  render(){
    return this.state.user.id !== -1 //User.isAlive()
      ? <Step3Logged vehicle={this.props.vehicle} user={this.state.user} setResourceListRequest={this.props.setResourceListRequest}/>
      : <Step3Guest vehicle={this.props.vehicle} setResourceListRequest={this.props.setResourceListRequest}/>
  }

}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    setStep: (step: number) => dispatch(userInfoDataSuccess({currentStep: step}))
  }
};

const mapState = (state: any) => {
  return {
    userData: state.userInfoData.user?.userData ?? "",
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

const Step3 = (props: any) => {

  const params: ReturnType<typeof useParams> = useParams();
  const history: ReturnType<typeof useHistory> = useHistory();

  return <Step3Component {...props} {...params} history={history}/>
}

export default connect(mapState, mapDispatch)(Step3);
// export default App;