import { Component } from "react";
import * as Constants from './utils/constants';

interface ILazyPage {
  title?: string;
  subtitle?: string;
  paragraph?: string;
  img?: string;
  alt?: string;
  id?: string
}

export default class StepLoaderSpinner extends Component <ILazyPage, {}>{

  
  private id?: string;
  private title?: string;
  private subtitle?: string;
  private paragraph?: string;
  private img?: string;
  private alt?: string;

  constructor(props: any){
    super(props);

    this.id = props?.id;
    this.title = props?.title;
    this.subtitle = props?.subtitle;
    this.paragraph = props?.paragraph;
    this.img = props?.img || `${Constants.SEISICURO_STATIC_CONTENT_URL}/img/6sicuro-logo-animato-grigio.gif`;
    this.alt = props?.alt || "attendi il caricamento dei risultati - 6sicuro";
  }

  render(){
    return <div {...this.id ? {id: this.id}: {}} className="progressbar-loader-a">
      {(this?.title || this?.subtitle || this?.paragraph)  &&
        <div className="mb-5">
          {this?.title &&
            <span className={`titleLev2 colorPrimaryBlue ${this?.subtitle ? "mb-1" : this?.paragraph ? "mb-3" : ""}`}>{this.title}</span>
          }
          {this?.subtitle &&
            <span className={`titleLev4 colorPrimaryBlue ${this?.paragraph ? "mb-3" : ""} `}>{this.subtitle}</span>
          }
          {this?.paragraph &&
            <p>{this.subtitle}</p>
          }
        </div>
      }
      <img src={this.img} alt={this.img} id="attesa_caricamento" className="img-fluid" />
  </div>;
  }
}