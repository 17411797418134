import { connect } from "react-redux";
import EventManager from "./EventManager";

const mapState = (state: any) => {
  return {
    step1Data: state.step1Data,
    step4Data: state.step4Data,
    vehicle: state.userInfoData.user.currentVehicleSelector
  };
}

const withEventManager = (WrappedComponent: any) => {

  const withEventManagerHOC = (props: any) => {
    return <EventManager {...props} component={WrappedComponent}></EventManager>
  }
  
  return connect(mapState)(withEventManagerHOC);
}

export default withEventManager;