import { Component, useContext } from "react";
import { fetchList, sortJson } from "../utils/methods";
import * as Constants from "../utils/constants";
import { Accordion } from "react-bootstrap";
import { AjaxEnrichedAutocompleteObject, IPerson, IPerson_WithErrors } from "../utils/common";
import { Tooltip } from "primereact/tooltip";
import { FeedbackBox } from "../utils/Feedback";
import { Step3OwnerDriverFieldsBuilder } from "../utils/PersonFieldsBuilder";
import { DataList, IDataList } from "./dataListContext";

class OwnerDriverListItemLabelComponent extends Component<{
  data: IPerson_WithErrors,
  parentId: string,
  selected: boolean,
  action?: any
  professionList: AjaxEnrichedAutocompleteObject[];
  civilStateList: AjaxEnrichedAutocompleteObject[];
  degreeList: AjaxEnrichedAutocompleteObject[]
},
{
  // loading: boolean;
}> {
  private readonly fields: any;
  
  constructor(props: any) {
    super(props);

    this.state = {
      // loading: true,
      // professionList: [],
      // civilStateList: [],
      // degreeList: [],
    };

    if(this.props.action?.attributes?.onClick){
      this.props.action.attributes.onClick = this.props.action.attributes.onClick.bind(this);
    }
    
    this.fields = Step3OwnerDriverFieldsBuilder();

  }

  componentDidMount() {
    // fetchList(Constants.SEISICURO_AJAX_PROFESSION_ENDPOINT, (result: any) => {
    //   result.sort(sortJson);
    //   this.setState({ professionList: result }, () =>
    //     this.updateLoadingState()
    //   );
    // });
    // fetchList(Constants.SEISICURO_AJAX_CIVIL_STATE_ENDPOINT, (result: any) => {
    //   result.sort(sortJson);
    //   this.setState({ civilStateList: result }, () =>
    //     this.updateLoadingState()
    //   );
    // });
    // fetchList(Constants.SEISICURO_AJAX_DEGREE_ENDPOINT, (result: any) => {
    //   result.sort(sortJson);
    //   this.setState({ degreeList: result }, () => this.updateLoadingState());
    // });
  }

  // updateLoadingState() {
  //   let prevState: boolean = this.state.loading;
  //   let newState: boolean = !(
  //     this.state.civilStateList.length > 0 &&
  //     this.state.professionList.length > 0 &&
  //     this.state.degreeList.length > 0
  //   );
  //   if (prevState !== newState) {
  //     this.setState({ loading: newState });
  //   }
  // }

  private getAge(dateOfBirth: Date){
    var today : Date = new Date();
    var age = today.getFullYear() - dateOfBirth.getFullYear();
    var m = today.getMonth() - dateOfBirth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
      age--;
    }
    return age;
  }

  render() {

    const person : any = this.props.data;
    const errors : string[] = this.props.data?.errors || [];
    
    const civilState: string = this.props.civilStateList.find((item: AjaxEnrichedAutocompleteObject) => item.value === person.civilState, person)?.label ?? "stato civile non dichiarato"
    const profession: string = this.props.professionList.find((item: AjaxEnrichedAutocompleteObject) => item.value === person.profession, person)?.label ?? "non dichiarata";


    return (
      // !this.state.loading &&
        <>
          <span className="personFieldTitle">
            { person.name || person.surname
              ? <>{person.name} {person.surname}</>
              : "Senza nome"
            }
            {errors && errors.length > 0 && 
              <>
                <Tooltip className="yellow-tooltip" target={`#${this.props.parentId}-warning-sign`}>Informazioni incomplete</Tooltip>
                <i id={`${this.props.parentId}-warning-sign`} className="pi pi-exclamation-triangle ms-2" />
              </>
            }
          </span>

          <Accordion.Collapse eventKey={`${this.props.parentId}`}>
            <>
              <p>
                  {`${person.gender === "donna"
                    ? "Donna"
                    : person.gender === "uomo"
                      ? "Uomo"
                      : "Sesso non dichiarato"
                  }`},&nbsp;
                  {(person?.dateOfBirth ?? undefined) !== undefined
                    ? this.getAge(person.dateOfBirth as Date)
                    : "età non dichiarata"
                  },&nbsp;
                  {civilState}<br/>
                  Professione: {profession}<br/>
                  {person?.bornInItaly !== undefined
                    ? person.bornInItaly
                      ? <span>
                          {`Paese di nascita: ${person.cityOfBirth?.value || "non dichiarato"}`}
                        </span>
                      : <span>
                          {`Stato di nascita: ${person.countryOfBirth?.value || "non dichiarato"}`}
                        </span>
                    :
                    "Paese di nasciata non dichiarato"
                  }
              </p>

              {errors && errors.length > 0 &&              
                <FeedbackBox
                  items={[{field:"generic", msg: `Attenzione informazioni mancanti: ${errors
                    .map((field: string) => this.fields[field]?.notification_label ?? this.fields[field].label_human_readable ?? this.fields[field])
                    .join(", ")}`, severity:"warn"}]}
                  filter={{field:"generic", severity:"warn"}}  
                />
              }

              {this.props?.action &&
                <span {...this.props.action?.attributes}>{this.props.action.label}</span>
              }
            </>
          </Accordion.Collapse>
        </>
    )
  }

}

const OwnerDriverListItemLabel = (props: any) => {
  const lists: IDataList = useContext(DataList);
  return <OwnerDriverListItemLabelComponent {...props} {...lists}/>;
};

export default OwnerDriverListItemLabel;
