import { InputMask } from "primereact/inputmask";
import { Component, createRef } from "react";
import { formataDateToString, isLikelyDate } from "../utils/methods";

interface IInputDateProps{
  date: Date | string | undefined;
  disabled?: boolean;
  onChange?: (selected: any) => void;
  onBlur?: (selected: any) => void;
  onFocus?: (selected: any) => void;
  id: string;
  className?: string;
}

interface IInputDateState{
  date: Date | string | undefined;
  stringDate: string | undefined;
}

export default class InputDate extends Component<IInputDateProps,IInputDateState>{

  private inputMaskRef : any;

  constructor(props: any){
    super(props);

    let date: Date | undefined = undefined;
    let stringDate: string | undefined = undefined;

    if((typeof this.props.date === 'string' || this.props.date instanceof String) && isLikelyDate(this.props.date)){
      stringDate = this.props.date as string;
      date = this.getDateFromString(this.props.date as string);
    }
    else if(this.props.date instanceof Date && !isNaN(this.props.date.getTime())){
      date = this.props.date;
      stringDate = formataDateToString(this.props.date) ?? "";
    }

    this.state = {
      date: date,
      stringDate: stringDate,
    }

    this.inputMaskRef = createRef();

  }

  componentDidUpdate(prevProps: Readonly<IInputDateProps>, prevState: Readonly<IInputDateState>, snapshot?: any): void {
    
    if(this.props?.date !== prevProps?.date){
      let stringDate: string = "";
      if((typeof this.props.date === 'string' || this.props.date instanceof String) && isLikelyDate(this.props.date)){
        stringDate = this.props.date as string;
      }
      else if(this.props.date instanceof Date && !isNaN(this.props.date.getTime())){
        stringDate = formataDateToString(this.props.date) ?? "";
      }
      else if(this.props?.date === undefined && this.state.stringDate){
        this.setState({stringDate: undefined});
      }
  
      this.setState({stringDate: stringDate});
    }
  }

  private getDateFromString(dateString: string) : Date{
    let date : Date;
    const [day, month, year] : [day: number, month : number, year : number] = dateString.split("/").map((elem : string) => parseInt(elem)) as [number, number, number];
    date = new Date(`${year}-${month}-${day}T00:00:00Z`);
    return date;
  }

  getStringDate(date: Date | string | undefined): string | undefined{
    let stringDate: string | undefined = undefined;

    if((typeof date === 'string' || date instanceof String) && isLikelyDate(date)){
      stringDate = date as string;
      date = this.getDateFromString(date as string);
    }
    else if(date instanceof Date && !isNaN(date.getTime())){
      stringDate = formataDateToString(date) ?? "";
    }

    return stringDate;
  }

  render(){

    return <InputMask
      id={this.props.id}
      type="tel"
      className={this.props?.className || "p-component p-inputwrapper p-inputwrapper-filled"}
      mask="99/99/9999"
      slotChar="gg/mm/aaaa"
      placeholder="gg/mm/aaaa"
      value={this.state?.stringDate ?? ""}
      onChange={selected => this.props.onChange?.(selected.value) ?? this.onChangeHandler(selected.value)}
      onBlur={() => this.props?.onBlur?.(this.state.stringDate)}
      onFocus={(e) => {
        // il componente non permette di aggiungere l'attributo autocomplete.. modifico il dom e disabilito
        // in caso contrario con determinati browser, al click il cursore viene spostato a destra
        this.props?.onFocus?.(e);
        this.inputMaskRef.current.inputRef.current.ariaAutoComplete = "off";
        
        const autocompleteOff = document.createAttribute("autocomplete");
        autocompleteOff.value = "off";
        this.inputMaskRef.current.inputRef.current.attributes.setNamedItem(autocompleteOff);
      }}
      ref={this.inputMaskRef}
      disabled={this.props.disabled}
    />
  }

  // changeHandler di default, se non viene passato nessun handler dall'esterno
  private onChangeHandler(stringDate: string){
    this.setState({stringDate: stringDate});
  }

}