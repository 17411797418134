// import moment from "moment";
import "../../scss/_entity-card.scss";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
// import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Component, createRef, ForwardedRef, forwardRef, useContext} from "react";
import { AjaxAutocompleteObject, IFieldDescriptor, IPerson } from "../utils/common";
import * as Constants from "../utils/constants";
import { fetchAutocomplete /*, formataDateToString*/, getDrivingLicenseAgeOptions, isLikelyDate, removeFocusClass, replaceSpecialChars, sortJson } from "../utils/methods";
import User, { IValidationResponse } from "../utils/User";
import UserService, { DuplicateException, GenericException } from "../utils/UserService";
import InputDate from "./InputDate";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "../utils/Feedback";
import { Divider } from "primereact/divider";
import Address from "./Address";
import { DataList, IDataList } from "./dataListContext";
import moment from "moment";

interface IPersonCardProps extends IDataList{
  showControl?: boolean;  // false => interfaccia show non mostra il controller per entrare in modalità modifica, solo modalità 'dettaglio'
  editMode?: boolean;   // true => modalità modifica, false => modalità dettaglio
  visible: boolean;
  header?: JSX.Element;
  successCallback?: Function;
  errorCallback?: Function;
  person?: string | IPerson;
  user: User;
  hideFields?: string[]; // lista dei campi da nascondere
  requiredFields?: string[] | string | IFieldDescriptor[] | any; // lista dei campi da nascondere
  errors?: string[];
  errorTexts?: IFeedbackText[];  //{field, msg}
  ageValidation?: Function;
  ref: ForwardedRef<PersonCardComponent>;
  setResourceListRequest: Function;
}
interface IPersonCardState{
  showControl: boolean;  
  editMode: boolean;
  visible: boolean;
  header: JSX.Element;
  id?: string | number;
  person: IPerson | undefined;
  saving: boolean;
  feedbackDialog: boolean;
  feedbackStatus: string;
  feedbackBody: string;
  assetsLoading: boolean;
  autocompleteCountries: any[] | undefined;
  autocompleteCities: any[] | undefined;
  errors: string[];
  errorTexts: IFeedbackText[];  //{field, msg}
  hideFields?: string[]; // lista dei campi da nascondere
  requiredFields: string[] | string | undefined | any;
  successCallback?: Function;
  errorCallback?: Function;
  phoneChecking: boolean;
}

class PersonCardComponent extends Component<IPersonCardProps, IPersonCardState>{

  private defaultHeader: JSX.Element = <h3>Header</h3>;
  // private professionList: any[] = [];
  // private degreeList: any[] = [];
  // private civilStateList: any[] = [];
  private minorAgeList : number[];
  // private dateRef : any;

  private dialogRef: any;
  private previousPhone: string;

  private addressComponentsFields: string[] = ["address", "addressNumber", "cityOfResidence", "postalCodeOfResidence"];

  constructor(props: IPersonCardProps){
    super(props);

    let person: IPerson | undefined = undefined;
    this.previousPhone = "";
    if(this.props?.person){
      if(typeof(this.props?.person) === "string"){
        person = this.props.user.getPersonById(this.props.person)
      }
      else{
        person = this.props.person
      }
      this.previousPhone = person?.phone ?? "";
    }

    this.state = {
      showControl: this.props?.showControl !== undefined ? this.props.showControl : true,
      editMode: this.props?.editMode as boolean,
      visible: this.props?.visible ? this.props.visible : false,
      header: this.props?.header ? this.props.header : this.defaultHeader,
      person: person,
      saving: false,
      feedbackDialog: false,
      feedbackStatus: "",
      feedbackBody: "",
      assetsLoading: false,
      autocompleteCountries: undefined,
      autocompleteCities: undefined,
      errors: this.props?.errors ?? [],
      errorTexts: this.props?.errorTexts ?? [],
      hideFields: this.props.hideFields,
      requiredFields: this.props.requiredFields,
      successCallback: this.props.successCallback,
      errorCallback: this.props.errorCallback,
      phoneChecking: false
    }

    this.minorAgeList = Array.from({length: Constants.MINOR_MAX_AGE}, (_, i) => i + 1)

    this.save = this.save.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.phoneHandler = this.phoneHandler.bind(this);
    this.defaultSuccessCallback = this.defaultSuccessCallback.bind(this);

    this.onBlurHandlerDate = this.onBlurHandlerDate.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.validation = this.validation.bind(this);

    this.dialogRef = createRef();
  }

  componentDidMount() {

    let resourseRequestMask: any = {};
    if(!(this.props?.professionList?.length ?? false)){
      resourseRequestMask.professionList = true;
    }
    
    if(!(this.props?.civilStateList?.length ?? false)){
      resourseRequestMask.civilStateList = true;
    }
    
    if(!(this.props?.degreeList?.length ?? false)){
      resourseRequestMask.degreeList = true;
    }

    if(JSON.stringify(resourseRequestMask) != "{}"){
      this.props.setResourceListRequest(resourseRequestMask)
    }

  }

  private phoneHandler(candidatePhone: string, skipPrevValueControl: boolean = false){
    if(skipPrevValueControl || candidatePhone !== this.previousPhone){
      if(candidatePhone === ""){
        this.setState({
          phoneChecking: false,
          errors: [...this.state.errors.filter((err: string) => err !== "phone")],
          errorTexts: [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== "phone")]
        })
      }
      else{
        this.setState({phoneChecking: true}, () => {

          UserService.call(Constants.WEB_SITE_API_SERVER_URL("/utils/misc/validate"), {
            method: "post",
            body: JSON.stringify({phone: candidatePhone ?? ""})
          })
            .then(result => {
              result?.phone.valid
                ? this.setState({
                  phoneChecking: false,
                  errors: this.state.errors.filter(e => e !== "phone")
                })
                : this.setState({
                  phoneChecking: false,
                  errors: this.state.errors.includes("phone")
                    ? this.state.errors
                    : [...this.state.errors, "phone"]
                })
            })
            .catch((err: any) => {
              this.setState({
                phoneChecking: false,
                errors: this.state.errors.includes("phone")
                  ? this.state.errors
                  : [...this.state.errors, "phone"]
              })
            })
        })
      }
    }
  }

  defaultSuccessCallback(response: {person: IPerson, validation: IValidationResponse}, callback?: Function){
    this.setState({
      saving: false,
      person: {...this.state.person, id: response.person.id},
      feedbackDialog: true,
      feedbackStatus: "success",
      feedbackBody: "Salvataggio completato con successo"
    }, () => {
      this.dialogScrollToTop();
      callback?.(this.props.user.getPersonById(response.person.id), response.validation)
    });

  }

  defaultErrorCallback(error?: Error){
    this.setState({
      saving: false,
      feedbackDialog: true,
      feedbackStatus: "error",
      feedbackBody: error !== undefined && (error?.message ?? "") !== "" ? error.message :  "Non è stato possibile completare il salvataggio"
    }, () => {
      this.dialogScrollToTop()
      this.state.errorCallback?.(this.state.person);
    });

  }

  render(){

    const person = this.state.person;
    let disabled : boolean = !this.state.editMode;

    // let x = person?.cityOfBirth !== undefined && (person.cityOfBirth?.id ?? undefined) !== undefined && (person.cityOfBirth?.value ?? undefined) !== undefined
    //   ? person?.cityOfBirth
    //   : {id: "12", value:"ASD"};

    return <Dialog
        id="personCard"
        className="entityCard"
        modal={true}
        visible={this.state.visible}
        resizable={false}
        draggable={false}
        closable={!this.state.saving}
        closeOnEscape={false}
        onHide={this.hide}
        header={
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-7 col-12 realTitle">
                {this.state.header}
              </div>
              <div className="col-md-5 mt-md-0 col-12 mt-2 switchModifica">
                {
                  this.state.showControl &&                
                  <span className="editToggle">
                    <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/`+(this.state.editMode ? "lock-sbloccato" : "lock")+`.svg`} alt=""/>
                    <span className={this.state.editMode ? "colorPrimaryGray" : "colorPrimaryGreen"} onClick={() => {this.setState({editMode: !this.state.editMode})}}>{this.state.editMode ? "Blocca modifica" : "Abilita modifica"}</span>
                  </span>                
                }  
              </div>              
            </div>
          </div>
        }
        /*breakpoints={{'960px': '75vw'}}*/
        style={{ width: '600px' }}
        blockScroll={true}
      >
        <div className="container-fluid" ref={this.dialogRef}>
          
          
          <div className="row">
            
            {this.state.feedbackDialog
              ? <FeedbackBox items={[{field: "generic", msg: this.state.feedbackBody, severity: this.state.feedbackStatus}]} filter={{field: "generic",  severity: this.state.feedbackStatus}}/>
              : <FeedbackBox items={this.state.errorTexts}/>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("name") && this.state.hideFields.includes("surname")) &&
              <>
                { !(this.state?.hideFields && this.state.hideFields.includes("name")) &&
                  <div className="col-xl-6 col-12 mb-xl-0 mb-4">
                    <label htmlFor="contraenete-name">{this.state.requiredFields?.name?.label_human_readable ?? "Nome"}</label>
                    <InputText
                      onChange={(selected) => this.onChangeHandler( selected.target.value, "name" ) }
                      // onBlur={() => this.validation("name")}
                      onFocus={(e) => this.resetValidation("name")}
                      placeholder="Nome"
                      name="name"
                      id="contraenete-name"
                      autoComplete="given-name"
                      disabled={disabled}
                      value={person?.name ?? ""}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("name")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "name") ??
                        {msg: this.state.requiredFields?.name?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.name?.validation?.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
                { !(this.state?.hideFields && this.state.hideFields.includes("surname")) &&
                  <div className="col-xl-6 col-12 mb-4">
                    <label htmlFor="contraenete-surname">Cognome</label>
                    <InputText
                      onChange={(selected) => this.onChangeHandler( selected.target.value, "surname" ) }
                      // onBlur={() => this.validation("surname")}
                      onFocus={(e) => this.resetValidation("surname")}
                      placeholder="Cognome"
                      name="surname"
                      id="contraenete-surname"
                      autoComplete="family-name"
                      disabled={disabled}
                      value={person?.surname ?? ""}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("surname")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "surname") ??
                        {msg: this.state.requiredFields?.surname?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.surname?.validation?.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
              </>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("gender")) &&
              <div className="col-12 mb-4">
                <label>Sesso</label>
                <SelectButton
                  value={person?.gender}
                  options={[
                    { label: "Uomo", value: "uomo" },
                    { label: "Donna", value: "donna" },
                  ]}
                  onChange={(selected) => this.onChangeHandler(selected.value, "gender") }
                  className="regularBtn"
                  unselectable={false}
                  disabled={disabled}
                />
                <ContextFeedback
                  show={this.state.errors.includes("gender")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "gender") ??
                    {msg: this.state.requiredFields?.gender?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.gender?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("dateOfBirth")) &&
              <div className="col-12 mb-4">
                <label htmlFor="contraenete-date-of-birth">Data di nascita</label>
                <InputDate
                  id="contraenete-date-of-birth"
                  date={person?.dateOfBirth}
                  disabled={disabled}
                  // onChange={stringDate => this.onChangeHandlerDate(stringDate)}
                  onBlur={stringDate => {
                    this.onBlurHandlerDate(stringDate)
                  }}
                />
                <ContextFeedback
                  show={this.state.errors.includes("dateOfBirth")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "dateOfBirth") ??
                    {msg: this.state.requiredFields?.dateOfBirth?.validation.fail_msg ?? "Indica una data di nascita valida. Devi essere maggiorenne", severity: this.state.requiredFields?.dateOfBirth?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("civilState")) &&
              <div className="col-12 mb-4">
                <label>Stato civile</label>
                <Dropdown
                  value={person?.civilState}
                  options={this.props.civilStateList}
                  onChange={(selected) => this.onChangeHandler(selected.value, "civilState")}
                  onHide={() => removeFocusClass()}
                  placeholder="Seleziona"
                  optionLabel="label"
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                  disabled={disabled}
                />
                <ContextFeedback
                  show={this.state.errors.includes("civilState")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "civilState") ??
                    {msg: this.state.requiredFields?.civilState?.validation.fail_msg ?? "Inserisci lo stato civile", severity: this.state.requiredFields?.civilState?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("bornInItaly")) &&
              <div className="col-12 field-button mb-4">
                <label>Paese di nascita</label>
                <SelectButton
                  value={person?.bornInItaly}
                  options={[
                    { label: "Italia", value: true },
                    { label: "Altro", value: false },
                  ]}
                  onChange={(selected) => this.onChangeHandler(selected.value, "bornInItaly") }
                  className="regularBtn"
                  unselectable={false}
                  disabled={disabled}
                />
                <ContextFeedback
                  show={this.state.errors.includes("bornInItaly")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "bornInItaly") ??
                    {msg: this.state.requiredFields?.bornInItaly?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.bornInItaly?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("bornInItaly")) &&
              person?.bornInItaly === false &&
              <div className="col-12 mb-4">
                <label htmlFor="country-of-birth">Stato di nascita</label>
                <AutoComplete
                  inputId="country-of-birth"
                  // value={person?.countryOfBirth}
                  value={person?.countryOfBirth !== undefined
                    ? person.countryOfBirth?.hasOwnProperty("id") && person.countryOfBirth?.hasOwnProperty("value")
                      ?  (person.countryOfBirth.id ?? undefined) === undefined || (person.countryOfBirth.value ?? undefined) === undefined  //tipo AjaxAutocompleteObject
                        ? undefined
                        : person.countryOfBirth
                      : person.countryOfBirth  // tipo stringa
                    : undefined}
                  suggestions={this.state.autocompleteCountries}
                  completeMethod={(e) =>
                    fetchAutocomplete(
                      e,
                      Constants.SEISICURO_AJAX_COUNTRY_OF_BIRTH_ENDPOINT,
                      (result: any) => {
                        this.setState({ autocompleteCountries: result });
                      },
                      ""
                    )
                  }
                  field="value"
                  placeholder="Stato di nascita"
                  onChange={(selected) =>
                    this.onChangeHandler(selected.value, "countryOfBirth")
                  }
                  onBlur={() => this.validation("countryOfBirth")}
                  disabled={disabled}
                />
                <ContextFeedback
                  show={this.state.errors.includes("countryOfBirth")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "countryOfBirth") ??
                    {msg: this.state.requiredFields?.countryOfBirth?.validation.fail_msg ?? "Inserisci lo stato di nascita", severity: this.state.requiredFields?.countryOfBirth?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("cityOfBirth")) &&
              person?.bornInItaly &&
              <div className="col-12 mb-4">
                <label>Comune di nascita</label>
                <AutoComplete
                  value={person?.cityOfBirth !== undefined
                    ? person.cityOfBirth?.hasOwnProperty("id") && person.cityOfBirth?.hasOwnProperty("value")
                      ?  (person.cityOfBirth.id ?? undefined) === undefined || (person.cityOfBirth.value ?? undefined) === undefined  //tipo AjaxAutocompleteObject
                        ? undefined
                        : person.cityOfBirth
                      : person.cityOfBirth  // tipo stringa
                    : undefined
                  }
                  suggestions={this.state.autocompleteCities}
                  completeMethod={(e) =>
                    fetchAutocomplete(
                      e,
                      Constants.SEISICURO_AJAX_CITY_OF_BIRTH_ENDPOINT,
                      (result: any) => {
                        this.setState({ autocompleteCities: result });
                      },
                      "NASCITA"
                    )
                  }
                  field="value"
                  placeholder="Comune di nascita"
                  onChange={(selected) => this.onChangeHandler(selected.value, "cityOfBirth") }
                  onBlur={() => this.validation("cityOfBirth")}
                  disabled={disabled}
                />
                <ContextFeedback
                  show={this.state.errors.includes("cityOfBirth")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "cityOfBirth") ??
                    {msg: this.state.requiredFields?.cityOfBirth?.validation.fail_msg ?? "Inserisci il comune di nascita", severity: this.state.requiredFields?.cityOfBirth?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("children")) &&
              <>
                <div className="col-12 mb-4">
                  <label>Figli</label>
                  <SelectButton
                    value={person?.children ?? []}
                    options={[
                      { label: "Senza figli", value: 0 },
                      { label: "Figli minorenni", value: 1 },
                      { label: "Figli maggiorenni", value: 2 },
                    ]}
                    onChange={(selected) => this.onChangeHandler(selected.value, "children") }
                    multiple
                    disabled={disabled}
                    unselectable={person?.children?.length !== 1} // fix selezione numero figli
                  />
                  <ContextFeedback
                    show={this.state.errors.includes("children")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "children") ??
                      {msg: this.state.requiredFields?.children?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.children?.validation?.fail_severity ?? "error"}
                    }
                  />
                </div>

                { !(this.state?.hideFields?.includes("youngestChild") || !person?.children?.includes(1)) &&
                  <div className="col-12 mb-4">
                    <label>Quanti anni ha il figlio più piccolo?</label>
                    <Dropdown
                      value={person?.youngestChild
                          ? typeof(person.youngestChild) === "number"
                            ? person.youngestChild.toString()
                            : person.youngestChild
                          : undefined
                      }
                      options={this.minorAgeList.map((year: number) => {return {value: year.toString(), label: year}})}
                      onChange={(selected) => this.onChangeHandler(selected.value, "youngestChild" )}
                      optionLabel="label"
                      placeholder="Seleziona"
                      emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                      disabled={disabled}
                    />
                    <ContextFeedback
                      show={this.state.errors.includes("youngestChild")}
                      message={
                        this.state.errorTexts.find(elem => elem.field === "youngestChild") ??
                        {msg: this.state.requiredFields?.youngestChild?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.youngestChild?.validation?.fail_severity ?? "error"}
                      }
                    />
                  </div>
                }
              </>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("address")) &&
              <div className="col-12 mb-4">
                <Address
                  cityOfResidence={person?.cityOfResidence}
                  address={person?.address}
                  civico={person?.addressNumber}
                  postalCodeOfResidence={person?.postalCodeOfResidence}
                  labelFullAddress="Indirizzo di residenza"
                  placeholderFullAddress="Via delle Rose, 23, Roma"
                  placeholderAddress="Via delle Rose, 23"
                  errors={this.state.errors.filter((error: string) => this.addressComponentsFields.includes(error))}
                  errorTexts={
                    this.state.errorTexts.filter((feedback: IFeedbackText) => this.addressComponentsFields.includes(feedback.field))
                  }
                  disabled={disabled}
                  onDone={(addressData: any[]) => {
                    const [address, postalCode, cityOfResidence, civico] = addressData;
                    this.updateAddress(address, postalCode, cityOfResidence, civico);
                  }}
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("italianDrivingLicense")) &&
              <div className="col-12 field-button mb-4">
                <label>Hai una patente italiana?</label>
                <SelectButton
                  value={person?.italianDrivingLicense}
                  options={[
                    { label: "Si", value: true },
                    { label: "No", value: false },
                  ]}
                  onChange={(selected) =>
                    this.onChangeHandler(
                      selected.value,
                      "italianDrivingLicense"
                    )
                  }
                  className="smallBtn"
                  unselectable={false}
                  disabled={disabled}
                />
                <ContextFeedback
                  show={this.state.errors.includes("italianDrivingLicense")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "italianDrivingLicense") ??
                    {msg: this.state.requiredFields?.italianDrivingLicense?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.italianDrivingLicense?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("drivingLicenseAge")) &&

              <div className="col-12 mb-4">
                <label>A che età ha preso la patente?</label>
                <Dropdown
                  value={person?.drivingLicenseAge}
                  options={getDrivingLicenseAgeOptions(person?.dateOfBirth)}
                  onChange={(selected) => this.onChangeHandler(selected.value, "drivingLicenseAge")}
                  onHide={() => removeFocusClass()}
                  optionLabel="label"
                  placeholder="Seleziona"
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                  disabled={disabled}
                />
                <ContextFeedback
                  show={this.state.errors.includes("drivingLicenseAge")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "drivingLicenseAge") ??
                    {msg: this.state.requiredFields?.drivingLicenseAge?.validation.fail_msg ?? "Campo obbligatorio", severity: this.state.requiredFields?.drivingLicenseAge?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("degree")) &&
              <div className="col-12 mb-4">
                <label>Titolo di studio</label>
                <Dropdown
                  value={person?.degree}
                  options={this.props.degreeList}
                  onChange={(selected) => this.onChangeHandler(selected.value, "degree") }
                  onHide={() => removeFocusClass()}
                  placeholder="Seleziona"
                  optionLabel="label"
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                  disabled={disabled}
                />
                <ContextFeedback
                  show={this.state.errors.includes("degree")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "degree") ??
                    {msg: this.state.requiredFields?.degree?.validation.fail_msg ?? "Inserisci il titolo di studio", severity: this.state.requiredFields?.degree?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("profession")) &&
              <div className="col-12 mb-4">
                <label>Professione</label>
                <Dropdown
                  value={person?.profession}
                  options={this.props.professionList}
                  onChange={(selected) =>
                    this.onChangeHandler(selected.value, "profession")
                  }
                  onHide={() => removeFocusClass()}
                  placeholder="Seleziona"
                  optionLabel="label"
                  emptyMessage={Constants.DROPDOWN_EMPTY_MESSAGE}
                  disabled={disabled}
                />
                <ContextFeedback
                  show={this.state.errors.includes("profession")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "profession") ??
                    {msg: this.state.requiredFields?.profession?.validation.fail_msg ?? "Inserisci la professione", severity: this.state.requiredFields?.profession?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }

            { !(this.state?.hideFields && this.state.hideFields.includes("phone")) &&
              <div className="col-12 mb-4">
                <label htmlFor="contraenete-phone">Cellulare</label>
                  <span className="p-input-icon-right d-block">
                  {this.state?.phoneChecking === true && <i className="pi pi-spin pi-spinner" />}
                  <InputText
                    value={person?.phone ?? ""}
                    onChange={(selected) => this.onChangeHandler( selected.target.value, "phone" ) }
                    placeholder="Cellulare"
                    name="phone"
                    id="contraenete-phone"
                    autoComplete="tel"
                    type="tel"
                    keyfilter={/^\d+(\.\d{1,2})?$/}
                    onBlur={(selected) => this.phoneHandler(selected.target.value)}
                    disabled={disabled}
                  />
                </span>
                <ContextFeedback
                  show={this.state.errors.includes("phone")}
                  message={
                    this.state.errorTexts.find(elem => elem.field === "phone") ??
                    {msg: this.state.requiredFields?.phone?.validation.fail_msg ?? "Inserire un numero di cellulare valido", severity: this.state.requiredFields?.phone?.validation?.fail_severity ?? "error"}
                  }
                />
              </div>
            }
          </div>
          
          {this.state.editMode &&
            <>
              <Divider></Divider>
              <div className="row mt-4">
                <div className="col-12">
                  <Button
                    className="customBtn d-block bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite mt-3"
                    style={{width: "100%", textAlign: "center"}}
                    onClick={this.save}
                    label="Salva"
                    loading={this.state.saving}
                  />
                </div>
              </div>
            </>
          }
        </div>

      </Dialog>

  }

  public show(params: {
    person?: IPerson | string | undefined,
    header: JSX.Element,
    hideFields: string[],
    errors: string[],
    errorTexts: IFeedbackText[],
    editMode?: boolean,
    showControl?: boolean,
    requiredFields?: string[] | string | any,
    successCallback?: Function,
    errorCallback?: Function,
  } = {header: this.defaultHeader, hideFields: [], errors: [], errorTexts: []}){

    let state : any = {
      visible: true,
      header: params.header,
      person: params.person !== undefined
        ? typeof(params.person) === "string"
          ? this.props.user.getPersonById(params.person)
          : params.person
        : undefined,
      errors: params.errors ?? [],
      errorTexts: params.errorTexts ?? [],
      editMode: params?.editMode !== undefined
        ? params.editMode
        : this.state.editMode,
      showControl: params?.showControl !== undefined
        ? params.showControl
        : this.state.showControl,
      hideFields: params?.hideFields !== undefined ? params.hideFields : this.state.hideFields,
      requiredFields: params?.requiredFields !== undefined ? params.requiredFields : this.state.requiredFields,
      successCallback: params?.successCallback ? params.successCallback : this.state.successCallback,
      errorCallback: params?.errorCallback ? params.errorCallback : this.state.errorCallback,
    };

    if(params?.person && typeof(params.person) !== "string" && params.person?.id){
      state.id = params.person.id
    }

    this.setState(state);
  }

  public hide(){
    this.setState({
      visible: false,
      errors: [],
      errorTexts: [],
      feedbackDialog: false,
      feedbackStatus: "",
      feedbackBody: "",
      person: undefined,
      id: undefined
    });
  }

  private save(){

    if(this.state.person){
      this.setState({
        person: {email: this.props.user.userData.email, phone: this.props.user.userData.phone, ...this.state.person},
        saving: true
      }, () => {
        
        this.validation()
          .then((result: IValidationResponse) => {
            if(result.status){

              let refinedPerson: IPerson = {...this.state.person};
              this.state.hideFields?.forEach((fieldToRemove: string) => {
                if(!["email", "phone"].includes(fieldToRemove)){
                  const k = fieldToRemove as keyof IPerson;
                  delete refinedPerson[k];
                }
              });

              // pulisco i campi nullish
              Object.entries(refinedPerson).forEach(([k, v]: [string, any]) => {
                if(v === undefined || v === null){
                  delete refinedPerson[k as keyof IPerson];
                }
              })

              if(!refinedPerson?.id && this.state.id){
                refinedPerson.id = this.state.id;
              }

              if(!this.state.hideFields?.includes("drivingLicenseAge")){
                // se mostro il campo drivingLicenseAge mando sempre un valore
                // al più in caso di null | undefined o chiave drivingLicenseAge inesistente viene settato -9999
                refinedPerson.drivingLicenseAge === undefined && (refinedPerson.drivingLicenseAge = -9999)
              }

              this.props.user.upsert("person", refinedPerson)
                .then((response: any) => {                  
                  if(response?.success === false){
                    if(response.error_code === "DUPLICATES_DETECTED"){
                      throw new DuplicateException("Persona già inserita")
                    }
                    
                    throw new GenericException("Impossibile completare la richiesta. Contattare il supporto tecnico", "generic")
                  }
                  else{
                    this.defaultSuccessCallback({person: response, validation: result}, this.state.successCallback);
                  }
                })
                .catch((error: any) => {
                  if(error instanceof Error){
                    this.defaultErrorCallback(error);
                  }
                  else{
                    this.defaultErrorCallback();
                  }
                })
            }
            else{
              this.defaultErrorCallback();
            }
          })

      })
    }
  }

  private onBlurHandlerDate(dateString: string, callback?: Function){
    
    let newDateOfBirth : Date | undefined;
    let newErrors: string[];
    let newFeedErrors: IFeedbackText[];
  
    if(dateString){
      if(isLikelyDate(dateString)){

        let dateParts: string[] = dateString.split("/");
        let testDate = moment(dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]);

        const [day, month, year] : [day: string, month : string, year : string] = dateString.split("/") as [string, string, string];
        
        newDateOfBirth = new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00Z`);

        if (!testDate.isValid()) {
          newDateOfBirth = undefined;
          newErrors = [...this.state.errors.filter((error: string) => error !== "dateOfBirth"), "dateOfBirth"];
          newFeedErrors = [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Data invalida"}];
        }
        else {
          if(this.props?.ageValidation?.()){
            const {dateOfBirth, error, feedback} = this.props.ageValidation(dateString)
            newDateOfBirth = dateOfBirth;
            newErrors = error
              ? [...this.state.errors.filter((err: string) => err !== error), error]
              : this.state.errors;
            newFeedErrors = feedback
              ? [...this.state.errorTexts.filter((feed: IFeedbackText) => feed.field !== feedback.field), feedback]
              : this.state.errorTexts;
          }
          else{
            if(newDateOfBirth instanceof Date && !isNaN(newDateOfBirth.getTime())){
              const today = new Date();
              const age = today.getFullYear() - newDateOfBirth.getFullYear();
              let maggiorenne : boolean;
              if(age > Constants.DOB_YEAR_RANGE_MIN){
                maggiorenne = true;
              }
              else if(age === Constants.DOB_YEAR_RANGE_MIN){
                if(today.getMonth() < newDateOfBirth.getMonth()){
                  // devi essere maggiorenne
                  maggiorenne = false;
                }
                else if(today.getMonth() > newDateOfBirth.getMonth()){
                  //ok
                  maggiorenne = true;
                }
                else{
                  //check giorno
                  if(today.getUTCDay() < newDateOfBirth.getUTCDay()){
                    // devi essere maggiorenne
                    maggiorenne = false;
                  }
                  else{
                    // ok
                    maggiorenne = true;
                  }
                }
              }
              else{
                maggiorenne = false;
              }
        
              if(maggiorenne){
                if(age > Constants.DOB_YEAR_RANGE_MAX){ // too old
                  newErrors = [...this.state.errors.filter((e: string) => e !== "dateOfBirth"), "dateOfBirth"];
                  newFeedErrors = [...this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== "dateOfBirth"), {
                    field: "dateOfBirth",
                    msg: "Età invalida. Il contraente deve avere meno di 100 anni."
                  }];
                }
                else{
                  newErrors = this.state.errors.filter((e: string) => e !== "dateOfBirth");
                  newFeedErrors = this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== "dateOfBirth");
                }
              }
              else{
                newErrors = [...this.state.errors.filter((e: string) => e !== "dateOfBirth"), "dateOfBirth"];
                newFeedErrors = [...this.state.errorTexts.filter((feedback : IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Devi essere maggiorenne"}];
              }
            }
            else{
              newDateOfBirth = undefined;
              newErrors = [...this.state.errors.filter((error: string) => error !== "dateOfBirth"), "dateOfBirth"];
              newFeedErrors = [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Data invalida"}];
            }
          }
    
        }
      }
      else{
        newDateOfBirth = undefined;
        newErrors = [...this.state.errors.filter((error: string) => error !== "dateOfBirth"), "dateOfBirth"];
        newFeedErrors = [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Data invalida"}];
      }
    }
    else{
      newDateOfBirth = undefined;
      newErrors = [...this.state.errors.filter((error: string) => error !== "dateOfBirth"), "dateOfBirth"];
      newFeedErrors = [...this.state.errorTexts.filter((feedback: IFeedbackText) => feedback.field !== "dateOfBirth"), {field: "dateOfBirth", msg: "Data invalida"}];
    }

    this.setState({
      person: {
        ...this.state.person,
        dateOfBirth: newDateOfBirth,
        drivingLicenseAge: undefined
      },
      errors: newErrors,
      errorTexts: newFeedErrors
    }, () => {
      callback?.();
    })
  }

  

  /**
   * Handles various state change events related to the form's components.
   *
   * @param {any} selected The selected object
   * @param {string} componentName The name of the component to handle
   */
  private onChangeHandler(value: any, componentName: string) {
    
    switch(componentName){
      case "bornInItaly":
        this.setState({
          person: {
            ...this.state.person,
            bornInItaly: value,
            countryOfBirth: value ? undefined : this.state.person?.countryOfBirth,
            cityOfBirth: value ? this.state.person?.cityOfBirth : undefined,
          }
        })
        break;
      case "cityOfBirth":
        // sostituice gli apici ’ con gli apostrofi
        value = replaceSpecialChars(value);
        this.setState({ person: { ...this.state.person , cityOfBirth: value }});
        break;
      case "children":

        let childrenState : any;

        // questo evento change si triggera solo se viene effettivamente cambiato il valore della multiselect
        // se sono qui è perché è cambiato almeno un valore dell'array
    
        // se provengo da uno stato nel quale ho dichiarato di non aver figli ([0]), devo ripulire il value da 0
        if (this.state.person?.children !== undefined && this.state.person?.children.includes(0)) {
          childrenState = {children: value.filter((arrayItem: any) => arrayItem !== 0).sort((a: any, b: any) => (a < b) ? -1 : (a > b) ? 1 : 0)};
        }
        // se provengo da uno stato nel quale ho dichiarato di avere almeno un figlio ([1] | [1,2]) e il nuovo valore è nessuno figlio ([0]) lo sovrascrivo direttamente
        else if (value.includes(0)) {
          childrenState = {
            children: [0],
            youngestChild: ""
          }
        }
        else{
          childrenState = {children: value};
        }

        this.setState({ person: { ...this.state.person , ...childrenState }});
        break;
      case "indirizzo":
        const [address, postalCodeOfResidence, cityOfResidence] = value;
        let newState = {
          address: address,
          postalCodeOfResidence: postalCodeOfResidence,
          cityOfResidence: cityOfResidence
        };
        value = newState;
        this.setState({ person: { ...this.state.person , ...value }});
        break;
      case "name":
        this.setState({
          person: { ...this.state.person , name: value}
        }, () => {
          const safename = User.getPersonSafeName(this.state.person)
          this.setState({
            header: <h3 className="colorPrimaryGreen titleLev4">{safename}</h3>
          });});
        break;
      case "surname":
        this.setState({
          person: { ...this.state.person , surname: value}
        }, () => {
          const safename = User.getPersonSafeName(this.state.person)
          this.setState({
            header: <h3 className="colorPrimaryGreen titleLev4">{safename}</h3>
          });});
        break;
      case "phone":
          value = value.substring(0, 10);
          this.setState({
            person: { ...this.state.person , phone: value}
          });
        break;
      default:
        this.setState({ person: { ...this.state.person , [componentName]: value} });
        break;
    }

    this.resetValidation(componentName);

  }

  /**
   * permette l'aggiornamento dello stato tramite un component esterno
   * @param typeOfPerson
   * @param address
   * @param postalCode
   * @param cityOfResidence
   */
   private updateAddress(
    address: string,
    postalCode: string,
    cityOfResidence: AjaxAutocompleteObject,
    civico: string
  ) {

    // if (civico && !isStreetAddres(address)){
    //   address += `, ${civico}`;
    // }

    this.setState({
      person: {
        ...this.state.person,
        cityOfResidence: cityOfResidence,
        address: address,
        addressNumber: civico,
        postalCodeOfResidence: postalCode,
      }
    }, () => {
      this.validation(["address", "addressNumber", "cityOfResidence", "postalCodeOfResidence"])
    });
  }

  /**
   * Reset form element validation
   * @param element
   * @returns
   */
  resetValidation(element: any) {
    this.setState({ errors: this.state.errors.filter((item: any) => item !== element) });
    return;
  }

  private async validation(specificField: undefined | string | string[] = undefined): Promise<IValidationResponse>{

    let fieldsToValidate : IFieldDescriptor[] = [];
    let initialErrors : string[] = [];
    let initialErrorTexts : IFeedbackText[] = [];

    // fix: Uncaught (in promise) TypeError: Cannot read property 'field' of undefined
    // in pratica da loggato: quando creo una persona, selezionando un comune di nascita, non viene settato requiredFields nello stato e la validazione non vine fatta..
    // da verificare se succede anche con altri campi, o è un comportamento voluto..
    if (this.state.requiredFields) {
      if (specificField !== undefined) {
        if (Array.isArray(specificField)) {
          fieldsToValidate = specificField.map(field => this.state.requiredFields?.[field])
        }
        else {
          fieldsToValidate = [this.state.requiredFields?.[specificField]];
        }

        let tmp = fieldsToValidate.map((descriptor: IFieldDescriptor) => descriptor.field);
        initialErrors = this.state.errors.filter((err: string) => !tmp.includes(err))
        initialErrorTexts = this.state.errorTexts.filter((feed: IFeedbackText) => !tmp.includes(feed.field))
      }
      else {
        fieldsToValidate = Object.entries(this.state.requiredFields).map(([, value]) => value) as IFieldDescriptor[];
      }
    }      

    return User.validatePerson(this.state.person as IPerson, fieldsToValidate)
      .then((result: IValidationResponse) => {
        const {error_fields, error_messages} = result;
        this.setState({
          errors: [...initialErrors, ...error_fields],
          errorTexts: [...initialErrorTexts, ...error_messages]
        })
        return result;
      });

  }

  private dialogScrollToTop() {
    this.dialogRef?.current.scrollIntoView({
      behavior: "smooth"      
    })
  }
}

export const PersonCard = forwardRef((props: any, ref: ForwardedRef<PersonCardComponent>) => {
  const lists: IDataList = useContext(DataList);
  return <PersonCardComponent {...props} ref={ref} {...lists} setResourceListRequest={props.setResourceListRequest} />
})