import { createContext } from "react";
import { AjaxEnrichedAutocompleteObject } from "../utils/common";

export interface IDataList{
  professionList: AjaxEnrichedAutocompleteObject[],
  civilStateList: AjaxEnrichedAutocompleteObject[],
  degreeList: AjaxEnrichedAutocompleteObject[]
  antitheftList: AjaxEnrichedAutocompleteObject[],
  vehicleUseList: AjaxEnrichedAutocompleteObject[],
  parkingLocationList: AjaxEnrichedAutocompleteObject[]
}

export const dataListInitialState : IDataList = {
  professionList: [],
  civilStateList: [],
  degreeList: [],
  antitheftList: [],
  vehicleUseList: [],
  parkingLocationList: [],
}

export const DataList: any = createContext(dataListInitialState);