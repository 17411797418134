import { Component } from "react";
import RadioButtonListItem from "./RadioButtonListItem";
import { Accordion } from "react-bootstrap";
import EmptyListItemLabel from "./EmptyListItemLabel";

export default class RadioButtonListComponent extends Component<
  {
    items: any[] | undefined; // elements to render in list
    className?: string
    name: string;             // name of radio component
    value?: string | number;  // selected value
    idPrefix?: string;        // prefix to use for calculate the input id
    valueField?: string;      // name of the field that store the data to use as value
    // itemsAction?: any;        // action of item in items
    itemsAction?: {           // action of item in items
      label: string,
      attributes: any,
    };
    emptyItem?: {             // add empty option
      label: string;
      component?: (props: any) => JSX.Element;
      value?: string | number;
      onClick?: Function;
      extra?: any;
    }
    handleChange?: any;
    labelType: {
      type?: string|undefined;
      component?: (props: any) => JSX.Element;
      extra?: any
    };
  },
  {}
> {
  private idPrefix?: string;
  private name: string;
  private valueField: string;
  private labelType: {
    type?: string|undefined;
    component?: (props: any) => JSX.Element;
    extra?: any
  };
  private emptyItem?: {
    label: string;
    component?: (props: any) => JSX.Element;
    value?: string | number;
    onClick?: Function;
    extra?: any;
  }
  private abortController: AbortController = new AbortController();

  constructor(props: any) {
    super(props);

    this.name = props.name;
    this.idPrefix = props?.idPrefix ?? this.name;
    this.valueField = props?.valueField ?? "value";
    this.labelType = props.labelType;
    
    this.emptyItem = {
      label: props?.emptyItem.label ?? "Inserisci manualmente",
      value: props?.emptyItem?.value ?? -1,
      onClick: props?.emptyItem?.component?.onClick ?? ((e: Event) => {}),
      component: props?.emptyItem?.component ?? EmptyListItemLabel,
      extra: props?.emptyItem?.extra
    }
    
    this.handleChange = props?.handleChange ? this.handleChange.bind(this) : (selected) => {console.log(selected)};
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  render() {

    if (this.props?.items) {
      return <Accordion activeKey={this.props?.value ? `${this.idPrefix}-${this.props?.value.toString()}` : "unselected"}>
        <ul className={`userItemRadioList ${this.props?.className ? this.props.className : ""}`}>
          {this.props.items.map((item, index, self) => {
            return <RadioButtonListItem
              key={index}
              item={item}
              action={this.props.itemsAction}
              name={this.name}
              idPrefix={this.idPrefix}
              valueField={this.valueField}
              value={this.props?.value}
              handleChange={this.handleChange}
              labelType={{type: this.labelType.type, component: this.labelType?.component}}
              addDivider={this?.emptyItem ? true : (index === self.length -1)}
            />
          })}
          {this?.emptyItem &&
            <RadioButtonListItem
              key={this.props?.items?.length ?? 0}
              item={{
                id: -1,
                label: this.emptyItem?.label
              }}
              name={this.name}
              idPrefix={this.idPrefix}
              valueField="id"
              value={this.props?.items.length === 0 ? -1 : this.props?.value}
              handleChange={this.props.emptyItem?.onClick}
              labelType={{component: this.emptyItem.component}}
            />
          }
        </ul>
      </Accordion>
    }
  }

  handleChange(e: any) {
    this.props.handleChange(e)
  }

}