import { Card } from "react-bootstrap";

const GenericErrorCard = () => {
    return <Card className="pr-form mb-5">
          <div className="p-fluid p-5">
              <div className="row">
                  <div className="col-12">
                      <h1 className="titleLev4 colorPrimaryBlue mb-2">
                        Registrazione fallita
                      </h1>
                      <p>                                        
                        Impossibile completare la richiesta di attivazione dell'account
                        <br/>
                        Contatta il nostro supporto tecnico
                      </p>

                  </div>
              </div>
          </div>
      </Card>    
}

export default GenericErrorCard;