import { Card } from "react-bootstrap";

const UndefinedErrorCard = () => {
  return <Card className="pr-form mb-5">
        <div className="p-fluid p-5">
            <div className="row">
                <div className="col-12">
                    <h1 className="titleLev4 colorPrimaryBlue mb-2">
                      Qualcosa è andato storto
                    </h1>
                    <p>
                      Prova a inviare di nuovo la richiesta, ad accedere o contatta il nostro supporto tecnico
                    </p>
                </div>
            </div>
        </div>
    </Card>
}

export default UndefinedErrorCard;