import React, { Component, createRef } from 'react';
import './App.scss';
import StepLoaderSpinner from './components/StepLoaderSpinner';
import * as Constants from './components/utils/constants';
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { addLocale } from 'primereact/api';
import { IPerson, IVehicle, Step1Data, Step2Data, Step3Data, UserInfo } from './components/utils/common';
import Menu from './components/customs/Menu';
import Footer from './components/customs/Footer';
import User, { IPrivacyStatus } from './components/utils/User';
import AreaPersonaleHub from './components/AreaPersonaleHub';
import LoginPage from './components/LoginPage';
import ForgottenPasswordPage from './components/ForgottenPasswordPage';
import ResetPasswordPage from './components/ResetPasswordPageComponent';
import PreventivatoreHub from './components/PreventivatoreHub';
import CookieService from './components/utils/CookieService';
import ScrollToTop from './components/customs/HOC/ScrollToTop';
import AccountActivationPage from './components/AccountActivationPage';
import AccountRimossoPage from './components/AccountRimossoPage';
import { TokenExpireException } from './components/utils/UserService';
import { step1DataSuccess, step2DataSuccess, step3DataSuccess, userInfoDataSuccess } from './redux/actions';
import RegistrationPage from './components/RegistrationPage';
import LoginDialog from './components/customs/LoginDialogComponent';
import RegistrationDialog from './components/customs/RegistrationComponent';
import { connect } from 'react-redux';
import { StepDataDualState } from './redux/reducers';
import { compose } from 'ramda';
import withEventManager from './components/customs/HOC/withEventManager';
import { IWithEventManager } from './components/customs/HOC/EventManager';


interface IAppProps extends IWithEventManager{
  userInfo: UserInfo;
  step1Data: StepDataDualState;
  step2Data: StepDataDualState;
  step3Data: StepDataDualState;
  userInfoDataSuccess: Function;
  setVehicle: Function;
  setPrivacy: Function;
  step1DataSuccess: Function;
  step2DataSuccess: Function;
  step3DataSuccess: Function;
  history: ReturnType<typeof useHistory>;
}

interface IAppState{
  scriptLoad: boolean,
  fbMounted: boolean,
  scrollClass: string,
  user: User,
  loading: boolean,
}

class AppComponent extends Component<IAppProps, IAppState> {
  private vehicle: string = "auto";
  private step: number = 0;
  private logginDialogRef: any;

  constructor(props: any) {
    super(props);

    if (window.location.pathname.split("/").length && window.location.pathname.split("/")[2] !== undefined) {
      const v = window.location.pathname.split("/")[2].split("-")[1];

      if (["auto", "moto"].includes(v)) {
        this.vehicle = v;
      }
    }


    if (this.props.history.location.pathname.endsWith(Constants.STEP1_AUTO_URL_POSTFIX) || this.props.history.location.pathname.endsWith(Constants.STEP1_MOTO_URL_POSTFIX)) {
      this.step = 0;
    }
    else if (this.props.history.location.pathname.endsWith(Constants.STEP2_URL_POSTFIX)) {
      this.step = 1;
    }
    else if (this.props.history.location.pathname.endsWith(Constants.STEP3_URL_POSTFIX)) {
      this.step = 2;
    }
    else if (this.props.history.location.pathname.endsWith(Constants.STEP4_URL_POSTFIX) || this.props.history.location.pathname.includes(`${Constants.STEP4_URL_POSTFIX};jsessionid=`)) {
      this.step = 3;
    }
    else if (this.props.history.location.pathname.endsWith(Constants.STEP5_URL_POSTFIX)) {
      this.step = 4;
    }
    else if (this.props.history.location.pathname.endsWith(Constants.STEP5_ERROR_URL_POSTFIX)) {
      this.step = 5;
    }
    else if (this.props.history.location.pathname.endsWith(Constants.NOTE_INFORMATIVE)) {
      this.step = -1;
    }

    this.state = {
      scriptLoad: false,
      fbMounted: false,
      scrollClass: '',
      user: User.getInstance(),
      loading: true
    };

    addLocale('it', {
      firstDayOfWeek: 1,
      dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
      monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
    });

    const query = new URLSearchParams(window.location.search);
    query.get("utm_id") && sessionStorage.setItem("utm_id", query.get("utm_id") as string);
    query.get("utm_campaign") && sessionStorage.setItem("utm_campaign", query.get("utm_campaign") as string);
    query.get("utm_content") && sessionStorage.setItem("utm_content", query.get("utm_content") as string);
    query.get("utm_source") && sessionStorage.setItem("utm_source", query.get("utm_source") as string);
    query.get("utm_medium") && sessionStorage.setItem("utm_medium", query.get("utm_medium") as string);
    query.get("utm_name") && sessionStorage.setItem("utm_name", query.get("utm_name") as string);
    query.get("utm_term") && sessionStorage.setItem("utm_term", query.get("utm_term") as string);
    query.get("gclid") && sessionStorage.setItem("gclid", query.get("gclid") as string);
    query.get("fbclid") && sessionStorage.setItem("fbclid", query.get("fbclid") as string);
    query.get("msclkid") && sessionStorage.setItem("msclkid", query.get("msclkid") as string);
    query.get("ttclid") && sessionStorage.setItem("ttclid", query.get("ttclid") as string);
    query.get("s_id") && sessionStorage.setItem("s_id", query.get("s_id") as string);

    this.handlePhoneClick = this.handlePhoneClick.bind(this);
    this.refineEntity = this.refineEntity.bind(this);
    this.storageFullResetAfterLogin = this.storageFullResetAfterLogin.bind(this);
    this.getPrivacyFromUser = this.getPrivacyFromUser.bind(this);

    
    this.logginDialogRef = createRef();

  }

  storageFullResetAfterLogin(user: User){
    this.props.userInfoDataSuccess({
      externalJourney: undefined,
      externalJourneyId: undefined
    })
  }

  private getPrivacyFromUser(user: User): Step3Data{
    let privacyToDispatch: Step3Data = {};
    user.privacy.forEach((p: IPrivacyStatus) => {
      switch(p.type){
        case "generale":
          privacyToDispatch.acceptSeiSicuroPrivacyPolicy = true
          break;
        case "commerciale":
          privacyToDispatch.acceptGdprPrivacyPolicy102 = p.consent;
          break;
        case "newsletter":
          privacyToDispatch.acceptGdprPrivacyPolicy22 = p.consent;
          break;
        case "profilazione":
          privacyToDispatch.acceptGdprPrivacyPolicy3 = p.consent;
          break;
        case "cessione":
          privacyToDispatch.acceptGdprPrivacyPolicy104 = p.consent;
          break;
        case "cgu":
          privacyToDispatch.acceptCgu105 = true;
          break;
        case "ivass":
          privacyToDispatch.acceptIvass106 = false;
          break;
      }
    })

    return privacyToDispatch
  }

  private mapFieldPersonToStep3Contactor(personField: string): string{
    let mappedField: string = "";
    
    switch(personField){
      case "name":
        mappedField = "contractorName";
        break;
      case "surname":
        mappedField = "contractorSurname";
        break;
      case "address":
        mappedField = "contractorAddress";
        break;
      case "addressNumber":
        mappedField = "contractorAddressNumber";
        break;
      case "cityOfResidence":
        mappedField = "contractorCityOfResidence";
        break;
      case "postalCodeOfResidence":
        mappedField = "contractorPostalCode";
        break;
      case "children":
        mappedField = "contractorChildren";
        break;
      case "youngestChild":
        mappedField = "contractorYoungestChild";
        break;
      case "email":
        mappedField = "contractorEmail";
        break;
      case "phone":
        mappedField = "contractorPhoneNumber";
        break;
      case "italianDrivingLicense":
        mappedField = "contractorItalianDrivingLicense";
        break;
      case "drivingLicenseAge":
        mappedField = "contractorDrivingLicenseAge";
        break;
    }

    return mappedField;
  }

  private refineEntity(user: User){
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    const step2Data: Step2Data = this.props.step2Data[this.vehicle as keyof StepDataDualState] as Step2Data;
    const step3Data: Step3Data = this.props.step3Data[this.vehicle as keyof StepDataDualState] as Step3Data;
    
    const contractor: IPerson|undefined = step1Data?.contractorId ? user.getPersonById(step1Data.contractorId) as IPerson : undefined
    const vehicle: IVehicle = user.getVehicleById(step2Data.vehicleLicensePlateNumber as string) as IVehicle

    this.props.step1DataSuccess(
      {
        ...step1Data,
        ...contractor
      },
      this.vehicle
    );

    this.props.step2DataSuccess(
      {
        ...step2Data,
        ...vehicle
      }
      ,
      this.vehicle
    );
    
    let step3DataToDispatch: Step3Data = {};
    if(contractor){
      const contracotrFields = ["name", "surname", "cityOfResidence", "postalCodeOfResidence", "address", "addressToponimo", "addressNumber", "children", "youngestChild", "email", "phone", "italianDrivingLicense", "drivingLicenseAge"];

      Object.entries(contractor).forEach(([filedOriginalName, value]) => {
        if(contracotrFields.includes(filedOriginalName)){
          step3DataToDispatch[this.mapFieldPersonToStep3Contactor(filedOriginalName) as keyof Step3Data] = value
        }
      })
    }
    
    if(step3Data.ownerId){
      step3DataToDispatch = {...step3DataToDispatch, owner: {...user.getPersonById(step3Data.ownerId)}};
    }
    if(step3Data.driverId){
      step3DataToDispatch = {...step3DataToDispatch, driver: {...user.getPersonById(step3Data.driverId)}};
    }

    this.props.step3DataSuccess(
      {
        ...step3Data,
        ...step3DataToDispatch
      },
      this.vehicle
    );
  }

  handleExternalJourney(fromBO: boolean) {
    console.log("App handleExternalJourney");

    // gestione da external journey
    if(this.props.userInfo?.externalJourney ?? false){

      if(this.props.userInfo?.externalJourneyId ?? false){
        // quotation user logged

        if(User.isAlive()){
          const user = User.getInstance();
          
          if(fromBO){
            // se provendo dal BO non devo fare nessun controllo incrociato tra JWT e dati dell'utente
            this.refineEntity(user);
            this.props.userInfoDataSuccess({
              email: user.userData.email,
              externalJourney: undefined,
              externalJourneyId: undefined,
              fromBO: undefined,
              logged: true,
              openLogin: false,
              openRegistration: false,
              userData: JSON.stringify(user),
              // skipEmailCheck: undefined
            })
            this.props.setPrivacy(this.getPrivacyFromUser(user), this.vehicle);
          }
          else{
            if(this.props.userInfo.externalJourneyId === user.id){
              // regular journey with jwt
              this.refineEntity(user);
              this.props.userInfoDataSuccess({
                externalJourney: undefined,
                externalJourneyId: undefined
              })
              this.props.setPrivacy(this.getPrivacyFromUser(user), this.vehicle);
            }
            else{
              // quotation user differente dall'utente attualmente loggato
              User.logout(this.vehicle);

              this.logginDialogRef?.current?.setOnLogin((user: User, prevState: UserInfo, nextState: UserInfo) : void => {

                if(prevState.externalJourneyId === user.id){
                  this.refineEntity(user);
                }
                else{
                  User.resetStepData(this.vehicle); // resetto i dati caricati dallo step4Loader
                  if(this.step !== 0){
                    this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle))
                  }
                }

                this.props.userInfoDataSuccess({
                  externalJourney: undefined,
                  externalJourneyId: undefined
                })
                this.props.setPrivacy(this.getPrivacyFromUser(user), this.vehicle);
              })

              this.logginDialogRef?.current?.setOnHide((prevState: UserInfo, nextState: UserInfo) : void => {
                this.props.userInfoDataSuccess({
                  externalJourney: undefined,
                  externalJourneyId: undefined
                })
                User.resetStepData(this.vehicle)
                if(this.step !== 0){
                  this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle))
                }
              })

              this.props.userInfoDataSuccess({
                openLogin: true,
                openRegistration: false,
                externalJourney: undefined,
                externalJourneyId: undefined,
                userData: JSON.stringify(user.setInitialState()),
                logged: false
              })

            }
          }
        }
        else{

          this.logginDialogRef?.current?.setOnLogin((user: User, prevState: UserInfo, nextState: UserInfo) : void => {

            if(prevState.externalJourneyId === user.id){
              this.refineEntity(user);
            }
            else{
              User.resetStepData(this.vehicle); // resetto i dati caricati dallo step4Loader
              if(this.step !== 0){
                this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle))
              }
            }

            this.props.userInfoDataSuccess({
              externalJourney: undefined,
              externalJourneyId: undefined
            })
            this.props.setPrivacy(this.getPrivacyFromUser(user), this.vehicle);
          })

          this.logginDialogRef?.current?.setOnHide((prevState: UserInfo, nextState: UserInfo) : void => {
            this.props.userInfoDataSuccess({
              externalJourney: undefined,
              externalJourneyId: undefined
            })
            User.resetStepData(this.vehicle)
            if(this.step !== 0){
              this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle))
            }
          })

          this.props.userInfoDataSuccess({
            openLogin: true,
            openRegistration: false,
            userData: JSON.stringify(User.getInstance().setInitialState()),
            logged: false
          })
        }

      }
      else{
        // quotation user guest

        if(User.isAlive()){
          User.logout(this.vehicle);
        }

        let dataToDispatch: UserInfo = {
          fromBO: undefined,
          externalJourney: undefined,
          externalJourneyId: undefined,
          openLogin: false,
          openRegistration: false,
          logged: false,
          userData: JSON.stringify(User.getInstance()),
        }
        
        this.props.userInfoDataSuccess(dataToDispatch);
      }

      return;
    }

  }

  componentWillMount(){
    // avvio il processo che gestisce i cookie e monta i vari script di traking

    console.log("App componentWillMount");
    // this.manageTraking();
  }

  componentDidMount() {
    this.manageTraking();
    // console.log("App componentDidMount");
    this.props.setVehicle(this.vehicle);

    const jwt: string | null = CookieService.getCookie("jwt");

    if(jwt) {
      //controllo se posso loggarmi con jwt
      // potrei essermi preloggato dal BO
      User.loginWithJWT(jwt)
        .then((user: User) => {
          this.props.setPrivacy(this.getPrivacyFromUser(user), this.vehicle);
          this.props.userInfoDataSuccess({
            userData: JSON.stringify(user),
            logged: true,
            openLogin: false,
          });
          this.setState({loading: false}, () => {
            if (this.props.userInfo?.externalJourney) {
              this.handleExternalJourney(this.props.userInfo?.fromBO ?? false);
            }
          });
        })
        .catch((error) => {
          if (error instanceof TokenExpireException) {
            // caso sessione scaduta

            this.logginDialogRef?.current?.setOnHide((prevState: UserInfo, nextState: UserInfo) : void => {
              if(prevState?.revive === true){
                const payload: UserInfo = {
                  revive: false,
                  openLogin: false
                };
          
                this.props.userInfoDataSuccess(payload);
                User.resetStepData(this.vehicle)
                if(this.step !== 0){
                  this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle))
                }
              }
            })
            
            this.props.userInfoDataSuccess({
              openLogin: true,
              openRegistration: false,
              revive: true,
              email: (this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data)?.email ?? ""
            });
            this.setState({loading: false});
          }
          else {
            ["auto", "moto"].forEach(vehicle => User.resetStepData(vehicle))
          }
        });
    }
    else{
      // JWT non esiste
      // non posso provenire dal BO in questo caso

      if(this.props.userInfo?.logged && (this.props.userInfo?.userData ?? "{}") !== "{}"){
        // JWT scaduto (non viene fatta nessuna richiesta allo User Service Layer da 2h)
        // cookie cancellati

        this.setState({loading: false}, () => {

          this.logginDialogRef.current?.setOnLogin((user: User, prevState: UserInfo, nextState: UserInfo) : void => {
            
            const prevUserId = JSON.parse(prevState.userData ?? "{}")?.id;
            if(prevUserId !== undefined && prevUserId !== user.id){
              this.storageFullResetAfterLogin(user);
              User.resetStepData(this.vehicle)
              this.props.setPrivacy(this.getPrivacyFromUser(user), this.vehicle);
              this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle))
            }
          })

          this.logginDialogRef.current?.setOnHide((prevState: UserInfo, nextState: UserInfo) : void => {
            User.resetStepData(this.vehicle)
            this.props.userInfoDataSuccess({
              // skipEmailCheck: false, // inibisco il controllo della mail al prossimo render loop
              step1EmailValid: undefined, // resetto lo stato di validazione
              emailChecking: undefined,
              emailRegistered: undefined,
              email: undefined
            })
            if(this.step !== 0){
              this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle))
            }
          })

          let dataToDispatch: UserInfo = {
            logged: false,
            email: JSON.parse(this.props.userInfo?.userData ?? "").email,
            openLogin: true,
            openRegistration: false,
          };
          this.props.userInfoDataSuccess(dataToDispatch)
          
          if (this.props.userInfo?.externalJourney) {
            this.handleExternalJourney(this.props.userInfo?.fromBO ?? false);
          }
        })
      }
      else{
        // pure guest
        this.setState({loading: false}, () => {
          if (this.props.userInfo?.externalJourney) {
            this.handleExternalJourney(this.props.userInfo?.fromBO ?? false);
          }
        });
      }
    }

  }

  componentDidUpdate(prevProps: Readonly<IAppProps>, prevState: Readonly<IAppState>, snapshot?: any): void {
    if (prevProps.userInfo?.externalJourney !== this.props.userInfo?.externalJourney) {
      this.handleExternalJourney(this.props.userInfo?.fromBO ?? false);
    }
  }

  /**
   * Definisce i vari callbak per i vari script di tracciamento
   * e si occupa di appendere al documento lo script di tag manager
   */
  private manageTraking () {
      /**
       * definisco la funzione adform_mounted() 
       * che deve essere richiamata nello script tracking_adform.js (deve essere iniesttato da TagManager)
       * 
       * quando questa viene richiamata (al mount del file tracking_adform.js), come callback viene eseguita la funzione 
       * 
       * adform_listener()
       * 
       * nei vari step di questo progetto (il che fa scattare i vari tracciamenti)
       * 
       * -- STESSO FUNZIONAMENTO PER LE RESTANTI FUNZIONI
       */
      (window as any).tagMan_mounted = () => {
        if (typeof (window as any).tagMan_listener === 'function') {
          (window as any).tagMan_listener();
        }
      }
  
      /**
       * Appende al App lo script traking_tagManager.js (viene scaricato dal server dei contenuti statici)
       * 
       * TagManager deve iniettare i restanti script, in cui sono richiamate le funzioni sovrastanti, che danno il via a vari traccimaneti
       * tramite le funzioni di callback
       */
      this.mountJsTrackingScriptV2("traking_gtag");
      this.mountJsTrackingScriptV2("traking_tagManager");
      this.mountCookiebot();
  }

  /**
   * Appende lo script "scriptName" al documento e setta la variabile di stato "scriptLoaded a true!"
   * @param scriptName  
   */
  private mountJsTrackingScriptV2(scriptName: string) {
    const script = document.createElement("script");

    if(process.env.REACT_APP_ENV !== 'brainpull' && process.env.REACT_APP_ENV !== 'brainpull_on_80'){
      script.src = `${Constants.SEISICURO_STATIC_CONTENT_URL_WITH_FOLDER}/tracking/${scriptName}.js?v=1.4.1`;
    }
    else{
      script.src = `https://static.6sicuro-cdn.it/static/seisicurotest/contenuti_statici_new/tracking/${scriptName}.js?v=1.4.1`;
    }
    script.setAttribute("data-cookieconsent", "ignore");
    script.async = true;
    // lascio come documentazione! -- se devo caricare altri script dopo il consenso usare onload
    script.onload = () => {
      // evito che venga appeso più volte!
      if (this.state.scriptLoad === false ) {
        /*
          se lo script traking_tagManager.js è stato montato correttamente
          viene richiamata la funzione tagMan_mounted() (presente nel file js)
          che manderà in esecuzione (window as any).tagMan_listener() 
          definita nella manageTraking() di questo file..

          Quindi se typeof (window as any).tagMan_listener === 'function'
          allora il tutto è stato montato correttamente

          Quindi posso avviare la chat e settare la variabile di stato
          scriptLoad a true!
        */
        /*
          if (typeof (window as any).tagMan_listener === 'function') {
            this.setState({ scriptLoad: true }, () => {
              // avvio la chat
              this.mountChatScript();
            });
          }
        */
      }
    }
  
    document.head.appendChild(script);
  }

  /**
   * Monta lo script che avvia la cookieBot
   */
  private mountCookiebot() {
    const script = document.createElement("script");
    script.src = "https://consent.cookiebot.com/uc.js";
    script.type = "text/javascript";
    script.id = "Cookiebot";
    script.setAttribute("data-cbid", "c46db3c9-c278-43ee-84c9-859c5fa91316");
    script.setAttribute("data-blockingmode", "auto");
    script.async = true;
    document.head.appendChild(script);
  }
  
  /**
   * Gestisce il click sul telefono
   * @param e l'evento (click)
   */
  private handlePhoneClick(e: React.MouseEvent<HTMLElement>) {
    this.props.trackEvent?.({
      name: "phoneNumberClick",
      data: {},
      callback: () => {
        document.getElementById('chiamata')?.click();
      }
    });
  }

  render() {
    console.log("App render");

    return <>
      <LoginDialog ref={this.logginDialogRef}/>
      {!this.state.loading
        ? <div className="pr-root">
            
            <Menu telClick={this.handlePhoneClick} />

            <RegistrationDialog/>
            
            <ScrollToTop />

            <Switch>
              <Route exact path="/">
                <Redirect to="/preventivi" />
              </Route>

              <Route path="/preventivi/area-personale/login">
                <LoginPage/>
              </Route>
              <Route path="/preventivi/area-personale/registrazione">
                <RegistrationPage/>
              </Route>
              <Route path="/preventivi/area-personale/password-dimenticata">
                <ForgottenPasswordPage />
              </Route>
              <Route path="/preventivi/area-personale/reset-password">
                <ResetPasswordPage />
              </Route>
              <Route path="/preventivi/area-personale/conferma-attivazione-account">
                <AccountActivationPage />
              </Route>                  
              <Route path="/preventivi/area-personale/account-rimosso">
                <AccountRimossoPage />
              </Route>

              <Route path="/preventivi/area-personale">
                <AreaPersonaleHub user={this.state.user}/>
              </Route>
              
              <Route path={["/preventivi", "/preventivi:rest_of_url"]}>
                <PreventivatoreHub/>
              </Route>
            </Switch>

            <Footer telClick={this.handlePhoneClick}/>

          </div>
        : <StepLoaderSpinner title="Caricamento" />
    }
    </>
  }

}

const mapState = (state: any) => {
  return {
    loginVisible: state.userInfoData.user.openLogin,
    step1Data: state.step1Data,
    step2Data: state.step2Data,
    step3Data: state.step3Data,
    userInfo: state.userInfoData.user
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    step1DataSuccess: (payload: any, vehicle: string) => dispatch(step1DataSuccess(payload, vehicle)),
    step2DataSuccess: (payload: any, vehicle: string) => dispatch(step2DataSuccess(payload, vehicle)),
    step3DataSuccess: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle)),
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    setPrivacy: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle)),
    setVehicle:  (vehicle: string) => dispatch(userInfoDataSuccess({currentVehicleSelector: vehicle})),
  }
};

const AppRouterContext = (props: any) => {
  const history: ReturnType<typeof useHistory> = useHistory();
  return <AppComponent {...props} history={history}/>
}

const App = (props: any) => {

  return <Router>
    <AppRouterContext {...props}/>
  </Router>
}

export default compose(connect(mapState, mapDispatch), withEventManager)(App);
// export default App;