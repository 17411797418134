import { Component, createRef, useContext } from "react";
import { Card } from "react-bootstrap";
import PagesContext, { IPageDescriptor } from "./customs/HOC/useAreaPersonalePages";
import { IPageProps } from "./customs/AreaPersonalePageMaker";
import User, { IValidationResponse } from "./utils/User";
import * as Constants from './utils/constants';
import CompanyRow from "./CompanyRow";
import { ICompany, ICompany_WithErrors } from "./utils/common";
import { CompanyCard } from "./customs/CompanyCard";
import { Divider } from "primereact/divider";
import { AreaPersonaleCompanyFieldsBuilder } from "./utils/CompanyFieldsBuilder";
import StepLoaderSpinner from "./StepLoaderSpinner";
import { isLikelyDate } from "./utils/methods";
import { IFeedbackText } from "./utils/Feedback";
import '../scss/gestione-persone.scss';

interface IGestioneAccountProps extends IPageProps{
  pages: IPageDescriptor[];
}

interface IGestioneAccountState{
  listOfCompanies: ICompany[];
  titolareData: ICompany;
  isLoading: boolean;
}

class AreaPersonaleGestioneAziendeComponent extends Component<IGestioneAccountProps, IGestioneAccountState>{
  
  private currentPage: IPageDescriptor | undefined;
  private pages: IPageDescriptor[];
  private refPCard: any;

  private readonly companyFields : any;

  constructor (props: IGestioneAccountProps){
    super(props);

    this.pages = this.props.pages;

    let listOfCompanies: ICompany[] = (this.props.user as User).getCompanies();
    listOfCompanies.sort((c1, c2) => {
      let c1Name: string = c1.name?.toLowerCase() ?? "";
      let c2Name: string = c2.name?.toLowerCase() ?? "";
      return c1Name > c2Name
        ? 1
        : c1Name < c2Name
          ? -1
          : 0
    });

    this.state = {
      titolareData: (this.props.user as User).userData,
      listOfCompanies: listOfCompanies,
      isLoading: false,
    }

    this.companyFields = AreaPersonaleCompanyFieldsBuilder();

    this.refPCard = createRef();
    this.currentPage = this.pages.find((p: IPageDescriptor) => p.name === this.props.page?.name);

    this.openCard = this.openCard.bind(this);
    this.showCompany = this.showCompany.bind(this);
    this.newCompany = this.newCompany.bind(this);
    this.updateStateCompanyUpsert = this.updateStateCompanyUpsert.bind(this);

    this.setLoading = this.setLoading.bind(this);
    this.unsetLoading = this.unsetLoading.bind(this);
    this.deleteCompany = this.deleteCompany.bind(this);
  }

  private openCard(parameter: any = {}){
    this.refPCard?.current?.show({...parameter});
  }

  private showCompany(c: ICompany_WithErrors){
    let reqFields : any = this.companyFields;
    let hideFields: string[] = [];
    /*if(p.id === (this.props.user as User).userData.id){
      reqFields.phone = {
        field: "phone",
        label_human_readable: "Cellulare",
        validation:{
          mandatory: true,
          fail_severity: "error",
          fail_msg: "Campo obbligatorio"
        }
      }
    }
    else{
      hideFields = ["phone"];
    }*/

    let parameters = {
      company: c,
      header: <h3 className="colorPrimaryGreen titleLev4">{c !== undefined ? c.name : "Aggiungi una nuova persona"}</h3>,
      editMode: false,
      showControl: true,
      errors: c?.errors ? c.errors : [],
      errorTexts: c?.errorTexts ? c.errorTexts : [],
      requiredFields: reqFields,
      hideFields: hideFields
    }
    
    this.openCard(parameters);
  }

  private newCompany(){

    this.openCard({
      header: <h3 className="colorPrimaryGreen titleLev4">Nuova azienda</h3>,
      editMode: true,
      showControl: false,
      hideFields: ["phone"],
      requiredFields: this.companyFields,
      feedbackDialog: false,
      errors: [],
      errorTexts: []
    })
  }

  updateStateCompanyUpsert(company: ICompany, validation: IValidationResponse){
    if(validation.status){
      (this.props.user as User).setCompany(company);
  
      this.setState({
        listOfCompanies: (this.props.user as User).getCompanies()
      });
    }
  }

  deleteCompany(id: string, callback?: Function){
    this.setState(
      {
        listOfCompanies: this.state.listOfCompanies.filter((company : ICompany) => company.id !== id),
        isLoading: false
      },
      () => {
        (this.props.user as User).removeCompany(id);
        callback?.()
      }
    );
  }

  setLoading(callback?: Function){
    this.setState(
      {isLoading: true},
      () => {callback?.()}
    );
  }

  unsetLoading(callback?: Function){
    this.setState(
      {isLoading: false},
      () => {callback?.()}
    );
  }

  ageValidation(dateString: string) : {dateOfBirth: Date | undefined; error: string | undefined, feedback: IFeedbackText | undefined}{
    
    let dateOfBirth : Date | undefined;
    let error: string | undefined;
    let feedback: IFeedbackText | undefined;
    
    if(dateString){
      if(isLikelyDate(dateString)){
        const [day, month, year] : [day: string, month : string, year : string] = dateString.split("/") as [string, string, string];
        dateOfBirth = new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00Z`);
  
        if(dateOfBirth instanceof Date && !isNaN(dateOfBirth.getTime())){
          const today = new Date();
          const age = today.getFullYear() - dateOfBirth.getFullYear();
    
          if(age > Constants.DOB_YEAR_RANGE_MAX){ // too old
            error = "dateOfBirth";
            feedback = {
              field: "dateOfBirth",
              msg: "Età invalida. Il contraente deve avere meno di 100 anni."
            };
          }
          else{
            error = undefined;
            feedback = undefined;
          }
        }
        else{
          dateOfBirth = undefined;
          error = "dateOfBirth";
          feedback = {field: "dateOfBirth", msg: "Data invalida"};
        }
      }
      else{
        dateOfBirth = undefined;
        error = "dateOfBirth";
        feedback = {field: "dateOfBirth", msg: "Data invalida"};
      }
    }
    else{
      dateOfBirth = undefined;
      error = "dateOfBirth";
      feedback = {field: "dateOfBirth", msg: "Data invalida"};
    }

    return {dateOfBirth: dateOfBirth, error: error, feedback: feedback};
  }

  render(){
    let styleLabel = {
      display: "inline-block",
      color: "#2DACA6",
      cursor: "pointer"
    }
    return <div className="mainBody">
      { this.state.isLoading ? <StepLoaderSpinner title="Caricamento"/> : "" }
      <CompanyCard
        ref={this.refPCard}
        visible={false}
        user={(this.props.user as User)}
        successCallback={this.updateStateCompanyUpsert}
        requiredFields={this.companyFields}
      />

      <div className="d-flex align-items-center">
        <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/gest-persone.svg`} style={{height: "3rem", marginRight: "1rem"}} alt=""/>
        <h1 className="titleLev3 colorPrimaryBlue mb-0 d-block">{this.currentPage?.fullTitle ?? ""}</h1>
      </div>

      <p className="mt-2">L’area B2B di 6sicuro è uno spazio dedicato alla protezione della tua attività, dei lavoratori e del tuo patrimonio. Per iniziare, aggiungi le informazioni dell’azienda che intendi tutelare oppure visualizza tutti i preventivi richiesti.</p>
      <Divider></Divider>
      <div className="mt-4">       

        <Card className="mt-5" title="Companye associate all'account">            
          <div className="cardTitle simpleBorderBottom">
            <h3 className="titleLev4 colorPrimaryBlue mb-3">Le tue aziende</h3>
            <span style={styleLabel} onClick={() => this.newCompany()}>+ Aggiungi nuova azienda</span>
          </div>
          <ul className="peopleList">
            {
              this.state.listOfCompanies.map((company: ICompany, index) =>
                <li key={company.id}>
                  <CompanyRow
                    company={company}
                    setLoading={this.setLoading}
                    unsetLoading={this.unsetLoading}
                    deleteCallback={this.deleteCompany}
                    openCard={this.showCompany}
                  />
                </li>
              )
            }            
          </ul>

        <Divider></Divider>
          {
            this.state.listOfCompanies.length > 0 &&
              <div className="cardTitle">
                <span style={styleLabel} onClick={() => this.newCompany()}>+ Aggiungi nuova azienda</span>
              </div>
          }
        </Card>


      </div>
      
    </div>

    
  }
}

const AreaPersonaleGestioneAziende = (props: IPageProps) => {

  const pages = useContext(PagesContext);
  return <AreaPersonaleGestioneAziendeComponent pages={pages} {...props} />;

};

export default AreaPersonaleGestioneAziende