import { Component, createRef, useContext } from "react";
import { Card } from "primereact/card";
import * as Constants from "./utils/constants";
import { Button } from "primereact/button";
import { AjaxEnrichedAutocompleteObject, IFieldDescriptor, IPerson, Step1Data, Step3Data, UserInfo } from "./utils/common";
import { step1DataFailure, step1DataSuccess, userInfoDataSuccess, step3DataSuccess, step1DataReset } from "../redux/actions";
import { fetchList, fixDirtyBackendErrorString /*, getProgressValue*/, sortJson, formatDateAdform, offsetFirstErrorField
} from "./utils/methods";
import CookieService from "./utils/CookieService";
import RadioButtonList from "./customs/RadioButtonList";
import StepLoaderSpinner from "./StepLoaderSpinner";
import { PersonCard } from "./customs/PersonCard";
import User, { IValidationResponse, sortPeopleFunction } from "./utils/User";
import { ContextFeedback, FeedbackBox, IFeedbackText } from "./utils/Feedback";
import PersonListItemLabel from "./customs/PersonListItemLabel";
import EmptyListItemLabel from "./customs/EmptyListItemLabel";
import { Step1PersonFieldsBuilder } from "./utils/PersonFieldsBuilder";
import { connect } from "react-redux";
import { StepDataDualState } from "../redux/reducers";
import { useHistory } from "react-router-dom";
import Info6Sicuro from "./customs/Info6Sicuro";
import compose from "ramda/es/compose";
import withEventManager from "./customs/HOC/withEventManager";
import { DataList, IDataList } from "./customs/dataListContext";
import { IWithEventManager } from "./customs/HOC/EventManager";

interface IStep1Props extends IWithEventManager, IDataList{
  user: User;
  userInfoDataSuccess: Function;
  step1DataSuccess: Function;
  step1DataFailure: Function;
  step1DataReset: Function;
  step3DataSuccess: Function;
  userInfo: UserInfo;
  fromMailSereno: boolean;
  step1Data: StepDataDualState;
  vehicle: string;
  history: ReturnType<typeof useHistory>;
  trackEvent?: Function;
  setResourceListRequest: Function;
}

interface IStep1State {
  // professionList: any[];
  // degreeList: any[];
  // civilStateList: any[];
  autocompleteCities: any[] | undefined;
  autocompleteCountries: any[] | undefined;
  submitting: boolean;
  errors: string[];
  errorTexts: IFeedbackText[];

  s_id?: any;
  gclid?: any;
  fbclid?: any,
  msclkid?: any;
  ttclid?: any;
  fbp?: any;
  fbc?: any;
  sessionId?: any;
  loading?: boolean;

  listOfPeople: IPerson[] | [];

  utm_id?: any;
  utm_campaign?: any;
  utm_content?: any;
  utm_source?: any;
  utm_medium?: any;
  utm_name?: any;
  utm_term?: any;
}
class Step1LoggedComponent extends Component<IStep1Props, IStep1State> {
  private vehicle: string;
  
  private personCardRef: any;
  private readonly maskedPersonFields : string[] = ["italianDrivingLicense", "drivingLicenseAge", "cityOfResidence", "postalCodeOfResidence", "address", "phone", "email", "children", "youngestChild"];

  private readonly personFields : any;

  constructor(props: any) {
    super(props);

    this.vehicle = this.props.vehicle;

    this.personFields = Step1PersonFieldsBuilder(true);

    // this.maskRef = createRef();
    this.personCardRef = createRef();

    const v_k = this.vehicle as keyof StepDataDualState;
    const step1Data: Step1Data = this.props.step1Data[v_k] as Step1Data;

    const query = new URLSearchParams(window.location.search);
    const utm_id = query.get("utm_id") ?? sessionStorage.getItem("utm_id");
    const utm_campaign = query.get("utm_campaign") ?? sessionStorage.getItem("utm_campaign");
    const utm_content = query.get("utm_content") ?? sessionStorage.getItem("utm_content");
    const utm_source = query.get("utm_source") ?? sessionStorage.getItem("utm_source");
    const utm_medium = query.get("utm_medium") ?? sessionStorage.getItem("utm_medium");
    const utm_name = query.get("utm_name") ?? sessionStorage.getItem("utm_name");
    const utm_term = query.get("utm_term") ?? sessionStorage.getItem("utm_term");
    const gclid = query.get("gclid") ?? sessionStorage.getItem("gclid");
    const fbclid = query.get("fbclid") ?? sessionStorage.getItem("fbclid");
    const msclkid = query.get("msclkid") ?? sessionStorage.getItem("msclkid");
    const ttclid = query.get("ttclid") ?? sessionStorage.getItem("ttclid");
    const s_id = query.get("s_id") ?? sessionStorage.getItem("s_id");

    const fbp = CookieService.getCookie("_fbp");
    const fbc = CookieService.getCookie("_fbp");
    const sessionId = CookieService.getCookie(`richiestapreventivoid${this.vehicle}`)

    const isBackward: boolean = this.props.userInfo?.direction === 'backwards';

    if (!isBackward) {
      this.saveStep1ReduxState({
        gclid: (gclid && gclid !== null) ? gclid : undefined,
        fbclid: (fbclid && fbclid !== null) ? fbclid : undefined,
        msclkid: (msclkid && msclkid !== null) ? msclkid : undefined,
        ttclid: (ttclid && ttclid !== null) ? ttclid : undefined,
        s_id: (s_id && s_id !== null) ? s_id : undefined,
        utm_id: (utm_id && utm_id !== null) ? utm_id : undefined,
        utm_campaign: (utm_campaign && utm_campaign !== null) ? utm_campaign : undefined,
        utm_content: (utm_content && utm_content !== null) ? utm_content : undefined,
        utm_source: (utm_source && utm_source !== null) ? utm_source : undefined,
        utm_medium: (utm_medium && utm_medium !== null) ? utm_medium : undefined,
        utm_name: (utm_name && utm_name !== null) ? utm_name : undefined,
        utm_term: (utm_term && utm_term !== null) ? utm_term : undefined,
        fbp: (fbp && fbp !== null) ? fbp : undefined,
        fbc: (fbc && fbc !== null) ? fbc : undefined,
        sessionId: (sessionId && sessionId !== null) ? sessionId : undefined
      });
    }

    this.state = {
      errors: [],
      errorTexts: [],
      submitting: false,
      autocompleteCities: undefined,
      autocompleteCountries: undefined,
      loading: true,
      listOfPeople: [],
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.saveReduxState = this.saveReduxState.bind(this);
    this.trakSendLoaded = this.trakSendLoaded.bind(this);
    this.submitToNode = this.submitToNode.bind(this);
    
    this.updateStatePersonSave = this.updateStatePersonSave.bind(this);
    this.handlePersonSaveError = this.handlePersonSaveError.bind(this);
    this.personToStep3Contactor = this.personToStep3Contactor.bind(this);
  }

  filterOnlyMaggiorenne(p: IPerson): boolean{
    let show: boolean = true;
    if(p?.dateOfBirth){
      const today = new Date();
      const age = today.getFullYear() - p.dateOfBirth.getFullYear();
      if(age > Constants.DOB_YEAR_RANGE_MIN){
        show = true; // è maggiorenne
      }
      else if(age === Constants.DOB_YEAR_RANGE_MIN){
        if(today.getMonth() < p.dateOfBirth.getMonth()){
          // devi essere maggiorenne
          show = false;
        }
        else if(today.getMonth() > p.dateOfBirth.getMonth()){
          //ok
          show = true;
        }
        else{
          //check giorno
          if(today.getUTCDay() < p.dateOfBirth.getUTCDay()){
            // devi essere maggiorenne
            show = false;
          }
          else{
            // ok
            show = true;
          }
        }
      }
      else{
        show = false;
      }
    }
    return show;
  }

  sortPeople: sortPeopleFunction = (p1, p2) => {
    let p1Name: string = User.getPersonSafeName(p1).toLowerCase();
    let p2Name: string = User.getPersonSafeName(p2).toLowerCase();
    return p1Name > p2Name
      ? 1
      : p1Name < p2Name
        ? -1
        : 0
  }

  enhanceListOfPeopleWithError(people: IPerson[]): Promise<IPerson[]> {

    let peoplePromises : Promise<IPerson>[] = people
        .map(async (p_validated) => {
          return User.validatePerson(
            {...p_validated},
            Object.entries(this.personFields).map(([, value]) => value) as IFieldDescriptor[])
              .then(result => {
                const {error_fields: errors, error_messages: errorTexts} = result;
                const refinedItems = {...p_validated, errors, errorTexts};
                return refinedItems;
              })
        })
  
    return Promise.allSettled(peoplePromises)
      .then((results) => {
        let listOfPeople: IPerson[] = [];
        results.forEach((fieldResponse) => {
          if (fieldResponse.status === "fulfilled") {
            listOfPeople.push(fieldResponse.value);
          }
        })
        return listOfPeople;
      })
  }

  loadResourceFromUser(){
    const listOfPeople: IPerson[] = this.props.user?.getPeople(this.filterOnlyMaggiorenne, true, this.sortPeople) ?? [];
    this.enhanceListOfPeopleWithError(listOfPeople)
      .then((enhancedPeople: IPerson[]) => {
        this.setState({
          listOfPeople: enhancedPeople,
          loading: false
        });
      });
  }

  // componentWillUnmount(): void {
  //   console.log("Step1 logged unmounted");
  // }

  componentDidMount() {
    console.log("Step1 logged mounted");
    // fetchList(Constants.SEISICURO_AJAX_PROFESSION_ENDPOINT, (result: any) => {
    //   result.sort(sortJson);
    //   this.setState({ professionList: result }, this.updateLoadingState);
    // });
    // fetchList(Constants.SEISICURO_AJAX_CIVIL_STATE_ENDPOINT, (result: any) => {
    //   result.sort(sortJson);
    //   this.setState({ civilStateList: result }, this.updateLoadingState);
    // });
    // fetchList(Constants.SEISICURO_AJAX_DEGREE_ENDPOINT, (result: any) => {
    //   result.sort(sortJson);
    //   this.setState({ degreeList: result }, this.updateLoadingState);
    // });


    this.loadResourceFromUser();

    if(!(window as any)?.Cookiebot?.hasResponse){
      window.addEventListener("CookiebotOnAccept", this.trakSendLoaded)
      window.addEventListener("CookiebotOnDecline", this.trakSendLoaded)
    }
    else{
      this.trakSendLoaded();
    }

  }

  componentDidUpdate(prevProps: IStep1Props, prevState: IStep1State){
    !prevProps.user.isEqual(this.props.user) && this.loadResourceFromUser()
  }


  updateStatePersonSave(person: IPerson, validation: IValidationResponse){
    
    if(validation.status){
      const {error_fields: errors, error_messages: errorTexts} = validation;
  
      let updatePeopleList: IPerson[] = [...this.state.listOfPeople.filter((p: IPerson) => p.id !== person.id), {...person, errors, errorTexts} as IPerson];
  
      updatePeopleList.sort(this.sortPeople)
      this.setState({
        listOfPeople: updatePeopleList,
        errors: errors,
        errorTexts: errorTexts
      })
      this.saveReduxState({...person, email: this.props.user.email, phone: this.props.user.phone})
    }
  }

  handlePersonSaveError(sentPerson: IPerson){
    this.props.step1DataReset(this.vehicle)
  }

  render() {
    const contractorData: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;

    return <>
      {!this.state.loading
        ?
          <div id="step-1-form">
            {/* <div className="progress-bar-steps">
              <ProgressBar value={progress}></ProgressBar>
            </div> */}
          
            <PersonCard
              visible={this.state.listOfPeople.length === 0}
              user={this.props.user}
              ref={this.personCardRef}
              successCallback={this.updateStatePersonSave}
              errorCallback={this.handlePersonSaveError}
              hideFields={this.maskedPersonFields}
              requiredFields={this.personFields}
              header={<h3 className="colorPrimaryGreen titleLev4">Inserisci un nuovo contraente</h3>}
              editMode={true}
              showControl={false}
              setResourceListRequest={this.props.setResourceListRequest}
            />
    
            <Card className="pr-form">
              <div className="p-fluid p-4 px-lg-5 py-lg-5">
  
                <div className="row">
                  <div className="col-12">
                    <h1 className="titleLev4 colorPrimaryBlue mb-4">
                      Preventivo Assicurazione{" "}
                      {this.vehicle === "auto" ? "Auto" : "Moto"} online
                    </h1>
                    {this.state.listOfPeople.length > 0
                      ? <h2 className="titleLev6 mt-4">Seleziona il contraente</h2>
                      : <h2 className="titleLev6 mt-4">Inserisci un contraente</h2>
                    }
                  </div>
                </div>

                <div className="row">
                  
                  <FeedbackBox items={this.state.errorTexts}/>

                  <RadioButtonList
                    labelType={{component: PersonListItemLabel }}
                    name="contractor"
                    items={this.state.listOfPeople}
                    itemsAction={{
                      label: "Modifica",
                      attributes: {
                        className: "aLike",
                        onClick: (e: Event) => {
                          const safename = User.getPersonSafeName(this.props.user.getPersonById(contractorData?.contractorId))

                          const contractor : any = this.state.listOfPeople.find((p : IPerson) => p.id === contractorData?.contractorId);
                          const {errors, errorTexts} = contractor

                          this.personCardRef?.current?.show({
                            person: contractor,
                            header: <h3 className="colorPrimaryGreen titleLev4">{safename}</h3>,
                            errors: errors,
                            errorTexts: errorTexts,
                            showControl: false,
                            editMode: true
                          });
                        }
                      },
                    }}
                    value={contractorData?.contractorId}
                    valueField="id"
                    emptyItem={{
                      label: "Inserisci un nuovo contraente",
                      component: EmptyListItemLabel,
                      onClick: (e: Event) => {
                        this.personCardRef?.current?.show({
                          header: <h3 className="colorPrimaryGreen titleLev4">Inserisci un nuovo contraente</h3>,
                          errors: [],
                          errorTexts: [],
                          showControl: false,
                          editMode: true
                        });
                      }
                    }}
                    handleChange={(selected: any) => { this.onChangeHandler(selected, "user"); } }
                  />
                  <ContextFeedback
                    show={this.state.errors.includes("user")}
                    message={
                      this.state.errorTexts.find(elem => elem.field === "user") ??
                      {msg: "Seleziona un contraente", severity: "error"}
                    }
                    setParentClassName={false}
                  />
                </div>
              </div>

              <div className="step-btn-container p-fluid p-4 px-lg-5 py-lg-5">
                <div className="row">
                  <div className="col-12 button-next">
                    <Button
                      className="customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                      type="button"
                      label="Continua"
                      onClick={() => this.handleFormSubmit()}
                      loading={this.state.submitting}
                    />
                  </div>
                </div>
              </div>
            </Card>

            <Info6Sicuro />

          </div>
        : <StepLoaderSpinner title="Caricamento"/>
      }
    </>
  }

  private getDataToTrack(){
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    return {
      prodotto: this.vehicle,
      gender: step1Data.gender,
      dataNascita: dateOfBirth !== undefined
        ? formatDateAdform(dateOfBirth.getTime())
        : undefined,
      titoloStudio: step1Data.degree,
      professione: step1Data.profession,
    }
  }

  /**
   * Trak tag-manager: notifica errore
   */
  private trackSendFailure() {
    this.props.trackEvent?.({
      name: "step1LoggedError",
      data: {
        ...this.getDataToTrack(),
        errors: this.state.errors,
        errorTexts: this.state.errorTexts,
      }
    });
  }

  /**
   * Trak tag-manager: notifica successo
   */
  private trackSendSuccess(callbackFunction?: Function) {
    this.props.trackEvent?.({
      name: "step1LoggedSuccess",
      data: this.getDataToTrack(),
      callback: callbackFunction
    });
  }

  /**
   * Track tag-mamager: notifica pagina caricata
   */
  private trakSendLoaded() {
    console.log("Step1 logged fire loaded event");
    this.props.trackEvent?.({
      name: "step1LoggedLoaded",
      data: this.getDataToTrack()
    });
  }

  /**
   * Handles various state change events related to the form's components.
   *
   * @param {any} selected The selected object
   * @param {string} componentName The name of the component to handle
   */
  private async onChangeHandler(selected: any, componentName: string){
    if (componentName === "user") {
      let pData: IPerson;
      if (selected !== -1) {
        const id : string = selected;
        pData = this.state.listOfPeople.find(p => p.id === id) || {};

        if(pData){
          pData.phone = this.props.user.phone;
          pData.email = this.props.user.email;
        }

        /** 
         * creo una refined list, per poter passare gli eventuali field che non hanno passato la 
         * validazione alla RadioButtonList in modo da segnare l'errore
         * i campi errati verrano usati anche dalla PersonCard per marcare i campi da riempire
         *  */
        
        let refinedListOfPeople : any[] = await User.validatePerson(pData, Object.entries(this.personFields).map(([, value]) => value) as IFieldDescriptor[])
          .then((result: IValidationResponse) => {
            return this.state.listOfPeople.map((refined_person: any) => {
              if(refined_person.id === id){
                refined_person = {...refined_person, errors: result.error_fields, errorTexts: result.error_messages};
              }
              return refined_person;
            });
          })

        if(pData){
          
          this.setState({
            errors: [],
            errorTexts: [],
            listOfPeople: refinedListOfPeople
          })
        }
      }
      else {
        pData = {
          name: undefined,
          surname: undefined,
          gender: undefined,
          dateOfBirth: new Date(""),
          civilState: undefined,
          degree: undefined,
          countryOfBirth: undefined,
          cityOfBirth: undefined,
          profession: undefined,
          bornInItaly: undefined,
          id: selected
        };
          
        this.setState({
          errors: [],
          errorTexts: []
        })
      }

      this.saveReduxState(pData);
    }

  }

  /**
   * Form validation.
   *
   * @param specificField whether to check validity of a specific field
   * @returns {Promise} Validation check
   */
  private validation(specificField: undefined | string = undefined) : Promise<IValidationResponse>{

    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }
    
    const person : IPerson = {
      id: step1Data.contractorId,
      name: step1Data.name,
      surname: step1Data.surname,
      gender: step1Data.gender,
      dateOfBirth: dateOfBirth,
      civilState: step1Data.civilState,
      degree: step1Data.degree,
      countryOfBirth: step1Data.countryOfBirth,
      cityOfBirth: step1Data.cityOfBirth,
      profession: step1Data.profession,
      bornInItaly: step1Data.bornInItaly,
      email: step1Data.email,
      phone: step1Data.phone,
    }

    let fieldToValidate : IFieldDescriptor[] = [];

    if(specificField !== undefined){
      fieldToValidate = this.personFields[specificField] ? [this.personFields[specificField]] : [];
    }
    else{
      fieldToValidate = Object.entries(this.personFields).map(([, value]) => value) as IFieldDescriptor[]
    }

    let result: {
      status: boolean;
      error_fields: string[];
      error_messages: IFeedbackText[];
    } = {
      status: true,
      error_fields: [],
      error_messages: []
    };

    if(step1Data.contractorId === undefined){
      result.status = false;
      result.error_fields = ["user"];
      result.error_messages = [{field: "user", msg: "Seleziona un contraente o inseriscine uno nuovo"}];
      return Promise.resolve(result);
    }
    else{
      return Promise
      .resolve(result)
      .then((result: {status: boolean; error_fields: string[]; error_messages: IFeedbackText[]}) => {

        if(!result.status){
          return result;
        }
        else{
          return User.validatePerson(person, fieldToValidate)
           .then((result: {
             status: boolean;
             error_fields: string[];
             error_messages: IFeedbackText[];
           }) => {
             let {status, error_fields, error_messages} = result;
             if(!status){
     
               // move feedback into generic
                let wrappedTextError : string[] = [];
                if(!status){
      
                  error_fields.forEach((errorfield : string) => {
                    const extistError : boolean = error_messages?.some((feedback: IFeedbackText) => feedback.field === errorfield && feedback?.msg) ?? false;
      
                    if(extistError){
                      wrappedTextError.push(error_messages
                        .filter((feedback: IFeedbackText) => feedback.field === errorfield && feedback?.msg)
                        .map((feedback: IFeedbackText) => feedback.msg)
                        .join(";"))
                    }
                    else{
                      wrappedTextError.push(errorfield);
                    }
                  })
                  
                  error_fields.push("user");
                  error_messages.push({
                    field: "user",
                    msg: "Assicurati di aver fornito tutti i dati necessari"
                  })
                }
     
               this.setState({errors: error_fields, errorTexts: error_messages})
     
             }
             return result;
           });
        }

      })
    }
  }

  /**
   * Reset form element validation
   * @param element
   * @returns
   */
  resetValidation(element: any, callback?: Function) {
    this.setState({
      errors: this.state.errors.filter((item: string) => item !== element),
      errorTexts: this.state.errorTexts.filter((item: IFeedbackText) => item.field !== element),
    }, () => callback?.());
    return;
  }

  /**
   * Check and append state data to form
   *
   * @param {any} formData The form data
   * @param {string } key The data key
   * @param {any} stateVariable The state variable
   */
  private formDataAppend(formData: any, key: string, stateVariable: any) {
    if (stateVariable !== undefined) {
      formData.append(key, `${stateVariable}`);
      //console.log(formData);
    }
  }

  private IPersonToStep1Data(p: IPerson): Step1Data{

    const id = p.id;
    delete p.id;


    const converted: Step1Data = {
      ...p,
      contractorId: id, // convert id key into contractorId
      dateOfBirth: p.dateOfBirth !== undefined && p.dateOfBirth !== null && !isNaN((p.dateOfBirth as Date)?.getTime())
          ? (p.dateOfBirth as Date).getTime()
          : undefined,

      degreeLabel: this.props.degreeList.find((element: AjaxEnrichedAutocompleteObject) => element.value === p.degree)?.label,
      professionLabel: this.props.professionList.find((element: AjaxEnrichedAutocompleteObject) => element.value === p.profession)?.label,
    };

    return converted;
  }

  private personToStep3Contactor(p: IPerson): Step3Data{
    let step3Contactor: Step3Data = {}

    p?.name && (step3Contactor.contractorName = p.name);
    p?.surname && (step3Contactor.contractorSurname = p.surname);
    p?.cityOfResidence && (step3Contactor.contractorCityOfResidence = p.cityOfResidence);
    p?.postalCodeOfResidence && (step3Contactor.contractorPostalCode = p.postalCodeOfResidence);
    p?.address && (step3Contactor.contractorAddress = p.address);
    p?.addressToponimo && (step3Contactor.contractorAddressToponimo = p.addressToponimo);
    p?.addressNumber && (step3Contactor.contractorAddressNumber = p.addressNumber);
    p?.children && (step3Contactor.contractorChildren = p.children);
    p?.youngestChild && (step3Contactor.contractorYoungestChild = (p.youngestChild as number | string));
    p?.email && (step3Contactor.contractorEmail = p.email);
    p?.phone && (step3Contactor.contractorPhoneNumber = p.phone);
    p?.italianDrivingLicense && (step3Contactor.contractorItalianDrivingLicense = p.italianDrivingLicense);
    p?.drivingLicenseAge && (step3Contactor.contractorDrivingLicenseAge = p.drivingLicenseAge);

    return step3Contactor;
  }

  /**
   * Saves the current internal component state to Redux.
   */
  private saveReduxState(p: IPerson) {
    this.props.step1DataSuccess(this.IPersonToStep1Data(p), this.vehicle);
    this.props.step3DataSuccess(this.personToStep3Contactor(p), this.vehicle);
  }

  private saveStep1ReduxState(bodyData: Step1Data) {
    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    this.props.step1DataSuccess({ ...step1Data, ...bodyData }, this.vehicle);
  }

  private submitToNode() {

    const step1Data: Step1Data = this.props.step1Data[this.vehicle as keyof StepDataDualState] as Step1Data;
    let dateOfBirth: Date | undefined = undefined;
    if(step1Data?.dateOfBirth){
      dateOfBirth = new Date(step1Data.dateOfBirth);
    }

    let formData = new URLSearchParams();
    formData.append("vehicle", this.vehicle);
    formData.append("step", "1");
    // TODO: Il vecchio preventivatore setta i prossimi tre campi a stringa vuota, verificare se sono effettivamente necessari
    formData.append("contraente+partitaIva", "");
    formData.append("contraente+denominazioneSociale", "");
    formData.append("contraente+settoreMerceologico", "");
    this.formDataAppend(formData, "contraente+sesso", step1Data.gender);
    if (dateOfBirth) {
      formData.append("contraente+dataDiNascita+giorno", dateOfBirth.getDate().toString());
      formData.append("contraente+dataDiNascita+mese", dateOfBirth.toLocaleString("it-IT", {month: "long",}));
      formData.append("contraente+dataDiNascita+anno", dateOfBirth.getFullYear().toString());
    }

    formData.append("idUserAP", this.props.user.id.toString());
    if(step1Data?.contractorId){
      formData.append("idContraenteAP", step1Data.contractorId.toString());
    }

    formData.append("contraente+natoInItalia", `${step1Data.bornInItaly}`);
    if (step1Data.bornInItaly) {
      formData.append("contraente+luogoDiNascitaItalia+id", `${step1Data.cityOfBirth?.id}`);
    } else {
      formData.append("contraente+luogoDiNascitaEstero+id", `${step1Data.countryOfBirth?.id}`);
    }
    this.formDataAppend(formData, "contraente+statoCivile", step1Data.civilState);
    this.formDataAppend(formData, "contraente+titoloDiStudio", step1Data.degree);
    this.formDataAppend(formData, "contraente+professione", step1Data.profession);

    // tracciamenti
    if (step1Data.utm_campaign) {
      formData.append("utm_campaign", `${step1Data.utm_campaign}`);
    }
    if (step1Data.utm_content) {
      formData.append("utm_content", `${step1Data.utm_content}`);
    }
    if (step1Data.utm_source) {
      formData.append("utm_source", `${step1Data.utm_source}`);
    }
    if (step1Data.utm_medium) {
      formData.append("utm_medium", `${step1Data.utm_medium}`);
    }
    if (step1Data.utm_name) {
      formData.append("utm_name", `${step1Data.utm_name}`);
    }
    if (step1Data.utm_term) {
      formData.append("utm_term", `${step1Data.utm_term}`);
    }
    if (step1Data.utm_id) {
      formData.append("utm_id", `${step1Data.utm_id}`);
    }
    if (step1Data.gclid) {
      formData.append("gclid", `${step1Data.gclid}`);
    }
    if (step1Data.fbclid) {
      formData.append("fbclid", `${step1Data.fbclid}`);
    }
    if (step1Data.msclkid) {
      formData.append("msclkid", `${step1Data.msclkid}`);
    }
    if (step1Data.ttclid) {
      formData.append("ttclid", `${step1Data.ttclid}`);
    }

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.append('Content-Type', 'application/x-www-form-urlencoded; charset="utf-8"');

    fetch(Constants.SEISICURO_FORM_POST_ENDPOINT, {
      method: "post",
      credentials: "include",
      body: formData,
      headers: requestHeaders,
      // headers: {
      //   "Content-Type": 'application/x-www-form-urlencoded; charset="utf-8"' /** AUTH_REDACTED */,
      // },
    })
      .then((response) => {
        if (response.status === 400) {
          response.text().then((responseText) => {
            this.setState({ errors: [responseText] }, () => {
              this.trackSendFailure();
            });
          });
        }
        else if (response.ok) { 
          this.props.step1DataSuccess({ step1Ok: true }, this.vehicle);

          //console.log(response);
          fetch(Constants.SEISICURO_STEP_CHECK(1), {
            credentials: "include",
            headers: {
              /** AUTH_REDACTED */
            },
          })
            .then((response) => {
              response.json().then((responseJson) => {
                if (Object.keys(responseJson[0]).length > 0) {
                  this.setState(
                    {
                      errorTexts: [{
                        field: "generic",
                        msg: Object.keys(responseJson[0])
                          .map((element) => fixDirtyBackendErrorString(responseJson[0][element]))
                          .join("<\br>")
                      }],
                      submitting: false,
                    },
                    () => {
                      this.trackSendFailure();
                      window.scrollTo(0, offsetFirstErrorField());
                    }
                  );
                }
                else {
                  this.trackSendSuccess(() => this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(2, this.vehicle)));
                }
              });
            });
        }
        else {
          //console.log(response);
          this.setState(
            { submitting: false },
            () => {
              this.trackSendFailure();
              this.props.step1DataFailure(this.vehicle);
            }
          );
        }
      })
      .catch((error) => {
        //console.log(error);
        this.setState(
          { submitting: false },
          () => {
            this.trackSendFailure();
            this.props.step1DataFailure(this.vehicle);
          })
      });
  }

  /**
   * Form submit.
   *
   */
  private handleFormSubmit() {

    this.props.userInfoDataSuccess({ direction: "forwards", nextStep: 2 }, this.vehicle);

    this.setState({submitting: true}, () => {

      this.validation()
        .then((result: any) => {
          const {status, error_fields, error_messages} = result;
          if(status){
            this.submitToNode();
          }
          else{
            this.trackSendFailure()
            this.setState({
              submitting: false,
              errors: error_fields,
              errorTexts: error_messages ?? []
            }, () => {
              window.scrollTo(0, offsetFirstErrorField());
            });
          }
        })
    })

  }

}

const mapState = (state: any) => {
  return {
    loginVisible: state.userInfoData.user.openLogin,
    userInfo: state.userInfoData.user,
    step1Data: state.step1Data,
    fromMailSereno: state.step4Data.fromMailSereno
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    step1DataSuccess: (payload: any, vehicle: string) => dispatch(step1DataSuccess(payload, vehicle)),
    step1DataFailure: (vehicle: string) => dispatch(step1DataFailure(vehicle)),
    step1DataReset: (vehicle: string) => dispatch(step1DataReset(vehicle)),
    step3DataSuccess: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle))
  }
};

// Required to access react-router's params (i.e. ":vehicle" in App.tsx).
const Step1Logged = (props: any) => {
  const history: ReturnType<typeof useHistory> = useHistory();
  const lists: IDataList = useContext(DataList);
  
  return <Step1LoggedComponent {...props} history={history} {...lists}/>
};

// const Step1WithEvent = withEventManager(Step1Logged);

// export default connect(mapState, mapDispatch)(Step1WithEvent);
export default compose(connect(mapState, mapDispatch), withEventManager)(Step1Logged);
