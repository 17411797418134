import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userInfoDataSuccess } from '../../redux/actions';
import * as Constants from '../utils/constants';

interface IFooterProps{
  telClick: Function
}

const Footer = (props: IFooterProps) => {

  return <div className="pr-footer-bottom pt-5">
    <div className="container pb-4">
      <div className='row justify-content-center'>
        <div className='col-xl-9 col-11'>
          <div className='row text-center'>
            <div className='col-xl-3 col-12 mb-4'>
              <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/logo-footer.svg`} alt="6 Sicuro"/>
            </div>
            <div className='col-xl-6 col-12'>
              {/*<p>Società a socio univoco soggetta all'attività di direzione <br/> e coordinamento di Howden Assiteca S.p.A.</p>*/}
              <Link className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite mb-3" to="/preventivi/area-personale">Area personale</Link>
            </div>
            <div className='col-xl-3 col-12'>
              <a className='contact-phone borderPrimaryGreen' href="tel:+390281480350" onClick={(e) => props.telClick(e)}>
                Contatta un consulente
                <span className='mt-1'><i className="pi pi-phone"></i> 0281480350</span>
              </a>
              <div className='contact-mail mt-2'>
                oppure <a href="mailto:info@6sicuro.it">Scrivici</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='inner-bottom py-4'>
      <div className="container">
        <div className='row justify-content-center'>
          <div className='col-xl-10 col-11'>
            <p className="pr-footer-text">6sicuro.it è un brand di Howden Assiteca S.p.A. a Socio Unico<br/>
            Iscritta al Registro Unico degli Intermediari Assicurativi e Riassicurativi - Sezione B - nr. 114899 del 16/04/2007<br/>
            Capitale sociale € 7.617.193,51 i.v. – C.F./P.IVA/REA IT09743130156 - Sede Legale: Via Costanza Arconati 1, 20135 Milano MI - Tel. <a href="tel:+390281480350">02.81480350</a></p>
            <div className="pr-footer-text-menu mt-3">
              <a className="pr-footer-text" href="https://www.6sicuro.it/privacy" title="Privacy" rel="nofollow">Privacy</a><span> | </span>
              <a className="pr-footer-text" href="https://www.6sicuro.it/assicurazioni/informativa-precontrattuale" title="Informativa Precontrattuale" rel="nofollow">Informativa precontrattuale</a><span> | </span>
              <a className="pr-footer-text" href="https://www.6sicuro.it/condizioni-generali-di-utilizzo" title="Condizioni Generali" rel="nofollow">Condizioni generali</a><span> | </span>
              <a className="pr-footer-text" href="https://www.6sicuro.it/calcolo-risparmio" title="Risparmio Assicurazioni">Scopri come calcoliamo il risparmio</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

}

export default Footer;