import { Component } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Divider } from "primereact/divider";
import RadioButtonListItemTextInfo from "./RadioButtonListItemTextInfo";

export default class RadioButtonListItem extends Component<{
  item: any | undefined;
  name: string;
  value?: string | number;
  idPrefix?: string;
  valueField?: string;
  handleChange?: any;
  labelType: {
    type?: string|undefined;
    component?: (props: any) => JSX.Element;
    extra?: any
  };
  action?: {           // action of item in items
    label: string,
    attributes?: any,
  };
  addDivider?: boolean
},
{}>{
  private idPrefix?: string;
  private name: string;
  private valueField: string;
  private labelType: {
    type?: string|undefined;
    component?: (props: any) => JSX.Element;
    extra?: any
  };
  private addDivider: boolean;
  private abortController: AbortController = new AbortController();
  
  constructor(props: any) {
    super(props);

    this.name = props.name;
    this.idPrefix = props?.idPrefix || this.name;
    this.valueField = props?.valueField || "value";
    this.labelType = props.labelType;
        
    this.handleChange = this.handleChange.bind(this);
    
    this.addDivider = this.props?.addDivider || false;
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  handleChange(e: any) {
    this.props.handleChange(e.target.value);
  }

  render(){

    const item = this.props.item;

    let textInfoProps : {
      id: string,
      type: string,
      component?: (props: any) => JSX.Element;
      data: any,
      action?: any,
      onClick?: any,
      selected: boolean
    }

    textInfoProps = {
      id: `${this.idPrefix}-${item?.id}`,
      type: this.labelType?.type || "",
      data: item,
      selected: this.props.value === item[this.valueField]
    }

    if(this.labelType?.component){
      textInfoProps.component = this.labelType.component;
    }

    if(this.labelType.type !== "empty" && this.props?.action){
      textInfoProps.action = this.props.action;
    }

    return <li className="listItem">
      <div className="radioBox">
        <RadioButton
          inputId={`${this.idPrefix}-${item[this.valueField]}`}
          value={item[this.valueField]}
          name={this.name}
          checked={this.props.value === item[this.valueField]}
          onChange={(e) => this.handleChange(e)}
        />
      </div>
      <div className="textBox">
        <RadioButtonListItemTextInfo {...textInfoProps}/>
      </div>
      
      {this.addDivider &&
        <Divider></Divider>
      }
    </li>
  }

}