import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { userInfoDataSuccess } from "../redux/actions";
import { FeedbackBox, IFeedbackText } from "./utils/Feedback";
import User from "./utils/User";
import { AccountNotActivatedException, PasswordException } from "./utils/UserService";

interface LoginPageProps{
  resetRedirectPage: Function;
  closeLoginPanel: Function;
  redirectPage: string;
  history: ReturnType<typeof useHistory>;
  userInfoDataSuccess: Function;
}

interface LoginPageState{
  errors: string[];
  errorTexts: IFeedbackText[];
  email: string;
  password: string;
  loading: boolean;
  redirectPage: string;
}
class LoginPageComponent extends Component<LoginPageProps, LoginPageState> {

  constructor(props: any) {
    super(props);

    this.state = {
      errors: [],
      errorTexts: [],
      email: "",
      password: "",
      loading: false,
      redirectPage: this.props.redirectPage
    };
    

    this.onLogin = this.onLogin.bind(this);
  }

  componentDidMount(): void {
    this.props.resetRedirectPage();
    this.props.closeLoginPanel();
  }

  render() {

    return User.isAlive()
    ? <Redirect to="/preventivi/area-personale" />
    : <div className="pr-body">
        <div className="container-fluid">

          <div className="row justify-content-center">
            <div className="col-xxl-4 col-xl-6 col-lg-8 col-12"> 

              <Card className="pr-form">
                <div id="loginPanel" className="p-fluid p-xl-5 p-3 mt-4">

                  <h1 className="titleLev4 colorPrimaryBlue">Accedi alla area tua personale</h1>
                  <p className="mt-lg-4 mt-2">Ecco la tua area privata!</p>

                  <FeedbackBox items={this.state.errorTexts}/>

                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="emailLoginPage">E-mail</label>
                      <InputText
                        id="emailLoginPage"
                        placeholder="E-mail"
                        name="email"
                        autoComplete="email"
                        type="email"
                        value={this.state.email}
                        onChange={(evt) => this.setState({email: evt.target.value})}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="mt-2" htmlFor="passLoginPage">Password</label>
                      <Password
                        id="passLoginPage"
                        placeholder="Inserisci password"
                        name="password"
                        feedback={false}
                        toggleMask
                        value={this.state.password}
                        onChange={(evt) => this.setState({password: evt.target.value})}
                      />
                      <div className="mt-3">
                        <Link className="aLike" to="/preventivi/area-personale/password-dimenticata">Hai dimenticato la password?</Link>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <Button
                        label="Accedi"
                        className="customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite"
                        onClick={() => this.onLogin() }
                        loading={this.state.loading}
                      />
                    </div>
                  </div>

                  {false && <Divider className="my-5" align="center">
                    <div className="inline-flex align-items-center">
                      <b className="colorPrimaryBlue">oppure</b>
                    </div>
                  </Divider>}

                  <div className="row">
                    <div className="col-12 text-center">
                      {false && <div className="text-center">
                        <Button className="customBtn bgColorGrey colorPrimaryBlue hoverBgColorDarkgreen hoverColorWhite">
                          Accedi con Facebook
                        </Button>
                        <br />
                        <Button className="customBtn bgColorGrey colorPrimaryBlue hoverBgColorDarkgreen hoverColorWhite mt-2">
                          Accedi con Google
                        </Button>
                      </div>}
                      <div className="mt-4">
                        <Link to={{pathname: "https://www.6sicuro.it/nostri-contatti/"}} target="_blank" className="aLike">Hai bisogno di aiuto? Contattaci</Link>
                      </div>
                    </div>
                  </div>

                  <Divider className="my-lg-5 my-3"></Divider>

                  <div className="row">
                    <div className="col-12 text-center">
                      <p>Non hai ancora un account? <Link to="/preventivi/area-personale/registrazione" className='aLike'>Registrati</Link></p>
                    </div>
                  </div>

                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
  }

  /**
   * Gestione click sul bottone di login
   * (uso una funzione per poter gestire eventuali traking)
   */
  private onLogin() {
    this.setState({loading: true}, () => {
      User.login(
        this.state.email,
        this.state.password
      )
        .then((user: User) => {
          this.props.userInfoDataSuccess({
            logged: true,
            openLogin: false,
            openRegistration: false,
            userData: JSON.stringify(user),
            skipEmailCheck: undefined
          });
          
          if(this.state.redirectPage){
            this.props.history.push(this.state.redirectPage)
          }
          else{
            this.props.history.push("/preventivi/area-personale")
          }          
        })
        .catch((err: any) => {
          if(err instanceof PasswordException || err instanceof AccountNotActivatedException){
            this.setState({loading: false, errorTexts: [err.getData()]});
          }
          else{
            this.setState({loading: false, errorTexts: [{field: "generic", msg: "Impossibile completare l'operazione"}]});
          }
        })
    })
  }
}

const mapState = (state: any) => {
  return {
    redirectPage: state.userInfoData.user.urlIntentPage
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    resetRedirectPage: () => dispatch(userInfoDataSuccess({urlIntentPage: undefined})),
    closeLoginPanel: () => dispatch(userInfoDataSuccess({openLogin: false})),
  }
};

const LoginPage = (props: any) => {

  const history: ReturnType<typeof useHistory> = useHistory();
  
  return <LoginPageComponent {...props} history={history} />
}

export default connect(mapState, mapDispatch)(LoginPage);