import { Component} from 'react';
import { useParams } from 'react-router-dom';
import * as Constants from './utils/constants';
import { fetchList} from './utils/methods';
import { Card } from 'primereact/card';

class NoteInformativeComponent extends Component<{}, {
    noteAutoList: any[], noteMotoList: any[]
}> {

    constructor(props: any) {
        super(props);

        this.state = {
            noteAutoList: [], noteMotoList: []
        }
    }

    componentDidMount() {
        fetchList(Constants.SEISICURO_NOTE, (result: any) => {
            if (result && result.auto && result.moto) {
                this.setState({
                    noteAutoList: result.auto.sort(this.noteSort),
                    noteMotoList: result.moto.sort(this.noteSort)
                });
            }
        });
    }

    render() {
        return (
            <div className="container" id="note-informative">
                <Card className="row pr-form" title="Set Informativi">
                    <div className="p-fluid">
                        <div className="row">
                            <div className='col-sm-12 col-md-6'>
                                <h2 className="title">Auto</h2>
                                <ul>
                                    {this.state.noteAutoList.map((elem, index) => {
                                        return <li key={index}>
                                            <a href={elem.LINK_NOTA_INFORMATIVA} target="_blank" rel="noreferrer">{elem.ASS_NAME}</a>
                                        </li>
                                    })}
                                </ul>
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <h2 className="title">Moto</h2>
                                <ul>
                                    {this.state.noteMotoList.map((elem, index) => {
                                        return <li key={index}>
                                            <a href={elem.LINK_NOTA_INFORMATIVA} target="_blank" rel="noreferrer">{elem.ASS_NAME}</a>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

    /**
     * Ordina la lista delle note informative
     * @param a oggetto da confrontare
     * @param b oggetto da confrontare
     * @returns -1, 0, 1 in base al campo ASS_NAME
     */
    private noteSort (a: any, b: any) {
        let av = a.ASS_NAME.toLowerCase();
        let bv = b.ASS_NAME.toLowerCase();
        if (av < bv) {
            return -1;
        }
        if (av > bv) {
            return 1;
        }
        return 0;
    }

}

const NoteInformative = (props: any) => (
    <NoteInformativeComponent
        {...props}
        params={useParams()}
    />
);

export default NoteInformative;