import { Step1Data, Step2Data, Step3Data, Step4Data, UserInfo, StorageInfo } from "../components/utils/common";

export const STEP1_DATA_SUCCESS = "STEP1_DATA_SUCCESS";
export const STEP1_DATA_FAILURE = "STEP1_DATA_FAILURE";
export const STEP1_DATA_RESET = "STEP1_DATA_RESET";
export const STEP2_DATA_SUCCESS = "STEP2_DATA_SUCCESS";
export const STEP2_DATA_FAILURE = "STEP2_DATA_FAILURE";
export const STEP2_DATA_RESET = "STEP2_DATA_RESET";
export const STEP3_DATA_SUCCESS = "STEP3_DATA_SUCCESS";
export const STEP3_DATA_FAILURE = "STEP3_DATA_FAILURE";
export const STEP3_DATA_RESET = "STEP3_DATA_RESET";
export const STEP4_DATA_SUCCESS = "STEP4_DATA_SUCCESS";
export const STEP4_DATA_RESET = "STEP4_DATA_RESET";
export const STEP5_DATA_SUCCESS = "STEP5_DATA_SUCCESS";
export const USERINFO_SUCCESS = "USERINFO_SUCCESS";
export const USERINFO_FAILURE = "USERINFO_FAILURE";
export const USERINFO_RESET = "USERINFO_RESET";
export const STEP_DATA_RESET = "STEP_DATA_RESET";

export function userInfoDataSuccess(payload: UserInfo | StorageInfo, vehicle?: string) {
    return { type: USERINFO_SUCCESS, payload };
};

export function step1DataSuccess(payload: Step1Data | StorageInfo, vehicle: string) {
    return { type: STEP1_DATA_SUCCESS, vehicle: vehicle, payload };
};

export function step1DataFailure(vehicle: string) {
    return { type: STEP1_DATA_FAILURE, vehicle: vehicle };
};

export function step1DataReset(vehicle?: string) {
    return { type: STEP1_DATA_RESET, vehicle: vehicle };
};

export function step2DataSuccess(payload: Step2Data | StorageInfo, vehicle: string) {
    return { type: STEP2_DATA_SUCCESS, vehicle: vehicle, payload: {...payload, vehicleType: vehicle} };
};

export function step2DataFailure(vehicle: string) {
    return { type: STEP2_DATA_FAILURE, vehicle: vehicle };
};

export function step2DataReset(vehicle?: string) {
    return { type: STEP2_DATA_RESET, vehicle: vehicle };
};

export function step3DataSuccess(payload: Step3Data | StorageInfo, vehicle: string) {
    return { type: STEP3_DATA_SUCCESS, vehicle: vehicle, payload };
};

export function step3DataFailure(vehicle: string) {
    return { type: STEP3_DATA_FAILURE, vehicle: vehicle };
};

export function step3DataReset(vehicle?: string) {
    return { type: STEP3_DATA_RESET, vehicle: vehicle };
};

export function step4DataSuccess(payload: Step4Data | StorageInfo, vehicle: string) {
    return { type: STEP4_DATA_SUCCESS, vehicle: vehicle, payload };
};

export function step4DataReset(vehicle?: string) {
    return { type: STEP4_DATA_RESET, vehicle: vehicle };
};

export function stepDataReset(vehicle?: string){
    return { type: STEP_DATA_RESET, vehicle: vehicle };
};