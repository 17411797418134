import { IFieldDescriptor } from "./common";
import User from "./User";

const Step1PersonFieldsBuilder = (logged: boolean) => {
  let personFields: any = {
    gender: {
      field: "gender",
      label_human_readable: "Sesso",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    dateOfBirth: {
      field: "dateOfBirth",
      label_human_readable: "Data di nascita",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    bornInItaly: {
      field: "bornInItaly",
      label_human_readable: "Paese di nascita",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    cityOfBirth: {
      field: "cityOfBirth",
      label_human_readable: "Comune di nascita",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    countryOfBirth: {
      field: "countryOfBirth",
      label_human_readable: "Stato di nascita",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    civilState: {
      field: "civilState",
      label_human_readable: "Stato civile",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    degree: {
      field: "degree",
      label_human_readable: "Titolo di studio",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
    profession: {
      field: "profession",
      label_human_readable: "Professione",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    },
  };

  if(logged){
    personFields.name = {
      field: "name",
      label_human_readable: "Nome",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Inserisci il nome del contraente"
      }
    };
    personFields.surname = {
      field: "surname",
      label_human_readable: "Cognome",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Inserisci il cognome del contraente"
      }
    };
  }
  else{
    personFields.phone = {
      field: "phone",
      label_human_readable: "Cellulare",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    };
    personFields.email = {
      field: "email",
      label_human_readable: "E-mail",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Campo obbligatorio"
      }
    }
  }

  return personFields;
}

const Step3ContractorBuilder = () => {
  let fields = PersonFieldsBuilder();

  delete fields.gender;
  delete fields.dateOfBirth;
  delete fields.bornInItaly;
  delete fields.countryOfBirth;
  delete fields.cityOfBirth;
  delete fields.civilState;
  delete fields.degree;
  delete fields.profession;
  delete fields.validDateOfBirthLabel;

  return fields;
}

const Step3OwnerFieldsBuilder = () => {
  let fields = PersonFieldsBuilder();
  // delete fields.degree;
  // delete fields.profession;
  delete fields.validDateOfBirthLabel;
  delete fields.email;
  delete fields.phone;
  delete fields.youngestChild;

  return fields;
}

const Step3DriverFieldsBuilder = () => {
  let fields = PersonFieldsBuilder();
  // delete fields.degree;
  // delete fields.profession;
  delete fields.validDateOfBirthLabel;
  delete fields.email;
  delete fields.phone;
  delete fields.youngestChild;

  return fields;
}

const Step3OwnerDriverFieldsBuilder = () => {
  return Step3OwnerFieldsBuilder()
}

const PersonFieldsBuilder = () => {

  let personFields: any = {
    gender: {
      field: "gender",
      label_human_readable: "Sesso",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    dateOfBirth: {
      field: "dateOfBirth",
      label_human_readable: "Data di nascita",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    bornInItaly: {
      field: "bornInItaly",
      label_human_readable: "Paese di nascita",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    cityOfBirth: {
      field: "cityOfBirth",
      label_human_readable: "Comune di nascita",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    countryOfBirth: {
      field: "countryOfBirth",
      label_human_readable: "Stato di nascita",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    civilState: {
      field: "civilState",
      label_human_readable: "Stato civile",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    degree: {
      field: "degree",
      label_human_readable: "Titolo di studio",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    profession: {
      field: "profession",
      label_human_readable: "Professione",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    name: {
      field: "name",
      label_human_readable: "Nome",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito o invalido"
      }
    },
    surname: {
      field: "surname",
      label_human_readable: "Cognome",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito o invalido"
      }
    },
    italianDrivingLicense: {
      field: "italianDrivingLicense",
      label_human_readable: "Hai una patente italiana",
      notification_label: "Nazionalità della patente",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    drivingLicenseAge: {
      field: "drivingLicenseAge",
      label_human_readable: "A che età hai preso la patente",
      notification_label: "Età conseguimento della patente",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    address: {
      field: "address",
      label_human_readable: "Indirizzo",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    addressNumber: {
      field: "addressNumber",
      label_human_readable: "Civico",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    cityOfResidence: {
      field: "cityOfResidence",
      label_human_readable: "Comune di residenza",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    postalCodeOfResidence: {
      field: "postalCodeOfResidence",
      label_human_readable: "CAP",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    children: {
      field: "children",
      label_human_readable: "Figli",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    },
    youngestChild: {
      field: "youngestChild",
      label_human_readable: "Età del figlio più piccolo",
      validation:{
        mandatory: true,
        fail_severity: "error",
        fail_msg: "Dato non fornito"
      }
    }
  };

    // phone = {
    //   field: "phone",
    //   label_human_readable: "Cellulare",
    //   validation:{
    //     mandatory: true,
    //     fail_severity: "error",
    //     fail_msg: "Campo obbligatorio"
    //   }
    // },
    // email = {
    //   field: "email",
    //   label_human_readable: "E-mail",
    //   validation:{
    //     mandatory: true,
    //     fail_severity: "error",
    //     fail_msg: "Campo obbligatorio"
    //   }
    // }

  return personFields;
}

const AreaPersonalePersonFieldsBuilder = () => {

  let personFields = PersonFieldsBuilder();

  personFields.gender.validation.mandatory = false;
  personFields.gender.validation.fail_severity = "warning";
  personFields.dateOfBirth.validation.mandatory = false;
  personFields.dateOfBirth.validation.fail_severity = "warning";
  personFields.bornInItaly.validation.mandatory = false;
  personFields.bornInItaly.validation.fail_severity = "warning";
  personFields.cityOfBirth.validation.mandatory = false;
  personFields.cityOfBirth.validation.fail_severity = "warning";
  personFields.countryOfBirth.validation.mandatory = false;
  personFields.countryOfBirth.validation.fail_severity = "warning";
  personFields.civilState.validation.mandatory = false;
  personFields.civilState.validation.fail_severity = "warning";
  personFields.degree.validation.mandatory = false;
  personFields.degree.validation.fail_severity = "warning";
  personFields.profession.validation.mandatory = false;
  personFields.profession.validation.fail_severity = "warning";
  // personFields.name.validation.mandatory = false;
  // personFields.name.validation.fail_severity = "warning";
  // personFields.surname.validation.mandatory = false;
  // personFields.surname.validation.fail_severity = "warning";
  personFields.italianDrivingLicense.validation.mandatory = false;
  personFields.italianDrivingLicense.validation.fail_severity = "warning";
  personFields.drivingLicenseAge.validation.mandatory = false;
  personFields.drivingLicenseAge.validation.fail_severity = "warning";
  personFields.addressNumber.validation.mandatory = false;
  personFields.addressNumber.validation.fail_severity = "warning";
  personFields.address.validation.mandatory = false;
  personFields.address.validation.fail_severity = "warning";
  personFields.cityOfResidence.validation.mandatory = false;
  personFields.cityOfResidence.validation.fail_severity = "warning";
  personFields.postalCodeOfResidence.validation.mandatory = false;
  personFields.postalCodeOfResidence.validation.fail_severity = "warning";
  personFields.children.validation.mandatory = false;
  personFields.children.validation.fail_severity = "warning";
  personFields.youngestChild.validation.mandatory = false;
  personFields.youngestChild.validation.fail_severity = "warning";

  return personFields;
}

export {Step1PersonFieldsBuilder, PersonFieldsBuilder, AreaPersonalePersonFieldsBuilder as AreaPesonalePersonFieldsBuilder, Step3OwnerFieldsBuilder, Step3DriverFieldsBuilder, Step3ContractorBuilder, Step3OwnerDriverFieldsBuilder};