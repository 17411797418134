import { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Constants from '../utils/constants';
import { Button } from 'primereact/button';
import User from '../utils/User';
import { IFeedbackText } from '../utils/Feedback';
import { step3DataSuccess, userInfoDataSuccess } from '../../redux/actions';
import { connect } from 'react-redux';
import { UserInfo } from '../utils/common';


 interface IMenuDropDownComponentProps{
    vehicle: string;
    header?: any;
    footer?: any;
    userInfoDataSuccess: Function;
    resetPrivacy: Function;
    userInfo: UserInfo;
    logged?: boolean;
    history: ReturnType<typeof useHistory>
 }

 interface IMenuDropDownComponentState{
    menuOpenend: boolean;
    header?: any;
    footer?: any;
    user: User;
    errors: any[];
    errorTexts: IFeedbackText[];
 }

class MenuDropDownComponent extends Component<IMenuDropDownComponentProps, IMenuDropDownComponentState> {
    private vehicle: any;

    constructor(props: any) {
        super(props);
        
        this.vehicle = this.props.vehicle;
        
        this.state = {
            menuOpenend: false,
            errors: [],
            errorTexts: [],
            user: User.getInstance()
        }

        this.toggleLoginMenu = this.toggleLoginMenu.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onRegistrationClick = this.onRegistrationClick.bind(this);
    }
    
    // componentDidUpdate(prevProps: Readonly<IMenuDropDownComponentProps>, prevState: Readonly<IMenuDropDownComponentState>, snapshot?: any): void {
    //     if(prevProps.userInfo.logged === false && this.props.userInfo?.logged === true && this.state.user === undefined){
    //         this.setState({user: this.props.user})
    //     }
    // }

    static getDerivedStateFromProps(newProps: IMenuDropDownComponentProps, prevState: IMenuDropDownComponentState){
      if(newProps.userInfo.userData !== JSON.stringify(prevState.user)){
        return {
            user: User.getInstance()
        }
      }
  
      return null;
    }

    render() {
        return (
            <>
                <div id='login-component' className='login-component' onClick={this.toggleLoginMenu}>
                    <span>
                        <img src={`${Constants.SEISICURO_STATIC_CONTENT_URL}/img/user-login.svg`} alt="autenticazione - 6sicuro" id="autenticazione" className="img-fluid" />
                    </span>
                    <span className='loggin-container'>
                        {this.props?.logged ?? false
                            ? <>
                                <span className='loggin-label'>
                                    <span style={{cursor: "pointer"}}>
                                        {this.state?.user?.name}
                                    </span>
                                </span>
                                <span id="loggin-menu" className="loggin-menu">
                                    <div className={`spacer ${this.state.menuOpenend ? "open" : ""}`}>
                                        <Link className='customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite' style={{marginRight: "10px"}} to='/preventivi/area-personale'>
                                            Area personale
                                        </Link>
                                        <Button label='Logout' type='button' id='logout-button' className='customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite' onClick={this.onLogout} />
                                    </div>
                                </span>
                            </>
                            : <>
                                <span className='loggin-label' style={{cursor: "pointer"}}>
                                    Accedi
                                </span>
                                <span id="loggin-menu" className="loggin-menu">
                                    <div className={`spacer ${this.state.menuOpenend ? "open" : ""}`}>
                                        <Button label='Accedi' type='button' id='login-button' className='customBtn bgColorGreen colorWhite hoverBgColorDarkgreen hoverColorWhite' onClick={this.onLoginClick} />
                                        <span className='px-2'>o</span>
                                        <Button label='Registrati' type='button' id='logon-button' className='customBtn bgColorPrimaryGreen colorWhite hoverBgColorDarkgreen hoverColorWhite' onClick={this.onRegistrationClick} />
                                    </div>
                                </span>
                            </>
                        }
                    </span>
                </div>

            </>
        )
    }

    toggleLoginMenu(){
        this.setState({menuOpenend: !this.state.menuOpenend});
    }

    private onLogout(){
        User.logout(this.vehicle)

        this.props.userInfoDataSuccess({
            logged: false,
            email: undefined,
            openLogin: false,
            userData: JSON.stringify(User.getInstance()),
            emailChecking: undefined,
            emailRegistered: undefined,
            // skipEmailCheck: undefined,
            step1EmailValid: undefined
        });

        this.props.resetPrivacy({
            acceptSeiSicuroPrivacyPolicy: undefined,
            acceptGdprPrivacyPolicy102: undefined,
            acceptGdprPrivacyPolicy22: undefined,
            acceptGdprPrivacyPolicy3: undefined,
            acceptGdprPrivacyPolicy104: undefined,
            acceptCgu105: undefined,
            acceptIvass106: undefined
        }, this.props.vehicle);
        
        const vehicles: string[] = this.vehicle ? [this.vehicle] : ["auto", "moto"];
        (vehicles).forEach(vehicle => User.resetStepData(vehicle))
        this.props.history.push(Constants.SEISICURO_STEP_URL_WITHOUT_BASE_STEP_URL(1, this.vehicle))
    }

    public onLoginClick(){
        this.props.userInfoDataSuccess({openLogin: true, openRegistration: false})
    }

    public onRegistrationClick() {
        this.props.userInfoDataSuccess({openLogin: false, openRegistration: true})
    }
}

const mapState = (state: any) => {
  return {
    userInfo: state.userInfoData.user,
    logged: state.userInfoData.user.logged
  };
}

// Map Redux actions to component props
const mapDispatch = (dispatch: any, ownProps: any) => {
  return {
    userInfoDataSuccess: (payload: any) => dispatch(userInfoDataSuccess(payload)),
    resetPrivacy: (payload: any, vehicle: string) => dispatch(step3DataSuccess(payload, vehicle))
  }
};

const MenuDropDown = (props: any) => {
    const history: ReturnType<typeof useHistory> = useHistory();
    return <MenuDropDownComponent  {...props} history={history}/>
};

export default connect(mapState, mapDispatch)(MenuDropDown);