import { Card } from "react-bootstrap";

const TokenExpiredCard = () => {
  return <Card className="pr-form mb-5">
        <div className="p-fluid p-5">
            <div className="row">
                <div className="col-12">
                    <h1 className="titleLev4 colorPrimaryBlue mb-2">
                      Tempo scaduto
                    </h1>
                    <p>
                      Il link di attivazione della tua area personale non è più attivo.
                      <br/>Riceverai una nuova email di attivazione del tuo account.
                      <br/>Se entro 10 minuti non avrai ricevuto l'email ne potrai chiedere una nuova.
                    </p>
                </div>
            </div>
        </div>
    </Card>
}

export default TokenExpiredCard;