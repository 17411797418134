import { Component } from 'react';
import * as Constants from './utils/constants';
import { useParams, withRouter } from 'react-router-dom';
import { getInsuranceName, isNullOrUndefined } from './utils/methods';
import { connect } from 'react-redux';
import { StepDataDualState } from '../redux/reducers';
import { Step4Data } from './utils/common';

interface IStep5ErrorProps{
    step4Data: StepDataDualState;
    location: any;
    vehicle: string;
}

interface IStep5ErrorState{
    scriptLoad: boolean;
    fbMounted: boolean;
}

class Step5ErrorComponent extends Component<IStep5ErrorProps, IStep5ErrorState> {
    private vehicle: any;
    private cid: string = "";
    private cid_picture: string = "";

    constructor(props: any) {
        super(props);
        this.vehicle = props.vehicle;

        const query = new URLSearchParams(this.props.location.search);
        this.cid = !isNullOrUndefined(query.get("cid")) ? query.get("cid") + "" : "";
        this.cid_picture = query.get("cid") + "_big.jpg";

        this.state = { scriptLoad: false, fbMounted: false };
    }

    componentDidMount() {
        // avvio il processo che gestisce i cookie e monta i vari script di traking
        this.manageTraking();
    }

    render() {
        const prevId: any = (this.props.step4Data[this.vehicle as keyof StepDataDualState] as Step4Data).prevId;
        return (
            <div className="container step5">

                <div className="row from6sicuro2company">
                <div className="col-top">
                    <img src={`${Constants.SEISICURO_INSURANCE_COMPANY_LOGO_URL(this.cid_picture)}`} className="col-left" alt="" width="200"/>
                    <h1 className="titleLev3 colorErrorFeedback">Non &egrave; stato possibile salvare il tuo preventivo sul sito di {getInsuranceName(this.cid)}</h1>
                    <p className="mt-3">Si &egrave; verificato un errore durante il salvataggio del preventivo sul sito della compagnia, <br/> chiamaci allo <a className="aLike" href="tel:+390281480350"><b>0281480350</b></a> e comunica il numero di preventivo <b>{prevId}</b>.</p>
                </div>
                </div>

                <div className="row prossimi-passi white-box">
                    <div className="col-12">

                        <h3>Prossimi Passi</h3>
                        <ul>
                            <li>Troverai <b>il tuo preventivo</b> senza dover inserire altri dati</li>
                            <li>Potrai <b>modificare</b> il preventivo e procedere all'acquisto</li>
                            <li>Il servizio di 6sicuro.it è imparziale e <b>gratuito</b></li>
                        </ul>

                        <hr />

                        <div className="testo"><strong>Tra un ora circa ti invieremo una mail</strong> per poter recuperare tutti i tuoi <strong>preventivi salvati</strong>. <strong>Puoi</strong> quindi decidere se <strong>acquistare la polizza subito</strong> o in un secondo momento utilizzando i dati che ti saranno inviati.</div>
                        <div className="testo">Assicurati di ricevere le email di 6sicuro per ricevere gli aggiornamenti relativi a questo preventivo!
                        </div>

                        <ol>
                            <li>Aggiungi il contatto di <strong>6sicuro.it</strong> alla tua rubrica da <a className="aLike" href={Constants.SEISICURO_CONTACT_URL}>questo link</a></li>
                            <li>Cercare tra le tue email quelle ricevute da <strong>6sicuro</strong> e trascinale nella tua cartella Principale.</li>
                            <li>Se trovi mie email nella tua cartella di spam segnalale come <b>NOSPAM</b>.</li>
                            <li>Se usi Gmail clicca sulla freccia gialla vicino alla stellina per indicare il messaggio come importante.</li>
                        </ol>
                    </div>

                </div>
            </div>
        );
    }

    /**
     * Definisce i vari callbak per i vari script di tracciamento
     * e si occupa di appendere al documento lo script di tag manager
     */
    private manageTraking() {
        /**
         * definisco la funzione adform_mounted() 
         * che deve essere richiamata nello script tracking_adform.js (deve essere iniesttato da TagManager)
         * 
         * quando questa viene richiamata (al mount del file tracking_adform.js), come callback viene eseguita la funzione 
         * 
         * adform_listener()
         * 
         * nei vari step di questo progetto (il che fa scattare i vari tracciamenti)
         * 
         * -- STESSO FUNZIONAMENTO PER LE RESTANTI FUNZIONI
         */
        (window as any).tagMan_mounted = () => {
            if (typeof (window as any).tagMan_listener === 'function') {
                (window as any).tagMan_listener();
            }
        }

        /**
         * Appende al App lo script traking_tagManager.js (viene scaricato dal server dei contenuti statici)
         * 
         * TagManager deve iniettare i restanti script, in cui sono richiamate le funzioni sovrastanti, che danno il via a vari traccimaneti
         * tramite le funzioni di callback
         */
        this.mountJsTrackingScriptV2("traking_gtag");
        this.mountJsTrackingScriptV2("traking_tagManager");
        this.mountCookiebot();
    }

    /**
     * Appende lo script "scriptName" al documento e setta la variabile di stato "scriptLoaded a true!"
     * @param scriptName  
     */
    private mountJsTrackingScriptV2(scriptName: string) {
        const script = document.createElement("script");
        script.src = `${Constants.SEISICURO_STATIC_CONTENT_URL}/tracking/${scriptName}.js?v=1.4.1`;
        script.async = true;
        script.setAttribute("data-cookieconsent", "ignore");
        document.head.appendChild(script);
    }

    /**
     * Monta lo script che avvia la cookieBot
     */
    private mountCookiebot() {
        const script = document.createElement("script");
        script.src = "https://consent.cookiebot.com/uc.js";
        script.type = "text/javascript";
        script.id = "Cookiebot";
        script.setAttribute("data-cbid", "c46db3c9-c278-43ee-84c9-859c5fa91316");
        script.setAttribute("data-blockingmode", "auto");
        script.async = true;
        document.head.appendChild(script);
    }
}

// Required to access react-router's params (i.e. ":vehicle" in App.tsx).
const Step5Error = (props: any) => (
    <Step5ErrorComponent {...props} {...useParams()}/>
);


const mapState = (state: any) => {
    return {
        step4Data: state.step4Data,
        vehicle: state.userInfoData.user.currentVehicleSelector
    };
}

export default connect(mapState)(withRouter(Step5Error));