import { createStore, applyMiddleware, compose } from "redux";
import reducers, { step1InitialState, step2InitialState, step3InitialState, step4InitialState } from './reducers'
import thunkMiddleware from 'redux-thunk'
import * as Constants from '../components/utils/constants';

const LOCAL_STORAGE_NAME = "localData";

class PersistedStore {

    // Singleton property
    static DefaultStore: any = null;

    // Redux store
    _store: any = null;

    // When class instance is used, initialize the store
    constructor() {
        this.initStore()
    }

    // Accessor to the default instance of this class
    static getDefaultStore() {
        if (PersistedStore.DefaultStore === null) {
            PersistedStore.DefaultStore = new PersistedStore();
        }

        return PersistedStore.DefaultStore;
    }

    // Initialization of Redux Store
    initStore() {
        const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        this._store = createStore(reducers, PersistedStore.loadState(), composeEnhancers(applyMiddleware(thunkMiddleware)));
        this._store.subscribe(() => {
            PersistedStore.saveState(this._store.getState());
        });
    }

    // Getter to access the Redux store
    get store() {
        return this._store;
    }

    // Loading persisted state from localStorage, no need to access
    // this method from the outside
    static loadState() {
        try {
            let serializedState: string | null = localStorage.getItem(LOCAL_STORAGE_NAME);

            if (serializedState === null || serializedState === "") {
                return PersistedStore.initialState();
            }

            let loadedStore = JSON.parse(serializedState);
         
            // The logic below will reset the Redux store content if it is considered outdated (i.e. the version is lower than the current build's, or does not exist)
            let step1StorageInfo = loadedStore.step1Data[Constants.STORAGE_INFO_KEY];
            let step2StorageInfo = loadedStore.step2Data[Constants.STORAGE_INFO_KEY];
            let step3StorageInfo = loadedStore.step3Data[Constants.STORAGE_INFO_KEY];
            let step4StorageInfo = loadedStore.step4Data[Constants.STORAGE_INFO_KEY];
            let resetStorage = false;
            if(step1StorageInfo === undefined || step1StorageInfo.version < Constants.STORAGE_VERSION) {
                //console.log("Resetting step1Data");
                loadedStore.step1Data = step1InitialState;
                resetStorage = true;
            }
            if(step2StorageInfo === undefined || step2StorageInfo.version < Constants.STORAGE_VERSION) {
                //console.log("Resetting step2Data");
                loadedStore.step2Data = step2InitialState;
                resetStorage = true;
            }
            if(step3StorageInfo === undefined || step3StorageInfo.version < Constants.STORAGE_VERSION) {
                //console.log("Resetting step3Data");
                loadedStore.step3Data = step3InitialState;
                resetStorage = true;
            }
            if(step4StorageInfo === undefined || step4StorageInfo.version < Constants.STORAGE_VERSION) {
                //console.log("Resetting step4Data");
                loadedStore.step4Data = step4InitialState;
                resetStorage = true;
            }
            if(resetStorage) {
                localStorage.setItem(LOCAL_STORAGE_NAME, loadedStore);
            }

            return loadedStore;
        } catch (err) {
            return PersistedStore.initialState();
        }
    }

    // Saving persisted state to localStorage every time something
    // changes in the Redux Store (This happens because of the subscribe() 
    // in the initStore-method). No need to access this method from the outside
    static saveState(state: any) {
        try {
            let serializedState = JSON.stringify(state);
            localStorage.setItem(LOCAL_STORAGE_NAME, serializedState);
        } catch (err) { }
    }

    /**
     * Pulisce / svuota lo store
     */
    static clearState() {
        try {
            let emptyState = {
                step1Data: step1InitialState,
                step2Data: step2InitialState,
                step3Data: step3InitialState,
                step4Data: step4InitialState
            };

            let serializedState = JSON.stringify(emptyState);

            localStorage.setItem(LOCAL_STORAGE_NAME, serializedState);

            PersistedStore.DefaultStore = new PersistedStore();

            return PersistedStore.DefaultStore;
        } catch (err) { }
    }

    // Return whatever you want your initial state to be
    static initialState() {
        // return {...step1InitialState, ...step2InitialState, ...step3InitialState, ...step4InitialState};
        return {};
    }
}

export default PersistedStore;
