import { StepDataDualState } from "../../../redux/reducers";
import { Step1Data, Step4Data } from "../../utils/common";
import CookieService from "../../utils/CookieService";
import User from "../../utils/User";

interface IEvtMngerProps{
  step1Data: StepDataDualState;
  step4Data: StepDataDualState;
  vehicle: string;
  component: any;
}

export interface ITrackEvent{
  name: string;
  data: any;
  callback?: Function
}

export interface IWithEventManager{
  trackEvent?: Function;
}

const EventManager = (props: IEvtMngerProps) => {
  const query = new URLSearchParams(window.location.search);

  const {component: WrappedComponent, ...rest} = props;

  const step1Data: Step1Data = props.step1Data[props.vehicle as keyof StepDataDualState] as Step1Data;


  const getGlobalVaraible = () => {

    const step4Data: Step4Data = props.step4Data[props.vehicle as keyof StepDataDualState] as Step4Data;

    let state = {
      prodotto: props.vehicle,
      s_id: (query.get("s_id") !== null)
        ? query.get("s_id")
        : step1Data.s_id,
      msclkid: (query.get("msclkid") !== null)
        ? query.get("msclkid")
        : step1Data.msclkid,
      fbp: (CookieService.getCookie("_fbp") !== null)
        ? CookieService.getCookie("_fbp")
        : null,
      fbc: (query.get("fbclid") !== null)
        ? query.get("fbclid")
        : (CookieService.getCookie("_fbc") !== null) ? CookieService.getCookie("_fbc") : null,
      sessionId: (CookieService.getCookie(`richiestapreventivoid${props.vehicle}`) !== null)
        ? CookieService.getCookie(`richiestapreventivoid${props.vehicle}`)
        : "",
      gclid: query.get("gclid") ?? sessionStorage.getItem("gclid") ?? step1Data?.gclid ?? undefined,
      utm_id: query.get("utm_id") ?? sessionStorage.getItem("utm_id") ?? step1Data?.utm_id ?? undefined,
      utm_campaign: query.get("utm_campaign") ?? sessionStorage.getItem("utm_campaign") ?? undefined,
      utm_content: query.get("utm_content") ?? sessionStorage.getItem("utm_content") ?? undefined,
      utm_source: query.get("utm_source") ?? sessionStorage.getItem("utm_source") ?? undefined,
      utm_medium: query.get("utm_medium") ?? sessionStorage.getItem("utm_medium") ?? undefined,
      utm_name: query.get("utm_name") ?? sessionStorage.getItem("utm_name") ?? undefined,
      utm_term: query.get("utm_term") ?? sessionStorage.getItem("utm_term") ?? undefined,
    };
    
    let globalVariable: any = {
      url: window.location.origin + window.location.pathname,
      userAgent: navigator?.userAgent,
      newSite: "nuova_UI",
      idFinanceAds: state.s_id,
      gclid: state.gclid,
      utm_id: state?.utm_id ?? undefined,
      utm_campaign: state?.utm_campaign ?? undefined,
      utm_content: state?.utm_content ?? undefined,
      utm_source: state?.utm_source ?? undefined,
      utm_medium: state?.utm_medium ?? undefined,
      utm_name: state?.utm_name ?? undefined,
      utm_term: state?.utm_term ?? undefined,
      msclkid: state.msclkid,
      fbp: state.fbp,
      fbc: state.fbc,
      sessionId: state.sessionId,
      fromMailSereno: step4Data.fromMailSereno
        ? "true"
        : "false"
    };

    ((User.getInstance()?.id ?? -1) !== -1) && (globalVariable.user_id = User.getInstance().id);

    return globalVariable;
  }


  const defaultTrackingValidation = () => {
    return (window as any)?.Cookiebot?.consent?.marketing || (window as any)?.Cookiebot?.consent?.statistics;
  }

  const trackEvent = (event_data: {name: string, data: any, callback?: Function, enabler?: Function}) => {

    const {name: eventName, data: dataLayer, callback, enabler } = event_data;

    (async () => {
      while (!(window as any).tagMan_mounted) {
        console.log("waiting for tagMan_listener");
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      
      if(enabler ? enabler() : defaultTrackingValidation()){
        if ((window as any).gtag !== undefined) {
          (window as any).gtag("event", eventName, {
            ...getGlobalVaraible(),
            ...dataLayer,
            event_callback: callback?.(),
          });
        }
        else {
          callback?.()
        }
      }
      else{
        callback?.()
      }
    })();

  }
  
  return <WrappedComponent {...rest} trackEvent={trackEvent}/>
}

export default EventManager;